<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-err text-center">
        <span class="err" v-show="isCheckBoxErr">{{ this.checkboxMsg }}</span>
        <span class="err" v-show="isKoji_kbnErr && !isLoading">
          <ul style="list-style-type: none;">
            <li v-for="(kojiMsg, index) in koji_kbnMsg" :key="index">
              {{ kojiMsg }}
            </li>
          </ul>
        </span>
      </div>
      <div class="container mt-3 mb-3">
        <h4 class="text-primary">【抽出条件】</h4>
      </div>
      <div class="container border-bottom border-secondary mb-3">
        <div class="form-group row" style="width:100%; max-width:650px">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
            <input
              type="tel"
              @blur="changedYear()"
              class="form-control bg-required"
              maxlength="4"
              v-model="jpYear"
            />
          </div>
          <label
            class="col-sm col-form-label text-left pr-0 pl-1"
            style="width:100%; max-width:20px;margin-right:7rem;"
          >年</label>
          <div class="form-check form-check-inline ml-4" style="width:100%; max-width:65px">
            <label class="form-check-label">工事区分</label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="koji_kbn1"
                true-value="1"
                false-value="0"
                :disabled="koji_kbn1_dislabled"
              />
              県工事
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="koji_kbn2"
                true-value="1"
                false-value="0"
              />
              市町村工事
            </label>
          </div>
        </div>
        <div class="offset-sm-1 pl-4 mb-2">
          <span class="err" v-show="isYearNotFound">{{ this.yearErrorMsg }}</span>
        </div>
        <div class="form-group row" style>
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:400px;">
            <!--
            <input
              @blur="kenChange(meisyou, saigai_ken)"
              class="form-control bg-required"
              style=" width:180px; border-radius: 0; position:absolute;"
              type="text"
              maxlength="9"
              v-model="selectedValue"
            />-->
            <select
              style="width:100%; max-width:400px; !important;"
              class="form-control bg-required"
              v-on:change="kenChange(meisyou, saigai_ken)"
              v-model="saigai_ken"
            >
              <option
                v-for="meisyou in meisyou"
                :key="meisyou.saigai_ken"
                :value="meisyou.saigai_ken"
              >{{convSaigaiKen(meisyou.saigai_ken)}}<span v-if="meisyou.saigai_ken!= null"> : </span>{{meisyou.saigai_mei}}</option>
            </select>
          </div>
          <label class="col-sm col-form-label text-right" style="width:100%; max-width:120px">災害発生日</label>
          <div class="col-sm pr-0" style="width:100%; max-width:130px">
            <input type="text" class="form-control bg-light" v-model="saigai_start" readonly />
          </div>
          <label class="col-sm col-form-label text-center" style="width:100%; max-width:50px">〜</label>
          <div class="col-sm pl-0" style="width:100%; max-width:130px">
            <input type="text" class="form-control bg-light" v-model="saigai_end" readonly />
          </div>
        </div>
        <div class="offset-sm-1 pl-4 mb-2">
          <span class="err" v-show="isHasKenNotFoundErr">{{ this.kenErrorMsg }}</span>
        </div>
        <div class="form-group row" style>
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
          <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:300px">
            <select
              id
              class="form-control"
              v-model="kanrisya_cd"
              v-on:change="kanrisyaChange($event)"
            >
              <option
                v-for="kanrisya in kanrisyaList"
                :key="kanrisya.kanrisya_cd"
                :value="kanrisya.kanrisya_cd"
              >{{kanrisya.kanrisya_cd}}：{{kanrisya.kanrisya_mei}}</option>
            </select>
          </div>
          <div class="form-check form-check-inline ml-4" style="width:100%; max-width:80px">
            <label class="form-check-label">訂正区分</label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" v-model="kode_kbn" v-bind:value="1" />
              災害報告
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" v-model="kode_kbn" v-bind:value="2" />
              １ヶ月訂正
            </label>
          </div>
        </div>
      </div>
      <div class="container">
        <p class="text-info">出力対象の帳票にチェックを入れて下さい。</p>
      </div>
      <div class="container clearfix">
        <div class="form-inline">
          <button
            v-on:click="check_all()"
            class="btn btn-light"
            type="submit"
            style="width:120px;"
          >全選択</button>
          <button
            v-on:click="uncheck_all()"
            class="btn btn-light ml-3"
            type="submit"
            style="width:120px;"
          >解除</button>
        </div>
      </div>
      <div class="container mt-4 table-responsive">
        <table class="table table-bordered table-sm">
          <template v-if="authorityAdmin()">
            <thead class="bg-primary text-light text-center">
              <th class="align-middle" width="15%">選択</th>
              <th class="align-middle" width="35%">帳票名</th>
              <th class="align-middle" width="15%">選択</th>
              <th class="align-middle" width="35%">帳票名</th>
            </thead>
            <tbody>
                <tr>
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk6">
                  </td>
                  <td>被害報告表</td>
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk1">
                  </td>
                  <td>県市町村工事災害報告一覧表</td>
                </tr>
                <tr class="table-secondary">
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk3">
                  </td>
                  <td>被害報告取りまとめ表</td>
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk4">
                  </td>
                  <td>災害報告一覧表</td>
                </tr>
                <tr>
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk5">
                  </td>
                  <td>災害報告表</td>
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk7">
                  </td>
                  <td>被害報告一覧</td>
                </tr>
                <tr class="table-secondary">
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk2">
                  </td>
                  <td>災害報告明細表</td>
                  <td class="text-center">
                    <input type="checkbox" v-model="chkArr" value="chk8">
                  </td>
                  <td>被害報告一覧（削除報告含む）</td>
                </tr>
              </tbody>
          </template>
          <template v-else>
            <thead class="bg-primary text-light text-center">
              <th class="align-middle" width="20%">選択</th>
              <th class="align-middle">帳票名</th>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  <input type="checkbox" v-model="chkArr" value="chk6">
                </td>
                <td>被害報告表</td>
              </tr>
              <tr class="table-secondary">
                <td class="text-center">
                  <input type="checkbox" v-model="chkArr" value="chk1">
                </td>
                <td>県市町村工事災害報告一覧表</td>
              </tr>
              <tr>
                <td class="text-center">
                  <input type="checkbox" v-model="chkArr" value="chk4">
                </td>
                <td>災害報告一覧表</td>
              </tr>
              <tr class="table-secondary">
                <td class="text-center">
                  <input type="checkbox" v-model="chkArr" value="chk7">
                </td>
                <td>被害報告一覧</td>
              </tr>
            </tbody>
          </template>
        </table>
        <button type="button" class="btn btn-light d-block mx-auto" @click="multipleExport()" style="width:120px;">出力</button>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { ja } from "vuejs-datepicker/dist/locale";
import { APIService } from "@/apiService.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

const apiService = new APIService();
var numeral = require("numeral");

export default {
  name: "fa114",
  components: {
    AppLayout, Loading
  },
  data() {
    var kanCd = "";
    var kanMei = "";
    if (localStorage.getItem("Kanrisya_Cd") == 1) {
      kanCd = "0";
      kanMei = "すべて";
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      jpYear: "",
      isYearNotFound: false,
      yearErrorMsg: "",
      isHasKenNotFoundErr: false,
      kenErrorMsg: "",
      isCheckBoxErr: false,
      checkboxMsg: "",
      saigai_yyyy: "",
      saigai_ken: "",
      saigai_kens: [],
      saigai_start: "",
      saigai_starts: [],
      saigai_end: "",
      saigai_ends: [],
      saigai_mei: "",
      saigai_meis: [],
      shicyou_kbn: localStorage.getItem('shicyou_kbn'),
      kanrisyaList: [],
      err: "",
      kanrisya1Check: false,
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      kode_kbn: "",
      koji_kbn1: this.authorityKenDodoku() ? '1' : '0',
      koji_kbn2: '1',
      meisyou: [],
      ja: ja,
      chkArr: [],
      checkError: false,
      isLoading: false,
      fullPage: true,
      selectedValue:"",
      isKoji_kbnErr: false,
      yearFlg: true,
      koji_kbnMsg: [],
      koji_kbn1_dislabled: this.authorityKenDodoku(),
    };
  },
  mounted() {
    this.saigai_yyyy = (new Date().getFullYear()).toString();
    this.getGengo(this.saigai_yyyy);
    this.getKanrisya();
    this.search(this.saigai_yyyy);
  },
  methods: {
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0") == 0
        ? ""
        : numeral(number).format("0,0");
    },
    getGengo(year){
      this.info = '';
      this.isHasError = false;
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format('YYYYMMDD');
      var request_data = {
        date: param,
      };
      apiService.getGengo(request_data)
      .then(result=>{
        this.jpYear = result;
      });
    },
    search(year) {
      this.isLoading = true;
      var request_data = {
        year: year
      };
      apiService
        .fa114Search(request_data)
        .then(result => {
          this.selectedValue = "";
          this.meisyou = result;
          if (Object.keys(this.meisyou).length != 0) {
            this.meisyou.unshift({saigai_yyyy: null, saigai_ken: null, saigai_mei: null, saigai_start: null, saigai_end: null ,kbn: null})
            var saigai_ken_array = [];
            var saigai_kuni_array = [];
            var saigai_start_array = [];
            var saigai_end_array = [];
            var saigai_mei_array = [];
            saigai_mei_array = [];
            for (var i in result) {
              saigai_ken_array[i] = result[i].saigai_ken;
              saigai_kuni_array[i] = result[i].saigai_kuni;
              saigai_start_array[i] = result[i].saigai_start;
              saigai_end_array[i] = result[i].saigai_end;
              saigai_mei_array[i] = result[i].saigai_mei;
            }
            this.saigai_ken = saigai_ken_array[saigai_ken_array.length - 1];
            this.saigai_kens = saigai_ken_array;
            this.saigai_kuni = saigai_kuni_array[saigai_kuni_array.length - 1];
            this.saigai_kunis = saigai_kuni_array;
            this.saigai_start = saigai_start_array[saigai_start_array.length - 1];
            this.saigai_start = this.formatDate(this.saigai_start);
            this.saigai_starts = saigai_start_array;
            this.saigai_end = saigai_end_array[saigai_end_array.length - 1];
            this.saigai_end = this.formatDate(this.saigai_end);
            this.saigai_ends = saigai_end_array;
            this.saigai_mei = saigai_mei_array[saigai_mei_array.length - 1];
            this.saigai_meis = saigai_mei_array;
            this.getSaigaiHoukokuDataCheck(this.saigai_yyyy);
            this.selectedValue = this.saigai_ken +" : "+this.saigai_mei;
          } else {
            this.clearData();
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    changedYear() {
      this.isYearNotFound = false;
      this.isHasKenNotFoundErr = false;
      if (this.jpYear != "") {
        var jpYear_1st_val  = this.jpYear.substring(0, 1);
        if(isNaN(jpYear_1st_val)){
          var matches = this.jpYear.match(/[a-zA-Z]+/g);
          var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
          var request_data = {
            gengo_cd: matches ? matches.toString() : "",
          }
        }
        apiService.getGengoStartDate(request_data)
        .then(result=>{
          if(result.length >0){
            var startDate = result[0].start_date;
            this.saigai_yyyy = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);
            this.yearFlg = true;
            this.search(this.saigai_yyyy);
          }else{
            this.yearFlg = false;
            this.clearData();
          }
        });
      } else {
        this.clearData();
      }
    },
    kenChange(meisyou, saigai_ken) {
      this.saigai_ken = this.formatNumber(saigai_ken);
      var flag = true;
      for (var i in meisyou) {
        if (saigai_ken == meisyou[i].saigai_ken) {
          this.saigai_ken = meisyou[i].saigai_ken;
          this.saigai_kuni = meisyou[i].saigai_kuni;
          this.saigai_start = meisyou[i].saigai_start;
          this.saigai_end = meisyou[i].saigai_end;
          if(meisyou[i].saigai_ken == null){
            this.saigai_start = "";
            this.saigai_end = "";
          }else{
            this.saigai_start = this.formatDate(this.saigai_start);
            this.saigai_end = this.formatDate(this.saigai_end);
          }
          this.saigai_mei = meisyou[i].saigai_mei;
          this.getSaigaiHoukokuDataCheck(this.saigai_yyyy);
          flag = false;
          if(meisyou[i].saigai_ken == null){
            this.selectedValue = "";
          }else{
            this.selectedValue = meisyou[i].saigai_ken + " : " +meisyou[i].saigai_mei;
          }
        }
        if (flag) {
          this.saigai_kuni = "";
          this.saigai_start = "";
          this.saigai_end = "";
          this.saigai_mei = "";
        }
      }
    },
    getKanrisya() {
      if (localStorage.getItem("Kanrisya_Cd") == 1) {
        this.kanrisya1Check = true;
      }
      var request_data = {
        kengen_cd: localStorage.getItem('kengen_cd'),
        kanrisya_cd: localStorage.getItem('Kanrisya_Cd'),
      };
      apiService
        .getKanrisyaByFlg(request_data)
        .then(result => {
          this.kanrisyaList = result;
          this.getSaigaiHoukokuDataCheck(this.saigai_yyyy);
        })
        .catch(error => {
          this.err = error;
        });
    },
    kanrisyaChange(kanrisya) {
      var kanrisya_index =
        kanrisya.target.options[kanrisya.target.options.selectedIndex].value;
      for (var i = 0; i < this.kanrisyaList.length; i++) {
        if (this.kanrisyaList[i].kanrisya_cd == kanrisya_index) {
          this.kanrisya_cd = kanrisya_index;
          this.kanrisya_mei = this.kanrisyaList[i].kanrisya_mei;
          this.shicyou_kbn = this.kanrisyaList[i].shicyou_kbn;
          this.koji_kbn1_dislabled = !this.authorityKenDodoku();
          this.getSaigaiHoukokuDataCheck(this.saigai_yyyy);
        }
      }
    },
    getSaigaiHoukokuDataCheck(year) {
      var request_data = {
        year: year,
        saigai_ken: this.saigai_ken,
        kanrisya_cd: localStorage.getItem("Kanrisya_Cd")
        // kbn :2
      };
      apiService
        .fa114getSaigaiHoukokuDataCheck(request_data)
        .then(result => {
          this.saigai_houkokuList = result;
          if (this.saigai_houkokuList.length > 0) {
            this.kode_kbn = 2;
          } else {
            this.kode_kbn = 1;
          }
        })
        .catch(error => {
          this.err = error;
        });
    },
    clearData() {
      this.meisyou = [];
      this.saigai_ken = "";
      this.saigai_kens = [];
      this.saigai_kuni = "";
      this.saigai_end = "";
      this.saigai_start = "";
      this.saigai_mei = "";
    },
    check_all() {
      var authorityAdmin = this.authorityAdmin();
      if (authorityAdmin) {
        this.chkArr = [ "chk1", "chk2", "chk3", "chk4", "chk5" , "chk6" , "chk7" , "chk8"];
      } else {
        this.chkArr = [ "chk1", "chk4", "chk6" , "chk7"];
      }
    },
    uncheck_all() {
      this.chkArr = [];
    },
    multipleExport() {
      this.checkError = false;
      this.isYearNotFound = false;
      this.isHasKenNotFoundErr = false;
      this.isCheckBoxErr = false;
      this.koji_kbnMsg = [];
      this.isLoading = true;
      this.isKoji_kbnErr = false;
      if(!this.yearFlg){
        this.checkError = true;
        this.isLoading = false;
        this.isCheckBoxErr = true;
        this.checkboxMsg = this.$messages.msg["0002E"];
      }else{
        if (this.jpYear == null || this.jpYear== "") {
          this.checkError = true;
          this.isLoading = false;
          this.isYearNotFound = true;
          this.yearErrorMsg = this.$messages.msg["0004W"];
        }
        if (this.saigai_ken == null || this.saigai_ken == "") {
          this.checkError = true;
          this.isLoading = false;
          this.isHasKenNotFoundErr = true;
          this.kenErrorMsg = this.$messages.msg["0005W"];
        }
        if(this.chkArr.length === 0) {
          this.checkError = true;
          this.isLoading = false;
          this.isCheckBoxErr = true;
          this.checkboxMsg = this.$messages.msg["0023W"];
        }
      }
      // 必須項目チェック
      this.requireCheck();
      if (!this.checkError) {
        this.isYearNotFound = false;
        this.isHasKenNotFoundErr = false;
        this.isCheckBoxErr = false;
        const request_data = {
          saigai_yyyy: this.saigai_yyyy,
          saigai_ken: this.saigai_ken,
          koji_kbn1: this.koji_kbn1,
          koji_kbn2: this.koji_kbn2,
          kbn: this.kode_kbn,
          kanrisya_cd: this.kanrisya_cd,
          doboku_cd: 2,
          excelObj: this.chkArr,
          jimusyo_mei: localStorage.getItem("Jimusyo_Mei"),
          jimusyo_cd: localStorage.getItem("Kanrisya_Cd"),
        }
        apiService.export(request_data).then(result => {
          var date = moment(String(new Date())).format('YYYYMMDD');
          this.downloadReport(result, this.$constants.FA114EXCELFN.LA203 + date +'.xlsx');
        }).catch(() => {
          this.isCheckBoxErr = true;
          this.isLoading = false;
          this.checkboxMsg = this.$messages.msg['0002E'];
          confirm(this.$messages.msg['0013E']);
        });
      } else {
        confirm(this.$messages.msg['0013E']);
        this.isLoading = false;
      }
    },
    downloadReport(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
        this.isLoading = false;
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        this.isLoading = false;
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    requireCheck() {
      if(this.chkArr.indexOf("chk1") !== -1) {
        if (this.koji_kbn1 == 0 && this.koji_kbn2 == 0){
          this.isKoji_kbnErr = true;
          this.koji_kbnMsg.push(this.$messages.msg["0035W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if(this.chkArr.indexOf("chk2") !== -1) {
        if (this.koji_kbn1 == 0 && this.koji_kbn2 == 0){
          this.isKoji_kbnErr = true;
          this.koji_kbnMsg.push(this.$messages.msg["0036W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
    },
    authorityAdmin(){
      var kengen_cd = localStorage.getItem('kengen_cd');
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    authorityKenDodoku(){
      if (this.shicyou_kbn == this.$constants.SHICYOU_KBN.KEN
          || this.shicyou_kbn == this.$constants.SHICYOU_KBN.DAM
          || this.shicyou_kbn == this.$constants.SHICYOU_KBN.DOBOKU) {
        return true;
      } else {
        return false;
      }
    },
    convSaigaiKen(saigai_ken){
      if(!saigai_ken){
        return "";
      }else{
        return ( '00000' + saigai_ken ).slice(-5);
      }
    },
  }
};
</script>
