<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";

const apiService = new APIService();

export default {
  name: "M1101",
  components: {
    AppLayout,
  },
  data() {
    return {
      naiyou: "",
      oshirase_id: "",
      kanrisya_cd: localStorage.getItem("Kanrisya_Cd"),
      login_id: localStorage.getItem('LOGIN_ID'),
      showEditBtn: false,
      image: '<img src="@/assets/new.gif"/>',
      isdisplay: localStorage.getItem("Kanrisya_Cd") == 1 ? true : false,
      isTest:
        window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
    };
  },
  mounted() {
    this.oshirase();
    if (this.kanrisya_cd == 1) {
      this.showEditBtn = true;
    } else {
      this.showEditBtn = false;
    }
  },
  methods: {
    oshirase() {
      apiService.getOshirase().then((result) => {
        if (result.length > 0) {
          var contents = result[0].naiyou;
          this.naiyou = contents;
          this.naiyou = this.naiyou.replace(/\n/g, "<br>");
          this.oshirase_id = result[0].id;
        }
      });
    },
    gofd101() {
      this.$store.commit("fd101/setLoginId", "");
      this.$store.commit("fd101/setLoginName", "");
      this.$store.commit("fd101/setKanrisya", "");
      this.$store.commit("fd101/setKanrisyaMei", "");
      this.$store.commit("fd101/setValidFlg", "");
      this.$store.commit("fd101/setKengenCd", "");
      this.$store.commit("fd101/setPage", "");
      this.$router.push({
        name: "FD101",
      });
    },
    authorityAdmin() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    authorityYoshiki() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.YOSHIKI) {
        return true;
      } else {
        return false;
      }
    },
    authorityGenba() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.GENBA) {
        return true;
      } else {
        return false;
      }
    },
    authorityGenbaTouroku() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.GENBATOUROKU) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div
        class="container-fluid bg-info mb-3 pt-2 pb-2"
        :class="this.isTest ? 'test-bg-info' : ''"
      >
        <div class="container">
          <h3 class="text-white mb-0">災害管理集計システム</h3>
        </div>
      </div>
      <div class="container">
        <h5 class="text-primary mb-0 float-left">お知らせ</h5>
        <form class="form-inline float-right mb-2" v-if="authorityAdmin()">
          <router-link
            :to="{ name: 'INF002' }"
            class="btn btn-light"
            role="button"
            >お知らせ編集</router-link
          >
        </form>
      </div>
      <template v-if="authorityYoshiki()">
        <div class="container">
          <div
            class="container mb-3 pt-2 pb-2 border border-secondary"
            style="height: 150px; overflow: auto; line-height: 1.5em"
          >
            <p v-html="naiyou"></p>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 mb-3">
                <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link
                      class="btn btn-youhsiki"
                      :to="{ name: 'FG203' }"
                      role="button"
                      >様式ダウンロード</router-link
                    >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="authorityGenba()">
        <div class="container">
          <div
            class="container mb-3 pt-2 pb-2 border border-secondary"
            style="height: 150px; overflow: auto; line-height: 1.5em"
          >
            <p v-html="naiyou"></p>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 mb-3">
                <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link :to="{ name: 'M1200' }" class="img_item d-block">
                      <img
                        src="@/assets/1.png"
                        alt
                        class="img-fluid mx-auto d-block"
                      />
                    </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 mb-3">
                <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link
                      class="btn btn-youhsiki"
                      :to="{ name: 'FD102' ,
                        query: {
                        param_kubun: 2,
                        login_id:this.login_id,
                        }
                      }"
                      role="button"
                      >ユーザ情報変更</router-link
                    >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="authorityGenbaTouroku()">
        <div class="container">
          <div
            class="container mb-3 pt-2 pb-2 border border-secondary"
            style="height: 150px; overflow: auto; line-height: 1.5em"
          >
            <p v-html="naiyou"></p>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 mb-3">
                <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link
                      class="btn btn-youhsiki"
                      :to="{ name: 'FD102' ,
                        query: {
                        param_kubun: 1,
                        login_id:this.login_id,
                        }
                      }"
                      role="button"
                      >ユーザ新規登録</router-link
                    >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="container">
          <div
            class="container mb-3 pt-2 pb-2 border border-secondary"
            style="height: 150px; overflow: auto; line-height: 1.5em"
          >
            <p v-html="naiyou"></p>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 mb-3">
                <router-link :to="{ name: 'M1200' }" class="img_item d-block">
                  <img
                    src="@/assets/1.png"
                    alt
                    class="img-fluid mx-auto d-block"
                  />
                </router-link>
              </div>
              <div class="col-sm-4 mb-3">
                <router-link :to="{ name: 'M1201' }" class="img_item d-block">
                  <img
                    src="@/assets/2.png"
                    alt
                    class="img-fluid mx-auto d-block"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 mb-3">
                <router-link :to="{ name: 'M1202' }" class="img_item d-block">
                  <img
                    src="@/assets/3.png"
                    alt
                    class="img-fluid mx-auto d-block"
                  />
                </router-link>
              </div>
              <div class="col-sm-4 mb-3">
                <template v-if="authorityAdmin()">
                  <router-link :to="{ name: 'M1203' }" class="img_item d-block">
                    <img
                      src="@/assets/4.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </router-link>
                </template>
                <template v-else>
                  <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link
                      class="btn btn-youhsiki"
                      :to="{ name: 'FG203' }"
                      role="button"
                      >様式ダウンロード</router-link
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>
          <template v-if="authorityAdmin()">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-4 mb-3">
                  <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link
                      class="btn btn-youhsiki"
                      :to="{ name: 'FG204' }"
                      role="button"
                      >様式アップロード</router-link
                    >
                  </div>
                </div>
                <div class="col-sm-4 mb-3">
                  <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <router-link
                      class="btn btn-youhsiki"
                      :to="{ name: 'FG203' }"
                      role="button"
                      >様式ダウンロード</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-4 mb-3">
                  <div
                    class="d-flex flex-row flex-wrap"
                    style="width: 100%; max-width: 800px"
                  >
                    <button
                      class="btn btn-youhsiki"
                      role="button"
                      @click="gofd101()"
                    >
                    ユーザ情報変更
                    </button>
                  </div>
                </div>
                <div class="col-sm-4 mb-3">
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </main>
  </AppLayout>
</template>
