<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle">
          <img src="@/assets/loader.gif" style="dipslay: inline-block; float: left" />
          <h4 style="dipslay: inline-block; float: left; color: #3399f3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasError" class="err">{{ this.errorMsg }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
        <form class="form-inline float-right">
          <button type="button" class="btn btn-light" style="width: 120px" :disabled="kanrisya_all" @click="newHoukoku()">新規</button>
        </form>
      </div>
      <div class="container mb-3">
        <form action method>
          <div class="form-group row" style="width: 100%" :class="isYearNotFound ? 'mb-0' : ''">
            <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">災害年</label>
            <div class="col-sm pr-0 pl-0 mb-md-0 mb-3" style="width: 100%; max-width: 60px">
              <input type="tel" class="form-control bg-required" @blur="changedYear()" v-model="jpYear" />
            </div>
            <label for class="col-sm col-form-label text-left pr-0 pl-1" style="width: 100%; max-width: 20px">年</label>
            <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 130px"></div>
            <div class="row ml-0" style="width: 450px">
              <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">有効フラグ</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="valid_flg" v-bind:value="2" />
                <label class="form-check-label" for="">すべて</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="valid_flg" v-bind:value="1" checked />
                <label class="form-check-label" for="">有効</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model="valid_flg" v-bind:value="0" />
                <label class="form-check-label" for="">無効</label>
              </div>
            </div>
          </div>
          <div class="offset-sm-1 pl-4 mb-2">
            <span class="err" v-show="isYearNotFound">{{ this.required }}</span>
          </div>
          <div class="form-group row ml-0" :class="isHasKenNotFoundErr ? 'mb-0' : ''">
            <div>
              <div class="row mr-3">
                <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">災害番号</label>
                <div class="col-sm pr-0 pl-3 pl-sm-0 mb-2 mr-3" value style="width: 340px; max-width: 350px">
                  <select @change="onChange(selectedOption, options)" class="form-control bg-required" v-model="selectedOption">
                    <option v-for="option in options" :key="`options-${option.saigai_ken}`" :value="option.saigai_ken">{{ convSaigaiKen(option.saigai_ken) }}<span v-if="option.saigai_ken != null"> : </span>{{ option.saigai_mei }}</option>
                  </select>
                </div>
              </div>
              <div class="offset-sm-1 pl-4 mb-2">
                <span class="err" v-show="isHasKenNotFoundErr">{{ this.kenErrorMsg }}</span>
              </div>
            </div>
            <div class="row" style="width: 450px">
              <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">災害発生日</label>
              <div class="row" style="width: 100%; max-width: 350px; margin-left: 5px">
                <div class="col-sm pr-0 mb-2" style="width: 100%; max-width: 130px">
                  <input type="text" class="form-control" v-model="saigai_start" readonly />
                </div>
                <label for class="col-sm col-form-label text-center" style="width: 100%; max-width: 50px">〜</label>
                <div class="col-sm pl-0 mb-2" style="width: 100%; max-width: 130px">
                  <input type="text" class="form-control" v-model="saigai_end" readonly />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row ml-0" style="width: 100%">
            <div class="row order-lg-2" style="width: 450px">
              <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">状態</label>
              <div class="col-sm">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" v-model="status" v-bind:value="0" />
                  <label class="form-check-label" for="">すべて</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" v-model="status" v-bind:value="1" />
                  <label class="form-check-label" for="">仮登録</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" v-model="status" v-bind:value="2" />
                  <label class="form-check-label" for="">本登録</label>
                </div>
              </div>
            </div>
            <div class="row mr-3 order-lg-1" style="width: 450px">
              <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">管理者</label>
              <div class="col-sm pr-0 pl-3 pl-sm-0 mb-2" value style="width: 100%; max-width: 300px">
                <select id class="form-control bg-required" v-model="kanrisya_cd" @change="kanrisyaChange(kanrisya_cd)" :disabled="genba_user">
                  <option v-for="kanrisya in kanrisyaList" :key="`kanrisyaList-${kanrisya.kanrisya_cd}`" :value="kanrisya.kanrisya_cd">{{ kanrisya.kanrisya_cd }}：{{ kanrisya.kanrisya_mei }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group row" style="width: 100%">
            <label for class="col-sm col-form-label" style="width: 100%; max-width: 130px">登録ユーザー</label>
            <div class="col-sm pr-0 pl-3 pl-sm-0" value style="width: 100%; max-width: 300px">
              <select id class="form-control bg-required" v-model="login_id" @change="registrantChange(login_id)" :disabled="genba_user">
                <option v-for="(registrant, index) in registrantList" :key="index" :value="registrant.login_id">{{ registrant.login_id }}：{{ registrant.login_name }}</option>
              </select>
            </div>
          </div>
          <div class="offset-sm-1 pl-4 mb-2">
            <span class="err" v-show="isHasKanrisyaNotFoundErr">{{ this.kanrisyaErrorMsg }}</span>
          </div>
          <button type="button" class="btn btn-light d-block mx-auto" @click="search" style="width: 120px">検索</button>
        </form>
      </div>
      <div class="container pt-3 border-secondary" v-show="showTable">
        <p class="text-info text-end text-sm-start">修正・削除行を選択して下さい。</p>
      </div>
      <div class="container" v-show="showTable">
        <div class="row">
          <div class="col-md-3 d-flex justify-content-end justify-content-md-start mb-2 mb-md-0">
            <table class="table-sm">
              <tr>
                <td class="align-middle">検索結果</td>
                <td width="40px" class="bg-light text-center table-bordered align-middle">{{ houkokuDataList.total }}</td>
                <td class="text-center align-middle">件</td>
              </tr>
            </table>
          </div>
          <div class="col-md-6 row justify-content-center">
            <div>
              <b-pagination v-model="houkokuDataList.current_page" :total-rows="houkokuDataList.total" :data="houkokuDataList.data" :per-page="houkokuDataList.per_page" @change="search"></b-pagination>
            </div>
            <div>
              <button type="button" class="btn btn-light d-block ml-3" @click="viewLarger()" style="width: 150px; height: 38.67px" :disabled="checkItijohoNotExist || checkMobileScreen ? true : false">地図拡大表示</button>
            </div>
          </div>
          <div class="col-md-3 text-right align-middle">
            <p class="mb-0 mt-2">{{ houkokuDataList.current_page }}/{{ houkokuDataList.last_page }}項</p>
          </div>
        </div>
      </div>
      <div v-if="!genba_user">
        <div class="container mt-2 table-responsive justify-content-center" v-show="showTable">
          <table class="table text-center table-bordered table-sm text-nowrap d-none d-sm-block d-sm-none" style="width: 100%; max-width: 1140px">
            <thead class="bg-primary text-light">
              <tr>
                <th rowspan="2" class="align-middle" width="5%"></th>
                <th scope class="align-middle" width="7%">整理番号</th>
                <th rowspan="2" class="align-middle" width="4.5%">状態</th>
                <th class="align-middle" width="11%">被災日</th>
                <th class="align-middle" width="12%">工種</th>
                <th class="align-middle" width="9%">事業主体</th>
                <th class="align-middle" width="15%">市町村(Map)</th>
                <th colspan="2" scope class="align-middle" width="22%">町名・字名</th>
                <th class="align-middle" width="14%">地先</th>
              </tr>
              <tr>
                <th class="align-middle">予算区分</th>
                <th colspan="3" class="align-middle" width>河川・路線</th>
                <th cla ss="align-middle">橋梁</th>
                <th class="align-middle" style="width: 175px">被災状況</th>
                <th class="align-middle">被災延長</th>
                <th class="align-middle">被災報告額</th>
              </tr>
            </thead>
            <tbody v-for="(houkokuData, index) in houkokuDataList.data" :key="index">
              <tr :class="index % 2 != 0 ? 'table-secondary' : ''">
                <td rowspan="2" class="align-middle">
                  <button class="btn btn-light" type="button" @click="goFA102(houkokuData)" style="width: 40px; height: 66px; font-size: 14px">選<br />択</button>
                </td>
                <td class="align-middle text-center">{{ houkokuData.seirinao }}</td>
                <td rowspan="2" class="align-middle">
                  <img v-if="houkokuData.status == 2" src="@/assets/hon.jpg" alt="" class="img-fluid mx-auto d-block" style="height: 38px" />
                  <img v-if="houkokuData.status == 1" src="@/assets/kari.jpg" alt="" class="img-fluid mx-auto d-block" style="height: 38px" />
                </td>
                <td class="align-middle">{{ houkokuData.hisaibi | formatDate }}</td>
                <td class="align-middle text-left">{{ houkokuData.kousyu_cd }}&nbsp;{{ houkokuData.kousyu_name }}</td>
                <td class="align-middle text-left">{{ houkokuData.jigyou_cd }}&nbsp;{{ houkokuData.jigyou_name }}</td>
                <td class="align-middle text-left">
                  <button class="btn btn-light" type="button" @click="moveMap(houkokuData.itijoho)" :disabled="houkokuData.itijoho === null">{{ houkokuData.shicyou_cd }}&nbsp;{{ houkokuData.shicyou_mei }}</button>
                </td>
                <td colspan="2" class="align-middle text-left">{{ houkokuData.cyoaza_cd }}&nbsp;{{ houkokuData.cyouaza_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.chisaki }}</td>
              </tr>
              <tr :class="index % 2 != 0 ? 'table-secondary' : ''">
                <td class="align-middle text-center">{{ convKuniTan(houkokuData.kuni_tan) }}</td>
                <td colspan="3" class="align-middle text-left">{{ houkokuData.rokasen_kbn }}&nbsp;{{ houkokuData.rokasen_syubetu }}&nbsp;{{ houkokuData.rokasen_cd }}&nbsp;{{ houkokuData.rokasen_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.rokyouryou_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.hisaijyou_mei }}</td>
                <td class="align-middle text-right">{{ houkokuData.hisai_encyou | formatNumber }}</td>
                <td class="align-middle text-right">{{ houkokuData.higai_gaku | formatNumber }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div class="container mt-2 table-responsive justify-content-center" v-show="showTable">
          <table class="table text-center table-bordered table-sm text-nowrap d-none d-md-block d-md-none" style="width: 100%; max-width: 1140px">
            <thead class="bg-primary text-light">
              <tr>
                <th rowspan="2" class="align-middle" width="5%"></th>
                <th class="align-middle" width="9%">状態</th>
                <th class="align-middle" width="13%">被災日</th>
                <th class="align-middle" width="14%">工種</th>
                <th class="align-middle" width="17%">市町村(Map)</th>
                <th colspan="2" scope class="align-middle" width="23%">町名・字名</th>
                <th class="align-middle" width="14%">地先</th>
              </tr>
              <tr>
                <th scope class="align-middle">整理番号</th>
                <th colspan="2" class="align-middle" width>河川・路線</th>
                <th class="align-middle">橋梁</th>
                <th class="align-middle">被災状況</th>
                <th class="align-middle">被災延長</th>
                <th class="align-middle"></th>
              </tr>
            </thead>
            <tbody v-for="(houkokuData, index) in houkokuDataList.data" :key="index">
              <tr :class="index % 2 != 0 ? 'table-secondary' : ''">
                <td rowspan="2" class="align-middle">
                  <button class="btn btn-light" type="button" @click="goFA102(houkokuData)" style="width: 40px; height: 66px; font-size: 14px">選<br />択</button>
                </td>
                <td class="align-middle">
                  <img v-if="houkokuData.status == 2" src="@/assets/hon.jpg" alt="" class="img-fluid mx-auto d-block" style="height: 38px" />
                  <img v-if="houkokuData.status == 1" src="@/assets/kari.jpg" alt="" class="img-fluid mx-auto d-block" style="height: 38px" />
                </td>
                <td class="align-middle">{{ houkokuData.hisaibi | formatDate }}</td>
                <td class="align-middle text-left">{{ houkokuData.kousyu_cd }}&nbsp;{{ houkokuData.kousyu_name }}</td>
                <td class="align-middle text-left">
                  <button class="btn btn-light" type="button" @click="moveMap(houkokuData.itijoho)" :disabled="houkokuData.itijoho === null">{{ houkokuData.shicyou_cd }}&nbsp;{{ houkokuData.shicyou_mei }}</button>
                </td>
                <td colspan="2" class="align-middle text-left">{{ houkokuData.cyoaza_cd }}&nbsp;{{ houkokuData.cyouaza_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.chisaki }}</td>
              </tr>
              <tr :class="index % 2 != 0 ? 'table-secondary' : ''">
                <td class="align-middle text-center">{{ houkokuData.seirinao }}</td>
                <td colspan="2" class="align-middle text-left">{{ houkokuData.rokasen_kbn }}&nbsp;{{ houkokuData.rokasen_syubetu }}&nbsp;{{ houkokuData.rokasen_cd }}&nbsp;{{ houkokuData.rokasen_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.rokyouryou_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.hisaijyou_mei }}</td>
                <td class="align-middle text-right">{{ houkokuData.hisai_encyou | formatNumber }}</td>
                <td class="align-middle text-right"></td>
              </tr>
            </tbody>
          </table>
          <table class="table text-center table-bordered table-md d-md-none" style="width: 100%; max-width: 1140px">
            <thead class="bg-primary text-light">
              <tr>
                <th rowspan="2" class="align-middle"></th>
                <th class="align-middle">被災日</th>
                <th class="align-middle">被災状況</th>
                <th rowspan="2" class="align-middle">市町村(Map)</th>
              </tr>
              <tr>
                <th class="align-middle" width>河川・路線</th>
                <th class="align-middle">工種</th>
              </tr>
            </thead>
            <tbody v-for="(houkokuData, index) in houkokuDataList.data" :key="index">
              <tr :class="index % 2 != 0 ? 'table-secondary' : ''">
                <td rowspan="2" class="align-middle">
                  <button class="btn btn-light" type="button" @click="goFA102(houkokuData)" style="width: 40px; height: 66px; font-size: 14px">選<br />択</button>
                </td>
                <td class="align-middle">{{ houkokuData.hisaibi | formatDate }}</td>
                <td class="align-middle text-left">{{ houkokuData.hisaijyou_mei }}</td>
                <td rowspan="2" class="align-middle text-left">
                  <button class="btn btn-light" type="button" style="padding: 10%" @click="moveMap(houkokuData.itijoho)" :disabled="houkokuData.itijoho === null">{{ houkokuData.shicyou_mei }}</button>
                </td>
              </tr>
              <tr :class="index % 2 != 0 ? 'table-secondary' : ''">
                <td class="align-middle text-left">{{ houkokuData.rokasen_cd }}&nbsp;{{ houkokuData.rokasen_mei }}</td>
                <td class="align-middle text-left">{{ houkokuData.kousyu_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container clearfix" v-show="showTable && !genba_user">
        <table class="table table-sm table-borderless float-right text-nowrap">
          <tr>
            <td width="48%"></td>
            <th width="10%" class="text-center border align-middle">箇所数</th>
            <td width="13%" class="bg-light text-center border align-middle text-right">{{ houkokuDataList.total | formatNumber }}</td>
            <th width="13%" class="text-center border align-middle">被害報告額計</th>
            <td width="16%" class="bg-light text-right border align-middle">{{ totalAmount | formatNumber }}</td>
          </tr>
        </table>
      </div>
      <div class="container clearfix" v-show="showTable" id="mapArea" style="width: 99%; height: 500px"></div>
    </main>
  </Layout>
</template>
<script>
import moment from "moment";
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();

var numeral = require("numeral");

((g) => {
  var h,
    a,
    k,
    p = "The Google Maps JavaScript API",
    c = "google",
    l = "importLibrary",
    q = "__ib__",
    m = document,
    b = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f, n) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l] ? console.warn(p + " only loads once. Ignoring:", g) : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
})({
  key: "AIzaSyBXbD7jihEamvLDszKlaJigeq8CfU2WgaE",
  // Add other bootstrap parameters as needed, using camel case.
  // Use the 'v' parameter to indicate the version to load (alpha, beta, weekly, etc.)
});

export default {
  name: "FA101",
  components: {
    Layout,
    Loading,
  },
  data() {
    var kanCd = "";
    var kanMei = "";
    var logId = "";
    var logName = "";
    if (this.authorityAdmin()) {
      kanCd = "0";
      kanMei = "すべて";
      logId = "0";
      logName = "すべて";
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
      logId = localStorage.getItem("LOGIN_ID");
      logName = localStorage.getItem("LOGIN_NAME");
    }
    return {
      isYearNotFound: false,
      required: this.$messages.msg["0004W"],
      selectedIndex: 0,
      showTable: false,
      showGoogleMap: false,
      meisyouData: {},
      meisyouList: {},
      isOptionSelected: false,
      valid_flg: 1,
      status: 0,
      options: [],
      gengoData: {},
      kanrisyaList: [],
      registrantList: [],
      registrant_id: "",
      registrant_name: "",
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      login_id: logId,
      login_name: logName,
      kanrisya_all: false,
      jpYear: "",
      saigai_yyyy: "",
      saigai_ken: "",
      saigai_mei: "",
      saigai_end: "",
      saigai_start: "",
      jigyou_cd: "",
      houkokuDataList: {
        total: 10000,
      },
      totalAmount: 0,
      errorMsg: "",
      kenErrorMsg: "",
      kanrisyaErrorMsg: "",
      houkokuselectedIndex: 0,
      selectedOption: "",
      checkedHoukokuData: [],
      isHasKenNotFoundErr: false,
      isHasKanrisyaNotFoundErr: false,
      loginUser: {
        id: window.localStorage.getItem("LOGIN_ID"),
        kanrisya_cd: window.localStorage.getItem("Kanrisya_Cd"),
        kanrisya_name: window.localStorage.getItem("Kanrisya_Mei"),
        shicyou_kbn: window.localStorage.getItem("shicyou_kbn") == 2 ? "2" : "1",
      },
      isHasError: false,
      isLoading: false,
      fullPage: true,
      searchFlg: false,
      itijoho: "",
      gazou1: null,
      gazou2: null,
      gazou3: null,
      markerList: [],
      markerData: [],
      markers: [],
      map: "",
      largeMap: "",
      genba_user: false,
      // true:位置情報が一つも存在しない、false:位置情報が一つでも存在する
      checkItijohoNotExist: true,
      screenWidth: window.innerWidth,
      checkMobileScreen: screen.width < 450 ? true : false,
    };
  },
  computed: {
    yearSV() {
      return this.$store.state.fa101.year;
    },
    kenSV() {
      return this.$store.state.fa101.ken;
    },
    kanrisya_cdSV() {
      return this.$store.state.fa101.kanrisya_cd;
    },
    kanrisya_meiSV() {
      return this.$store.state.fa101.kanrisya_mei;
    },
    registrant_idSV() {
      return this.$store.state.fa101.login_id;
    },
    registrant_nameSV() {
      return this.$store.state.fa101.login_name;
    },
    validFlg_SV() {
      return this.$store.state.fa101.valid_flg;
    },
    pageSV() {
      return this.$store.state.fa101.page;
    },
    statusSV() {
      return this.$store.state.fa101.status;
    },
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    if (this.yearSV) {
      this.isSuccessMsgShow = this.$route.params.message ? true : false;
      this.isMsgShow = false;
      this.saigai_yyyy = this.yearSV;
      this.saigai_ken = this.kenSV;
      this.isOptionSelected = true;
      this.selectedOption = this.kenSV;
      this.kanrisya_cd = this.kanrisya_cdSV;
      this.kanrisya_mei = this.kanrisya_meiSV;
      this.registrant_id = this.registrant_idSV;
      this.registrant_name = this.registrant_nameSV;
      this.houkokusya_cd = this.validFlg_SV;
      this.valid_flg = this.validFlg_SV;
      this.page = this.pageSV;
      this.status = this.statusSV;
      this.itijoho = this.itijoho;
      this.getGengoSV(this.saigai_yyyy);
      this.checkKengenCdSV();
    } else {
      this.isSuccessMsgShow = this.$route.params.message ? true : false;
      this.isMsgShow = false;
      this.saigai_yyyy = new Date().getFullYear().toString();
      this.getGengo(this.saigai_yyyy);
      this.getSaigaiMeisyou(new Date().getFullYear());
      this.getKanrisya();
      this.getRegistrant();
      this.checkKengenCd();
    }
    window.addEventListener("resize", this.handleResize);
  },
  filters: {
    formatDate: function (date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function (number) {
      return numeral(number).format("0,0");
    },
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      // resizeのたびにこの部分が発火
      this.screenWidth = screen.width;
      if (this.screenWidth < 450) {
        this.checkMobileScreen = true;
      } else {
        this.checkMobileScreen = false;
      }
    },

    getGengoSV(year) {
      this.info = "";
      this.isHasError = false;
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param,
      };
      apiService.getGengo(request_data).then((result) => {
        this.jpYear = result;
        this.getSaigaiMeisyouSV(year);
      });
    },
    getSaigaiMeisyouSV(year) {
      var request_data = {
        year: year,
        kanrisya_cd: this.kanrisya_cd,
        kengen_cd: localStorage.getItem("kengen_cd"),
      };
      apiService.fa101GetSaigaiMeisyou(request_data).then((result) => {
        this.options = {};
        if (result.length > 0) {
          this.meisyouList = result;
          this.meisyouList.unshift({ saigai_yyyy: null, saigai_ken: null, saigai_mei: null, saigai_start: null, saigai_end: null });
          this.errorMsg = "";
          for (var i = 0; i < this.meisyouList.length; i++) {
            this.options[i] = this.meisyouList[i];
            if (this.isOptionSelected) {
              if (this.selectedOption == this.meisyouList[i].saigai_ken) {
                this.selectedIndex = i;
              }
            } else {
              this.selectedIndex = i;
            }
          }
          this.selectedOption = this.options[this.selectedIndex].saigai_ken;
          this.meisyouData = this.meisyouList[this.selectedIndex];
          if (this.selectedIndex === 0) {
            this.saigai_start = "";
            this.saigai_end = "";
          } else {
            this.saigai_start = this.formatDate(this.meisyouData.saigai_start);
            this.saigai_end = this.formatDate(this.meisyouData.saigai_end);
          }
          this.saigai_ken = this.meisyouData.saigai_ken;
          this.saigai_mei = this.meisyouData.saigai_mei;
        } else {
          this.clearData();
        }
        this.getKanrisyaSV();
      });
    },
    getKanrisyaSV() {
      if (localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA) {
        this.kanrisyaList.push({
          kanrisya_cd: 0,
        });
        this.getRegistrantSV();
        this.isLoading = false;
        return;
      }
      var request_data = {
        kengen_cd: localStorage.getItem("kengen_cd"),
        kanrisya_cd: localStorage.getItem("Kanrisya_Cd"),
      };
      apiService
        .getKanrisyaByFlg(request_data)
        .then((result) => {
          this.kanrisyaList = result;
          //新規ボタンはいつでも押せるようにする。
          //this.kanrisya_all = this.kanrisya_cd == this.$constants.FA101.KANRISYA_ALL ? true : false;
          this.kanrisya_all = false;
          this.getRegistrantSV();
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((error) => {
          this.err = error;
        });
    },
    // 登録ユーザーリストを取得
    getRegistrantSV() {
      var request_data = [];
      if (localStorage.getItem("Kanrisya_Cd") === "null") {
        this.registrantList.push({
          login_id: localStorage.getItem("LOGIN_ID"),
          login_name: localStorage.getItem("LOGIN_NAME"),
        });
        return;
      } else {
        request_data = {
          kengen_cd: localStorage.getItem("kengen_cd"),
          kanrisya_cd: localStorage.getItem("Kanrisya_Cd"),
          login_id: localStorage.getItem("LOGIN_ID"),
        };
      }
      apiService
        .getRegistrantByFlg(request_data)
        .then((result) => {
          this.registrantList = result;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((error) => {
          this.err = error;
        });
    },
    checkKengenCdSV() {
      if (localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA) {
        this.genba_user = true;
      } else {
        this.genba_user = false;
      }
    },

    getGengo(year) {
      this.info = "";
      this.isHasError = false;
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param,
      };
      apiService.getGengo(request_data).then((result) => {
        this.jpYear = result;
      });
    },
    getSaigaiMeisyou(year) {
      var request_data = {
        year: year,
        kanrisya_cd: this.kanrisya_cd,
        kengen_cd: localStorage.getItem("kengen_cd"),
      };
      apiService.fa101GetSaigaiMeisyou(request_data).then((result) => {
        this.options = {};
        if (result.length > 0) {
          this.meisyouList = result;
          this.meisyouList.unshift({ saigai_yyyy: null, saigai_ken: null, saigai_mei: null, saigai_start: null, saigai_end: null });
          this.errorMsg = "";
          for (var i = 0; i < this.meisyouList.length; i++) {
            this.options[i] = this.meisyouList[i];
            if (this.isOptionSelected) {
              if (this.selectedOption == this.meisyouList[i].saigai_ken) {
                this.selectedIndex = i;
              }
            } else {
              this.selectedIndex = i;
            }
          }
          this.selectedOption = this.options[this.selectedIndex].saigai_ken;
          this.meisyouData = this.meisyouList[this.selectedIndex];
          if (this.selectedIndex === 0) {
            this.saigai_start = "";
            this.saigai_end = "";
          } else {
            this.saigai_start = this.formatDate(this.meisyouData.saigai_start);
            this.saigai_end = this.formatDate(this.meisyouData.saigai_end);
          }
          this.saigai_ken = this.meisyouData.saigai_ken;
          this.saigai_mei = this.meisyouData.saigai_mei;
        } else {
          this.clearData();
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      });
    },
    searchButton() {
      this.search(1);
    },
    search(page) {
      this.isLoading = true;
      var errFlg = false;
      if (this.kanrisya_cd == null || this.kanrisya_cd === "") {
        confirm(this.$messages.msg["0013E"]);
        this.kanrisyaErrorMsg = this.$messages.msg["0015E"];
        this.isHasKanrisyaNotFoundErr = true;
        this.isLoading = false;
        errFlg = true;
      }
      if (!errFlg) {
        if (this.meisyouList.length > 0) {
          if (typeof page == undefined) {
            page = 1;
          }
          this.page = page;
          this.totalAmount = 0;
          var request_data = {
            saigai_yyy: this.saigai_yyyy,
            saigai_ken: this.meisyouData.saigai_ken,
            kanrisya_cd: this.kanrisya_cd,
            valid_flg: this.valid_flg,
            page: page,
            searchFlg: this.searchFlg,
            status: this.status,
            login_id: this.login_id,
          };
          apiService.fa101Search(request_data).then((result) => {
            this.houkokuDataList = result.searchList;
            if (this.houkokuDataList.data.length > 0) {
              this.showTable = true;
              this.isHasError = false;
              this.checkedHoukokuData = this.houkokuDataList.data[0];
              this.totalAmount = this.checkedHoukokuData.total_haigai_gaku;
              this.markerList = new Array();
              this.showMap(result.itijohoList);
            } else {
              confirm(this.$messages.msg["0013E"]);
              this.isHasError = true;
              this.showTable = false;
              this.errorMsg = this.$messages.msg["0002E"];
            }
          });
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          this.isHasKenNotFoundErr = false;
        } else {
          confirm(this.$messages.msg["0013E"]);
          this.kenErrorMsg = this.$messages.msg["0004E"];
          this.isHasKenNotFoundErr = true;
          this.isLoading = false;
        }
      }
    },
    changedYear() {
      if (this.jpYear != "" && /^[a-zA-Z]+$/.test(this.jpYear.substr(0, 1))) {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace(/[^\d.]/g, "");
        var request_data = {
          gengo_cd: matches ? matches.toString() : "",
        };
        apiService.getGengoStartDate(request_data).then((result) => {
          if (result.length > 0) {
            var startDate = result[0].start_date;
            this.saigai_yyyy = parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);
            this.isOptionSelected = false;
            this.getSaigaiMeisyou(this.saigai_yyyy);
          } else {
            this.saigai_yyyy = "";
            this.clearData();
          }
        });
      } else {
        this.saigai_yyyy = "";
        this.clearData();
      }
      this.isYearNotFound = false;
      this.showTable = false;
      this.isHasKenNotFoundErr = false;
      this.isHasError = false;
    },
    clearData() {
      this.meisyouData = {};
      this.meisyouList = {};
      this.options = {};
      this.saigai_ken = "";
      this.saigai_mei = "";
      this.saigai_end = "";
      this.saigai_start = "";
      this.markerList = "";
      this.map = "";
      this.largeMap = "";
    },
    onChange() {
      this.isOptionSelected = true;
      this.getSaigaiMeisyou(this.saigai_yyyy);
    },
    kanrisyaChange() {
      this.isLoading = true;
      var kanrisya_id = this.kanrisyaList.filter((i) => i.kanrisya_cd == this.kanrisya_cd);
      this.kanrisya_mei = kanrisya_id[0].kanrisya_mei;
      /*   20200612
      if(this.kanrisya_cd == 0) {
          kanrisya_id = this.kanrisyaList.filter(i => i.kanrisya_cd == localStorage.getItem('Kanrisya_Cd'));
      }
      this.jigyou_cd = kanrisya_id[0].shicyou_kbn == this.$constants.JIGYOU_CD.JIGYOU_CD2 ? this.$constants.JIGYOU_CD.JIGYOU_CD2 : this.$constants.JIGYOU_CD.JIGYOU_CD1;
    20200612 */
      this.jigyou_cd = this.loginUser.shicyou_kbn == this.$constants.JIGYOU_CD.JIGYOU_CD2 || localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.$constants.JIGYOU_CD.JIGYOU_CD2 : this.$constants.JIGYOU_CD.JIGYOU_CD1;
      //新規ボタンはいつでも押せるようにする。
      //this.kanrisya_all = this.kanrisya_cd == this.$constants.FA101.KANRISYA_ALL ? true : false;
      this.kanrisya_all = false;
      // 登録ユーザ再取得
      var request_data = [];

      request_data = {
        // ログインユーザの権限にかかわらず、選択した管理者の下のユーザを取得する
        kengen_cd: localStorage.getItem("kengen_cd"),
        kanrisya_cd: kanrisya_id[0].kanrisya_cd,
        login_id: localStorage.getItem("LOGIN_ID"),
      };
      apiService
        .getRegistrantByFlg(request_data)
        .then((result) => {
          this.registrantList = result;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((error) => {
          this.err = error;
        });
    },
    registrantChange(registrant_cd) {
      var registrant_id = this.registrantList.filter((i) => i.login_id == registrant_cd);
      this.registrant_name = registrant_id[0].login_name;
    },
    goFA102(houkokuData) {
      this.checkedHoukokuData = houkokuData;
      this.setState();
      //this.jigyou_cd = this.loginUser.shicyou_kbn == "2" || localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.$constants.JIGYOU_CD.JIGYOU_CD2 : this.$constants.JIGYOU_CD.JIGYOU_CD1;
      // 選択した報告データから事業主体コードを取得
      this.jigyou_cd = this.checkedHoukokuData.jigyou_cd;
      this.$router.push({
        name: "FA102",
        params: 1,
        query: {
          param_kubun: 2,
          kbn: this.checkedHoukokuData.kbn,
          saigai_yyyy: this.saigai_yyyy,
          saigai_ken: this.saigai_ken,
          saigai_mei: this.saigai_mei,
          // login_id: this.checkedHoukokuData.login_id,
          // login_name: this.checkedHoukokuData.login_name,
          kousyu_cd: this.checkedHoukokuData.kousyu_cd,
          cd_value: this.checkedHoukokuData.jigyou_name,
          kanrisya_cd: this.checkedHoukokuData.kanrisya_cd,
          kanrisya_mei: this.checkedHoukokuData.kanrisya_mei,
          hisaibi: this.formatDate(this.checkedHoukokuData.hisaibi),
          seirinao: this.checkedHoukokuData.seirinao,
          jigyou_cd: this.jigyou_cd,
          valid_flg: this.checkedHoukokuData.valid_flg,
          status: this.checkedHoukokuData.status,
        },
      });
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    newHoukoku() {
      this.setState();
      if (this.jpYear == null || this.jpYear.trim() == "") {
        confirm(this.$messages.msg["0013E"]);
        this.isYearNotFound = true;
      } else {
        this.isYearNotFound = false;
      }
      if (this.saigai_ken == null || this.saigai_ken == "") {
        confirm(this.$messages.msg["0013E"]);
        this.isHasKenNotFoundErr = true;
        this.kenErrorMsg = this.$messages.msg["0005W"];
      } else {
        this.kenErrorMsg = this.$messages.msg["0005W"];
        this.isHasKenNotFoundErr = false;
      }
      if (!this.isYearNotFound && !this.isHasKenNotFoundErr) {
        //var kanrisya_id = this.kanrisyaList.filter(i => i.kanrisya_cd == this.kanrisya_cd);
        //this.jigyou_cd = kanrisya_id[0].shicyou_kbn == '2' ? this.$constants.JIGYOU_CD.JIGYOU_CD2 : this.$constants.JIGYOU_CD.JIGYOU_CD1;
        // 権限：被災現場確認の場合、事業区分が市町村工事
        this.jigyou_cd = this.loginUser.shicyou_kbn == "2" || localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA ? this.$constants.JIGYOU_CD.JIGYOU_CD2 : this.$constants.JIGYOU_CD.JIGYOU_CD1;
        this.$router.push({
          name: "FA102",
          params: {
            kanrisya_cd: this.loginUser.id,
          },
          query: {
            param_kubun: 1,
            kbn: this.$constants.FA101.NEW_KBN,
            saigai_yyyy: this.saigai_yyyy,
            saigai_ken: this.saigai_ken,
            saigai_mei: this.saigai_mei,
            kanrisya_cd: this.kanrisya_cd == 0 ? localStorage.getItem("Kanrisya_Cd") : this.kanrisya_cd,
            kanrisya_mei: this.kanrisya_cd == "0" ? localStorage.getItem("Kanrisya_Mei") : this.kanrisya_mei,
            login_id: this.login_id == 0 ? localStorage.getItem("LOGIN_ID") : this.login_id,
            login_name: this.login_id == 0 ? localStorage.getItem("LOGIN_NAME") : this.login_name,
            jigyou_cd: this.jigyou_cd,
            hisaibi: this.formatDate(this.saigai_start),
            valid_flg: 1,
          },
        });
      }
    },
    formatDate: function (date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    convKuniTan(kuni_tan) {
      var result = "";
      if (kuni_tan == "1") {
        result = "国";
      } else if (kuni_tan == "2") {
        result = "単";
      }
      return result;
    },
    getKanrisya() {
      if (localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA) {
        this.kanrisyaList.push({
          kanrisya_cd: 0,
        });
        this.getRegistrantSV();
        this.isLoading = false;
        return;
      }
      var request_data = {
        kengen_cd: localStorage.getItem("kengen_cd"),
        kanrisya_cd: localStorage.getItem("Kanrisya_Cd"),
      };
      apiService
        .getKanrisyaByFlg(request_data)
        .then((result) => {
          this.kanrisyaList = result;
          //新規ボタンはいつでも押せるようにする。
          //this.kanrisya_all = this.kanrisya_cd == this.$constants.FA101.KANRISYA_ALL ? true : false;
          this.kanrisya_all = false;
        })
        .catch((error) => {
          this.err = error;
        });
    },
    getRegistrant() {
      var request_data = [];
      if (localStorage.getItem("Kanrisya_Cd") === "null") {
        this.registrantList.push({
          login_id: localStorage.getItem("LOGIN_ID"),
          login_name: localStorage.getItem("LOGIN_NAME"),
        });
        return;
      } else {
        request_data = {
          kengen_cd: localStorage.getItem("kengen_cd"),
          kanrisya_cd: localStorage.getItem("Kanrisya_Cd"),
          login_id: localStorage.getItem("LOGIN_ID"),
        };
      }

      apiService
        .getRegistrantByFlg(request_data)
        .then((result) => {
          this.registrantList = result;
          //新規ボタンはいつでも押せるようにする。
          //this.kanrisya_all = this.kanrisya_cd == this.$constants.FA101.KANRISYA_ALL ? true : false;
          this.kanrisya_all = false;
        })
        .catch((error) => {
          this.err = error;
        });
    },
    checkKengenCd() {
      if (localStorage.getItem("kengen_cd") === this.$constants.KENGEN_CD.GENBA) {
        this.genba_user = true;
      } else {
        this.genba_user = false;
      }
    },
    convSaigaiKen(saigai_ken) {
      if (!saigai_ken) {
        return "";
      } else {
        return ("00000" + saigai_ken).slice(-5);
      }
    },
    authorityAdmin() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    setState() {
      this.$store.commit("fa101/setYear", this.saigai_yyyy);
      this.$store.commit("fa101/setKen", this.meisyouData.saigai_ken);
      this.$store.commit("fa101/setKanrisya", this.kanrisya_cd);
      this.$store.commit("fa101/setKanrisyaMei", this.kanrisya_mei);
      this.$store.commit("fa101/setLoginId", this.login_id);
      this.$store.commit("fa101/setLoginName", this.login_name);
      this.$store.commit("fa101/setValidFlg", this.valid_flg);
      this.$store.commit("fa101/setStatus", this.status);
      this.$store.commit("fa101/setPage", this.page);
    },
    async showMap(itijohoList) {
      this.markerList = itijohoList;
      // 位置情報存在チェック
      // true:位置情報が一つも存在しない、false:位置情報が一つでも存在する
      this.checkItijohoNotExist = true;
      for (var l = 0; l < this.markerList.length; l++) {
        if (this.markerList[l].itijoho !== null) {
          this.checkItijohoNotExist = false;
          break;
        }
      }
      if (this.checkItijohoNotExist) {
        this.isHasError = true;
        this.errorMsg = this.$messages.msg["0059W"];
      }
      var markerData = [];
      for (var i = 0; i < this.markerList.length; i++) {
        if (this.markerList[i].itijoho === null) {
          continue;
        }
        var latitude = parseFloat(this.markerList[i].itijoho.substring(0, this.markerList[i].itijoho.indexOf(",")));
        var longitude = parseFloat(this.markerList[i].itijoho.substring(this.markerList[i].itijoho.indexOf(",") + 1));
        var kousyu_name = this.markerList[i].kousyu_name;
        var kousyu_cd = this.markerList[i].kousyu_cd;
        var imageUrl1 = null;
        var imageUrl2 = null;
        var imageUrl3 = null;
        if (this.markerList[i].gazou1 !== null) {
          imageUrl1 = this.$constants.GAZOU_PATH + this.markerList[i].gazou1;
        }
        if (this.markerList[i].gazou2 !== null) {
          imageUrl2 = this.$constants.GAZOU_PATH + this.markerList[i].gazou2;
        }
        if (this.markerList[i].gazou3 !== null) {
          imageUrl3 = this.$constants.GAZOU_PATH + this.markerList[i].gazou3;
        }

        markerData.push({
          lat: latitude,
          lng: longitude,
          gazou1: imageUrl1,
          gazou2: imageUrl2,
          gazou3: imageUrl3,
          kousyu_name: kousyu_name,
          kousyu_cd: kousyu_cd,
        });
      }

      this.map = await initMap();

      async function initMap() {
        //@ts-ignore
        const { Map } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
        const { PinElement } = await window.google.maps.importLibrary("marker");

        window.mapLoaded = true;
        var myLatLng = "";
        if (markerData.length === 0) {
          myLatLng = new window.google.maps.LatLng({ lat: Number(36.34224731438585), lng: Number(140.44662369628952) });
        } else {
          myLatLng = new window.google.maps.LatLng({ lat: Number(markerData[0]["lat"]), lng: Number(markerData[0]["lng"]) }); // 緯度経度のデータ作成
        }

        // 地図の設定と作成
        // this.opts = "";
        var map = new Map(document.getElementById("mapArea"), {
          center: myLatLng,
          zoom: 16,
          mapId: "28ae36fad9a1de3a",
        });

        var markers = [];
        // マーカー毎の処理
        for (var j = 0; j < markerData.length; j++) {
          // 緯度経度のデータ作成
          var markerLatLng = new window.google.maps.LatLng({ lat: Number(markerData[j]["lat"]), lng: Number(markerData[j]["lng"]) });
          // マーカーのカラーを設定
          var backgroundcollor = "";
          var borderColor = "";
          var glyphColor = "";
          switch (markerData[j].kousyu_cd) {
            // 工種：河川
            case 1:
              backgroundcollor = "#4DC4FF";
              borderColor = "#4DC4FF";
              glyphColor = "#ffffff";
              break;
            // 工種：海岸
            case 2:
              backgroundcollor = "#005AFF";
              borderColor = "#005AFF";
              glyphColor = "#ffffff";
              break;
            // 工種：砂防設備
            case 3:
              backgroundcollor = "#F6AA00";
              borderColor = "#F6AA00";
              glyphColor = "#ffffff";
              break;
            // 工種：地すべり防止施設
            case 4:
              backgroundcollor = "#03AF7A";
              borderColor = "#03AF7A";
              glyphColor = "#ffffff";
              break;
            // 工種：急傾斜地崩壊防止施設
            case 5:
              backgroundcollor = "#FFF100";
              borderColor = "#FFF100";
              glyphColor = "#ffffff";
              break;
            // 工種：道路
            case 6:
              backgroundcollor = "#FF4B00";
              borderColor = "#FF4B00";
              glyphColor = "#ffffff";
              break;
            // 工種：橋梁
            case 7:
              backgroundcollor = "#84919E";
              borderColor = "#84919E";
              glyphColor = "#ffffff";
              break;
            // 工種：水道
            case 8:
              backgroundcollor = "#CC6677";
              borderColor = "#CC6677";
              glyphColor = "#ffffff";
              break;
            // 工種：下水道
            case 9:
              backgroundcollor = "#AA4499";
              borderColor = "#AA4499";
              glyphColor = "#ffffff";
              break;
            // 工種：公園
            case 10:
              backgroundcollor = "#000000";
              borderColor = "#000000";
              glyphColor = "#ffffff";
              break;
            default:
              backgroundcollor = "#3399F3";
              borderColor = "#3399F3";
              glyphColor = "#ffffff";
              break;
          }
          const pinViewBackground = new PinElement({
            background: backgroundcollor,
            borderColor: borderColor,
            glyphColor: glyphColor,
          });
          // マーカーの追加
          markers = new AdvancedMarkerElement({
            position: markerLatLng, // マーカーを立てる位置を指定
            map: map, // マーカーを立てる地図を指定
            content: pinViewBackground.element,
          });

          // 情報ウィンドウに表示する内容を作成
          var contentString = '<div style="width : 300px;height : 150px;">' + '<div id="siteNotice"></div>' + '<h4 id="firstHeading" class="firstHeading">' + markerData[j]["kousyu_name"] + "</h4>" + '<div id="bodyContent" class="row">';

          if (markerData[j]["gazou1"] !== null) {
            const gazou1 = '<p style="margin-left:20px;">画像1<br>' + '<img src="' + markerData[j]["gazou1"] + '" alt="" width="96" height="65"/></p>';
            contentString = contentString + gazou1;
          }
          if (markerData[j]["gazou2"] !== null) {
            const gazou2 = '<p style="margin-left:20px;">画像2<br>' + '<img src="' + markerData[j]["gazou2"] + '" alt="" width="96" height="65"/></p>';
            contentString = contentString + gazou2;
          }
          if (markerData[j]["gazou3"] !== null) {
            const gazou3 = '<p style="margin-left:20px;">画像3<br>' + '<img src="' + markerData[j]["gazou3"] + '" alt="" width="96" height="65"/></p>';
            contentString = contentString + gazou3;
          }
          const lastHTML = "</div>" + "</div>";
          contentString = contentString + lastHTML;

          // マーカーに対応した吹き出しをセット
          markerEvent(markers, contentString, map);
        }
        return map;
      }

      // マーカークリック時のイベント作成
      function markerEvent(markers, contentString, map) {
        var infowindow = [];
        infowindow = new window.google.maps.InfoWindow({
          content: contentString, // 吹き出しに表示する内容
        });
        markers.addListener("click", function () {
          // マーカーをクリックしたとき
          infowindow.open({
            anchor: markers,
            map: map,
          });
        });
      }
    },
    moveMap(itijoho) {
      if (itijoho === null) {
        return;
      }
      var latitude = parseFloat(itijoho.substring(0, itijoho.indexOf(",")));
      var longitude = parseFloat(itijoho.substring(itijoho.indexOf(",") + 1));

      this.map.panTo(new window.google.maps.LatLng(latitude, longitude));
    },
    viewLarger() {
      var markerList = this.markerList;
      var markerData = [];
      for (var i = 0; i < markerList.length; i++) {
        if (markerList[i].itijoho === null) {
          continue;
        }
        var latitude = parseFloat(markerList[i].itijoho.substring(0, markerList[i].itijoho.indexOf(",")));
        var longitude = parseFloat(markerList[i].itijoho.substring(markerList[i].itijoho.indexOf(",") + 1));
        var kousyu_name = markerList[i].kousyu_name;
        var kousyu_cd = markerList[i].kousyu_cd;
        var imageUrl1 = null;
        var imageUrl2 = null;
        var imageUrl3 = null;
        if (markerList[i].gazou1 !== null) {
          imageUrl1 = this.$constants.GAZOU_PATH + markerList[i].gazou1;
        }
        if (markerList[i].gazou2 !== null) {
          imageUrl2 = this.$constants.GAZOU_PATH + markerList[i].gazou2;
        }
        if (markerList[i].gazou3 !== null) {
          imageUrl3 = this.$constants.GAZOU_PATH + markerList[i].gazou3;
        }

        markerData.push({
          lat: latitude,
          lng: longitude,
          gazou1: imageUrl1,
          gazou2: imageUrl2,
          gazou3: imageUrl3,
          kousyu_name: kousyu_name,
          kousyu_cd: kousyu_cd,
        });
      }

      //ボタンクリックのイベント
      var myWindow;
      myWindow = window.open("", "myWindow", "width=800, height=800");
      myWindow.document.write("<div id='map' style = 'width : 100%;height : 100%;'></div>");

      // ヘッダに要素を追加します
      function load(src) {
        var head = myWindow.document.getElementsByTagName("head")[0];
        var script = myWindow.document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        head.appendChild(script);
      }

      // Google Map を Load します
      // document.write を定義します
      var nativeWrite = myWindow.document.write;
      myWindow.document.write = function (html) {
        var m = html.match(/script.+src="([^"]+)"/);
        if (m) {
          load(m[1]);
        } else {
          nativeWrite(html);
        }
      };

      load("https://maps.googleapis.com/maps/api/js?key=AIzaSyBXbD7jihEamvLDszKlaJigeq8CfU2WgaE&v=beta&callback=initMap&libraries=marker");

      const myLatLng = new window.google.maps.LatLng({ lat: Number(markerData[0]["lat"]), lng: Number(markerData[0]["lng"]) }); // 緯度経度のデータ作成
      // 地図の設定と作成
      this.opts = "";
      var largeMap = new window.google.maps.Map(myWindow.document.getElementById("map"), {
        center: myLatLng,
        zoom: 12,
        mapId: "28ae36fad9a1de3a",
      });

      var markers = [];
      // マーカー毎の処理
      for (var j = 0; j < markerData.length; j++) {
        // 緯度経度のデータ作成
        var markerLatLng = new window.google.maps.LatLng({ lat: Number(markerData[j]["lat"]), lng: Number(markerData[j]["lng"]) });
        // マーカーのカラーを設定
        var backgroundcollor = "";
        var borderColor = "";
        var glyphColor = "";
        switch (markerData[j].kousyu_cd) {
          // 工種：河川
          case 1:
            backgroundcollor = "#4DC4FF";
            borderColor = "#4DC4FF";
            glyphColor = "#ffffff";
            break;
          // 工種：海岸
          case 2:
            backgroundcollor = "#005AFF";
            borderColor = "#005AFF";
            glyphColor = "#ffffff";
            break;
          // 工種：砂防設備
          case 3:
            backgroundcollor = "#F6AA00";
            borderColor = "#F6AA00";
            glyphColor = "#ffffff";
            break;
          // 工種：地すべり防止施設
          case 4:
            backgroundcollor = "#03AF7A";
            borderColor = "#03AF7A";
            glyphColor = "#ffffff";
            break;
          // 工種：急傾斜地崩壊防止施設
          case 5:
            backgroundcollor = "#FFF100";
            borderColor = "#FFF100";
            glyphColor = "#ffffff";
            break;
          // 工種：道路
          case 6:
            backgroundcollor = "#FF4B00";
            borderColor = "#FF4B00";
            glyphColor = "#ffffff";
            break;
          // 工種：橋梁
          case 7:
            backgroundcollor = "#84919E";
            borderColor = "#84919E";
            glyphColor = "#ffffff";
            break;
          // 工種：水道
          case 8:
            backgroundcollor = "#CC6677";
            borderColor = "#CC6677";
            glyphColor = "#ffffff";
            break;
          // 工種：下水道
          case 9:
            backgroundcollor = "#AA4499";
            borderColor = "#AA4499";
            glyphColor = "#ffffff";
            break;
          // 工種：公園
          case 10:
            backgroundcollor = "#000000";
            borderColor = "#000000";
            glyphColor = "#ffffff";
            break;
          default:
            backgroundcollor = "#3399F3";
            borderColor = "#3399F3";
            glyphColor = "#ffffff";
            break;
        }
        const pinViewBackground = new window.google.maps.marker.PinElement({
          background: backgroundcollor,
          borderColor: borderColor,
          glyphColor: glyphColor,
        });
        // マーカーの追加
        markers = new window.google.maps.marker.AdvancedMarkerElement({
          position: markerLatLng, // マーカーを立てる位置を指定
          map: largeMap, // マーカーを立てる地図を指定
          content: pinViewBackground.element,
        });

        // 情報ウィンドウに表示する内容を作成
        var contentString =
          '<div id="content" style="width : 300px;height : 150px;">' +
          '<div id="siteNotice"></div>' +
          '<h4 id="firstHeading" class="firstHeading" style="margin:0px">' +
          markerData[j]["kousyu_name"] +
          "</h4>" +
          '<div id="bodyContent" style="display: flex;">';

        if (markerData[j]["gazou1"] !== null) {
          const gazou1 = '<div style="margin-left:20px;">画像1<br>' + '<img src="' + markerData[j]["gazou1"] + '" alt="" width="96" height="65"/></div>';
          contentString = contentString + gazou1;
        }
        if (markerData[j]["gazou2"] !== null) {
          const gazou2 = '<div style="margin-left:20px;">画像2<br>' + '<img src="' + markerData[j]["gazou2"] + '" alt="" width="96" height="65"/></div>';
          contentString = contentString + gazou2;
        }
        if (markerData[j]["gazou3"] !== null) {
          const gazou3 = '<div style="margin-left:20px;">画像3<br>' + '<img src="' + markerData[j]["gazou3"] + '" alt="" width="96" height="65"/></div>';
          contentString = contentString + gazou3;
        }
        const lastHTML = "</div>" + "</div>";
        contentString = contentString + lastHTML;

        // マーカーに対応した吹き出しをセット
        markerEvent(markers, contentString, largeMap);
      }
      function markerEvent(markers, contentString, map) {
        var infowindow = [];
        infowindow = new window.google.maps.InfoWindow({
          content: contentString, // 吹き出しに表示する内容
        });
        markers.addListener("click", function () {
          // マーカーをクリックしたとき
          infowindow.open({
            anchor: markers,
            map: map,
          });
        });
      }
    },
  },
};
</script>
