import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import fa101Module from "./modules/fa101.module";
import fa103Module from "./modules/fa103.module";
import fa104Module from "./modules/fa104.module";
import fb100Module from "./modules/fb100.module";
import fc100Module from "./modules/fc100.module";
import fd101Module from "./modules/fd101.module";

Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    fa101: fa101Module,
    fa103: fa103Module,
    fa104: fa104Module,
    fb100: fb100Module,
    fc100: fc100Module,
    fd101: fd101Module,
  },
  mutations: {},
  actions: {}
});
