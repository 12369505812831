<script>
import AppLayout from "@/components/layouts/Layout.vue";

export default {
  name: "honcyousyorimenu",
  components: {
    AppLayout,
  },
  data() {
    return {
      isTest:
        window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
    };
  },
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div
        class="container-fluid bg-info mb-3 pt-2 pb-2"
        :class="this.isTest ? 'test-bg-info' : ''"
      >
        <div class="container">
          <h3 class="text-white mb-0">本庁処理</h3>
        </div>
      </div>
      <div class="container mb-3 pt-3">
        <h4 class="text-primary">【帳票出力機能】</h4>
        <div
          class="d-flex flex-row flex-wrap"
          style="width: 100%; max-width: 800px"
        >
          <router-link
            class="btn btn-secondary btn-lg mr-3 mb-3"
            :to="{ name: 'FG202' }"
            role="button"
            >帳票出力</router-link
          >
        </div>
      </div>
    </main>
  </AppLayout>
</template>
