<script>
import Layout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { APIService } from "@/apiService.js";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();
var numeral = require("numeral");
export default {
  name: "FB101",
  components: {
    Layout,
    datepicker,
    Loading
  },
  data() {
    return {
      ja: ja,
      saigai_ken: "",
      jpYear: "",
      engYear: this.$route.query.saigai_yyyy,
      ikkan_no: this.$route.query.ikkan_no,
      koji_no: "",
      saigai_seirinao: "",
      seirinaos: {},
      seirinao: "",
      status: 1,
      status_check: false,
      status_text_off: "",
      status_text_on: "",
      kanrisyas: [],
      kanrisya_cd: "",
      kanrisya_mei: "",
      nji_satei: "",
      hisaibi: "",
      kousyus: [],
      kousyu_cd: "",
      kousyu_mei: "",
      jigyou_cd: localStorage.getItem("Kanrisya_Cd") == 1 ? 1 : 2,
      jigyou_name: "",
      shicyous: [],
      shicyou_cd: "",
      shicyou_mei: "",
      cyoazas: [],
      cyoaza_cd: "",
      cyoaza_mei: [],
      chisaki: "",
      hisaijyous: [],
      hisaijyou_cd: "",
      hisaijyou_mei: [],
      hisaijyou_etc: "",
      hisai_encyou: "",
      syokans: [],
      syokan_cd: this.$constants.FB101.DEFAULT_SYOKAN_CD,
      syokan_mei: "",
      kunitans: [],
      kuni_tan: "",
      kuni_mei: "",
      higai_gaku: "",
      saigai_kunis: [],
      saigai_kuni: "",
      saigai_kuni_mei: "",
      hans: [],
      han_cd: "",
      han_mei: "",
      michikubuns: [],
      rokasen_kbn: "",
      syubetus: [],
      rokasen_syubetu: "",
      rokasen_syubetu_mei: "",
      rokasens: [],
      rokasen_cd: "",
      rokasen_mei: "",
      rokasen_txt: "",
      rokasen_required: false,
      rokasen_chk: "1",
      rokyouryou_mei: "",
      ippans: [],
      itupanritou_kbn: 1,
      ippan_mei: "",
      fukyu_encyou: "",
      kasyos: [],
      sayus: [],
      kasyo1: "",
      kasyo1_mei: "",
      sayukisi1: "",
      sayukisi1_mei: "",
      encyou1: "",
      kasyo2: "",
      kasyo2_mei: "",
      sayukisi2: "",
      sayukisi2_mei: "",
      encyou2: "",
      kasyo3: "",
      kasyo3_mei: "",
      sayukisi3: "",
      sayukisi3_mei: "",
      encyou3: "",
      kasyo4: "",
      kasyo4_mei: "",
      sayukisi4: "",
      sayukisi4_mei: "",
      encyou4: "",
      kasyo5: "",
      kasyo5_mei: "",
      sayukisi5: "",
      sayukisi5_mei: "",
      encyou5: "",
      youkous: [],
      youkou: "",
      youkou2: "",
      youkou3: "",
      max_hisaiencyou: 10,
      max_hisaijyou_etc: 100,
      max_fukyu: 10,
      max_encyou1: 10,
      max_encyou2: 10,
      max_encyou3: 10,
      max_encyou4: 10,
      max_encyou5: 10,
      hisaijyou_sonota_selected: false,
      isNumFormat: false,
      numFormatErr: "",
      info: "",
      textError: "",
      showInfo: false,
      showError: false,
      errors: {
        status: null,
        kanrisya_cd: null,
        hisaibi: null,
        kousyu_cd: null,
        shicyou_cd: null,
        cyoaza_cd: null,
        hisaijyou_etc: null,
        hisai_encyou: null,
        saigai_kuni: null,
        han_cd: null,
        fukyu_encyou: null,
        encyou: null,
        rokyouryou_mei: null,
        youkou: null,
        rokasen_cd: null,
        rokasen_txt: null,
      },
      showFlg: true,
      isLoading: false,
      fullPage: true,
      bg_required: false,
      chk_date: ""
    };
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getGengo(this.engYear);
    this.showDetail();
  },
  watch: {
    encyou1: function(encyou1) {
      if (encyou1 != null) {
        // console.log(heikin_fusoku.length);
        var e1 = encyou1
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (e1 !== encyou1) {
          // Only if something had to be fixed, update
          encyou1 = e1;
        }
        this.max_encyou1 = encyou1.indexOf(".") > 0 ? 11 : 10;
      }
    },
    encyou2: function(encyou2) {
      if (encyou2 != null) {
        // console.log(heikin_fusoku.length);
        var e2 = encyou2
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (e2 !== encyou2) {
          // Only if something had to be fixed, update
          encyou2 = e2;
        }
        this.max_encyou2 = encyou2.indexOf(".") > 0 ? 11 : 10;
      }
    },
    encyou3: function(encyou3) {
      if (encyou3 != null) {
        // console.log(heikin_fusoku.length);
        var e3 = encyou3
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (e3 !== encyou3) {
          // Only if something had to be fixed, update
          encyou3 = e3;
        }
        this.max_encyou3 = encyou3.indexOf(".") > 0 ? 11 : 10;
      }
    },
    encyou4: function(encyou4) {
      if (encyou4 != null) {
        // console.log(heikin_fusoku.length);
        var e4 = encyou4
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (e4 !== encyou4) {
          // Only if something had to be fixed, update
          encyou4 = e4;
        }
        this.max_encyou4 = encyou4.indexOf(".") > 0 ? 11 : 10;
      }
    },
    encyou5: function(encyou5) {
      if (encyou5 != null) {
        // console.log(heikin_fusoku.length);
        var e5 = encyou5
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (e5 !== encyou5) {
          // Only if something had to be fixed, update
          encyou5 = e5;
        }
        this.max_encyou5 = encyou5.indexOf(".") > 0 ? 11 : 10;
      }
    },
    fukyu_encyou: function(fukyu_encyou) {
      if (fukyu_encyou != null) {
        // console.log(heikin_fusoku.length);
        var fukyuencyou = fukyu_encyou
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (fukyuencyou !== fukyu_encyou) {
          // Only if something had to be fixed, update
          fukyu_encyou = fukyuencyou;
        }
        this.max_fukyu = fukyu_encyou.indexOf(".") > 0 ? 11 : 10;
      }
    },
    hisai_encyou: function(hisai_encyou) {
      if (hisai_encyou != null) {
        // console.log(heikin_fusoku.length);
        var hisaiencyou = hisai_encyou
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (hisaiencyou !== hisai_encyou) {
          // Only if something had to be fixed, update
          hisai_encyou = hisaiencyou;
        }
        this.max_hisaiencyou = hisai_encyou.indexOf(".") > 0 ? 11 : 10;
      }
    }
  },
  methods: {
    formatDate: function(date) {
      if (date != "" && date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
      return "";
    },
    formatNumber: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0") == 0
        ? ""
        : numeral(number).format("0,0");
    },
    formatNumeric: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
    removeComma: function(number) {
      return number == "" || number == null
        ? number
        : number.toString().replace(/,/g, "");
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
      });
    },
    showDetail() {
      var request_data = {
        year: this.engYear,
        ikkan_no: this.ikkan_no,
        login_kanrisya: localStorage.getItem('Kanrisya_Cd'),
        shicyou_kbn: localStorage.getItem("shicyou_kbn"),
      };
      apiService.fb101Detail(request_data).then(result => {
        console.log(result);
        if (result != null) {
          for (var a in result.kanrisya) {
            this.kanrisyas.push({
              value: result.kanrisya[a].kanrisya_mei,
              key: result.kanrisya[a].kanrisya_cd
            });
          }
          for (var b in result.saigai_meisyou) {
            this.saigai_kunis.push({
              value: result.saigai_meisyou[b].saigai_mei,
              key: result.saigai_meisyou[b].saigai_kuni
            });
          }
          for (var c in result.kousyu) {
            this.kousyus.push({
              value: result.kousyu[c].cd_value,
              key: result.kousyu[c].meisai
            });
          }
          var kotuko_shicyou_exist = false;
          for (var d in result.shcyouson) {
            this.shicyous.push({
              value: result.shcyouson[d].shicyou_mei,
              key: result.shcyouson[d].shicyou_cd
            });
            if (result.kotuko_sinsei.length > 0 
                && result.kotuko_sinsei[0].shicyou_cd == result.shcyouson[d].shicyou_cd){
                  kotuko_shicyou_exist = true;
            }
          }
          // 上位権限のユーザが現ユーザで選択できない市町村を設定した場合、
          // リストにその市町村を追加する。
          if(!kotuko_shicyou_exist){
            this.shicyous.push({
              value: result.kotuko_sinsei[0].shicyou_name,
              key: result.kotuko_sinsei[0].shicyou_cd
            });
          }
          for (var e in result.syokan) {
            this.syokans.push({
              value: result.syokan[e].cd_value,
              key: result.syokan[e].meisai
            });
          }
          for (var f in result.kunitan) {
            this.kunitans.push({
              value: result.kunitan[f].cd_value,
              key: result.kunitan[f].meisai
            });
          }
          for (var g in result.michikubun) {
            this.michikubuns.push({
              value: result.michikubun[g].cd_value,
              key: result.michikubun[g].meisai
            });
          }
          for (var h in result.han) {
            this.hans.push({
              value: result.han[h].cd_value,
              key: result.han[h].meisai
            });
          }
          for (var i in result.ippan) {
            this.ippans.push({
              value: result.ippan[i].cd_value,
              key: result.ippan[i].meisai
            });
          }
          for (var j in result.youkou) {
            this.youkous.push({
              value: result.youkou[j].cd_value,
              key: result.youkou[j].meisai
            });
          }
          for (var k in result.sayu) {
            this.sayus.push({
              value: result.sayu[k].cd_value,
              key: result.sayu[k].meisai
            });
          }
          for (var l in result.kasyo) {
            this.kasyos.push({
              value: result.kasyo[l].cd_value,
              key: result.kasyo[l].meisai
            });
          }

          if (result.kotuko_sinsei.length > 0) {
            if (result.kotuko_sinsei[0].saigai_kuni) {
              var request_data = {
                saigai_kuni: this.saigai_kuni,
                saigai_yyyy: this.engYear
              };
              apiService.fb101GetSeirinao(request_data).then(result => {
                for (var k in result) {
                  this.seirinaos[k] = result[k].seirinao;
                }
              });
            }
            if (result.kotuko_sinsei[0].kousyu_cd) {
              var hisai_data = {
                kousyu_cd: result.kotuko_sinsei[0].kousyu_cd
              };
              apiService.fb101GetHisaijoumei(hisai_data).then(result => {
                for (var h in result) {
                  this.hisaijyous.push({
                    value: result[h].hisaijyou_mei,
                    key: result[h].hisaijyou_cd
                  });
                }
              });
            }
            if (result.kotuko_sinsei[0].shicyou_cd) {
              var cyouaza_data = {
                shicyou_cd: result.kotuko_sinsei[0].shicyou_cd
              };
              apiService.fb101GetCyouazamei(cyouaza_data).then(result => {
                for (var c in result) {
                  this.cyoazas.push({
                    value: result[c].cyouaza_mei,
                    key: result[c].cyouaza_cd
                  });
                }
              });
            }
            if (result.kotuko_sinsei[0].rokasen_kbn) {
              var shubetu_data = {
                rokasen_kbn: result.kotuko_sinsei[0].rokasen_kbn
              };
              apiService.fb101GetSyubetu(shubetu_data).then(result => {
                for (var m in result) {
                  this.syubetus.push({
                    value: result[m].kasen_syubetumei,
                    key: result[m].kasen_syubetu
                  });
                }
              });
            }

            if (result.kotuko_sinsei[0].rokasen_syubetu) {
              var rokasen_data = {
                rokasen_kbn: result.kotuko_sinsei[0].rokasen_kbn,
                rokasen_syubetu: result.kotuko_sinsei[0].rokasen_syubetu
              };
              apiService.fb101GetRokasen(rokasen_data).then(result => {
                console.log(result)
                if (result != null && result.length > 0) {
                  for (var n in result) {
                    this.rokasens.push({
                      value: result[n].rokasen_mei,
                      key: result[n].kasen_cd
                    });
                  }
                  this.rokasen_required = true;
                }else{
                  this.rokasen_required = false;
                }

              });
            }

            this.engYear = result.kotuko_sinsei[0].saigai_yyyy;
            this.getGengo(this.engYear);
            this.nji_satei = result.kotuko_sinsei[0].nji_satei;
            this.koji_no = result.kotuko_sinsei[0].koji_no;
            this.kanrisya_cd = result.kotuko_sinsei[0].kanrisya_cd;
            this.kanrisya_mei = result.kotuko_sinsei[0].kanrisya_mei;
            this.jigyou_cd = result.kotuko_sinsei[0].jigyou_cd;
            this.jigyou_name = result.kotuko_sinsei[0].jigyou_name;
            this.saigai_kuni = result.kotuko_sinsei[0].saigai_kuni;
            this.saigai_kuni_mei = result.kotuko_sinsei[0].saigai_mei;
            this.saigai_seirinao = result.kotuko_sinsei[0].saigai_seirinao;
            this.seirinao = result.kotuko_sinsei[0].saigai_seirinao;
            this.hisaibi = this.formatDate(result.kotuko_sinsei[0].hisaibi);
            this.kousyu_cd = result.kotuko_sinsei[0].kousyu_cd;
            this.kousyu_mei = result.kotuko_sinsei[0].kousyu_name;
            this.shicyou_cd = result.kotuko_sinsei[0].shicyou_cd;
            this.shicyou_mei = result.kotuko_sinsei[0].shicyou_name;
            this.cyoaza_cd = result.kotuko_sinsei[0].cyoaza_cd;
            this.cyoaza_mei = result.kotuko_sinsei[0].cyoaza_name;
            this.hisaijyou_cd = result.kotuko_sinsei[0].hisaijyou_cd;
            this.hisaijyou_mei = result.kotuko_sinsei[0].hisaijyou_mei;
            this.hisaijyou_etc = result.kotuko_sinsei[0].hisaijyou_etc;
            this.hisaijyou_sonota_selected = this.hisaijyou_cd == this.$constants.FA102.HISAIJYOU_CD_OTHER ? true : false;
            this.chisaki = result.kotuko_sinsei[0].chisaki;
            this.hisai_encyou = this.formatNumeric(
              result.kotuko_sinsei[0].hisai_encyou
            );
            this.higai_gaku = this.formatNumber(
              result.kotuko_sinsei[0].higai_gaku
            );
            this.syokan_mei = result.kotuko_sinsei[0].syokan_name;
            this.kuni_tan = result.kotuko_sinsei[0].kuni_tan;
            this.kuni_mei = result.kotuko_sinsei[0].kuni_name;
            this.rokasen_kbn = result.kotuko_sinsei[0].rokasen_kbn;
            this.michikubun_mei = result.kotuko_sinsei[0].rokasen_name;
            this.rokasen_syubetu = result.kotuko_sinsei[0].rokasen_syubetu;
            this.rokasen_syubetu_mei = result.kotuko_sinsei[0].kasen_syubetumei;
            //this.rokasen_cd = result.kotuko_sinsei[0].rokasen_cd;
            //this.rokasen_mei = result.kotuko_sinsei[0].rokasen_mei;
            this.rokyouryou_mei = result.kotuko_sinsei[0].rokyouryou_mei;
            this.han_cd = result.kotuko_sinsei[0].han_cd;
            this.han_mei = result.kotuko_sinsei[0].han_name;
            this.ippan_mei = result.kotuko_sinsei[0].itupanritou_name;
            this.fukyu_encyou = this.formatNumeric(
              result.kotuko_sinsei[0].fukyu_encyou
            );
            this.youkou = result.kotuko_sinsei[0].youkou;
            this.youkou2 = result.kotuko_sinsei[0].youkou2;
            this.youkou3 = result.kotuko_sinsei[0].youkou3;
            this.status = result.kotuko_sinsei[0].status;
            this.status_check = this.status == 2 ? true : false;
            this.statusCheck();
            if (!result.kotuko_sinsei[0].rokasen_cd) {
              this.rokasen_txt = result.kotuko_sinsei[0].rokasen_mei;
              this.rokasen_mei = result.kotuko_sinsei[0].rokasen_mei;
              if(!result.kotuko_sinsei[0].rokasen_mei){
                this.rokasen_chk = "1";
              } else {
                this.rokasen_chk = "2";
              }
            } else {
              this.rokasen_cd = result.kotuko_sinsei[0].rokasen_cd;
              this.rokasen_mei = result.kotuko_sinsei[0].rokasen_mei;
              this.rokasen_chk = "1";
            }
            if (result.kotuko_sinsei[0].kasyo1) {
              this.kasyo1 = result.kotuko_sinsei[0].kasyo1;
              this.kasyo1_mei = this.kasyos.filter(
                i => i.key == this.kasyo1
              )[0].value;
            }
            if (result.kotuko_sinsei[0].kasyo2) {
              this.kasyo2 = result.kotuko_sinsei[0].kasyo2;
              this.kasyo2_mei = this.kasyos.filter(
                i => i.key == this.kasyo2
              )[0].value;
            }
            if (result.kotuko_sinsei[0].kasyo3) {
              this.kasyo3 = result.kotuko_sinsei[0].kasyo3;
              this.kasyo3_mei = this.kasyos.filter(
                i => i.key == this.kasyo3
              )[0].value;
            }
            if (result.kotuko_sinsei[0].kasyo4) {
              this.kasyo4 = result.kotuko_sinsei[0].kasyo4;
              this.kasyo4_mei = this.kasyos.filter(
                i => i.key == this.kasyo4
              )[0].value;
            }

            if (result.kotuko_sinsei[0].kasyo5) {
              this.kasyo5 = result.kotuko_sinsei[0].kasyo5;
              this.kasyo5_mei = this.kasyos.filter(
                i => i.key == this.kasyo5
              )[0].value;
            }
            if (result.kotuko_sinsei[0].sayukisi1) {
              this.sayukisi1 = result.kotuko_sinsei[0].sayukisi1;
              this.sayukisi1_mei = this.sayus.filter(
                i => i.key == this.sayukisi1
              )[0].value;
            }
            if (result.kotuko_sinsei[0].sayukisi2) {
              this.sayukisi2 = result.kotuko_sinsei[0].sayukisi2;
              this.sayukisi2_mei = this.sayus.filter(
                i => i.key == this.sayukisi2
              )[0].value;
            }
            if (result.kotuko_sinsei[0].sayukisi3) {
              this.sayukisi3 = result.kotuko_sinsei[0].sayukisi3;
              this.sayukisi3_mei = this.sayus.filter(
                i => i.key == this.sayukisi3
              )[0].value;
            }
            if (result.kotuko_sinsei[0].sayukisi4) {
              this.sayukisi4 = result.kotuko_sinsei[0].sayukisi4;
              this.sayukisi4_mei = this.sayus.filter(
                i => i.key == this.sayukisi4
              )[0].value;
            }
            if (result.kotuko_sinsei[0].sayukisi5) {
              this.sayukisi5 = result.kotuko_sinsei[0].sayukisi5;
              this.sayukisi5_mei = this.sayus.filter(
                i => i.key == this.sayukisi5
              )[0].value;
            }
            if(this.kousyu_cd == 7){
              this.bg_required = true;
            }else{
              this.bg_required = false;
            }
            this.encyou1 = this.formatNumeric(result.kotuko_sinsei[0].encyou1);
            this.encyou2 = this.formatNumeric(result.kotuko_sinsei[0].encyou2);
            this.encyou3 = this.formatNumeric(result.kotuko_sinsei[0].encyou3);
            this.encyou4 = this.formatNumeric(result.kotuko_sinsei[0].encyou4);
            this.encyou5 = this.formatNumeric(result.kotuko_sinsei[0].encyou5);
          }
        }

        this.chk_date = result.chk_date; // 排他チェック用
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    saigaiChange(saigai_kuni) {
      this.saigai_kuni = saigai_kuni;
      if (this.saigai_kuni) {
        this.saigai_kuni_mei = this.saigai_kunis.filter(
          i => i.key == saigai_kuni
        )[0].value;
      }
      // this.getSeirinao();
    },
    // getSeirinao() {
    //   var request_data = {
    //     saigai_kuni: this.saigai_kuni,
    //     saigai_yyyy: this.engYear,
    //     kanrisya_cd: parseInt(this.kanrisya_cd)
    //   };
    //   console.log(request_data);
    //   apiService.fb101GetSeirinao(request_data).then(result => {
    //     console.log(result);
    //     this.seirinaos = {};
    //     this.saigai_seirinao = "";
    //     for (var k in result) {
    //       this.seirinaos[k] = result[k].seirinao;
    //     }
    //   });
    // },
    kanrisyaChange(kanrisya_cd) {
      this.kanrisya_cd = kanrisya_cd;
      this.kanrisya_mei = this.kanrisyas.filter(
        i => i.key == kanrisya_cd
      )[0].value;
    },
    kousyuChange(kousyu_cd) {
      this.kousyu_cd = kousyu_cd;
      if (this.kousyu_cd) {
        this.kousyu_mei = this.kousyus.filter(i => i.key == kousyu_cd)[0].value;
      }
      if(this.kousyu_cd == 7){
        this.bg_required = true;
      }else{
        this.bg_required = false;
      }
      this.getHisaijoumei();
    },
    getHisaijoumei() {
      var request_data = {
        kousyu_cd: this.kousyu_cd
      };
      apiService.fb101GetHisaijoumei(request_data).then(result => {
        this.hisaijyous = [];
        this.hisaijyou = "";
        this.hisaijyou_mei = "";
        for (var h in result) {
          this.hisaijyous.push({
            value: result[h].hisaijyou_mei,
            key: result[h].hisaijyou_cd
          });
        }
      });
    },
    shicyouChange(shicyou_cd) {
      this.shicyou_cd = shicyou_cd;
      if (this.shicyou_cd) {
        this.shicyou_mei = this.shicyous.filter(
          i => i.key == shicyou_cd
        )[0].value;
      }
      this.getCyouazamei();
    },
    getCyouazamei() {
      var request_data = {
        shicyou_cd: this.shicyou_cd
      };
      apiService
        .fb101GetCyouazamei(request_data)
        .then(result => {
          this.cyoazas = [];
          this.cyoaza_cd = "";
          this.cyoaza_mei = "";
          for (var c in result) {
            this.cyoazas.push({
              value: result[c].cyouaza_mei,
              key: result[c].cyouaza_cd
            });
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    hisaijyouChange(hisaijyou_cd) {
      this.hisaijyou_cd = hisaijyou_cd;
      if (this.hisaijyou_cd) {
        this.hisaijyou_mei = this.hisaijyous.filter(
          i => i.key == hisaijyou_cd
        )[0].value;
      }
      this.hisaijyou_sonota_selected = this.hisaijyou_cd == this.$constants.FA102.HISAIJYOU_CD_OTHER ? true : false;
    },
    cyoazaChange(cyoaza_cd) {
      this.cyoaza_cd = cyoaza_cd;
      if (this.cyoaza_cd) {
        this.cyoaza_mei = this.cyoazas.filter(i => i.key == cyoaza_cd)[0].value;
      }
    },
    kunitanChange(kuni_tan) {
      this.kuni_tan = kuni_tan;
      if (this.kuni_tan) {
        this.kuni_mei = this.kunitans.filter(i => i.key == kuni_tan)[0].value;
      }
    },
    michikubunChange(rokasen_kbn) {
      this.rokasen_kbn = rokasen_kbn;
      if (this.rokasen_kbn) {
        this.michikubun_mei = this.michikubuns.filter(
          i => i.key == rokasen_kbn
        )[0].value;
      }
      this.getShubetu();
    },
    getShubetu() {
      var request_data = {
        rokasen_kbn: this.rokasen_kbn
      };
      apiService.fb101GetSyubetu(request_data).then(result => {
        this.syubetus = [];
        this.rokasen_syubetu = "";
        this.rokasen_syubetu_mei = "";
        this.rokasens = [];
        this.rokasen_cd = "";
        this.rokasen_mei = "";
        for (var m in result) {
          this.syubetus.push({
            value: result[m].kasen_syubetumei,
            key: result[m].kasen_syubetu
          });
        }
      });
    },
    syubetuChange(rokasen_syubetu) {
      this.rokasen_syubetu = rokasen_syubetu;
      if (this.rokasen_syubetu) {
        this.rokasen_syubetu_mei = this.syubetus.filter(
          i => i.key == rokasen_syubetu
        )[0].value;
        this.getRokasen();
      }
    },
    getRokasen() {
      var request_data = {
        rokasen_kbn: this.rokasen_kbn,
        rokasen_syubetu: this.rokasen_syubetu
      };
      apiService.fb101GetRokasen(request_data).then(result => {
        this.rokasens = [];
        this.rokasen_cd = "";
        this.rokasen_mei = "";
        for (var n in result) {
          this.rokasens.push({
            value: result[n].rokasen_mei,
            key: result[n].kasen_cd
          });
        }
      });
    },
    rokasenChange(rokasen_cd) {
      this.rokasen_cd = rokasen_cd;
      if (this.rokasen_cd) {
        this.rokasen_mei = this.rokasens.filter(
          i => i.key == rokasen_cd
        )[0].value;
      }
    },
    hanChange(han_cd) {
      this.han_cd = han_cd;
      if (this.han_cd) {
        this.han_mei = this.hans.filter(i => i.key == han_cd)[0].value;
      }
    },
    youkouChange(youkou) {
      this.youkou = youkou;
    },
    youkou2Change(youkou2) {
      this.youkou2 = youkou2;
    },
    youkou3Change(youkou3) {
      this.youkou3 = youkou3;
    },
    kasyo1Change(kasyo1) {
      this.kasyo1 = kasyo1;
      this.kasyo1_mei = this.kasyos.filter(i => i.key == kasyo1)[0].value;
    },
    kasyo2Change(kasyo2) {
      this.kasyo2 = kasyo2;
      this.kasyo2_mei = this.kasyos.filter(i => i.key == kasyo2)[0].value;
    },
    kasyo3Change(kasyo3) {
      this.kasyo3 = kasyo3;
      this.kasyo3_mei = this.kasyos.filter(i => i.key == kasyo3)[0].value;
    },
    kasyo4Change(kasyo4) {
      this.kasyo4 = kasyo4;
      this.kasyo4_mei = this.kasyos.filter(i => i.key == kasyo4)[0].value;
    },
    kasyo5Change(kasyo5) {
      this.kasyo5 = kasyo5;
      this.kasyo5_mei = this.kasyos.filter(i => i.key == kasyo5)[0].value;
    },
    sayukisi1Change(sayukisi1) {
      this.sayukisi1 = sayukisi1;
      if(this.sayukisi1){
        this.sayukisi1_mei = this.sayus.filter(i => i.key == sayukisi1)[0].value;
      }
    },
    sayukisi2Change(sayukisi2) {
      this.sayukisi2 = sayukisi2;
      if(this.sayukisi2){
        this.sayukisi2_mei = this.sayus.filter(i => i.key == sayukisi2)[0].value;
      }
    },
    sayukisi3Change(sayukisi3) {
      this.sayukisi3 = sayukisi3;
      if(this.sayukisi3){
        this.sayukisi3_mei = this.sayus.filter(i => i.key == sayukisi3)[0].value;
      }
    },
    sayukisi4Change(sayukisi4) {
      this.sayukisi4 = sayukisi4;
      if(this.sayukisi4){
        this.sayukisi4_mei = this.sayus.filter(i => i.key == sayukisi4)[0].value;
      }
    },
    sayukisi5Change(sayukisi5) {
      this.sayukisi5 = sayukisi5;
      if(this.sayukisi5){
        this.sayukisi5_mei = this.sayus.filter(i => i.key == sayukisi5)[0].value;
      }
    },
    encyou1Change(encyou1) {
      this.encyou1 = this.replaceComma(encyou1);
      if (this.isFloat(this.encyou1)) {
        this.encyou1 = this.encyou1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou1RemoveComma() {
      this.encyou1 = this.replaceComma(this.encyou1);
    },
    encyou2Change(encyou2) {
      this.encyou2 = this.replaceComma(encyou2);
      if (this.isFloat(this.encyou2)) {
        this.encyou2 = this.encyou2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou2RemoveComma() {
      this.encyou2 = this.replaceComma(this.encyou2);
    },
    encyou3Change(encyou3) {
      this.encyou3 = this.replaceComma(encyou3);
      if (this.isFloat(this.encyou3)) {
        this.encyou3 = this.encyou3
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou3RemoveComma() {
      this.encyou3 = this.replaceComma(this.encyou3);
    },
    encyou4Change(encyou4) {
      this.encyou4 = this.replaceComma(encyou4);
      if (this.isFloat(this.encyou4)) {
        this.encyou4 = this.encyou4
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou4RemoveComma() {
      this.encyou4 = this.replaceComma(this.encyou4);
    },
    encyou5Change(encyou5) {
      this.encyou5 = this.replaceComma(encyou5);
      if (this.isFloat(this.encyou5)) {
        this.encyou5 = this.encyou5
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou5RemoveComma() {
      this.encyou5 = this.replaceComma(this.encyou5);
    },
    hisaiChange(hisai_encyou) {
      this.hisai_encyou = this.replaceComma(hisai_encyou);
      if (this.isFloat(this.hisai_encyou)) {
        this.hisai_encyou = this.hisai_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    hisaiReplaceComma() {
      this.hisai_encyou = this.replaceComma(this.hisai_encyou);
    },
    fukyuChange(fukyu_encyou) {
      this.fukyu_encyou = this.replaceComma(fukyu_encyou);
      if (this.isFloat(this.fukyu_encyou)) {
        this.fukyu_encyou = this.fukyu_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    fukyuRemoveComma() {
      this.fukyu_encyou = this.replaceComma(this.fukyu_encyou);
    },
    gakuChange(higai_gaku) {
      this.higai_gaku = this.replaceComma(higai_gaku);
      if (this.isInteger(this.higai_gaku)) {
        this.higai_gaku = this.higai_gaku
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    gakuRemoveComma() {
      this.higai_gaku = this.replaceComma(this.higai_gaku);
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    update_btn() {
      this.showInfo = false;
      this.showError = false;
      this.numFormatErr = false;
      if(!this.koji_no) {
        confirm(this.$messages.msg['0013E']);
        this.showError = true;
        this.textError = "工事番号" + this.$messages.msg["00013W"];
      } else {
        this.numericFormatCheck();
      }
      if (!this.isNumFormat && !this.showError) {
        var request_data = {
          saigai_yyyy: this.engYear,
          ikkan_no: parseInt(this.ikkan_no),
          koji_no: this.koji_no,
          status: this.status,
          saigai_kuni: this.saigai_kuni,
          kanrisya_cd: parseInt(this.kanrisya_cd),
          nji_satei: this.nji_satei,
          hisaibi: this.formatDate(this.hisaibi),
          kousyu_cd: parseInt(this.kousyu_cd),
          jigyou_cd: this.jigyou_cd,
          shicyou_cd: parseInt(this.shicyou_cd),
          cyoaza_cd: parseInt(this.cyoaza_cd),
          chisaki: this.chisaki,
          hisaijyou_cd: parseInt(this.hisaijyou_cd),
          hisaijyou_etc: this.hisaijyou_etc,
          hisai_encyou: this.removeComma(this.hisai_encyou),
          syokan_cd: parseInt(this.syokan_cd),
          kuni_tan: this.kuni_tan,
          higai_gaku: this.removeComma(this.higai_gaku),
          han_cd: this.han_cd,
          rokasen_kbn: this.rokasen_kbn,
          rokasen_syubetu: this.rokasen_syubetu,
          rokasen_cd: this.rokasen_cd,
          rokasen_mei: this.rokasen_mei,
          rokasen_txt: this.rokasen_txt,
          rokasen_chk: this.rokasen_chk,
          rokyouryou_mei: this.rokyouryou_mei,
          itupanritou_kbn: this.itupanritou_kbn,
          fukyu_encyou: this.removeComma(this.fukyu_encyou),
          kasyo1: this.kasyo1,
          sayukisi1: this.sayukisi1,
          encyou1: this.removeComma(this.encyou1),
          kasyo2: this.kasyo2,
          sayukisi2: this.sayukisi2,
          encyou2: this.removeComma(this.encyou2),
          kasyo3: this.kasyo3,
          sayukisi3: this.sayukisi3,
          encyou3: this.removeComma(this.encyou3),
          kasyo4: this.kasyo4,
          sayukisi4: this.sayukisi4,
          encyou4: this.removeComma(this.encyou4),
          kasyo5: this.kasyo5,
          sayukisi5: this.sayukisi5,
          encyou5: this.removeComma(this.encyou5),
          youkou: this.youkou,
          youkou2: this.youkou2,
          youkou3: this.youkou3,
          saigai_seirinao: this.saigai_seirinao,
          loginId: localStorage.getItem("LOGIN_ID"),
          chk_date: this.chk_date
        };
        console.log(request_data);
        apiService
          .fb101Update(request_data)
          .then(result => {
            this.showInfo = true;
            this.showError = false;
            //this.info = result.msg;
            this.chk_date = result.chk_date;
            confirm(result.msg);
            setTimeout(() => {
              this.return_fb100();
            }, 1500); 
          })
          .catch(error => {
            console.log(error);
            if (typeof error == "string") {
              confirm(this.$messages.msg['0013E']);
              this.showError = true;
              this.textError = error;
            } else {
              this.showError = false;
              this.errors.status = error.errors.status
                ? error.errors.status[0]
                : "";
              this.errors.kanrisya_cd = error.errors.kanrisya_cd
                ? error.errors.kanrisya_cd[0]
                : "";
              this.errors.hisaibi = error.errors.hisaibi
                ? error.errors.hisaibi[0]
                : "";
              this.errors.kousyu_cd = error.errors.kousyu_cd
                ? error.errors.kousyu_cd[0]
                : "";
              this.errors.shicyou_cd = error.errors.shicyou_cd
                ? error.errors.shicyou_cd[0]
                : "";
              this.errors.cyoaza_cd = error.errors.cyoaza_cd
                ? error.errors.cyoaza_cd[0]
                : "";
              this.errors.hisaijyou_etc = error.errors.hisaijyou_etc
                ? error.errors.hisaijyou_etc[0]
                : "";
              this.errors.hisai_encyou = error.errors.hisai_encyou
                ? error.errors.hisai_encyou[0]
                : "";
              this.errors.encyou = error.errors.encyou
                ? error.errors.encyou[0]
                : "";
              this.errors.saigai_kuni = error.errors.saigai_kuni
                ? error.errors.saigai_kuni[0]
                : "";
              this.errors.han_cd = error.errors.han_cd
                ? error.errors.han_cd[0]
                : "";
              this.errors.fukyu_encyou = error.errors.fukyu_encyou
                ? error.errors.fukyu_encyou[0]
                : "";
              this.errors.rokyouryou_mei = error.errors.rokyouryou_mei
                ? error.errors.rokyouryou_mei[0]
                : "";
              this.errors.youkou = error.errors.youkou
                ? error.errors.youkou[0]
                : "";
              this.errors.rokasen_cd = error.errors.rokasen_cd
                ? error.errors.rokasen_cd[0]
                : "";
              this.errors.rokasen_txt = error.errors.rokasen_txt
                ? error.errors.rokasen_txt[0]
                : "";
              confirm(this.$messages.msg["0013E"]);
            }
          });
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500); 
    },

    //工事概要、改良復旧、内未等のボタンをクリックすると登録又は更新する。
    fbChange_update(fb_name) {
      this.showInfo = false;
      this.showError = false;
      this.numFormatErr = false;
      if(!this.koji_no) {
        confirm(this.$messages.msg['0013E']);
        this.showError = true;
        this.textError = "工事番号" + this.$messages.msg["00013W"];
      } else {
        this.numericFormatCheck();
      }
      if (!this.isNumFormat && !this.showError) {
        var request_data = {
          saigai_yyyy: this.engYear,
          ikkan_no: parseInt(this.ikkan_no),
          koji_no: this.koji_no,
          status: this.status,
          saigai_kuni: this.saigai_kuni,
          kanrisya_cd: parseInt(this.kanrisya_cd),
          nji_satei: this.nji_satei,
          hisaibi: this.formatDate(this.hisaibi),
          kousyu_cd: parseInt(this.kousyu_cd),
          jigyou_cd: this.jigyou_cd,
          shicyou_cd: parseInt(this.shicyou_cd),
          cyoaza_cd: parseInt(this.cyoaza_cd),
          chisaki: this.chisaki,
          hisaijyou_cd: parseInt(this.hisaijyou_cd),
          hisaijyou_etc: this.hisaijyou_etc,
          hisai_encyou: this.removeComma(this.hisai_encyou),
          syokan_cd: parseInt(this.syokan_cd),
          kuni_tan: this.kuni_tan,
          higai_gaku: this.removeComma(this.higai_gaku),
          han_cd: this.han_cd,
          rokasen_kbn: this.rokasen_kbn,
          rokasen_syubetu: this.rokasen_syubetu,
          rokasen_cd: this.rokasen_cd,
          rokasen_mei: this.rokasen_mei,
          rokasen_txt: this.rokasen_txt,
          rokasen_chk: this.rokasen_chk,
          rokyouryou_mei: this.rokyouryou_mei,
          itupanritou_kbn: this.itupanritou_kbn,
          fukyu_encyou: this.removeComma(this.fukyu_encyou),
          kasyo1: this.kasyo1,
          sayukisi1: this.sayukisi1,
          encyou1: this.removeComma(this.encyou1),
          kasyo2: this.kasyo2,
          sayukisi2: this.sayukisi2,
          encyou2: this.removeComma(this.encyou2),
          kasyo3: this.kasyo3,
          sayukisi3: this.sayukisi3,
          encyou3: this.removeComma(this.encyou3),
          kasyo4: this.kasyo4,
          sayukisi4: this.sayukisi4,
          encyou4: this.removeComma(this.encyou4),
          kasyo5: this.kasyo5,
          sayukisi5: this.sayukisi5,
          encyou5: this.removeComma(this.encyou5),
          youkou: this.youkou,
          youkou2: this.youkou2,
          youkou3: this.youkou3,
          saigai_seirinao: this.saigai_seirinao,
          loginId: localStorage.getItem("LOGIN_ID"),
          chk_date: this.chk_date
        };
        console.log(request_data);
        apiService
          .fb101Update(request_data)
          .then(result => {
            this.showInfo = true;
            this.showError = false;
            this.info = result.msg;
            this.chk_date = result.chk_date;
            this.$router.push({
              name: fb_name,
              query: {
                saigai_yyyy: this.engYear,
                ikkan_no: this.ikkan_no
              }
            });
          })
          .catch(error => {
            console.log(error);
            if (typeof error == "string") {
              confirm(this.$messages.msg['0013E']);
              this.showError = true;
              this.textError = error;
            } else {
              this.showError = false;
              this.errors.status = error.errors.status
                ? error.errors.status[0]
                : "";
              this.errors.kanrisya_cd = error.errors.kanrisya_cd
                ? error.errors.kanrisya_cd[0]
                : "";
              this.errors.hisaibi = error.errors.hisaibi
                ? error.errors.hisaibi[0]
                : "";
              this.errors.kousyu_cd = error.errors.kousyu_cd
                ? error.errors.kousyu_cd[0]
                : "";
              this.errors.shicyou_cd = error.errors.shicyou_cd
                ? error.errors.shicyou_cd[0]
                : "";
              this.errors.cyoaza_cd = error.errors.cyoaza_cd
                ? error.errors.cyoaza_cd[0]
                : "";
              this.errors.hisaijyou_etc = error.errors.hisaijyou_etc
                ? error.errors.hisaijyou_etc[0]
                : "";
              this.errors.hisai_encyou = error.errors.hisai_encyou
                ? error.errors.hisai_encyou[0]
                : "";
              this.errors.saigai_kuni = error.errors.saigai_kuni
                ? error.errors.saigai_kuni[0]
                : "";
              this.errors.han_cd = error.errors.han_cd
                ? error.errors.han_cd[0]
                : "";
              this.errors.fukyu_encyou = error.errors.fukyu_encyou
                ? error.errors.fukyu_encyou[0]
                : "";
              this.errors.encyou = error.errors.encyou
                ? error.errors.encyou[0]
                : "";
              this.errors.rokyouryou_mei = error.errors.rokyouryou_mei
                ? error.errors.rokyouryou_mei[0]
                : "";
              this.errors.youkou = error.errors.youkou
                ? error.errors.youkou[0]
                : "";
              this.errors.rokasen_cd = error.errors.rokasen_cd
                ? error.errors.rokasen_cd[0]
                : "";
              this.errors.rokasen_txt = error.errors.rokasen_txt
                ? error.errors.rokasen_txt[0]
                : "";
              confirm(this.$messages.msg["0013E"]);
            }
          });
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500); 
    },
    delete_btn() {
      this.showInfo = false;
      this.showError = false;
      this.numFormatErr = false;

      var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: parseInt(this.ikkan_no),
        koji_no: this.koji_no,
        loginId: localStorage.getItem("LOGIN_ID"),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fb101Delete(request_data)
          .then(result => {
            this.showFlg = false;
            this.showInfo = true;
            this.info = result;
            this.clearData();
          })
          .catch(error => {
            this.showFlg = true;
            console.log(error);
            // if(error.errors != null){
            //   this.errors.biko = error.errors.biko
            //   ? error.errors.biko[0]
            //   : "";
            //   confirm(this.$messages.msg['0013E']);
            // }
            confirm(this.$messages.msg['0013E']);
            this.showError = true;
            this.textError = error;
          });
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    goFB102() {
      this.fbChange_update("FB102");
    },
    goFB103() {
      this.fbChange_update("FB103");
    },
    goFB104() {
      this.fbChange_update("FB104");
    },
    goFB105() {
      this.fbChange_update("FB105");
    },
    return_fb100() {
      this.$router.push({
        name: "FB100",
        query: {
          saigai_yyyy: this.engYear,
          ikkan_no: this.ikkan_no
        }
      });
    },
    numericFormatCheck() {
      var hisaiencyou = this.hisai_encyou.replace(/,/g, "");
      hisaiencyou = hisaiencyou.trim().split(".")[0];
      var fukyuencyou = this.fukyu_encyou.replace(/,/g, "");
      fukyuencyou = fukyuencyou.trim().split(".")[0];
      var encyou1 = this.encyou1.replace(/,/g, "");
      encyou1 = encyou1.trim().split(".")[0];
      var encyou2 = this.encyou2.replace(/,/g, "");
      encyou2 = encyou2.trim().split(".")[0];
      var encyou3 = this.encyou3.replace(/,/g, "");
      encyou3 = encyou3.trim().split(".")[0];
      var encyou4 = this.encyou4.replace(/,/g, "");
      encyou4 = encyou4.trim().split(".")[0];
      var encyou5 = this.encyou5.replace(/,/g, "");
      encyou5 = encyou5.trim().split(".")[0];

      if (hisaiencyou.length > 9) {
        this.isNumFormat = true;
        this.numFormatErr = this.$messages.msg["0024W"];
      } else {
        if (fukyuencyou.length > 9) {
          this.isNumFormat = true;
          this.numFormatErr = this.$messages.msg["0025W"];
        } else {
          if (encyou1.length > 9) {
            this.isNumFormat = true;
            this.numFormatErr = this.$messages.msg["0026W"];
          } else {
            if (encyou2.length > 9) {
              this.isNumFormat = true;
              this.numFormatErr = this.$messages.msg["0027W"];
            } else {
              if (encyou3.length > 9) {
                this.isNumFormat = true;
                this.numFormatErr = this.$messages.msg["0028W"];
              } else {
                if (encyou4.length > 9) {
                  this.isNumFormat = true;
                  this.numFormatErr = this.$messages.msg["0029W"];
                } else {
                  if (encyou5.length > 9) {
                    this.isNumFormat = true;
                    this.numFormatErr = this.$messages.msg["0030W"];
                  } else {
                    this.isNumFormat = false;
                  }
                }
              }
            }
          }
        }
      }
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    convSaigaiKuni(saigai_kuni) {
      return ("00000" + saigai_kuni).slice(-5);
    },
    clearData() {
      (this.status = ""),
      (this.koji_no = ""),
      (this.kanrisya_cd = ""),
      (this.kanrisya_mei = ""),
      (this.nji_satei = ""),
      (this.hisaibi = ""),
      (this.kousyu_cd = ""),
      (this.kousyu_mei = ""),
      (this.jigyou_name = ""),
      (this.shicyou_cd = ""),
      (this.shicyou_mei = ""),
      (this.cyoaza_cd = ""),
      (this.cyoaza_mei = ""),
      (this.chisaki = ""),
      (this.hisaijyou_cd = ""),
      (this.hisaijyou_etc = ""),
      (this.hisaijyou_mei = ""),
      (this.hisai_encyou = ""),
      (this.syokan_mei = ""),
      (this.kuni_tan = ""),
      (this.kuni_mei = ""),
      (this.higai_gaku = ""),
      (this.saigai_kuni = ""),
      (this.saigai_kuni_mei = ""),
      (this.han_cd = ""),
      (this.han_mei = ""),
      (this.rokasen_kbn = ""),
      (this.michikubun_mei = ""),
      (this.rokasen_syubetu = ""),
      (this.rokasen_syubetu_mei = ""),
      (this.rokasen_cd = ""),
      (this.rokasen_mei = ""),
      (this.rokyouryou_mei = ""),
      (this.ippan_mei = ""),
      (this.fukyu_encyou = ""),
      (this.kasyo1 = ""),
      (this.kasyo1_mei = ""),
      (this.sayukisi1 = ""),
      (this.sayukisi1_mei = ""),
      (this.encyou1 = ""),
      (this.kasyo2 = ""),
      (this.kasyo2_mei = ""),
      (this.sayukisi2 = ""),
      (this.sayukisi2_mei = ""),
      (this.encyou2 = ""),
      (this.kasyo3 = ""),
      (this.kasyo3_mei = ""),
      (this.sayukisi3 = ""),
      (this.sayukisi3_mei = ""),
      (this.encyou3 = ""),
      (this.kasyo4 = ""),
      (this.kasyo4_mei = ""),
      (this.sayukisi4 = ""),
      (this.sayukisi4_mei = ""),
      (this.encyou4 = ""),
      (this.kasyo5 = ""),
      (this.kasyo5_mei = ""),
      (this.sayukisi5 = ""),
      (this.sayukisi5_mei = ""),
      (this.encyou5 = ""),
      (this.youkou = ""),
      (this.youkou2 = ""),
      (this.youkou3 = "");
    },
    statusCheck(){
      this.status = this.status_check ? 2 : 1;
      this.status_text_off = this.status == 2 ? "未登録" : "本登録";
      this.status_text_on = this.status == 2 ? "本登録" : "未登録";
    }
  }
};
</script>
<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main id="fb101" class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-show="showInfo">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isNumFormat" class="err">{{ numFormatErr }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="showError" class="err">{{ textError }}</span>
      </div>
      <div class="container mb-3 mt-3">
        <div class="form-group row" style="width:100%; max-width:500px">
          <label for class="col-sm col-form-label" style="width:100%; max-width:80px">災害年</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
            <input type="text" class="form-control bg-light" v-model="jpYear" readonly />
          </div>
          <label
            for
            class="col-sm col-form-label text-left pr-0 pl-1"
            style="width:100%; max-width:20px"
          >年</label>
          <label for class="col-sm col-form-label ml-3" style="width:100%; max-width:70px">査定</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:100px">
            <input type="text" class="form-control bg-light" v-model="nji_satei" readonly />
          </div>
          <label
            for
            class="col-sm col-form-label text-left pr-0 pl-1"
            style="width:100%; max-width:20px"
          >次</label>
        </div>
      </div>
      <form>
        <div class="container mb-3 pt-3 clearfix border-top border-secondary">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="update_btn()"
              v-show="showFlg"
            >更新</button>
            <router-link
              :to="{ name: 'FB100' }"
              class="btn btn-light ml-3"
              style="width:120px;"
              role="button"
            >戻る</router-link>
          </div>
        </div>
        <div class="container mb-3 mt-3">
          <div class="form-group" style>
            <div class="form-check form-check-inline" style="width:100%; max-width:75px; float:left">
              <label class="form-check-label" style="margin-top:8px;" for>状態</label>
            </div>
            <div class="statusArea mb-3" id="makeImg">
              <input type="checkbox" id="statusCheck" checked="" @change="statusCheck()" v-model="status_check">
              <label for="statusCheck">
                <div id="statusBox"><span>{{status_text_on}}</span></div>
                <div id="statusText"><span>{{status_text_off}}</span></div>
              </label>
            </div>
            <p
              style="width:100%; max-width:100px; white-space: nowrap;"
              class="err"
              v-if="this.errors.status"
            >{{ this.errors.status }}</p>
          </div>
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">工事番号</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:100px">
              <input type="text" class="form-control bg-light" v-model="koji_no" readonly />
            </div>
            <label for class="col-sm col-form-label ml-2" style="width:100%; max-width:80px">管理者</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select
                id
                class="form-control bg-required"
                v-model="kanrisya_cd"
                @change="kanrisyaChange(kanrisya_cd)"
                disabled
              >
                <option
                  v-for="item in kanrisyas"
                  :key="item.key"
                  :value="item.key"
                >{{item.key}} : {{item.value}}</option>
              </select>
            </div>
            <label for class="col-sm col-form-label ml-2" style="width:100%; max-width:100px">事業主体</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:300px">
              <input type="text" class="form-control bg-light" v-model="jigyou_name" readonly />
            </div>
          </div>
          <p
            style="width:100%; max-width:100px; margin-left: 210px; white-space: nowrap;"
            class="err"
            v-if="this.errors.kanrisya_cd"
          >{{ this.errors.kanrisya_cd }}</p>
        </div>
        <div class="container mb-3 mt-3 border pt-3 pb-3">
          <table class style="width:100%; font-size:0.95rem">
            <tbody class="align-middle">
              <tr>
                <td class="align-middle pt-1 pb-1" width>気象コード</td>
                <td class="align-middle pt-1 pb-1" colspan="2" width>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control bg-required"
                      style="width:88%;"
                      v-model="saigai_kuni"
                      @change="saigaiChange(saigai_kuni)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in saigai_kunis"
                        :key="item.key"
                        :value="item.key"
                      >{{convSaigaiKuni(item.key)}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td width></td>
                <td width></td>
                <td width></td>
                <td width></td>
              </tr>
              <tr>
                <td width="9%"></td>
                <td width="25%">
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.saigai_kuni"
                    >{{ this.errors.saigai_kuni }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">整理番号</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saigai_seirinao"
                      readonly
                      style="width:80px;"
                    />
                    <label
                      for
                      class="form-label ml-2 text-info"
                      style="font-size:0.9rem"
                    >災害報告(1ヶ月訂正)整理番号</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td width="9%" scope="row" class="align-middle pt-1 pb-1">被災日</td>
                <td width="30%" class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <datepicker
                      :language="ja"
                      v-model="hisaibi"
                      :value="hisaibi"
                      format="yyyy/MM/dd"
                      :typeable="true"
                      width="100px"
                    ></datepicker>
                  </div>
                </td>
                <td width="10%" scope="row" class="align-middle pt-1 pb-1 pl-3">工種</td>
                <td width="28%" class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control bg-required"
                      style="width:100%"
                      v-model="kousyu_cd"
                      @change="kousyuChange(kousyu_cd)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in kousyus"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td width="6%"></td>
                <td width="17%"></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.hisaibi"
                    >{{ this.errors.hisaibi }}</p>
                  </div>
                </td>
                <td></td>
                <td>
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.kousyu_cd"
                    >{{ this.errors.kousyu_cd }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">市町村</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control bg-required"
                      style="width:100%"
                      v-model="shicyou_cd"
                      @change="shicyouChange(shicyou_cd)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in shicyous"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-3">町字</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control bg-required"
                      style="width:100%"
                      v-model="cyoaza_cd"
                      @change="cyoazaChange(cyoaza_cd)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in cyoazas"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-3">地先</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input
                      type="text"
                      class="form-control p-1 flex-fill"
                      v-model="chisaki"
                      maxlength="100"
                      style="width:100%"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td width="9%"></td>
                <td width="20%">
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.shicyou_cd"
                    >{{ this.errors.shicyou_cd }}</p>
                  </div>
                </td>
                <td width="10%"></td>
                <td width="25%">
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.cyoaza_cd"
                    >{{ this.errors.cyoaza_cd }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">被災状況</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      style="width:100%"
                      v-model="hisaijyou_cd"
                      @change="hisaijyouChange(hisaijyou_cd)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in hisaijyous"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-3">その他時必須</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input
                      type="text"
                      class="form-control p-1 flex-fill"
                      v-model="hisaijyou_etc"
                      v-bind:class="{ 'bg-required': hisaijyou_sonota_selected }"
                      :maxlength="max_hisaijyou_etc"
                      style="width:100px"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td width="9%"></td>
                <td width="20%"></td>
                <td width="10%"></td>
                <td width="100px">
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.hisaijyou_etc"
                    >{{ this.errors.hisaijyou_etc }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">被災延長</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input
                      type="tel"
                      class="form-control p-1 bg-required text-right"
                      v-model="hisai_encyou"
                      :maxlength="max_hisaiencyou"
                      @blur="hisaiChange(hisai_encyou)"
                      @focus="hisaiReplaceComma()"
                      style="width:100px"
                    />
                    <label for class="form-label ml-2" style>m</label>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-3" width="13%">被害報告額</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right"
                      v-model="higai_gaku"
                      maxlength="9"
                      @blur="gakuChange(higai_gaku)"
                      @focus="gakuRemoveComma()"
                      style="max-width:60%"
                    />
                    <label for class="form-label ml-2" style>千円</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="9%"></td>
                <td width="20%">
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.hisai_encyou"
                    >{{ this.errors.hisai_encyou }}</p>
                  </div>
                </td>
                <td width="10%"></td>
                <td width="100px">
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">路河川区分</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      style="width:100%"
                      v-model="rokasen_kbn"
                      @change="michikubunChange(rokasen_kbn)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in michikubuns"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-3">路河川種別</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      style="width:100%"
                      v-model="rokasen_syubetu"
                      @change="syubetuChange(rokasen_syubetu)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in syubetus"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">
                  <label for class=" col-form-label" style="width:100%; max-width:150px">路河川名</label>
                </td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input type="radio" style="vertical-align:middle;margin-right: 18px;" v-model="rokasen_chk" v-bind:value="1">
                    <select
                      id
                      class="form-control"
                      style="width:90%"
                      :class="rokasen_required && rokasen_chk == '1' ? 'bg-required' : ''"
                      v-model="rokasen_cd"
                      @change="rokasenChange(rokasen_cd)"
                    >
                      <option value=""></option>
                      <option
                        v-for="item in rokasens"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}} : {{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-3">橋梁名</td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input
                      type="text"
                      class="form-control p-1 flex-fill"
                      :class="[this.bg_required? 'bg-required' : '',]"
                      v-model="rokyouryou_mei"
                      maxlength="100"
                      style="width:100%"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td width="10%"></td>
                <td>
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.rokasen_cd"
                    >{{ this.errors.rokasen_cd }}</p>
                  </div>
                </td>
                <td></td>
                <td>
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.rokyouryou_mei"
                    >{{ this.errors.rokyouryou_mei }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1">
                  <label for class=" col-form-label" style="width:100%; max-width:150px">路河川名<br>(直接入力)</label>
                </td>
                <td class="align-middle pt-1 pb-1" colspan>
                  <div class="form-inline">
                    <input type="radio" style="vertical-align:middle;margin-right:18px;" v-model="rokasen_chk" v-bind:value="2">
                    <input
                      type="text"
                      class="form-control"
                      style="width:90%;float:right;"
                      :class="rokasen_chk == '2' ? 'bg-required' : ''"
                      v-model="rokasen_txt"
                      maxlength="100"
                    />
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td width="10%"></td>
                <td>
                  <div class="form-inline">
                    <p
                      style="white-space: nowrap;"
                      class="err"
                      v-if="this.errors.rokasen_txt"
                    >{{ this.errors.rokasen_txt }}</p>
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>



            </tbody>
          </table>
        </div>
        <div class="container d-flex flex-wrap mb-3 mt-3 p-0 justify-content-between">
          <div class="border p-3" style="width:33%;">
            <table class style="width:100%; font-size:0.95rem">
              <tbody>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="29%">班コード</td>
                  <td class="align-middle pt-1 pb-1" colspan width="71%">
                    <div class="form-inline">
                      <select
                        id
                        class="form-control bg-required"
                        style="width:100%"
                        v-model="han_cd"
                        @change="hanChange(han_cd)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in hans"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}} : {{item.value}}</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="29%"></td>
                  <td class="align-middle pt-1 pb-1" colspan width="71%">
                    <div class="form-inline">
                      <p
                        style="white-space: nowrap;"
                        class="err"
                        v-if="this.errors.han_cd"
                      >{{ this.errors.han_cd }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="25%">復旧延長</td>
                  <td class="align-middle pt-1 pb-1" colspan width="75%">
                    <div class="form-inline">
                      <input
                        type="tel"
                        class="form-control bg-required p-1 flex-fill text-right"
                        v-model="fukyu_encyou"
                        :maxlength="max_fukyu"
                        @blur="fukyuChange(fukyu_encyou)"
                        @focus="fukyuRemoveComma()"
                        style="max-width:70%"
                      />
                      <label for class="form-label ml-2" style>m</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="25%"></td>
                  <td class="align-middle pt-1 pb-1" colspan width="75%">
                    <div class="form-inline">
                      <p
                        style="white-space: nowrap;"
                        class="err"
                        v-if="this.errors.fukyu_encyou"
                      >{{ this.errors.fukyu_encyou }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="25%">採択条項</td>
                  <td class="align-middle pt-1 pb-1" colspan width="75%">
                    <div class="form-inline">
                      <select
                        class="form-control flex-fill"
                        style="max-width:70%;background-color: #ffefd5;"
                        v-model="youkou"
                        @change="youkouChange(youkou)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in youkous"
                          :key="item.key"
                          :value="item.value"
                        >{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="25%"></td>
                  <td class="align-middle pt-1 pb-1" colspan width="75%">
                    <div class="form-inline">
                      <p
                        style="white-space: nowrap;"
                        class="err"
                        v-if="this.errors.youkou"
                      >{{ this.errors.youkou }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="25%"></td>
                  <td class="align-middle pt-1 pb-1" colspan width="75%">
                    <div class="form-inline">
                      <select
                        class="form-control flex-fill"
                        style="max-width:70%;"
                        v-model="youkou2"
                        @change="youkou2Change(youkou2)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in youkous"
                          :key="item.key"
                          :value="item.value"
                        >{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="align-middle pt-1 pb-1" width="25%"></td>
                  <td class="align-middle pt-1 pb-1" colspan width="75%">
                    <div class="form-inline">
                      <select
                        class="form-control flex-fill"
                        style="max-width:70%;"
                        v-model="youkou3"
                        @change="youkou3Change(youkou3)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in youkous"
                          :key="item.key"
                          :value="item.value"
                        >{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="border p-3" style="width:49%;">
            <table class style="width:100%; font-size:0.95rem">
              <thead class="text-white">
                <tr>
                  <th class="bg-primary pl-2 pr-2" width="5%"></th>
                  <th class="bg-primary border text-center align-middle" width="32%">左右岸</th>
                  <th class="bg-primary border text-center align-middle" width="28%">延長</th>
                  <th width="3%"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="bg-primary text-center align-middle text-white border-top">1</th>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <select
                        class="form-control"
                        style="width:100%;"
                        v-model="sayukisi1"
                        @change="sayukisi1Change(sayukisi1)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayus"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}} : {{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <input
                        type="tel"
                        class="form-control p-1 flex-fill text-right"
                        v-model="encyou1"
                        :maxlength="max_encyou1"
                        @blur="encyou1Change(encyou1)"
                        @focus="encyou1RemoveComma()"
                        style="width:90px"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">m</td>
                </tr>
                <tr>
                  <th class="bg-primary text-center align-middle text-white border-top">2</th>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <select
                        class="form-control"
                        style="width:100%;"
                        v-model="sayukisi2"
                        @change="sayukisi2Change(sayukisi2)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayus"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}} : {{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <input
                        type="tel"
                        class="form-control p-1 flex-fill text-right text-right"
                        v-model="encyou2"
                        :maxlength="max_encyou2"
                        @blur="encyou2Change(encyou2)"
                        @focus="encyou2RemoveComma()"
                        style="width:90px"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">m</td>
                </tr>
                <tr>
                  <th class="bg-primary text-center align-middle text-white border-top">3</th>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <select
                        class="form-control"
                        style="width:100%;"
                        v-model="sayukisi3"
                        @change="sayukisi3Change(sayukisi3)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayus"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}} : {{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <input
                        type="tel"
                        class="form-control p-1 flex-fill text-right"
                        v-model="encyou3"
                        :maxlength="max_encyou3"
                        @blur="encyou3Change(encyou3)"
                        @focus="encyou3RemoveComma()"
                        style="width:90px"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">m</td>
                </tr>
                <tr>
                  <th class="bg-primary text-center align-middle text-white border-top">4</th>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <select
                        class="form-control"
                        style="width:100%;"
                        v-model="sayukisi4"
                        @change="sayukisi4Change(sayukisi4)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayus"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}} : {{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <input
                        type="tel"
                        class="form-control p-1 flex-fill text-right"
                        v-model="encyou4"
                        :maxlength="max_encyou4"
                        @blur="encyou4Change(encyou4)"
                        @focus="encyou4RemoveComma()"
                        style="width:90px"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">m</td>
                </tr>
                <tr>
                  <th class="bg-primary text-center align-middle text-white border-top">5</th>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <select
                        class="form-control"
                        style="width:100%;"
                        v-model="sayukisi5"
                        @change="sayukisi5Change(sayukisi5)"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayus"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}} : {{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle pt-1 pb-1 border" width>
                    <div class="form-inline">
                      <input
                        type="tel"
                        class="form-control p-1 flex-fill text-right"
                        v-model="encyou5"
                        :maxlength="max_encyou5"
                        @blur="encyou5Change(encyou5)"
                        @focus="encyou5RemoveComma()"
                        style="width:90px"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">m</td>
                </tr>
              </tbody>
            </table>
            <tr>
              <td scope="row" class="align-middle pt-1 pb-1" width="25%"></td>
              <td class="align-middle pt-1 pb-1" colspan width="75%">
                <div class="form-inline">
                  <p
                    style="white-space: nowrap;"
                    class="err"
                    v-if="this.errors.encyou"
                  >{{ this.errors.encyou }}</p>
                </div>
              </td>
            </tr>
          </div>
          <div class="p-3 border" style="width:16%;">
            <button
              type="button"
              class="btn btn-success d-block mx-auto mb-2"
              @click="goFB102()"
              role="button"
              style="width:120px;margin-top:100px"
            >工事概要</button>
            <button
              type="button"
              class="btn btn-success d-block mx-auto mb-2"
              @click="goFB103()"
              role="button"
              style="width:120px;"
            >経済効果</button>
            <button v-if="false"
              type="button"
              class="btn btn-success d-block mx-auto mb-2"
              @click="goFB104()"
              role="button"
              style="width:120px;"
            >改良復旧</button>
            <button v-if="false"
              type="button"
              class="btn btn-success d-block mx-auto"
              @click="goFB105()"
              role="button"
              style="width:120px;"
            >内未・内転</button>
          </div>
        </div>
        <div class="container mb-3 pt-3 clearfix border-top border-secondary">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              v-show="showFlg"
              @click="delete_btn()"
            >削除</button>
          </div>
        </div>
      </form>
    </main>
  </Layout>
</template>