<template>
  <Layout>
    <main class="mb-3" id="fa107">
      <div class="col-sm-12 bg-success-info text-center">
        <span class="col-md-12 text-center" v-show="isHasInfo"> {{ info }} </span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasErr"  class="err">{{ this.errMsg }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:700px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="jpYear" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>

              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:150px"
              >被害報告番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:105px">
                <input type="text" class="form-control bg-light" v-model="higai_houkoku_cd" readonly />
              </div>
            </div>

            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
              <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:105px">
                <input type="text" class="form-control bg-light" v-model="ken" readonly />
              </div>
              <label for class="col-sm col-form-label" style="width:100%; max-width:125px">異常気象名</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
                <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >災害発生日</label>
              <div class="col-sm pr-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_start" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-center"
                style="width:100%; max-width:50px"
              >〜</label>
              <div class="col-sm pl-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light"  v-model="saigai_end" readonly/>
              </div>
            </div>

            <div class="form-group row mb-0" style="width:55%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_cd" readonly/>
              </div>
              <div class="col-sm pr-0 pl-1" style="width:100%; max-width:500px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_mei" readonly/>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix align-middle">
          <div class="form-inline float-right">
            <button class="btn btn-light" type="button" style="width:120px;" @click="updateData()">登録</button>
            <button class="btn btn-light ml-3" style="width:120px;" type="button" @click="back_btn()">戻る</button>
          </div>
        </div>
        <div class="container pt-3 clearfix align-middle">
          <div class="form-inline float-left">
            <button class="btn btn-light" type="button" style="width:120px;"  @click="changeItem()">行変更</button>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px">
              <select id class="form-control" v-model="selecteditem">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container mt-2 pt-3">
          <table class="table table-sm table-bordered" cellpadding="0" cellspacing="0">
            <tbody v-for="(sisetu1,index) in sisetu1SearchedList" :key="index" :value="index">
              <tr v-if="index < item_count">
                <th scope="row" class="align-middle table-primary border-right" width="5%">{{sisetu1.renban}}</th>
                <td class="align-middle border-right" :class="index%2 != 0 ? 'table-secondary' : ''">
                  <div class="form-group row" style="width:100%;">
                    <label
                      for
                      class="col-sm col-form-label"
                      style="width:100%; max-width:130px"
                    >河川区分</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px">
                      <select class="form-control" v-model="sisetu1.rokasen_kbn" v-on:change="rokasenKbnChange($event,index)">
                        <option
                          v-for="mcode in sisetu1.mCodeList"
                          :key="mcode.meisai"
                          :value="mcode.meisai"
                        >{{mcode.meisai}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="sisetu1.cd_value" readonly/>
                    </div>
                    <div style="width:100%; max-width:10px"></div>
                    <label
                      for
                      class="m-0 p-0 text-primary font-weight-bold"
                      style="width:100%; max-width:130px"
                    >被災位置</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px"></div>
                    <div class="col-sm pr-0 pl-0" style="width:100%;"></div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label
                      for
                      class="col-sm col-form-label"
                      style="width:100%; max-width:130px"
                    >河川種別</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px">
                      <select class="form-control" v-model="sisetu1.rokasen_syubetu" v-on:change="shubetuChange($event,index)">
                       <option 
                          v-for="shubetsu in sisetu1.shubetuList"
                          :key="shubetsu.kasen_syubetu"
                          :value="shubetsu.kasen_syubetu"
                        >{{shubetsu.kasen_syubetu}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="sisetu1.kasen_syubetumei" readonly/>
                    </div>
                    <div style="width:100%; max-width:10px"></div>
                    <label for class="col-sm col-form-label" style="width:100%; max-width:130px">市町村</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px">
                      <select class="form-control" v-model="sisetu1.hisai_shiguncd" v-on:change="shcyousonChange($event,index)">
                        <option
                        v-for="shcyouson in sisetu1.shcyousonList"
                        :key="shcyouson.shicyou_cd"
                        :value="shcyouson.shicyou_cd"
                        >{{shcyouson.shicyou_cd}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="sisetu1.shicyou_mei" readonly/>
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label
                      for
                      class="col-sm col-form-label"
                      style="width:100%; max-width:130px"
                    >河川海岸</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px">
                      <select class="form-control" v-model="sisetu1.rokasen_cd" v-on:change="rokasenChange($event,index)">
                        <option
                        v-for="rokasen in sisetu1.rokasenCheckList"
                        :key="rokasen.kasen_cd"
                        :value="rokasen.kasen_cd"
                        >{{rokasen.kasen_cd}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="sisetu1.kasen_kaiganmei" readonly/>
                    </div>
                    <div style="width:100%; max-width:10px"></div>
                    <label for class="col-sm col-form-label" style="width:100%; max-width:130px">町字</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:100px">
                      <select class="form-control" v-model="sisetu1.hisai_machicd" v-on:change="shcyouson2Change($event,index)">
                        <option
                        v-for="shcyouson in sisetu1.shcyouson2List"
                        :key="shcyouson.cyouaza_cd"
                        :value="shcyouson.cyouaza_cd"
                        >{{shcyouson.cyouaza_cd}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" value  v-model="sisetu1.cyouaza_mei" readonly/>
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label
                      for
                      class="col-sm col-form-label"
                      style="width:100%; max-width:130px"
                    >被災延長</label>
                    <div class="col-sm pr-0 pl-0" style="max-width:200px;">
                      <input type="text" class="form-control text-right" v-model="sisetu1.hisai_encyou" :maxlength="max" @blur="encyouChange(index)" @focus="focusEncyou(index)" @input="input(index)" style="width:100%;" />
                    </div>
                    <label
                      for
                      class="col-sm col-form-label text-left"
                      style="width:100%; max-width:140px"
                    >m</label>
                    <label for class="col-sm col-form-label" style="width:100%; max-width:150px">期間</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:150px">
                      <datepicker :language="ja" v-model="sisetu1.oukyukouhou_start" :value="saigai_start" :typeable="true" format="yyyy/MM/dd"></datepicker> 
                    </div>
                    <label
                      for
                      class="col-sm col-form-label text-center"
                      style="width:100%; max-width:50px"
                    >〜</label>
                    <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:150px">
                      <datepicker :language="ja" v-model="sisetu1.oukyukouhou_end" :value="saigai_start" :typeable="true" format="yyyy/MM/dd"></datepicker> 
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label for class="col-sm col-form-label" style="width:100%; max-width:130px">被災額</label>
                    <div class="col-sm pr-0 pl-0" style="max-width:200px;">
                      <input type="text" class="form-control text-right" v-model="sisetu1.hisai_gaku" maxlength="9" style="width:100%;" v-on:change="gakuChange(index)"/>
                    </div>
                    <label
                      for
                      class="col-sm col-form-label text-left"
                      style="width:100%; max-width:130px"
                    >千円</label>
                    <div style="width:100%; max-width:10px"></div>
                    <label
                      for
                      class="col-sm col-form-label"
                      style="width:100%; max-width:150px"
                    >被災状況等</label>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:350px">
                      <input type="text" class="form-control" maxlength="100" v-model="sisetu1.hisai_jyoukyou" />
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label
                      for
                      class="col-sm col-form-label"
                      style="width:100%; max-width:130px"
                    >応急工法</label>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:350px">
                      <input type="text" class="form-control" maxlength="100" v-model="sisetu1.oukyukouhou"/>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container mt-3 pt-3 border-top border-secondary">
          <div class="form-group row" style="width:100%; max-width:700px"></div>
          <div class="form-group row" style="width:100%; max-width:700px"></div>
        </div>
        <!--container-->
      </form>
    </main>
  </Layout>
</template>
<script>
import moment from "moment";
import {ja} from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';
import { APIService } from "@/apiService.js";
const apiService = new APIService();
var numeral = require("numeral");
import Layout from "@/components/layouts/Layout.vue";
export default {
  components: {
    Layout,datepicker
  },
  data() {
    if (this.$route.query) {
      return {
        max: '10',
        engYear: this.$route.query.saigai_yyyy,
        ken: this.$route.query.saigai_ken,
        higai_houkoku_cd: this.$route.query.houkokusya_cd,
        kanrisya_cd: window.localStorage.getItem('Kanrisya_Cd'),
        kanrisya_mei: window.localStorage.getItem('Kanrisya_Mei'),
        bunrui_cd: this.$route.query.bunrui_cd,
        jpYear: "",
        saigai_end: "",
        saigai_start: "",
        saigai_mei: "",
        ja:ja,
        isHasInfo: false,
        info: '',
        isHasErr: false,
        errMsg: '',
        item_count: 1,
        selecteditem: 1,
        mCodeList: [],
        item_options: {},
        sisetu1SearchedList: {},
        loginUser: {
          id: window.localStorage.getItem('LOGIN_ID'),
          kanrisya_cd: window.localStorage.getItem('Kanrisya_Cd'),
          kanrisya_name: window.localStorage.getItem('Kanrisya_Mei'),
        },
        post: null
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.isMsgShow = false;
    this.getGengo(this.engYear);
    this.fa101GetSaigaimeisyou(this.engYear);
    this.getHigaiSisetu1Data();
  },
  filters: {
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number){
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0,0");
      if(result == 0){
        return '';
      }
      return result;
    },
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    fa101GetSaigaimeisyou(year) {
      var request_data = {
        year: year,
        ken: this.ken
      };
      apiService.getSaigaiMeisyou(request_data).then(result => {
        this.saigai_mei = result[0].saigai_mei;
        this.saigai_start = this.formatDate(result[0].saigai_start);
        this.saigai_end = this.formatDate(result[0].saigai_end);
      }); 
    },
    getHigaiSisetu1Data(){
      var request_data = {
        year: this.engYear,
        ken: this.ken,
        kanrisya_cd: this.kanrisya_cd,
        houkokusya_cd: this.higai_houkoku_cd,
        bunrui_cd: this.bunrui_cd,
      };
      apiService.fa107getHigaiSisetu1Data(request_data)
      .then(result => {
        this.sisetu1SearchedList = result;
        for(var i = 0; i < this.sisetu1SearchedList.length; i++){
          this.sisetu1SearchedList[i].oukyukouhou_start = this.formatDate(this.sisetu1SearchedList[i].oukyukouhou_start);
          this.sisetu1SearchedList[i].oukyukouhou_end = this.formatDate(this.sisetu1SearchedList[i].oukyukouhou_end);
          this.sisetu1SearchedList[i].hisai_encyou = this.formatDecimal(this.sisetu1SearchedList[i].hisai_encyou);
          this.sisetu1SearchedList[i].hisai_gaku = this.formatNumber(this.sisetu1SearchedList[i].hisai_gaku);
        }
      });
    },
    rokasenKbnChange(kbn,index){
      var rokasan_index = kbn.target.options[kbn.target.options.selectedIndex].text;
        for(var i = 0; i < this.sisetu1SearchedList[index].mCodeList.length; i++){
          if(this.sisetu1SearchedList[index].mCodeList[i].meisai == rokasan_index){
            this.sisetu1SearchedList[index].rokasen_kbn = rokasan_index;
            this.sisetu1SearchedList[index].cd_value = this.sisetu1SearchedList[index].mCodeList[i].cd_value;
          }
        }
        this.sisetu1SearchedList[index].rokasen_syubetu = '';
        this.sisetu1SearchedList[index].kasen_syubetumei = '';
        this.sisetu1SearchedList[index].rokasen_cd = '';
        this.sisetu1SearchedList[index].kasen_kaiganmei = '';
        var request_data = {
          kubun_cd: rokasan_index,
        };
        apiService
          .fa102GetKasenshubetsumei(request_data)
          .then(result => {
            this.sisetu1SearchedList[index].shubetuList = result;
        });
    },
    shubetuChange(key,index){
      var shubetu_index = key.target.options[key.target.options.selectedIndex].text;
      for(var i = 0; i < this.sisetu1SearchedList[index].shubetuList.length; i++){
        if(this.sisetu1SearchedList[index].shubetuList[i].kasen_syubetu == shubetu_index){
          this.sisetu1SearchedList[index].rokasen_syubetu = shubetu_index;
          this.sisetu1SearchedList[index].kasen_syubetumei = this.sisetu1SearchedList[index].shubetuList[i].kasen_syubetumei;
        }
      }
      this.sisetu1SearchedList[index].rokasen_cd = '';
      this.sisetu1SearchedList[index].kasen_kaiganmei = '';
      var request_data = {
        rokasen_kbn: this.sisetu1SearchedList[index].rokasen_kbn,
        rokasen_shubetu: shubetu_index, 
      };
      apiService
        .fa107checkRokasenCd(request_data)
        .then(result => {
          this.sisetu1SearchedList[index].rokasenCheckList = result;
      });
    },
    rokasenChange(key,index){
      var rokasen_index = key.target.options[key.target.options.selectedIndex].text;
          for(var i = 0; i < this.sisetu1SearchedList[index].rokasenCheckList.length; i++){
            if(this.sisetu1SearchedList[index].rokasenCheckList[i].kasen_cd == rokasen_index){
              this.sisetu1SearchedList[index].rokasen_cd= rokasen_index;
              this.sisetu1SearchedList[index].kasen_kaiganmei = this.sisetu1SearchedList[index].rokasenCheckList[i].rokasen_mei;
            }
          }
    },
    shcyousonChange(key,index){
      var shcyouson_index = key.target.options[key.target.options.selectedIndex].text;
        for(var i = 0; i < this.sisetu1SearchedList[index].shcyousonList.length; i++){
          if(this.sisetu1SearchedList[index].shcyousonList[i].shicyou_cd == shcyouson_index){
            this.sisetu1SearchedList[index].hisai_shiguncd= shcyouson_index;
            this.sisetu1SearchedList[index].shicyou_mei = this.sisetu1SearchedList[index].shcyousonList[i].shicyou_mei;
          }
        }
        this.sisetu1SearchedList[index].hisai_machicd = '';
        this.sisetu1SearchedList[index].cyouaza_mei = '';
        var request_data = {  
          shcyou_cd: shcyouson_index,
        };
        apiService.fa107getShcyousonCheckListKbn(request_data)
        .then(result => {
          this.sisetu1SearchedList[index].shcyouson2List = result;
        });
    },

    shcyouson2Change(key,index){
      var shcyouson_index = key.target.options[key.target.options.selectedIndex].text;
        for(var i = 0; i < this.sisetu1SearchedList[index].shcyouson2List.length; i++){
          if(this.sisetu1SearchedList[index].shcyouson2List[i].cyouaza_cd == shcyouson_index){
            this.sisetu1SearchedList[index].hisai_machicd = shcyouson_index;
            this.sisetu1SearchedList[index].cyouaza_mei = this.sisetu1SearchedList[index].shcyouson2List[i].cyouaza_mei;
          }
        }
    },
    updateData(){
      this.isHasInfo = false;
      this.info = '';
      this.isHasErr = false;
      this.errMsg = '';
      for(var i = 0; i < this.sisetu1SearchedList.length; i++){
        var tempencyou = this.sisetu1SearchedList[i].hisai_encyou;
        var tempgaku = this.sisetu1SearchedList[i].hisai_gaku;
        if(this.sisetu1SearchedList[i].hisai_encyou != null){
          tempencyou = tempencyou.trim().replace(/,/g,'');
          this.sisetu1SearchedList[i].hisai_encyou = tempencyou;
        }
        if(this.sisetu1SearchedList[i].hisai_gaku != null){
          tempgaku = tempgaku.trim().replace(/,/g,'');
          if(this.isInteger(tempgaku)){
            this.sisetu1SearchedList[i].hisai_gaku = parseInt(tempgaku);
          }
        }
        this.sisetu1SearchedList[i].oukyukouhou_start = this.formatDate(this.sisetu1SearchedList[i].oukyukouhou_start);
        this.sisetu1SearchedList[i].oukyukouhou_end = this.formatDate(this.sisetu1SearchedList[i].oukyukouhou_end);
        if(this.sisetu1SearchedList[i].oukyukouhou_start > this.sisetu1SearchedList[i].oukyukouhou_end){
          this.isHasErr = true;
          this.errMsg = this.$messages.msg['0003E'];
        }else{
          this.isHasErr = false;
          this.errMsg = '';
        }
      }
      var request_data = {
        saigai_yyyy: this.engYear,
        saigai_ken: this.ken,
        kanrisya_cd:  this.loginUser.kanrisya_cd,
        houkokusya_cd: this.higai_houkoku_cd,
        sisetu1List: this.sisetu1SearchedList,
        updateCount: this.item_count,
        login_id: this.loginUser.id,
      };
      if(!this.isHasErr){
        apiService.fa107updateData(request_data)
        .then(result => {
          this.info = result;
          this.isHasInfo = true;
          this.getHigaiSisetu1Data();
        }).catch(error => {
          this.errMsg = error;
          this.isHasErr = true;
        });
      }
    },
    changeItem(){
      this.isHasErr = false;
      this.isHasInfo = false;
      this.item_count = this.selecteditem;
    },
    focusEncyou(index){
      var tempencyou = this.sisetu1SearchedList[index].hisai_encyou;
      if(this.sisetu1SearchedList[index].hisai_encyou != null){
        this.sisetu1SearchedList[index].hisai_encyou = tempencyou.trim().replace(/,/g,'');
      }
    },
    encyouChange(index){
      this.sisetu1SearchedList[index].hisai_encyou = this.formatDecimal(this.sisetu1SearchedList[index].hisai_encyou.toString().trim());
    },
    gakuChange(index){
      this.sisetu1SearchedList[index].hisai_gaku = this.formatNumber(this.sisetu1SearchedList[index].hisai_gaku.toString().trim());
    },
    input(index) {
      this.max = this.sisetu1SearchedList[index].hisai_encyou.indexOf(".") > 0 ? 11 : 10;
      if(this.sisetu1SearchedList[index].hisai_encyou.length > 9 &&  this.sisetu1SearchedList[index].hisai_encyou.charAt(9) != '.' ) {
        this.sisetu1SearchedList[index].hisai_encyou = this.sisetu1SearchedList[index].hisai_encyou.substring(0,9);
      }
    },
    formatDate: function(date) {
      if(date == '' || date == null ){
        return date;
      }
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number){
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? '': result;
    },
    formatDecimal: function(number) {
      return number=='' || number==null || numeral(number).format("0,0.0")==0 ? '' : numeral(number).format("0,0.0");
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
      });
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    }
  },
};
</script>