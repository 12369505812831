<template>
  <Layout>
    <main id="fa103" class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span class="col-md-12 text-center" v-show="isHasInfo"> {{ info }} </span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasError"  class="err">{{ errorMsg }}</span>
      </div>
      <div class="container mt-3 mb-3">
        <h4 class="text-primary">【抽出条件】</h4>
      </div>
      <div class="container mb-3 mt-3">
        <form action method>
          <div class="form-group row" style="width:100%; max-width:400px">
            <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="tel" class="form-control bg-required" @blur="changedYear()" v-model='jpYear'/>
            </div>
            <label
              for
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >年</label>
          </div>

          <div class="form-group row" style>
            <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:350px">
              <select @change="onChange(selectedOption, options)" class="form-control bg-required" v-model='selectedOption'>
                <option v-for="option in options" :key="option.saigai_ken" :value="option.saigai_ken">
                  {{convSaigaiKen(option.saigai_ken)}}<span v-if="option.saigai_ken != null"> : </span>{{option.saigai_mei}}
                </option>
              </select>
              <span class="err" v-show="isHasKenNotFoundErr">{{ this.kenErrorMsg }}</span>
            </div>
            <label
              for
              class="col-sm col-form-label text-right"
              style="width:100%; max-width:120px"
            >災害発生日</label>
            <div class="col-sm pr-0" style="width:100%; max-width:130px">
              <input type="text" class="form-control" v-model="saigai_start" readonly/>
            </div>
            <label
              for
              class="col-sm col-form-label text-center"
              style="width:100%; max-width:50px"
            >〜</label>
            <div class="col-sm pl-0" style="width:100%; max-width:130px">
              <input type="text" class="form-control" v-model="saigai_end" readonly/>
            </div>
          </div>

          <div class="form-group" style>
            <div class="form-check form-check-inline" style="width:100%; max-width:100px">
              <label class="form-check-label" for>災害報告</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="shicyou_kbn" v-bind:value="1"/>
              <label class="form-check-label" for>県工事災害</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="shicyou_kbn" v-bind:value="2" />
              <label class="form-check-label" for>市町村工事災害</label>
            </div>
          </div>

          <button type="button" class="btn btn-light d-block mx-auto" style="width:120px;" @click="search()" v-if="isHasMeisyou">検索</button>
        </form>
      </div>
      <form>
        <template  >
          <div class="container pt-3 clearfix border-top border-secondary mb-2" >
            <div class="form-inline float-left">
              <p class="text-info"  v-if="showFlg">「集計実行」ボタン押下後に「登録」ボタンを押下してください。</p>
            </div>
            <div class="form-inline float-right">
              <button class="btn btn-light" type="button" style="width:170px;margin-right:5px" v-if="showFlg" :disabled="syukeiMode ? false: true" @click="aggregateData('1')">災害報告集計実行</button>
              <button class="btn btn-light" type="button" style="width:170px;margin-right:5px" v-if="showFlg" :disabled="syukeiMode_1 ? false: true" @click="aggregateData('2')">1ヶ月訂正集計実行</button>
              <button class="btn btn-light" type="button" style="width:120px;"  @click="update_btn()" v-if="showFlg">登録</button>
            </div>
          </div>    
        </template>
        <!--TABLE BODY ここから-->
        <div class="container">
          <!--1200px以下の画面幅で表示-->
          <div class="form-inline float-left" v-if="showTable">
            <p class="align-middle">{{ this.koushinbi }}</p>
          </div>
          <table class="text-center table-bordered table-sm mb-0">
            <thead class="bg-primary text-light text-center">
              <tr>
                <th rowspan="2" scope class="align-middle" width="9%">管理者</th>
                <th colspan="2" scope class="align-middle" width="13%">河川</th>
                <th colspan="2" scope class="align-middle" width="13%">海岸</th>
                <th colspan="2" scope class="align-middle" width="13%">砂防</th>
                <th colspan="2" scope class="align-middle" width="13%">地すべり</th>
                <th colspan="2" scope class="align-middle" width="13%">急傾斜地</th>
                <th colspan="2" scope class="align-middle" width="13%">水道</th>
                <th rowspan="2" colspan="2" scope class="align-middle">合計</th>
              </tr>
              <tr>
                <th colspan="2" class="align-middle">道路</th>
                <th colspan="2" scope class="align-middle">橋梁</th>
                <th colspan="2" scope class="align-middle">港湾</th>
                <th colspan="2" scope class="align-middle">海岸(港湾)</th>
                <th colspan="2" scope class="align-middle">公園</th>
                <th colspan="2" scope class="align-middle">下水道</th>
              </tr>
            </thead>
          </table>
          <div class="table-scroll-y" v-if="showTable">
            <!--1200px以上の画面幅で表示-->
            <table class="table-bordered table-sm">
              <tbody v-for="(item, index) in searchList" :key="index" :value="index">
                <tr :class="index % 2 != 0 ? 'table-secondary' : ''" v-if="item.kanrisya_mei != null">
                  <td rowspan="2" class="align-middle text-center" width="9%">{{ item.kanrisya_mei }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.kasen_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.kasen_gaku | formatNumber }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.kaigan_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.kaigan_gaku | formatNumber }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.sabou_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.sabou_gaku | formatNumber }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.jisuberi_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.jisuberi_gaku | formatNumber }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.kyukeisya_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.kyukeisya_gaku | formatNumber }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.jousuidou_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.jousuidou_gaku | formatNumber }}</td>
                  <td rowspan="2" width="4%" class="align-middle text-right">{{ item.goukei_kasyo | formatNumber }}</td>
                  <td rowspan="2" width="9%" class="align-middle text-right">{{ item.goukei_gaku | formatNumber }}</td>
                </tr>
                <tr :class="index % 2 != 0 ? 'table-secondary' : ''" v-if="item.kanrisya_mei != null">
                  <td width="4%" class="align-middle text-right">{{ item.douro_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.douro_gaku | formatNumber }}</td>
                  <td width="4%" class="align-middle text-right">{{ item.kyouryou_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.kyouryou_gaku | formatNumber }}</td>
                  <td width="6%" class="align-middle text-right">
                    <input type="tel" class="form-control text-right bg-lightmint" v-model="item.kouwan_kasyo" @blur="kouwan_kasyo_change(index)" @focus="kouwan_kasyo_focus(index)" />
                  </td>
                  <td width="9%" class="align-middle text-right">
                    <input type="tel" class="form-control text-right bg-lightmint" v-model="item.kouwan_gaku" @blur="kouwan_gaku_change(index)" @focus="kouwan_gaku_focus(index)" />
                  </td>
                  <td width="6%" class="align-middle text-right">
                    <input type="tel" class="form-control text-right bg-lightmint" v-model="item.kaigankou_kasyo" @blur="kaigankou_kasyo_change(index)" @focus="kaigankou_kasyo_focus(index)" />
                  </td>
                  <td width="9%" class="align-middle text-right">
                    <input type="tel" class="form-control text-right bg-lightmint" v-model="item.kaigankou_gaku" @blur="kaigankou_gaku_change(index)" @focus="kaigankou_gaku_focus(index)" />
                  </td>
                  <td width="6%" class="align-middle text-right">
                    <input type="tel" class="form-control text-right bg-lightmint" v-model="item.kouen_kasyo" @blur="kouen_kasyo_change(index)" @focus="kouen_kasyo_focus(index)" />
                  </td>
                  <td width="9%" class="align-middle text-right">
                    <input type="tel" class="form-control text-right bg-lightmint" v-model="item.kouen_gaku" @blur="kouen_gaku_change(index)" @focus="kouen_gaku_focus(index)" />
                  </td>
                  <td width="4%" class="align-middle text-right">{{ item.gesuidou_kasyo | formatNumber }}</td>
                  <td width="9%" class="align-middle text-right">{{ item.gesuidou_gaku | formatNumber }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--1200px以下の画面幅で表示 終了-->
        </div>
        <!--container-->
        <!--TABLE BODY 終了-->
      </form>
      <!--県工事災害合計ここから-->
      <div class="container mt-3" v-if="showTable">
        <table class="table-bordered table-sm table-info">
          <tbody>
            <tr>
              <td rowspan="2" width="9%" class="text-center">{{ totalName }}&nbsp;合計</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kasen_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kasen_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kaigan_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kaigan_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_sabou_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_sabou_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_jisuberi_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_jisuberi_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kyukeisya_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kyukeisya_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_jousuidou_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_jousuidou_gaku | formatNumber }}</td>
              <td rowspan="2" width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_goukei_kasyo | formatNumber }}</td>
              <td rowspan="2" width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_goukei_gaku | formatNumber }}</td>
            </tr>
            <tr>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_douro_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_douro_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kyouryou_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kyouryou_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kouwan_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kouwan_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kaigankou_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kaigankou_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_kouen_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_kouen_gaku | formatNumber }}</td>
              <td width="4%" class="align-middle text-right">{{ searchList[lastIndex].total_gesuidou_kasyo | formatNumber }}</td>
              <td width="9%" class="align-middle text-right">{{ searchList[lastIndex].total_gesuidou_gaku | formatNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--1200px以上の画面幅で表示 終了-->
      <!--container-->
      <!--県工事災害合計 終了-->
    </main>
  </Layout>
</template>
<script>
  import moment from 'moment';
  import Layout from "@/components/layouts/Layout.vue";
  import { APIService } from '@/apiService.js';
  const apiService = new APIService();
  var numeral = require("numeral")
  export default {
    name: "FA103",
    components: {
      Layout,
    },
    data() {
      return {
        selectedIndex: 0,
        showTable:false,
        meisyouData: {},
        meisyouList: {},
        isOptionSelected:false,
        options: [],
        gengoData: {},
        jpYear: '',
        saigai_ken: '',
        searchList: {},
        saigai_end:'',
        saigai_start:'',
        errorMsg: '',
        is103: true,
        info: '',
        isHasInfo:false,
        shicyou_kbn: '',
        kenErrorMsg: '',
        isHasMeisyou: false,
        houkoku_kbn: '',
        selectedOption:'',
        lastIndex: 0,
        totalName:'',
        ishoukokuExist: false,
        itemSelectedIndex: 0,
        isHasKenNotFoundErr: false,
        loginUser: {
          id: window.localStorage.getItem('LOGIN_ID'),
          kanrisya_cd: window.localStorage.getItem('Kanrisya_Cd'),
          kanrisya_name: window.localStorage.getItem('Kanrisya_Mei'),
          shicyou_kbn: window.localStorage.getItem('shicyou_kbn') == 2 ? '2' : '1',
        },
        isHasError: false,
        saigai_yyyy:'',
        syukeiMode: false,
        syukeiMode_1: false,
        showFlg : false,
        koushinbi : '',
        chk_date: ''
      }
    },
    mounted() {
      this.isSuccessMsgShow = (this.$route.params.message) ? true : false;
      this.isMsgShow = false;
      this.saigai_yyyy = (new Date().getFullYear()).toString();
      this.getGengo(this.saigai_yyyy);
      this.getSaigaiMeisyou(new Date().getFullYear());
      this.$store.commit("fa103/setReportName",'');
    },
    filters: {
      formatDate: function (date) {
        return moment(String(date)).format('YYYY/MM/DD');
      },
      formatNumber: function(number){
        return  numeral(number).format("0,0");
      }
    },
    
    methods: {
      getGengo(year){	
        this.info = '';	
        this.isHasError = false;	
        var date = new Date(year, 6, 1, 0, 0, 0, 0);	
        var param = moment(String(date)).format('YYYYMMDD');	
        var request_data = {	
          date: param,	
        };	
        apiService.getGengo(request_data)	   
        .then(result=>{	
          this.jpYear = result;	
        });	
      },
      getSaigaiMeisyou(year){
        var request_data = {
          year: year,
        };
        apiService.fa101GetSaigaiMeisyou(request_data)
          .then(result => {
          this.options = {};
          if(result.length > 0){
            this.shicyou_kbn = this.loginUser.shicyou_kbn;
            this.isHasMeisyou = true;
            this.meisyouList = result;
            this.meisyouList.unshift({saigai_yyyy: null, saigai_ken: null, saigai_mei: null, saigai_start: null, saigai_end: null,kbn: null})
            this.errorMsg = '';
            for (var i = 0; i < this.meisyouList.length; i++) {   
              this.options[i] = this.meisyouList[i]; 
              if (this.isOptionSelected) {
                if (this.selectedOption == this.meisyouList[i].saigai_ken) {
                  this.selectedIndex = i;
                }
              } else {
                this.selectedIndex = i;
              }
            }
            this.selectedOption = this.options[this.selectedIndex].saigai_ken;
            this.meisyouData = this.meisyouList[this.selectedIndex];
            if(this.selectedIndex === 0){
              this.saigai_start = "";
              this.saigai_end ="";
            }else{
              this.saigai_start = this.formatDate(this.meisyouData.saigai_start);
              this.saigai_end = this.formatDate(this.meisyouData.saigai_end);
            }
            this.saigai_ken = this.meisyouData.saigai_ken;
            this.houkoku_kbn = this.meisyouData.kbn;
          }else{
            this.isHasMeisyou = false;
            this.clearData();
          }
        });
        
      },
      search(page){
        this.isHasInfo = false;
        this.isHasError = false;
        if( page==undefined){
          page = 1;
        }
        var request_data = {
          year: this.saigai_yyyy,
          saigai_ken: this.saigai_ken,
          jigyou_cd: this.shicyou_kbn,
          kbn: this.houkoku_kbn,
        }
        apiService.fa103getHoukokuDataCount(request_data)
        .then(result => {
          if(result[0].count > 0){
            this.ishoukokuExist = true;
            }else{
              this.ishoukokuExist = false;
            }
        });
        var requestdata = {
          year: this.saigai_yyyy,
          saigai_ken: this.saigai_ken,
          kanrisya_cd: this.loginUser.kanrisya_cd,
          kbn: this.houkoku_kbn,
          start_date: this.meisyouData.saigai_start,
          shicyou_kbn: this.shicyou_kbn
        }
        apiService.fa103searchData(requestdata)
        .then(result => {
          this.searchList = result.searchList;
          if (this.searchList.length > 0) {
            if (this.shicyou_kbn == 2) {
              this.$store.commit("fa103/setReportName", this.$constants.FA103["SHICHOUSON"]);
              this.totalName = this.$constants.FA103["SHICHOUSON_KOUJI"];
            } else {
              this.totalName = this.$constants.FA103["KENKOUJI"];
              this.$store.commit("fa103/setReportName", this.$constants.FA103["KEN"]);
            }
            if(this.searchList[0].kbn == 1){
              this.syukeiMode_1 = true;
            } else {
              this.syukeiMode_1 = false;
            }
            for(var i = 0; i < this.searchList.length; i++) {
              this.searchList[i].kaigankou_kasyo = this.formatComma(this.searchList[i].kaigankou_kasyo);
              this.searchList[i].kaigankou_gaku  = this.formatComma(this.searchList[i].kaigankou_gaku);
              this.searchList[i].kouwan_kasyo    = this.formatComma(this.searchList[i].kouwan_kasyo);
              this.searchList[i].kouwan_gaku     = this.formatComma(this.searchList[i].kouwan_gaku);
              this.searchList[i].kouen_kasyo     = this.formatComma(this.searchList[i].kouen_kasyo);
              this.searchList[i].kouen_gaku      = this.formatComma(this.searchList[i].kouen_gaku);
            }
            this.koushinbi  = this.searchList[0].koushinbi + " 時点の集計データです。";
            this.lastIndex  = result.searchList.length-1;
            this.showTable  = true;
            this.isHasError = false;
            this.syukeiMode = false;
          } else {
            confirm(this.$messages.msg['0013E']);
            this.isHasError = true;
            this.showTable = false;
            this.errorMsg = this.errorMsg = this.$messages.msg['0002E'];
            this.searchList = {};
            this.isHasInfo = false;
            this.syukeiMode = true;
            this.syukeiMode_1 = false;
            this.clearReportNames();
          }
          this.chk_date = result.chk_date;
          this.showFlg = true;
        }).catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.isHasError = true;
          this.errorMsg = error;
          console.log(error);
        });
      },
      aggregateData(kbn){
        if(this.searchList.length > 0){
          if(!confirm("既存のデータを削除して、新たに集計をしますか？")){
            return;
          }
        }
        // 一ヶ月訂正集計時、入力値保持
        var tempInputList = null;
        if(kbn == '2'){
          tempInputList = new Array();
          for(var i = 0; i < this.searchList.length; i++) {
            tempInputList[i] = {
              kaigankou_kasyo : this.searchList[i].kaigankou_kasyo,
              kaigankou_gaku : this.searchList[i].kaigankou_gaku,
              kouwan_kasyo: this.searchList[i].kouwan_kasyo,
              kouwan_gaku : this.searchList[i].kouwan_gaku,
              kouen_kasyo : this.searchList[i].kouen_kasyo,
              kouen_gaku : this.searchList[i].kouen_gaku
            };
          }
        }
        
        this.isHasInfo = false;
        this.isHasError = true;
        var request_data = {
          year: this.saigai_yyyy,
          saigai_ken: this.saigai_ken,
          kbn: kbn,
          shicyou_kbn: this.shicyou_kbn,
        };
        apiService.fa103aggregateData(request_data)
        .then(result => {
          this.info = this.$messages.msg['0004I'];
          this.isHasInfo = true;
          this.isHasError = false;
          this.showTable = true;
          this.searchList = result;
          if(this.searchList != null && this.searchList.length > 0){
            for(var i = 0; i < this.searchList.length; i++) {
              this.searchList[i].kaigankou_kasyo = tempInputList ? this.formatComma(tempInputList[i].kaigankou_kasyo) : this.formatComma(this.searchList[i].kaigankou_kasyo);
              this.searchList[i].kaigankou_gaku  = tempInputList ? this.formatComma(tempInputList[i].kaigankou_gaku) : this.formatComma(this.searchList[i].kaigankou_gaku);
              this.searchList[i].kouwan_kasyo    = tempInputList ? this.formatComma(tempInputList[i].kouwan_kasyo) : this.formatComma(this.searchList[i].kouwan_kasyo);
              this.searchList[i].kouwan_gaku     = tempInputList ? this.formatComma(tempInputList[i].kouwan_gaku) : this.formatComma(this.searchList[i].kouwan_gaku);
              this.searchList[i].kouen_kasyo     = tempInputList ? this.formatComma(tempInputList[i].kouen_kasyo) : this.formatComma(this.searchList[i].kouen_kasyo);
              this.searchList[i].kouen_gaku      = tempInputList ? this.formatComma(tempInputList[i].kouen_gaku) : this.formatComma(this.searchList[i].kouen_gaku);
            }
            this.lastIndex = result.length - 1;
          } else {
            confirm(this.$messages.msg['0013E']);
            this.isHasInfo = false;
            this.errorMsg = this.$messages.msg['0002E'];
            this.isHasError = true;
            this.showTable = false;
            this.searchList = {};
            this.clearReportNames();
          }
        });
        
      },
      update_btn(){
        this.info = null;
        this.isHasInfo = false;
        this.errorMsg = null;
        this.isHasError = false;
        for(var i = 0; i < this.searchList.length; i++) {
          this.searchList[i].kaigankou_kasyo = this.replaceComma(this.searchList[i].kaigankou_kasyo);
          this.searchList[i].kaigankou_gaku  = this.replaceComma(this.searchList[i].kaigankou_gaku);
          this.searchList[i].kouwan_kasyo    = this.replaceComma(this.searchList[i].kouwan_kasyo);
          this.searchList[i].kouwan_gaku     = this.replaceComma(this.searchList[i].kouwan_gaku );
          this.searchList[i].kouen_kasyo     = this.replaceComma(this.searchList[i].kouen_kasyo);
          this.searchList[i].kouen_gaku      = this.replaceComma(this.searchList[i].kouen_gaku);
        }
        if(confirm('既存のデータを削除して、画面の値を登録しますか？')){
          var request_data = {
            saigai_yyyy: this.saigai_yyyy,
            saigai_ken: this.saigai_ken,
            shicyou_kbn: this.shicyou_kbn,
            loginId : window.localStorage.getItem('LOGIN_ID'),
            searchList : this.searchList,
            chk_date: this.chk_date
          };
          apiService.fa103updateData(request_data)
          .then(result => {
            this.search();
            this.info = result;
            this.isHasInfo = true;
            this.errorMsg = null;
            this.isHasError = false;
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.errorMsg = error;
            this.isHasError = true;
            this.error = error;
            console.log(error);
          });
        }

      },
      kaigankou_kasyo_change(index){
        this.searchList[index].kaigankou_kasyo = this.replaceComma(this.searchList[index].kaigankou_kasyo);
        if (this.isInteger(this.searchList[index].kaigankou_kasyo)) {
          this.searchList[index].kaigankou_kasyo = this.searchList[index].kaigankou_kasyo
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      kaigankou_gaku_change(index){
        this.searchList[index].kaigankou_gaku = this.replaceComma(this.searchList[index].kaigankou_gaku);
        if (this.isInteger(this.searchList[index].kaigankou_gaku)) {
          this.searchList[index].kaigankou_gaku = this.searchList[index].kaigankou_gaku
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      kouwan_kasyo_change(index){
        this.searchList[index].kouwan_kasyo = this.replaceComma(this.searchList[index].kouwan_kasyo);
        if (this.isInteger(this.searchList[index].kouwan_kasyo)) {
          this.searchList[index].kouwan_kasyo = this.searchList[index].kouwan_kasyo
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      kouwan_gaku_change(index){
        this.searchList[index].kouwan_gaku = this.replaceComma(this.searchList[index].kouwan_gaku);
        if (this.isInteger(this.searchList[index].kouwan_gaku)) {
          this.searchList[index].kouwan_gaku = this.searchList[index].kouwan_gaku
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      kouen_kasyo_change(index){
        this.searchList[index].kouen_kasyo = this.replaceComma(this.searchList[index].kouen_kasyo);
        if (this.isInteger(this.searchList[index].kouen_kasyo)) {
          this.searchList[index].kouen_kasyo = this.searchList[index].kouen_kasyo
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      kouen_gaku_change(index){
        this.searchList[index].kouen_gaku = this.replaceComma(this.searchList[index].kouen_gaku);
        if (this.isInteger(this.searchList[index].kouen_gaku)) {
          this.searchList[index].kouen_gaku = this.searchList[index].kouen_gaku
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      kaigankou_kasyo_focus(index){
        this.searchList[index].kaigankou_kasyo = this.replaceComma(this.searchList[index].kaigankou_kasyo);
      },
      kaigankou_gaku_focus(index){
        this.searchList[index].kaigankou_gaku = this.replaceComma(this.searchList[index].kaigankou_gaku);
      },
      kouwan_kasyo_focus(index){
        this.searchList[index].kouwan_kasyo = this.replaceComma(this.searchList[index].kouwan_kasyo);
      },
      kouwan_gaku_focus(index){
        this.searchList[index].kouwan_gaku = this.replaceComma(this.searchList[index].kouwan_gaku);
      },
      kouen_kasyo_focus(index){
        this.searchList[index].kouen_kasyo = this.replaceComma(this.searchList[index].kouen_kasyo);
      },
      kouen_gaku_focus(index){
        this.searchList[index].kouen_gaku = this.replaceComma(this.searchList[index].kouen_gaku);
      },
      formatComma: function(number){
      if(number == 0) {
            return '';
        }
        if(number == '' || number == null){
          return number;
        }
        var result = numeral(number).format("0,0");
        return result == 0 ? '': result;
      },
      replaceComma: function(string) {
        if(string == '' || string == null){
          return string;
        }
        return string.toString().trim().replace(/,/g,'');
      },
      clearReportNames(){
        this.is103 = false;
        this.totalName = '';
        this.$store.commit("fa103/setReportName",'');
      },
      changedYear(){
        if(this.jpYear != '' && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
          var matches = this.jpYear.match(/[a-zA-Z]+/g);
          var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
          var request_data = {	
            gengo_cd: matches ? matches.toString() : "",	
          }
          apiService.getGengoStartDate(request_data)	
          .then(result=>{
            if(result.length > 0) {
              var startDate = result[0].start_date;	
              this.saigai_yyyy = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);
              this.isOptionSelected = false;
              this.getSaigaiMeisyou(this.saigai_yyyy);
            } else {
              this.saigai_yyyy = '';
              this.clearData();
            }
          });
        }else {
          this.saigai_yyyy = '';
          this.clearData();
        }
        this.isYearNotFound = false;
        this.showTable = false;
        this.isHasKenNotFoundErr = false;
        this.isHasError = false;
      },
      clearData(){
        this.meisyouData = {};
        this.meisyouList = {};
        this.options={};
        this.saigai_ken="";        
        this.saigai_end="";
        this.saigai_start="";
        this.isupdated = false;
        this.shicyou_kbn = this.loginUser.shicyou_kbn;
        this.$store.commit("fa103/setReportName",'');
      },
      onChange(){
        this.isOptionSelected = true;
        this.ishoukokuExist = false;
        this.getSaigaiMeisyou(this.saigai_yyyy);
      },
      isInteger(value) {
        return /^\d+$/.test(value);
      },
      formatDate: function (date) {
        return moment(String(date)).format('YYYY/MM/DD');
      },
      convSaigaiKen(saigai_ken){
        if(!saigai_ken){
          return "";
        }else{
          return ( '00000' + saigai_ken ).slice(-5);
        }  
      },
    },
  }
</script>
