<template>
  <Layout>
    <main class="mb-3" id="fc104">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="error" class="err">{{ error }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <table>
              <tbody>
                <tr>
                  <td class="pr-2 pb-3 align-middle">災害年</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="jpYear"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pr-5 pb-3 align-middle">年</td>
                  <td class="pr-2 pb-3 align-middle">査定</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="assessment"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pb-3 align-middle">次</td>
                  <td class="pr-5 align-middle"></td>
                  <td class="pr-2 pb-3 align-middle">管理者</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="kanrisya_cd"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pb-3 align-middle" width="400px">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="kanrisha_mei"
                      style="max-width:500px"
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td class="pr-2 align-middle">工事番号</td>
                  <td class="align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="construction_no"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pr-5 align-middle"></td>
                  <td class="pr-2 align-middle">分冊</td>
                  <td class="align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="separate_volume"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              role="button"
              style="width:120px;"
              @click="back_btn()"
            >戻る</button>
          </div>
        </div>
        <div class="container mt-3 table-responsive">
          <div
            class="rounded p-2 text-center mb-3"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">申請</p>
          </div>
          <table class="table table-bordered table-sm">
            <thead class="table-dark">
              <tr>
                <th class="align-middle text-center border-white" width="8%">No.</th>
                <th class="align-middle text-center border-white" width="28%">区分</th>
                <th class="align-middle text-center border-white" width="13%">年災</th>
                <th class="align-middle text-center border-white" width="23%">工事番号</th>
                <th class="align-middle text-center border-white" width="28%">打切精算額(千円)</th>
              </tr>
            </thead>
            <tbody
              v-for="(kotukoNaiminaiten,index) in kotukoNaiminaitenList"
              :key="index"
              :value="index"
            >
              <tr>
                <td class="align-middle text-center" width="8%" height="35px">{{index+1}}</td>
                <td
                  class="align-middle bg-lightyellow"
                  width="28%"
                  height="35px"
                >{{ kotukoNaiminaiten.cd_value }}</td>
                <td
                  class="align-middle bg-lightyellow"
                  width="13%"
                  height="35px"
                >{{ kotukoNaiminaiten.nensai_jp }}</td>
                <td
                  class="align-middle bg-lightyellow"
                  width="23%"
                  height="35px"
                >{{ kotukoNaiminaiten.naimi_kojino}}</td>
                <td
                  class="align-middle bg-lightyellow"
                  width="28%"
                >{{ kotukoNaiminaiten.seisan_gaku }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container pt-3 clearfix border-top border-secondary">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              style="width:120px;"
              :disabled="this.disableInsertBtn"
              @click="insertData()"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              :disabled="this.disableUpDelBtn"
              @click="updateData()"
            >更新</button>
          </div>
        </div>
        <div class="container mt-3 table-responsive">
          <div
            class="float-left rounded p-2 text-center mb-3"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">査定</p>
          </div>
          <div class="form-group float-left">
            <p class="m-0 p-2 text-info">
              <font color="#CCFFFF">■</font>は各行項目に入力があった場合、必須となります。
            </p>
          </div>
          <table class="table text-center table-bordered table-sm">
            <thead class="table-dark">
              <tr>
                <th class="align-middle border-white" width="10%">No.</th>
                <th class="align-middle border-white" width="25%">区分</th>
                <th class="align-middle border-white" width="10%">年災</th>
                <th class="align-middle border-white" width="20%">工事番号</th>
                <th class="align-middle border-white" width="10%">分冊番号</th>
                <th class="align-middle border-white" width="25%">打切精算額(千円)</th>
              </tr>
            </thead>
            <tbody
              v-for="(saigaiNaiminaiten,index) in saigaiNaiminaitenList"
              :key="saigaiNaiminaiten.renban"
              :value="index"
            >
              <tr>
                <td class="align-middle" width="5%">{{ saigaiNaiminaiten.renban }}</td>
                <td class="align-middle" width="25%">
                  <div class="form-inline">
                    <select
                      class="form-control"
                      v-model="saigaiNaiminaiten.kbn"
                      style="width:100%;background-color: #CCFFFF"
                      
                    >
                      <option value></option>
                      <option
                        v-for="mcode in mcodeList"
                        :key="mcode.meisai"
                        :value="mcode.meisai"
                      >{{mcode.meisai}}<span> : </span>{{mcode.cd_value}}</option>
                    </select>
                  </div>
                </td>
                <td class="align-middle" width="10%">
                  <select class="form-control" v-model="saigaiNaiminaiten.nensai" style="width:100%;background-color: #CCFFFF" v-on:change="nensaiChange($event,index)">
                      <option value=""></option>
                       <option
                        v-for="nensai in nensaiList"
                        :key="nensai.nen"
                        :value="nensai.nen"
                      >{{nensai.gengo}}</option>
                    </select>
                </td>
                <td class="align-middle" width="20%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="saigaiNaiminaiten.naimi_kojino"
                    @blur="kojinoChange(index)"
                    style="width:100%;background-color: #CCFFFF"
                  />
                </td>
                <td class="align-middle" width="10%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="saigaiNaiminaiten.bunsatu"
                    @blur="bunsatuChange(index)"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle" width="25%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="saigaiNaiminaiten.seisan_gaku"
                    @blur="seisanGakuChange(index)"
                    @focus="removeComma(index)"
                    style="width:100%"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container pt-3 clearfix border-top border-secondary">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              :disabled="this.disableUpDelBtn"
              @click="deleteData()"
            >削除</button>
          </div>
        </div>
      </form>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from 'moment';
const apiService = new APIService();
var numeral = require("numeral");
export default {
  components: {
    Layout
  },
  data() {
    if (this.$route.query.kanrisya_cd == localStorage.getItem("Kanrisya_Cd")) {
      var kanrisha_mei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      jpYear:"",
      engYear: this.$route.query.saigai_yyyy,
      construction_no: this.$route.query.koji_no,
      separate_volume: this.$route.query.bunsatu_no,
      kanrisha_mei: kanrisha_mei,
      kanrisya_cd: this.$route.query.kanrisya_cd,
      assessment: "",
      kotukoNaiminaitenList: [],
      saigaiNaiminaitenList: [],
      mcodeList: [],
      nensaiList: [],
      info: "",
      error: "",
      validFlg: true,
      disableUpDelBtn: true,
      disableInsertBtn: true,
      post: null,
      chk_date: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.getGengo(this.engYear);
    this.getAssessment();
    this.getkotukoNaiminaiten();
    this.getsaigaiNaiminaiten();
    this.getMcodeList();
    this.getNensaiList();
    this.getKanrisyamei(this.kanrisya_cd);
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    getKanrisyamei(kanrisya_cd) {
      var request_data = {
        kanrisya_cd: kanrisya_cd
      };
      apiService
        .fc104getKanrisyamei(request_data)
        .then(result => {
          if (result.length > 0) {
            this.kanrisha_mei = result[0].kanrisya_mei;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAssessment() {
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.construction_no,
        bunsatu_no: this.separate_volume
      };
      apiService
        .fc104getAssessment(request_data)
        .then(result => {
          if (result.length > 0) {
            this.assessment = result[0].nji_satei;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getkotukoNaiminaiten() {
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.construction_no
      };
      apiService
        .fc104getkotukoNaiminaiten(request_data)
        .then(result => {
          this.kotukoNaiminaitenList = result;
          for (var i = 0; i < this.kotukoNaiminaitenList.length; i++) {
            this.kotukoNaiminaitenList[i].naimi_kojino = this.formatNumber(
              this.kotukoNaiminaitenList[i].naimi_kojino
            );
            this.kotukoNaiminaitenList[i].seisan_gaku = this.formatComma(
              this.kotukoNaiminaitenList[i].seisan_gaku
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getsaigaiNaiminaiten() {
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.construction_no,
        bunsatu_no: this.separate_volume
      };
      apiService
        .fc104getsaigaiNaiminaiten(request_data)
        .then(result => {
          this.saigaiNaiminaitenList = result.result;
          for (var i = 0; i < this.saigaiNaiminaitenList.length; i++) {
            this.saigaiNaiminaitenList[i].naimi_kojino = this.formatNumber(
              this.saigaiNaiminaitenList[i].naimi_kojino
            );
            if (this.saigaiNaiminaitenList[i].bunsatu !== 0) {
              this.saigaiNaiminaitenList[i].bunsatu = this.formatNumber(
                this.saigaiNaiminaitenList[i].bunsatu
              );
            }
            this.saigaiNaiminaitenList[i].seisan_gaku = this.formatComma(
              this.saigaiNaiminaitenList[i].seisan_gaku
            );
            this.saigaiNaiminaitenList[i].nensai = this.formatNumber(
              this.saigaiNaiminaitenList[i].nensai
            );
          }
          if (result.flg) {
            this.disableInsertBtn = true;
            this.disableUpDelBtn  = false;
          } else {
            this.disableInsertBtn = false;
            this.disableUpDelBtn  = true;
          }

          this.chk_date = result.chk_date; // 排他チェック用
        })
        .catch(error => {
          console.log(error);
        });
    },
    getMcodeList() {
      apiService
        .getMcodeList()
        .then(result => {
          this.mcodeList = result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getNensaiList() {
      apiService.getNensaiList()
      .then(result => {
        this.nensaiList = result;
      })
      .catch(error => {
        console.log(error);
      })
    },
    nensaiChange(nensai,index){
      var nensai_index = nensai.target.options[nensai.target.options.selectedIndex].text;
      if(nensai_index == '') {
        this.saigaiNaiminaitenList[index].nensai = '';
      } else {
        for(var i = 0; i < this.nensaiList.length; i++){
          if(this.nensaiList[i].nen == nensai_index){
            this.saigaiNaiminaitenList[index].nensai = nensai_index;
          }
        }
      }
    },
    kojinoChange(index) {
      this.saigaiNaiminaitenList[index].naimi_kojino = this.replaceComma(this.saigaiNaiminaitenList[index].naimi_kojino);
       if (this.isInteger(this.saigaiNaiminaitenList[index].naimi_kojino)) {
        this.saigaiNaiminaitenList[index].naimi_kojino = this.saigaiNaiminaitenList[index].naimi_kojino
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    bunsatuChange(index) {
      if (this.saigaiNaiminaitenList[index].bunsatu === '0') {
        this.saigaiNaiminaitenList[index].bunsatu = 0;
      } else {
        this.saigaiNaiminaitenList[index].bunsatu = this.replaceComma(this.saigaiNaiminaitenList[index].bunsatu);
        if (this.isInteger(this.saigaiNaiminaitenList[index].bunsatu)) {
          this.saigaiNaiminaitenList[index].bunsatu = this.saigaiNaiminaitenList[index].bunsatu
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
      
    },
    seisanGakuChange(index) {
      this.saigaiNaiminaitenList[index].seisan_gaku = this.replaceComma(this.saigaiNaiminaitenList[index].seisan_gaku);
      if (this.isInteger(this.saigaiNaiminaitenList[index].seisan_gaku)) {
        this.saigaiNaiminaitenList[index].seisan_gaku = this.saigaiNaiminaitenList[index].seisan_gaku
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    removeComma(index) {
      this.saigaiNaiminaitenList[index].seisan_gaku = this.replaceComma(
        this.saigaiNaiminaitenList[index].seisan_gaku
      );
    },
    insertData() {
      this.info = "";
      this.error = "";
      for (var i = 0; i < this.saigaiNaiminaitenList.length; i++) {
        if (
          this.saigaiNaiminaitenList[i].cd_value ||
          this.saigaiNaiminaitenList[i].kbn ||
          this.saigaiNaiminaitenList[i].nensai ||
          this.saigaiNaiminaitenList[i].naimi_kojino ||
          this.saigaiNaiminaitenList[i].bunsatu ||
          this.saigaiNaiminaitenList[i].seisan_gaku
        ) {
          if (!this.saigaiNaiminaitenList[i].kbn) {
            confirm(this.$messages.msg['0013E']);
            this.error =
              "連番" + (i + 1) + "の区分" + this.$messages.msg["00013W"];
            this.validFlg = false;
            break;
          }
          if (!this.saigaiNaiminaitenList[i].nensai) {
            confirm(this.$messages.msg['0013E']);
            this.error =
              "連番" + (i + 1) + "の年災" + this.$messages.msg["00013W"];
            this.validFlg = false;
            break;
          }
          if (!this.saigaiNaiminaitenList[i].naimi_kojino) {
            confirm(this.$messages.msg['0013E']);
            this.error =
              "連番" + (i + 1) + "の工事番号" + this.$messages.msg["00013W"];
            this.validFlg = false;
            break;
          }
          this.validFlg = true;
        }
      }
      for (var j = 0; j < this.saigaiNaiminaitenList.length; j++) {
        this.saigaiNaiminaitenList[j].seisan_gaku = this.replaceComma(
          this.saigaiNaiminaitenList[j].seisan_gaku
        );
      }
      if (this.validFlg) {
        this.error = "";
        var request_data = {
          saigai_yyyy: this.engYear,
          koji_no: this.construction_no,
          bunsatu_no: this.separate_volume,
          kanrisya_cd: this.kanrisya_cd,
          sakuseisya: window.localStorage.getItem('LOGIN_ID'),
          saigai_naiminaiten_list: this.saigaiNaiminaitenList,
          chk_date: this.chk_date
        };
        apiService
          .fc104insertSaigaiNaiminaiten(request_data)
          .then(result => {
            this.info = result;
            this.getsaigaiNaiminaiten();
            this.getMcodeList();
            this.getNensaiList();
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.info = "";
            this.error = error;
          });
      }
    },
    updateData() {
      this.info = "";
      this.error = "";
      for (var i = 0; i < this.saigaiNaiminaitenList.length; i++) {
        if (
          this.saigaiNaiminaitenList[i].cd_value ||
          this.saigaiNaiminaitenList[i].kbn ||
          this.saigaiNaiminaitenList[i].nensai ||
          this.saigaiNaiminaitenList[i].naimi_kojino ||
          this.saigaiNaiminaitenList[i].bunsatu ||
          this.saigaiNaiminaitenList[i].seisan_gaku
        ) {
          if (!this.saigaiNaiminaitenList[i].kbn) {
            confirm(this.$messages.msg['0013E']);
            this.error =
              "連番" + (i + 1) + "の区分" + this.$messages.msg["00013W"];
            this.validFlg = false;
            break;
          }
          if (!this.saigaiNaiminaitenList[i].nensai) {
            confirm(this.$messages.msg['0013E']);
            this.error =
              "連番" + (i + 1) + "の年災" + this.$messages.msg["00013W"];
            this.validFlg = false;
            break;
          }
          if (!this.saigaiNaiminaitenList[i].naimi_kojino) {
            confirm(this.$messages.msg['0013E']);
            this.error =
              "連番" + (i + 1) + "の工事番号" + this.$messages.msg["00013W"];
            this.validFlg = false;
            break;
          }
          this.validFlg = true;
        }
      }
      for (var j = 0; j < this.saigaiNaiminaitenList.length; j++) {
        this.saigaiNaiminaitenList[j].seisan_gaku = this.replaceComma(
          this.saigaiNaiminaitenList[j].seisan_gaku
        );
      }
      if (this.validFlg) {
        this.error = "";
        var request_data = {
          saigai_yyyy: this.engYear,
          koji_no: this.construction_no,
          bunsatu_no: this.separate_volume,
          kanrisya_cd: this.kanrisya_cd,
          sakuseisya: window.localStorage.getItem('LOGIN_ID'),
          saigai_naiminaiten_list: this.saigaiNaiminaitenList,
          chk_date: this.chk_date
        };
        console.log(request_data);
        apiService
          .fc104updateSaigaiNaiminaiten(request_data)
          .then(result => {
            this.error = "";
            this.info = result;
            this.getsaigaiNaiminaiten();
            this.getMcodeList();
            this.getNensaiList();
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.info = "";
            this.error = error;
          });
      }
    },
    deleteData() {
      this.info = "";
      this.error = "";
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.construction_no,
        bunsatu_no: this.separate_volume,
        loginId: window.localStorage.getItem('LOGIN_ID'),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fc104Delete(request_data)
          .then(result => {
            this.error = "";
            this.info = result;
            this.getsaigaiNaiminaiten();
            this.getMcodeList();
            this.getNensaiList();
          })
          .catch(error => {
            console.log(error);
            confirm(this.$messages.msg['0013E']);
            this.info = "";
            this.error = error;
          });
      }
    },
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? "" : result;
    },
    formatComma: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    }
  }
};
</script>