<script>
import AppLayout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { APIService } from "@/apiService.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
var numeral = require("numeral");

const apiService = new APIService();

export default {
  name: "FA104",
  data() {
    return {
      houkokusya_cd: "",
      selectedIndex: 0,
      showTable: false,
      meisyouData: {},
      meisyouList: {},
      isOptionSelected: false,
      options: [],
      gengoData: {},
      saigai_yyyy: "",
      jpYear: "",
      saigai_ken: "",
      saigai_end: "",
      saigai_start: "",
      saigai_mei: "",
      higaihoukokuList: {
        total: 10000
      },
      errorMsg: "",
      yearErrorMsg: "",
      kenErrorMsg: "",
      selectedOption: "",
      higaiselectedIndex: 0,
      checkedHigaiData: [],
      isHasYearErr: false,
      isHasKenNotFoundErr: false,
      loginUser: {
        login_id: window.localStorage.getItem("LOGIN_ID"),
        kanrisya_cd: window.localStorage.getItem("Kanrisya_Cd"),
        kanrisya_name: window.localStorage.getItem("Kanrisya_Mei"),
        shicyou_kbn: window.localStorage.getItem("shicyou_kbn")
      },
      isHasError: false,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    AppLayout,
    Loading
  },
  computed: {
    yearSV() {
      return this.$store.state.fa104.year;
    },
    kenSV() {
      return this.$store.state.fa104.ken;
    },
    kanrisya_cdSV() {
      return this.$store.state.fa104.kanrisya_cd;
    },
    hokokusya_cdSV() {
      return this.$store.state.fa104.hokokusya_cd;
    },
    pageSV() {
      return this.$store.state.fa104.page;
    },
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    if(this.yearSV) {
      this.saigai_yyyy = this.yearSV;
      this.saigai_ken = this.kenSV;
      this.isOptionSelected = true;
      this.selectedOption = this.kenSV;
      this.kanrisya_cd = this.kanrisya_cdSV;
      this.houkokusya_cd = this.hokokusya_cdSV;
      this.page = this.pageSV;
      this.getGengoSV(this.saigai_yyyy);
      // this.getHigaiMeisyou(this.saigai_yyyy);
      // setTimeout(() => {
      //   this.search(this.pageSV);
      // },2000)
    } else {
      this.saigai_yyyy = (new Date().getFullYear()).toString();
      this.getGengo(new Date().getFullYear());
      this.getHigaiMeisyou(new Date().getFullYear());
    }
  },
  filters: {
    formatDate: function(date) {
      if (date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
    },
    formatDateTime: function(date) {
      if (date != null) {
        return moment(String(date)).format("YYYY/MM/DD hh:mm");
      }
    },
    formatTime: function(time) {
      if (time != null) {
        return moment.utc(time * 3600 * 1000).format("HH:mm");
      }
    }
  },
  methods: {
    getGengoSV(year){	
      this.info = '';	
      this.isHasError = false;	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format('YYYYMMDD');	
      var request_data = {	
        date: param,	
      };	
      apiService.getGengo(request_data)	   
      .then(result=>{	
        this.jpYear = result;	
        this.getHigaiMeisyouSV(year);
      });	
    },
    getHigaiMeisyouSV(year) {
      var request_data = {
        year: year
      };
      apiService.fa104GetHigaiMeisyou(request_data).then(result => {
        this.options = {};
        if (result.length > 0) {
          this.kenErrorMsg = '';
          this.meisyouList = result;
          this.meisyouList.unshift({saigai_yyyy: null, saigai_ken: null, saigai_mei: null, saigai_start: null, saigai_end: null})
          this.isHasError = false;
          this.errorMsg = "";
          for (var i = 0; i < this.meisyouList.length; i++) {   
            this.options[i] = this.meisyouList[i]; 
            if (this.isOptionSelected) {
              if (this.selectedOption == this.meisyouList[i].saigai_ken) {
                this.selectedIndex = i;
              }
            } else {
              this.selectedIndex = i;
            }
          }
          this.selectedOption = this.options[this.selectedIndex].saigai_ken; 
          this.meisyouData = this.meisyouList[this.selectedIndex];
          if(this.selectedIndex === 0){
            this.saigai_start = "";
            this.saigai_end ="";
            this.saigai_ken = "";
            this.saigai_mei = ""; 
          }else{
            this.saigai_start = this.formatDate(this.meisyouData.saigai_start);
            this.saigai_end = this.formatDate(this.meisyouData.saigai_end);
            this.saigai_ken = this.meisyouData.saigai_ken;
            this.saigai_mei = this.meisyouData.saigai_mei; 
          }
 
        } else {
          this.clearData();
        }
        this.search(this.pageSV);
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
        this.isHasError = true;
        this.errorMsg = error;
        console.log(error);
      });
    },
    getGengo(year){	
      this.info = '';	
      this.isHasError = false;	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format('YYYYMMDD');	
      var request_data = {	
        date: param,	
      };	
      apiService.getGengo(request_data)	   
      .then(result=>{	
        this.jpYear = result;	
      });	
    },
    getHigaiMeisyou(year) {
      var request_data = {
        year: year
      };
      apiService.fa104GetHigaiMeisyou(request_data).then(result => {
        this.options = {};
        if (result.length > 0) {
          this.kenErrorMsg = '';
          this.meisyouList = result;
          this.meisyouList.unshift({saigai_yyyy: null, saigai_ken: null, saigai_mei: null, saigai_start: null, saigai_end: null})
          this.isHasError = false;
          this.errorMsg = "";
          for (var i = 0; i < this.meisyouList.length; i++) {   
            this.options[i] = this.meisyouList[i]; 
            if (this.isOptionSelected) {
              if (this.selectedOption == this.meisyouList[i].saigai_ken) {
                this.selectedIndex = i;
              }
            } else {
              this.selectedIndex = i;
            }
          }
          this.selectedOption = this.options[this.selectedIndex].saigai_ken; 
          this.meisyouData = this.meisyouList[this.selectedIndex];
          if(this.selectedIndex === 0){
            this.saigai_start = "";
            this.saigai_end ="";
            this.saigai_ken = "";
            this.saigai_mei = ""; 
          }else{
            this.saigai_start = this.formatDate(this.meisyouData.saigai_start);
            this.saigai_end = this.formatDate(this.meisyouData.saigai_end);
            this.saigai_ken = this.meisyouData.saigai_ken;
            this.saigai_mei = this.meisyouData.saigai_mei; 
          }
 
        } else {
          this.clearData();
        }
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
        this.isHasError = true;
        this.errorMsg = error;
        console.log(error);
      });
    },
    changedYear() {
      // if (this.saigai_yyyy != "") {
      //   this.isOptionSelected = false;
      //   this.getHigaiMeisyou(this.saigai_yyyy);
      // } else {
      //   this.clearData();
      // }
      if(this.jpYear != '' && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
        var request_data = {	
          gengo_cd: matches ? matches.toString() : "",	
        }
        apiService.getGengoStartDate(request_data)	
        .then(result=>{
          if(result.length > 0) {
            var startDate = result[0].start_date;	
            this.saigai_yyyy = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);
            this.isOptionSelected = false;
            this.getHigaiMeisyou(this.saigai_yyyy);	
          } else {
            this.saigai_yyyy = null;
            this.clearData();
          }
        });
      } else {
        this.saigai_yyyy = null;
        this.clearData();
      }
    },
    onChange() {
      this.isOptionSelected = true;
      this.getHigaiMeisyou(this.saigai_yyyy);
    },
    formatInteger: function(number){
      if(number == '' || number == null){
        return '';
      }
      var result = numeral(number).format("0");
      return result == 0 ? '': result;
    },
    formatNumber: function(number) {
      if(number == '' || number == null){
        return '';
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? '': result;
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    search(page) {
      this.isLoading = true;
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.yearErrorMsg = this.$messages.msg["0005E"];
        this.isHasYearErr = true;
        this.isLoading = false;
      } else {
        this.higaiselectedIndex = 0;
        this.isHasYearErr = false;
        if (this.meisyouList.length > 0) {
          if (typeof page == undefined) {
            page = 1;
          }
          this.page = page;
          var request_data = {
            saigai_yyyy: this.saigai_yyyy,
            saigai_ken: this.meisyouData.saigai_ken,
            kanrisya_cd: this.loginUser.kanrisya_cd,
            houkokusya_cd: this.houkokusya_cd,
            page: page
          };
          apiService.fa104Search(request_data).then(result => {
            this.higaihoukokuList = result;
            if (this.higaihoukokuList.data.length > 0) {
              this.isHasError = false;
              this.showTable = true;
            } else {
              confirm(this.$messages.msg['0013E']);
              this.isHasError = true;
              this.showTable = false;
              this.errorMsg = this.$messages.msg["0009E"];
              this.isLoading = false;
            }
            this.checkedHigaiData = this.higaihoukokuList.data[0];
            this.isLoading = false;
          }).catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.isLoading = false;
            this.errorMsg = error;
            console.log(error);
          });
          this.isHasYearErr = false;
          this.isHasKenNotFoundErr = false;
          this.kenErrorMsg = '';
        } else {
          confirm(this.$messages.msg['0013E']);
          this.kenErrorMsg = this.$messages.msg["0004E"];
          this.isHasKenNotFoundErr = true;
          this.isLoading = false;
        }
      }
    },
    setCheckedHigaiData(higaiData) {
      this.checkedHigaiData = higaiData;
    },
    insert() {
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.yearErrorMsg = this.$messages.msg["0005E"];
        this.isHasYearErr = true;
      } else if (this.saigai_ken == "") {
        confirm(this.$messages.msg['0013E']);
        this.kenErrorMsg = this.$messages.msg["0004E"];
        this.isHasKenNotFoundErr = true;
      } else {
        this.setState();
        this.$router.push({
          name: "FA112",
          query: {
            saigai_yyyy: this.saigai_yyyy,
            saigai_ken: this.saigai_ken,
            kubun: 1
          }
        });
      }
    },
    update(higaiData) {
      this.setCheckedHigaiData(higaiData);
      this.setState();
      this.$router.push({
        name: "FA112",
        query: {
          saigai_yyyy: this.saigai_yyyy,
          saigai_ken: this.saigai_ken,
          hou: this.checkedHigaiData.hou
        }
      });
    },
    setState() {
      this.$store.commit("fa104/setYear", this.saigai_yyyy);
      this.$store.commit("fa104/setKen", this.meisyouData.saigai_ken);
      this.$store.commit("fa104/setKanrisya", this.loginUser.kanrisya_cd);
      this.$store.commit("fa104/setHokokusya", this.houkokusya_cd);
      this.$store.commit("fa104/setPage", this.page);
    },
    clearData() {
      this.meisyouData = {};
      this.meisyouList = {};
      this.options = {};
      this.saigai_ken = "";
      this.saigai_end = "";
      this.saigai_start = "";
      this.saigai_mei = "";
    },
    convHou(hou_cd){
      if(hou_cd != null){
        return "第" + hou_cd + "報";
      } else {
        return "";
      }
    },
    convHoukokuStatus(houkoku_status){
      if(houkoku_status == '1'){
        return "解除中";
      } else if(houkoku_status == '2'){
        return "〆切";
      } else {
        return "";
      }
    },
    convSaigaiKen(saigai_ken){
      if(!saigai_ken){
         return "";
      }else{
        return ( '00000' + saigai_ken ).slice(-5);
      }  
    },
  }
};
</script>
<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-md-12 bg-err text-center">
        <span class="err" v-show="isHasError">{{ errorMsg }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
        <form class="form-inline float-right">
          <button type="button" class="btn btn-light" style="width:120px;" @click="insert()">新規</button>
        </form>
      </div>
      <div class="container mb-3 mt-3">
        <form action method>
          <div class="form-group row" style="width:100%; max-width:500px">
            <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
            <div
              class="col-sm pr-0 pl-0"
              :class="isHasYearErr?'mb-0':''"
              style="width:100%; max-width:60px"
            >
              <input
                type="tel"
                class="form-control bg-required"
                @change="changedYear()"
                v-model="jpYear"
                maxlength="4"
              />
            </div>
          </div>
          <div class="offset-sm-1 pl-4 mb-2">
            <span class="err" v-show="isHasYearErr">{{ this.yearErrorMsg }}</span>
          </div>
          <div class="form-group row" :class="kenErrorMsg ? 'mb-0' : ''">
            <label
              for
              class="col-sm col-form-label"
              :class="isHasKenNotFoundErr?'mb-0':''"
              style="width:100%; max-width:130px"
            >災害番号</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:350px">
              <select
                @change="onChange(selectedOption, options)"
                class="form-control bg-required"
                v-model="selectedOption"
              >    
                <option v-for="option in options" :key="option.saigai_ken" :value="option.saigai_ken">
                  {{convSaigaiKen(option.saigai_ken)}}<span v-if="option.saigai_ken != null"> : </span>{{option.saigai_mei}}
                </option>
              </select>
            </div>
            <label
              for
              class="col-sm col-form-label text-right"
              style="width:100%; max-width:120px"
            >災害発生日</label>
            <div class="col-sm pr-0" style="width:100%; max-width:130px">
              <input type="text" class="form-control" v-model="saigai_start" readonly />
            </div>
            <label
              for
              class="col-sm col-form-label text-center"
              style="width:100%; max-width:50px"
            >〜</label>
            <div class="col-sm pl-0" style="width:100%; max-width:130px">
              <input type="text" class="form-control" v-model="saigai_end" readonly />
            </div>
          </div>
          <div class="offset-sm-1 pl-4 mb-2">
            <span class="err" v-show="isHasKenNotFoundErr">{{ this.kenErrorMsg }}</span>
          </div>
          <div class="form-group row" style>
            <button
              type="button"
              class="btn btn-light d-block mx-auto"
              @click="search"
              style="width:120px;"
            >検索</button>
          </div>
        </form>
      </div>
      <div class="container pt-3 border-top border-secondary" v-show="showTable">
        <p class="text-info" v-show="showTable">修正・削除行を選択して下さい</p>
        <div class="container" v-show="showTable">
          <div class="row">
            <div class="col-sm-4">
              <table class="table-sm">
                <tr>
                  <td class="align-middle">検索結果</td>
                  <td
                    width="130px"
                    class="bg-light text-center table-bordered align-middle"
                  >{{higaihoukokuList.total}}</td>
                  <td class="text-center align-middle">件</td>
                </tr>
              </table>
            </div>
            <div class="col-sm-4">
              <b-pagination
              v-model="higaihoukokuList.current_page"
              :total-rows="higaihoukokuList.total"
              :data="higaihoukokuList.data"
              :per-page="higaihoukokuList.per_page"
              @change="search"
            ></b-pagination>
            </div>
            <div class="col-sm-4 text-right align-middle">
              <p class="mb-0">{{higaihoukokuList.current_page}}/{{higaihoukokuList.last_page}}項</p>
            </div>
          </div>
          <div class="container mt-2 table-responsive">
            <table class="table text-center table-bordered table-sm" style="table-layout:fixed;">
              <thead class="bg-primary text-light">
                <tr>
                  <th class="align-middle" width="8%"></th>
                  <th class="align-middle" width="8%">報</th>
                  <th class="align-middle" width="10%">被害報告<br>入力</th>
                  <th class="align-middle" width="20%">報告者</th>
                  <th class="align-middle" width="17%">記入日時</th>
                  <th class="align-middle" width="20%">更新者</th>
                  <th class="align-middle" width="17%">更新日時</th>
                </tr>
              </thead>
              <tbody
                v-for="(higaiData,index) in higaihoukokuList.data"
                :key="higaiData.houkokusya_cd"
                :value="higaiData.houkokusya_cd"
              >
                <tr :class="index%2 != 0 ? 'table-secondary':''">
                  <td rowspan="2" class="align-middle" height="35px">
                    <button class="btn btn-light" type="button" @click="update(higaiData)" style="width:58px;height:35px;font-size:14px;" >選択</button>
                  </td>
                  <td rowspan="2" class="align-middle">{{convHou(higaiData.hou)}}</td>
                  <td rowspan="2" class="align-middle">{{convHoukokuStatus(higaiData.houkoku_status)}}</td>
                  <td
                    class="align-middle"
                    height="45px"
                    style="overflow:hidden;"
                  >{{higaiData.houkokusya_mei}}</td>
                  <td
                    class="align-middle"
                  >{{higaiData.kinyubi|formatDate}} {{higaiData.kinyuji|formatTime}}</td>
                  <td class="align-middle" width="16%" height="35px">{{higaiData.login_name}}</td>
                  <td
                    class="align-middle"
                    width="16%"
                    height="45px"
                  >{{higaiData.koushinbi|formatDateTime}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>