<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasError"  class="err">{{ this.errorMsg }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
        <form class="form-inline float-right">
          <button type="button" class="btn btn-light" style="width:120px;" :disabled="kanrisya_all" @click="newUser()">新規</button>
        </form>
      </div>
      <div class="container mb-3">
        <form action method>
          <div class="form-group row" style="width:100%;">
            <label for="" class="col-sm col-form-label" style="width: 100%;max-width: 130px;">ログインID</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px;">
              <input type="tel" class="form-control" v-model='login_id'/>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:130px"></div>
            <label for class="col-sm col-form-label text-left" style="width:100%; max-width:115px">有効フラグ</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:10px"></div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="valid_flg" v-bind:value="2">
              <label class="form-check-label" for="">すべて</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="valid_flg" v-bind:value="1" checked>
              <label class="form-check-label" for="">有効</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="valid_flg" v-bind:value="0"> 
              <label class="form-check-label" for="">無効</label>
            </div>
          </div>
          <div class="form-group row">
            <label for="" class="col-sm col-form-label" style="width: 100%; max-width: 130px;">ログイン名</label>
            <div class="col-sm pr-0 pl-0" style="width: 100%;max-width: 250px;">
              <input type="tel" class="form-control" style="max-width: 250px;" v-model='login_name'>
            </div>
          </div>
          <div class="form-group row" style="width: 100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:300px">
              <select
                id
                class="form-control bg-required"
                v-model="kanrisya_cd"
                @change="kanrisyaChange(kanrisya_cd)"
              >
                <option
                  v-for="kanrisya in kanrisyaList"
                  :key="kanrisya.kanrisya_cd"
                  :value="kanrisya.kanrisya_cd"
                >{{kanrisya.kanrisya_cd}}：{{kanrisya.kanrisya_mei}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm col-form-label" style="width: 100%; max-width: 130px;">権限区分</label>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="0" /><label for="" class="form-check-label">すべて</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="1" /><label for="" class="form-check-label">河川課</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="2" /><label for="" class="form-check-label">県庁各課</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="3" /><label for="" class="form-check-label">茨城県土木・工事事務所及び県内市町村</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="4" /><label for="" class="form-check-label">公社</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="5" /><label for="" class="form-check-label">被災現場確認</label>
            </div>
          </div>
          <button type="button" class="btn btn-light d-block mx-auto" @click="search" style="width:120px;">検索</button>
        </form>
      </div>
      <div class="container pt-3 border-secondary" v-show="showTable">
        <p class="text-info">修正・削除行を選択して下さい。</p>
      </div>
      <div class="container" v-show='showTable'>
        <div class="row">
          <div class="col-sm-4">
            <table class="table-sm">
              <tr>
                <td class="align-middle">検索結果</td>
                <td width="40px" class="bg-light text-center table-bordered align-middle">{{userDataList.total}}</td>
                <td class="text-center align-middle">件</td>
              </tr>
            </table>
          </div>
          <div class="col-sm-4">
            <b-pagination
              v-model="userDataList.current_page"
              :total-rows="userDataList.total"
              :data="userDataList.data"
              :per-page="userDataList.per_page"
              @change="search"
            ></b-pagination>
          </div>
          <div class="col-sm-4 text-right align-middle">
            <p class="mb-0 mt-2">{{userDataList.current_page}}/{{userDataList.last_page}}項</p>
          </div>
        </div>
      </div>
      <div class="container mt-2 table-responsive" v-show="showTable">
        <table class="table text-center table-bordered table-sm">
          <thead class="bg-primary text-light">
            <tr>
              <th rowspan="2" class="align-middle" width="5%"></th>
              <th scope class="align-middle" width="19%">ログインID</th>
              <th class="align-middle" width="30%">ログイン名＋氏名</th>
              <th class="align-middle" width="12%">管理者</th>
              <th class="align-middle" width="24%">権限区分</th>
              <th class="align-middle" width="10%">有効フラグ</th>
            </tr>
          </thead>
          <tbody v-for="(userData, index) in userDataList.data" :key="userData.seirinao" :value="userData.seirinao">
            <tr :class="index%2 != 0 ? 'table-secondary':''">
              <td class="align-middle">
                <button class="btn btn-light" type="button" @click="goFD102(userData)" style="width:40px;height:66px;font-size:14px;" >選<br>択</button>
              </td>
              <td class="align-middle text-center">{{userData.login_id}}</td>
              <td class="align-middle text-left">{{userData.login_name}}&nbsp;{{userData.shimei}}</td>
              <td class="align-middle text-left">{{userData.kanrisya_cd}}&nbsp;{{userData.kanrisya_mei}}</td>
              <td class="align-middle text-left">{{userData.kengen}}</td>
              <td class="align-middle text-left">{{userData.valid}}</td>
            </tr>  
          </tbody>
        </table>
      </div>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from '@/apiService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();

var numeral = require("numeral");
export default {
  name: "FD101",
  components: {
    Layout,Loading
  },
  data() {
    var kanCd = '';
    var kanMei = '';
    if(this.authorityAdmin()) {
      kanCd = '0';
      kanMei = 'すべて';
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      required: this.$messages.msg['0004W'],
      selectedIndex: 0,
      showTable:false,
      meisyouData: {},
      meisyouList: {},
      kanrisyaList: [],
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      kanrisya_all: false,
      login_id:localStorage.getItem("LOGIN_ID"),
      login_name:localStorage.getItem("LOGIN_NAME"),
      valid_flg: 1,
      kengen_cd: localStorage.getItem("kengen_cd"),
      userDataList: {
        total: 10000
      },
      errorMsg: '',
      checkeduserData: [],
      isHasError: false,
      isLoading: false,
      fullPage: true,
      searchFlg: false,
    }
  },
  computed: {
    loginIdSV() {
      return this.$store.state.fd101.login_id;
    },
    loginNameSV() {
      return this.$store.state.fd101.login_name;
    },
    kanrisya_cdSV() {
      return this.$store.state.fd101.kanrisya_cd;
    },
    kanrisya_meiSV() {
      return this.$store.state.fd101.kanrisya_mei;
    },
    validFlg_SV() {
      return this.$store.state.fd101.valid_flg;
    },
    kengenCd_SV() {
      return this.$store.state.fd101.kengen_cd;
    },
    pageSV() {
      return this.$store.state.fd101.page;
    },
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    if(this.loginIdSV) {
      this.isSuccessMsgShow = (this.$route.params.message) ? true : false;
      this.isMsgShow = false;
      this.login_id = this.loginIdSV;
      this.login_name = this.loginNameSV;
      this.kanrisya_cd = this.kanrisya_cdSV;
      this.kanrisya_mei = this.kanrisya_meiSV;
      this.valid_flg = this.validFlg_SV;
      this.kengen_cd = this.kengenCd_SV;
      this.page = this.pageSV;
      this.getKanrisya();
      this.search(1);
      this.isSuccessMsgShow = (this.$route.params.message) ? true : false;
      this.isMsgShow = false;
    } else {
      this.isSuccessMsgShow = (this.$route.params.message) ? true : false;
      this.isMsgShow = false;
      this.getKanrisya();
      this.search(1);
    }
  },
  filters: {
    formatNumber: function(number){
      return  numeral(number).format("0,0");
    }
  },
  methods: {
    searchButton(){
      this.search(1);
    },
    search(page) {
      this.isLoading = true;
      if(typeof(page)== undefined){
        page = 1;
      }
      this.page = page;
      var request_data = {
        login_id: this.login_id,
        login_name: this.login_name,
        kanrisya_cd: this.kanrisya_cd,
        valid_flg: this.valid_flg,
        kengen_cd: String(this.kengen_cd),
        page: page,
        searchFlg: this.searchFlg,
      };
      apiService.fd101Search(request_data)
      .then(result=> {
        this.userDataList = result;
        if(this.userDataList.data.length>0){
          this.showTable = true;
          this.isHasError = false;
          this.checkeduserData = this.userDataList.data[0];
        } else {
          confirm(this.$messages.msg['0013E']);
          this.isHasError = true;
          this.showTable = false;
          this.errorMsg = this.$messages.msg['0002E'];
        }
      });
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    kanrisyaChange(){
      var kanrisya_id = this.kanrisyaList.filter(i => i.kanrisya_cd == this.kanrisya_cd);
      this.kanrisya_mei = kanrisya_id[0].kanrisya_mei;
      this.kanrisya_all =false;
    },
    goFD102(userData) {
      this.checkeduserData = userData;
      this.setState();
      this.$router.push({
        name: 'FD102',
        query: {
          param_kubun: 2,
          login_id:this.checkeduserData.login_id,
          }
      });
    },
    newUser(){
      this.setState();
      this.$router.push({
        name: 'FD102',
        query:{
          param_kubun: 1,
          login_id:localStorage.getItem("LOGIN_ID"),
        }
      });
    },
    getKanrisya(){
      var request_data = {
        kengen_cd: localStorage.getItem('kengen_cd'),
        kanrisya_cd: localStorage.getItem('Kanrisya_Cd'),
      }
      apiService.getKanrisyaByFlg(request_data)
      .then(result => {
        this.kanrisyaList = result;
        this.kanrisya_all = false;
      })
      .catch(error => {
        this.err = error;
      })
    },
    authorityAdmin(){
      var kengen_cd = localStorage.getItem('kengen_cd');
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    setState() {
      this.$store.commit("fd101/setLoginId", this.login_id);
      this.$store.commit("fd101/setLoginName", this.login_name);
      this.$store.commit("fd101/setKanrisya", this.kanrisya_cd);
      this.$store.commit("fd101/setKanrisyaMei", this.kanrisya_mei);
      this.$store.commit("fd101/setValidFlg", this.valid_flg);
      this.$store.commit("fd101/setKengenCd", this.kengen_cd);
      this.$store.commit("fd101/setPage", this.page);
    },
  },
};
</script>
