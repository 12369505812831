<script>
import AppLayout from "@/components/layouts/Layout.vue";
import datepicker from 'vuejs-datepicker';
import moment from 'moment';
import {ja} from 'vuejs-datepicker/dist/locale';
import { APIService } from '@/apiService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();

export default {
  name: "FD102",
  components: {
    AppLayout, datepicker, Loading
  },
  
  data() {
  	if (this.$route.query.param_kubun == 1) {
  		if(localStorage.getItem('kengen_cd') == this.$constants.KENGEN_CD.ADMIN) {
  			return {
		      login_id: '',
		      old_login_id:'',
		      login_name: '',
		      kanrisya_cd: '',
		      kengen_cd: '1',
		      password: '',
		      old_password: '',
		      valid_flg:'1',
		      shimei: '',
		      renrakusaki: '',
		      kanrisyaList: [],
		      errors: {
		          login_id: null,
			      login_name: null,
			      kanrisya_cd: null,
			      kengen_cd: null,
			      password: null,
			      valid_flg: null,
			      shimei: null,
			      renrakusaki: null,
		      },
		      unique_error: null,
		      unique_show: false,
		      disableUpDelBtn: true,
		      disableInsertBtn: true,
		      info: null,
		      regist_status: '1',
		      isLoading: false,
		      fullPage: true,
		      chk_date: ""
		    }
  		} else {
  			return {
		      login_id: '',
		      old_login_id:'',
		      login_name: '',
		      kanrisya_cd: '',
		      kengen_cd: '5',
		      password: '',
		      old_password: '',
		      valid_flg: '1',
		      shimei: '',
		      renrakusaki: '',
		      kanrisyaList: [],
		      errors: {
		          login_id: null,
			      login_name: null,
			      kanrisya_cd: null,
			      kengen_cd: null,
			      password: null,
			      valid_flg: null,
			      shimei: null,
			      renrakusaki: null,
		      },
		      unique_error: null,
		      unique_show: false,
		      disableUpDelBtn: true,
		      disableInsertBtn: true,
		      info: null,
		      regist_status: '1',
		      isLoading: false,
		      fullPage: true,
		      chk_date: ""
		    }
  		}
  		
  	} else {
  		return {
	      login_id: this.$route.query.login_id,
	      old_login_id:'',
	      login_name: '',
	      kanrisya_cd: '',
	      kengen_cd: '',
	      password: '',
	      old_password: '1',
	      valid_flg: '',
	      shimei: '',
	      renrakusaki: '',
	      kanrisyaList: [],
	      errors: {
	          login_id: null,
		      login_name: null,
		      kanrisya_cd: null,
		      kengen_cd: null,
		      password: null,
		      valid_flg: null,
		      shimei: null,
		      renrakusaki: null,
	      },
	      unique_error: null,
	      unique_show: false,
	      disableUpDelBtn: true,
	      disableInsertBtn: true,
	      info: null,
	      regist_status: '2',
	      isLoading: false,
	      fullPage: true,
	      chk_date: ""
	    }
  	}
    
  },
  created() {
    this.isLoading = true;
    this.getKanrisya();
  },
  mounted() {
  
  	if (this.$route.query.param_kubun == 1 && localStorage.getItem('kengen_cd') == this.$constants.KENGEN_CD.GENBATOUROKU) {
  		this.getMaxLoginID();
  	}
  	
  	if(this.$route.query.param_kubun == 2) {
  		this.login_id = this.$route.query.login_id;
  		this.search(this.login_id);
  	}
    this.isLoading = false;
  },
  methods: {
  	getMaxLoginID(){
      apiService.fd102GetMaxLoginId()
      .then(result => {
        this.login_id = result;
        this.password = this.login_id;
      })
      .catch(error => {
      	this.unique_error = error;
      	this.unique_show = true;
        console.log(error);
      })
    },
  	getKanrisya(){
      apiService.getKanrisya()
      .then(result => {
        this.kanrisyaList = result;
      })
      .catch(error => {
      	this.isLoading = false;
      	this.unique_error = error;
      	this.unique_show = true;
        console.log(error);
      })
    },
    search(loginId){
      this.clearError();
      var request_data = {
        login_id: loginId,
      }

      apiService.fd102Search(request_data)
        .then(result => {
          var login_list = result.data;
          if(login_list && login_list.length > 0){
            this.login_id = login_list[0].login_id;
            this.old_login_id = login_list[0].login_id;
            this.login_name = login_list[0].login_name;
            this.kanrisya_cd = login_list[0].kanrisya_cd;
            this.kengen_cd = login_list[0].kengen_cd;
            this.valid_flg = login_list[0].valid_flg;
            this.shimei = login_list[0].shimei;
            this.renrakusaki = login_list[0].renrakusaki;
            this.chk_date = result.chk_date;
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.unique_show = true;
          this.unique_error = error;
          console.log(error);
        });
    },
    insert_btn(){
      this.isLoading = true;
      this.clearError();
      this.info = null;
      var request_data = {
        login_id: this.login_id,
        login_name: this.login_name,
        kanrisya_cd: this.kanrisya_cd,
        kengen_cd: this.kengen_cd,
        password: this.password,
        valid_flg: this.valid_flg,
        shimei: this.shimei,
        renrakusaki: this.renrakusaki,
        regist_login_id: localStorage.getItem('LOGIN_ID')
      }

      apiService.fd102Insert(request_data)
        .then(result => {
          this.info = result;
          this.regist_status = '2';
          this.search(this.login_id);
        })
        .catch(error => {
          if(error.errors) {
            this.unique_show = false;
            confirm(this.$messages.msg['0013E']);
            this.errors.login_id = (error.errors.login_id) ? error.errors.login_id[0] : '';
            this.errors.login_name = (error.errors.login_name) ? error.errors.login_name[0] : '';
            this.errors.kanrisya_cd = (error.errors.kanrisya_cd) ? error.errors.kanrisya_cd[0] : '';
            this.errors.kengen_cd = (error.errors.kengen_cd) ? error.errors.kengen_cd[0] : '';
            this.errors.password = (error.errors.password) ? error.errors.password[0] : '';
            this.errors.valid_flg = (error.errors.valid_flg) ? error.errors.valid_flg[0] : '';
            this.errors.shimei = (error.errors.shimei) ? error.errors.shimei[0] : '';
            this.errors.renrakusaki = (error.errors.renrakusaki) ? error.errors.renrakusaki[0] : '';
          } else {
            confirm(this.$messages.msg['0013E']);
            this.unique_show = true;
          }
          this.unique_error = error;
          this.isLoading = false;
        });
    },
    update_btn(){
      this.clearError();
      this.isLoading = true;
      this.info = null;
      var request_data = {
        login_id: this.login_id,
        old_login_id: this.old_login_id,
        login_name: this.login_name,
        kanrisya_cd: this.kanrisya_cd,
        kengen_cd: this.kengen_cd,
        password: this.password,
        valid_flg: this.valid_flg,
        shimei: this.shimei,
        renrakusaki: this.renrakusaki,
        regist_login_id: localStorage.getItem('LOGIN_ID'),
        chk_date: this.chk_date
      }
      console.log(request_data)
      apiService.fd102Update(request_data)
        .then(result => {
          console.log(result)
          this.info = result;
          this.search(this.login_id);
        })
        .catch(error => {
          if(error.errors) {
            this.unique_show = false;
            this.unique_error = error;
            confirm(this.$messages.msg['0013E']);
            this.errors.login_id = (error.errors.login_id) ? error.errors.login_id[0] : '';
            this.errors.login_name = (error.errors.login_name) ? error.errors.login_name[0] : '';
            this.errors.kanrisya_cd = (error.errors.kanrisya_cd) ? error.errors.kanrisya_cd[0] : '';
            this.errors.kengen_cd = (error.errors.kengen_cd) ? error.errors.kengen_cd[0] : '';
            this.errors.password = (error.errors.password) ? error.errors.password[0] : '';
            this.errors.valid_flg = (error.errors.valid_flg) ? error.errors.valid_flg[0] : '';
            this.errors.shimei = (error.errors.shimei) ? error.errors.shimei[0] : '';
            this.errors.renrakusaki = (error.errors.renrakusaki) ? error.errors.renrakusaki[0] : '';
          } else {
            confirm(this.$messages.msg['0013E']);
            this.unique_show = true;
          }
          this.unique_error = error;
          this.isLoading = false;
        });
    },
    delete_btn(){
      this.clearError();
      this.info = null;
      var request_data = {
        old_login_id: this.old_login_id,
        chk_date: this.chk_date
      }  
      if(confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")){
        apiService.fd102Delete(request_data)
        .then(result => {
          this.info = result;
          //this.search(this.login_id);

          var date = new Date();
          var param = moment(String(date)).format("Y-m-d H:i:s");

          this.chk_date=param;
          this.login_id= '';
		      this.old_login_id='';
		      this.login_name= '';
		      this.kanrisya_cd= '';
		      this.kengen_cd= '1';
		      this.password= '';
		      this.old_password= '';
		      this.valid_flg='1';
		      this.shimei= '';
		      this.renrakusaki= '';
		      this.regist_status= '1';
        })
        .catch(error => {
          console.log(error)
          confirm(this.$messages.msg['0013E']);
          this.unique_error = error;
          this.unique_show  = true;
          this.isLoading    = false;
        });
      }
    },
    changeRegistStatus(regist_status) {
      if(regist_status == '1'){
        this.disableInsertBtn = false;
        this.disableUpDelBtn = true;
        this.saigai_yyyy = (new Date().getFullYear()).toString();
        this.setSinkiData(this.saigai_yyyy);
      } else {
        this.isLoading = true;
        this.disableInsertBtn = true;
        this.disableUpDelBtn = false;
        this.search(this.saigai_yyyy);
        this.getGengo(this.saigai_yyyy);
      }
    },
    clearError() {
      this.unique_error = null,
      this.unique_show = false,
      this.errors.login_id = null,
      this.errors.login_name = null,
      this.errors.kanrisya_cd = null,
      this.errors.kengen_cd = null,
      this.errors.password = null,
      this.errors.valid_flg = null,
      this.errors.shimei = null,
      this.errors.renrakusaki = null
    },
    registStatus() {
      if (this.regist_status == "1") {
        return true;
      } else {
        return false;
      }
    },
    updateStatus() {
      if (this.regist_status == "2") {
        return true;
      } else {
        return false;
      }
    },
    deleteStatus() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (this.regist_status == "2" && kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    adminStatus() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>
<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main id="fd102" class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-show="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="unique_show" class="err">{{ unique_error }}</span>
      </div>
      <div class="offset-sm-1 pl-4"><span class="err"></span></div>
      <div class="container mt-3 mb-3 clearfix">
        <div class="form-inline float-right">
          <button v-if="registStatus()" v-on:click="insert_btn()" class="btn btn-light ml-3" type="submit" style="width: 120px">登録</button>
          <button v-if="updateStatus()" v-on:click="update_btn()" class="btn btn-light ml-3" type="submit" style="width: 120px">更新</button>
          <button v-if="deleteStatus()" v-on:click="delete_btn()" class="btn btn-light ml-3" type="submit" style="width: 120px">削除</button>
        </div>
      </div>
      <div class="container mb-3">
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">ログインID</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 250px">
            <input type="tel" maxlength="16" class="form-control bg-required" :readonly="!adminStatus()" v-model="login_id" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">
              {{ this.errors.login_id }}
            </p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">ログイン名(会社名)</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 250px">
            <input maxlength="50" class="form-control bg-required" v-model="login_name" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">
              {{ this.errors.login_name }}
            </p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">パスワード</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 250px">
            <input type="tel" maxlength="50" class="form-control bg-required" :readonly="!adminStatus()" v-model="password" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">
              {{ this.errors.password }}
            </p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; position: relative; max-width: 700px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">管理者</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 400px">
            <select id class="form-control bg-required" :disabled="!adminStatus() || kengen_cd == '5'" v-model="kanrisya_cd">
              <option v-for="kanrisya in kanrisyaList" :key="kanrisya.kanrisya_cd" :value="kanrisya.kanrisya_cd">{{ kanrisya.kanrisya_cd }}：{{ kanrisya.kanrisya_mei }}</option>
            </select>
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">
              {{ this.errors.kanrisya_cd }}
            </p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">有効フラグ</label>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" v-model="valid_flg" v-bind:value="1" />
            <label for="" class="form-check-label">有効</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" :disabled="!adminStatus()" v-model="valid_flg" v-bind:value="0" />
            <label for="" class="form-check-label">無効</label>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 1000px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">権限区分</label>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" :disabled="!adminStatus()" v-model="kengen_cd" v-bind:value="1" /><label for="" class="form-check-label">河川課</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" :disabled="!adminStatus()" v-model="kengen_cd" v-bind:value="2" /><label for="" class="form-check-label">県庁各課</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" :disabled="!adminStatus()" v-model="kengen_cd" v-bind:value="3" />
            <label for="" class="form-check-label">茨城県土木・工事事務所及び県内市町村</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" :disabled="!adminStatus()" v-model="kengen_cd" v-bind:value="4" /><label for="" class="form-check-label">公社</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" v-model="kengen_cd" v-bind:value="5" /><label for="" class="form-check-label">被災現場確認</label>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">氏名</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 250px">
            <input :class="kengen_cd == '5' ? 'form-control bg-required' : 'form-control'" maxlength="50" v-model="shimei" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">
              {{ this.errors.shimei }}
            </p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 130px">連絡先</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 250px">
            <input type="tel" :class="kengen_cd == '5' ? 'form-control bg-required' : 'form-control'" maxlength="50" v-model="renrakusaki" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red">
              {{ this.errors.renrakusaki }}
            </p>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>