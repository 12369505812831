<script>
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();
var numeral = require("numeral");

export default {
  components: {
    Layout,Loading
  },
  data() {
    return {
      max: "10",
      info: "",
      textError: "",
      kotuko: {},
      jpYear:"",
      engYear: this.$route.query.saigai_yyyy,
      kubun: this.$route.query.kbn,
      satei_no: this.$route.query.nji_satei,
      kanrisya_cd: this.$route.query.kanrisya_cd,
      kanrisya_mei: this.$route.query.kanrisya_mei,
      koji_no: this.$route.query.koji_no,
      bunsatu_no: !this.$route.query.bunsatu_no ? "0" : this.$route.query.bunsatu_no,
      setei_kubun: "",
      kanrisyas: [],
      gousatu_no: "",
      kinkyus: [],
      kinkyu: "1",
      totukis: [],
      totuki: "",
      kyougis: [],
      kyougi: "",
      horyus: [],
      horyu: "",
      kairyous: [],
      kairyou: "",
      sikakus: [],
      sikaku: "",
      shinsei: "",
      fukyu_encyou: "",
      kotuko_fukyu_encyou: "",
      sayu1s: [],
      sayu1: "",
      sayu2s: [],
      sayu2: "",
      sayu3s: [],
      sayu3: "",
      sayu4s: [],
      sayu4: "",
      sayu5s: [],
      sayu5: "",
      encyou1: "",
      encyou2: "",
      encyou3: "",
      encyou4: "",
      encyou5: "",
      kotuko_sayu1: "",
      kotuko_sayu1_mei: "",
      kotuko_encyou1: "",
      kotuko_sayu2: "",
      kotuko_sayu2_mei: "",
      kotuko_encyou2: "",
      kotuko_sayu3: "",
      kotuko_sayu3_mei: "",
      kotuko_encyou3: "",
      kotuko_sayu4: "",
      kotuko_sayu4_mei: "",
      kotuko_encyou4: "",
      kotuko_sayu5: "",
      kotuko_sayu5_mei: "",
      kotuko_encyou5: "",
      errors: {
        koji_no: null,
        bunsatu_no: null,
        kinkyu: null
      },
      showError: false,
      showInfo: false,
      isLoading: false,
      fullPage: true,
      importFlg:false,
      chk_date:""
    };
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getListBoxData();
    this.getGengo(this.engYear);
    if (this.kubun == "2") {
      this.showDetail();
    } else {
      this.setei_kubun = this.$constants.FC101["OTHER"];
    }
  },
  filter: {
    formatNumber: function(number) {
      return number == "" || number == null
        ? number
        : numeral(number).format("0,0");
    }
  },
  methods: {
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    getListBoxData() {
      apiService.fc101Listbox().then(result => {
        for (var z in result.kanrisya) {
          this.kanrisyas.push({
            value: result.kanrisya[z].kanrisya_mei,
            key: result.kanrisya[z].kanrisya_cd
          });
        }
        this.kanrisya_mei = this.kanrisyas.filter(
          i => i.key == this.kanrisya_cd
        )[0].value;
        for (var k in result.kinkyu) {
          this.kinkyus.push({
            value: result.kinkyu[k].cd_value,
            key: result.kinkyu[k].meisai
          });
        }
        for (var t in result.totuki) {
          this.totukis.push({
            value: result.totuki[t].totuki_kbn,
            key: result.totuki[t].totuki_cd
          });
        }
        for (var i in result.kyougi) {
          this.kyougis.push({
            value: result.kyougi[i].kyougi_kbn,
            key: result.kyougi[i].kyougi_cd
          });
        }
        for (var h in result.horyu) {
          this.horyus.push({
            value: result.horyu[h].horyu_kbn,
            key: result.horyu[h].horyu_cd
          });
        }
        for (var j in result.kairyou) {
          this.kairyous.push({
            value: result.kairyou[j].cd_value,
            key: result.kairyou[j].meisai
          });
        }
        for (var s in result.sikaku) {
          this.sikakus.push({
            value: result.sikaku[s].sikaku_kbn,
            key: result.sikaku[s].sikaku_cd
          });
        }
        for (var y in result.sayu) {
          this.sayu1s.push({
            value: result.sayu[y].cd_value,
            key: result.sayu[y].meisai
          });
          this.sayu2s.push({
            value: result.sayu[y].cd_value,
            key: result.sayu[y].meisai
          });
          this.sayu3s.push({
            value: result.sayu[y].cd_value,
            key: result.sayu[y].meisai
          });
          this.sayu4s.push({
            value: result.sayu[y].cd_value,
            key: result.sayu[y].meisai
          });
          this.sayu5s.push({
            value: result.sayu[y].cd_value,
            key: result.sayu[y].meisai
          });
        }
      });
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    showDetail() {
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        kanrisya_cd: this.kanrisya_cd,
        bunsatu_no: this.bunsatu_no,
        nji_satei: this.satei_no
      };
      apiService.fc101getSaigaiSatei(request_data).then(result => {
        if (result['saigai_satei'].length > 0) {
          var saigaiSateiList = result['saigai_satei'];
          
          this.kanrisya_mei = saigaiSateiList[0].kanrisya_mei;
          this.satei_no     = saigaiSateiList[0].nji_satei;
          this.gousatu_no   = saigaiSateiList[0].gousatu_no;
          this.kinkyu       = saigaiSateiList[0].kinkyu_cd;
          this.totuki       = saigaiSateiList[0].tottki_cd;
          this.kyougi       = saigaiSateiList[0].kyouhi_cd;
          this.horyu        = saigaiSateiList[0].horyu_cd;
          this.kairyou      = saigaiSateiList[0].kairyu_cd;
          this.sikaku       = saigaiSateiList[0].sittkaku_cd;
          this.fukyu_encyou = this.formatNumber(saigaiSateiList[0].fukyu_encyou);
          this.kotuko_fukyu_encyou = this.formatNumber(saigaiSateiList[0].kotuko_fukyu_encyou);

          if (saigaiSateiList[0].kotuko_sayukisi1_name) {
            this.kotuko_sayu1 = this.sayu1s.filter(
              i => i.value == saigaiSateiList[0].kotuko_sayukisi1_name
            )[0].key;
          }
          this.kotuko_sayu1_mei = saigaiSateiList[0].kotuko_sayukisi1_name;
          if (saigaiSateiList[0].kotuko_encyou1) {
            this.kotuko_encyou1 = this.formatNumber(
              saigaiSateiList[0].kotuko_encyou1.toString()
            );
          }
          this.sayu1 = saigaiSateiList[0].sayukisi1;
          if (saigaiSateiList[0].encyou1) {
            this.encyou1 = this.formatNumber(saigaiSateiList[0].encyou1);
          }
          if (saigaiSateiList[0].kotuko_sayukisi2_name) {
            this.kotuko_sayu2 = this.sayu2s.filter(
              i => i.value == saigaiSateiList[0].kotuko_sayukisi2_name
            )[0].key;
          }
          this.kotuko_sayu2_mei = saigaiSateiList[0].kotuko_sayukisi2_name;
          if (saigaiSateiList[0].kotuko_encyou2) {
            this.kotuko_encyou2 = this.formatNumber(
              saigaiSateiList[0].kotuko_encyou2.toString()
            );
          }
          this.sayu2 = saigaiSateiList[0].sayukisi2;
          if (saigaiSateiList[0].encyou2) {
            this.encyou2 = this.formatNumber(saigaiSateiList[0].encyou2);
          }
          if (saigaiSateiList[0].kotuko_sayukisi3_name) {
            this.kotuko_sayu3 = this.sayu3s.filter(
              i => i.value == saigaiSateiList[0].kotuko_sayukisi3_name
            )[0].key;
          }
          this.kotuko_sayu3_mei = saigaiSateiList[0].kotuko_sayukisi3_name;
          if (saigaiSateiList[0].kotuko_encyou3) {
            this.kotuko_encyou3 = this.formatNumber(
              saigaiSateiList[0].kotuko_encyou3.toString()
            );
          }
          this.sayu3 = saigaiSateiList[0].sayukisi3;
          if (saigaiSateiList[0].encyou3) {
            this.encyou3 = this.formatNumber(saigaiSateiList[0].encyou3);
          }
          if (saigaiSateiList[0].kotuko_sayukisi4_name) {
            this.kotuko_sayu4 = this.sayu4s.filter(
              i => i.value == saigaiSateiList[0].kotuko_sayukisi4_name
            )[0].key;
          }
          this.kotuko_sayu4_mei = saigaiSateiList[0].kotuko_sayukisi4_name;
          if (saigaiSateiList[0].kotuko_encyou4) {
            this.kotuko_encyou4 = this.formatNumber(
              saigaiSateiList[0].kotuko_encyou4.toString()
            );
          }
          this.sayu4 = saigaiSateiList[0].sayukisi4;
          if (saigaiSateiList[0].encyou4) {
            this.encyou4 = this.formatNumber(saigaiSateiList[0].encyou4);
          }
          if (saigaiSateiList[0].kotuko_sayukisi5_name) {
            this.kotuko_sayu5 = this.sayu5s.filter(
              i => i.value == saigaiSateiList[0].kotuko_sayukisi5_name
            )[0].key;
          }
          this.kotuko_sayu5_mei = saigaiSateiList[0].kotuko_sayukisi5_name;
          if (saigaiSateiList[0].kotuko_encyou5) {
            this.kotuko_encyou5 = this.formatNumber(
              saigaiSateiList[0].kotuko_encyou5.toString()
            );
          }
          this.sayu5 = saigaiSateiList[0].sayukisi5;
          if (saigaiSateiList[0].encyou5) {
            this.encyou5 = this.formatNumber(saigaiSateiList[0].encyou5);
          }
          if (saigaiSateiList[0].satei_kbn == 1) {
            this.setei_kubun = this.$constants.FC101["1"];
          } else if (saigaiSateiList[0].satei_kbn == 2) {
            this.setei_kubun = this.$constants.FC101["2"];
          } else {
            this.setei_kubun = this.$constants.FC101["OTHER"];
          }
          // 排他チェック用更新日
          this.chk_date = result['chk_date'];
        }
      });
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    formatNumberZero: function(number) {
      if (number === '0') {
        return 0;
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? "" : result;
    },
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0.0");
      return result == 0 ? "" : result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    removeComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    inputKettei() {
      this.max = this.fukyu_encyou.indexOf(".") > 0 ? 11 : 10;
      if (this.fukyu_encyou.length > 9 && this.fukyu_encyou.charAt(9) != ".") {
        this.fukyu_encyou = this.fukyu_encyou.substring(0, 9);
      }
    },
    focusKettei() {
      if (this.fukyu_encyou != null) {
        this.fukyu_encyou = this.fukyu_encyou.trim().replace(/,/g, "");
      }
    },
    inputEncyou1() {
      this.max = this.encyou1.indexOf(".") > 0 ? 11 : 10;
      if (this.encyou1.length > 9 && this.encyou1.charAt(9) != ".") {
        this.encyou1 = this.encyou1.substring(0, 9);
      }
    },
    focusEncyou1() {
      if (this.encyou1 != null) {
        this.encyou1 = this.encyou1.trim().replace(/,/g, "");
      }
    },
    inputEncyou2() {
      this.max = this.encyou2.indexOf(".") > 0 ? 11 : 10;
      if (this.encyou2.length > 9 && this.encyou2.charAt(9) != ".") {
        this.encyou2 = this.encyou2.substring(0, 9);
      }
    },
    focusEncyou2() {
      if (this.encyou2 != null) {
        this.encyou2 = this.encyou2.trim().replace(/,/g, "");
      }
    },
    inputEncyou3() {
      this.max = this.encyou3.indexOf(".") > 0 ? 11 : 10;
      if (this.encyou3.length > 9 && this.encyou3.charAt(9) != ".") {
        this.encyou3 = this.encyou3.substring(0, 9);
      }
    },
    focusEncyou3() {
      if (this.encyou3 != null) {
        this.encyou3 = this.encyou3.trim().replace(/,/g, "");
      }
    },
    inputEncyou4() {
      this.max = this.encyou4.indexOf(".") > 0 ? 11 : 10;
      if (this.encyou4.length > 9 && this.encyou4.charAt(9) != ".") {
        this.encyou4 = this.encyou4.substring(0, 9);
      }
    },
    focusEncyou4() {
      if (this.encyou4 != null) {
        this.encyou4 = this.encyou4.trim().replace(/,/g, "");
      }
    },
    inputEncyou5() {
      this.max = this.encyou5.indexOf(".") > 0 ? 11 : 10;
      if (this.encyou5.length > 9 && this.encyou5.charAt(9) != ".") {
        this.encyou5 = this.encyou5.substring(0, 9);
      }
    },
    focusEncyou5() {
      if (this.encyou5 != null) {
        this.encyou5 = this.encyou5.trim().replace(/,/g, "");
      }
    },
    bunsatuNoChange() {
      if (this.isInteger(this.bunsatu_no)) {
        this.bunsatu_no = this.bunsatu_no
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.bunsatu_no = this.formatNumberZero(this.bunsatu_no);
      }
    },
    kokofutan_shinsei_btn() {
      if (this.koji_no != null && this.koji_no != "") {
        this.errors.koji_no = null;
        var request_data = {
          saigai_yyyy: this.engYear,
          koji_no: this.koji_no,
          kanrisya_cd: localStorage.getItem("Kanrisya_Cd"), // 国庫負担の情報取得権限の有無はログイン情報を見る。
          nji_satei: this.satei_no
        };
        apiService.fc101KotukoShinseiData(request_data).then(result => {
          if (result.status === 'success') {
            if (result.data.length > 0) {
                this.kotuko = result.data;
                this.kotuko_fukyu_encyou = this.formatNumber(this.kotuko[0].fukyu_encyou);
                this.kanrisya_cd = this.kotuko[0].kanrisya_cd;
                this.kotuko_sayu1 = this.kotuko[0].sayukisi1;
                this.kotuko_sayu2 = this.kotuko[0].sayukisi2;
                this.kotuko_sayu3 = this.kotuko[0].sayukisi3;
                this.kotuko_sayu4 = this.kotuko[0].sayukisi4;
                this.kotuko_sayu5 = this.kotuko[0].sayukisi5;
                if (this.kotuko_sayu1) {
                    var sayu1_id = this.sayu1s.filter(
                        i => i.key == this.kotuko_sayu1
                    );
                    this.kotuko_sayu1_mei = sayu1_id[0].value;
                }
                if (this.kotuko_sayu2) {
                    var sayu2_id = this.sayu2s.filter(
                        i => i.key == this.kotuko_sayu2
                    );
                    this.kotuko_sayu2_mei = sayu2_id[0].value;
                }
                if (this.kotuko_sayu3) {
                    var sayu3_id = this.sayu3s.filter(
                        i => i.key == this.kotuko_sayu3
                    );
                    this.kotuko_sayu3_mei = sayu3_id[0].value;
                }
                if (this.kotuko_sayu4) {
                    var sayu4_id = this.sayu4s.filter(
                        i => i.key == this.kotuko_sayu4
                    );
                    this.kotuko_sayu4_mei = sayu4_id[0].value;
                }
                if (this.kotuko_sayu5) {
                var sayu5_id = this.sayu5s.filter(
                    i => i.key == this.kotuko_sayu5
                );
                this.kotuko_sayu5_mei = sayu5_id[0].value;
                }
                if (this.kotuko[0].encyou1) {
                    this.kotuko_encyou1 = this.formatNumber(this.kotuko[0].encyou1);
                }
                if (this.kotuko[0].encyou2) {
                    this.kotuko_encyou2 = this.formatNumber(this.kotuko[0].encyou2);
                }
                if (this.kotuko[0].encyou3) {
                    this.kotuko_encyou3 = this.formatNumber(this.kotuko[0].encyou3);
                }
                if (this.kotuko[0].encyou4) {
                    this.kotuko_encyou4 = this.formatNumber(this.kotuko[0].encyou4);
                }
                if (this.kotuko[0].encyou5) {
                    this.kotuko_encyou5 = this.formatNumber(this.kotuko[0].encyou5);
                }

                if (this.kotuko[0].satei_kbn == 1) {
                    this.setei_kubun = this.$constants.FC101["1"];
                } else if (this.kotuko[0].satei_kbn == 2) {
                    this.setei_kubun = this.$constants.FC101["2"];
                } else {
                    this.setei_kubun = this.$constants.FC101["OTHER"];
                }

                this.importFlg = true; 
                this.insert_btn();
                //this.getListBoxData();
            } else {
                this.clearKotukoData();
                confirm(this.$messages.msg['0013E']);
                this.errors.koji_no = this.$messages.msg["0039W"];

            }
          } else {
              this.clearKotukoData();
              if (result.msg_cd) {
                confirm(this.$messages.msg['0013E']);
                this.errors.koji_no = this.$messages.msg[result.msg_cd];
              }
          }
          
        });
      } else {
        confirm(this.$messages.msg['0013E']);
        this.errors.koji_no = this.$messages.msg["0022W"];
      }
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    insert_btn() {
      this.showInfo = false;
      this.showError = false;
      // 国庫負担申請データの取得チェック
      if (this.importFlg === false) {
          confirm(this.$messages.msg['0013E']);
          this.errors.koji_no = this.$messages.msg["0042W"];
          return;
      }
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        nji_satei: parseInt(this.satei_no),
        kanrisya_cd: parseInt(this.kanrisya_cd),
        bunsatu_no: parseInt(this.bunsatu_no),
        gousatu_no: this.gousatu_no,
        kinkyu_cd: this.kinkyu,
        tottki_cd: this.totuki,
        kyouhi_cd: this.kyougi,
        horyu_cd: this.horyu,
        kairyu_cd: this.kairyou,
        sittkaku_cd: this.sikaku,
        fukyu_encyou: parseFloat(this.removeComma(this.fukyu_encyou)),
        sayukisi1: this.sayu1,
        encyou1: this.removeComma(this.encyou1),
        sayukisi2: this.sayu2,
        encyou2: this.removeComma(this.encyou2),
        sayukisi3: this.sayu3,
        encyou3: this.removeComma(this.encyou3),
        sayukisi4: this.sayu4,
        encyou4: this.removeComma(this.encyou4),
        sayukisi5: this.sayu5,
        encyou5: this.removeComma(this.encyou5),
        loginId: localStorage.getItem("LOGIN_ID")
      };
      apiService
        .fc101Insert(request_data)
        .then(result => {
          this.showInfo  = true;
          this.showError = false;
          this.errors.koji_no    = null;
          this.errors.bunsatu_no = null;
          this.errors.kinkyu     = null;
          this.errors.gousatu_no = null;
          this.kubun = "2";
          this.info     = result.msg;
          this.chk_date = result.chk_date
          this.$router.push({
          name: "FC101",
          query: {
            saigai_yyyy: this.engYear,
            nji_satei: parseInt(this.satei_no),
            koji_no: this.koji_no,
            bunsatu_no: this.bunsatu_no,
            kanrisya_cd: this.kanrisya_cd,
            kbn: 2
          }});
        })
        .catch(error => {
          this.showInfo = false;
          if (typeof error == "string") {
            confirm(this.$messages.msg['0013E']);
            this.showError = true;
            this.textError = error;
            this.errors.koji_no    = null;
            this.errors.bunsatu_no = null;
            this.errors.kinkyu     = null;
            this.errors.gousatu_no = null;
          } else {
            this.showError = false;
            this.errors.koji_no = error.errors.koji_no
              ? error.errors.koji_no[0]
              : "";
            this.errors.bunsatu_no = error.errors.bunsatu_no
              ? error.errors.bunsatu_no[0]
              : "";
            this.errors.kinkyu = error.errors.kinkyu_cd
              ? error.errors.kinkyu_cd[0]
              : "";
            this.errors.gousatu_no = error.errors.gousatu_no
              ? error.errors.gousatu_no[0]
              : "";
            confirm(this.$messages.msg['0013E']);
          }
        });
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    update_btn() {
      this.showInfo = false;
      this.showError = false;
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        nji_satei: parseInt(this.satei_no),
        kanrisya_cd: parseInt(this.kanrisya_cd),
        bunsatu_no: parseInt(this.bunsatu_no),
        gousatu_no: this.gousatu_no,
        kinkyu_cd: this.kinkyu,
        tottki_cd: this.totuki,
        kyouhi_cd: this.kyougi,
        horyu_cd: this.horyu,
        kairyu_cd: this.kairyou,
        sittkaku_cd: this.sikaku,
        fukyu_encyou: parseFloat(this.removeComma(this.fukyu_encyou)),
        sayukisi1: this.sayu1,
        encyou1: this.removeComma(this.encyou1),
        sayukisi2: this.sayu2,
        encyou2: this.removeComma(this.encyou2),
        sayukisi3: this.sayu3,
        encyou3: this.removeComma(this.encyou3),
        sayukisi4: this.sayu4,
        encyou4: this.removeComma(this.encyou4),
        sayukisi5: this.sayu5,
        encyou5: this.removeComma(this.encyou5),
        chk_date: this.chk_date,
        loginId: localStorage.getItem("LOGIN_ID")
      };
      apiService
        .fc101Update(request_data)
        .then(result => {
          this.showInfo  = true;
          this.showError = false;
          this.errors.koji_no    = null;
          this.errors.bunsatu_no = null;
          this.errors.kinkyu     = null;
          this.errors.gousatu_no = null;
          //this.info     = result.msg;
          this.chk_date = result.chk_date
          confirm(result.msg);
          setTimeout(() => {
            this.$router.push({
              name: "FC100",
              query: {
                saigai_yyyy: this.engYear,
                koji_no: this.koji_no,
                bunsatu_no: this.bunsatu_no,
                kanrisya_cd: this.kanrisya_cd
              }
            });
          }, 1500); 
        })
        .catch(error => {
          this.showInfo = false;
          if (typeof error == "string") {
            confirm(this.$messages.msg['0013E']);
            this.showError = true;
            this.textError = error;
            this.errors.koji_no    = null;
            this.errors.bunsatu_no = null;
            this.errors.kinkyu     = null;
            this.errors.gousatu_no = null;
          } else {
            this.showError = false;
            this.errors.koji_no = error.errors.koji_no
              ? error.errors.koji_no[0]
              : "";
            this.errors.bunsatu_no = error.errors.bunsatu_no
              ? error.errors.bunsatu_no[0]
              : "";
            this.errors.kinkyu = error.errors.kinkyu_cd
              ? error.errors.kinkyu_cd[0]
              : "";
            this.errors.gousatu_no = error.errors.gousatu_no
              ? error.errors.gousatu_no[0]
              : "";
            confirm(this.$messages.msg['0013E']);
          }
        });
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    fcChange_update(fc_name) {
      this.showInfo = false;
      this.showError = false;
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        nji_satei: parseInt(this.satei_no),
        kanrisya_cd: parseInt(this.kanrisya_cd),
        bunsatu_no: parseInt(this.bunsatu_no),
        gousatu_no: this.gousatu_no,
        kinkyu_cd: this.kinkyu,
        tottki_cd: this.totuki,
        kyouhi_cd: this.kyougi,
        horyu_cd: this.horyu,
        kairyu_cd: this.kairyou,
        sittkaku_cd: this.sikaku,
        fukyu_encyou: parseFloat(this.removeComma(this.fukyu_encyou)),
        sayukisi1: this.sayu1,
        encyou1: this.removeComma(this.encyou1),
        sayukisi2: this.sayu2,
        encyou2: this.removeComma(this.encyou2),
        sayukisi3: this.sayu3,
        encyou3: this.removeComma(this.encyou3),
        sayukisi4: this.sayu4,
        encyou4: this.removeComma(this.encyou4),
        sayukisi5: this.sayu5,
        encyou5: this.removeComma(this.encyou5),
        chk_date: this.chk_date,
        loginId: localStorage.getItem("LOGIN_ID")
      };
      apiService
        .fc101Update(request_data)
        .then(result => {
          this.showInfo  = true;
          this.showError = false;
          this.errors.koji_no    = null;
          this.errors.bunsatu_no = null;
          this.errors.kinkyu     = null;
          this.errors.gousatu_no = null;
          //this.info     = result.msg;
          this.chk_date = result.chk_date
          setTimeout(() => {
            this.$router.push({
              name: fc_name,
              query: {
                saigai_yyyy: this.engYear,
                koji_no: this.koji_no,
                bunsatu_no: this.bunsatu_no,
                kanrisya_cd: this.kanrisya_cd
              }
            });
          }, 1500); 
        })
        .catch(error => {
          this.showInfo = false;
          if (typeof error == "string") {
            confirm(this.$messages.msg['0013E']);
            this.showError = true;
            this.textError = error;
            this.errors.koji_no    = null;
            this.errors.bunsatu_no = null;
            this.errors.kinkyu     = null;
            this.errors.gousatu_no = null;
          } else {
            this.showError = false;
            this.errors.koji_no = error.errors.koji_no
              ? error.errors.koji_no[0]
              : "";
            this.errors.bunsatu_no = error.errors.bunsatu_no
              ? error.errors.bunsatu_no[0]
              : "";
            this.errors.kinkyu = error.errors.kinkyu_cd
              ? error.errors.kinkyu_cd[0]
              : "";
            this.errors.gousatu_no = error.errors.gousatu_no
              ? error.errors.gousatu_no[0]
              : "";
            confirm(this.$messages.msg['0013E']);
          }
        });
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    delete_btn() {
      var delete_data = {
        saigai_yyyy: this.engYear,
        koji_no: parseInt(this.koji_no),
        kanrisya_cd: parseInt(this.kanrisya_cd),
        bunsatu_no: parseInt(this.bunsatu_no),
        loginId: localStorage.getItem("LOGIN_ID"),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fc101Delete(delete_data)
          .then(result => {
            this.showInfo = true;
            this.showError = false;
            this.info = result;
            this.clearData();
            this.clearKotukoData();
            confirm('災害査定を削除しました。');
            this.$router.push({
            name: "FC101",
            query: {
                saigai_yyyy: this.engYear,
                nji_satei: parseInt(this.satei_no),
                kanrisya_cd: this.kanrisya_cd == 0 ? 1 : this.kanrisya_cd,
                kbn: 1
            }
            });
            this.$router.go({path: this.$router.currentRoute.path, force: true});
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.showInfo = false;
            this.showError = true;
            this.textError = error;
            console.log(error);
          });
      }
    },
    goFC102() {
      this.fcChange_update("FC102");
    },
    goFC103() {
      this.fcChange_update("FC103");
    },
    goFC104() {
      this.fcChange_update("FC104");
    },
    clearKotukoData() {
        (this.setei_kubun = this.$constants.FC101["OTHER"]),
        (this.kotuko_fukyu_encyou = ""),
        (this.kotuko_sayu1 = ""),
        (this.kotuko_sayu1_mei = ""),
        (this.kotuko_encyou1 = ""),
        (this.kotuko_sayu2 = ""),
        (this.kotuko_sayu2_mei = ""),
        (this.kotuko_encyou2 = ""),
        (this.kotuko_sayu3 = ""),
        (this.kotuko_sayu3_mei = ""),
        (this.kotuko_encyou3 = ""),
        (this.kotuko_sayu4 = ""),
        (this.kotuko_sayu4_mei = ""),
        (this.kotuko_encyou4 = ""),
        (this.kotuko_sayu5 = ""),
        (this.kotuko_sayu5_mei = ""),
        (this.kotuko_encyou5 = "");
        (this.importFlg = false);
    },
    clearData() {
        (this.koji_no = ""),
        (this.gousatu_no = ""),
        (this.bunsatu_no = ""),
        (this.kanrisya_mei = ""),
        (this.gousatu_no = ""),
        (this.kinkyu = ""),
        (this.totuki = ""),
        (this.kyougi = ""),
        (this.horyu = ""),
        (this.kairyou = ""),
        (this.sikaku = ""),
        (this.shinsei = ""),
        (this.fukyu_encyou = ""),
        (this.sayu1 = ""),
        (this.sayu2 = ""),
        (this.sayu3 = ""),
        (this.sayu4 = ""),
        (this.sayu5 = ""),
        (this.encyou1 = ""),
        (this.encyou2 = ""),
        (this.encyou3 = ""),
        (this.encyou4 = ""),
        (this.encyou5 = "");
    },
    ketteiChange(kettei) {
      this.fukyu_encyou = this.removeComma(kettei);
       if (this.isInteger(this.fukyu_encyou)) {
        this.fukyu_encyou = this.fukyu_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou1Change(encyou1) {
      this.encyou1 = this.removeComma(encyou1);
      if (this.isInteger(this.encyou1)) {
        this.encyou1 = this.encyou1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou2Change(encyou2) {
      this.encyou2 = this.removeComma(encyou2);
      if (this.isInteger(this.encyou2)) {
        this.encyou2 = this.encyou2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou3Change(encyou3) {
      this.encyou3 = this.removeComma(encyou3);
      if (this.isInteger(this.encyou3)) {
        this.encyou3 = this.encyou3
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou4Change(encyou4) {
      this.encyou4 = this.removeComma(encyou4);
      if (this.isInteger(this.encyou4)) {
        this.encyou4 = this.encyou4
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    encyou5Change(encyou5) {
      this.encyou5 = this.removeComma(encyou5);
      if (this.isInteger(this.encyou5)) {
        this.encyou5 = this.encyou5
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
};
</script>
<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3" v-if="!isLoading">
      <p class="col-md-12 bg-success-info text-center" v-show="showInfo">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err" v-show="showError">{{ textError }}</p>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row mb-0" style="width:100%; max-width:800px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:80px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="jpYear" tabindex="1" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
              <label for class="col-sm col-form-label ml-3" style="width:100%; max-width:70px">査定</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
                <input type="text" class="form-control bg-light" v-model="satei_no" tabindex="2" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:50px"
              >次</label>
              <div class="form-group row mb-0" style="width:55%; ">
                <label for class="col-sm col-form-label" style="width:100%; max-width:80px">管理者</label>
                <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                  <input type="text" class="form-control bg-light" v-model="kanrisya_cd" tabindex="3" readonly />
                </div>
                <div class="col-sm pr-0 pl-1" style="width:100%; max-width:500px">
                  <input type="text" class="form-control bg-light" v-model="kanrisya_mei" tabindex="4" readonly />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container mb-3 pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              style="width:120px;"
              tabindex="5"
              @click="insert_btn()"
              :disabled="this.kubun == 2 ? true : false"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              tabindex="6"
              @click="update_btn()"
              :disabled="this.kubun == 1 ? true : false"
            >更新</button>
            <router-link
              :to="{ name: 'FC100' }"
              class="btn btn-light ml-3"
              style="width:120px;"
              tabindex="8"
              role="button"
            >戻る</router-link>
          </div>
        </div>
        <div class="container mb-3 mt-3">
          <div class="form-group row" style="width:100%;margin-bottom:0px;">
            <label
              for
              class="col-sm col-form-label d-flex align-items-center"
              style="width:100%; max-width:100px"
            >工事番号</label>
            <div
              class="col-sm pr-2 pl-0 d-flex align-items-center"
              style="width:100%; max-width:100px"
            >
              <input
                type="tel"
                class="form-control bg-required"
                v-model="koji_no"
                maxlength="9"
                tabindex="9"
                :readonly="this.kubun == 2 ? true : false"
              />
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
              <button
                class="btn btn-light"
                type="button"
                style="width:130px;"
                tabindex="10"
                @click="kokofutan_shinsei_btn()"
              >
                国庫負担申請
                <br />データ取得
              </button>
            </div>
            <label
              for
              class="col-sm col-form-label ml-2 d-flex align-items-center"
              style="width:100%; max-width:70px"
            >分冊</label>
            <div
              class="col-sm pr-0 pl-0 d-flex align-items-center"
              style="width:100%; max-width:80px"
            >
              <input
                type="tel"
                class="form-control bg-required"
                v-model="bunsatu_no"
                maxlength="9"
                tabindex="11"
                :readonly="this.kubun == 2 ? true : false"
                @blur="bunsatuNoChange()"
              />
            </div>
          </div>
          <div class="form-group row" style="width:100%; max-width:800px">
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px;">
              <div style="width:100%; max-width:130px; white-space: nowrap; color: red;">{{ this.errors.koji_no }}</div>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px;">
              <div style="width:100%; max-width:130px; white-space: nowrap; margin-left:220px; color: red;">{{ this.errors.bunsatu_no }}</div>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">査定区分</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
              <input type="text" class="form-control" v-model="setei_kubun" tabindex="12" readonly />
            </div>
          </div>
        </div>
        <div class="container mb-3 mt-3 border pt-3 pb-3">
          <table class style="width:100%; font-size:0.95rem">
            <tbody class="align-middle">
              <tr>
                <td class="align-middle pt-1 pb-1" width="8%">合冊番号</td>
                <td class="align-middle pt-1 pb-1" colspan width="41%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill"
                    v-model="gousatu_no"
                    maxlength="9"
                    style="width:30%"
                    tabindex="13"
                  />
                  <div style="width:100%; max-width:130px; white-space: nowrap; color: red;">{{ this.errors.gousatu_no }}</div>
                </td>
                <td width="11%"></td>
                <td width="40%"></td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1" width="8%" style="font-size: 18px;font-weight: bold;">緊急順位</td>
                <td class="align-middle pt-1 pb-1" width="41%">
                  <div class="form-inline">
                    <span style="margin-right:15px;" v-for="item in kinkyus" :key="item.key" :value="item.key">
                      <input class="form-check-input" type="radio" style="margin-right:5px;" tabindex="14" :value="item.key" v-model="kinkyu" />{{item.value}}
                    </span>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-4" width="11%">保留</td>
                <td class="align-middle pt-1 pb-1" colspan width="40%">
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      v-model="horyu"
                      style="width:100%;max-width:450px;"
                      tabindex="15"
                    >
                      <option></option>
                      <option v-for="item in horyus" :key="item.key" :value="item.key">{{item.key}}<span> : </span>{{item.value}}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <div style="width:100%; max-width:130px; white-space: nowrap; color: red;">{{ this.errors.kinkyu }}</div>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1" width="8%">特記事項</td>
                <td class="align-middle pt-1 pb-1" width="41%">
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      v-model="totuki"
                      style="width:100%;max-width:450px;"
                      tabindex="16"
                    >
                      <option></option>
                      <option v-for="item in totukis" :key="item.key" :value="item.key">{{item.key}}<span> : </span>{{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-4" width="11%">改良</td>
                <td class="align-middle pt-1 pb-1" colspan width="40%">
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      v-model="kairyou"
                      style="width:100%;max-width:450px;"
                      tabindex="17"
                    >
                      <option></option>
                      <option
                        v-for="item in kairyous"
                        :key="item.key"
                        :value="item.key"
                      >{{item.key}}<span> : </span>{{item.value}}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-1 pb-1" width="8%">協議事項</td>
                <td class="align-middle pt-1 pb-1" width="41%">
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      v-model="kyougi"
                      style="width:100%;max-width:450px;"
                      tabindex="18"
                    >
                      <option></option>
                      <option v-for="item in kyougis" :key="item.key" :value="item.key">{{item.key}}<span> : </span>{{item.value}}</option>
                    </select>
                  </div>
                </td>
                <td scope="row" class="align-middle pt-1 pb-1 pl-4" width="11%">失格・欠格</td>
                <td class="align-middle pt-1 pb-1" width="40%">
                  <div class="form-inline">
                    <select
                      id
                      class="form-control"
                      v-model="sikaku"
                      style="width:100%;max-width:450px;"
                      tabindex="19"
                    >
                      <option></option>
                      <option v-for="item in sikakus" :key="item.key" :value="item.key">{{item.key}}<span> : </span>{{item.value}}</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container d-flex flex-wrap mb-3 mt-3 p-0 justify-content-between">
          <div class="border p-2" style="width:86%;">
            <div class="form-group row mb-3" style="width:60%;">
              <label for class="col-sm col-form-label" style="width:100%;">復旧延長</label>
              <label for class="col-sm col-form-label" style="width:100%; max-width:70px;">申請</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:130px">
                <input
                  type="tel"
                  class="form-control bg-light text-right"
                  tabindex="20"
                  v-model="kotuko_fukyu_encyou"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >m</label>
              <label for class="col-sm col-form-label ml-3" style="width:100%; max-width:70px">決定</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:130px">
                <input
                  type="tel"
                  class="form-control text-right"
                  tabindex="21"
                  v-model="fukyu_encyou"
                  :maxlength="max"
                  @blur="ketteiChange(fukyu_encyou)"
                  @focus="focusKettei()"
                  @input="inputKettei()"
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >m</label>
            </div>
            <p class="text-info mb-1">各行の上段には、国庫負担申請情報を表示します</p>
            <table class="table-sm table-bordered" style="width:100%; font-size:0.95rem">
              <thead class="text-white">
                <tr>
                  <th colspan="3" class="bg-primary border text-center align-middle" width="21%">左右岸</th>
                  <th class="bg-primary border text-center align-middle" width="17%">延長(m)</th>
                  <th colspan="3" class="bg-primary border text-center align-middle" width="21%">左右岸</th>
                  <th class="bg-primary border text-center align-middle" width="17%">延長(m)</th>
                </tr>
              </thead>
              <tbody>
                <!-- 1 & 4 -->
                <tr>
                  <th rowspan="2" class="text-center align-middle border-top" width="1%">1</th>
                  <td
                    class="align-middle bg-lightyellow"
                    width="6%"
                    height="35px"
                  >{{ this.kotuko_sayu1}}</td>
                  <td
                    class="align-middle bg-lightyellow"
                    width="13%"
                    height="35px"
                  >{{ this.kotuko_sayu1_mei}}</td>
                  <td
                    class="align-middle bg-lightyellow text-right"
                    width="19%"
                    height="35px"
                  >{{ this.kotuko_encyou1}}</td>
                  <th rowspan="2" class="text-center align-middle border-top" width="1%">4</th>
                  <td
                    class="align-middle bg-lightyellow"
                    width="6%"
                    height="35px"
                  >{{this.kotuko_sayu4}}</td>
                  <td
                    class="align-middle bg-lightyellow"
                    width="13%"
                    height="35px"
                  >{{this.kotuko_sayu4_mei}}</td>
                  <td
                    class="align-middle bg-lightyellow text-right"
                    width="19%"
                    height="35px"
                  >{{this.kotuko_encyou4}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="align-middle p-1 border" width="19%">
                    <div class="form-inline">
                      <select
                        id
                        class="form-control bg-light"
                        v-model="sayu1"
                        maxlength="9"
                        style="width:100%;"
                        tabindex="22"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayu1s"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}}<span> : </span>{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle p-1" width="19%">
                    <input
                      type="tel"
                      class="form-control text-right p-1 flex-fill"
                      style="width:100%"
                      tabindex="23"
                      v-model="encyou1"
                      :maxlength="max"
                      @blur="encyou1Change(encyou1)"
                      @focus="focusEncyou1()"
                      @input="inputEncyou1()"
                    />
                  </td>
                  <td colspan="2" class="align-middle p-1 border" width="19%">
                    <div class="form-inline">
                      <select
                        id
                        class="form-control bg-light"
                        v-model="sayu4"
                        maxlength="9"
                        style="width:100%;"
                        tabindex="28"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayu4s"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}}<span> : </span>{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle p-1" width="19%">
                    <input
                      type="tel"
                      class="form-control text-right p-1 flex-fill"
                      style="width:100%"
                      tabindex="29"
                      v-model="encyou4"
                      :maxlength="max"
                      @blur="encyou4Change(encyou4)"
                      @focus="focusEncyou4()"
                      @input="inputEncyou4()"
                    />
                  </td>
                </tr>
                <!-- 1 & 4 end -->
                <!-- 2 & 5 -->
                <tr>
                  <th rowspan="2" class="text-center align-middle border-top" width="1%">2</th>
                  <td
                    class="align-middle bg-lightyellow"
                    width="6%"
                    height="35px"
                  >{{this.kotuko_sayu2}}</td>
                  <td
                    class="align-middle bg-lightyellow"
                    width="13%"
                    height="35px"
                  >{{this.kotuko_sayu2_mei}}</td>
                  <td
                    class="align-middle bg-lightyellow text-right"
                    width="19%"
                    height="35px"
                  >{{this.kotuko_encyou2}}</td>
                  <th rowspan="2" class="text-center align-middle border-top" width="1%">5</th>
                  <td
                    class="align-middle bg-lightyellow"
                    width="6%"
                    height="35px"
                  >{{this.kotuko_sayu5}}</td>
                  <td
                    class="align-middle bg-lightyellow"
                    width="13%"
                    height="35px"
                  >{{this.kotuko_sayu5_mei}}</td>
                  <td
                    class="align-middle bg-lightyellow text-right"
                    width="19%"
                    height="35px"
                  >{{this.kotuko_encyou5}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="align-middle p-1 border" width="19%">
                    <div class="form-inline">
                      <select
                        id
                        class="form-control bg-light"
                        v-model="sayu2"
                        maxlength="9"
                        style="width:100%;"
                        tabindex="24"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayu2s"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}}<span> : </span>{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle p-1" width="19%">
                    <input
                      type="tel"
                      class="form-control text-right p-1 flex-fill"
                      style="width:100%"
                      tabindex="25"
                      v-model="encyou2"
                      :maxlength="max"
                      @blur="encyou2Change(encyou2)"
                      @focus="focusEncyou2()"
                      @input="inputEncyou2()"
                    />
                  </td>
                  <td colspan="2" class="align-middle p-1 border" width="19%">
                    <div class="form-inline">
                      <select
                        id
                        class="form-control bg-light"
                        v-model="sayu5"
                        maxlength="9"
                        style="width:100%;"
                        tabindex="30"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayu5s"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}}<span> : </span>{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle p-1" width="19%">
                    <input
                      type="tel"
                      class="form-control text-right p-1 flex-fill"
                      style="width:100%"
                      tabindex="31"
                      v-model="encyou5"
                      :maxlength="max"
                      @blur="encyou5Change(encyou5)"
                      @focus="focusEncyou5()"
                      @input="inputEncyou5()"
                    />
                  </td>
                </tr>
                <!-- 2 & 5 end -->
                <!-- 3 -->
                <tr>
                  <th rowspan="2" class="text-center align-middle border-top" width="1%">3</th>
                  <td
                    class="align-middle bg-lightyellow"
                    width="6%"
                    height="35px"
                  >{{this.kotuko_sayu3}}</td>
                  <td
                    class="align-middle bg-lightyellow"
                    width="13%"
                    height="35px"
                  >{{this.kotuko_sayu3_mei}}</td>
                  <td
                    class="align-middle bg-lightyellow text-right"
                    width="19%"
                    height="35px"
                  >{{this.kotuko_encyou3}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="align-middle p-1 border" width="19%">
                    <div class="form-inline">
                      <select
                        id
                        class="form-control bg-light"
                        v-model="sayu3"
                        maxlength="9"
                        style="width:100%;"
                        tabindex="26"
                      >
                        <option value=""></option>
                        <option
                          v-for="item in sayu3s"
                          :key="item.key"
                          :value="item.key"
                        >{{item.key}}<span> : </span>{{item.value}}</option>
                      </select>
                    </div>
                  </td>
                  <td class="align-middle p-1" width="19%">
                    <input
                      type="text"
                      class="form-control text-right p-1 flex-fill"
                      style="width:100%"
                      tabindex="27"
                      v-model="encyou3"
                      :maxlength="max"
                      @blur="encyou3Change(encyou3)"
                      @focus="focusEncyou3()"
                      @input="inputEncyou3()"
                    />
                  </td>
                </tr>
                <!-- 3 end -->
              </tbody>
            </table>
          </div>
          <div class="p-2 border" style="width:13%;">
            <button
              type="button"
              class="btn btn-success d-block mx-auto mb-2"
              href="satei_koji_r_u_d.html"
              role="button"
              style="width:110px;margin-top:164px"
              @click="goFC102()"
              :disabled="this.kubun == 1 ? true : false"
            >工事概要</button>
            <button v-if="false"
              type="button"
              class="btn btn-success d-block mx-auto mb-2"
              href="satei_kasen_r_u_d.html"
              role="button"
              style="width:110px;"
              @click="goFC103()"
              :disabled="this.kubun == 1 ? true : false"
            >改良復旧</button>
            <button v-if="false"
              type="button"
              class="btn btn-success d-block mx-auto"
              href="satei_naiten_r_u_d.html"
              role="button"
              style="width:110px;"
              @click="goFC104()"
              :disabled="this.kubun == 1 ? true : false"
            >内未・内転</button>
          </div>
        </div>
        <div class="container mb-3 pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              tabindex="7"
              @click="delete_btn()"
              :disabled="this.kubun == 1 ? true : false"
            >削除</button>
          </div>
        </div>
      </form>`
    </main>
  </Layout>
</template>