const defaultState = {
  reportname: "",
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setReportName(state, name) {
      state.reportname = name;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  }
};