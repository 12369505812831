import Vue from 'vue'
import VueRouter from 'vue-router'
import M1100 from './pages/M1100/index.vue'
import M1101 from './pages/M1101/index.vue'
import M1102 from './pages/M1102/edit.vue'
import M1200 from './pages/M1200/index.vue'
import M1201 from './pages/M1201/index.vue'
import M1202 from './pages/M1202/index.vue'
import M1203 from './pages/M1203/index.vue'
import FA100 from './pages/FA100/edit.vue'
import FA101 from './pages/FA101/list.vue'
import FA102 from './pages/FA102/edit.vue'
import FA103 from './pages/FA103/list.vue'
import FA104 from './pages/FA104/edit.vue'
import FA107 from './pages/FA107/edit.vue'
import FA105 from './pages/FA105/edit.vue'
import FA109 from './pages/FA109/edit.vue'
import FA110 from './pages/FA110/edit.vue'
import FA111 from './pages/FA111/edit.vue'
import FA112 from './pages/FA112/edit.vue'
import FA113 from './pages/FA113/edit.vue'
import FA114 from './pages/FA114/index.vue'
import FB103 from './pages/FB103/edit.vue'
import FC101 from './pages/FC101/edit.vue'
import FC102 from './pages/FC102/edit.vue'
import FC103 from './pages/FC103/edit.vue'
import FB100 from './pages/FB100/list.vue'
import FB101 from './pages/FB101/edit.vue'
import FB102 from './pages/FB102/edit.vue'
import FB104 from './pages/FB104/edit.vue'
import FB105 from './pages/FB105/edit.vue'
import FB106 from './pages/FB106/edit.vue'
import FB108 from './pages/FB108/index.vue'
import FC100 from './pages/FC100/list.vue'
import FC104 from './pages/FC104/list.vue'
import FC105 from './pages/FC105/index.vue'
import INF002 from './pages/INF002/edit.vue'
import FG203 from './pages/FG203/index.vue'
import FG202 from './pages/FG202/index.vue'
import FG204 from './pages/FG204/index.vue'
import FD101 from './pages/FD101/list.vue'
import FD102 from './pages/FD102/edit.vue'

import AUTHERROR from './pages/common/error_auth.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'M1100',
    component: M1100
},
{
    path: '/m1101',
    name: 'M1101',
    component: M1101,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/m1102',
    name: 'M1102',
    component: M1102,
    meta: {
        noNeedAuth: true
    }
},
{
    path: '/m1200',
    name: 'M1200',
    component: M1200,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/m1201',
    name: 'M1201',
    component: M1201,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/m1202',
    name: 'M1202',
    component: M1202,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/m1203',
    name: 'M1203',
    component: M1203,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/autherror',
    name: 'AUTHERROR',
    component: AUTHERROR,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa100',
    name: 'FA100',
    component: FA100,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa101',
    name: 'FA101',
    component: FA101,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa102',
    name: 'FA102',
    component: FA102
},
{
    path: '/fa103',
    name: 'FA103',
    component: FA103,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa104',
    name: 'FA104',
    component: FA104,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa105',
    name: 'FA105',
    component: FA105,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa107',
    name: 'FA107',
    component: FA107,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa109',
    name: 'FA109',
    component: FA109,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa110',
    name: 'FA110',
    component: FA110,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa111',
    name: 'FA111',
    component: FA111,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa112',
    name: 'FA112',
    component: FA112
},
{
    path: '/fa113',
    name: 'FA113',
    component: FA113,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fa114',
    name: 'FA114',
    component: FA114,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fb100',
    name: 'FB100',
    component: FB100
},
{
    path: '/fb101',
    name: 'FB101',
    component: FB101
},
{
    path: '/fb102',
    name: 'FB102',
    component: FB102
},
{
    path: '/fb103',
    name: 'FB103',
    component: FB103
},
{
    path: '/fb104',
    name: 'FB104',
    component: FB104
},
{
    path: '/fb105',
    name: 'FB105',
    component: FB105
},
{
    path: '/fb106',
    name: 'FB106',
    component: FB106,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fb108',
    name: 'FB108',
    component: FB108,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fc101',
    name: 'FC101',
    component: FC101
},
{
    path: '/fc102',
    name: 'FC102',
    component: FC102
},
{
    path: '/fc103',
    name: 'FC103',
    component: FC103
},
{
    path: '/fc104',
    name: 'FC104',
    component: FC104
},
{
    path: '/fc105',
    name: 'FC105',
    component: FC105
},
{
    path: '/fg202',
    name: 'FG202',
    component: FG202
},
{
    path: '/fg203',
    name: 'FG203',
    component: FG203
},
{
    path: '/inf002',
    name: 'INF002',
    component: INF002,
    meta: {
        requiresAuth: true
    }
},
{
    path: '/fc100',
    name: 'FC100',
    component: FC100
},
{
    path: '/fd101',
    name: 'FD101',
    component: FD101
},
{
    path: '/fd102',
    name: 'FD102',
    component: FD102
},
{
    path: '/fg204',
    name: 'FG204',
    component: FG204
}
]

const router = new VueRouter({
    mode: 'hash',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.noNeedAuth) {
        if (!localStorage.getItem('AUTH_TOKEN')) {
            next();
            return;
        }
        localStorage.clear();
        next({ name: 'M1100' });
    } else {
        next();
    }
});

export default router;
