<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-err text-center">
        <span class="err" v-show="isCheckBoxErr">{{ this.checkboxMsg }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span class="err" v-show="isNjisateiErr && !isLoading">
          <ul style="list-style-type: none;">
            <li v-for="(njiMsg, index) in njisateiMsg" :key="index">{{ njiMsg }}</li>
          </ul>
        </span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
      </div>
      <div class="container mb-3">
        <form action method>
          <div class="form-group row" style="width:100%;">
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control bg-required"
                maxlength="4"
                v-model="jpYear"
                @blur="changedYear()"
              />
            </div>
            <label
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:30px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:70px">査定</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                v-model="nji_satei"
                @blur="nji_sateiChange()"
              />
            </div>
            <label
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >次</label>
            <label class="col-sm col-form-label text-right" style="width:100%; max-width:120px">工事番号</label>
            <div class="col-sm pr-0" style="width:100%; max-width:130px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                v-model="koji_no_from"
              />
            </div>
            <label class="col-sm col-form-label text-center" style="width:100%; max-width:50px">〜</label>
            <div class="col-sm pl-0" style="width:100%; max-width:130px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                v-model="koji_no_to"
              />
            </div>
            <label
              class="col-sm col-form-label text-right"
              style="width:100%; max-width:120px"
            >査定年月日</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:130px">
              <datepicker
                :language="ja"
                v-model="nji_satei_date"
                :value="nji_satei_date"
                :typeable="true"
                format="yyyy/MM/dd"
                width="100px"
              ></datepicker>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <div style="width:100%; max-width:220px">
              <span class="err" v-show="isYearNotFound">{{ this.yearErrorMsg }}</span>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">事業主体</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select id class="form-control" v-model="meisai1" v-on:change="codeChange1($event)">
                <option></option>
                <option
                  v-for="mcode in mcodeList1"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:30px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">管理者</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select
                id
                class="form-control"
                v-model="kanrisya_cd"
                v-on:change="kanrisyaChange($event)"
              >
                <option
                  v-for="kanrisya in kanrisyaList"
                  :key="kanrisya.kanrisya_cd"
                  :value="kanrisya.kanrisya_cd"
                >{{kanrisya.kanrisya_cd}} : {{kanrisya.kanrisya_mei}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">班</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select id class="form-control" v-model="meisai2" v-on:change="codeChange2($event)">
                <option></option>
                <option
                  v-for="mcode in mcodeList2"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">査定区分</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="1" />
                本省査定
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="2" />
                整備局査定
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="3" />
                混合査定
              </label>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px"></div>
            <!-- <label class="col-sm col-form-label" style="width:100%; max-width:100px">金額区分</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="kingaku_kbn" v-bind:value="3" />
                工事費総額
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="kingaku_kbn" v-bind:value="1" />
                国庫負担対象額
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="kingaku_kbn" v-bind:value="2" />
                工事雑費・営繕費
              </label>
            </div> -->
            <label for="" class="col-sm col-form-label" style="width:100%; max-width:100px">集計条件</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" disabled />
              <label class="form-check-label" for="">特記事項を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" disabled />
              <label class="form-check-label" for="">協議事項を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" disabled />
              <label class="form-check-label" for="">保留を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" disabled />
              <label class="form-check-label" for="">改良を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" disabled />
              <label class="form-check-label" for="">失格・欠格を含む</label>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">出力順</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" v-model="output_order_han" v-bind:value="1" />
                班コード順
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" v-model="output_order_koji" v-bind:value="1" />
                工事番号順
              </label>
            </div>

            <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 80px"></div>
            <label class="col-sm col-form-label" style="width: 100%; max-width: 100px"> 出力帳票 </label>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="1" /> 河川等 </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="2" /> 水道 </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="3" /> 下水道 </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="0" /> すべて </label>
            </div>
          </div>
        </form>
      </div>
      <div class="container pt-3 border-top border-secondary">
        <p class="text-info">出力対象の帳票にチェックを入れて下さい</p>
      </div>
      <div class="container mt-2 table-responsive">
        <div class="form-group float-left">
          <button
            v-on:click="check_all()"
            class="btn btn-light"
            type="submit"
            style="width:90px;"
          >全選択</button>
          <button
            v-on:click="uncheck_all()"
            class="btn btn-light ml-3"
            type="submit"
            style="width:90px;"
          >解除</button>
        </div>
      </div>
      <div class="container mt-2 table-responsive">
        <table class="table text-center table-bordered table-sm">
          <thead class="bg-primary text-light">
            <tr>
              <th class="align-middle" width="20%" height="35px">選択</th>
              <th class="align-middle" width="30%">帳票名</th>
              <th class="align-middle" width="20%">選択</th>
              <th class="align-middle" width="30%">帳票名</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="authorityAdmin()">
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk0" />
                </td>
                <td class="align-middle" align="left">申請野帳</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk1" />
                </td>
                <td class="align-middle" align="left">災害総計表</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk2" />
                </td>
                <td class="align-middle" align="left">目論見書</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk3" />
                </td>
                <td class="align-middle" align="left">班別工事内訳書</td>
              </tr>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk4" />
                </td>
                <td class="align-middle" align="left">全体とりまとめ表</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk5" />
                </td>
                <td class="align-middle" align="left">市町村工事内訳調書</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk6" />
                </td>
                <td class="align-middle" align="left">班別とりまとめ表</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk7" />
                </td>
                <td class="align-middle" align="left">被災原因別調書</td>
              </tr> 
            </template>
            <template v-else>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk0" />
                </td>
                <td class="align-middle" align="left">申請野帳</td>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk2" />
                </td>
                <td class="align-middle" align="left">目論見書</td>
              </tr>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk4" />
                </td>
                <td class="align-middle" align="left">全体とりまとめ表</td>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk6" />
                </td>
                <td class="align-middle" align="left">班別とりまとめ表</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="container mb-3">
        <form action method>
          <button
            type="button"
            class="btn btn-light d-block mx-auto"
            @click="multipleExport()"
            style="width:120px;"
          >出力</button>
        </form>
      </div>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { APIService } from "@/apiService.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();
var numeral = require("numeral");
export default {
  components: {
    Layout,
    datepicker,
    Loading
  },
  data() {
    var kanCd = "";
    var kanMei = "";
    if (localStorage.getItem("Kanrisya_Cd") == 1) {
      kanCd = "0";
      kanMei = "すべて";
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      engYear: "",
      jpYear: "",
      year: "",
      ja: ja,
      kanrisya1Check: false,
      kanrisyaList: [],
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      nji_satei: "",
      koji_no_from: "",
      koji_no_to: "",
      nji_satei_date: "",
      satei3: "",
      mcodeList1: [],
      mcodeList2: [],
      meisai1: "",
      cd_value1: "",
      meisai2: "",
      cd_value2: "",
      satei_kbn: 1,
      kingaku_kbn: 3,
      tyouhyou_kbn: 0,
      output_order_han: false,
      output_order_koji: false,
      chkArr: [],
      checkError: false,
      yearErrorMsg: "",
      isYearNotFound: false,
      isCheckBoxErr: false,
      checkboxMsg: "",
      isNjisateiErr: false,
      njisateiMsg: [],
      isLoading: false,
      fullPage: true,
      yearFlg: true,
      order_kbn: 1,
      jimusyo_mei : "",
    };
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.engYear = new Date().getFullYear();
    this.getGengo(new Date().getFullYear());
    this.getKanrisya();
    this.getMcodeList1();
    this.getMcodeList2();
  },
  methods: {
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    changedYear() {
      if (this.jpYear) {
        var jpYear_1st_val  = this.jpYear.substring(0, 1);
        if(isNaN(jpYear_1st_val)){
          var matches = this.jpYear.match(/[a-zA-Z]+/g);
          var yvalue = this.jpYear.replace(/[^\d.]/g, "");
          var request_data = {	
            gengo_cd: matches ? matches.toString() : ""	
          };
        }
        apiService.getGengoStartDate(request_data).then(result => {
          if(result.length >0){
            var startDate = result[0].start_date;	
            this.engYear =	
              parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);
            this.yearFlg = true;
          }else{
            this.yearFlg = false;
          }
          
        });	
      } else {
        this.jpYear = null;
      }
    },
    getKanrisya() {
      if (localStorage.getItem("Kanrisya_Cd") == 1) {
        this.kanrisya1Check = true;
      }
      var request_data = {
        kengen_cd: localStorage.getItem('kengen_cd'),
        kanrisya_cd: localStorage.getItem('Kanrisya_Cd'),
      };
      apiService
        .getKanrisyaByFlg(request_data)
        .then(result => {
          this.kanrisyaList = result;
        })
        .catch(error => {
          this.err = error;
        });
        setTimeout(() => {
          this.isLoading = false;
        },500)
    },
    kanrisyaChange(kanrisya) {
      var kanrisya_index =
        kanrisya.target.options[kanrisya.target.options.selectedIndex].text;
      for (var i = 0; i < this.kanrisyaList.length; i++) {
        if (this.kanrisyaList[i].kanrisya_cd == kanrisya_index) {
          this.kanrisya_cd = kanrisya_index;
          this.kanrisya_mei = this.kanrisyaList[i].kanrisya_mei;
        }
      }
    },
    getMcodeList1() {
      apiService
        .fb108getMcodeList1()
        .then(result => {
          this.mcodeList1 = result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    codeChange1(mcode) {
      var mcode_index =
        mcode.target.options[mcode.target.options.selectedIndex].text;
      for (var i = 0; i < this.mcodeList1.length; i++) {
        if (this.mcodeList1[i].meisai == mcode_index) {
          this.meisai1 = mcode_index;
          this.cd_value1 = this.mcodeList1[i].cd_value;
        }
      }
    },
    getMcodeList2() {
      apiService
        .fb108getMcodeList2()
        .then(result => {
          this.mcodeList2 = result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    codeChange2(mcode) {
      var mcode_index =
        mcode.target.options[mcode.target.options.selectedIndex].text;
      for (var i = 0; i < this.mcodeList2.length; i++) {
        if (this.mcodeList2[i].meisai == mcode_index) {
          this.meisai2 = mcode_index;
          this.cd_value2 = this.mcodeList2[i].cd_value;
        }
      }
    },
    check_all() {
      var authorityAdmin = this.authorityAdmin();
      if (authorityAdmin) {
        this.chkArr = [
          "chk0",
          "chk1",
          "chk2",
          "chk3",
          "chk4",
          "chk5",
          "chk6",
          "chk7"
        ];
      } else {
        this.chkArr = [
          "chk0",
          "chk2",
          "chk4",
          "chk6"
        ];
      }
    },
    uncheck_all() {
      this.chkArr = [];
    },
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? "" : result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    nji_sateiChange() {
      if (this.isInteger(this.nji_satei)) {
        this.nji_satei = this.nji_satei
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    multipleExport() {
      this.njisateiMsg = [];
      this.isCheckBoxErr = false;
      this.isLoading = true
      this.checkError = false;
      if(isNaN(this.engYear)){
        this.yearFlg = false;
      }
      if(!this.yearFlg){
        this.checkError = true;
        this.isLoading = false;
        this.isCheckBoxErr = true;
        this.isYearNotFound = false;
        this.checkboxMsg = '災害年' + this.$messages.msg["0002W"];
      }else{
        if (this.jpYear  == null || this.jpYear  == "") {
          this.checkError = true;
          this.isLoading = false;
          this.isYearNotFound = true;
          this.yearErrorMsg = this.$messages.msg["0004W"];
        }
        if (this.chkArr.length === 0) {
          this.checkError = true;
          this.isLoading = false;
          this.isCheckBoxErr = true;
          this.checkboxMsg = this.$messages.msg["0023W"];
        }
        this.requireCheck();
      }
      if (!this.checkError) {
        this.isYearNotFound = false;
        this.isCheckBoxErr = false;
        const request_data = {
          saigai_yyyy: this.engYear,
          nji_satei: this.nji_satei,
          satei_kbn: this.satei_kbn,
          kingaku_kbn: this.kingaku_kbn,
          tyouhyou_kbn: this.tyouhyou_kbn,
          output_order_han: this.output_order_han,
          output_order_koji: this.output_order_koji,
          kanrisya_cd: this.kanrisya_cd,
          kanrisya_mei: this.kanrisya_mei,
          nji_satei_date: !this.nji_satei_date ? null : moment(String(this.nji_satei_date)).format("YYYY/MM/DD"),
          excelObj: this.chkArr,
          order_kbn: this.order_kbn,
          meisai1: this.meisai1,
          meisai2: this.meisai2,
          koji_no_from: this.koji_no_from,
          koji_no_to: this.koji_no_to,
          jimusyo_mei: localStorage.getItem("Jimusyo_Mei"),
        };
        console.log(request_data)	
        apiService
          .fb108Export(request_data)
          .then(result => {
            var date = moment(String(new Date())).format("YYYYMMDD");
            var fileName = this.$constants.FB108EXCELFN.FB108 + "_" + date + ".xlsx";
            if(this.nji_satei){
              fileName = this.$constants.FB108EXCELFN.FB108 + "(第"+ this.nji_satei +"次)_"+ date + ".xlsx";
            }
            this.fb108Download(
              result,
              fileName
            );
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.isLoading = false;
            this.isCheckBoxErr = true;
            const reader = new FileReader();
            reader.onload = (e) => {
              var str = e.target.result;
              str = str.replace(/"/g, '');
              this.checkboxMsg = str;
            };
            reader.readAsText(error);
          });
      } else {
        confirm(this.$messages.msg['0013E']);
        this.isLoading = false;
      }
    },
    fb108Download(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
      this.isLoading = false;
    },
    authorityAdmin(){
      var kengen_cd = localStorage.getItem('kengen_cd');
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    requireCheck() {
      if (this.chkArr.indexOf("chk0") !== -1) {
        // 片方だけ入力された場合エラー
        if (!(this.koji_no_from && this.koji_no_to)
            && (this.koji_no_from || this.koji_no_to)) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0046W"]);
          this.checkError = true;
          this.isLoading = false;
        }
        if (!this.koji_no_from && !this.koji_no_to && !this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0047W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk1") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0034W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk2") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0050W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk3") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0045W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk4") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0051W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk5") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0033W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk6") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0032W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk7") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0031W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
    }
  }
};
</script>