<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info" >{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="error"  class="err">{{ error }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:500px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="jpYear" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
              <label
                for
                class="col-sm col-form-label ml-3 text-right"
                style="width:100%; max-width:65px"
              >査定</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
                <input type="text" class="form-control bg-light" v-model="nji_satei" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >次</label>
            </div>
            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">工事番号</label>
              <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="koji_no" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:100px"
              >管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_mei" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >事業主体</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="cd_value" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >整理番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
                <input type="text" class="form-control bg-light" v-model="saigai_seirinao" readonly />
              </div>
            </div>
            <div class="form-group row mb-0" style="width:100%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">気象コード</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:410px">
                <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly/>
              </div>
              <label for class="col-sm col-form-label ml-4" style="width:100%; max-width:80px">被災日</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="hisaibi" readonly/>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>          
        <div class="container pt-3 clearfix">            
          <div class="form-inline float-right">
            <button class="btn btn-light" type="button" style="width:120px;" :disabled = this.disableInsertBtn @click="insertData()">登録</button>
            <button class="btn btn-light ml-3" type="button" style="width:120px;" :disabled = this.disableUpDelBtn @click="updateData()">更新</button>
            <button @click="back_btn()" class="btn btn-light ml-3" type="button" style="width:120px;">戻る</button>
          </div>
        </div>     
      <div class="container mt-3 table-responsive">
        <div class="form-group float-left">
          <p class="m-0 p-0 text-info"><font color="#CCFFFF">■</font>は各行項目に入力があった場合、必須となります。</p>
        </div>
        <table class="table text-center table-bordered table-sm">
          <thead class="bg-primary text-light">
            <tr>
              <th class="align-middle" width="10%">No.</th>
              <th class="align-middle" width="25%">区分</th>
              <th class="align-middle" width="10%">年災</th>
              <th class="align-middle" width="20%">工事番号</th>
              <th class="align-middle" width="10%">分冊番号</th>
              <th class="align-middle" width="25%">打切精算額(千円)</th>
            </tr>
          </thead>              
          <tbody v-for="(kotukoNaiminaiten,index) in kotukoNaiminaitenList" :key="kotukoNaiminaiten.renban" :value="index">
            <tr>
              <td class="align-middle" width="5%">{{kotukoNaiminaiten.renban}}</td>
              <td class="align-middle" width="25%">
                <div class="form-inline">
                    <select class="form-control" v-model="kotukoNaiminaiten.kbn" style="width:100%;background-color: #CCFFFF" v-on:change="codeChange($event,index)">
                      <option value=""></option>
                       <option
                        v-for="mcode in mcodeList"
                        :key="mcode.meisai"
                        :value="mcode.meisai"
                      >{{mcode.meisai}} : {{mcode.cd_value}}</option>
                    </select>
                </div>
              </td>
              <td class="align-middle" width="10%">
                <div class="form-inline">
                    <select class="form-control" v-model="kotukoNaiminaiten.nensai" style="width:100%;background-color: #CCFFFF" v-on:change="nensaiChange($event,index)">
                      <option value=""></option>
                       <option
                        v-for="nensai in nensaiList"
                        :key="nensai.nen"
                        :value="nensai.nen"
                      >{{nensai.gengo}}</option>
                    </select>
                </div>
              </td>
              <td class="align-middle " width="20%">
                <input type="tel" class="form-control p-1 flex-fill text-right" maxlength="9" v-model="kotukoNaiminaiten.naimi_kojino" style="width:100%;background-color: #CCFFFF" @blur="kojinoChange(index)">
              </td>
              <td class="align-middle" width="10%">
                <input type="tel" class="form-control p-1 flex-fill text-right" maxlength="9" v-model="kotukoNaiminaiten.bunsatu_no" style="width:100%" @blur="bunsatuChange(index)">
              </td>
              <td class="text-right" width="25%">
                <input type="tel" class="form-control p-1 flex-fill text-right" maxlength="9" v-model="kotukoNaiminaiten.seisan_gaku" style="width:100%" @blur="seisanGakuChange(index)" @focus="removeComma(index)">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container pt-3 clearfix">            
        <div class="form-inline float-right">
          <button class="btn btn-light ml-3" type="button" style="width:120px;" :disabled = this.disableUpDelBtn @click="deleteData()">削除</button>
        </div>
      </div>  
     </form>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import moment from 'moment';
import { APIService } from '@/apiService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
var numeral = require("numeral");
const apiService = new APIService();
export default {
  components: {
    Layout,Loading
  },
  data() {
    return {
      jpYear: "",
      saigai_yyyy: this.$route.query.saigai_yyyy,
      ikkan_no: this.$route.query.ikkan_no,
      nji_satei: '',
      koji_no: '',
      hisaibi: '',
      saigai_seirinao: '',
      kanrisya_mei: '',
      cd_value: '',
      saigai_mei: '',
      mcodeList: [],
      nensaiList: [],
      kotukoNaiminaitenList: [],
      info: '',
      error: '',
      validFlg: true,
      disableUpDelBtn: true,
      disableInsertBtn: true,
      post: null,
      isLoading: false,
      fullPage: true,
      chk_date: ""
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getKotukoSinsei();
    this.getMcodeList();
    this.getNensaiList();
    this.getKotukoNaiminaiten();
    this.getGengo(this.saigai_yyyy);
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    getKotukoSinsei() {
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        ikkan_no: this.ikkan_no
      }
      apiService.getKotukoSinsei(request_data)
      .then(result => {
        this.nji_satei = result.nji_satei;
        this.koji_no = result.koji_no;
        this.hisaibi = this.formatDate(result.hisaibi);
        this.saigai_seirinao = result.saigai_seirinao;
        this.kanrisya_mei = result.kanrisya_mei;
        this.cd_value = result.cd_value;
        this.saigai_mei = result.saigai_mei;
      }).catch (error => {
        console.log(error);
      });
    },
    getMcodeList() {
      apiService.getMcodeList()
      .then(result => {
        this.mcodeList = result;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getNensaiList() {
      apiService.getNensaiList()
      .then(result => {
        this.nensaiList = result;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getKotukoNaiminaiten() {
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        ikkan_no: this.ikkan_no
      }
      apiService.fb105getKotukoNaiminaiten(request_data)
      .then(result => {
        console.log(result)
        this.kotukoNaiminaitenList = result.result;
        this.disableInsertBtn = false;
        this.disableUpDelBtn = true;
        for(var i = 0; i < this.kotukoNaiminaitenList.length; i++) {
          this.kotukoNaiminaitenList[i].naimi_kojino = this.formatNumber(this.kotukoNaiminaitenList[i].naimi_kojino);
          if (this.kotukoNaiminaitenList[i].bunsatu_no !== 0) {
            this.kotukoNaiminaitenList[i].bunsatu_no = this.formatNumber(this.kotukoNaiminaitenList[i].bunsatu_no);
          }
          this.kotukoNaiminaitenList[i].seisan_gaku = this.formatComma(this.kotukoNaiminaitenList[i].seisan_gaku);
          if(this.kotukoNaiminaitenList[i].nensai) {
            this.kotukoNaiminaitenList[i].nensai = this.kotukoNaiminaitenList[i].nensai_eng;
          }
        }
        if(result.flg) {
          this.disableInsertBtn = true;
          this.disableUpDelBtn = false;
        }

        this.chk_date = result.chk_date; // 排他チェック用
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },
    codeChange(mcode,index){
      var mcode_index = mcode.target.options[mcode.target.options.selectedIndex].text;
      if(mcode_index == '') {
        this.kotukoNaiminaitenList[index].cd_value = '';
      } else {
        for(var i = 0; i < this.mcodeList.length; i++){
          if(this.mcodeList[i].meisai == mcode_index){
            this.kotukoNaiminaitenList[index].cd_value = this.mcodeList[i].cd_value;
            this.kotukoNaiminaitenList[index].kbn = mcode_index;
          }
        }
      }
    },
    nensaiChange(nensai,index){
      var nensai_index = nensai.target.options[nensai.target.options.selectedIndex].text;
      if(nensai_index == '') {
        this.kotukoNaiminaitenList[index].nensai = '';
      } else {
        for(var i = 0; i < this.nensaiList.length; i++){
          if(this.nensaiList[i].nen == nensai_index){
            this.kotukoNaiminaitenList[index].nensai = nensai_index;
          }
        }
      }
    },
    kojinoChange(index) {
      this.kotukoNaiminaitenList[index].naimi_kojino = this.formatNumber(this.kotukoNaiminaitenList[index].naimi_kojino);
    },
    bunsatuChange(index) {
      if (this.kotukoNaiminaitenList[index].bunsatu_no === '0') {
        this.kotukoNaiminaitenList[index].bunsatu_no = 0;
      } else {
              if (this.isInteger(this.kotukoNaiminaitenList[index].bunsatu_no)) {
                this.kotukoNaiminaitenList[index].bunsatu_no = this.kotukoNaiminaitenList[index].bunsatu_no
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
      }
    },
    seisanGakuChange(index) {
      this.kotukoNaiminaitenList[index].seisan_gaku = this.removeComma(this.kotukoNaiminaitenList[index].seisan_gaku);
      if (this.isFloat(this.kotukoNaiminaitenList[index].seisan_gaku)) {
        this.kotukoNaiminaitenList[index].seisan_gaku = this.kotukoNaiminaitenList[index].seisan_gaku
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    removeComma(index) {
      this.kotukoNaiminaitenList[index].seisan_gaku = this.replaceComma(this.kotukoNaiminaitenList[index].seisan_gaku);
    },
    insertData() {
      var existFlg = false;

      this.info = '';
      this.error = '';
      this.isLoading = true;
      for(var i = 0 ; i < this.kotukoNaiminaitenList.length ; i++) {
        if(this.kotukoNaiminaitenList[i].cd_value ||
           this.kotukoNaiminaitenList[i].kbn ||
           this.kotukoNaiminaitenList[i].nensai ||
           this.kotukoNaiminaitenList[i].naimi_kojino ||
           this.kotukoNaiminaitenList[i].bunsatu_no || 
           this.kotukoNaiminaitenList[i].seisan_gaku) {
             if(!this.kotukoNaiminaitenList[i].kbn) {
               confirm(this.$messages.msg['0013E']);
               this.error = "連番" + (i+1) + "の区分" + this.$messages.msg['00013W'];
               this.validFlg = false;
               this.isLoading = false;
               break;
             }
             if(!this.kotukoNaiminaitenList[i].nensai) {
               confirm(this.$messages.msg['0013E']);
               this.error = "連番" + (i+1) + "の年災" + this.$messages.msg['00013W'];
               this.validFlg = false;
               this.isLoading = false;
               break;
             }
             if(!this.kotukoNaiminaitenList[i].naimi_kojino) {
               confirm(this.$messages.msg['0013E']);
               this.error = "連番" + (i+1) + "の工事番号" + this.$messages.msg['00013W'];
               this.validFlg = false;
               this.isLoading = false;
               break;
             }
             this.validFlg = true;

             existFlg = true;
        }
      }
      for(var j = 0 ; j < this.kotukoNaiminaitenList.length ; j++) {
        this.kotukoNaiminaitenList[j].seisan_gaku = this.replaceComma(this.kotukoNaiminaitenList[j].seisan_gaku);
      }
      if(this.validFlg) {
        if (existFlg === false) {
            confirm(this.$messages.msg['0013E']);
            this.info = '';
            this.error = '必須項目の入力がありません。';
            this.isLoading = false;
            return;
        }

        this.error= '';
        var request_data = {
          saigai_yyyy: this.saigai_yyyy,
          koji_no: this.koji_no,
          ikkan_no: this.ikkan_no,
          loginId: localStorage.getItem('LOGIN_ID'),
          kotuko_naiminaiten_list: this.kotukoNaiminaitenList,
        }
        apiService.fb105insertKotukoNaiminaiten(request_data)
        .then(result => {
          this.info = result;
          this.getKotukoNaiminaiten();
          this.getMcodeList();
          this.getNensaiList();
          this.isLoading = false;
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.info = '';
          this.error = error;
          this.isLoading = false;
        })
      }
    },
    updateData() {
      var existFlg = false;

      this.info = '';
      this.error = '';
      this.isLoading = true;
      for(var i = 0 ; i < this.kotukoNaiminaitenList.length ; i++) {
        if(this.kotukoNaiminaitenList[i].cd_value ||
           this.kotukoNaiminaitenList[i].kbn ||
           this.kotukoNaiminaitenList[i].nensai ||
           this.kotukoNaiminaitenList[i].naimi_kojino ||
           this.kotukoNaiminaitenList[i].bunsatu_no || 
           this.kotukoNaiminaitenList[i].seisan_gaku) {
             if(!this.kotukoNaiminaitenList[i].kbn) {
               confirm(this.$messages.msg['0013E']);
               this.error = "連番" + (i+1) + "の区分" + this.$messages.msg['00013W'];
               this.validFlg = false;
               this.isLoading = false;
               break;
             }
             if(!this.kotukoNaiminaitenList[i].nensai) {
               confirm(this.$messages.msg['0013E']);
               this.error = "連番" + (i+1) + "の年災" + this.$messages.msg['00013W'];
               this.validFlg = false;
               this.isLoading = false;
               break;
             }
             if(!this.kotukoNaiminaitenList[i].naimi_kojino) {
               confirm(this.$messages.msg['0013E']);
               this.error = "連番" + (i+1) + "の工事番号" + this.$messages.msg['00013W'];
               this.validFlg = false;
               this.isLoading = false;
               break;
             }
             this.validFlg = true;

             existFlg = true;
        }
      }
      for(var j = 0 ; j < this.kotukoNaiminaitenList.length ; j++) {
        this.kotukoNaiminaitenList[j].seisan_gaku = this.replaceComma(this.kotukoNaiminaitenList[j].seisan_gaku);
      }
      if(this.validFlg) {

        if (existFlg === false) {
            confirm(this.$messages.msg['0013E']);
            this.info = '';
            this.error = '必須項目の入力がありません。';
            this.isLoading = false;
            return;
        }

        this.error= '';
        var request_data = {
          saigai_yyyy: this.saigai_yyyy,
          koji_no: this.koji_no,
          ikkan_no: this.ikkan_no,
          loginId: localStorage.getItem('LOGIN_ID'),
          kotuko_naiminaiten_list: this.kotukoNaiminaitenList,
          chk_date: this.chk_date
        }
        apiService.fb105updateKotukoNaiminaiten(request_data)
        .then(result => {
          this.error = '';
          this.info = result;
          this.getKotukoNaiminaiten();
          this.getMcodeList();
          this.getNensaiList();
          this.isLoading = false;
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.info = '';
          this.error = error;
          this.isLoading = false;
        })
      }
    },  
    deleteData() {
      this.info = '';
      this.error = '';
      this.isLoading = true;
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        koji_no: this.koji_no,
        ikkan_no: this.ikkan_no,
        loginId: localStorage.getItem('LOGIN_ID'),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fb105Delete(request_data)
          .then(result => {
            this.error = '';
            this.info = result;
            this.getKotukoNaiminaiten();
            this.getMcodeList();
            this.getNensaiList();
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error);
            if(error.errors != null){
              this.errors.biko = error.errors.biko
              ? error.errors.biko[0]
              : "";
              confirm(this.$messages.msg['0013E']);
            }
            confirm(this.$messages.msg['0013E']);
            this.info = '';
            this.error = error;
            this.isLoading = false;
          });
      }
      this.isLoading = false;
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    },
    formatNumber: function(number){
     if(number == 0) {
          return '';
      }
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? '': result;
    },
    formatComma: function(number){
     if(number == 0) {
          return '';
      }
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? '': result;
    },
    formatDate: function(date) {
      if(date == '' || date == null ){
        return date;
      }
      return moment(String(date)).format("YYYY/MM/DD");
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    replaceComma: function(string) {
        if(string == '' || string == null){
          return string;
        }
        return string.trim().replace(/,/g,'');
    }
  }
};
</script>