<template>
  <div id="page-content" class="AppLayout d-flex flex-column">
    <Header />
      <slot />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue'
import Footer from '@/components/layouts/Footer.vue'

export default {
  name: 'Applayout',
  components: {
    Header,
    Footer
  }
}
</script>