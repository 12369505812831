<script>
import AppLayout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { ja } from "vuejs-datepicker/dist/locale";
import { APIService } from "@/apiService.js";

const apiService = new APIService();
var numeral = require("numeral");
export default {
  name: "fa110",
  components: {
    AppLayout
  },
  data() {
    return {
      jpYear: "",
      year: parseInt(this.$route.query.saigai_yyyy),
      saigai_ken: parseInt(this.$route.query.saigai_ken),
      kanrisya_cd: parseInt(this.$route.query.kanrisya_cd),
      houkokusya_cd: parseInt(this.$route.query.houkokusya_cd),
      saigai_start: "",
      saigai_end: "",
      saigai_mei: "",
      ja: ja,
      higai_douro: {},
      zenmen_kunirosen: "",
      zenmen_kunikasyo: "",
      zenmen_kenrosen: "",
      zenmen_kenkasyo: "",
      zenmen_shicyousonrosen: "",
      zenmen_shicyousonkasyo: "",
      ichibu_kunirosen: "",
      ichibu_kunikasyo: "",
      ichibu_kenrosen: "",
      ichibu_kenkasyo: "",
      ichibu_shicyousonrosen: "",
      ichibu_shicyousonkasyo: "",
      loginUser: {
        login_id: window.localStorage.getItem("LOGIN_ID"),
        kanrisya_name: window.localStorage.getItem("Kanrisya_Mei")
      },
      zemen_sen: "",
      zemen_syo: "",
      ichibu_sen: "",
      ichibu_syo: "",
      info: null,
      unique_error: null,
      post: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.getGengo(this.year);
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    search(year, saigai_ken) {
      this.clearError();
      this.info = null;
      var request_data = {
        year: year,
        ken: saigai_ken
      };
      apiService
        .fa110GetMeisyou(request_data)
        .then(result => {
          this.meisyou = result;
          this.saigai_ken = this.meisyou[0].saigai_ken;
          this.saigai_mei = this.meisyou[0].saigai_mei;
          this.saigai_start = this.meisyou[0].saigai_start;
          this.saigai_start = moment(String(this.saigai_start)).format(
            "YYYY/MM/DD"
          );
          this.saigai_end = this.meisyou[0].saigai_end;
          this.saigai_end = moment(String(this.saigai_end)).format(
            "YYYY/MM/DD"
          );
          this.getKanrisyaData(
            this.year,
            this.saigai_ken,
            this.kanrisya_cd,
            this.houkokusya_cd
          );
        })
        .catch(error => {
          console.log(error);
        });
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService
        .getGengo(request_data)
        .then(result => {
          this.jpYear = result;
          this.search(this.year, this.saigai_ken);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getKanrisyaData(year, saigai_ken, kanrisya_cd, houkokusya_cd) {
      this.clearError();
      var request_data = {
        year: year,
        saigai_ken: saigai_ken,
        kanrisya_cd: kanrisya_cd,
        houkokusya_cd: houkokusya_cd
      };
      apiService
        .fa110getKanrisya(request_data)
        .then(result => {
          this.higai_douro = result;
          if (Object.keys(this.higai_douro).length > 0) {
            var total_sen1 =
              this.higai_douro[0].zenmen_kunirosen +
              this.higai_douro[0].zenmen_kenrosen +
              this.higai_douro[0].zenmen_shicyousonrosen;
            if (total_sen1 != 0) {
              this.zemen_sen = this.formatNumber(total_sen1.toString());
            }

            var total_syo1 =
              this.higai_douro[0].zenmen_kunikasyo +
              this.higai_douro[0].zenmen_kenkasyo +
              this.higai_douro[0].zenmen_shicyousonkasyo;
            if (total_syo1) {
              this.zemen_syo = this.formatNumber(total_syo1.toString());
            }

            var total_sen2 =
              this.higai_douro[0].ichibu_kunirosen +
              this.higai_douro[0].ichibu_kenrosen +
              this.higai_douro[0].ichibu_shicyousonrosen;
            if (total_sen2 != 0) {
              this.ichibu_sen = this.formatNumber(total_sen2.toString());
            }

            var total_syo2 =
              this.higai_douro[0].ichibu_kunikasyo +
              this.higai_douro[0].ichibu_kenkasyo +
              this.higai_douro[0].ichibu_shicyousonkasyo;
            if (total_syo2 != 0) {
              this.ichibu_syo = this.formatNumber(total_syo2.toString());
            }

            if (this.higai_douro[0].zenmen_kunirosen) {
              this.zenmen_kunirosen = this.formatNumber(
                this.higai_douro[0].zenmen_kunirosen
              );
            }
            if (this.higai_douro[0].zenmen_kunikasyo) {
              this.zenmen_kunikasyo = this.formatNumber(
                this.higai_douro[0].zenmen_kunikasyo
              );
            }
            if (this.higai_douro[0].zenmen_kenrosen) {
              this.zenmen_kenrosen = this.formatNumber(
                this.higai_douro[0].zenmen_kenrosen
              );
            }
            if (this.higai_douro[0].zenmen_kenkasyo) {
              this.zenmen_kenkasyo = this.formatNumber(
                this.higai_douro[0].zenmen_kenkasyo
              );
            }
            if (this.higai_douro[0].zenmen_shicyousonrosen) {
              this.zenmen_shicyousonrosen = this.formatNumber(
                this.higai_douro[0].zenmen_shicyousonrosen
              );
            }
            if (this.higai_douro[0].zenmen_shicyousonkasyo) {
              this.zenmen_shicyousonkasyo = this.formatNumber(
                this.higai_douro[0].zenmen_shicyousonkasyo
              );
            }
            if (this.higai_douro[0].ichibu_kunirosen) {
              this.ichibu_kunirosen = this.formatNumber(
                this.higai_douro[0].ichibu_kunirosen
              );
            }
            if (this.higai_douro[0].ichibu_kunikasyo) {
              this.ichibu_kunikasyo = this.formatNumber(
                this.higai_douro[0].ichibu_kunikasyo
              );
            }
            if (this.higai_douro[0].ichibu_kenrosen) {
              this.ichibu_kenrosen = this.formatNumber(
                this.higai_douro[0].ichibu_kenrosen
              );
            }
            if (this.higai_douro[0].ichibu_kenkasyo) {
              this.ichibu_kenkasyo = this.formatNumber(
                this.higai_douro[0].ichibu_kenkasyo
              );
            }
            if (this.higai_douro[0].ichibu_shicyousonrosen) {
              this.ichibu_shicyousonrosen = this.formatNumber(
                this.higai_douro[0].ichibu_shicyousonrosen
              );
            }
            if (this.higai_douro[0].ichibu_shicyousonkasyo) {
              this.ichibu_shicyousonkasyo = this.formatNumber(
                this.higai_douro[0].ichibu_shicyousonkasyo
              );
            }
          } else {
            this.clearData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number) {
      if (number == "0") {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    },
    formatInteger: function(number){
      var result = numeral(number).format("0");
      
      return result;
    },
    removeComma: function(number) {
      return number.toString().replace(/,/g, "");
    },
    zenmen_kunirosenChange() {
      var total_zemen_sen =
        parseInt(this.formatInteger(this.zenmen_kunirosen)) +
        parseInt(this.formatInteger(this.zenmen_kenrosen)) +
        parseInt(this.formatInteger(this.zenmen_shicyousonrosen));
      if (total_zemen_sen) {
        this.zemen_sen = this.formatNumber(total_zemen_sen.toString());
      }
      this.zenmen_kunirosen = this.formatNumber(this.zenmen_kunirosen);
    },
    zenmen_kunikasyoChange() {
      var total_zemen_syo =
        parseInt(this.formatInteger(this.zenmen_kunikasyo)) +
        parseInt(this.formatInteger(this.zenmen_kenkasyo)) +
        parseInt(this.formatInteger(this.zenmen_shicyousonkasyo));
      if (total_zemen_syo) {
        this.zemen_syo = this.formatNumber(total_zemen_syo.toString());
      }
      this.zenmen_kunikasyo = this.formatNumber(this.zenmen_kunikasyo);
    },
    zenmen_shicyousonrosenChange() {
      var total_zemen_sen =
        parseInt(this.formatInteger(this.zenmen_kunirosen)) +
        parseInt(this.formatInteger(this.zenmen_kenrosen)) +
        parseInt(this.formatInteger(this.zenmen_shicyousonrosen));
      if (total_zemen_sen) {
        this.zemen_sen = this.formatNumber(total_zemen_sen.toString());
      }
      this.zenmen_shicyousonrosen = this.formatNumber(
        this.zenmen_shicyousonrosen
      );
    },
    zenmen_shicyousonkasyoChange() {
      var total_zemen_syo =
        parseInt(this.formatInteger(this.zenmen_kunikasyo)) +
        parseInt(this.formatInteger(this.zenmen_kenkasyo)) +
        parseInt(this.formatInteger(this.zenmen_shicyousonkasyo));
      if (total_zemen_syo) {
        this.zemen_syo = this.formatNumber(total_zemen_syo.toString());
      }
      this.zenmen_shicyousonkasyo = this.formatNumber(
        this.zenmen_shicyousonkasyo
      );
    },
    zenmen_kenrosenChange() {
      var total_zemen_sen =
        parseInt(this.formatInteger(this.zenmen_kunirosen)) +
        parseInt(this.formatInteger(this.zenmen_kenrosen)) +
        parseInt(this.formatInteger(this.zenmen_shicyousonrosen));
      if (total_zemen_sen) {
        this.zemen_sen = this.formatNumber(total_zemen_sen.toString());
      }
      this.zenmen_kenrosen = this.formatNumber(this.zenmen_kenrosen);
    },
    zenmen_kenkasyoChange() {
      var total_zemen_syo =
        parseInt(this.formatInteger(this.zenmen_kunikasyo)) +
        parseInt(this.formatInteger(this.zenmen_kenkasyo)) +
        parseInt(this.formatInteger(this.zenmen_shicyousonkasyo));
      if (total_zemen_syo) {
        this.zemen_syo = this.formatNumber(total_zemen_syo.toString());
      }
      this.zenmen_kenkasyo = this.formatNumber(this.zenmen_kenkasyo);
    },
    ichibu_kunirosenChange() {
      var total_ichibu_sen =
        parseInt(this.formatInteger(this.ichibu_kunirosen)) +
        parseInt(this.formatInteger(this.ichibu_kenrosen)) +
        parseInt(this.formatInteger(this.ichibu_shicyousonrosen));
      if (total_ichibu_sen) {
        this.ichibu_sen = this.formatNumber(total_ichibu_sen.toString());
      }
      this.ichibu_kunirosen = this.formatNumber(this.ichibu_kunirosen);
    },
    ichibu_kunikasyoChange() {
      var total_ichibu_syo =
        parseInt(this.formatInteger(this.ichibu_kunikasyo)) +
        parseInt(this.formatInteger(this.ichibu_kenkasyo)) +
        parseInt(this.formatInteger(this.ichibu_shicyousonkasyo));
      if (total_ichibu_syo) {
        this.ichibu_syo = this.formatNumber(total_ichibu_syo.toString());
      }
      this.ichibu_kunikasyo = this.formatNumber(this.ichibu_kunikasyo);
    },
    ichibu_shicyousonrosenChange() {
      var total_ichibu_sen =
        parseInt(this.formatInteger(this.ichibu_kunirosen)) +
        parseInt(this.formatInteger(this.ichibu_kenrosen)) +
        parseInt(this.formatInteger(this.ichibu_shicyousonrosen));
      if (total_ichibu_sen) {
        this.ichibu_sen = this.formatNumber(total_ichibu_sen.toString());
      }
      this.ichibu_shicyousonrosen = this.formatNumber(
        this.ichibu_shicyousonrosen
      );
    },
    ichibu_shicyousonkasyoChange() {
      var total_ichibu_syo =
        parseInt(this.formatInteger(this.ichibu_kunikasyo)) +
        parseInt(this.formatInteger(this.ichibu_kenkasyo)) +
        parseInt(this.formatInteger(this.ichibu_shicyousonkasyo));
      if (total_ichibu_syo) {
        this.ichibu_syo = this.formatNumber(total_ichibu_syo.toString());
      }
      this.ichibu_shicyousonkasyo = this.formatNumber(
        this.ichibu_shicyousonkasyo
      );
    },
    ichibu_kenrosenChange() {
      var total_ichibu_sen =
        parseInt(this.formatInteger(this.ichibu_kunirosen)) +
        parseInt(this.formatInteger(this.ichibu_kenrosen)) +
        parseInt(this.formatInteger(this.ichibu_shicyousonrosen));
      if (total_ichibu_sen) {
        this.ichibu_sen = this.formatNumber(total_ichibu_sen.toString());
      }
      this.ichibu_kenrosen = this.formatNumber(this.ichibu_kenrosen);
    },
    ichibu_kenkasyoChange() {
      var total_ichibu_syo =
        parseInt(this.formatInteger(this.ichibu_kunikasyo)) +
        parseInt(this.formatInteger(this.ichibu_kenkasyo)) +
        parseInt(this.formatInteger(this.ichibu_shicyousonkasyo));
      if (total_ichibu_syo) {
        this.ichibu_syo = this.formatNumber(total_ichibu_syo.toString());
      }
      this.ichibu_kenkasyo = this.formatNumber(this.ichibu_kenkasyo);
    },
    clearData() {
      (this.zenmen_kunirosen = ""),
        (this.zenmen_kunikasyo = ""),
        (this.zenmen_kenrosen = ""),
        (this.zenmen_kenkasyo = ""),
        (this.zenmen_shicyousonrosen = ""),
        (this.zenmen_shicyousonkasyo = ""),
        (this.ichibu_kunirosen = ""),
        (this.ichibu_kunikasyo = ""),
        (this.ichibu_kenrosen = ""),
        (this.ichibu_kenkasyo = ""),
        (this.ichibu_shicyousonrosen = ""),
        (this.ichibu_shicyousonkasyo = "");
    },
    insertUpdate() {
      this.clearError();
      this.info = null;
      var request_data = {
        year: this.year,
        saigai_ken: this.saigai_ken,
        kanrisya_cd: this.kanrisya_cd,
        houkokusya_cd: this.houkokusya_cd,
        zenmen_kunirosen: this.removeComma(this.zenmen_kunirosen),
        zenmen_kunikasyo: this.removeComma(this.zenmen_kunikasyo),
        zenmen_kenrosen: this.removeComma(this.zenmen_kenrosen),
        zenmen_kenkasyo: this.removeComma(this.zenmen_kenkasyo),
        zenmen_shicyousonrosen: this.removeComma(this.zenmen_shicyousonrosen),
        zenmen_shicyousonkasyo: this.removeComma(this.zenmen_shicyousonkasyo),
        ichibu_kunirosen: this.removeComma(this.ichibu_kunirosen),
        ichibu_kunikasyo: this.removeComma(this.ichibu_kunikasyo),
        ichibu_kenrosen: this.removeComma(this.ichibu_kenrosen),
        ichibu_kenkasyo: this.removeComma(this.ichibu_kenkasyo),
        ichibu_shicyousonrosen: this.removeComma(this.ichibu_shicyousonrosen),
        ichibu_shicyousonkasyo: this.removeComma(this.ichibu_shicyousonkasyo),
        login_id: localStorage.getItem("LOGIN_ID")
      };
      if (this.higai_douro.length > 0) {
        apiService
          .fa110Update(request_data)
          .then(result => {
            this.info = result;
            this.getKanrisyaData(
              this.year,
              this.saigai_ken,
              this.kanrisya_cd,
              this.houkokusya_cd
            );
          })
          .catch(error => {
            console.log(error);
            this.unique_error = error;
            this.info = null;
          });
      } else {
        apiService
          .fa110Insert(request_data)
          .then(result => {
            this.info = result;
            this.getKanrisyaData(
              this.year,
              this.saigai_ken,
              this.kanrisya_cd,
              this.houkokusya_cd
            );
          })
          .catch(error => {
            console.log(error);
            this.unique_error = error;
            this.info = null;
          });
      }
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    },
    clearError() {
      this.unique_error = null;
    }
  }
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="unique_error" class="err">{{ unique_error }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:450px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input
                  type="text"
                  class="form-control bg-light"
                  maxlength="4"
                  v-model="jpYear"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:130px"
              >被害報告番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
                <input
                  type="text"
                  class="form-control bg-light"
                  value
                  v-model="houkokusya_cd"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:200px;">
                <input
                  class="form-control bg-light"
                  style="border-radius: 0; position:absolute; width:180px;"
                  type="text"
                  v-model="saigai_ken"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:100px"
              >異常気象名</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
                <input
                  type="text"
                  class="form-control bg-light"
                  value
                  v-model="saigai_mei"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >災害発生日</label>
              <div class="col-sm pr-0" style="width:100%; max-width:130px">
                <input
                  type="text"
                  class="form-control bg-light"
                  value
                  v-model="saigai_start"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-center"
                style="width:100%; max-width:50px"
              >〜</label>
              <div class="col-sm pl-0" style="width:100%; max-width:130px">
                <input
                  type="text"
                  class="form-control bg-light"
                  value
                  v-model="saigai_end"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row mb-0" style="width:55%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input
                  type="text"
                  class="form-control bg-light"
                  value
                  v-model="kanrisya_cd"
                  readonly
                />
              </div>
              <div class="col-sm pr-0 pl-1" style="width:100%; max-width:500px">
                <input
                  type="text"
                  class="form-control bg-light"
                  value
                  v-model="loginUser.kanrisya_name"
                  readonly
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix">
          <div class="rounded bg-success p-2 text-center float-left" style="width:130px;">
            <p class="m-0 p-0 text-white font-weight-bold">道路通行止</p>
          </div>
          <div class="form-inline float-right">
            <button
              v-on:click="insertUpdate()"
              class="btn btn-light"
              type="button"
              style="width:120px;"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="back_btn()"
            >戻る</button>
          </div>
        </div>
        <div class="container mt-3">
          <table class>
            <tbody class="align-middle">
              <tr>
                <td scope="row" class="align-middle pt-2 pb-2">県管理国道</td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="zenmen_kunirosen"
                      @blur="zenmen_kunirosenChange()"
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="zenmen_kunikasyo"
                      @blur="zenmen_kunikasyoChange()"
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-2 pb-2">都道府県道</td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="zenmen_kenrosen"
                      @blur="zenmen_kenrosenChange()"
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="zenmen_kenkasyo"
                      @blur="zenmen_kenkasyoChange()"
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-2 pb-2">市町村道</td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="zenmen_shicyousonrosen"
                      @blur="zenmen_shicyousonrosenChange()"
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="zenmen_shicyousonkasyo"
                      @blur="zenmen_shicyousonkasyoChange()"
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
                <td></td>
                <td class="align-middle pl-5 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <label for class="form-label mr-2" style>計</label>
                    <input
                      type="text"
                      class="form-control bg-light p-1 text-right"
                      value
                      style="width:100px"
                      v-model="zemen_sen"
                      readonly
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control bg-light p-1 text-right"
                      value
                      style="width:100px"
                      v-model="zemen_syo"
                      readonly
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--container-->
        <div class="container mt-3 pt-3 border-top border-secondary">
          <div class="rounded bg-success p-2 text-center" style="width:130px;">
            <p class="m-0 p-0 text-white font-weight-bold">一部通行規制</p>
          </div>
          <table class="mt-3">
            <tbody class="align-middle">
              <tr>
                <td scope="row" class="align-middle pt-2 pb-2">県管理国道</td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="ichibu_kunirosen"
                      @blur="ichibu_kunirosenChange()"
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="ichibu_kunikasyo"
                      @blur="ichibu_kunikasyoChange()"
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-2 pb-2">都道府県道</td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="ichibu_kenrosen"
                      @blur="ichibu_kenrosenChange()"
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="ichibu_kenkasyo"
                      @blur="ichibu_kenkasyoChange()"
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td scope="row" class="align-middle pt-2 pb-2">市町村道</td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="ichibu_shicyousonrosen"
                      @blur="ichibu_shicyousonrosenChange()"
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control p-1 text-right"
                      value
                      style="width:100px"
                      maxlength="9"
                      v-model="ichibu_shicyousonkasyo"
                      @blur="ichibu_shicyousonkasyoChange()"
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
                <td></td>
                <td class="align-middle pl-5 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <label for class="form-label mr-2" style>計</label>
                    <input
                      type="text"
                      class="form-control bg-light p-1 text-right"
                      value
                      style="width:100px"
                      v-model="ichibu_sen"
                      readonly
                    />
                    <label for class="form-label ml-2" style>路線</label>
                  </div>
                </td>
                <td class="align-middle pl-3 pt-2 pb-2" colspan>
                  <div class="form-inline align-items-center">
                    <input
                      type="text"
                      class="form-control bg-light p-1 text-right"
                      value
                      style="width:100px"
                      v-model="ichibu_syo"
                      readonly
                    />
                    <label for class="form-label ml-2" style>箇所</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--container-->
      </form>
    </main>
  </AppLayout>
</template>