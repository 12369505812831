const defaultState = {
    search: '',
  };
  export default {
    namespaced: true,
    state: defaultState,
    mutations: {
      setLoginId(state, result) {
        state.login_id = result;
      },
      setLoginName(state, result) {
        state.login_name = result;
      },
      setKanrisya(state, result) {
        state.kanrisya_cd = result;
      },
      setKanrisyaMei(state, result) {
        state.kanrisya_mei = result;
      },
      setValidFlg(state, result) {
        state.valid_flg = result;
      },
      setKengenCd(state, result) {
        state.kengen_cd = result;
      },
      setPage(state, result) {
        state.page = result;
      },
      init(state) {
        Object.assign(state, defaultState);
      },
    }
  };