<script>
import AppLayout from "@/components/layouts/Layout.vue";

export default {
  name: "saigaiMenu",
  components: {
    AppLayout,
  },
  data() {
    return {
      isTest:
        window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
    };
  },
  methods: {
    gofa104() {
      this.$store.commit("fa104/setYear", "");
      this.$store.commit("fa104/setKen", "");
      this.$store.commit("fa104/setKanrisya", "");
      this.$store.commit("fa104/setKanrisyaMei", "");
      this.$store.commit("fa104/setHokokusya", "");
      this.$store.commit("fa104/setPage", "");
      this.$router.push({
        name: "FA104",
      });
    },
    gofa101() {
      this.$store.commit("fa101/setYear", "");
      this.$store.commit("fa101/setKen", "");
      this.$store.commit("fa101/setKanrisya", "");
      this.$store.commit("fa101/setKanrisyaMei", "");
      this.$store.commit("fa101/setValidFlg", "");
      this.$store.commit("fa101/setPage", "");
      this.$store.commit("fa101/setStatus", "");
      this.$router.push({
        name: "FA101",
      });
    },
    authorityAdmin() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    authorityGyousha() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.GENBA) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div
        class="container-fluid bg-info mb-3 pt-2 pb-2"
        :class="this.isTest ? 'test-bg-info' : ''"
      >
        <div class="container">
          <h3 class="text-white mb-0">災害報告管理機能</h3>
        </div>
      </div>
      <div class="container mb-3">
        <h4 class="text-primary">【登録機能】</h4>
        <div class="d-flex flex-row flex-wrap">
          <template v-if="authorityAdmin()">
            <router-link
              :to="{ name: 'FA100' }"
              class="btn btn-secondary btn-lg mr-3 mb-3"
              role="button"
            >
              災害名称入力
            </router-link>
            <button
              class="btn btn-secondary btn-lg mr-3 mb-3"
              type="button"
              @click="gofa104()"
            >
              気象情報入力
            </button>
            <button
              class="btn btn-secondary btn-lg mr-3 mb-3"
              role="button"
              @click="gofa101()"
            >
              被害報告入力
            </button>
            <router-link
              :to="{ name: 'FA103' }"
              class="btn btn-secondary btn-lg mr-3 mb-3"
              role="button"
              >災害報告集計</router-link
            >
          </template>
          <template v-else>
            <button
              class="btn btn-secondary btn-lg mr-3 mb-3"
              role="button"
              @click="gofa101()"
            >
              被害報告入力
            </button>
          </template>
        </div>
      </div>
      <template v-if="authorityGyousha()"></template>
      <template v-else>
        <div class="container mb-3 pt-3 border-top border-secondary">
          <h4 class="text-primary">【帳票出力機能】</h4>
          <div class="d-flex flex-row flex-wrap justify-content-center justify-content-sm-start">
            <router-link :to="{ name: 'FA114' }" class="btn btn-secondary btn-lg mr-3 mb-3" role="button">帳票出力</router-link>
          </div>
        </div>
      </template>
    </main>
  </AppLayout>
</template>
