<script>
import AppLayout from '@/components/layouts/Layout.vue'

export default {
  name: 'honcyousyorimenu',
  components: {
    AppLayout
  }
}
</script>
<template>
  <AppLayout>
    <main class="mb-3">  
      <div class="container">
        <p>画面を操作する権限がありません。</p>
      </div>    
    </main>
  </AppLayout>
</template>