<script>
import AppLayout from "@/components/layouts/Layout.vue";

export default {
  name: "sagaisateimenu",
  components: {
    AppLayout,
  },
  data() {
    return {
      isTest:
        window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
    };
  },
  methods: {
    gofc100() {
      this.$store.commit("fc100/setYear", "");
      this.$store.commit("fc100/setNjiSatei", "");
      this.$store.commit("fc100/setKanrisyaCd", "");
      this.$store.commit("fc100/setKanrisyaMei", "");
      this.$store.commit("fc100/setPage", "");
      this.$router.push({
        name: "FC100",
      });
    },
  },
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div
        class="container-fluid bg-info mb-3 pt-2 pb-2"
        :class="this.isTest ? 'test-bg-info' : ''"
      >
        <div class="container">
          <h3 class="text-white mb-0">災害査定管理機能</h3>
        </div>
      </div>
      <div class="container mb-3">
        <h4 class="text-primary">【登録機能】</h4>
        <div class="d-flex flex-row flex-wrap">
          <button
            class="btn btn-secondary btn-lg mb-3"
            role="button"
            @click="gofc100()"
          >
            災害査定入力
          </button>
        </div>
      </div>
      <div class="container mb-3 pt-3 border-top border-secondary">
        <h4 class="text-primary">【帳票出力機能】</h4>
        <div
          class="d-flex flex-row flex-wrap"
          style="width: 100%; max-width: 1100px"
        >
          <router-link
            :to="{ name: 'FC105' }"
            class="btn btn-secondary btn-lg mb-3"
            role="button"
          >
            帳票出力</router-link
          >
        </div>
      </div>
    </main>
  </AppLayout>
</template>
