<script>
import AppLayout from "@/components/layouts/Layout.vue";

export default {
  name: "kokofutanMenu",
  components: {
    AppLayout,
  },
  data() {
    return {
      isTest:
        window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
    };
  },
  methods: {
    gofb100() {
      this.$store.commit("fb100/setChanges", "");
      this.$store.commit("fb100/setPreviousItems", "");
      this.$store.commit("fb100/setYear", "");
      this.$store.commit("fb100/setNjiSatei", "");
      this.$store.commit("fb100/setKanrisyaCd", "");
      this.$store.commit("fb100/setStatus", "");
      this.$store.commit("fb100/setPageLimit", "");
      this.$store.commit("fb100/setPage", "");
      this.$router.push({
        name: "FB100",
      });
    },
    authorityAdmin() {
      var kengen_cd = localStorage.getItem("kengen_cd");
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div
        class="container-fluid bg-info mb-3 pt-2 pb-2"
        :class="this.isTest ? 'test-bg-info' : ''"
      >
        <div class="container">
          <h3 class="text-white mb-0">国庫負担申請管理機能</h3>
        </div>
      </div>
      <div class="container mb-3">
        <h4 class="text-primary">【登録機能】</h4>
        <div class="d-flex flex-row flex-wrap">
          <template v-if="authorityAdmin()"
            ><router-link
              :to="{ name: 'FB106' }"
              class="btn btn-secondary btn-lg mr-3 mb-3"
              role="button"
              >工事番号振分</router-link
            ></template
          >
          <button
            class="btn btn-secondary btn-lg mr-3 mb-3"
            role="button"
            @click="gofb100()"
          >
            国庫負担申請入力
          </button>
        </div>
      </div>
      <div class="container mb-3 pt-3 border-top border-secondary">
        <h4 class="text-primary">【帳票出力機能】</h4>
        <div
          class="d-flex flex-row flex-wrap"
          style="width: 100%; max-width: 800px"
        >
          <router-link
            :to="{ name: 'FB108' }"
            class="btn btn-secondary btn-lg mr-3 mb-3"
            role="button"
          >
            帳票出力
          </router-link>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
