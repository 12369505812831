<script>
import AppLayout from "@/components/layouts/Layout.vue";
import datepicker from "vuejs-datepicker";
import moment from "moment";
import { ja } from "vuejs-datepicker/dist/locale";
import { APIService } from "@/apiService.js";

const apiService = new APIService();
var numeral = require("numeral");

export default {
  name: "fa111",
  components: {
    AppLayout,
    datepicker
  },
  data() {
    return {
      max_fusoku: 10,
      max_cyoui: 10,
      max_hakou: 10,
      isYearNotFound: false,
      required: this.$messages.msg["0004W"],
      isHasKenNotFoundErr: false,
      kenErrorMsg: "",
      isRenbanNotFound: false,
      renbanErrMsg: this.$messages.msg["0012W"],
      isNumFormat: false,
      numFormatErr: "",
      year: "",
      jpYear: "",
      engYear: "",
      meisyou: [],
      saigai_ken: "",
      saigai_kens: [],
      saigai_start: "",
      saigai_starts: [],
      saigai_end: "",
      saigai_ends: [],
      saigai_mei: "",
      saigai_meis: [],
      kode_kbn: 1,
      ja: ja,
      renban: "",
      shicyou: [],
      genin: [],
      shicyou_cd: "",
      shicyou_mei: "",
      uryou: "",
      uryoubi_start: "",
      uryoubi_end: "",
      uryouji_start: "",
      uryouji_end: "",
      hiuryou: "",
      hiuryoubi_start: "",
      hiuryoubi_end: "",
      hiuryouji_start: "",
      hiuryoujii_end: "",
      jikanuryou: "",
      jikanuryoubi_start: "",
      jikanuryoubi_end: "",
      jikanuryoujii_start: "",
      jikanuryouji_end: "",
      heikin_fusoku: "",
      heikin_fusokubi: "",
      heikin_fusokuji_start: "",
      heikin_fusokuji_end: "",
      saidai_cyoui: "",
      saidai_cyouibi: "",
      saidai_cyouiji: "",
      saidai_hakou: "",
      saidai_hakoubi: "",
      saidai_hakouji: "",
      sonota: "",
      renbans: [],
      shicyou_cds: [],
      shicyou_meis: [],
      uryous: [],
      uryoubi_starts: [],
      uryoubi_ends: [],
      uryouji_starts: [],
      uryouji_ends: [],
      hiuryous: [],
      hiuryoubi_starts: [],
      hiuryoubi_ends: [],
      hiuryouji_starts: [],
      hiuryoujii_ends: [],
      jikanuryous: [],
      jikanuryoubi_starts: [],
      jikanuryoubi_ends: [],
      jikanuryoujii_starts: [],
      jikanuryouji_ends: [],
      heikin_fusokus: [],
      heikin_fusokubis: [],
      heikin_fusokuji_starts: [],
      heikin_fusokuji_ends: [],
      saidai_cyouis: [],
      saidai_cyouibis: [],
      saidai_cyouijis: [],
      saidai_hakous: [],
      saidai_hakoubis: [],
      saidai_hakoujis: [],
      sonotas: [],
      unique_error: null,
      unique_show: false,
      info: null,
      errors: {
        renban: null,
        shicyou_cd: null,
        shicyou_mei: null,
        uryou: null,
        uryoubi_start: null,
        uryoubi_end: null,
        uryouji_start: null,
        uryouji_end: null,
        hiuryou: null,
        hiuryoubi_start: null,
        hiuryoubi_end: null,
        hiuryouji_start: null,
        hiuryoujii_end: null,
        jikanuryou: null,
        jikanuryoubi_start: null,
        jikanuryoubi_end: null,
        jikanuryoujii_start: null,
        jikanuryouji_end: null,
        heikin_fusoku: null,
        heikin_fusokubi: null,
        heikin_fusokuji_start: null,
        heikin_fusokuji_end: null,
        saidai_cyoui: null,
        saidai_cyouibi: null,
        saidai_cyouiji: null,
        saidai_hakou: null,
        saidai_hakoubi: null,
        saidai_hakouji: null,
        sonota: null
      },
      disableUpDelBtn: true,
      disableInsertBtn: true
    };
  },
  mounted() {
    this.engYear = new Date().getFullYear();
    this.getGengo(this.engYear);
    this.search(this.engYear);
  },
  watch: {
    heikin_fusoku: function(heikin_fusoku) {
      if (heikin_fusoku != null) {
        // console.log(heikin_fusoku.length);
        var fusoku = heikin_fusoku
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (fusoku !== heikin_fusoku) {
          // Only if something had to be fixed, update
          heikin_fusoku = fusoku;
        }
        this.max_fusoku = heikin_fusoku.indexOf(".") > 0 ? 11 : 10;
      }
    },
    saidai_cyoui: function(saidai_cyoui) {
      if (saidai_cyoui != null) {
        var cyoui = saidai_cyoui
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d\d).+/, "$1");
        if (cyoui !== saidai_cyoui) {
          // Only if something had to be fixed, update
          saidai_cyoui = cyoui;
        }
        this.max_cyoui = saidai_cyoui.indexOf(".") > 0 ? 11 : 10;
      }
    },
    saidai_hakou: function(saidai_hakou) {
      if (saidai_hakou != null) {
        var hakou = saidai_hakou
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d\d).+/, "$1");
        if (hakou !== saidai_hakou) {
          // Only if something had to be fixed, update
          saidai_hakou = hakou;
        }
        this.max_hakou = saidai_hakou.indexOf(".") > 0 ? 11 : 10;
      }
    }
  },
  filters: {
    formatDate: function(date) {
      console.log("date" + date);
      if (date != "" && date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
      return "";
    },
    formatNumber: function(number) {
      return numeral(number).format("0,0");
    },
    formatTime: function(value) {
      return value.toString().slice(0, 2) + ":" + value.toString().slice(2);
    }
  },
  methods: {
    search(year) {
      this.clearError();
      var request_data = {
        year: year
      };

      apiService
        .fa100Search(request_data)
        .then(result => {
          this.meisyou = result;
          this.meisyou.unshift({
            saigai_yyyy: null,
            saigai_ken: null,
            saigai_mei: null,
            saigai_start: null,
            saigai_end: null,
            saigai_kuni: null
          });
          if (Object.keys(this.meisyou).length != 0) {
            var saigai_ken_array = [];
            var saigai_start_array = [];
            var saigai_end_array = [];
            var saigai_mei_array = [];
            for (var i in result) {
              saigai_ken_array[i] = result[i].saigai_ken;
              saigai_start_array[i] = result[i].saigai_start;
              saigai_end_array[i] = result[i].saigai_end;
              saigai_mei_array[i] = result[i].saigai_mei;
            }
            this.saigai_ken = saigai_ken_array[saigai_ken_array.length - 1];
            this.saigai_kens = saigai_ken_array;
            this.saigai_start =
              saigai_start_array[saigai_start_array.length - 1];
            this.saigai_start = moment(String(this.saigai_start)).format(
              "YYYY/MM/DD"
            );
            this.saigai_starts = saigai_start_array;
            this.saigai_end = saigai_end_array[saigai_end_array.length - 1];
            this.saigai_end = moment(String(this.saigai_end)).format(
              "YYYY/MM/DD"
            );
            this.saigai_ends = saigai_end_array;
            this.saigai_mei = saigai_mei_array[saigai_mei_array.length - 1];
            this.saigai_meis = saigai_mei_array;
            if (this.saigai_ken == "") {
              this.clearGeninData();
            } else {
              this.getRenban(this.engYear, this.saigai_ken, this.kode_kbn);
              this.getShicyouMei(this.kode_kbn);
            }
          } else {
            this.disableInsertBtn = false;
            this.disableUpDelBtn = true;
            this.clearError();
            this.clearData();
            this.clearGeninData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getRenban(year, saigai_ken, kode_kbn) {
      this.clearError();
      var request_data = {
        year: year,
        saigai_ken: saigai_ken,
        kode_kbn: kode_kbn
      };
      apiService
        .fa111getRenban(request_data)
        .then(result => {
          this.genin = result;
          var renban_array = [];
          for (var i in result) {
            renban_array[i] = result[i].renban;
          }
          this.renban = "";
          this.disableInsertBtn = true;
          this.disableUpDelBtn = true;
          this.renbans = renban_array;
          this.getRenbanData(
            this.engYear,
            this.saigai_ken,
            this.kode_kbn,
            this.renban
          );
        })
        .catch(error => {
          console.log(error);
        });
    },
    getRenbanData(year, saigai_ken, kode_kbn, renban) {
      this.clearError();
      var request_data = {
        year: year,
        saigai_ken: saigai_ken,
        kode_kbn: kode_kbn,
        renban: renban
      };
      apiService
        .fa111getRenbanData(request_data)
        .then(result => {
          this.genin = result;
          if (Object.keys(this.genin).length === 0) {
            this.getShicyouMei(this.kode_kbn);
            this.clearGeninData();
            this.disableUpDelBtn = true;
            this.disableInsertBtn = false;
          } else {
            this.disableUpDelBtn = false;
            this.disableInsertBtn = true;
          }
          var shicyou_cd_array = [];
          var shicyou_mei_array = [];
          var uryou_array = [];
          var uryoubi_start_array = [];
          var uryoubi_end_array = [];
          var uryouji_start_array = [];
          var uryouji_end_array = [];
          var hiuryou_array = [];
          var hiuryoubi_start_array = [];
          var hiuryoubi_end_array = [];
          var hiuryouji_start_array = [];
          var hiuryoujii_end_array = [];
          var jikanuryou_array = [];
          var jikanuryoubi_start_array = [];
          var jikanuryoubi_end_array = [];
          var jikanuryoujii_start_array = [];
          var jikanuryouji_end_array = [];
          var heikin_fusoku_array = [];
          var heikin_fusokubi_array = [];
          var heikin_fusokuji_start_array = [];
          var heikin_fusokuji_end_array = [];
          var saidai_cyoui_array = [];
          var saidai_cyouibi_array = [];
          var saidai_cyouiji_array = [];
          var saidai_hakou_array = [];
          var saidai_hakoubi_array = [];
          var saidai_hakouji_array = [];
          var sonota_array = [];

          for (var i in result) {
            shicyou_cd_array[i] = result[i].shicyou_cd;
            shicyou_mei_array[i] = result[i].shicyou_mei;
            uryou_array[i] = result[i].uryou;
            uryoubi_start_array[i] = result[i].uryoubi_start;
            uryoubi_end_array[i] = result[i].uryoubi_end;
            uryouji_start_array[i] = result[i].uryouji_start;
            uryouji_end_array[i] = result[i].uryouji_end;
            hiuryou_array[i] = result[i].hiuryou;
            hiuryoubi_start_array[i] = result[i].hiuryoubi_start;
            hiuryoubi_end_array[i] = result[i].hiuryoubi_end;
            hiuryouji_start_array[i] = result[i].hiuryouji_start;
            hiuryoujii_end_array[i] = result[i].hiuryoujii_end;
            jikanuryou_array[i] = result[i].jikanuryou;
            jikanuryoubi_start_array[i] = result[i].jikanuryoubi_start;
            jikanuryoubi_end_array[i] = result[i].jikanuryoubi_end;
            jikanuryoujii_start_array[i] = result[i].jikanuryoujii_start;
            jikanuryouji_end_array[i] = result[i].jikanuryouji_end;
            heikin_fusoku_array[i] = result[i].heikin_fusoku;
            heikin_fusokubi_array[i] = result[i].heikin_fusokubi;
            heikin_fusokuji_start_array[i] = result[i].heikin_fusokuji_start;
            heikin_fusokuji_end_array[i] = result[i].heikin_fusokuji_end;
            saidai_cyoui_array[i] = result[i].saidai_cyoui;
            saidai_cyouibi_array[i] = result[i].saidai_cyouibi;
            saidai_cyouiji_array[i] = result[i].saidai_cyouiji;
            saidai_hakou_array[i] = result[i].saidai_hakou;
            saidai_hakoubi_array[i] = result[i].saidai_hakoubi;
            saidai_hakouji_array[i] = result[i].saidai_hakouji;
            sonota_array[i] = result[i].sonota;

            this.shicyou_cd = shicyou_cd_array[shicyou_cd_array.length - 1];
            this.shicyou_mei = shicyou_mei_array[shicyou_mei_array.length - 1];
            this.uryou = uryou_array[uryou_array.length - 1];
            this.uryous = uryou_array;
            this.uryou = this.formatNumber(this.uryou);
            this.uryoubi_start =
              uryoubi_start_array[uryoubi_start_array.length - 1];
            this.uryoubi_start = this.formatDate(this.uryoubi_start);
            this.uryoubi_starts = uryoubi_start_array;
            this.uryoubi_end = uryoubi_end_array[uryoubi_end_array.length - 1];
            this.uryoubi_end = this.formatDate(this.uryoubi_end);
            this.uryoubi_ends = uryoubi_end_array;
            this.uryouji_start =
              uryouji_start_array[uryouji_start_array.length - 1];
            this.uryouji_starts = uryouji_start_array;
            this.uryouji_end = uryouji_end_array[uryouji_end_array.length - 1];
            this.uryouji_ends = uryouji_end_array;
            this.hiuryou = hiuryou_array[hiuryou_array.length - 1];
            this.hiuryou = this.formatNumber(this.hiuryou);
            this.hiuryous = hiuryou_array;
            this.hiuryoubi_start =
              hiuryoubi_start_array[hiuryoubi_start_array.length - 1];
            this.hiuryoubi_start = this.formatDate(this.hiuryoubi_start);
            this.hiuryoubi_starts = hiuryoubi_start_array;
            this.hiuryoubi_end =
              hiuryoubi_end_array[hiuryoubi_end_array.length - 1];
            this.hiuryoubi_end = this.formatDate(this.hiuryoubi_end);
            this.hiuryoubi_ends = hiuryoubi_end_array;
            this.hiuryouji_start =
              hiuryouji_start_array[hiuryouji_start_array.length - 1];
            this.hiuryouji_starts = hiuryouji_start_array;
            this.hiuryoujii_end =
              hiuryoujii_end_array[hiuryoujii_end_array.length - 1];
            this.hiuryoujii_ends = hiuryoujii_end_array;
            this.jikanuryou = jikanuryou_array[jikanuryou_array.length - 1];
            this.jikanuryou = this.formatNumber(this.jikanuryou);
            this.jikanuryous = jikanuryou_array;
            this.jikanuryoubi_start =
              jikanuryoubi_start_array[jikanuryoubi_start_array.length - 1];
            this.jikanuryoubi_start = this.formatDate(this.jikanuryoubi_start);
            this.jikanuryoubi_starts = jikanuryoubi_start_array;
            this.jikanuryoubi_end =
              jikanuryoubi_end_array[jikanuryoubi_end_array.length - 1];
            this.jikanuryoubi_end = this.formatDate(this.jikanuryoubi_end);
            this.jikanuryoubi_ends = jikanuryoubi_end_array;
            this.jikanuryoujii_start =
              jikanuryoujii_start_array[jikanuryoujii_start_array.length - 1];
            this.jikanuryoujii_starts = jikanuryoujii_start_array;
            this.jikanuryouji_end =
              jikanuryouji_end_array[jikanuryouji_end_array.length - 1];
            this.jikanuryouji_ends = jikanuryouji_end_array;
            this.heikin_fusoku =
              heikin_fusoku_array[heikin_fusoku_array.length - 1];
            this.heikin_fusoku = this.formatDecimal(this.heikin_fusoku);
            this.heikin_fusokus = heikin_fusoku_array;
            this.heikin_fusokubi =
              heikin_fusokubi_array[heikin_fusokubi_array.length - 1];
            this.heikin_fusokubi = this.formatDate(this.heikin_fusokubi);
            this.heikin_fusokubis = heikin_fusokubi_array;
            this.heikin_fusokuji_start =
              heikin_fusokuji_start_array[
                heikin_fusokuji_start_array.length - 1
              ];
            this.heikin_fusokuji_start = this.formatTime(
              this.heikin_fusokuji_start
            );
            this.heikin_fusokuji_starts = heikin_fusokuji_start_array;
            this.heikin_fusokuji_end =
              heikin_fusokuji_end_array[heikin_fusokuji_end_array.length - 1];
            this.heikin_fusokuji_end = this.formatTime(
              this.heikin_fusokuji_end
            );
            this.heikin_fusokuji_ends = heikin_fusokuji_end_array;
            this.saidai_cyoui =
              saidai_cyoui_array[saidai_cyoui_array.length - 1];
            this.saidai_cyoui = this.formatDecimal(this.saidai_cyoui);
            this.saidai_cyouis = saidai_cyoui_array;
            this.saidai_cyouibi =
              saidai_cyouibi_array[saidai_cyouibi_array.length - 1];
            this.saidai_cyouibi = this.formatDate(this.saidai_cyouibi);
            this.saidai_cyouibis = saidai_cyouibi_array;
            this.saidai_cyouiji =
              saidai_cyouiji_array[saidai_cyouiji_array.length - 1];
            this.saidai_cyouiji = this.formatTime(this.saidai_cyouiji);
            this.saidai_cyouijis = saidai_cyouiji_array;
            this.saidai_hakou =
              saidai_hakou_array[saidai_hakou_array.length - 1];
            this.saidai_hakou = this.formatDecimal(this.saidai_hakou);
            this.saidai_hakous = saidai_hakou_array;
            this.saidai_hakoubi =
              saidai_hakoubi_array[saidai_hakoubi_array.length - 1];
            this.saidai_hakoubi = this.formatDate(this.saidai_hakoubi);
            this.saidai_hakoubis = saidai_hakoubi_array;
            this.saidai_hakouji =
              saidai_hakouji_array[saidai_hakouji_array.length - 1];
            this.saidai_hakouji = this.formatTime(this.saidai_hakouji);
            this.saidai_hakoujis = saidai_hakouji_array;
            this.sonota = sonota_array[sonota_array.length - 1];
            this.sonotas = sonota_array;
            this.fusokujiStartChange(this.heikin_fusokuji_start);
            this.fusokujiEndChange(this.heikin_fusokuji_end);
            this.saidaiCyouiji(this.saidai_cyouiji);
            this.saidaiHakouji(this.saidai_hakouji);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getShicyouMei(kode_kbn) {
      this.clearError();
      var request_data = {
        kode_kbn: kode_kbn
      };
      apiService
        .fa111getShicyouMei(request_data)
        .then(result => {
          this.shicyou = result;
          var shicyou_cd_array = [];
          var shicyou_mei_array = [];

          for (var i in result) {
            shicyou_cd_array[i] = result[i].shicyou_cd;
            shicyou_mei_array[i] = result[i].shicyou_mei;
            this.shicyou_cd = this.shicyou_cd;
            this.shicyou_cds = shicyou_cd_array;
            this.shicyou_mei = this.shicyou_mei;
            this.shicyou_meis = shicyou_mei_array;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    focusShicyouCd(shicyou, shicyou_cd) {
      this.clearError();
      var flag = true;
      for (var i in shicyou) {
        if (shicyou_cd == shicyou[i].shicyou_cd) {
          this.shicyou_cd = shicyou[i].shicyou_cd;
          this.shicyou_mei = shicyou[i].shicyou_mei;
          flag = false;
        }
        if (flag) {
          this.shicyou_mei = "";
        }
      }
    },
    changeShicyouCd(shicyou_cd, shicyou_cds, shicyou_meis) {
      this.clearError();
      var index = shicyou_cds.indexOf(shicyou_cd);
      this.shicyou_cd = shicyou_cds[index];
      this.shicyou_mei = shicyou_meis[index];
    },
    formatDate: function(date) {
      console.log("date" + date);
      if (date != "" && date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
      return "";
    },
    formatNumber: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0") == 0
        ? ""
        : numeral(number).format("0,0");
    },
    formatDecimal: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
    formatTime: function(value) {
      if (value == null) {
        return value;
      } else if (value.toString().length == 1) {
        return "00:0" + value.toString();
      } else if (value.toString().length == 2) {
        return "00:" + value.toString();
      } else if (value.toString().length == 3) {
        return (
          "0" +
          value.toString().slice(0, 1) +
          ":" +
          value.toString().slice(1, 3)
        );
      } else {
        return value.toString().slice(0, 2) + ":" + value.toString().slice(2);
      }
    },
    removeCommaFusoku() {
      if (this.heikin_fusoku != null && this.heikin_fusoku != "") {
        this.heikin_fusoku = this.heikin_fusoku.replace(/,/g, "");
      }
    },
    removeCommaCyoui() {
      if (this.saidai_cyoui != null && this.saidai_cyoui != "") {
        this.saidai_cyoui = this.saidai_cyoui.replace(/,/g, "");
      }
    },
    removeCommaHakou() {
      if (this.saidai_hakou != null && this.saidai_hakou != "") {
        this.saidai_hakou = this.saidai_hakou.replace(/,/g, "");
      }
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
      });
    },
    changedYear() {
      this.info = null;
      this.kode_kbn = 1;
      this.renban = "";
      if (this.jpYear != "" && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace(/[^\d.]/g, "");
        var request_data = {
          gengo_cd: matches ? matches.toString() : ""
        };
        apiService
          .getGengoStartDate(request_data)
          .then(result => {
            if(result.length > 0 ) {
              var startDate = result[0].start_date;
              this.engYear =
                parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);
              this.search(this.engYear);
            } else {
              this.disableInsertBtn = false;
              this.disableUpDelBtn = true;
              this.engYear = "";
              this.clearError();
              this.clearData();
              this.clearGeninData();
            }
          })
          .catch(() => {
            this.clearError();
            this.clearData();
            this.clearGeninData();
          });
      } else {
        this.disableInsertBtn = false;
        this.disableUpDelBtn = true;
        this.engYear = "";
        this.clearError();
        this.clearData();
        this.clearGeninData();
      }
      this.isYearNotFound = false;
      this.isHasKenNotFoundErr = false;
      this.isRenbanNotFound = false;
      this.isNumFormat = false;
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    clearData() {
      this.meisyou = [];
      this.saigai_ken = "";
      this.saigai_kens = [];
      this.saigai_end = "";
      this.saigai_start = "";
      this.saigai_mei = "";
    },
    clearGeninData() {
      (this.shicyou_cd = ""),
        (this.shicyou_mei = ""),
        (this.uryou = ""),
        (this.uryoubi_start = ""),
        (this.uryoubi_end = ""),
        (this.uryouji_start = ""),
        (this.uryouji_end = ""),
        (this.hiuryou = ""),
        (this.hiuryoubi_start = ""),
        (this.hiuryoubi_end = ""),
        (this.hiuryouji_start = ""),
        (this.hiuryoujii_end = ""),
        (this.jikanuryou = ""),
        (this.jikanuryoubi_start = ""),
        (this.jikanuryoubi_end = ""),
        (this.jikanuryoujii_start = ""),
        (this.jikanuryouji_end = ""),
        (this.heikin_fusoku = ""),
        (this.heikin_fusokubi = ""),
        (this.heikin_fusokuji_start = ""),
        (this.heikin_fusokuji_end = ""),
        (this.saidai_cyoui = ""),
        (this.saidai_cyouibi = ""),
        (this.saidai_cyouiji = ""),
        (this.saidai_hakou = ""),
        (this.saidai_hakoubi = ""),
        (this.saidai_hakouji = ""),
        (this.sonota = "");
    },
    kenChange(meisyou, saigai_ken) {
      this.clearError();
      this.info = null;
      var flag = true;
      for (var i in meisyou) {
        if (saigai_ken == meisyou[i].saigai_ken) {
          this.saigai_ken = meisyou[i].saigai_ken;
          this.saigai_start = meisyou[i].saigai_start;
          this.saigai_start = this.formatDate(this.saigai_start);
          this.saigai_end = meisyou[i].saigai_end;
          this.saigai_end = this.formatDate(this.saigai_end);
          this.saigai_mei = meisyou[i].saigai_mei;
          this.getRenban(this.engYear, this.saigai_ken, this.kode_kbn);
          flag = false;
        }
        if (flag) {
          this.saigai_start = "";
          this.saigai_end = "";
          this.saigai_mei = "";
          this.clearGeninData();
        }
      }
      this.isYearNotFound = false;
      this.isHasKenNotFoundErr = false;
      this.isRenbanNotFound = false;
      this.isNumFormat = false;
    },
    renbanFocus(genin, renban) {
      this.info = null;
      this.clearError();
      this.isYearNotFound = false;
      this.isHasKenNotFoundErr = false;
      this.isRenbanNotFound = false;
      this.isNumFormat = false;
      this.getRenbanData(this.engYear, this.saigai_ken, this.kode_kbn, renban);
    },
    updateRadioClicked() {
      this.renban = "";
      this.disableInsertBtn = false;
      this.disableUpDelBtn = true;
      this.clearError();
      this.clearGeninData();
    },
    fusokujiStartChange(heikin_fusokuji_start) {
      if (
        (heikin_fusokuji_start != null) &
        heikin_fusokuji_start.includes(":")
      ) {
        var str = heikin_fusokuji_start.trim().split(":");
        console.log("string" + str);
        if (str.length > 1 && str[0].length < 3 && str[1].length < 3) {
          var str0 = "";
          var str1 = "";
          if (str[0].length == 0) {
            str0 = "00";
          } else if (str[0].length == 1) {
            str0 = "0" + str[0];
          } else {
            str0 = str[0];
          }
          if (str[1].length == 0) {
            str1 = "00";
          } else if (str[1].length == 1) {
            str1 = "0" + str[1];
          } else {
            str1 = str[1];
          }
          this.heikin_fusokuji_start = str0 + ":" + str1;
        }
      }
    },
    fusokujiEndChange(heikin_fusokuji_end) {
      if (heikin_fusokuji_end != null && heikin_fusokuji_end.includes(":")) {
        var str = heikin_fusokuji_end.trim().split(":");
        if (str.length > 1 && str[0].length < 3 && str[1].length < 3) {
          var str0 = "";
          var str1 = "";
          if (str[0].length == 0) {
            str0 = "00";
          } else if (str[0].length == 1) {
            str0 = "0" + str[0];
          } else {
            str0 = str[0];
          }
          if (str[1].length == 0) {
            str1 = "00";
          } else if (str[1].length == 1) {
            str1 = "0" + str[1];
          } else {
            str1 = str[1];
          }
          this.heikin_fusokuji_end = str0 + ":" + str1;
        }
      }
    },
    saidaiCyouiji(saidai_cyouiji) {
      if (saidai_cyouiji != null && saidai_cyouiji.includes(":")) {
        var str = saidai_cyouiji.trim().split(":");
        if (str.length > 1 && str[0].length < 3 && str[1].length < 3) {
          var str0 = "";
          var str1 = "";
          if (str[0].length == 0) {
            str0 = "00";
          } else if (str[0].length == 1) {
            str0 = "0" + str[0];
          } else {
            str0 = str[0];
          }
          if (str[1].length == 0) {
            str1 = "00";
          } else if (str[1].length == 1) {
            str1 = "0" + str[1];
          } else {
            str1 = str[1];
          }
          this.saidai_cyouiji = str0 + ":" + str1;
        }
      }
    },
    saidaiHakouji(saidai_hakouji) {
      if (saidai_hakouji != null && saidai_hakouji.includes(":")) {
        var str = saidai_hakouji.trim().split(":");
        if (str.length > 1 && str[0].length < 3 && str[1].length < 3) {
          var str0 = "";
          var str1 = "";
          if (str[0].length == 0) {
            str0 = "00";
          } else if (str[0].length == 1) {
            str0 = "0" + str[0];
          } else {
            str0 = str[0];
          }
          if (str[1].length == 0) {
            str1 = "00";
          } else if (str[1].length == 1) {
            str1 = "0" + str[1];
          } else {
            str1 = str[1];
          }
          this.saidai_hakouji = str0 + ":" + str1;
        }
      }
    },
    uryouChange() {
      this.uryou = this.replaceComma(this.uryou);
      if (this.isInteger(this.uryou)) {
        this.uryou = this.uryou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    hiuryouChange() {
      this.hiuryou = this.replaceComma(this.hiuryou);
      if (this.isInteger(this.hiuryou)) {
        this.hiuryou = this.hiuryou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    jikanuryouChange() {
      this.jikanuryou = this.replaceComma(this.jikanuryou);
      if (this.isInteger(this.jikanuryou)) {
        this.jikanuryou = this.jikanuryou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    heikinFusokuChange() {
      this.heikin_fusoku = this.replaceComma(this.heikin_fusoku);
      if (this.isFloat(this.heikin_fusoku)) {
        this.heikin_fusoku = this.heikin_fusoku
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saidaiCyouiChange() {
      this.saidai_cyoui = this.replaceComma(this.saidai_cyoui);
      if (this.isFloat(this.saidai_cyoui)) {
        this.saidai_cyoui = this.saidai_cyoui
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saidaiHakouChange() {
      this.saidai_hakou = this.replaceComma(this.saidai_hakou);
      if (this.isFloat(this.saidai_hakou)) {
        this.saidai_hakou = this.saidai_hakou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    insert_btn() {
      if (this.jpYear == null || this.jpYear.trim() == "") {
        this.isYearNotFound = true;
      } else {
        this.isYearNotFound = false;
      }
      if (this.saigai_ken == null || this.saigai_ken == "") {
        this.isHasKenNotFoundErr = true;
        this.kenErrorMsg = this.$messages.msg["0005W"];
      } else {
        this.kenErrorMsg = this.$messages.msg["0005W"];
        this.isHasKenNotFoundErr = false;
      }
      if (this.renban == null || this.renban.trim() == "") {
        this.isRenbanNotFound = true;
      } else {
        this.isRenbanNotFound = false;
      }
      this.FormatCheck();
      if (
        !this.isYearNotFound &&
        !this.isHasKenNotFoundErr &&
        !this.isRenbanNotFound &&
        !this.isNumFormat
      ) {
        this.clearError();
        this.info = null;
        var request_data = {
          year: this.engYear,
          saigai_ken: this.saigai_ken,
          kode_kbn: this.kode_kbn,
          renban: this.renban,
          shicyou_cd: this.shicyou_cd,
          uryou: this.replaceComma(this.uryou),
          uryoubi_start: this.formatDate(this.uryoubi_start),
          uryoubi_end: this.formatDate(this.uryoubi_end),
          uryouji_start: this.uryouji_start,
          uryouji_end: this.uryouji_end,
          hiuryou: this.replaceComma(this.hiuryou),
          hiuryoubi_start: this.formatDate(this.hiuryoubi_start),
          hiuryoubi_end: this.formatDate(this.hiuryoubi_end),
          hiuryouji_start: this.hiuryouji_start,
          hiuryoujii_end: this.hiuryoujii_end,
          jikanuryou: this.replaceComma(this.jikanuryou),
          jikanuryoubi_start: this.formatDate(this.jikanuryoubi_start),
          jikanuryoubi_end: this.formatDate(this.jikanuryoubi_end),
          jikanuryoujii_start: this.jikanuryoujii_start,
          jikanuryouji_end: this.jikanuryouji_end,
          heikin_fusoku: this.replaceComma(this.heikin_fusoku),
          heikin_fusokubi: this.heikin_fusokubi,
          heikin_fusokuji_start: this.heikin_fusokuji_start,
          heikin_fusokuji_end: this.heikin_fusokuji_end,
          saidai_cyoui: this.replaceComma(this.saidai_cyoui),
          saidai_cyouibi: this.saidai_cyouibi,
          saidai_cyouiji: this.saidai_cyouiji,
          saidai_hakou: this.replaceComma(this.saidai_hakou),
          saidai_hakoubi: this.saidai_hakoubi,
          saidai_hakouji: this.saidai_hakouji,
          sonota: this.sonota,
          login_id: localStorage.getItem("LOGIN_ID")
        };
        apiService
          .fa111Insert(request_data)
          .then(result => {
            this.info = result;
            this.disableInsertBtn = true;
            this.disableUpDelBtn = false;
          })
          .catch(error => {
            this.disableInsertBtn = false;
            this.disableUpDelBtn = true;
            if (error.errors) {
              this.unique_show = false;
              this.info = null;
              this.unique_error = error;
              this.errors.renban = error.errors.renban
                ? error.errors.renban[0]
                : "";
              this.errors.shicyou_cd = error.errors.shicyou_cd
                ? error.errors.shicyou_cd[0]
                : "";
              this.errors.uryou = error.errors.uryou
                ? error.errors.uryou[0]
                : "";
              this.errors.uryoubi_start = error.errors.uryoubi_start
                ? error.errors.uryoubi_start[0]
                : "";
              this.errors.uryoubi_end = error.errors.uryoubi_end
                ? error.errors.uryoubi_end[0]
                : "";
              this.errors.uryouji_start = error.errors.uryouji_start
                ? error.errors.uryouji_start[0]
                : "";
              this.errors.uryouji_end = error.errors.uryouji_end
                ? error.errors.uryouji_end[0]
                : "";
              this.errors.hiuryou = error.errors.hiuryou
                ? error.errors.hiuryou[0]
                : "";
              this.errors.hiuryoubi_start = error.errors.hiuryoubi_start
                ? error.errors.hiuryoubi_start[0]
                : "";
              this.errors.hiuryoubi_end = error.errors.hiuryoubi_end
                ? error.errors.hiuryoubi_end[0]
                : "";
              this.errors.hiuryouji_start = error.errors.hiuryouji_start
                ? error.errors.hiuryouji_start[0]
                : "";
              this.errors.hiuryoujii_end = error.errors.hiuryoujii_end
                ? error.errors.hiuryoujii_end[0]
                : "";
              this.errors.jikanuryou = error.errors.jikanuryou
                ? error.errors.jikanuryou[0]
                : "";
              this.errors.jikanuryoubi_start = error.errors.jikanuryoubi_start
                ? error.errors.jikanuryoubi_start[0]
                : "";
              this.errors.jikanuryoubi_end = error.errors.jikanuryoubi_end
                ? error.errors.jikanuryoubi_end[0]
                : "";
              this.errors.jikanuryoujii_start = error.errors.jikanuryoujii_start
                ? error.errors.jikanuryoujii_start[0]
                : "";
              this.errors.jikanuryouji_end = error.errors.jikanuryouji_end
                ? error.errors.jikanuryouji_end[0]
                : "";
              this.errors.heikin_fusoku = error.errors.heikin_fusoku
                ? error.errors.heikin_fusoku[0]
                : "";
              this.errors.heikin_fusokubi = error.errors.heikin_fusokubi
                ? error.errors.heikin_fusokubi[0]
                : "";
              this.errors.heikin_fusokuji_start = error.errors
                .heikin_fusokuji_start
                ? error.errors.heikin_fusokuji_start[0]
                : "";
              this.errors.heikin_fusokuji_end = error.errors.heikin_fusokuji_end
                ? error.errors.heikin_fusokuji_end[0]
                : "";
              this.errors.saidai_cyoui = error.errors.saidai_cyoui
                ? error.errors.saidai_cyoui[0]
                : "";
              this.errors.saidai_cyouibi = error.errors.saidai_cyouibi
                ? error.errors.saidai_cyouibi[0]
                : "";
              this.errors.saidai_cyouiji = error.errors.saidai_cyouiji
                ? error.errors.saidai_cyouiji[0]
                : "";
              this.errors.saidai_hakou = error.errors.saidai_hakou
                ? error.errors.saidai_hakou[0]
                : "";
              this.errors.saidai_hakoubi = error.errors.saidai_hakoubi
                ? error.errors.saidai_hakoubi[0]
                : "";
              this.errors.saidai_hakouji = error.errors.saidai_hakouji
                ? error.errors.saidai_hakouji[0]
                : "";
              this.errors.sonota = error.errors.sonota
                ? error.errors.sonota[0]
                : "";
            } else {
              this.unique_show = true;
              this.info = null;
            }
            this.unique_error = error;
            this.info = null;
            var request_data = {
              kode_kbn: this.kode_kbn
            };
            apiService
              .fa111getShicyouMei(request_data)
              .then(result => {
                this.shicyou = result;
                var shicyou_cd_array = [];
                var shicyou_mei_array = [];

                for (var i in result) {
                  shicyou_cd_array[i] = result[i].shicyou_cd;
                  shicyou_mei_array[i] = result[i].shicyou_mei;
                  this.shicyou_cd = this.shicyou_cd;
                  this.shicyou_cds = shicyou_cd_array;
                  this.shicyou_mei = this.shicyou_mei;
                  this.shicyou_meis = shicyou_mei_array;
                }
              })
              .catch(error => {
                console.log(error);
              });
          });
      }
    },
    update_btn() {
      this.FormatCheck();
      if (!this.isNumFormat) {
        this.clearError();
        this.info = null;
        var request_data = {
          year: this.engYear,
          saigai_ken: this.saigai_ken,
          kode_kbn: this.kode_kbn,
          renban: this.renban,
          shicyou_cd: this.shicyou_cd,
          uryou: this.replaceComma(this.uryou),
          uryoubi_start: this.formatDate(this.uryoubi_start),
          uryoubi_end: this.formatDate(this.uryoubi_end),
          uryouji_start: this.uryouji_start,
          uryouji_end: this.uryouji_end,
          hiuryou: this.replaceComma(this.hiuryou),
          hiuryoubi_start: this.formatDate(this.hiuryoubi_start),
          hiuryoubi_end: this.formatDate(this.hiuryoubi_end),
          hiuryouji_start: this.hiuryouji_start,
          hiuryoujii_end: this.hiuryoujii_end,
          jikanuryou: this.replaceComma(this.jikanuryou),
          jikanuryoubi_start: this.formatDate(this.jikanuryoubi_start),
          jikanuryoubi_end: this.formatDate(this.jikanuryoubi_end),
          jikanuryoujii_start: this.jikanuryoujii_start,
          jikanuryouji_end: this.jikanuryouji_end,
          heikin_fusoku: this.replaceComma(this.heikin_fusoku),
          heikin_fusokubi: this.heikin_fusokubi,
          heikin_fusokuji_start: this.heikin_fusokuji_start,
          heikin_fusokuji_end: this.heikin_fusokuji_end,
          saidai_cyoui: this.replaceComma(this.saidai_cyoui),
          saidai_cyouibi: this.saidai_cyouibi,
          saidai_cyouiji: this.saidai_cyouiji,
          saidai_hakou: this.replaceComma(this.saidai_hakou),
          saidai_hakoubi: this.saidai_hakoubi,
          saidai_hakouji: this.saidai_hakouji,
          sonota: this.sonota,
          login_id: localStorage.getItem("LOGIN_ID")
        };
        apiService
          .fa111Update(request_data)
          .then(result => {
            this.info = result;
            this.getRenbanData(
              this.engYear,
              this.saigai_ken,
              this.kode_kbn,
              this.renban
            );
          })
          .catch(error => {
            console.log(error);
            if (error.errors) {
              this.unique_show = false;
              this.unique_error = error;
              this.errors.renban = error.errors.renban
                ? error.errors.renban[0]
                : "";
              this.errors.shicyou_cd = error.errors.shicyou_cd
                ? error.errors.shicyou_cd[0]
                : "";
              this.errors.uryou = error.errors.uryou
                ? error.errors.uryou[0]
                : "";
              this.errors.uryoubi_start = error.errors.uryoubi_start
                ? error.errors.uryoubi_start[0]
                : "";
              this.errors.uryoubi_end = error.errors.uryoubi_end
                ? error.errors.uryoubi_end[0]
                : "";
              this.errors.uryouji_start = error.errors.uryouji_start
                ? error.errors.uryouji_start[0]
                : "";
              this.errors.uryouji_end = error.errors.uryouji_end
                ? error.errors.uryouji_end[0]
                : "";
              this.errors.hiuryou = error.errors.hiuryou
                ? error.errors.hiuryou[0]
                : "";
              this.errors.hiuryoubi_start = error.errors.hiuryoubi_start
                ? error.errors.hiuryoubi_start[0]
                : "";
              this.errors.hiuryoubi_end = error.errors.hiuryoubi_end
                ? error.errors.hiuryoubi_end[0]
                : "";
              this.errors.hiuryouji_start = error.errors.hiuryouji_start
                ? error.errors.hiuryouji_start[0]
                : "";
              this.errors.hiuryoujii_end = error.errors.hiuryoujii_end
                ? error.errors.hiuryoujii_end[0]
                : "";
              this.errors.jikanuryou = error.errors.jikanuryou
                ? error.errors.jikanuryou[0]
                : "";
              this.errors.jikanuryoubi_start = error.errors.jikanuryoubi_start
                ? error.errors.jikanuryoubi_start[0]
                : "";
              this.errors.jikanuryoubi_end = error.errors.jikanuryoubi_end
                ? error.errors.jikanuryoubi_end[0]
                : "";
              this.errors.jikanuryoujii_start = error.errors.jikanuryoujii_start
                ? error.errors.jikanuryoujii_start[0]
                : "";
              this.errors.jikanuryouji_end = error.errors.jikanuryouji_end
                ? error.errors.jikanuryouji_end[0]
                : "";
              this.errors.heikin_fusoku = error.errors.heikin_fusoku
                ? error.errors.heikin_fusoku[0]
                : "";
              this.errors.heikin_fusokubi = error.errors.heikin_fusokubi
                ? error.errors.heikin_fusokubi[0]
                : "";
              this.errors.heikin_fusokuji_start = error.errors
                .heikin_fusokuji_start
                ? error.errors.heikin_fusokuji_start[0]
                : "";
              this.errors.heikin_fusokuji_end = error.errors.heikin_fusokuji_end
                ? error.errors.heikin_fusokuji_end[0]
                : "";
              this.errors.saidai_cyoui = error.errors.saidai_cyoui
                ? error.errors.saidai_cyoui[0]
                : "";
              this.errors.saidai_cyouibi = error.errors.saidai_cyouibi
                ? error.errors.saidai_cyouibi[0]
                : "";
              this.errors.saidai_cyouiji = error.errors.saidai_cyouiji
                ? error.errors.saidai_cyouiji[0]
                : "";
              this.errors.saidai_hakou = error.errors.saidai_hakou
                ? error.errors.saidai_hakou[0]
                : "";
              this.errors.saidai_hakoubi = error.errors.saidai_hakoubi
                ? error.errors.saidai_hakoubi[0]
                : "";
              this.errors.saidai_hakouji = error.errors.saidai_hakouji
                ? error.errors.saidai_hakouji[0]
                : "";
              this.errors.sonota = error.errors.sonota
                ? error.errors.sonota[0]
                : "";
              this.info = null;
            } else {
              this.unique_show = true;
              this.info = null;
            }
            this.unique_error = error;
            var request_data = {
              kode_kbn: this.kode_kbn
            };
            apiService
              .fa111getShicyouMei(request_data)
              .then(result => {
                this.shicyou = result;
                var shicyou_cd_array = [];
                var shicyou_mei_array = [];

                for (var i in result) {
                  shicyou_cd_array[i] = result[i].shicyou_cd;
                  shicyou_mei_array[i] = result[i].shicyou_mei;
                  this.shicyou_cd = this.shicyou_cd;
                  this.shicyou_cds = shicyou_cd_array;
                  this.shicyou_mei = this.shicyou_mei;
                  this.shicyou_meis = shicyou_mei_array;
                }
              })
              .catch(error => {
                console.log(error);
              });
          });
      }
    },
    delete_btn() {
      if (this.renban == null || this.renban.trim() == "") {
        this.isRenbanNotFound = true;
      } else {
        this.isRenbanNotFound = false;
      }
      if (!this.isRenbanNotFound) {
        this.clearError();
        this.info = null;
        var request_data = {
          year: this.engYear,
          saigai_ken: this.saigai_ken,
          kode_kbn: this.kode_kbn,
          renban: this.renban
        };
        if (confirm("このデータを削除してもよろしいですか？")) {
          apiService
            .fa111Delete(request_data)
            .then(result => {
              this.info = result;
              this.renban = "";
              this.getRenbanData(
                this.engYear,
                this.saigai_ken,
                this.kode_kbn,
                this.renban
              );
            })
            .catch(error => {
              console.log(error);
              var request_data = {
                kode_kbn: this.kode_kbn
              };
              apiService
                .fa111getShicyouMei(request_data)
                .then(result => {
                  this.disableInsertBtn = true;
                  this.disableUpDelBtn = false;
                  this.shicyou = result;
                  var shicyou_cd_array = [];
                  var shicyou_mei_array = [];

                  for (var i in result) {
                    shicyou_cd_array[i] = result[i].shicyou_cd;
                    shicyou_mei_array[i] = result[i].shicyou_mei;
                    this.shicyou_cd = this.shicyou_cd;
                    this.shicyou_cds = shicyou_cd_array;
                    this.shicyou_mei = this.shicyou_mei;
                    this.shicyou_meis = shicyou_mei_array;
                  }
                })
                .catch(error => {
                  this.disableInsertBtn = false;
                  this.disableUpDelBtn = true;
                  console.log(error);
                });
            });
        }
      }
    },
    FormatCheck() {
      var fusoku = this.heikin_fusoku.replace(/,/g, "");
      fusoku = fusoku.trim().split(".")[0];
      var cyoui = this.saidai_cyoui.replace(/,/g, "");
      cyoui = cyoui.trim().split(".")[0];
      var hakou = this.saidai_hakou.replace(/,/g, "");
      hakou = hakou.trim().split(".")[0];
      if (fusoku.length > 9) {
        this.isNumFormat = true;
        this.info = null;
        this.numFormatErr = this.$messages.msg["0007W"];
      } else {
        if (cyoui.length > 9) {
          this.isNumFormat = true;
          this.info = null;
          this.numFormatErr = this.$messages.msg["0008W"];
        } else {
          if (hakou.length > 9) {
            this.isNumFormat = true;
            this.info = null;
            this.numFormatErr = this.$messages.msg["0009W"];
          } else {
            this.isNumFormat = false;
          }
        }
      }
    },
    clearError() {
      (this.unique_error = null),
        (this.unique_show = false),
        (this.errors = []);
    }
  }
};
</script>
<template>
  <AppLayout>
    <main class="mb-3" id="fa111">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="isNumFormat" class="err">{{ numFormatErr }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="unique_show" class="err">{{ unique_error }}</span>
      </div>
      <div class="container mt-3 mb-3">
        <h4 class="text-primary">【抽出条件】</h4>
      </div>
      <div class="container mb-3">
        <div class="form-group row" style="width:100%; max-width:400px">
          <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
            <input
              @blur="changedYear()"
              type="text"
              class="form-control bg-required"
              maxlength="4"
              v-model="jpYear"
            />
          </div>
          <label
            for
            class="col-sm col-form-label text-left pr-0 pl-1"
            style="width:100%; max-width:20px"
          >年</label>
        </div>
        <div class="offset-sm-1 pl-4 mb-2">
          <span class="err" v-show="isYearNotFound">{{ this.required }}</span>
        </div>
        <div class="form-group row" style>
          <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:200px; display:inline-flex; ">
            <select
              @change="kenChange(meisyou, saigai_ken)"
              v-model="saigai_ken"
              class="form-control bg-required"
            >
              <option
                v-for="meisyou in meisyou"
                :key="meisyou.saigai_ken"
                :value="meisyou.saigai_ken"
              >
                {{meisyou.saigai_ken}}
                <span v-if="meisyou.saigai_ken!= null">:</span>
                {{meisyou.saigai_mei}}
              </option>
            </select>
          </div>
          <label
            for
            class="col-sm col-form-label text-right"
            style="width:100%; max-width:120px"
          >災害発生日</label>
          <div class="col-sm pr-0" style="width:100%; max-width:130px">
            <input type="text" class="form-control bg-light" v-model="saigai_start" readonly />
          </div>
          <label for class="col-sm col-form-label text-center" style="width:100%; max-width:50px">〜</label>
          <div class="col-sm pl-0" style="width:100%; max-width:130px">
            <input type="text" class="form-control bg-light" v-model="saigai_end" readonly />
          </div>
        </div>
        <div class="offset-sm-1 pl-4 mb-2">
          <span class="err" v-show="isHasKenNotFoundErr">{{ this.kenErrorMsg }}</span>
        </div>
        <div class="form-group" style>
          <div class="form-check form-check-inline" style="width:100%; max-width:100px">
            <label class="form-check-label" for>訂正区分</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              v-model="kode_kbn"
              v-bind:value="1"
              v-on:change="updateRadioClicked()"
            />
            <label class="form-check-label" for>災害報告</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              v-model="kode_kbn"
              v-bind:value="2"
              v-on:change="updateRadioClicked()"
            />
            <label class="form-check-label" for>１ヶ月訂正</label>
          </div>
        </div>
      </div>
      <div class="container pt-3 border-top border-secondary">
        <div class="form-group form-inline" style>
          <label for class="form-label mr-3" style>連番</label>
          <div
            class="mr-1"
            style="margin-left:70px; width:100%; max-width:200px; display:inline-flex; "
          >
            <select
              @change="renbanFocus(genin, renban)"
              style="width:130px; max-width:200px;"
              id
              class="form-control bg-required"
              name
              v-model="renban"
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
        </div>
        <div class="offset-sm-1 pl-4 mb-2">
          <span class="err" v-show="isRenbanNotFound">{{ this.renbanErrMsg }}</span>
        </div>
        <p
          style="width:100%; max-width:100px; margin-left: 120px; white-space: nowrap;"
          class="err"
        >{{ this.errors.renban }}</p>
      </div>
      <div class="container pt-3 border-top border-secondary clearfix">
        <div class="form-inline float-right">
          <button
            v-on:click="insert_btn()"
            :disabled="this.disableInsertBtn"
            class="btn btn-light"
            type="submit"
            style="width:120px;"
          >登録</button>
          <button
            v-on:click="update_btn()"
            :disabled="this.disableUpDelBtn"
            class="btn btn-light ml-3"
            type="submit"
            style="width:120px;"
          >更新</button>
          <button
            v-on:click="delete_btn()"
            :disabled="this.disableUpDelBtn"
            class="btn btn-light ml-3"
            type="submit"
            style="width:120px;"
          >削除</button>
        </div>
      </div>
      <div class="container mt-3">
        <table>
          <tbody class="align-middle">
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">市町村</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="col-sm pr-0 pl-0" style="display:inline-flex;">
                  <input
                    @blur="focusShicyouCd(shicyou, shicyou_cd)"
                    class="form-control"
                    style="border-radius: 0; position:absolute; width:70px;"
                    maxlength="9"
                    type="text"
                    v-model="shicyou_cd"
                  />
                  <select
                    @change="changeShicyouCd(shicyou_cd, shicyou_cds, shicyou_meis)"
                    style="width:100px; max-width:200px border-left:none; border-radius: 0 !important;;"
                    class="form-control"
                    v-model="shicyou_cd"
                  >
                    <option v-for="cd in shicyou_cds" :key="cd.value" :value="cd">{{ cd }}</option>
                  </select>
                  <input
                    type="text"
                    class="form-control p-1 bg-light"
                    v-model="shicyou_mei"
                    style="width:200px;border-left:none; border-radius: 0 !important;"
                    readonly
                  />
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.shicyou_cd"
                >{{ this.errors.shicyou_cd }}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="mt-3">
          <tbody class="align-middle">
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">連続雨量</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="uryou"
                    maxlength="9"
                    style="width:80px"
                    v-on:change="uryouChange()"
                  />
                  <label for class="form-label ml-2" style>㎜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.uryou"
                >{{ this.errors.uryou }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    v-model="uryoubi_start"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    :min="0"
                    :max="23"
                    class="form-control p-1 text-right"
                    style="width:70px; margin-left:10px;"
                    maxlength="2"
                    v-model="uryouji_start"
                  />
                  <label for class="form-label ml-2" style>時 〜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.uryoubi_start"
                >{{ this.errors.uryoubi_start }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.uryouji_start"
                >{{ this.errors.uryouji_start }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="uryoubi_end"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1 text-right"
                    style="width:70px; margin-left:10px;"
                    maxlength="2"
                    v-model="uryouji_end"
                  />
                  <label for class="form-label ml-2" style>時</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.uryoubi_end"
                >{{ this.errors.uryoubi_end }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.uryouji_end"
                >{{ this.errors.uryouji_end }}</p>
              </td>
            </tr>
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">最大日雨量</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    style="width:80px"
                    maxlength="9"
                    v-model="hiuryou"
                    v-on:change="hiuryouChange()"
                  />
                  <label for class="form-label ml-2" style>㎜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.hiuryou"
                >{{ this.errors.hiuryou }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="hiuryoubi_start"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1 text-right"
                    style="width:70px; margin-left:10px;"
                    maxlength="2"
                    v-model="hiuryouji_start"
                  />
                  <label for class="form-label ml-2" style>時 〜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.hiuryoubi_start"
                >{{ this.errors.hiuryoubi_start }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.hiuryouji_start"
                >{{ this.errors.hiuryouji_start }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="hiuryoubi_end"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1 text-right"
                    style="width:70px; margin-left:10px;"
                    maxlength="2"
                    v-model="hiuryoujii_end"
                  />
                  <label for class="form-label ml-2" style>時</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.hiuryoubi_end"
                >{{ this.errors.hiuryoubi_end }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.hiuryoujii_end"
                >{{ this.errors.hiuryoujii_end }}</p>
              </td>
            </tr>
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">最大時間雨量</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    style="width:80px"
                    maxlength="9"
                    v-model="jikanuryou"
                    v-on:change="jikanuryouChange()"
                  />
                  <label for class="form-label ml-2" style>㎜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.jikanuryou"
                >{{ this.errors.jikanuryou }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="jikanuryoubi_start"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1 text-right"
                    style="width:70px; margin-left:10px;"
                    maxlength="2"
                    v-model="jikanuryoujii_start"
                  />
                  <label for class="form-label ml-2" style>時 〜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.jikanuryoubi_start"
                >{{ this.errors.jikanuryoubi_start }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.jikanuryoujii_start"
                >{{ this.errors.jikanuryoujii_start }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="jikanuryoubi_end"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1 text-right"
                    style="width:70px; margin-left:10px;"
                    maxlength="2"
                    v-model="jikanuryouji_end"
                  />
                  <label for class="form-label ml-2" style>時</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.jikanuryoubi_end"
                >{{ this.errors.jikanuryoubi_end }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.jikanuryouji_end"
                >{{ this.errors.jikanuryouji_end }}</p>
              </td>
            </tr>
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">最大平均風速</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    style="width:120px"
                    :maxlength="max_fusoku"
                    v-model="heikin_fusoku"
                    @blur="heikinFusokuChange()"
                    @focus="removeCommaFusoku()"
                  />
                  <label for class="form-label ml-2" style>m/s</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.heikin_fusoku"
                >{{ this.errors.heikin_fusoku }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="heikin_fusokubi"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1"
                    style="width:70px; margin-left:10px;"
                    maxlength="5"
                    v-model="heikin_fusokuji_start"
                    v-on:change="fusokujiStartChange(heikin_fusokuji_start)"
                  />
                  <label for class="form-label ml-2" style="padding-left:20px;">〜</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if=" this.errors.heikin_fusokubi"
                >{{ this.errors.heikin_fusokubi }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if=" this.errors.heikin_fusokuji_start"
                >{{ this.errors.heikin_fusokuji_start }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="text"
                    class="form-control p-1"
                    style="width:70px;"
                    maxlength="5"
                    v-model="heikin_fusokuji_end"
                    v-on:change="fusokujiEndChange(heikin_fusokuji_end)"
                  />
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.heikin_fusokuji_end"
                >{{ this.errors.heikin_fusokuji_end }}</p>
              </td>
            </tr>
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">最大潮位</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    style="width:120px"
                    :maxlength="max_cyoui"
                    v-model="saidai_cyoui"
                    @blur="saidaiCyouiChange()"
                    @focus="removeCommaCyoui()"
                  />
                  <label for class="form-label ml-2" style>m</label>
                </div>
                <p
                  style="width:100%; max-width:130px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.saidai_cyoui"
                >{{ this.errors.saidai_cyoui }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="saidai_cyouibi"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1"
                    style="width:70px; margin-left:10px;"
                    maxlength="5"
                    v-model="saidai_cyouiji"
                    v-on:change="saidaiCyouiji(saidai_cyouiji)"
                  />
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.saidai_cyouibi"
                >{{ this.errors.saidai_cyouibi }}</p>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.saidai_cyouiji"
                >{{ this.errors.saidai_cyouiji }}</p>
              </td>
            </tr>
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">最大波高</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    style="width:120px"
                    :maxlength="max_hakou"
                    v-model="saidai_hakou"
                    @blur="saidaiHakouChange()"
                    @focus="removeCommaHakou()"
                  />
                  <label for class="form-label ml-2" style>m</label>
                </div>
                <p
                  style="width:100%; max-width:100px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.saidai_hakou"
                >{{ this.errors.saidai_hakou }}</p>
              </td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan>
                <div class="form-inline align-items-center">
                  <datepicker
                    :language="ja"
                    :typeable="true"
                    width="30px"
                    v-model="saidai_hakoubi"
                    format="yyyy/MM/dd"
                  ></datepicker>
                  <input
                    type="text"
                    class="form-control p-1"
                    style="width:70px; margin-left:10px;"
                    maxlength="5"
                    v-model="saidai_hakouji"
                    v-on:change="saidaiHakouji(saidai_hakouji)"
                  />
                </div>
                <p
                  style="width:100%; max-width:130px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.saidai_hakoubi"
                >{{ this.errors.saidai_hakoubi }}</p>
                <p
                  style="width:100%; max-width:130px; white-space: nowrap;"
                  class="err"
                  v-if="this.errors.saidai_hakouji"
                >{{ this.errors.saidai_hakouji }}</p>
              </td>
            </tr>
            <tr>
              <td scope="row" class="align-middle pt-2 pb-2" style="width:100px;">その他</td>
              <td class="align-middle pl-3 pt-2 pb-2" colspan="3">
                <div class="form-inline align-items-center" style="width:100%">
                  <input
                    type="text"
                    class="form-control p-1flex-fill"
                    style="width:100%"
                    maxlength="100"
                    v-model="sonota"
                  />
                  <p
                    style="width:100%; max-width:130px; white-space: nowrap;"
                    class="err"
                    v-if="this.errors.sonota"
                  >{{ this.errors.sonota }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </AppLayout>
</template>