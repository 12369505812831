<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();

export default {
  name: "FG204",
  components: {
    AppLayout,
    Loading,
  },

  data() {
    return {
      login_id: "",
      regist_status: "2",
      disableInsertBtn: true,
      disableUpDelBtn: false,
      kbn: "",
      daikoumokuList: [],
      daikoumoku: "",
      daikoumoku_mei: "",
      daikoumoku_meisai: "",
      chukoumokuList: [],
      chukoumoku: "",
      chukoumoku_cd: "",
      chukoumoku_mei: "",
      chukoumoku_meisai: "",
      fileList: [],
      file: "",
      file_no: "",
      file_name: "",
      file_data: "",
      file_type: "1",
      order_no: "",
      target_file_no: "",
      display_order: "",
      chk_date: "",
      err: "",
      errors: {
        daikoumoku: null,
        file_name: null,
        file_data: null,
        order_no: null,
        file_no: null,
      },
      isLoading: false,
      fullPage: true,
      info: null,
      textError: null,
    };
  },
  created() {
    this.getFailinfo();
  },
  mounted() {},
  methods: {
    getFailinfo() {
      this.isLoading = true;

      apiService
        .getFailinfo()
        .then((result) => {
          this.daikoumokuList = result.daikoumokuList;
          this.chukoumokuList = result.chukoumokuList;
          this.file_no = result.file_no;
          this.chk_date = result.chk_date;
          // 中項目リストに未選択用の項目を追加
          let no_selection = {
            cd_value: "~中項目無し~",
            meisai: null,
          };
          this.chukoumokuList.unshift(no_selection);
          this.isLoading = false;
        })
        .catch((error) => {
          this.textError = error;
          this.isLoading = false;
        });
    },
    daikoumokuChange(daikoumoku) {
      this.clearError();
      this.textError = null;
      this.info = null;
      // 選択した大項目をセット
      this.daikoumoku_meisai = daikoumoku;
      // 大項目を変更した際に、中項目とファイルリスト、ファイル名を初期化
      if (this.chukoumoku_meisai !== "") {
        this.chukoumoku = "";
        this.file = "";
        this.fileList = [];
        this.file_name = "";
        this.file_data = "";
        var obj = document.getElementById("file-data");
        obj.value = "";
        this.file_type = "1";
        this.order_no = "";
        this.target_file_no = "";
        this.file_name = "";
      }
    },
    chukoumokuChange(chukoumoku) {
      this.clearError();
      this.textError = null;
      this.info = null;
      this.fileList = [];

      // 登録区分：更新の場合ファイル名を検索
      if (this.regist_status == "2") {
        // 選択した中項目をセット
        this.chukoumoku_meisai = chukoumoku;
        this.isLoading = true;
        var request_data = {
          daikoumoku: this.daikoumoku_meisai,
          chukoumoku: this.chukoumoku_meisai,
        };
        // ファイルリストを取得
        apiService
          .getFileList(request_data)
          .then((result) => {
            this.fileList = result;
            this.isLoading = false;
          })
          .catch((error) => {
            this.textError = error;
            this.isLoading = false;
          });
      }
    },
    fileChange(file) {
      this.clearError();
      this.textError = null;
      this.info = null;
      // ファイル名が入力されていた場合初期化
      this.file_name = "";
      // 選択したファイルのファイルNoをセット
      this.file = file;
      // 選択したファイル情報を表示
      var file_id = this.fileList.filter((i) => i.file_no == this.file);
      this.file_type = file_id[0].file_type;
      this.order_no = file_id[0].order_no;
      this.file_name = file_id[0].file_name;
      this.target_file_no = this.file;
    },
    nameChange(file_name) {
      this.clearError();
      this.textError = null;
      this.info = null;
      // ファイル名に使用できない文字の入力制限を追加
      // ファイル名をセット
      this.file_name = file_name;
    },
    changeRegistStatus(regist_status) {
      this.clearData();
      this.getFailinfo();
      if (regist_status == "1") {
        this.disableInsertBtn = false;
        this.disableUpDelBtn = true;
      } else {
        this.disableInsertBtn = true;
        this.disableUpDelBtn = false;
      }
    },
    fileSelected(e) {
      this.clearError();
      this.textError = null;
      this.info = null;
      this.file_data = e.target.files[0];
    },
    changeFileType(file_type) {
      this.file_type = file_type;
    },
    changeOrderNo(order_no) {
      this.order_no = order_no;
    },
    insert_btn() {
      this.isLoading = true;
      this.clearError();
      this.textError = null;
      this.info = null;

      let formData = new FormData();
      formData.append("file_data", this.file_data);
      formData.append("daikoumoku", this.daikoumoku);
      formData.append("chukoumoku", this.chukoumoku);
      formData.append("file_no", this.file_no);
      formData.append("file_name", this.file_name);
      formData.append("file_type", this.file_type);
      formData.append("order_no", this.order_no);
      formData.append("login_id", localStorage.getItem("LOGIN_ID"));
      formData.append("action", "insert");

      apiService
        .fg204InsertFile(formData)
        .then((result) => {
          this.info = result;
          this.getFailinfo();
          this.clearData();
        })
        .catch((error) => {
          if (typeof error == "string") {
            confirm(this.$messages.msg["0013E"]);
            this.textError = error;
          }
          if (error.errors != null) {
            this.errors.daikoumoku = error.errors.daikoumoku ? error.errors.daikoumoku[0] : "";
            this.errors.file_name = error.errors.file_name ? error.errors.file_name[0] : "";
            this.errors.file_data = error.errors.file_data ? error.errors.file_data[0] : "";
            this.errors.order_no = error.errors.order_no ? error.errors.order_no[0] : "";
            confirm(this.$messages.msg["0013E"]);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update_btn() {
      this.isLoading = true;
      this.clearError();
      this.textError = null;
      this.info = null;

      let formData = new FormData();
      formData.append("file_data", this.file_data);
      formData.append("daikoumoku", this.daikoumoku);
      formData.append("chukoumoku", this.chukoumoku);
      formData.append("file_no", this.target_file_no);
      formData.append("file_name", this.file_name);
      formData.append("file_type", this.file_type);
      formData.append("order_no", this.order_no);
      formData.append("login_id", localStorage.getItem("LOGIN_ID"));
      formData.append("chk_date", this.chk_date);
      formData.append("action", "update");

      apiService
        .fg204UpdateFile(formData)
        .then((result) => {
          this.info = result;
          this.getFailinfo();
          this.clearData();
        })
        .catch((error) => {
          if (typeof error == "string") {
            confirm(this.$messages.msg["0013E"]);
            this.textError = error;
          }
          if (error.errors != null) {
            this.errors.daikoumoku = error.errors.daikoumoku ? error.errors.daikoumoku[0] : "";
            this.errors.file_name = error.errors.file_name ? error.errors.file_name[0] : "";
            this.errors.file_data = error.errors.file_data ? error.errors.file_data[0] : "";
            this.errors.file_no = error.errors.file_no ? error.errors.file_no[0] : "";
            this.errors.order_no = error.errors.order_no ? error.errors.order_no[0] : "";
            confirm(this.$messages.msg["0013E"]);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    delete_btn() {
      this.clearError();
      this.textError = null;
      this.info = null;

      var request_data = {
        file_no: this.target_file_no,
        chk_date: this.chk_date,
      };

      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        this.isLoading = true;
        apiService
          .fg204DeleteFile(request_data)
          .then((result) => {
            this.info = result;
            this.isLoading = false;
          })
          .catch((error) => {
            if (typeof error == "string") {
              confirm(this.$messages.msg["0013E"]);
              this.textError = error;
            }
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
        this.getFailinfo();
        this.clearData();
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    clearData() {
      this.daikoumoku = "";
      this.chukoumoku = "";
      this.fileList = [];
      this.file = "";
      this.file_no = "";
      this.file_data = "";
      var obj = document.getElementById("file-data");
      obj.value = "";
      this.file_type = "1";
      this.order_no = "";
      this.target_file_no = "";
      this.file_name = "";
    },
    clearError() {
      this.errors.daikoumoku = null;
      this.errors.file_name = null;
      this.errors.file_data = null;
      this.errors.order_no = null;
    },
  },
};
</script>
<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle">
          <img src="@/assets/loader.gif" style="dipslay: inline-block; float: left" />
          <h4 style="dipslay: inline-block; float: left; color: #3399f3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main id="fg204" class="mb-3">
      <p class="col-md-12 bg-success-info text-center">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err">{{ textError }}</p>
      <div class="container mt-3 mb-3 clearfix">
        <div class="form-inline float-right">
          <button v-on:click="insert_btn()" :disabled="this.disableInsertBtn" class="btn btn-light" type="submit" style="width: 120px">登録</button>
          <button v-on:click="update_btn()" :disabled="this.disableUpDelBtn" class="btn btn-light ml-3" type="submit" style="width: 120px">更新</button>
        </div>
      </div>
      <div class="container mb-3">
        <div class="form-group row" style="width: 100%; max-width: 400px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">登録区分</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="regist_status" @change="changeRegistStatus(regist_status)" v-bind:value="1" />
            <label class="form-check-label" for>新規</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="regist_status" @change="changeRegistStatus(regist_status)" v-bind:value="2" />
            <label class="form-check-label" for>更新</label>
          </div>
        </div>
      </div>
      <div class="container mb-3">
        <div class="form-group row" style="width: 100%; max-width: 700px; max-height: 60px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">大項目</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 400px">
            <select id class="form-control bg-required" v-model="daikoumoku" @change="daikoumokuChange(daikoumoku)">
              <option v-for="koumoku in daikoumokuList" :key="koumoku.meisai" :value="koumoku.meisai">{{ koumoku.cd_value }}</option>
            </select>
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red" v-show="this.errors.daikoumoku">{{ this.errors.daikoumoku }}</p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; position: relative; max-width: 700px" :class="this.errors.saigai_ken ? 'mb-0' : ''">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">中項目</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 400px">
            <select id class="form-control bg-required" v-model="chukoumoku" @change="chukoumokuChange(chukoumoku)">
              <option v-for="chukoumoku in chukoumokuList" :key="chukoumoku.meisai" :value="chukoumoku.meisai">{{ chukoumoku.cd_value }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; position: relative; max-width: 700px" :class="this.errors.saigai_ken ? 'mb-0' : ''">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">ファイル名</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 500px">
            <input v-if="regist_status == '1'" id="file" class="form-control bg-required" style="width: 100%; max-width: 200px" disabled />
            <select v-if="regist_status == '2'" id="file" style="width: 100%" class="form-control bg-required" v-model="file" @change="fileChange(file)">
              <option v-for="file in fileList" :key="file.file_no" :value="file.file_no">{{ file.file_name }}</option>
            </select>
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red" v-show="this.errors.file_no">{{ this.errors.file_no }}</p>
          </div>
        </div>
        <div class="offset-sm-1 pl-4">
          <span class="err">{{ this.errors.saigai_ken }}</span>
        </div>
      </div>
      <div class="container pt-3 border-top border-secondary">
        <div class="form-group row" style="width: 100%; max-width: 800px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">ファイルNo</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 80px">
            <input v-if="regist_status == '1'" class="form-control" maxlength="5" v-model="file_no" readonly />
            <input v-if="regist_status == '2'" class="form-control" maxlength="5" v-model="target_file_no" readonly />
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 800px; max-height: 60px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">ファイル名</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 500px">
            <input type="text" class="form-control bg-required" v-model="file_name" @blur="nameChange(file_name)" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red" v-show="this.errors.file_name">{{ this.errors.file_name }}</p>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 800px; max-height: 60px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">ファイル</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 500px">
            <input type="file" id="file-data" class="form-control" @change="fileSelected" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red" v-show="this.errors.file_data">{{ this.errors.file_data }}</p>
          </div>
        </div>
        <div v-if="regist_status == '2'" class="form-group row" style="width: 100%; max-width: 800px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px"></label>
          <div>
            <div class="pr-3 pl-3" v-for="file in fileList" :key="file.file_no" :value="file.file_no">{{ file.file_name }}</div>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 800px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">ファイル種類</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="file_type" @change="changeFileType(file_type)" v-bind:value="1" />
            <label class="form-check-label" for>PDF</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="file_type" @change="changeFileType(file_type)" v-bind:value="2" />
            <label class="form-check-label" for>Excel</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="file_type" @change="changeFileType(file_type)" v-bind:value="3" />
            <label class="form-check-label" for>Word</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="file_type" @change="changeFileType(file_type)" v-bind:value="4" />
            <label class="form-check-label" for>PowerPoint</label>
          </div>
        </div>
        <div class="form-group row" style="width: 100%; max-width: 800px">
          <label class="col-sm col-form-label" style="width: 100%; max-width: 170px">表示順（中項目内）</label>
          <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 80px">
            <input class="form-control" maxlength="15" @change="changeOrderNo(order_no)" v-model="order_no" />
            <p style="width: 100%; max-width: 130px; white-space: nowrap; color: red" v-show="this.errors.order_no">{{ this.errors.order_no }}</p>
          </div>
        </div>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <div class="form-inline float-right">
          <button v-on:click="delete_btn()" :disabled="this.disableUpDelBtn" class="btn btn-light ml-3" type="submit" style="width: 120px">削除</button>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
