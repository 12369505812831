const defaultState = {
  search: '',
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setYear(state, result) {
      state.year = result;
    },
    setNjiSatei(state, result) {
      state.nji_satei = result;
    },
    setKanrisyaCd(state, result) {
      state.kanrisya_cd = result;
    },
    setKanrisyaMei(state, result) {
      state.kanrisya_mei = result;
    },
    setPage(state, result) {
      state.page = result;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  }
};