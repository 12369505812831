/**
 * 災害集計管理システムシステム
 */
const msg = {

    //########################################################################
    //### 情報メッセージ                                                   ###
    //########################################################################

    '0001I': "{0}を登録しました。",
    '0002I': "{0}を更新しました。",
    '0003I': "{0}を削除しました。",
    '0004I': "集計処理が完了しました",
    '0005I': "既にデータが存在しています",
    '0006I': "振分処理実行 処理が完了しました。",

    //########################################################################
    //### 警告メッセージ                                                   ###
    //########################################################################

    '0001W': "{0}を入力して下さい。",
    '0002W': "が誤っています。",
    '0003W': "既にログイン中のユーザが存在しますがログインしてもよろしいでしょうか？（前回ログアウトせずブラウザを閉じてシステムを終了した場合にもこちらのメッセージが表示されることがあります。）",
    '0004W': "災害年を入力して下さい。",
    '0005W': "災害番号を入力して下さい。",
    '0006W': "被災延長は99999999.99以下で入力してください。", //FB102
    '0007W': "最大平均風速は999999999.9以下で入力してください。", //FB111
    '0008W': "最大潮位は999999999.9以下で入力してください。", //FB111
    '0009W': "最大波高は999999999.9以下で入力してください。", //FB111
    '0010W': "連続雨量最大の最大平均風速は999999999.9以下で入力してください。", //FB112
    '0011W': "被災中心地の最大平均風速は999999999.9以下で入力してください。", //FB112
    '0012W': "連番を入力して下さい。",
    '00013W': "を入力して下さい。",
    '0014W': "数量1は999999999.9以下で入力してください。", //FC102,FB102
    '0015W': "数量2は999999999.9以下で入力してください。", //FC102,FB102
    '0016W': "数量3は999999999.9以下で入力してください。", //FC102,FB102
    '0017W': "数量4は999999999.9以下で入力してください。", //FC102,FB102
    '0018W': "数量5は999999999.9以下で入力してください。", //FC102,FB102
    '0019W': "数量6は999999999.9以下で入力してください。", //FC102,FB102
    '0020W': "数量7は999999999.9以下で入力してください。", //FC102,FB102
    '0021W': "数量8は999999999.9以下で入力してください。", //FC102,FB102
    '0022W': "工事番号を入力してください。", //FC101
    '0023W': "出力対象の帳票が選択されていません", //FA114,FB108
    '0024W': "被災延長は999999999.9以下で入力してください。", //FC101
    '0025W': "復旧延長は999999999.9以下で入力してください。", //FC101
    '0026W': "延長1は999999999.9以下で入力してください。", //FC101
    '0027W': "延長2は999999999.9以下で入力してください。", //FC101
    '0028W': "延長3は999999999.9以下で入力してください。", //FC101
    '0029W': "延長4は999999999.9以下で入力してください。", //FC101
    '0030W': "延長5は999999999.9以下で入力してください。", //FC101
    '0031W': "被害原因別調書を出力する場合、n次査定は必須項目です。", //Fb108
    '0032W': "班別とりまとめ表を出力する場合、n次査定は必須項目です。", //Fb108,Fc105
    '0033W': "市町村工事内訳調書を出力する場合、n次査定は必須項目です。", //Fb108
    '0034W': "災害総計表を出力する場合、n次査定は必須項目です。", //Fb108
    '0035W': "県市町村工事災害報告一覧表を出力する場合、工事区分は必須項目です。", //FA114
    '0036W': "災害報告明細表を出力する場合、工事区分は必須項目です。", //FA114
    '0037W': "欠格箇所調書を出力する場合、n次査定は必須項目です。", //Fc105
    '0038W': "対象データは存在しません。", //FC101
    '0039W': "査定番号が異なります。確認してください。", //FC101
    '0040W': "仮登録状態です。確認ください。", //FC101
    '0041W': "工事番号は数値を入力してください。", //FC101
    '0042W': "国庫負担申請データを取得してください。", //FC101
    '0043W': "全体とりまとめ表を出力する場合、n次査定は必須項目です。", //lb303
    '0044W': "工事番号が重複しています。", //FB100
    '0045W': "班別工事内訳書を出力する場合、n次査定は必須項目です。",//FB108
    '0046W': "申請野帳を出力する場合、工事番号は開始と終了の両方を指定して下さい。", //FB108
    '0047W': "申請野帳を出力する場合、n次査定または工事番号を指定して下さい。", //FB108
    '0048W': "復命書を出力する場合、n次査定は必須項目です。", //FC105
    '0049W': "査定速報を出力する場合、n次査定は必須項目です。", //FC105
    '0050W': "目論見書を出力する場合、n次査定は必須項目です。", //FB108
    '0051W': "全体とりまとめ表を出力する場合、n次査定は必須項目です。", //FB108
    '0052W': "班別とりまとめ表を出力する場合、n次査定は必須項目です。", //FB108
    '0053W': "内未成工事調書を出力する場合、n次査定は必須項目です。", //FB108
    '0054W': "内転属工事調書を出力する場合、n次査定は必須項目です。", //FB108
    '0055W': "協議設計箇所調書を出力する場合、n次査定は必須項目です。", //FB108
    '0056W': "保留箇所調書を出力する場合、n次査定は必須項目です。", //FB108
    '0057W': "特記事項調書を出力する場合、n次査定は必須項目です。", //FB108
    '0058W': "検査申請済被害報告額調書を出力する場合、n次査定は必須項目です。", //FB108
    '0059W': "位置情報が登録されていません。",//FA101


    //########################################################################
    //### 異常メッセージ                                                   ###
    //########################################################################

    '0001E': "現在ログイン中のユーザが存在します。",
    '0002E': "該当するデータがみつかりません。",
    '0003E': "災害発生開始日の日付は災害発生終了日より越えて指定できません。",
    '0004E': "災害番号を入力してください",
    '0005E': "災害年を入力してください。",
    '0006E': "該当する連続雨量は存在しません。",
    '0007E': "該当する異常気象名は存在しません。",
    '0008E': "選択された{0}が存在しません。",
    '0009E': "被害報告データが存在しません。",
    '0010E': "災害査定データが存在しません", //FC100
    '0011E': "国庫負担申請の本登録データが存在する為，再振分できません",//fB106
    '0012E': "国庫負担申請データが存在しません", //FB100
    '0013E': "エラー：入力内容を確認してください。", // 共通
    '0014E': "入力された災害年が正しくありません。", //FA100
    '0015E': "管理者を入力してください",
    '0016E': "画像の更新に失敗しました。",
    '0017E': "位置情報を変更しますか？",
    //########################################################################
    //### 確認メッセージ                                                   ###
    //########################################################################
    '0001Q': "このデータを削除してもよろしいですか？",
    '0002Q': "被災報告額（工事費）は千円単位です。10億円以上の値が入力されていますがよろしいですか？",
    '0003Q': "被災報告額（委託費）は千円単位です。10億円以上の値が入力されていますがよろしいですか？",

};

export default {
    msg
}
