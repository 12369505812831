<script>
import AppLayout from "@/components/layouts/Layout.vue";
import datepicker from 'vuejs-datepicker';
import moment from 'moment';
import {ja} from 'vuejs-datepicker/dist/locale';
import { APIService } from '@/apiService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();

export default {
  name: "FA100",
  components: {
    AppLayout, datepicker, Loading
  },
  
  data() {
    return {
      jpYear: '',
      saigai_yyyy: '',
      saigai_ken: '',
      saigai_ken_select: '',
      saigai_ken_text: '',
      saigai_kens: [],
      saigai_kuni: '',
      saigai_kunis: [],
      saigai_start: '',
      saigai_starts: [],
      saigai_end: '',
      saigai_ends: [],
      saigai_mei: '',
      saigai_meis: [],
      ja:ja,
      meisyou: [],
      unique_error: null,
      unique_show: false,
      disableUpDelBtn: true,
      disableInsertBtn: true,
      info: null,
      regist_status: '2',
      errors: {
        saigai_ken: null,
        saigai_kuni: null,
        saigai_start: null,
        saigai_end: null,
        saigai_mei:null
      },
      isLoading: false,
      fullPage: true,
      chk_date: ""
    }
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.saigai_yyyy = (new Date().getFullYear()).toString();
    this.search(this.saigai_yyyy);
    this.getGengo(this.saigai_yyyy);
  },
  methods: {
    getGengo(year){
      this.info = '';
      this.isHasError = false;
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format('YYYYMMDD');
      var request_data = {
        date: param,
      };
      apiService.getGengo(request_data)
      .then(result=>{
        this.jpYear = result;
      });
    },
    search(year){
      this.clearError();
      var request_data = {
        year: year,
      }
      if(year == "") {
        this.saigai_kuni = '';
        this.saigai_start = '';
        this.saigai_end = '';
        this.saigai_mei = '';
      }

      apiService.fa100Search(request_data)
        .then(result => {
          var meisyou_list = result.meisyou;
          if(meisyou_list && meisyou_list.length > 0){
            this.meisyou = meisyou_list;
            var saigai_ken_array   = [];
            var saigai_kuni_array  = [];
            var saigai_start_array = [];
            var saigai_end_array   = [];
            var saigai_mei_array   = [];
            
            for(var i in meisyou_list) {
              saigai_ken_array[i]   = meisyou_list[i].saigai_ken;
              saigai_kuni_array[i]  = meisyou_list[i].saigai_kuni;
              saigai_start_array[i] = meisyou_list[i].saigai_start;
              saigai_end_array[i]   = meisyou_list[i].saigai_end;
              saigai_mei_array[i]   = meisyou_list[i].saigai_mei;
            }
            this.saigai_ken    = saigai_ken_array[saigai_ken_array.length-1];
            this.saigai_ken_select = this.saigai_ken;
            this.saigai_kens   = saigai_ken_array;
            this.saigai_kuni   = this.convSaigaiKen(saigai_kuni_array[saigai_kuni_array.length-1]);
            this.saigai_kunis  = saigai_kuni_array;
            this.saigai_start  = saigai_start_array[saigai_start_array.length-1];
            this.saigai_start  = moment(String(this.saigai_start)).format('YYYY-MM-DD');
            this.saigai_starts = saigai_start_array;
            this.saigai_end    = saigai_end_array[saigai_end_array.length-1];
            this.saigai_end    = moment(String(this.saigai_end)).format('YYYY-MM-DD');
            this.saigai_ends   = saigai_end_array;
            this.saigai_mei    = saigai_mei_array[saigai_mei_array.length-1];
            this.saigai_meis   = saigai_mei_array;
            if(this.saigai_kens.length > 0) {
              this.disableInsertBtn = true;
              this.disableUpDelBtn = false;
            } else {
              this.disableInsertBtn = false;
              this.disableUpDelBtn = true;
            }
            this.chk_date = result.chk_date;
          } else if(year != "") {
            this.meisyou = [];
            this.saigai_ken="";
            this.saigai_kens = [];
            this.saigai_kuni="";
            this.saigai_end="";
            this.saigai_start="";
            this.saigai_mei="";
            this.saigai_ken_text = "";
            this.unique_show = true;
            this.unique_error = this.$messages.msg['0002E'];
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.unique_show = true;
          this.unique_error = error;
          console.log(error);
        });
    },
    insert_btn(){
      this.isLoading = true;
      this.clearError();
      this.info = null;
      var request_data = {
        year: this.saigai_yyyy,
        saigai_ken: (this.isInteger(this.saigai_ken)) ? parseInt(this.saigai_ken,10) : this.saigai_ken,
        saigai_kuni: (this.isInteger(this.saigai_kuni)) ? parseInt(this.saigai_kuni,10) : this.saigai_kuni,
        saigai_start: moment(String(this.saigai_start)).format('YYYY-MM-DD'),
        saigai_end: moment(String(this.saigai_end)).format('YYYY-MM-DD'),
        saigai_mei: this.saigai_mei,
        login_id: localStorage.getItem('LOGIN_ID')
      }

      apiService.fa100Insert(request_data)
        .then(result => {
          this.info = result;
          this.regist_status = '2';
          this.saigai_ken_text = "";
          this.search(this.saigai_yyyy);
        })
        .catch(error => {
          if(error.errors) {
            this.unique_show = false;
            confirm(this.$messages.msg['0013E']);
            this.errors.saigai_ken = (error.errors.saigai_ken) ? error.errors.saigai_ken[0] : '';
            this.errors.saigai_kuni = (error.errors.saigai_kuni) ? error.errors.saigai_kuni[0] : '';
            this.errors.saigai_start = (error.errors.saigai_start) ? error.errors.saigai_start[0] : '';
            this.errors.saigai_end = (error.errors.saigai_end) ? error.errors.saigai_end[0] : '';
            this.errors.saigai_mei = (error.errors.saigai_mei) ? error.errors.saigai_mei[0] : '';
          } else {
            confirm(this.$messages.msg['0013E']);
            this.unique_show = true;
          }
          this.unique_error = error;
          this.isLoading = false;
        });
    },
    update_btn(){
      this.clearError();
      this.isLoading = true;
      this.info = null;
      var request_data = {
        year: this.saigai_yyyy,
        saigai_ken: (this.isInteger(this.saigai_ken)) ? parseInt(this.saigai_ken,10) : this.saigai_ken,
        saigai_kuni: (this.isInteger(this.saigai_kuni)) ? parseInt(this.saigai_kuni,10) : this.saigai_kuni,
        saigai_start: moment(String(this.saigai_start)).format('YYYY-MM-DD'),
        saigai_end: moment(String(this.saigai_end)).format('YYYY-MM-DD'),
        saigai_mei: this.saigai_mei,
        login_id: localStorage.getItem('LOGIN_ID'),
        chk_date: this.chk_date
      }
      console.log(request_data)
      apiService.fa100Update(request_data)
        .then(result => {
          console.log(result)
          this.info = result;
          this.search(this.saigai_yyyy);
        })
        .catch(error => {
          if(error.errors) {
            this.unique_show = false;
            this.unique_error = error;
            confirm(this.$messages.msg['0013E']);
            this.errors.saigai_ken = (error.errors.saigai_ken) ? error.errors.saigai_ken[0] : '';
            this.errors.saigai_kuni = (error.errors.saigai_kuni) ? error.errors.saigai_kuni[0] : '';
            this.errors.saigai_start = (error.errors.saigai_start) ? error.errors.saigai_start[0] : '';
            this.errors.saigai_end = (error.errors.saigai_end) ? error.errors.saigai_end[0] : '';
            this.errors.saigai_mei = (error.errors.saigai_mei) ? error.errors.saigai_mei[0] : '';
          } else {
            confirm(this.$messages.msg['0013E']);
            this.unique_show = true;
          }
          this.unique_error = error;
          this.isLoading = false;
        });
    },
    delete_btn(){
      this.clearError();
      this.info = null;
      var request_data = {
        year: this.saigai_yyyy,
        saigai_ken: parseInt(this.saigai_ken,10),
        login_id: localStorage.getItem('LOGIN_ID'),
        chk_date: this.chk_date
      }  
      if(confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")){
        apiService.fa100Delete(request_data)
        .then(result => {
          this.info = result;
          this.search(this.saigai_yyyy);
        })
        .catch(error => {
          console.log(error)
          confirm(this.$messages.msg['0013E']);
          this.unique_error = error;
          this.unique_show  = true;
          this.isLoading    = false;
        });
      }
    },
    changeRegistStatus(regist_status) {
      if(regist_status == '1'){
        this.disableInsertBtn = false;
        this.disableUpDelBtn = true;
        this.clearData();
        this.saigai_yyyy = (new Date().getFullYear()).toString();
        this.setSinkiData(this.saigai_yyyy);
      } else {
        this.isLoading = true;
        this.disableInsertBtn = true;
        this.disableUpDelBtn = false;
        this.search(this.saigai_yyyy);
        this.getGengo(this.saigai_yyyy);
      }
    },
    onChange(saigai_ken, saigai_kens, saigai_kunis, saigai_starts, saigai_ends, saigai_meis) {
      this.isLoading = true;
      var index = saigai_kens.indexOf(saigai_ken);
      this.clearError();
      this.info = null;
      this.disableUpDelBtn = false;
      this.disableInsertBtn = true;
      this.saigai_ken   = saigai_ken;
      this.saigai_kuni  = saigai_kunis[index];
      this.saigai_start = saigai_starts[index];
      this.saigai_start = moment(String(this.saigai_start)).format('YYYY-MM-DD');
      this.saigai_end   = saigai_ends[index];
      this.saigai_end   = moment(String(this.saigai_start)).format('YYYY-MM-DD');
      this.saigai_mei   = saigai_meis[index];
      setTimeout(() => {
        this.isLoading = false;
      },300)
    },
    changedYear(){
      if(this.jpYear != ''  && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
        this.isLoading = true;
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
        var request_data = {
          gengo_cd: matches ? matches.toString() : "",
        }
        apiService.getGengoStartDate(request_data)
        .then(result=>{
          if(result.length > 0){
            this.unique_show = false;
            var startDate = result[0].start_date;
            this.saigai_yyyy = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);
            if (this.regist_status == '1') {
              // 新規登録時
              var request_data = {
                saigai_yyyy: this.saigai_yyyy,
              }
              apiService.fa101GetMaxSaigaiKen(request_data)
              .then(saigai_ken=>{
                this.saigai_ken = saigai_ken;
                this.saigai_ken_text = saigai_ken;
                this.isLoading = false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
            } else if (this.regist_status == '2') {
              // 更新時
              this.search(this.saigai_yyyy);
            }
          } else {
            // 元号が取得できない場合エラー
            this.unique_show = true;
            this.unique_error = this.$messages.msg['0014E'];
            this.isLoading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      } else {
        this.clearData();
        this.clearError();
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    convSaigaiKen(saigai_ken){
      return saigai_ken != null ? ( '00000' + saigai_ken ).slice(-5) : "";
    },
    clearData(){
      this.jpYear = "";
      this.saigai_yyyy = "";
      this.meisyou = [];
      this.saigai_ken="";
      this.saigai_kens = [];
      this.saigai_kuni="";
      this.saigai_end="";
      this.saigai_start="";
      this.saigai_mei="";
      this.saigai_ken_text = "";
    },
    clearError() {
      this.unique_error = null,
      this.unique_show = false,
      this.errors.saigai_ken = null,
      this.errors.saigai_kuni = null,
      this.errors.saigai_start = null,
      this.errors.saigai_end = null,
      this.errors.saigai_mei = null
    },
    setSinkiData(year) {
      this.info = '';
      this.isHasError = false;
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format('YYYYMMDD');
      var request_data = {
        date: param,
      };
      apiService.getGengo(request_data)
      .then(result=>{
        this.jpYear = result;
        this.changedYear();
      });
    } 
  }
};
</script>
<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main id="fa100" class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-show="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="unique_show"  class="err">{{ unique_error }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <div class="form-inline float-right">
          <button v-on:click="insert_btn()" :disabled = this.disableInsertBtn class="btn btn-light" type="submit" style="width:120px;">登録</button>
          <button v-on:click="update_btn()" :disabled = this.disableUpDelBtn class="btn btn-light ml-3" type="submit" style="width:120px;">更新</button>
        </div>
      </div>
      <div class="container mb-3">
        <div class="form-group row" style="width:100%; max-width:400px">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">登録区分</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="regist_status" @change="changeRegistStatus(regist_status)" v-bind:value="1">
            <label class="form-check-label" for>新規</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="regist_status" @change="changeRegistStatus(regist_status)" v-bind:value="2">
            <label class="form-check-label" for>更新</label>
          </div>
        </div>
      </div>
      <div class="container mb-3">
        <div class="form-group row" style="width:100%; max-width:400px">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
            <input @blur="changedYear()" type="tel" class="form-control bg-required" maxlength = "4" v-model="jpYear"/>
          </div>
          <label class="col-sm col-form-label text-left pr-0 pl-1" style="width:100%; max-width:20px">年</label>
        </div>
        <div class="form-group row" style="width: 100%; position: relative; max-width: 700px;" :class="this.errors.saigai_ken ? 'mb-0':''">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:400px;">
            <input v-if="regist_status == '1'" type="tel" id="ken" class="form-control bg-required"  style="width:100%; max-width:200px" maxlength = "9" disabled v-model="saigai_ken_text">
            <select v-if="regist_status == '2'" style="width:100%;" class="form-control bg-required" @change="onChange(saigai_ken_select, saigai_kens, saigai_kunis, saigai_starts, saigai_ends, saigai_meis)" v-model="saigai_ken_select">
              <option v-for="saigai_ken_list in meisyou" :key="saigai_ken_list.saigai_ken" :value="saigai_ken_list.saigai_ken">{{convSaigaiKen(saigai_ken_list.saigai_ken)}}：{{saigai_ken_list.saigai_mei}}</option>
            </select>
          </div>
        </div>
        <div class="offset-sm-1 pl-4">
            <span class="err">{{ this.errors.saigai_ken }}</span>
        </div>
      </div>
      <div class="container pt-3 border-top border-secondary">
        <div class="form-group row" style="width:100%; max-width:800px">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">気象コード</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:200px;">
            <input type="tel" class="form-control" maxlength = "5" v-model="saigai_kuni"/>
            <p style="width:100%; max-width:130px; white-space: nowrap; color: red;">{{ this.errors.saigai_kuni }}</p>
          </div>
        </div>
        <div class="form-group row" :class="this.errors ? 'mb-0':''">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">災害発生日</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px;" >
            <datepicker :language="ja" v-model="saigai_start" :value="saigai_start" :typeable="true" format="yyyy/MM/dd" width="100px"></datepicker>
          </div>
          <div>
            <label class="col-sm col-form-label text-center" style="max-width:50px; padding-left: 70px; padding-right: 50px;">〜</label>
          </div>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px;">
            <datepicker :language="ja" v-model="saigai_end" :value="saigai_end" format="yyyy/MM/dd" :typeable="true" width="100px"></datepicker>
          </div>
        </div>
        <div class="form-group row" style="width:100%; max-width:800px">
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px;">
            <p style="width:100%; max-width:130px; white-space: nowrap; margin-left:128px; color: red;">{{ this.errors.saigai_start }}</p>
          </div>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px;">
            <p style="width:100%; max-width:130px; white-space: nowrap; margin-left:250px; color: red;">{{ this.errors.saigai_end }}</p>
          </div>
        </div>
        <div class="form-group row" style="width:100%; max-width:800px">
          <label class="col-sm col-form-label" style="width:100%; max-width:130px">異常気象名</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:400px;">
            <input type="text" class="form-control bg-required" maxlength = "15" v-model="saigai_mei"/>
            <p style="width:100%; max-width:130px; white-space: nowrap; color: red;">{{ this.errors.saigai_mei }}</p>
          </div>
        </div>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <div class="form-inline float-right">
          <button v-on:click="delete_btn()" :disabled = this.disableUpDelBtn class="btn btn-light ml-3" type="submit" style="width:120px;">削除</button>
        </div>
      </div>
    </main>
  </AppLayout>
</template>