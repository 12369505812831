<template>
  <Layout>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span class="col-md-12 text-center" v-show="isHasInfo"> {{ info }} </span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasErr"  class="err">{{ this.errMsg }}</span>
      </div>
      <div class="container-flubg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <table>
              <tbody>
                <tr>
                  <td class="pr-2 pb-3 align-middle">災害年</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      style="width:100%; max-width:60px"
                      v-model="jpYear"
                      readonly
                    />
                  </td>
                  <td class="pr-5 pb-3 align-middle">年</td>
                  <td class="pr-2 pb-3 align-middle">査定</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      style="width:100%; max-width:60px"
                      v-model="satei" 
                      readonly
                    />
                  </td>
                  <td class="pb-3 align-middle">次</td>
                  <td class="pr-5 align-middle"></td>
                  <td class="pr-2 pb-3 align-middle">管理者</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      style="width:100%; max-width:60px"
                      v-model="admin_cd" 
                      readonly
                    />
                  </td>
                  <td class="pb-3 align-middle" width="400px">
                    <input
                      type="text"
                      class="form-control bg-light"
                      style="max-width:500px"
                      v-model="admin_name" 
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td class="pr-2 align-middle">工事番号</td>
                  <td class="align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      style="width:100%; max-width:60px"
                      v-model="koji_no"
                      readonly
                    />
                  </td>
                  <td class="pr-5 align-middle"></td>
                  <td class="pr-2 align-middle">分冊</td>
                  <td class="align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      style="width:100%; max-width:60px"
                       v-model="bunsatu_no"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <!--container-fluid-->

      <form>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button class="btn btn-light" type="button" style="width:120px;" @click="insertData()" :disabled="insertMode ? false: true">登録</button>
            <button class="btn btn-light ml-3" type="button" style="width:120px;" @click="updateData()" :disabled="updateMode ? false: true">更新</button>
            <button class="btn btn-light ml-3" style="width:120px;" role="button" @click="back_btn()">戻る</button>
          </div>
        </div>

        <div class="container">
          <div class="form-group row mt-3 mb-2" style="width:100%; ">
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">上部</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select class="form-control" v-model="saigaiKasenData.jyoubu_cd" v-on:change="jyoubuCodeChange($event)">
                <option value=""></option>
                <option
                  v-for="mcode in mCode36"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">下部</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select class="form-control" v-model="saigaiKasenData.kabu_cd" v-on:change="kabuCodeChange($event)">
                <option value=""></option>
                <option
                  v-for="mcode in mCode37"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="container pt-3 clearfix mt-3 border-top border-secondary">
          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">河川</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:150px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">海岸</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:150px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">砂防</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:150px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">急傾斜</p>
          </div>
        </div>

        <div class="container mt-3 pt-1">
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">河川改修</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kasen_kaisyu}}&nbsp;{{saigaiKasenData.kasen_kaisyu_name1}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">海岸保全</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_kaigan_hozen}}&nbsp;{{saigaiKasenData.kaigan_hozen_name1}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">砂 防</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_sabou}}&nbsp;{{saigaiKasenData.sabou_name1}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">区域番号</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:100px">{{saigaiKasenData.kotuko_jisuberi_no}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
              <select class="form-control" v-model="saigaiKasenData.saigai_kasen_kaisyu" v-on:change="kasenKaisyuChange($event)" :disabled="kousyu_cd != null && kousyu_cd!=1">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd==1"
                  v-for="mcode in mCode7"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
              <select class="form-control" v-model="saigaiKasenData.saigai_kaigan_hozen" v-on:change="kaiganHozenChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 2">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 2"
                  v-for="mcode in mCode6"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:285px">
              <select class="form-control" v-model="saigaiKasenData.saigai_sabou"  v-on:change="sabouChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 3">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 3"
                  v-for="mcode in mCode22"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:90px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:100px">
              <input type="tel" class="form-control" v-model="saigaiKasenData.saigai_jisuberi_no" @blur="saigai_jisuberi_noChange()" :disabled="kousyu_cd45"/>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">河なり</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kawanari_name1}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">現天端高</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_tenzui_daka}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">高 さ</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_damu_takasa}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
              <select class="form-control" v-model="saigaiKasenData.saigai_kawanari" v-on:change="kawanariChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 1">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 1"
                  v-for="mcode in mCode22"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_tenzui_daka" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 2" @focus="focusTenzui_daka()" @blur="tenzui_dakaChange" @input="inputTenzui_daka()"/>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:185px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_damu_takasa"  :maxlength="max" @blur="damu_takasaChange()" :disabled="kousyu_cd != null && kousyu_cd != 3" @focus="focusdamu_takasa()" @input="inputdamu_takasa()"/>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px"></label>
            <label for class="col-sm col-form-label" style="width:100%; max-width:180px"></label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復旧天端</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_fukyu_tenzui}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">延 長</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_damu_encyou}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px"></div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_fukyu_tenzui" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 2" @blur="fukyu_tenzuiChange()" @focus="focusfukyu_tenzui()" @input="inputfukyu_tenzui()"/>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:185px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_damu_encyou" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 3" @blur="damu_encyouChange()" @focus="focusdamu_encyou()" @input="inputdamu_encyou()"/>
            </div>
          </div>
        </div>

        <div class="container pt-3 clearfix mt-3 border-top border-secondary">
          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">道路</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:150px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">橋梁</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:150px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">下水道</p>
          </div>
        </div>

        <div class="container mt-3 pt-1">
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">路 面</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.hotuko_douro_romen}}&nbsp;{{saigaiKasenData.douro_romen_name1}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">原 橋</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_rokyouryou_harahasi}}&nbsp;{{saigaiKasenData.rokyouryou_harahasi_name1}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">種 類</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_gesuidou_syurui}}&nbsp;{{saigaiKasenData.gesuidou_syurui_name1}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
              <select class="form-control" v-model="saigaiKasenData.saigai_douro_romen" v-on:change="douroRomenChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 6">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 6"
                  v-for="mcode in mCode8"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
             <select class="form-control" v-model="saigaiKasenData.saigai_harahasi" v-on:change="rokyouryouHarahasiChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 7">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 7"
                  v-for="mcode in mCode9"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
              <select class="form-control" v-model="saigaiKasenData.saigai_gesuidou_syurui" v-on:change="gesuidouSyuruiChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 8">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 8"
                  v-for="mcode in mCode10"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">厚 さ</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_atusa}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">原橋延長</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_harahasi_encyou}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">排 除</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_haijyo_housiki}}&nbsp;{{saigaiKasenData.haijyo_housiki_name1}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_atusa" :maxlength="max" @blur="atusaChange()"  @focus="focusatusa()" @input="inputatusa()" :disabled="kousyu_cd != null && kousyu_cd != 6"/>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_harahasi_encyou" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 7" @blur="harahasi_encyouChange()" @focus="focusharahasi_encyou()" @input="inputharahasi_encyou()"/>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:285px">
              <select class="form-control" v-model="saigaiKasenData.saigai_haijyo_housiki" v-on:change="haijyoHousikiChannge($event)" :disabled="kousyu_cd != null && kousyu_cd != 8">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null || kousyu_cd == 8"
                  v-for="mcode in mCode11"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">現 員</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_gendou_habain}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">被災延長</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_harahasi_higaiencyou}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_gendou_habain" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 6" @blur="gendou_habainChange()" @focus="focusgendou_habain()" @input="inputgendou_habain()"/>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:185px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_harahasi_higaiencyou" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 7" @blur="harahasi_higaiencyouChange()" @focus="focusharahasi_higaiencyou()" @input="inputharahasi_higaiencyou()"/>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復 員</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_douro_habain}}</label>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">原橋幅員</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_harahasi_habain}}</label>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_douro_habain" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 6" @blur="douro_habainChange()" @focus="focusdouro_habain()" @input="inputdouro_habain()"/>
            </div>

            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:185px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_harahasi_habain" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 7" @blur="harahasi_habainChange()" @focus="focusharahasi_habain()" @input="inputharahasi_habain()"/>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px"></label>
            <label for class="m-0 p-0" style="width:100%; max-width:180px"></label>
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復 旧 端</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_fukyu_hasi}}</label>
          </div>
          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px"></div>
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:280px">
              <select class="form-control" v-model="saigaiKasenData.saigai_fukyu_hasi" v-on:change="fukyuHasiChange($event)" :disabled="kousyu_cd != null && kousyu_cd != 7">
                <option value=""></option>
                <option
                  v-show="kousyu_cd == null ||kousyu_cd == 7"
                  v-for="mcode in mCode9"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px"></label>
            <label for class="m-0 p-0" style="width:100%; max-width:180px"></label>
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復旧幅員</label>
            <label for class="m-0 p-0 bg-lightyellow" style="width:100%; max-width:180px">{{saigaiKasenData.kotuko_rokyouryou_habain}}</label>
          </div>
          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:180px"></div>
            <div class="col-sm pr-0 pl-3" value style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:185px">
              <input type="tel" class="form-control text-right" v-model="saigaiKasenData.saigai_rokyouryou_habain" :maxlength="max" :disabled="kousyu_cd != null && kousyu_cd != 7" @blur="rokyouryou_habainChange()" @focus="focusrokyouryou_habain()" @input="inputrokyouryou_habain()"/>
            </div>
          </div>
        </div>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button class="btn btn-light ml-3" type="button" style="width:120px;" @click="deleteData()" :disabled="updateMode ? false: true">削除</button>
          </div>
        </div>
      </form>
    </main>
  </Layout>
</template>
<script>
import Layout from '@/components/layouts/Layout.vue'
import { APIService } from "@/apiService.js";
import moment from "moment";
var numeral = require("numeral");
const apiService = new APIService();
export default {
  components: {
    Layout
  },
  data() {
    if (this.$route.query) {
      return {
        max: '10',
        insertMode: false,
        updateMode: false,
        jpYear: '',
        engYear: this.$route.query.saigai_yyyy,
        satei:'',
        bunsatu_no:  this.$route.query.bunsatu_no,
        koji_no:  this.$route.query.koji_no,
        admin_cd:  window.localStorage.getItem('Kanrisya_Cd'),
        admin_name: window.localStorage.getItem('Kanrisya_Mei'),
        saigaiKasenData: [],
        sateiData: [],
        mCode36: '',
        mCode37: '',
        mCode7: '',        
        mCode22: '',
        mCode6: '',        
        mCode8: '',       
        mCode9: '',        
        mCode10: '',
        mCode11: '', 
        kousyu_cd: '',
        kousyu_cd45: true,
        isHasInfo: false,
        info: '',
        isHasErr: false,
        errMsg: '',
        loginUser: {
          id: window.localStorage.getItem('LOGIN_ID'),
          kanrisya_cd: window.localStorage.getItem('Kanrisya_Cd'),
          kanrisya_name: window.localStorage.getItem('Kanrisya_Mei'),
        },  
        post: null,
        chk_date: ""
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.getSaigaiKasen();
    this.getMasterCheckLists();
    this.getSateiData();
    this.getGengo(this.engYear);
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    getSaigaiKasen(){
      var saigai_kasen = {
                jyoubu_cd: '',
                jyoubu_name: '',
                kabu_cd: '',
                kabu_name: '',
                kotuko_kasen_kaisyu: '',
                kasen_kaisyu_name1: '',
                saigai_kasen_kaisyu: '',
                kasen_kaisyu_name2: '',
                kotuko_kawanari: '',
                kawanari_name1: '',
                saigai_kawanari: '',
                kawanari_name2: '',
                kokuto_kaigan_hozen: '',
                kaigan_hozen_name1: '',
                saigai_kaigan_hozen: '',
                kaigan_hozen_name2: '',
                kotuko_tenzui_daka: '',
                saigai_tenzui_daka: '',
                kotuko_fukyu_tenzui: '',
                saigai_fukyu_tenzui: '',
                kotuko_sabou: '',
                sabou_name1: '',
                saigai_sabou: '',
                sabou_name2: '',
                kotuko_damu_takasa: '',
                saigai_damu_takasa: '',
                kotuko_damu_encyou: '',
                saigai_damu_encyou: '',
                kotuko_jisuberi_no: '',
                saigai_jisuberi_no: '',
                kotuko_douro_romen: '',
                douro_romen_name1: '',
                saigai_douro_romen: '',
                douro_romen_name2: '',
                kotuko_atusa: '',
                daigai_atusa: '',
                kotuko_gendou_habain: '',
                saigai_gendou_habain: '',
                kotuko_douro_habain: '',
                saigai_douro_habain: '',
                kotuko_harahasi: '',
                rokyouryou_harahasi_name1: '',
                saigai_harahasi: '',
                rokyouryou_harahasi_name2: '',
                kotuko_harahasi_encyou: '',
                saigai_harahasi_encyou: '',
                kotuko_harahasi_higaiencyou: '',
                saigai_harahasi_higaiencyou: '',
                kotuko_harahasi_habain: '',
                saigai_harahasi_habain: '',
                kotuko_fukyu_hasi: '',
                fukyu_hasi_name1: '',
                saigai_fukyu_hasi: '',
                fukyu_hasi_name2: '',
                kotuko_rokyouryou_habain: '',
                saigai_rokyouryou_habain: '',
                kotuko_gesuidou_syurui: '',
                gesuidou_syurui_name1: '',
                saigai_gesuidou_syurui: '',
                gesuidou_syurui_name2: '',
                kotuko_haijyo_housiki: '',
                haijyo_housiki_name1: '',
                saigai_haijyo_housiki: '',
                haijyo_housiki_name2: '',
        };
       var request_data = {
        bunsatu_no: this.bunsatu_no,
        koji_no: this.koji_no,
        saigai_yyyy: this.engYear,
      };
      apiService.fc103getSaigaiKasenData(request_data).then(result => {
        if(result.kasen_list.length>0){
          this.saigaiKasenData = result.kasen_list[0];
          this.updateMode = true;
          this.insertMode = false;
          this.saigaiKasenData.kotuko_tenzui_daka = this.formatNumber(this.saigaiKasenData.kotuko_tenzui_daka);
          this.saigaiKasenData.saigai_tenzui_daka = this.formatNumber(this.saigaiKasenData.saigai_tenzui_daka);
          this.saigaiKasenData.kotuko_fukyu_tenzui = this.formatDecimal(this.saigaiKasenData.kotuko_fukyu_tenzui);
          this.saigaiKasenData.saigai_fukyu_tenzui = this.formatDecimal(this.saigaiKasenData.saigai_fukyu_tenzui);
          this.saigaiKasenData.kotuko_damu_takasa = this.formatDecimal(this.saigaiKasenData.kotuko_damu_takasa);
          this.saigaiKasenData.saigai_damu_takasa = this.formatDecimal(this.saigaiKasenData.saigai_damu_takasa);
          this.saigaiKasenData.kotuko_damu_encyou = this.formatDecimal(this.saigaiKasenData.kotuko_damu_encyou);
          this.saigaiKasenData.saigai_damu_encyou = this.formatDecimal(this.saigaiKasenData.saigai_damu_encyou);
          this.saigaiKasenData.kotuko_atusa = this.formatDecimal(this.saigaiKasenData.kotuko_atusa);
          this.saigaiKasenData.saigai_atusa = this.formatDecimal(this.saigaiKasenData.saigai_atusa);
          this.saigaiKasenData.kotuko_gendou_habain = this.formatDecimal(this.saigaiKasenData.kotuko_gendou_habain);
          this.saigaiKasenData.saigai_gendou_habain = this.formatDecimal(this.saigaiKasenData.saigai_gendou_habain);
          this.saigaiKasenData.kotuko_douro_habain = this.formatDecimal(this.saigaiKasenData.kotuko_douro_habain);
          this.saigaiKasenData.saigai_douro_habain = this.formatDecimal(this.saigaiKasenData.saigai_douro_habain);
          this.saigaiKasenData.kotuko_harahasi_encyou = this.formatDecimal(this.saigaiKasenData.kotuko_harahasi_encyou);
          this.saigaiKasenData.saigai_harahasi_encyou = this.formatDecimal(this.saigaiKasenData.saigai_harahasi_encyou);
          this.saigaiKasenData.kotuko_harahasi_higaiencyou = this.formatDecimal(this.saigaiKasenData.kotuko_harahasi_higaiencyou);
          this.saigaiKasenData.saigai_harahasi_higaiencyou = this.formatDecimal(this.saigaiKasenData.saigai_harahasi_higaiencyou);
          this.saigaiKasenData.kotuko_harahasi_habain = this.formatDecimal(this.saigaiKasenData.kotuko_harahasi_habain);
          this.saigaiKasenData.saigai_harahasi_habain = this.formatDecimal(this.saigaiKasenData.saigai_harahasi_habain);
          this.saigaiKasenData.kotuko_rokyouryou_habain = this.formatDecimal(this.saigaiKasenData.kotuko_rokyouryou_habain);
          this.saigaiKasenData.saigai_rokyouryou_habain = this.formatDecimal(this.saigaiKasenData.saigai_rokyouryou_habain);
        }else{
          apiService.fc103getKotukoKasenData(request_data).then(result => {
            this.saigaiKasenData = saigai_kasen;
            this.insertMode = true;
            this.updateMode = false;
            if(result.kasen_list.length>0){
              var saigaiKasenDataRes = result.kasen_list[0];
              this.saigaiKasenData.kotuko_kasen_kaisyu = saigaiKasenDataRes.kotuko_kasen_kaisyu;
              this.saigaiKasenData.kasen_kaisyu_name1 = saigaiKasenDataRes.kasen_kaisyu_name1;
              this.saigaiKasenData.kotuko_kawanari = saigaiKasenDataRes.kotuko_kawanari;
              this.saigaiKasenData.kawanari_name1 = saigaiKasenDataRes.kawanari_name1;
              this.saigaiKasenData.kokuto_kaigan_hozen = saigaiKasenDataRes.kokuto_kaigan_hozen;
              this.saigaiKasenData.kaigan_hozen_name1 = saigaiKasenDataRes.kaigan_hozen_name1;
              this.saigaiKasenData.kotuko_sabou = saigaiKasenDataRes.kotuko_sabou;
              this.saigaiKasenData.sabou_name1 = saigaiKasenDataRes.sabou_name1;
              this.saigaiKasenData.kotuko_jisuberi_no = saigaiKasenDataRes.kotuko_jisuberi_no;
              this.saigaiKasenData.kotuko_douro_romen = saigaiKasenDataRes.kotuko_douro_romen;
              this.saigaiKasenData.douro_romen_name1 = saigaiKasenDataRes.douro_romen_name1;
              this.saigaiKasenData.kotuko_harahasi = saigaiKasenDataRes.kotuko_harahasi;
              this.saigaiKasenData.rokyouryou_harahasi_name1 = saigaiKasenDataRes.rokyouryou_harahasi_name1;
              this.saigaiKasenData.kotuko_fukyu_hasi = saigaiKasenDataRes.kotuko_fukyu_hasi;
              this.saigaiKasenData.fukyu_hasi_name1 = saigaiKasenDataRes.fukyu_hasi_name1;
              this.saigaiKasenData.kotuko_gesuidou_syurui = saigaiKasenDataRes.kotuko_gesuidou_syurui;
              this.saigaiKasenData.gesuidou_syurui_name1 = saigaiKasenDataRes.gesuidou_syurui_name1;
              this.saigaiKasenData.kotuko_haijyo_housiki = saigaiKasenDataRes.kotuko_haijyo_housiki;
              this.saigaiKasenData.haijyo_housiki_name1 = saigaiKasenDataRes.haijyo_housiki_name1;
              this.saigaiKasenData.kotuko_tenzui_daka = this.formatNumber(saigaiKasenDataRes.kotuko_tenzui_daka);
              this.saigaiKasenData.kotuko_fukyu_tenzui = this.formatDecimal(saigaiKasenDataRes.kotuko_fukyu_tenzui);
              this.saigaiKasenData.kotuko_damu_takasa = this.formatDecimal(saigaiKasenDataRes.kotuko_damu_takasa);
              this.saigaiKasenData.kotuko_damu_encyou = this.formatDecimal(saigaiKasenDataRes.kotuko_damu_encyou);
              this.saigaiKasenData.kotuko_atusa = this.formatDecimal(saigaiKasenDataRes.kotuko_atusa);
              this.saigaiKasenData.kotuko_gendou_habain = this.formatDecimal(saigaiKasenDataRes.kotuko_gendou_habain);
              this.saigaiKasenData.kotuko_douro_habain = this.formatDecimal(saigaiKasenDataRes.kotuko_douro_habain);
              this.saigaiKasenData.kotuko_harahasi_encyou = this.formatDecimal(saigaiKasenDataRes.kotuko_harahasi_encyou);
              this.saigaiKasenData.kotuko_harahasi_higaiencyou = this.formatDecimal(saigaiKasenDataRes.kotuko_harahasi_higaiencyou);
              this.saigaiKasenData.kotuko_harahasi_habain = this.formatDecimal(saigaiKasenDataRes.kotuko_harahasi_habain);
              this.saigaiKasenData.kotuko_rokyouryou_habain = this.formatDecimal(saigaiKasenDataRes.kotuko_rokyouryou_habain);
            }
          });
        }

        this.chk_date = result.chk_date; // 排他チェック用
      });
    },
    getSateiData(){
       var request_data = {
        bunsatu_no: this.bunsatu_no,
        koji_no: this.koji_no,
        saigai_yyyy: this.engYear,
      };
      apiService.fc103getSateiData(request_data).then(result => {
        if(result.length>0){
          this.satei = result[0].nji_satei;
          this.kousyu_cd = result[0].kousyu_cd;
          if(this.kousyu_cd == null || this.kousyu_cd == 4 || this.kousyu_cd == 5)
          {
            this.kousyu_cd45 = false;
          }
        }
      });
    },
    removeComma(){
      this.saigaiKasenData.kotuko_tenzui_daka = this.saigaiKasenData.kotuko_tenzui_daka != null ? this.saigaiKasenData.kotuko_tenzui_daka.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_tenzui_daka = this.saigaiKasenData.saigai_tenzui_daka != null ? this.saigaiKasenData.saigai_tenzui_daka.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_fukyu_tenzui = this.saigaiKasenData.kotuko_fukyu_tenzui != null ? this.saigaiKasenData.kotuko_fukyu_tenzui.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_fukyu_tenzui = this.saigaiKasenData.saigai_fukyu_tenzui != null ? this.saigaiKasenData.saigai_fukyu_tenzui.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_damu_takasa = this.saigaiKasenData.kotuko_damu_takasa != null ? this.saigaiKasenData.kotuko_damu_takasa.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_damu_takasa = this.saigaiKasenData.saigai_damu_takasa != null ? this.saigaiKasenData.saigai_damu_takasa.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_damu_encyou = this.saigaiKasenData.kotuko_damu_encyou != null ? this.saigaiKasenData.kotuko_damu_encyou.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_damu_encyou = this.saigaiKasenData.saigai_damu_encyou != null ? this.saigaiKasenData.saigai_damu_encyou.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_atusa = this.saigaiKasenData.kotuko_atusa != null ? this.saigaiKasenData.kotuko_atusa.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_atusa = this.saigaiKasenData.saigai_atusa != null ? this.saigaiKasenData.saigai_atusa.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_gendou_habain = this.saigaiKasenData.kotuko_gendou_habain != null ? this.saigaiKasenData.kotuko_gendou_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_gendou_habain = this.saigaiKasenData.saigai_gendou_habain != null ? this.saigaiKasenData.saigai_gendou_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_douro_habain = this.saigaiKasenData.kotuko_douro_habain != null ? this.saigaiKasenData.kotuko_douro_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_douro_habain = this.saigaiKasenData.saigai_douro_habain != null ? this.saigaiKasenData.saigai_douro_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_harahasi_encyou = this.saigaiKasenData.kotuko_harahasi_encyou != null ? this.saigaiKasenData.kotuko_harahasi_encyou.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_harahasi_encyou = this.saigaiKasenData.saigai_harahasi_encyou != null ? this.saigaiKasenData.saigai_harahasi_encyou.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_harahasi_higaiencyou = this.saigaiKasenData.kotuko_harahasi_higaiencyou != null ? this.saigaiKasenData.kotuko_harahasi_higaiencyou.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_harahasi_higaiencyou = this.saigaiKasenData.saigai_harahasi_higaiencyougg != null ? this.saigaiKasenData.saigai_harahasi_higaiencyou.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_harahasi_habain = this.saigaiKasenData.kotuko_harahasi_habain != null ? this.saigaiKasenData.kotuko_harahasi_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_harahasi_habain = this.saigaiKasenData.saigai_harahasi_habain != null ? this.saigaiKasenData.saigai_harahasi_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.kotuko_rokyouryou_habain = this.saigaiKasenData.kotuko_rokyouryou_habain != null ? this.saigaiKasenData.kotuko_rokyouryou_habain.toString().trim().replace(/,/g,'') : '';
      this.saigaiKasenData.saigai_rokyouryou_habain = this.saigaiKasenData.saigai_rokyouryou_habain != null ? this.saigaiKasenData.saigai_rokyouryou_habain.toString().trim().replace(/,/g,'') : '';
    },
    insertData(){
      this.removeComma();
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        bunsatu_no: this.bunsatu_no,
        saigaiKasenData: this.saigaiKasenData,
        loginId: this.loginUser.id,
        chk_date: this.chk_date
      };
      apiService.fc103insertData(request_data).then(result => {
          this.isHasInfo = true;
          this.isHasErr = false;
          this.info = result;
          this.getSaigaiKasen();
      }).catch(error => {
        confirm(this.$messages.msg['0013E']);
        this.isHasInfo = false;
        this.isHasErr = true;
        this.errMsg = error;
      });
    },
    updateData(){
      this.removeComma();
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        bunsatu_no: this.bunsatu_no,
        saigaiKasenData: this.saigaiKasenData,
        loginId: this.loginUser.id,
        chk_date: this.chk_date
      };
      apiService.fc103updateData(request_data).then(result => {
          this.isHasInfo = true;
          this.isHasErr = false;
          this.info = result;
          this.getSaigaiKasen();
      }).catch(error => {
        confirm(this.$messages.msg['0013E']);
        this.isHasInfo = false;
        this.isHasErr = true;
        this.errMsg = error;
      });
    },
    deleteData(){
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        bunsatu_no: this.bunsatu_no,
        loginId: this.loginUser.id,
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService.fc103deleteData(request_data).then(result => {
          this.isHasInfo = true;  
            this.isHasErr = false;
            this.info = result;
            this.saigaiKasenData = this.saigai_kasen;
            this.getSaigaiKasen();
        }).catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.isHasInfo = false;
          this.isHasErr = true;
          this.errMsg = error;
        });
      }
    },
    jyoubuCodeChange(index){
      var jyoubu_cd = index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode36.length; i++){
        if(jyoubu_cd == this.mCode36[i].meisai){
          this.saigaiKasenData.jyoubu_cd = jyoubu_cd;
          this.saigaiKasenData.jyoubu_name = this.mCode36[i].cd_value;
        }
      }
    },
    kabuCodeChange(index){
      var kabu_cd =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode37.length; i++){
        if(kabu_cd == this.mCode37[i].meisai){
          this.saigaiKasenData.kabu_cd = kabu_cd;
          this.saigaiKasenData.kabu_name = this.mCode37[i].cd_value;
        }
      }
    },
    kasenKaisyuChange(index){
      var kasen_kaisyu =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode7.length; i++){
        if(kasen_kaisyu == this.mCode7[i].meisai){
          this.saigaiKasenData.saigai_kasen_kaisyu = kasen_kaisyu;
          this.saigaiKasenData.kasen_kaisyu_name2 = this.mCode7[i].cd_value;
        }
      }
    },
    kaiganHozenChange(index){
      var kaigan_hozen =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode6.length; i++){
        if(kaigan_hozen == this.mCode6[i].meisai){
          this.saigaiKasenData.saigai_kaigan_hozen = kaigan_hozen;
          this.saigaiKasenData.kaigan_hozen_name2 = this.mCode6[i].cd_value;
        }
      }
    },
    sabouChange(index){
      var sabou =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode22.length; i++){
        if(sabou == this.mCode22[i].meisai){
          this.saigaiKasenData.saigai_sabou = sabou;
          this.saigaiKasenData.sabou_name2 = this.mCode22[i].cd_value;
        }
      }
    },
    kawanariChange(index){
      var kawanari =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode22.length; i++){
        if(kawanari == this.mCode22[i].meisai){
          this.saigaiKasenData.saigai_kawanari = kawanari;
          this.saigaiKasenData.kawanari_name2 = this.mCode22[i].cd_value;
        }
      }
    },
    rokyouryouHarahasiChange(index){
      var rokyouryou_harahasi =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode9.length; i++){
        if(rokyouryou_harahasi == this.mCode9[i].meisai){
          this.saigaiKasenData.saigai_rokyouryou_harahasi = rokyouryou_harahasi;
          this.saigaiKasenData.rokyouryou_harahasi_name2 = this.mCode9[i].cd_value;
        }
      }
    },
    douroRomenChange(index){
      var douro_romen =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode8.length; i++){
        if(douro_romen == this.mCode8[i].meisai){
          this.saigaiKasenData.saigai_douro_romen = douro_romen;
          this.saigaiKasenData.douro_romen_name2 = this.mCode8[i].cd_value;
        }
      }
    },
    gesuidouSyuruiChange(index){
      var gesuidou_syurui =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode10.length; i++){
        if(gesuidou_syurui == this.mCode10[i].meisai){
          this.saigaiKasenData.saigai_gesuidou_syurui = gesuidou_syurui;
          this.saigaiKasenData.gesuidou_syurui_name2 = this.mCode10[i].cd_value;
        }
      }
    },
    haijyoHousikiChannge(index){
      var haijyo_housiki =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode11.length; i++){
        if(haijyo_housiki == this.mCode11[i].meisai){
          this.saigaiKasenData.saigai_haijyo_housiki = haijyo_housiki;
          this.saigaiKasenData.haijyo_housiki_name2 = this.mCode11[i].cd_value;
        }
      }
    },
    fukyuHasiChange(index){
      var fukyu_hasi =  index.target.options[index.target.options.selectedIndex].text;
      for(var i = 0; i < this.mCode9.length; i++){
        if(fukyu_hasi == this.mCode9[i].meisai){
          this.saigaiKasenData.saigai_fukyu_hasi = fukyu_hasi;
          this.saigaiKasenData.fukyu_hasi_name2 = this.mCode9[i].cd_value;
        }
      }
    },
    getMasterCheckLists(){
      apiService.fc103getMasterCheckLists().then(result => {
        this.mCode36 = result.mCode36;
        this.mCode37 = result.mCode37;
        this.mCode27 = result.mCode27;
        this.mCode7 = result.mCode7;
        this.mCode22 = result.mCode22;
        this.mCode6 = result.mCode6;
        this.mCode8 = result.mCode8;
        this.mCode9 = result.mCode9;
        this.mCode10 = result.mCode10;
        this.mCode11 = result.mCode11;
      });
    },
    
    focusTenzui_daka(){
      if(this.saigaiKasenData.saigai_tenzui_daka != null && this.kousyu_cd == 2){
        this.saigaiKasenData.saigai_tenzui_daka = this.saigaiKasenData.saigai_tenzui_daka.trim().replace(/,/g,'');
      }
    },
    inputTenzui_daka(){
      this.max = this.saigaiKasenData.saigai_tenzui_daka.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_tenzui_daka.length > 9 && this.saigaiKasenData.saigai_tenzui_daka.charAt(9) != ".") {
        this.saigaiKasenData.saigai_tenzui_daka = this.saigaiKasenData.saigai_tenzui_daka.substring(0, 9);
      }
    },
    tenzui_dakaChange(){
      this.saigaiKasenData.saigai_tenzui_daka = this.replaceComma(this.saigaiKasenData.saigai_tenzui_daka);
      if (this.isInteger(this.saigaiKasenData.saigai_tenzui_daka)) {
        this.saigaiKasenData.saigai_tenzui_daka = this.saigaiKasenData.saigai_tenzui_daka
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusfukyu_tenzui(){
      if(this.saigaiKasenData.saigai_fukyu_tenzui != null && this.kousyu_cd == 2){
        this.saigaiKasenData.saigai_fukyu_tenzui = this.saigaiKasenData.saigai_fukyu_tenzui.trim().replace(/,/g,'');
      }
    },
    inputfukyu_tenzui(){
      this.max = this.saigaiKasenData.saigai_fukyu_tenzui.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_fukyu_tenzui.length > 9 && this.saigaiKasenData.saigai_fukyu_tenzui.charAt(9) != ".") {
        this.saigaiKasenData.saigai_fukyu_tenzui = this.saigaiKasenData.saigai_fukyu_tenzui.substring(0, 9);
      }
    },
    fukyu_tenzuiChange(){
      this.saigaiKasenData.saigai_fukyu_tenzui = this.replaceComma(this.saigaiKasenData.saigai_fukyu_tenzui);
      if (this.isFloat(this.saigaiKasenData.saigai_fukyu_tenzui)) {
        this.saigaiKasenData.saigai_fukyu_tenzui = this.saigaiKasenData.saigai_fukyu_tenzui
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusdamu_takasa(){
      if(this.saigaiKasenData.saigai_damu_takasa != null && this.kousyu_cd == 3){
        this.saigaiKasenData.saigai_damu_takasa = this.saigaiKasenData.saigai_damu_takasa.trim().replace(/,/g,'');
      }
    },
    inputdamu_takasa(){
      this.max = this.saigaiKasenData.saigai_damu_takasa.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_damu_takasa.length > 9 && this.saigaiKasenData.saigai_damu_takasa.charAt(9) != ".") {
        this.saigaiKasenData.saigai_damu_takasa = this.saigaiKasenData.saigai_damu_takasa.substring(0, 9);
      }
    },
    damu_takasaChange(){
      this.saigaiKasenData.saigai_damu_takasa = this.replaceComma(this.saigaiKasenData.saigai_damu_takasa);
      if (this.isFloat(this.saigaiKasenData.saigai_damu_takasa)) {
        this.saigaiKasenData.saigai_damu_takasa = this.saigaiKasenData.saigai_damu_takasa
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
   focusdamu_encyou(){
      if(this.saigaiKasenData.saigai_damu_encyou != null && this.kousyu_cd == 3){
        this.saigaiKasenData.saigai_damu_encyou = this.saigaiKasenData.saigai_damu_encyou.trim().replace(/,/g,'');
      }
    },
    inputdamu_encyou(){
      this.max = this.saigaiKasenData.saigai_damu_encyou.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_damu_encyou.length > 9 && this.saigaiKasenData.saigai_damu_encyou.charAt(9) != ".") {
        this.saigaiKasenData.saigai_damu_encyou = this.saigaiKasenData.saigai_damu_encyou.substring(0, 9);
      }
    },
    damu_encyouChange(){
      this.saigaiKasenData.saigai_damu_encyou = this.replaceComma(this.saigaiKasenData.saigai_damu_encyou);
      if (this.isFloat(this.saigaiKasenData.saigai_damu_encyou)) {
        this.saigaiKasenData.saigai_damu_encyou = this.saigaiKasenData.saigai_damu_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusgendou_habain(){
      if(this.saigaiKasenData.saigai_gendou_habain != null  && this.kousyu_cd == 6){
        this.saigaiKasenData.saigai_gendou_habain = this.saigaiKasenData.saigai_gendou_habain.trim().replace(/,/g,'');
      }
    },
    inputgendou_habain(){
      this.max = this.saigaiKasenData.saigai_gendou_habain.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_gendou_habain.length > 9 && this.saigaiKasenData.saigai_gendou_habain.charAt(9) != ".") {
        this.saigaiKasenData.saigai_gendou_habain = this.saigaiKasenData.saigai_gendou_habain.substring(0, 9);
      }
    },
    gendou_habainChange(){
      this.saigaiKasenData.saigai_gendou_habain = this.replaceComma(this.saigaiKasenData.saigai_gendou_habain);
      if (this.isFloat(this.saigaiKasenData.saigai_gendou_habain)) {
        this.saigaiKasenData.saigai_gendou_habain = this.saigaiKasenData.saigai_gendou_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusdouro_habain(){
      if(this.saigaiKasenData.saigai_douro_habain != null && this.kousyu_cd == 6){
        this.saigaiKasenData.saigai_douro_habain = this.saigaiKasenData.saigai_douro_habain.trim().replace(/,/g,'');
      }
    },
    inputdouro_habain(){
      this.max = this.saigaiKasenData.saigai_douro_habain.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_douro_habain.length > 9 && this.saigaiKasenData.saigai_douro_habain.charAt(9) != ".") {
        this.saigaiKasenData.saigai_douro_habain = this.saigaiKasenData.saigai_douro_habain.substring(0, 9);
      }
    },
    douro_habainChange(){
      this.saigaiKasenData.saigai_douro_habain = this.replaceComma(this.saigaiKasenData.saigai_douro_habain);
      if (this.isFloat(this.saigaiKasenData.saigai_douro_habain)) {
        this.saigaiKasenData.saigai_douro_habain = this.saigaiKasenData.saigai_douro_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusharahasi_encyou(){
      if(this.saigaiKasenData.saigai_harahasi_encyou != null && this.kousyu_cd == 7){
        this.saigaiKasenData.saigai_harahasi_encyou = this.saigaiKasenData.saigai_harahasi_encyou.trim().replace(/,/g,'');
      }
    },
    inputharahasi_encyou(){
      this.max = this.saigaiKasenData.saigai_harahasi_encyou.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_harahasi_encyou.length > 9 && this.saigaiKasenData.saigai_harahasi_encyou.charAt(9) != ".") {
        this.saigaiKasenData.saigai_harahasi_encyou = this.saigaiKasenData.saigai_harahasi_encyou.substring(0, 9);
      }
    },
    harahasi_encyouChange(){
      this.saigaiKasenData.saigai_harahasi_encyou = this.replaceComma(this.saigaiKasenData.saigai_harahasi_encyou);
      if (this.isFloat(this.saigaiKasenData.saigai_harahasi_encyou)) {
        this.saigaiKasenData.saigai_harahasi_encyou = this.saigaiKasenData.saigai_harahasi_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusharahasi_higaiencyou(){
      if(this.saigaiKasenData.saigai_harahasi_higaiencyou != null  && this.kousyu_cd == 7){
        this.saigaiKasenData.saigai_harahasi_higaiencyou = this.saigaiKasenData.saigai_harahasi_higaiencyou.trim().replace(/,/g,'');
      }
    },
    inputharahasi_higaiencyou(){
      this.max = this.saigaiKasenData.saigai_harahasi_higaiencyou.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_harahasi_higaiencyou.length > 9 && this.saigaiKasenData.saigai_harahasi_higaiencyou.charAt(9) != ".") {
        this.saigaiKasenData.saigai_harahasi_higaiencyou = this.saigaiKasenData.saigai_harahasi_higaiencyou.substring(0, 9);
      }
    },
    harahasi_higaiencyouChange(){
      this.saigaiKasenData.saigai_harahasi_higaiencyou = this.replaceComma(this.saigaiKasenData.saigai_harahasi_higaiencyou);
      if (this.isFloat(this.saigaiKasenData.saigai_harahasi_higaiencyou)) {
        this.saigaiKasenData.saigai_harahasi_higaiencyou = this.saigaiKasenData.saigai_harahasi_esaigai_harahasi_higaiencyouncyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusharahasi_habain(){
      if(this.saigaiKasenData.saigai_harahasi_habain != null && this.kousyu_cd == 7){
        this.saigaiKasenData.saigai_harahasi_habain = this.saigaiKasenData.saigai_harahasi_habain.trim().replace(/,/g,'');
      }
    },
    inputharahasi_habain(){
      this.max = this.saigaiKasenData.saigai_harahasi_habain.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_harahasi_habain.length > 9 && this.saigaiKasenData.saigai_harahasi_habain.charAt(9) != ".") {
        this.saigaiKasenData.saigai_harahasi_habain = this.saigaiKasenData.saigai_harahasi_habain.substring(0, 9);
      }
    },
    harahasi_habainChange(){
      this.saigaiKasenData.saigai_harahasi_habain = this.replaceComma(this.saigaiKasenData.saigai_harahasi_habain);
      if (this.isFloat(this.saigaiKasenData.saigai_harahasi_habain)) {
        this.saigaiKasenData.saigai_harahasi_habain = this.saigaiKasenData.saigai_harahasi_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusrokyouryou_habain(){
      if(this.saigaiKasenData.saigai_rokyouryou_habain != null && this.kousyu_cd == 7){
        this.saigaiKasenData.saigai_rokyouryou_habain = this.saigaiKasenData.saigai_rokyouryou_habain.trim().replace(/,/g,'');
      }
    },
    inputrokyouryou_habain(){
      this.max = this.saigaiKasenData.saigai_rokyouryou_habain.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_rokyouryou_habain.length > 9 && this.saigaiKasenData.saigai_rokyouryou_habain.charAt(9) != ".") {
        this.saigaiKasenData.saigai_rokyouryou_habain = this.saigaiKasenData.saigai_rokyouryou_habain.substring(0, 9);
      }
    },
    rokyouryou_habainChange(){
      this.saigaiKasenData.saigai_rokyouryou_habain = this.replaceComma(this.saigaiKasenData.saigai_rokyouryou_habain);
      if (this.isFloat(this.saigaiKasenData.saigai_rokyouryou_habain)) {
        this.saigaiKasenData.saigai_rokyouryou_habain = this.saigaiKasenData.saigai_rokyouryou_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusatusa(){
      if(this.saigaiKasenData.saigai_atusa != null && this.kousyu_cd == 6){
        this.saigaiKasenData.saigai_atusa = this.saigaiKasenData.saigai_atusa.trim().replace(/,/g,'');
      }
    },
    inputatusa(){
      this.max = this.saigaiKasenData.saigai_atusa.indexOf(".") > 0 ? 11 : 10;
      if (this.saigaiKasenData.saigai_atusa.length > 9 && this.saigaiKasenData.saigai_atusa.charAt(9) != ".") {
        this.saigaiKasenData.saigai_atusa = this.saigaiKasenData.saigai_atusa.substring(0, 9);
      }
    },
    atusaChange(){
      this.saigaiKasenData.saigai_atusa = this.replaceComma(this.saigaiKasenData.saigai_atusa);
      if (this.isFloat(this.saigaiKasenData.saigai_atusa)) {
        this.saigaiKasenData.saigai_atusa = this.saigaiKasenData.saigai_atusa
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigai_jisuberi_noChange(){
      this.saigaiKasenData.saigai_jisuberi_no = this.replaceComma(this.saigaiKasenData.saigai_jisuberi_no);
      if (this.isInteger(this.saigaiKasenData.saigai_jisuberi_no)) {
        this.saigaiKasenData.saigai_jisuberi_no = this.saigaiKasenData.saigai_jisuberi_no
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatDate: function(date) {
      if(date != '' && date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
      return '';
    },
    formatInteger: function(number){
      if(number == '' || number == null){
        return '';
      }
      var result = numeral(number).format("0");
      
      return result == 0 ? '': result;
    },
    formatDecimal: function(number) {
      return number=='' || number==null || numeral(number).format("0,0.0") == 0 ? '' : numeral(number).format("0,0.0");
    },
    formatNumber: function(number){
      if(number == '' || number == null){
        return '';
      }
      var result = numeral(number).format("0,0");
      
      return result == 0 ? '': result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    }
  },
};
</script>