<template>
  <Layout>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="err" class="err">{{ err }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasErr" class="err">{{ this.errMsg }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:700px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="jpYear" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:150px"
              >被害報告番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:105px">
                <input
                  type="text"
                  class="form-control bg-light"
                  v-model="higai_houkoku_cd"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
              <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:105px">
                <input type="text" class="form-control bg-light" v-model="ken" readonly />
              </div>
              <label for class="col-sm col-form-label" style="width:100%; max-width:125px">異常気象名</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
                <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >災害発生日</label>
              <div class="col-sm pr-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_start" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-center"
                style="width:100%; max-width:50px"
              >〜</label>
              <div class="col-sm pl-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_end" readonly />
              </div>
            </div>

            <div class="form-group row mb-0" style="width:55%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_cd" readonly />
              </div>
              <div class="col-sm pr-0 pl-1" style="width:100%; max-width:500px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_mei" readonly />
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix">
          <div class="rounded bg-success p-2 text-center float-left" style="width:130px;">
            <p class="m-0 p-0 text-white font-weight-bold">人的被害</p>
          </div>
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              style="width:120px;"
              @click="updateData()"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="back_btn()"
            >戻る</button>
          </div>
        </div>
        <div class="container table-responsive mt-3">
          <table class="table table-sm border" style>
            <thead class="table-primary border-top border">
              <tr>
                <th scope="col" class="align-middle text-center border" width="10%">区分</th>
                <th scope="col" colspan class="align-middle text-center border" width="10%">人数</th>
                <th scope="col" colspan class="align-middle text-center border" width="25%">市町村1</th>
                <th scope="col" colspan class="align-middle text-center border" width="25%">市町村2</th>
                <th scope="col" colspan class="align-middle text-center border" width="15%">原因1</th>
                <th scope="col" colspan class="align-middle text-center border" width="15%">原因2</th>
              </tr>
            </thead>
            <tbody v-for="(jinteki,index) in jintekiList" :key="index" :value="index">
              <tr>
                <th
                  scope="row"
                  class="align-middle table-primary border"
                  width="10%"
                >{{jintekiName[index]}}</th>
                <td class="align-middle border" width="10%">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    v-model="jinteki.inzu"
                    @focus="focusJInzu(index)"
                    style
                    maxlength="9"
                    @blur="inzuChangeJ(index)"
                  />
                </td>
                <td class="align-middle border" width="25%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control"
                      v-model="jinteki.basyo_cd1"
                      style="width:30%;"
                      v-on:change="shcyousonChangeJ1($event,index)"
                    >
                      <option
                        v-for="shcyouson in shcyousonList"
                        :key="shcyouson.shicyou_cd"
                        :value="shcyouson.shicyou_cd"
                      >{{shcyouson.shicyou_cd}}</option>
                    </select>
                    <input
                      type="text"
                      class="form-control bg-light p-1 flex-fill"
                      v-model="jinteki.basyo_name1"
                      style="width:70%"
                      readonly
                    />
                  </div>
                </td>
                <td class="align-middle border" width="25%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control"
                      v-model="jinteki.basyo_cd2"
                      style="width:30%;"
                      v-on:change="shcyousonChangeJ2($event,index)"
                    >
                      <option
                        v-for="shcyouson in shcyousonList"
                        :key="shcyouson.shicyou_cd"
                        :value="shcyouson.shicyou_cd"
                      >{{shcyouson.shicyou_cd}}</option>
                    </select>
                    <input
                      type="text"
                      class="form-control bg-light p-1 flex-fill"
                      v-model="jinteki.basyo_name2"
                      style="width:70%"
                      readonly
                    />
                  </div>
                </td>
                <td class="align-middle border" width="15%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control flex-fill"
                      v-model="jinteki.genin1"
                      style="width:30%;"
                      v-on:change="geninChangeJ1($event,index)"
                    >
                      <option
                        v-for="genin in geninList"
                        :key="genin.meisai"
                        :value="genin.meisai"
                      >{{genin.cd_value}}</option>
                    </select>
                  </div>
                </td>
                <td class="align-middle border" width="15%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control flex-fill"
                      v-model="jinteki.genin2"
                      style="width:30%;"
                      v-on:change="geninChangeJ2($event,index)"
                    >
                      <option
                        v-for="genin in geninList"
                        :key="genin.meisai"
                        :value="genin.meisai"
                      >{{genin.cd_value}}</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container pt-3">
          <div class="rounded bg-success p-2 text-center" style="width:130px;">
            <p class="m-0 p-0 text-white font-weight-bold">住家被害</p>
          </div>
        </div>
        <div class="container table-responsive mt-3">
          <table class="table table-sm border" style="width:85%">
            <thead class="table-primary border-top border">
              <tr>
                <th scope="col" class="align-middle text-center border" width="10%">区分</th>
                <th scope="col" colspan class="align-middle text-center border" width="10%">人数</th>
                <th scope="col" colspan class="align-middle text-center border" width="25%">市町村1</th>
                <th scope="col" colspan class="align-middle text-center border" width="25%">市町村2</th>
                <th scope="col" colspan class="align-middle text-center border" width="15%">原因1</th>
              </tr>
            </thead>
            <tbody v-for="(jyuka,index) in jyukaList" :key="jyuka.renban" :value="index">
              <tr>
                <th
                  scope="row"
                  class="align-middle table-primary border"
                  width="10%"
                >{{jyukaName[index]}}</th>
                <td class="align-middle border" width="10%">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    v-model="jyuka.inzu"
                    maxlength="9"
                    @focus="focusGInzu(index)"
                    @blur="inzuChangeG(index)"
                  />
                </td>
                <td class="align-middle border" width="25%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control"
                      v-model="jyuka.basyo_cd1"
                      style="width:30%;"
                      v-on:change="shcyousonChangeG1($event,index)"
                    >
                      <option
                        v-for="shcyouson in shcyousonList"
                        :key="shcyouson.shicyou_cd"
                        :value="shcyouson.shicyou_cd"
                      >{{shcyouson.shicyou_cd}}</option>
                    </select>
                    <input
                      type="text"
                      class="form-control bg-light p-1 flex-fill"
                      v-model="jyuka.basyo_name1"
                      style="width:70%"
                      readonly
                    />
                  </div>
                </td>
                <td class="align-middle border" width="25%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control"
                      v-model="jyuka.basyo_cd2"
                      style="width:30%;"
                      v-on:change="shcyousonChangeG2($event,index)"
                    >
                      <option
                        v-for="shcyouson in shcyousonList"
                        :key="shcyouson.shicyou_cd"
                        :value="shcyouson.shicyou_cd"
                      >{{shcyouson.shicyou_cd}}</option>
                    </select>
                    <input
                      type="text"
                      class="form-control bg-light p-1 flex-fill"
                      v-model="jyuka.basyo_name2"
                      style="width:70%"
                      readonly
                    />
                  </div>
                </td>
                <td class="align-middle border" width="15%">
                  <div class="form-inline align-items-center">
                    <select
                      class="form-control flex-fill"
                      v-model="jyuka.genin1"
                      style="width:30%;"
                      v-on:change="geninChangeG1($event,index)"
                    >
                      <option
                        v-for="genin in geninList"
                        :key="genin.meisai"
                        :value="genin.meisai"
                      >{{genin.cd_value}}</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </main>
  </Layout>
</template>
<script>
import moment from "moment";
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
const apiService = new APIService();
var numeral = require("numeral");

export default {
  components: {
    Layout
  },
  data() {
    if (this.$route.query) {
      return {
        engYear: this.$route.query.saigai_yyyy,
        ken: this.$route.query.saigai_ken,
        higai_houkoku_cd: this.$route.query.houkokusya_cd,
        kanrisya_cd: this.$route.query.kanrisya_cd,
        kanrisya_mei: window.localStorage.getItem("Kanrisya_Mei"),
        info: "",
        err: "",
        jpYear: "",
        saigai_end: "",
        saigai_start: "",
        saigai_mei: "",
        isHasErr: false,
        errMsg: "",
        shichou_index: "",
        shcyousonList: [],
        geninList: [],
        jintekiName: [],
        jyukaName: [],
        jintekiList: [],
        jyukaList: [],
        loginUser: {
          id: window.localStorage.getItem("LOGIN_ID"),
          kanrisya_cd: window.localStorage.getItem("Kanrisya_Cd"),
          kanrisya_name: window.localStorage.getItem("Kanrisya_Mei"),
          shicyou_kbn:
            window.localStorage.getItem("shicyou_kbn") == 2 ? "2" : "1"
        },
        post: null
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.isMsgShow = false;
    this.getGengo(this.engYear);
    this.jintekiName = this.$constants.FA105_JINTEKI_NAMES;
    this.jyukaName = this.$constants.FA105_JYUKA_NAMES;
    this.fa101GetSaigaimeisyou(this.engYear);
    this.getShcyousonLit();
    this.getGeninList();
    this.getJintekiList();
    this.getJyukaList();
  },
  filters: {
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number) {
      return numeral(number).format("0,0");
    }
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    fa101GetSaigaimeisyou(year) {
      var request_data = {
        year: year,
        ken: this.ken
      };
      apiService.getSaigaiMeisyou(request_data).then(result => {
        this.saigai_mei = result[0].saigai_mei;
        this.saigai_start = this.formatDate(result[0].saigai_start);
        this.saigai_end = this.formatDate(result[0].saigai_end);
      });
    },
    shcyousonChangeJ1(shcyouson, index) {
      this.info = "";
      this.err = "";
      this.shichou_index =
        shcyouson.target.options[shcyouson.target.options.selectedIndex].text;
      for (var i = 0; i < this.shcyousonList.length; i++) {
        if (this.shcyousonList[i].shicyou_cd == this.shichou_index) {
          this.jintekiList[index].basyo_name1 = this.shcyousonList[
            i
          ].shicyou_mei;
          this.jintekiList[index].basyo_cd1 = this.shichou_index;
        }
      }
    },
    shcyousonChangeJ2(shcyouson, index) {
      this.info = "";
      this.err = "";
      this.shichou_index =
        shcyouson.target.options[shcyouson.target.options.selectedIndex].text;
      for (var i = 0; i < this.shcyousonList.length; i++) {
        if (this.shcyousonList[i].shicyou_cd == this.shichou_index) {
          this.jintekiList[index].basyo_name2 = this.shcyousonList[
            i
          ].shicyou_mei;
          this.jintekiList[index].basyo_cd2 = this.shichou_index;
        }
      }
    },
    shcyousonChangeG1(shcyouson, index) {
      this.info = "";
      this.err = "";
      this.shichou_index =
        shcyouson.target.options[shcyouson.target.options.selectedIndex].text;
      for (var i = 0; i < this.shcyousonList.length; i++) {
        if (this.shcyousonList[i].shicyou_cd == this.shichou_index) {
          this.jyukaList[index].basyo_name1 = this.shcyousonList[i].shicyou_mei;
          this.jyukaList[index].basyo_cd1 = this.shichou_index;
        }
      }
    },
    shcyousonChangeG2(shcyouson, index) {
      this.info = "";
      this.err = "";
      this.shichou_index =
        shcyouson.target.options[shcyouson.target.options.selectedIndex].text;
      for (var i = 0; i < this.shcyousonList.length; i++) {
        if (this.shcyousonList[i].shicyou_cd == this.shichou_index) {
          this.jyukaList[index].basyo_name2 = this.shcyousonList[i].shicyou_mei;
          this.jyukaList[index].basyo_cd2 = this.shichou_index;
        }
      }
    },
    geninChangeJ1(genin, index) {
      this.info = "";
      this.err = "";
      this.genin_index =
        genin.target.options[genin.target.options.selectedIndex].text;
      for (var i = 0; i < this.geninList.length; i++) {
        if (this.geninList[i].cd_value == this.genin_index) {
          this.jintekiList[index].genin1 = this.geninList[i].meisai;
        }
      }
    },
    geninChangeJ2(genin, index) {
      this.info = "";
      this.err = "";
      this.genin_index =
        genin.target.options[genin.target.options.selectedIndex].text;
      for (var i = 0; i < this.geninList.length; i++) {
        if (this.geninList[i].cd_value == this.genin_index) {
          this.jintekiList[index].genin2 = this.geninList[i].meisai;
        }
      }
    },
    geninChangeG1(genin, index) {
      this.info = "";
      this.err = "";
      this.genin_index =
        genin.target.options[genin.target.options.selectedIndex].text;
      for (var i = 0; i < this.geninList.length; i++) {
        if (this.geninList[i].cd_value == this.genin_index) {
          this.jyukaList[index].genin1 = this.geninList[i].meisai;
        }
      }
    },
    getJintekiList() {
      var request_data = {
        year: this.engYear,
        ken: this.ken,
        houkokusya_cd: this.higai_houkoku_cd,
        kanrisya_cd: this.kanrisya_cd
      };
      apiService.fa105getJintekiList(request_data).then(result => {
        this.jintekiList = result;
        for (var i = 0; i < this.jintekiList.length; i++) {
          this.jintekiList[i].inzu = this.formatNumber(
            this.jintekiList[i].inzu
          );
          for (var j = 0; j < this.shcyousonList.length; j++) {
            if (
              this.jintekiList[i].basyo_cd1 == this.shcyousonList[j].shicyou_cd
            ) {
              this.jintekiList[i].basyo_name1 = this.shcyousonList[
                j
              ].shicyou_mei;
            }
            if (
              this.jintekiList[i].basyo_cd2 == this.shcyousonList[j].shicyou_cd
            ) {
              this.jintekiList[i].basyo_name2 = this.shcyousonList[
                j
              ].shicyou_mei;
            }
          }
        }
      });
    },
    getJyukaList() {
      var request_data = {
        year: this.engYear,
        ken: this.ken,
        houkokusya_cd: this.higai_houkoku_cd,
        kanrisya_cd: this.kanrisya_cd
      };
      apiService.fa105getJyukaList(request_data).then(result => {
        this.jyukaList = result;
        for (var i = 0; i < this.jyukaList.length; i++) {
          this.jyukaList[i].inzu = this.formatNumber(this.jyukaList[i].inzu);
          for (var j = 0; j < this.shcyousonList.length; j++) {
            if (
              this.jyukaList[i].basyo_cd1 == this.shcyousonList[j].shicyou_cd
            ) {
              this.jyukaList[i].basyo_name1 = this.shcyousonList[j].shicyou_mei;
            }
            if (
              this.jyukaList[i].basyo_cd2 == this.shcyousonList[j].shicyou_cd
            ) {
              this.jyukaList[i].basyo_name2 = this.shcyousonList[j].shicyou_mei;
            }
          }
        }
      });
    },
    focusJInzu(index) {
      var inzu = this.jintekiList[index].inzu;
      if (this.jintekiList[index].inzu != null) {
        this.jintekiList[index].inzu = inzu.trim().replace(/,/g, "");
      }
    },
    focusGInzu(index) {
      var inzu = this.jyukaList[index].inzu;
      if (this.jyukaList[index].inzu != null) {
        this.jyukaList[index].inzu = inzu.trim().replace(/,/g, "");
      }
    },
    inzuChangeJ(index) {
      this.info = "";
      this.err = "";
      this.jintekiList[index].inzu = this.formatNumber(
        this.jintekiList[index].inzu
      );
    },
    inzuChangeG(index) {
      this.info = "";
      this.err = "";
      this.jyukaList[index].inzu = this.formatNumber(
        this.jyukaList[index].inzu
      );
    },
    getGeninList() {
      apiService.getGeninList().then(result => {
        this.geninList = result;
      });
    },
    getShcyousonLit() {
      apiService.getShcyousonLit().then(result => {
        this.shcyousonList = result;
      });
    },
    updateData() {
      for (var j = 0; j < this.jintekiList.length; j++) {
        var temp = this.jintekiList[j].inzu.toString().replace(/,/g, "");
        if (this.isInteger(temp) || temp == "") {
          this.jintekiList[j].inzu =
            temp == "" || temp == null ? "" : parseInt(temp);
        } else {
          this.isHasErr = true;
          this.errMsg = this.$messages.msg["0002W"];
          break;
        }
      }
      for (var i = 0; i < this.jyukaList.length; i++) {
        var tempj = this.jyukaList[i].inzu.toString().replace(/,/g, "");
        if (this.isInteger(tempj) || tempj == "") {
          this.jyukaList[i].inzu =
            tempj == "" || temp == null ? "" : parseInt(tempj);
        } else {
          this.isHasErr = true;
          this.errMsg = this.$messages.msg["0002W"];
          break;
        }
      }
      if (!this.isHasErr) {
        var request_data = {
          id: this.loginUser.id,
          year: this.engYear,
          ken: this.ken,
          houkokusya_cd: this.higai_houkoku_cd,
          kanrisya_cd: this.kanrisya_cd,
          jintekiList: this.jintekiList,
          jyukaList: this.jyukaList
        };
        console.log(request_data);
        apiService
          .fa105UpdateData(request_data)
          .then(result => {
            this.info = result;
            this.getJintekiList();
            this.getJyukaList();
          })
          .catch(error => {
            this.err = error;
          });
      }
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0") == 0
        ? ""
        : numeral(number).format("0,0");
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
      });
    }
  }
};
</script>