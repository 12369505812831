<script>
import AppLayout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { ja } from "vuejs-datepicker/dist/locale";
import { APIService } from "@/apiService.js";

const apiService = new APIService();
var numeral = require("numeral");
export default {
  name: "fa113",
  components: {
    AppLayout
  },
  data() {
    return {
      jpYear: "",
      year: parseInt(this.$route.query.saigai_yyyy),
      saigai_ken: parseInt(this.$route.query.saigai_ken),
      kanrisya_cd: parseInt(this.$route.query.kanrisya_cd),
      houkokusya_cd: parseInt(this.$route.query.houkokusya_cd),
      saigai_start: "",
      saigai_end: "",
      saigai_mei: "",
      ja: ja,
      higai_kousyu_gaku: {},

      jigyou_cd: "",
      kasen_kasyo: "",
      kasen_gaku: "",
      kaigankou_kasyo: "",
      kaigankou_gaku: "",
      kaigan_kasyo: "",
      kaigan_gaku: "",
      sabou_kasyo: "",
      sabou_gaku: "",
      jisuberi_kasyo: "",
      jisuberi_gaku: "",
      kyukeisya_kasyo: "",
      kyukeisya_gaku: "",
      douro_kasyo: "",
      douro_gaku: "",
      kyouryou_kasyo: "",
      kyouryou_gaku: "",
      kouwan_kasyo: "",
      kouwan_gaku: "",
      gesuidou_kasyo: "",
      gesuidou_gaku: "",
      kouen_kasyo: "",
      kouen_gaku: "",
      goukei_kasyo: "",
      goukei_gaku: "",

      jigyou_cd0: 1,
      kasen_kasyo0: "",
      kasen_gaku0: "",
      kaigankou_kasyo0: "",
      kaigankou_gaku0: "",
      kaigan_kasyo0: "",
      kaigan_gaku0: "",
      sabou_kasyo0: "",
      sabou_gaku0: "",
      jisuberi_kasyo0: "",
      jisuberi_gaku0: "",
      kyukeisya_kasyo0: "",
      kyukeisya_gaku0: "",
      douro_kasyo0: "",
      douro_gaku0: "",
      kyouryou_kasyo0: "",
      kyouryou_gaku0: "",
      kouwan_kasyo0: "",
      kouwan_gaku0: "",
      gesuidou_kasyo0: "",
      gesuidou_gaku0: "",
      kouen_kasyo0: "",
      kouen_gaku0: "",
      goukei_kasyo0: "",
      goukei_gaku0: "",

      jigyou_cd1: 2,
      kasen_kasyo1: "",
      kasen_gaku1: "",
      kaigankou_kasyo1: "",
      kaigankou_gaku1: "",
      kaigan_kasyo1: "",
      kaigan_gaku1: "",
      sabou_kasyo1: "",
      sabou_gaku1: "",
      jisuberi_kasyo1: "",
      jisuberi_gaku1: "",
      kyukeisya_kasyo1: "",
      kyukeisya_gaku1: "",
      douro_kasyo1: "",
      douro_gaku1: "",
      kyouryou_kasyo1: "",
      kyouryou_gaku1: "",
      kouwan_kasyo1: "",
      kouwan_gaku1: "",
      gesuidou_kasyo1: "",
      gesuidou_gaku1: "",
      kouen_kasyo1: "",
      kouen_gaku1: "",
      goukei_kasyo1: "",
      goukei_gaku1: "",
      loginUser: {
        login_id: window.localStorage.getItem("LOGIN_ID"),
        kanrisya_name: window.localStorage.getItem("Kanrisya_Mei")
      },
      request_data0: {},
      request_data1: {},
      info: null,
      unique_error: null,
      post: null
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.getGengo(this.year);
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    search(year, saigai_ken) {
      this.clearError();
      this.info = null;
      var request_data = {
        year: year,
        ken: saigai_ken
      };
      apiService
        .fa113GetMeisyou(request_data)
        .then(result => {
          this.meisyou = result;
          this.saigai_ken = this.meisyou[0].saigai_ken;
          this.saigai_mei = this.meisyou[0].saigai_mei;
          this.saigai_start = this.meisyou[0].saigai_start;
          this.saigai_start = moment(String(this.saigai_start)).format(
            "YYYY/MM/DD"
          );
          this.saigai_end = this.meisyou[0].saigai_end;
          this.saigai_end = moment(String(this.saigai_end)).format(
            "YYYY/MM/DD"
          );
          this.getKousyuGakuData(
            this.year,
            this.saigai_ken,
            this.kanrisya_cd,
            this.houkokusya_cd
          );
        })
        .catch(error => {
          console.log(error);
        });
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService
        .getGengo(request_data)
        .then(result => {
          this.jpYear = result;
          this.search(this.year, this.saigai_ken);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getKousyuGakuData(year, saigai_ken, kanrisya_cd, houkokusya_cd) {
      //  this.clearError();
      var request_data = {
        year: year,
        saigai_ken: saigai_ken,
        kanrisya_cd: kanrisya_cd,
        houkokusya_cd: houkokusya_cd
      };
      apiService
        .fa113getKousyuGakuData(request_data)
        .then(result => {
          this.higai_kousyu_gaku = result;
          if (Object.keys(this.higai_kousyu_gaku).length > 0) {
            (this.jigyou_cd0 = this.higai_kousyu_gaku[0].jigyou_cd),
              (this.kasen_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kasen_kasyo
              )),
              (this.kasen_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kasen_gaku
              ));
            (this.kaigankou_kasyo0 = this.formatNumber(
              this.higai_kousyu_gaku[0].kaigankou_kasyo
            )),
              (this.kaigankou_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigankou_gaku
              )),
              (this.kaigan_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigan_kasyo
              )),
              (this.kaigan_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigan_gaku
              )),
              (this.sabou_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].sabou_kasyo
              )),
              (this.sabou_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].sabou_gaku
              )),
              (this.jisuberi_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].jisuberi_kasyo
              )),
              (this.jisuberi_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].jisuberi_gaku
              )),
              (this.kyukeisya_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kyukeisya_kasyo
              )),
              (this.kyukeisya_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kyukeisya_gaku
              )),
              (this.douro_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].douro_kasyo
              )),
              (this.douro_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].douro_gaku
              )),
              (this.kyouryou_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kyouryou_kasyo
              )),
              (this.kyouryou_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kyouryou_gaku
              )),
              (this.kouwan_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kouwan_kasyo
              )),
              (this.kouwan_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kouwan_gaku
              )),
              (this.gesuidou_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].gesuidou_kasyo
              )),
              (this.gesuidou_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].gesuidou_gaku
              )),
              (this.kouen_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kouen_kasyo
              )),
              (this.kouen_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].kouen_gaku
              )),
              (this.goukei_kasyo0 = this.formatNumber(
                this.higai_kousyu_gaku[0].goukei_kasyo
              )),
              (this.goukei_gaku0 = this.formatNumber(
                this.higai_kousyu_gaku[0].goukei_gaku
              )),
              (this.jigyou_cd1 = this.higai_kousyu_gaku[1].jigyou_cd),
              (this.kasen_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kasen_kasyo
              )),
              (this.kasen_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kasen_gaku
              )),
              (this.kaigankou_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kaigankou_kasyo
              )),
              (this.kaigankou_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kaigankou_gaku
              )),
              (this.kaigan_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kaigan_kasyo
              )),
              (this.kaigan_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kaigan_gaku
              )),
              (this.sabou_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].sabou_kasyo
              )),
              (this.sabou_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].sabou_gaku
              )),
              (this.jisuberi_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].jisuberi_kasyo
              )),
              (this.jisuberi_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].jisuberi_gaku
              )),
              (this.kyukeisya_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kyukeisya_kasyo
              )),
              (this.kyukeisya_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kyukeisya_gaku
              )),
              (this.douro_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].douro_kasyo
              )),
              (this.douro_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].douro_gaku
              )),
              (this.kyouryou_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kyouryou_kasyo
              )),
              (this.kyouryou_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kyouryou_gaku
              )),
              (this.kouwan_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kouwan_kasyo
              )),
              (this.kouwan_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kouwan_gaku
              )),
              (this.gesuidou_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].gesuidou_kasyo
              )),
              (this.gesuidou_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].gesuidou_gaku
              )),
              (this.kouen_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kouen_kasyo
              )),
              (this.kouen_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].kouen_gaku
              )),
              (this.goukei_kasyo1 = this.formatNumber(
                this.higai_kousyu_gaku[1].goukei_kasyo
              )),
              (this.goukei_gaku1 = this.formatNumber(
                this.higai_kousyu_gaku[1].goukei_gaku
              )),
              (this.kasen_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kasen_kasyo +
                  this.higai_kousyu_gaku[1].kasen_kasyo
              )),
              (this.kasen_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kasen_gaku +
                  this.higai_kousyu_gaku[1].kasen_gaku
              )),
              (this.kaigankou_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigankou_kasyo +
                  this.higai_kousyu_gaku[1].kaigankou_kasyo
              )),
              (this.kaigankou_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigankou_gaku +
                  this.higai_kousyu_gaku[1].kaigankou_gaku
              )),
              (this.kaigan_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigan_kasyo +
                  this.higai_kousyu_gaku[1].kaigan_kasyo
              )),
              (this.kaigan_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kaigan_gaku +
                  this.higai_kousyu_gaku[1].kaigan_gaku
              )),
              (this.sabou_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].sabou_kasyo +
                  this.higai_kousyu_gaku[1].sabou_kasyo
              )),
              (this.sabou_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].sabou_gaku +
                  this.higai_kousyu_gaku[1].sabou_gaku
              )),
              (this.jisuberi_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].jisuberi_kasyo +
                  this.higai_kousyu_gaku[1].jisuberi_kasyo
              )),
              (this.jisuberi_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].jisuberi_gaku +
                  this.higai_kousyu_gaku[1].jisuberi_gaku
              )),
              (this.kyukeisya_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kyukeisya_kasyo +
                  this.higai_kousyu_gaku[1].kyukeisya_kasyo
              )),
              (this.kyukeisya_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kyukeisya_gaku +
                  this.higai_kousyu_gaku[1].kyukeisya_gaku
              )),
              (this.douro_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].douro_kasyo +
                  this.higai_kousyu_gaku[1].douro_kasyo
              )),
              (this.douro_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].douro_gaku +
                  this.higai_kousyu_gaku[1].douro_gaku
              )),
              (this.kyouryou_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kyouryou_kasyo +
                  this.higai_kousyu_gaku[1].kyouryou_kasyo
              )),
              (this.kyouryou_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kyouryou_gaku +
                  this.higai_kousyu_gaku[1].kyouryou_gaku
              )),
              (this.kouwan_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kouwan_kasyo +
                  this.higai_kousyu_gaku[1].kouwan_kasyo
              )),
              (this.kouwan_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kouwan_gaku +
                  this.higai_kousyu_gaku[1].kouwan_gaku
              )),
              (this.gesuidou_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].gesuidou_kasyo +
                  this.higai_kousyu_gaku[1].gesuidou_kasyo
              )),
              (this.gesuidou_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].gesuidou_gaku +
                  this.higai_kousyu_gaku[1].gesuidou_gaku
              )),
              (this.kouen_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].kouen_kasyo +
                  this.higai_kousyu_gaku[1].kouen_kasyo
              )),
              (this.kouen_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].kouen_gaku +
                  this.higai_kousyu_gaku[1].kouen_gaku
              )),
              (this.goukei_kasyo = this.formatNumber(
                this.higai_kousyu_gaku[0].goukei_kasyo +
                  this.higai_kousyu_gaku[1].goukei_kasyo
              )),
              (this.goukei_gaku = this.formatNumber(
                this.higai_kousyu_gaku[0].goukei_gaku +
                  this.higai_kousyu_gaku[1].goukei_gaku
              ));
          } else {
            this.clearData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    kasenKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kasen_kasyo0 != null && this.kasen_kasyo0 != "") {
        zero = this.replaceComma(this.kasen_kasyo0);
        this.kasen_kasyo0 = this.formatNumber(this.kasen_kasyo0);
      }
      if (this.kasen_kasyo1 != null && this.kasen_kasyo1 != "") {
        one = this.replaceComma(this.kasen_kasyo1);
        this.kasen_kasyo1 = this.formatNumber(this.kasen_kasyo1);
      }
      this.kasen_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kasen_kasyo = this.formatNumber(this.kasen_kasyo);
    },
    kasenKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kasen_gaku0 != null && this.kasen_gaku0 != "") {
        zero = this.replaceComma(this.kasen_gaku0);
        this.kasen_gaku0 = this.formatNumber(this.kasen_gaku0);
      }
      if (this.kasen_gaku1 != null && this.kasen_gaku1 != "") {
        one = this.replaceComma(this.kasen_gaku1);
        this.kasen_gaku1 = this.formatNumber(this.kasen_gaku1);
      }
      this.kasen_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kasen_gaku = this.formatNumber(this.kasen_gaku);
    },
    removeComkasenKasyo1st() {
      this.kasen_kasyo0 = this.replaceComma(this.kasen_kasyo0);
    },
    removeComkasenKasyo2nd() {
      this.kasen_gaku0 = this.replaceComma(this.kasen_gaku0);
    },
    removeComkasenKasyo3rd() {
      this.kasen_kasyo1 = this.replaceComma(this.kasen_kasyo1);
    },
    removeComkasenKasyo4th() {
      this.kasen_gaku1 = this.replaceComma(this.kasen_gaku1);
    },
    kaigankouKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kaigankou_kasyo0 != null && this.kaigankou_kasyo0 != "") {
        zero = this.replaceComma(this.kaigankou_kasyo0);
        this.kaigankou_kasyo0 = this.formatNumber(this.kaigankou_kasyo0);
      }
      if (this.kaigankou_kasyo1 != null && this.kaigankou_kasyo1 != "") {
        one = this.replaceComma(this.kaigankou_kasyo1);
        this.kaigankou_kasyo1 = this.formatNumber(this.kaigankou_kasyo1);
      }
      this.kaigankou_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kaigankou_kasyo = this.formatNumber(this.kaigankou_kasyo);
    },
    kaigankouKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kaigankou_gaku0 != null && this.kaigankou_gaku0 != "") {
        zero = this.replaceComma(this.kaigankou_gaku0);
        this.kaigankou_gaku0 = this.formatNumber(this.kaigankou_gaku0);
      }
      if (this.kaigankou_gaku1 != null && this.kaigankou_gaku1 != "") {
        one = this.replaceComma(this.kaigankou_gaku1);
        this.kaigankou_gaku1 = this.formatNumber(this.kaigankou_gaku1);
      }
      this.kaigankou_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kaigankou_gaku = this.formatNumber(this.kaigankou_gaku);
    },
    removeComkaigankouKasyo1st() {
      this.kaigankou_kasyo0 = this.replaceComma(this.kaigankou_kasyo0);
    },
    removeComkaigankouKasyo2nd() {
      this.kaigankou_gaku0 = this.replaceComma(this.kaigankou_gaku0);
    },
    removeComkaigankouKasyo3rd() {
      this.kaigankou_kasyo1 = this.replaceComma(this.kaigankou_kasyo1);
    },
    removeComkaigankouKasyo4th() {
      this.kaigankou_gaku1 = this.replaceComma(this.kaigankou_gaku1);
    },
    kaiganKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kaigan_kasyo0 != null && this.kaigan_kasyo0 != "") {
        zero = this.replaceComma(this.kaigan_kasyo0);
        this.kaigan_kasyo0 = this.formatNumber(this.kaigan_kasyo0);
      }
      if (this.kaigan_kasyo1 != null && this.kaigan_kasyo1 != "") {
        one = this.replaceComma(this.kaigan_kasyo1);
        this.kaigan_kasyo1 = this.formatNumber(this.kaigan_kasyo1);
      }
      this.kaigan_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kaigan_kasyo = this.formatNumber(this.kaigan_kasyo);
    },
    kaiganKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kaigan_gaku0 != null && this.kaigan_gaku0 != "") {
        zero = this.replaceComma(this.kaigan_gaku0);
        this.kaigan_gaku0 = this.formatNumber(this.kaigan_gaku0);
      }
      if (this.kaigan_gaku1 != null && this.kaigan_gaku1 != "") {
        one = this.replaceComma(this.kaigan_gaku1);
        this.kaigan_gaku1 = this.formatNumber(this.kaigan_gaku1);
      }
      this.kaigan_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kaigan_gaku = this.formatNumber(this.kaigan_gaku);
    },
    removeComkaiganKasyo1st() {
      this.kaigan_kasyo0 = this.replaceComma(this.kaigan_kasyo0);
    },
    removeComkaiganKasyo2nd() {
      this.kaigan_gaku0 = this.replaceComma(this.kaigan_gaku0);
    },
    removeComkaiganKasyo3rd() {
      this.kaigan_kasyo1 = this.replaceComma(this.kaigan_kasyo1);
    },
    removeComkaiganKasyo4th() {
      this.kaigan_gaku0 = this.replaceComma(this.kaigan_gaku0);
    },
    sabouKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.sabou_kasyo0 != null && this.sabou_kasyo0 != "") {
        zero = this.replaceComma(this.sabou_kasyo0);
        this.sabou_kasyo0 = this.formatNumber(this.sabou_kasyo0);
      }
      if (this.sabou_kasyo1 != null && this.sabou_kasyo1 != "") {
        one = this.replaceComma(this.sabou_kasyo1);
        this.sabou_kasyo1 = this.formatNumber(this.sabou_kasyo1);
      }
      this.sabou_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.sabou_kasyo = this.formatNumber(this.sabou_kasyo);
    },
    sabouKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.sabou_gaku0 != null && this.sabou_gaku0 != "") {
        zero = this.replaceComma(this.sabou_gaku0);
        this.sabou_gaku0 = this.formatNumber(this.sabou_gaku0);
      }
      if (this.sabou_gaku1 != null && this.sabou_gaku1 != "") {
        one = this.replaceComma(this.sabou_gaku1);
        this.sabou_gaku1 = this.formatNumber(this.sabou_gaku1);
      }
      this.sabou_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.sabou_gaku = this.formatNumber(this.sabou_gaku);
    },
    removeComsabouKasyo1st() {
      this.sabou_kasyo0 = this.replaceComma(this.sabou_kasyo0);
    },
    removeComsabouKasyo2nd() {
      this.sabou_gaku0 = this.replaceComma(this.sabou_gaku0);
    },
    removeComsabouKasyo3rd() {
      this.sabou_kasyo1 = this.replaceComma(this.sabou_kasyo1);
    },
    removeComsabouKasyo4th() {
      this.sabou_gaku1 = this.replaceComma(this.sabou_gaku1);
    },
    jisuberiKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.jisuberi_kasyo0 != null && this.jisuberi_kasyo0 != "") {
        zero = this.replaceComma(this.jisuberi_kasyo0);
        this.jisuberi_kasyo0 = this.formatNumber(this.jisuberi_kasyo0);
      }
      if (this.jisuberi_kasyo1 != null && this.jisuberi_kasyo1 != "") {
        one = this.replaceComma(this.jisuberi_kasyo1);
        this.jisuberi_kasyo1 = this.formatNumber(this.jisuberi_kasyo1);
      }
      this.jisuberi_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.jisuberi_kasyo = this.formatNumber(this.jisuberi_kasyo);
    },
    jisuberiKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.jisuberi_gaku0 != null && this.jisuberi_gaku0 != "") {
        zero = this.replaceComma(this.jisuberi_gaku0);
        this.jisuberi_gaku0 = this.formatNumber(this.jisuberi_gaku0);
      }
      if (this.jisuberi_gaku1 != null && this.jisuberi_gaku1 != "") {
        one = this.replaceComma(this.jisuberi_gaku1);
        this.jisuberi_gaku1 = this.formatNumber(this.jisuberi_gaku1);
      }
      this.jisuberi_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.jisuberi_gaku = this.formatNumber(this.jisuberi_gaku);
    },
    removeComjisuberiKasyo1st() {
      this.jisuberi_kasyo0 = this.replaceComma(this.jisuberi_kasyo0);
    },
    removeComjisuberiKasyo2nd() {
      this.jisuberi_gaku0 = this.replaceComma(this.jisuberi_gaku0);
    },
    removeComjisuberiKasyo3rd() {
      this.jisuberi_kasyo1 = this.replaceComma(this.jisuberi_kasyo1);
    },
    removeComjisuberiKasyo4th() {
      this.jisuberi_gaku1 = this.replaceComma(this.jisuberi_gaku1);
    },
    kyukeisyaKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kyukeisya_kasyo0 != null && this.kyukeisya_kasyo0 != "") {
        zero = this.replaceComma(this.kyukeisya_kasyo0);
        this.kyukeisya_kasyo0 = this.formatNumber(this.kyukeisya_kasyo0);
      }
      if (this.kyukeisya_kasyo1 != null && this.kyukeisya_kasyo1 != "") {
        one = this.replaceComma(this.kyukeisya_kasyo1);
        this.kyukeisya_kasyo1 = this.formatNumber(this.kyukeisya_kasyo1);
      }
      this.kyukeisya_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kyukeisya_kasyo = this.formatNumber(this.kyukeisya_kasyo);
    },
    kyukeisyaKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kyukeisya_gaku0 != null && this.kyukeisya_gaku0 != "") {
        zero = this.replaceComma(this.kyukeisya_gaku0);
        this.kyukeisya_gaku0 = this.formatNumber(this.kyukeisya_gaku0);
      }
      if (this.kyukeisya_gaku1 != null && this.kyukeisya_gaku1 != "") {
        one = this.replaceComma(this.kyukeisya_gaku1);
        this.kyukeisya_gaku1 = this.formatNumber(this.kyukeisya_gaku1);
      }
      this.kyukeisya_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kyukeisya_gaku = this.formatNumber(this.kyukeisya_gaku);
    },
    removeComkyukeisyaKasyo1st() {
      this.kyukeisya_kasyo0 = this.replaceComma(this.kyukeisya_kasyo0);
    },
    removeComkyukeisyaKasyo2nd() {
      this.kyukeisya_gaku0 = this.replaceComma(this.kyukeisya_gaku0);
    },
    removeComkyukeisyaKasyo3rd() {
      this.kyukeisya_kasyo1 = this.replaceComma(this.kyukeisya_kasyo1);
    },
    removeComkyukeisyaKasyo4th() {
      this.kyukeisya_gaku1 = this.replaceComma(this.kyukeisya_gaku1);
    },
    douroKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.douro_kasyo0 != null && this.douro_kasyo0 != "") {
        zero = this.replaceComma(this.douro_kasyo0);
        this.douro_kasyo0 = this.formatNumber(this.douro_kasyo0);
      }
      if (this.douro_kasyo1 != null && this.douro_kasyo1 != "") {
        one = this.replaceComma(this.douro_kasyo1);
        this.douro_kasyo1 = this.formatNumber(this.douro_kasyo1);
      }
      this.douro_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.douro_kasyo = this.formatNumber(this.douro_kasyo);
    },
    douroKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.douro_gaku0 != null && this.douro_gaku0 != "") {
        zero = this.replaceComma(this.douro_gaku0);
        this.douro_gaku0 = this.formatNumber(this.douro_gaku0);
      }
      if (this.douro_gaku1 != null && this.douro_gaku1 != "") {
        one = this.replaceComma(this.douro_gaku1);
        this.douro_gaku1 = this.formatNumber(this.douro_gaku1);
      }
      this.douro_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.douro_gaku = this.formatNumber(this.douro_gaku);
    },
    removeComdouroKasyo1st() {
      this.douro_kasyo0 = this.replaceComma(this.douro_kasyo0);
    },
    removeComdouroKasyo2nd() {
      this.douro_gaku0 = this.replaceComma(this.douro_gaku0);
    },
    removeComdouroKasyo3rd() {
      this.douro_kasyo1 = this.replaceComma(this.douro_kasyo1);
    },
    removeComdouroKasyo4th() {
      this.douro_gaku1 = this.replaceComma(this.douro_gaku1);
    },
    kyouryouKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kyouryou_kasyo0 != null && this.kyouryou_kasyo0 != "") {
        zero = this.replaceComma(this.kyouryou_kasyo0);
        this.kyouryou_kasyo0 = this.formatNumber(this.kyouryou_kasyo0);
      }
      if (this.kyouryou_kasyo1 != null && this.kyouryou_kasyo1 != "") {
        one = this.replaceComma(this.kyouryou_kasyo1);
        this.kyouryou_kasyo1 = this.formatNumber(this.kyouryou_kasyo1);
      }
      this.kyouryou_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kyouryou_kasyo = this.formatNumber(this.kyouryou_kasyo);
    },
    kyouryouKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kyouryou_gaku0 != null && this.kyouryou_gaku0 != "") {
        zero = this.replaceComma(this.kyouryou_gaku0);
        this.kyouryou_gaku0 = this.formatNumber(this.kyouryou_gaku0);
      }
      if (this.kyouryou_gaku1 != null && this.kyouryou_gaku1 != "") {
        one = this.replaceComma(this.kyouryou_gaku1);
        this.kyouryou_gaku1 = this.formatNumber(this.kyouryou_gaku1);
      }
      this.kyouryou_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kyouryou_gaku = this.formatNumber(this.kyouryou_gaku);
    },
    removeComkyouryouKasyo1st() {
      this.kyouryou_kasyo0 = this.replaceComma(this.kyouryou_kasyo0);
    },
    removeComkyouryouKasyo2nd() {
      this.kyouryou_gaku0 = this.replaceComma(this.kyouryou_gaku0);
    },
    removeComkyouryouKasyo3rd() {
      this.kyouryou_kasyo1 = this.replaceComma(this.kyouryou_kasyo1);
    },
    removeComkyouryouKasyo4th() {
      this.kyouryou_gaku0 = this.replaceComma(this.kyouryou_gaku1);
    },
    kouwanKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kouwan_kasyo0 != null && this.kouwan_kasyo0 != "") {
        zero = this.replaceComma(this.kouwan_kasyo0);
        this.kouwan_kasyo0 = this.formatNumber(this.kouwan_kasyo0);
      }
      if (this.kouwan_kasyo1 != null && this.kouwan_kasyo1 != "") {
        one = this.replaceComma(this.kouwan_kasyo1);
        this.kouwan_kasyo1 = this.formatNumber(this.kouwan_kasyo1);
      }
      this.kouwan_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kouwan_kasyo = this.formatNumber(this.kouwan_kasyo);
    },
    kouwanKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kouwan_gaku0 != null && this.kouwan_gaku0 != "") {
        zero = this.replaceComma(this.kouwan_gaku0);
        this.kouwan_gaku0 = this.formatNumber(this.kouwan_gaku0);
      }
      if (this.kouwan_gaku1 != null && this.kouwan_gaku1 != "") {
        one = this.replaceComma(this.kouwan_gaku1);
        this.kouwan_gaku1 = this.formatNumber(this.kouwan_gaku1);
      }
      this.kouwan_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kouwan_gaku = this.formatNumber(this.kouwan_gaku);
    },
    removeComkouwanKasyo1st() {
      this.kouwan_kasyo0 = this.replaceComma(this.kouwan_kasyo0);
    },
    removeComkouwanKasyo2nd() {
      this.kouwan_gaku0 = this.replaceComma(this.kouwan_gaku0);
    },
    removeComkouwanKasyo3rd() {
      this.kouwan_kasyo1 = this.replaceComma(this.kouwan_kasyo1);
    },
    removeComkouwanKasyo4th() {
      this.kouwan_gaku1 = this.replaceComma(this.kouwan_gaku1);
    },
    gesuidouKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.gesuidou_kasyo0 != null && this.gesuidou_kasyo0 != "") {
        zero = this.replaceComma(this.gesuidou_kasyo0);
        this.gesuidou_kasyo0 = this.formatNumber(this.gesuidou_kasyo0);
      }
      if (this.gesuidou_kasyo1 != null && this.gesuidou_kasyo1 != "") {
        one = this.replaceComma(this.gesuidou_kasyo1);
        this.gesuidou_kasyo1 = this.formatNumber(this.gesuidou_kasyo1);
      }
      this.gesuidou_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.gesuidou_kasyo = this.formatNumber(this.gesuidou_kasyo);
    },
    gesuidouKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.gesuidou_gaku0 != null && this.gesuidou_gaku0 != "") {
        zero = this.replaceComma(this.gesuidou_gaku0);
        this.gesuidou_gaku0 = this.formatNumber(this.gesuidou_gaku0);
      }
      if (this.gesuidou_gaku1 != null && this.gesuidou_gaku1 != "") {
        one = this.replaceComma(this.gesuidou_gaku1);
        this.gesuidou_gaku1 = this.formatNumber(this.gesuidou_gaku1);
      }
      this.gesuidou_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.gesuidou_gaku = this.formatNumber(this.gesuidou_gaku);
    },
    removeComgesuidouKasyo1st() {
      this.gesuidou_kasyo0 = this.replaceComma(this.gesuidou_kasyo0);
    },
    removeComgesuidouKasyo2nd() {
      this.gesuidou_gaku0 = this.replaceComma(this.gesuidou_gaku0);
    },
    removeComgesuidouKasyo3rd() {
      this.gesuidou_kasyo1 = this.replaceComma(this.gesuidou_kasyo1);
    },
    removeComgesuidouKasyo4th() {
      this.gesuidou_gaku1 = this.replaceComma(this.gesuidou_gaku1);
    },
    kouenKasyo1st() {
      var zero = "0";
      var one = "0";
      this.goukeiKasyo();
      if (this.kouen_kasyo0 != null && this.kouen_kasyo0 != "") {
        zero = this.replaceComma(this.kouen_kasyo0);
        this.kouen_kasyo0 = this.formatNumber(this.kouen_kasyo0);
      }
      if (this.kouen_kasyo1 != null && this.kouen_kasyo1 != "") {
        one = this.replaceComma(this.kouen_kasyo1);
        this.kouen_kasyo1 = this.formatNumber(this.kouen_kasyo1);
      }
      this.kouen_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kouen_kasyo = this.formatNumber(this.kouen_kasyo);
    },
    kouenKasyo2nd() {
      var zero = "0";
      var one = "0";
      this.goukeiGaku();
      if (this.kouen_gaku0 != null && this.kouen_gaku0 != "") {
        zero = this.replaceComma(this.kouen_gaku0);
        this.kouen_gaku0 = this.formatNumber(this.kouen_gaku0);
      }
      if (this.kouen_gaku1 != null && this.kouen_gaku1 != "") {
        one = this.replaceComma(this.kouen_gaku1);
        this.kouen_gaku1 = this.formatNumber(this.kouen_gaku1);
      }
      this.kouen_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.kouen_gaku = this.formatNumber(this.kouen_gaku);
    },
    removeComkouenKasyo1st() {
      this.kouen_kasyo0 = this.replaceComma(this.kouen_kasyo0);
    },
    removeComkouenKasyo2nd() {
      this.kouen_gaku0 = this.replaceComma(this.kouen_gaku0);
    },
    removeComkouenKasyo3rd() {
      this.kouen_kasyo1 = this.replaceComma(this.kouen_kasyo1);
    },
    removeComkouenKasyo4th() {
      this.kouen_gaku1 = this.replaceComma(this.kouen_gaku1);
    },
    goukeiKasyo() {
      var firstrow1 = "0";
      var firstrow2 = "0";
      var firstrow3 = "0";
      var firstrow4 = "0";
      var firstrow5 = "0";
      var firstrow6 = "0";
      var firstrow7 = "0";
      var firstrow8 = "0";
      var firstrow9 = "0";
      var firstrow10 = "0";
      var firstrow11 = "0";
      var secrow1 = "0";
      var secrow2 = "0";
      var secrow3 = "0";
      var secrow4 = "0";
      var secrow5 = "0";
      var secrow6 = "0";
      var secrow7 = "0";
      var secrow8 = "0";
      var secrow9 = "0";
      var secrow10 = "0";
      var secrow11 = "0";
      var zero = "0";
      var one = "0";
      if (this.kasen_kasyo0 != null && this.kasen_kasyo0 != "") {
        firstrow1 = this.replaceComma(this.kasen_kasyo0);
      }
      if (this.kaigankou_kasyo0 != null && this.kaigankou_kasyo0 != "") {
        firstrow2 = this.replaceComma(this.kaigankou_kasyo0);
      }
      if (this.kaigan_kasyo0 != null && this.kaigan_kasyo0 != "") {
        firstrow3 = this.replaceComma(this.kaigan_kasyo0);
      }
      if (this.sabou_kasyo0 != null && this.sabou_kasyo0 != "") {
        firstrow4 = this.replaceComma(this.sabou_kasyo0);
      }
      if (this.jisuberi_kasyo0 != null && this.jisuberi_kasyo0 != "") {
        firstrow5 = this.replaceComma(this.jisuberi_kasyo0);
      }
      if (this.kyukeisya_kasyo0 != null && this.kyukeisya_kasyo0 != "") {
        firstrow6 = this.replaceComma(this.kyukeisya_kasyo0);
      }
      if (this.douro_kasyo0 != null && this.douro_kasyo0 != "") {
        firstrow7 = this.replaceComma(this.douro_kasyo0);
      }
      if (this.kyouryou_kasyo0 != null && this.kyouryou_kasyo0 != "") {
        firstrow8 = this.replaceComma(this.kyouryou_kasyo0);
      }
      if (this.kouwan_kasyo0 != null && this.kouwan_kasyo0 != "") {
        firstrow9 = this.replaceComma(this.kouwan_kasyo0);
      }
      if (this.gesuidou_kasyo0 != null && this.gesuidou_kasyo0 != "") {
        firstrow10 = this.replaceComma(this.gesuidou_kasyo0);
      }
      if (this.kouen_kasyo0 != null && this.kouen_kasyo0 != "") {
        firstrow11 = this.replaceComma(this.kouen_kasyo0);
      }
      if (this.kasen_kasyo1 != null && this.kasen_kasyo1 != "") {
        secrow1 = this.replaceComma(this.kasen_kasyo1);
      }
      if (this.kaigankou_kasyo1 != null && this.kaigankou_kasyo1 != "") {
        secrow2 = this.replaceComma(this.kaigankou_kasyo1);
      }
      if (this.kaigan_kasyo1 != null && this.kaigan_kasyo1 != "") {
        secrow3 = this.replaceComma(this.kaigan_kasyo1);
      }
      if (this.sabou_kasyo1 != null && this.sabou_kasyo1 != "") {
        secrow4 = this.replaceComma(this.sabou_kasyo1);
      }
      if (this.jisuberi_kasyo1 != null && this.jisuberi_kasyo1 != "") {
        secrow5 = this.replaceComma(this.jisuberi_kasyo1);
      }
      if (this.kyukeisya_kasyo1 != null && this.kyukeisya_kasyo1 != "") {
        secrow6 = this.replaceComma(this.kyukeisya_kasyo1);
      }
      if (this.douro_kasyo1 != null && this.douro_kasyo1 != "") {
        secrow7 = this.replaceComma(this.douro_kasyo1);
      }
      if (this.kyouryou_kasyo1 != null && this.kyouryou_kasyo1 != "") {
        secrow8 = this.replaceComma(this.kyouryou_kasyo1);
      }
      if (this.kouwan_kasyo1 != null && this.kouwan_kasyo1 != "") {
        secrow9 = this.replaceComma(this.kouwan_kasyo1);
      }
      if (this.gesuidou_kasyo1 != null && this.gesuidou_kasyo1 != "") {
        secrow10 = this.replaceComma(this.gesuidou_kasyo1);
      }
      if (this.kouen_kasyo1 != null && this.kouen_kasyo1 != "") {
        secrow11 = this.replaceComma(this.kouen_kasyo1);
      }
      this.goukei_kasyo0 =
        (
          parseInt(firstrow1) +
          parseInt(firstrow2) +
          parseInt(firstrow3) +
          parseInt(firstrow4) +
          parseInt(firstrow5) +
          parseInt(firstrow6) +
          parseInt(firstrow7) +
          parseInt(firstrow8) +
          parseInt(firstrow9) +
          parseInt(firstrow10) +
          parseInt(firstrow11)
        ).toString() == "0"
          ? ""
          : (
              parseInt(firstrow1) +
              parseInt(firstrow2) +
              parseInt(firstrow3) +
              parseInt(firstrow4) +
              parseInt(firstrow5) +
              parseInt(firstrow6) +
              parseInt(firstrow7) +
              parseInt(firstrow8) +
              parseInt(firstrow9) +
              parseInt(firstrow10) +
              parseInt(firstrow11)
            ).toString();

      this.goukei_kasyo1 =
        (
          parseInt(secrow1) +
          parseInt(secrow2) +
          parseInt(secrow3) +
          parseInt(secrow4) +
          parseInt(secrow5) +
          parseInt(secrow6) +
          parseInt(secrow7) +
          parseInt(secrow8) +
          parseInt(secrow9) +
          parseInt(secrow10) +
          parseInt(secrow11)
        ).toString() == "0"
          ? ""
          : (
              parseInt(secrow1) +
              parseInt(secrow2) +
              parseInt(secrow3) +
              parseInt(secrow4) +
              parseInt(secrow5) +
              parseInt(secrow6) +
              parseInt(secrow7) +
              parseInt(secrow8) +
              parseInt(secrow9) +
              parseInt(secrow10) +
              parseInt(secrow11)
            ).toString();

      if (this.goukei_kasyo0 != null && this.goukei_kasyo0 != "") {
        zero = this.replaceComma(this.goukei_kasyo0);
        this.goukei_kasyo0 = this.formatNumber(this.goukei_kasyo0);
      }
      if (this.goukei_kasyo1 != null && this.goukei_kasyo1 != "") {
        one = this.replaceComma(this.goukei_kasyo1);
        this.goukei_kasyo1 = this.formatNumber(this.goukei_kasyo1);
      }
      this.goukei_kasyo =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.goukei_kasyo = this.formatNumber(this.goukei_kasyo);
    },
    goukeiGaku() {
      var firstrow1 = "0";
      var firstrow2 = "0";
      var firstrow3 = "0";
      var firstrow4 = "0";
      var firstrow5 = "0";
      var firstrow6 = "0";
      var firstrow7 = "0";
      var firstrow8 = "0";
      var firstrow9 = "0";
      var firstrow10 = "0";
      var firstrow11 = "0";
      var secrow1 = "0";
      var secrow2 = "0";
      var secrow3 = "0";
      var secrow4 = "0";
      var secrow5 = "0";
      var secrow6 = "0";
      var secrow7 = "0";
      var secrow8 = "0";
      var secrow9 = "0";
      var secrow10 = "0";
      var secrow11 = "0";
      var zero = "0";
      var one = "0";
      if (this.kasen_gaku0 != null && this.kasen_gaku0 != "") {
        firstrow1 = this.replaceComma(this.kasen_gaku0);
      }
      if (this.kaigankou_gaku0 != null && this.kaigankou_gaku0 != "") {
        firstrow2 = this.replaceComma(this.kaigankou_gaku0);
      }
      if (this.kaigan_gaku0 != null && this.kaigan_gaku0 != "") {
        firstrow3 = this.replaceComma(this.kaigan_gaku0);
      }
      if (this.sabou_gaku0 != null && this.sabou_gaku0 != "") {
        firstrow4 = this.replaceComma(this.sabou_gaku0);
      }
      if (this.jisuberi_gaku0 != null && this.jisuberi_gaku0 != "") {
        firstrow5 = this.replaceComma(this.jisuberi_gaku0);
      }
      if (this.kyukeisya_gaku0 != null && this.kyukeisya_gaku0 != "") {
        firstrow6 = this.replaceComma(this.kyukeisya_gaku0);
      }
      if (this.douro_gaku0 != null && this.douro_gaku0 != "") {
        firstrow7 = this.replaceComma(this.douro_gaku0);
      }
      if (this.kyouryou_gaku0 != null && this.kyouryou_gaku0 != "") {
        firstrow8 = this.replaceComma(this.kyouryou_gaku0);
      }
      if (this.kouwan_gaku0 != null && this.kouwan_gaku0 != "") {
        firstrow9 = this.replaceComma(this.kouwan_gaku0);
      }
      if (this.gesuidou_gaku0 != null && this.gesuidou_gaku0 != "") {
        firstrow10 = this.replaceComma(this.gesuidou_gaku0);
      }
      if (this.kouen_gaku0 != null && this.kouen_gaku0 != "") {
        firstrow11 = this.replaceComma(this.kouen_gaku0);
      }
      if (this.kasen_gaku1 != null && this.kasen_gaku1 != "") {
        secrow1 = this.replaceComma(this.kasen_gaku1);
      }
      if (this.kaigankou_gaku1 != null && this.kaigankou_gaku1 != "") {
        secrow2 = this.replaceComma(this.kaigankou_gaku1);
      }
      if (this.kaigan_gaku1 != null && this.kaigan_gaku1 != "") {
        secrow3 = this.replaceComma(this.kaigan_gaku1);
      }
      if (this.sabou_gaku1 != null && this.sabou_gaku1 != "") {
        secrow4 = this.replaceComma(this.sabou_gaku1);
      }
      if (this.jisuberi_gaku1 != null && this.jisuberi_gaku1 != "") {
        secrow5 = this.replaceComma(this.jisuberi_gaku1);
      }
      if (this.kyukeisya_gaku1 != null && this.kyukeisya_gaku1 != "") {
        secrow6 = this.replaceComma(this.kyukeisya_gaku1);
      }
      if (this.douro_gaku1 != null && this.douro_gaku1 != "") {
        secrow7 = this.replaceComma(this.douro_gaku1);
      }
      if (this.kyouryou_gaku1 != null && this.kyouryou_gaku1 != "") {
        secrow8 = this.replaceComma(this.kyouryou_gaku1);
      }
      if (this.kouwan_gaku1 != null && this.kouwan_gaku1 != "") {
        secrow9 = this.replaceComma(this.kouwan_gaku1);
      }
      if (this.gesuidou_gaku1 != null && this.gesuidou_gaku1 != "") {
        secrow10 = this.replaceComma(this.gesuidou_gaku1);
      }
      if (this.kouen_gaku1 != null && this.kouen_gaku1 != "") {
        secrow11 = this.replaceComma(this.kouen_gaku1);
      }
      this.goukei_gaku0 =
        (
          parseInt(firstrow1) +
          parseInt(firstrow2) +
          parseInt(firstrow3) +
          parseInt(firstrow4) +
          parseInt(firstrow5) +
          parseInt(firstrow6) +
          parseInt(firstrow7) +
          parseInt(firstrow8) +
          parseInt(firstrow9) +
          parseInt(firstrow10) +
          parseInt(firstrow11)
        ).toString() == "0"
          ? ""
          : (
              parseInt(firstrow1) +
              parseInt(firstrow2) +
              parseInt(firstrow3) +
              parseInt(firstrow4) +
              parseInt(firstrow5) +
              parseInt(firstrow6) +
              parseInt(firstrow7) +
              parseInt(firstrow8) +
              parseInt(firstrow9) +
              parseInt(firstrow10) +
              parseInt(firstrow11)
            ).toString();

      this.goukei_gaku1 =
        (
          parseInt(secrow1) +
          parseInt(secrow2) +
          parseInt(secrow3) +
          parseInt(secrow4) +
          parseInt(secrow5) +
          parseInt(secrow6) +
          parseInt(secrow7) +
          parseInt(secrow8) +
          parseInt(secrow9) +
          parseInt(secrow10) +
          parseInt(secrow11)
        ).toString() == "0"
          ? ""
          : (
              parseInt(secrow1) +
              parseInt(secrow2) +
              parseInt(secrow3) +
              parseInt(secrow4) +
              parseInt(secrow5) +
              parseInt(secrow6) +
              parseInt(secrow7) +
              parseInt(secrow8) +
              parseInt(secrow9) +
              parseInt(secrow10) +
              parseInt(secrow11)
            ).toString();
      if (this.goukei_gaku0 != null && this.goukei_gaku0 != "") {
        zero = this.replaceComma(this.goukei_gaku0);
        this.goukei_gaku0 = this.formatNumber(this.goukei_gaku0);
      }
      if (this.goukei_gaku1 != null && this.goukei_gaku1 != "") {
        one = this.replaceComma(this.goukei_gaku1);
        this.goukei_gaku1 = this.formatNumber(this.goukei_gaku1);
      }
      this.goukei_gaku =
        (parseInt(zero) + parseInt(one)).toString() == "0"
          ? ""
          : (parseInt(zero) + parseInt(one)).toString();
      this.goukei_gaku = this.formatNumber(this.goukei_gaku);
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number) {
      if (number == "0") {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    clearData() {
      (this.higai_kousyu_gaku = {}),
        (this.kasen_kasyo = ""),
        (this.kasen_gaku = ""),
        (this.kaigankou_kasyo = ""),
        (this.kaigankou_gaku = ""),
        (this.kaigan_kasyo = ""),
        (this.kaigan_gaku = ""),
        (this.sabou_kasyo = ""),
        (this.sabou_gaku = ""),
        (this.jisuberi_kasyo = ""),
        (this.jisuberi_gaku = ""),
        (this.kyukeisya_kasyo = ""),
        (this.kyukeisya_gaku = ""),
        (this.douro_kasyo = ""),
        (this.douro_gaku = ""),
        (this.kyouryou_kasyo = ""),
        (this.kyouryou_gaku = ""),
        (this.kouwan_kasyo = ""),
        (this.kouwan_gaku = ""),
        (this.kouwan_gaku = ""),
        (this.gesuidou_kasyo = ""),
        (this.gesuidou_gaku = ""),
        (this.kouen_kasyo = ""),
        (this.kouen_gaku = ""),
        (this.goukei_kasyo = ""),
        (this.goukei_gaku = ""),
        (this.kasen_kasyo0 = ""),
        (this.kasen_gaku0 = ""),
        (this.kaigankou_kasyo0 = ""),
        (this.kaigankou_gaku0 = ""),
        (this.kaigan_kasyo0 = ""),
        (this.kaigan_gaku0 = ""),
        (this.sabou_kasyo0 = ""),
        (this.sabou_gaku0 = ""),
        (this.jisuberi_kasyo0 = ""),
        (this.jisuberi_gaku0 = ""),
        (this.kyukeisya_kasyo0 = ""),
        (this.kyukeisya_gaku0 = ""),
        (this.douro_kasyo0 = ""),
        (this.douro_gaku0 = ""),
        (this.kyouryou_kasyo0 = ""),
        (this.kyouryou_gaku0 = ""),
        (this.kouwan_kasyo0 = ""),
        (this.kouwan_gaku0 = ""),
        (this.gesuidou_kasyo0 = ""),
        (this.gesuidou_gaku0 = ""),
        (this.kouen_kasyo0 = ""),
        (this.kouen_gaku0 = ""),
        (this.goukei_kasyo0 = ""),
        (this.goukei_gaku0 = ""),
        (this.kasen_kasyo1 = ""),
        (this.kasen_gaku1 = ""),
        (this.kaigankou_kasyo1 = ""),
        (this.kaigankou_gaku1 = ""),
        (this.kaigan_kasyo1 = ""),
        (this.kaigan_gaku1 = ""),
        (this.sabou_kasyo1 = ""),
        (this.sabou_gaku1 = ""),
        (this.jisuberi_kasyo1 = ""),
        (this.jisuberi_gaku1 = ""),
        (this.kyukeisya_kasyo1 = ""),
        (this.kyukeisya_gaku1 = ""),
        (this.douro_kasyo1 = ""),
        (this.douro_gaku1 = ""),
        (this.kyouryou_kasyo1 = ""),
        (this.kyouryou_gaku1 = ""),
        (this.kouwan_kasyo1 = ""),
        (this.kouwan_gaku1 = ""),
        (this.gesuidou_kasyo1 = ""),
        (this.gesuidou_gaku1 = ""),
        (this.kouen_kasyo1 = ""),
        (this.kouen_gaku1 = ""),
        (this.goukei_kasyo1 = ""),
        (this.goukei_gaku1 = "");
    },
    insertUpdate() {
      this.clearError();
      this.info = null;
      var request_data = {
        year: this.year,
        saigai_ken: this.saigai_ken,
        kanrisya_cd: this.kanrisya_cd,
        houkokusya_cd: this.houkokusya_cd,
        higai_kousyu_gaku: [
          {
            year: this.year,
            saigai_ken: this.saigai_ken,
            kanrisya_cd: this.kanrisya_cd,
            houkokusya_cd: this.houkokusya_cd,
            jigyou_cd: this.jigyou_cd0,
            kasen_kasyo: this.replaceComma(this.kasen_kasyo0),
            kasen_gaku: this.replaceComma(this.kasen_gaku0),
            kaigankou_kasyo: this.replaceComma(this.kaigankou_kasyo0),
            kaigankou_gaku: this.replaceComma(this.kaigankou_gaku0),
            kaigan_kasyo: this.replaceComma(this.kaigan_kasyo0),
            kaigan_gaku: this.replaceComma(this.kaigan_gaku0),
            sabou_kasyo: this.replaceComma(this.sabou_kasyo0),
            sabou_gaku: this.replaceComma(this.sabou_gaku0),
            jisuberi_kasyo: this.replaceComma(this.jisuberi_kasyo0),
            jisuberi_gaku: this.replaceComma(this.jisuberi_gaku0),
            kyukeisya_kasyo: this.replaceComma(this.kyukeisya_kasyo0),
            kyukeisya_gaku: this.replaceComma(this.kyukeisya_gaku0),
            douro_kasyo: this.replaceComma(this.douro_kasyo0),
            douro_gaku: this.replaceComma(this.douro_gaku0),
            kyouryou_kasyo: this.replaceComma(this.kyouryou_kasyo0),
            kyouryou_gaku: this.replaceComma(this.kyouryou_gaku0),
            kouwan_kasyo: this.replaceComma(this.kouwan_kasyo0),
            kouwan_gaku: this.replaceComma(this.kouwan_gaku0),
            gesuidou_kasyo: this.replaceComma(this.gesuidou_kasyo0),
            gesuidou_gaku: this.replaceComma(this.gesuidou_gaku0),
            kouen_kasyo: this.replaceComma(this.kouen_kasyo0),
            kouen_gaku: this.replaceComma(this.kouen_gaku0),
            goukei_kasyo: this.replaceComma(this.goukei_kasyo0),
            goukei_gaku: this.replaceComma(this.goukei_gaku0)
          },
          {
            year: this.year,
            saigai_ken: this.saigai_ken,
            kanrisya_cd: this.kanrisya_cd,
            houkokusya_cd: this.houkokusya_cd,
            jigyou_cd: this.jigyou_cd1,
            kasen_kasyo: this.replaceComma(this.kasen_kasyo1),
            kasen_gaku: this.replaceComma(this.kasen_gaku1),
            kaigankou_kasyo: this.replaceComma(this.kaigankou_kasyo1),
            kaigankou_gaku: this.replaceComma(this.kaigankou_gaku1),
            kaigan_kasyo: this.replaceComma(this.kaigan_kasyo1),
            kaigan_gaku: this.replaceComma(this.kaigan_gaku1),
            sabou_kasyo: this.replaceComma(this.sabou_kasyo1),
            sabou_gaku: this.replaceComma(this.sabou_gaku1),
            jisuberi_kasyo: this.replaceComma(this.jisuberi_kasyo1),
            jisuberi_gaku: this.replaceComma(this.jisuberi_gaku1),
            kyukeisya_kasyo: this.replaceComma(this.kyukeisya_kasyo1),
            kyukeisya_gaku: this.replaceComma(this.kyukeisya_gaku1),
            douro_kasyo: this.replaceComma(this.douro_kasyo1),
            douro_gaku: this.replaceComma(this.douro_gaku1),
            kyouryou_kasyo: this.replaceComma(this.kyouryou_kasyo1),
            kyouryou_gaku: this.replaceComma(this.kyouryou_gaku1),
            kouwan_kasyo: this.replaceComma(this.kouwan_kasyo1),
            kouwan_gaku: this.replaceComma(this.kouwan_gaku1),
            gesuidou_kasyo: this.replaceComma(this.gesuidou_kasyo1),
            gesuidou_gaku: this.replaceComma(this.gesuidou_gaku1),
            kouen_kasyo: this.replaceComma(this.kouen_kasyo1),
            kouen_gaku: this.replaceComma(this.kouen_gaku1),
            goukei_kasyo: this.replaceComma(this.goukei_kasyo1),
            goukei_gaku: this.replaceComma(this.goukei_gaku1)
          }
        ],
        login_id: localStorage.getItem("LOGIN_ID")
      };
      apiService
        .fa113Update(request_data)
        .then(result => {
          this.info = result;
          this.getKousyuGakuData(
            this.year,
            this.saigai_ken,
            this.kanrisya_cd,
            this.houkokusya_cd
          );
        })
        .catch(error => {
          console.log(error);
          this.unique_error = error;
          this.info = null;
          
        });
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    },
    clearError() {
      this.unique_error = null;
    }
  }
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info" >{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="unique_error"  class="err">{{ unique_error }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:700px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input
                  type="text"
                  class="form-control bg-light"
                  maxlength="4"
                  v-model="jpYear"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:150px"
              >被害報告番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:105px">
                <input type="text" class="form-control bg-light" v-model="houkokusya_cd" readonly />
              </div>
            </div>
            <div class="form-group row" maxlength="9">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
              <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:105px">
                <input type="text" class="form-control bg-light" v-model="saigai_ken" readonly />
              </div>
              <label for class="col-sm col-form-label" style="width:100%; max-width:125px">異常気象名</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
                <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >災害発生日</label>
              <div class="col-sm pr-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_start" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-center"
                style="width:100%; max-width:50px"
              >〜</label>
              <div class="col-sm pl-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_end" readonly />
              </div>
            </div>
            <div class="form-group row mb-0" style="width:55%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_cd" readonly />
              </div>
              <div class="col-sm pr-0 pl-1" style="width:100%; max-width:500px">
                <input
                  type="text"
                  class="form-control bg-light"
                  v-model="loginUser.kanrisya_name"
                  readonly
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              v-on:click="insertUpdate()"
              type="button"
              style="width:120px;"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="back_btn()"
            >戻る</button>
          </div>
        </div>
        <div class="container table-responsive mt-3">
          <table class="table table-sm border" maxlength="9">
            <thead class="table-primary border-top border">
              <tr>
                <th scope="col" rowspan="2" class="align-middle text-center border" width="17%">工種</th>
                <th scope="col" colspan="2" class="align-middle text-center border" width>県工事</th>
                <th scope="col" colspan="2" class="align-middle text-center border" width>市町村工事</th>
                <th scope="col" colspan="2" class="align-middle text-center border" width>計</th>
              </tr>
              <tr>
                <th scope="col" colspan class="align-middle text-center border" width>箇所数</th>
                <th scope="col" colspan class="align-middle text-center border" width>金額（千円）</th>
                <th scope="col" colspan class="align-middle text-center border" width>箇所数</th>
                <th scope="col" colspan class="align-middle text-center border" width>金額（千円）</th>
                <th scope="col" colspan class="align-middle text-center border" width>箇所数</th>
                <th scope="col" colspan class="align-middle text-center border" width>金額（千円）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="align-middle table-primary border">河川</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kasen_kasyo0"
                    @blur="kasenKasyo1st()"
                    @focus="removeComkasenKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kasen_gaku0"
                    @blur="kasenKasyo2nd()"
                    @focus="removeComkasenKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kasen_kasyo1"
                    @blur="kasenKasyo1st()"
                    @focus="removeComkasenKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kasen_gaku1"
                    @blur="kasenKasyo2nd()"
                    @focus="removeComkasenKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kasen_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kasen_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">海岸（港湾に係るもの）</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigankou_kasyo0"
                    @blur="kaigankouKasyo1st()"
                    @focus="removeComkaigankouKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigankou_gaku0"
                    @blur="kaigankouKasyo2nd()"
                    @focus="removeComkaigankouKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigankou_kasyo1"
                    @blur="kaigankouKasyo1st()"
                    @focus="removeComkaigankouKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigankou_gaku1"
                    @blur="kaigankouKasyo2nd()"
                    @focus="removeComkaigankouKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kaigankou_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kaigankou_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">海岸（その他）</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigan_kasyo0"
                    @blur="kaiganKasyo1st()"
                    @focus="removeComkaiganKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigan_gaku0"
                    @blur="kaiganKasyo2nd()"
                    @focus="removeComkaiganKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigan_kasyo1"
                    @blur="kaiganKasyo1st()"
                    @focus="removeComkaiganKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kaigan_gaku1"
                    @blur="kaiganKasyo2nd()"
                    @focus="removeComkaiganKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kaigan_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kaigan_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">砂防設備</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="sabou_kasyo0"
                    @blur="sabouKasyo1st()"
                    @focus="removeComsabouKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="sabou_gaku0"
                    @blur="sabouKasyo2nd()"
                    @focus="removeComsabouKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="sabou_kasyo1"
                    @blur="sabouKasyo1st()"
                    @focus="removeComsabouKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="sabou_gaku1"
                    @blur="sabouKasyo2nd()"
                    @focus="removeComsabouKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="sabou_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="sabou_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">地すべり防止施設</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="jisuberi_kasyo0"
                    @blur="jisuberiKasyo1st()"
                    @focus="removeComjisuberiKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="jisuberi_gaku0"
                    @blur="jisuberiKasyo2nd()"
                    @focus="removeComjisuberiKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="jisuberi_kasyo1"
                    @blur="jisuberiKasyo1st()"
                    @focus="removeComjisuberiKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="jisuberi_gaku1"
                    @blur="jisuberiKasyo2nd()"
                    @focus="removeComjisuberiKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="jisuberi_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="jisuberi_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">急傾斜地崩壊防止施設</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyukeisya_kasyo0"
                    @blur="kyukeisyaKasyo1st()"
                    @focus="removeComkyukeisyaKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyukeisya_gaku0"
                    @blur="kyukeisyaKasyo2nd()"
                    @focus="removeComkyukeisyaKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyukeisya_kasyo1"
                    @blur="kyukeisyaKasyo1st()"
                    @focus="removeComkyukeisyaKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyukeisya_gaku1"
                    @blur="kyukeisyaKasyo2nd()"
                    @focus="removeComkyukeisyaKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kyukeisya_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kyukeisya_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">道路</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="douro_kasyo0"
                    @blur="douroKasyo1st()"
                    @focus="removeComdouroKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="douro_gaku0"
                    @blur="douroKasyo2nd()"
                    @focus="removeComdouroKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="douro_kasyo1"
                    @blur="douroKasyo1st()"
                    @focus="removeComdouroKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="douro_gaku1"
                    @blur="douroKasyo2nd()"
                    @focus="removeComdouroKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="douro_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="douro_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">橋梁</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyouryou_kasyo0"
                    @blur="kyouryouKasyo1st()"
                    @focus="removeComkyouryouKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyouryou_gaku0"
                    @blur="kyouryouKasyo2nd()"
                    @focus="removeComkyouryouKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyouryou_kasyo1"
                    @blur="kyouryouKasyo1st()"
                    @focus="removeComkyouryouKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kyouryou_gaku1"
                    @blur="kyouryouKasyo2nd()"
                    @focus="removeComkyouryouKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kyouryou_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kyouryou_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">港湾</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouwan_kasyo0"
                    @blur="kouwanKasyo1st()"
                    @focus="removeComkouwanKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouwan_gaku0"
                    @blur="kouwanKasyo2nd()"
                    @focus="removeComkouwanKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouwan_kasyo1"
                    @blur="kouwanKasyo1st()"
                    @focus="removeComkouwanKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouwan_gaku1"
                    @blur="kouwanKasyo2nd()"
                    @focus="removeComkouwanKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kouwan_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kouwan_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">下水道</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="gesuidou_kasyo0"
                    @blur="gesuidouKasyo1st()"
                    @focus="removeComgesuidouKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="gesuidou_gaku0"
                    @blur="gesuidouKasyo2nd()"
                    @focus="removeComgesuidouKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="gesuidou_kasyo1"
                    @blur="gesuidouKasyo1st()"
                    @focus="removeComgesuidouKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="gesuidou_gaku1"
                    @blur="gesuidouKasyo2nd()"
                    @focus="removeComgesuidouKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="gesuidou_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="gesuidou_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">公園</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouen_kasyo0"
                    @blur="kouenKasyo1st()"
                    @focus="removeComkouenKasyo1st()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouen_gaku0"
                    @blur="kouenKasyo2nd()"
                    @focus="removeComkouenKasyo2nd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouen_kasyo1"
                    @blur="kouenKasyo1st()"
                    @focus="removeComkouenKasyo3rd()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control p-1 flex-fill text-right"
                    maxlength="9"
                    v-model="kouen_gaku1"
                    @blur="kouenKasyo2nd()"
                    @focus="removeComkouenKasyo4th()"
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kouen_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="kouen_gaku"
                    readonly
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" class="align-middle table-primary border">計</th>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="goukei_kasyo0"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="goukei_gaku0"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="goukei_kasyo1"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="goukei_gaku1"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="goukei_kasyo"
                    readonly
                  />
                </td>
                <td class="align-middle border" width>
                  <input
                    type="text"
                    class="form-control bg-light p-1 flex-fill text-right"
                    v-model="goukei_gaku"
                    readonly
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </main>
  </AppLayout>
</template>