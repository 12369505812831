<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import { VueRecaptcha } from "vue-recaptcha";

const apiService = new APIService();

export default {
  name: "M1100",
  data() {
    return {
      loginId: window.localStorage.getItem("REMEMBER_LOGIN_ID") ? window.localStorage.getItem("REMEMBER_LOGIN_ID") : "",
      password: window.localStorage.getItem("REMEMBER_PASSWORD") ? window.localStorage.getItem("REMEMBER_PASSWORD") : "",
      rememberMe: window.localStorage.getItem("REMEMBER") ? window.localStorage.getItem("REMEMBER") : false,
      error: null,
      isTest: window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
      sendflag: null,
      recaptchaToken: "",
      isDisabled: true,
    };
  },
  components: {
    AppLayout,
    VueRecaptcha,
  },
  methods: {
    login() {
      var request_data = {
        loginId: this.loginId,
        password: this.password,
        rememberMe: this.rememberMe,
        recaptchaToken: this.recaptchaToken,
      };
      apiService
        .loginUser(request_data)
        .then((result) => {
          apiService.setLocalStorage(result);
          if (result.alreadyLogin) {
            var request_data = {
              loginId: localStorage.getItem("LOGIN_ID"),
              password: localStorage.getItem("REMEMBER_PASSWORD"),
              rememberMe: localStorage.getItem("REMEMBER"),
              token: result.token,
            };
            apiService
              .overwriteLogin(request_data)
              .then((result) => {
                this.localStorageAndChangeMenu(result);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$router.push({
              name: "M1101",
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    localStorageAndChangeMenu(result) {
      apiService.setLocalStorage(result);
      this.$router.push({
        name: "M1101",
      });
    },
    onVerify(response) {
      this.recaptchaToken = response; //レスポンスのトークンをセット
      this.isDisabled = false;
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.isDisabled = true;
    },
  },
};
</script>

<template>
  <AppLayout>
    <main class="mb-3">
      <p class="col-md-12 bg-err text-center err">{{ error }}</p>
      <div class="container text-center">
        <h4 class="font-weight-normal text-primary">ユーザーIDとパスワードを入力してログインして下さい。</h4>
      </div>
      <div class="container login-body rounded">
        <form class="form-signin" @submit.prevent="login">
          <label for="inputEmail" class="sr-only">ユーザーID</label>
          <input type="user-id" id="inputEmail" class="form-control" style="ime-mode: disabled" v-model="loginId" placeholder="ユーザーID" required autofocus />
          <label for="inputPassword" class="sr-only">パスワード</label>
          <input type="password" id="inputPassword" class="form-control" style="ime-mode: disabled" v-model="password" placeholder="パスワード" required />
          <div class="checkbox mb-2">
            <label> <input type="checkbox" v-model="rememberMe" /> ログイン情報を記録 </label>
          </div>
          <vue-recaptcha class="mb-2" :sitekey="this.$constants.RECAPTCHA_SITE_KEY" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
          <button class="btn btn-lg btn-block btn-primary" :class="this.isTest ? 'test-btn-primary' : ''" :disabled="this.isDisabled">ログイン</button>
        </form>
      </div>
      <div class="container text-center">
        <router-link :to="{ name: 'M1102' }" class="pwd-link font-weight-normal text-primary"> パスワード変更画面へ </router-link>
      </div>
    </main>
  </AppLayout>
</template>
