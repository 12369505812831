<template>
  <Layout>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="isHasInfo">{{ infoMsg }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="isHasError" class="err">{{ dataNotFoundMsg }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
        <form class="form-inline float-right">
          <button type="button" class="btn btn-light" style="width:120px;" @click="goFC101()">新規</button>
        </form>
      </div>
      <div class="container mb-3">
        <form action method>
          <div
            class="form-group row"
            style="width:100%;"
            :class="!engYearErr || nji_sateiErr ? 'mb-0':''"
          >
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control bg-required"
                maxlength="4"
                v-model="jpYear"
                @blur="changedYear()"
              />

            </div>
            <label
              for
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:30px"
            >年</label>
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">査定</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="tel" class="form-control bg-required" maxlength="9" v-model="nji_satei" @blur="nji_sateiChange()"/>
            </div>
            <label
              for
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:30px"
            >次</label>
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">管理者</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select
                class="form-control bg-required"
                v-model="kanrisya_cd"
                v-on:change="kanrisyaChange($event)"
              >
                <option
                  v-for="kanrisya in kanrisyaList"
                  :key="kanrisya.kanrisya_cd"
                  :value="kanrisya.kanrisya_cd"
                >{{kanrisya.kanrisya_cd}} : {{kanrisya.kanrisya_mei}} </option>
              </select>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <div >
              <span style class="col-sm-2 err" v-show="engYearErr">{{ engYearErr }}</span>
            </div>
            <div class="pl-10" :class="!engYearErr? 'offset-sm-2':''">
              <span
                class="col-sm-3 err"
                :class="!engYearErr? 'pl-0':'pl-4'"
                v-show="nji_sateiErr"
              >{{ nji_sateiErr }}</span>
            </div>
            <div :class="!nji_sateiErr? 'pl-10 offset-sm-2':''" >
              <span class="col-sm-3 err"  v-show="kanrisyaErrorMsg">{{ kanrisyaErrorMsg }}</span>
            </div>  
          </div>
          <button
            type="button"
            @click="getSaigaiSateiData()"
            class="btn btn-light d-block mx-auto"
            style="width:120px;"
          >検索</button>
        </form>
      </div>
      <div class="container pt-3 border-top border-secondary" v-show="showTable">
        <form class="clearfix" action="saigai_satei_r_u_d.html">
          <div class="float-left">
            <p class="text-info">修正・削除行を選択して下さい</p>
          </div>
          <div class="float-right">
            <span>［災害査定 / 国庫負担申請］</span>
            {{this.saigai_satei_cnt}} 件 / {{this.kotuko_sinsei_cnt}} 件
          </div>
        </form>
      </div>
      <div class="container" v-show="showTable">
        <div class="row">
          <div class="col-sm-4">
            <table class="table-sm">
              <tr>
                <td class="align-middle">検索結果</td>
                <td
                  width="130px"
                  class="bg-light text-center table-bordered align-middle"
                >{{saigaisateiDataList.total}}</td>
                <td class="text-center align-middle">件</td>
              </tr>
            </table>
          </div>
          <div class="col-sm-4">
            <b-pagination
              v-model="saigaisateiDataList.current_page"
              :total-rows="saigaisateiDataList.total"
              :data="saigaisateiDataList.data"
              :per-page="saigaisateiDataList.per_page"
              @change="getSaigaiSateiData"
            ></b-pagination>
          </div>
          <div class="col-sm-4 text-right align-middle">
            <p
              class="mb-0 mt-2"
            >{{saigaisateiDataList.current_page}}/{{saigaisateiDataList.last_page}}項</p>
          </div>
        </div>
      </div>
      <div class="container mt-2 table-responsive" v-show="showTable">
        <table class="table text-center table-bordered table-sm">
          <thead class="table-dark">
            <tr>
              <th rowspan="2" class="align-middle" width="4%">
                選
                <br />択
              </th>
              <th rowspan="2" class="align-middle" width="5%">
                工事
                <br />番号
              </th>
              <th rowspan="2" class="align-middle" width="3%">
                分
                <br />冊
              </th>
              <th class="align-middle" width="16%">管理者</th>
              <th class="align-middle" width="14%">工種</th>
              <th class="align-middle" width="15%">市町村</th>
              <th colspan="3" class="align-middle">町名・字名／地先</th>
              <th rowspan="2" class="align-middle" width="19%">情報入力</th>
            </tr>
            <tr>
              <th colspan="3" class="align-middle">河川・路線／橋梁</th>
              <th class="align-middle" width="8%">被災延長</th>
              <th class="align-middle" width="8%">申請額</th>
              <th class="align-middle" width="8%">決定額</th>
            </tr>
          </thead>
          <tbody
            v-for="(saigaisateiData, index) in saigaisateiDataList.data"
            :key="index"
            :value="saigaisateiData.koji_no"
          >
            <tr :class="index%2 != 0 ? 'table-secondary':''">
              <td rowspan="2" class="align-middle">
                <button class="btn btn-light" type="button" @click="editSaigaiSateiData(saigaisateiData)" style="width:40px;height:100%;font-size:14px;" >選<br>択</button>
              </td>
              <td rowspan="2" class="align-middle" align="left">{{ saigaisateiData.koji_no}}</td>
              <td rowspan="2" class="align-middle" align="left">{{ saigaisateiData.bunsatu_no}}</td>
              <td
                class="align-middle"
                align="left"
              >{{ saigaisateiData.kanrisya_cd }}&nbsp;{{ saigaisateiData.kanrisya_mei }}</td>
              <td
                class="align-middle"
                align="left"
              >{{ saigaisateiData.kousyu_cd }}&nbsp;{{ saigaisateiData.kousyu_name}}</td>
              <td
                class="align-middle"
                align="left"
              >{{ saigaisateiData.shicyou_cd }}&nbsp;{{ saigaisateiData.shicyou_name}}</td>
              <td
                colspan="3"
                class="align-middle"
                align="left"
              >{{ saigaisateiData.cyoaza_cd }}&nbsp;{{ saigaisateiData.cyouaza_name}}/{{ saigaisateiData.chisaki}}</td>
              <td rowspan="2" class="align-middle" align="center">
                <button class="btn btn-success" style="width:90px;" @click="goFC102(index)" role="button">工事概要</button>
              </td>
            </tr>
            <tr :class="index%2 != 0 ? 'table-secondary':''">
              <td
                colspan="3"
                class="align-middle"
                align="left"
              >{{ saigaisateiData.rokasen_cd}}&nbsp;{{ saigaisateiData.rokasen_mei}}/{{ saigaisateiData.rokyouryou_mei}}</td>
              <td class="align-middle" align="right">{{ saigaisateiData.hisai_encyou|formatNumeric }}</td>
              <td class="align-middle" align="right">{{ saigaisateiData.sinsei_gaku|formatNumber }}</td>
              <td class="align-middle" align="right">{{ saigaisateiData.kettei_gaku|formatNumber }}</td>
              <td v-if="false" class="align-middle" align="center">
                <button class="btn btn-success" style="width:90px;margin-right:5px;" @click="goFC103(index)" role="button">改良復旧</button>
                <button class="btn btn-success" style="width:90px;" @click="goFC104(index)" role="button">内未･転</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container clearfix" v-show="showTable">
        <table class="table table-sm table-borderless float-right">
          <tr>
            <td width="49%"></td>
            <th width="10%" class="text-center border align-middle">箇所数</th>
            <td
              width="13%"
              class="bg-light text-center border align-middle"
            >{{ saigaisateiDataList.total|formatNumber }}</td>
            <th width="13%" class="text-center border align-middle">決定額計</th>
            <td
              width="15%"
              class="bg-light text-center border align-middle"
            >{{ totalAmount|formatNumber }}</td>
          </tr>
        </table>
      </div>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from 'moment';
const apiService = new APIService();

var numeral = require("numeral");
export default {
  components: {
    Layout
  },
  data() {
    var kanCd = "";
    var kanMei = "";
    if(this.authorityAdmin()) {
      kanCd = '0';
      kanMei = 'すべて';
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      jpYear:"",
      jpYearFlg: true,
      engYearErr:"",
      nji_sateiErr: "",
      engYear: "",
      kanrisyaList: [],
      err: "",
      kanrisya1Check: false,
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      nji_satei: "",
      saigaisateiDataList: {
        total: 10000
      },
      checkedSaigaisateiData: [],
      houkokuselectedIndex: 0,
      totalAmount: 0,
      showTable: false,
      isHasInfo: false,
      infoMsg: "",
      isHasError: false,
      dataNotFoundMsg: null,
      saigai_satei_cnt:0,
      kotuko_sinsei_cnt:0,
      kanrisyaErrorMsg: '',
    };
  },
  computed: {
    yearSV() {
      return this.$store.state.fc100.year;
    },
    nji_sateiSV() {
      return this.$store.state.fc100.nji_satei;
    },
    kanrisya_cdSV() {
      return this.$store.state.fc100.kanrisya_cd;
    },
    kanrisya_meiSV() {
      return this.$store.state.fc100.kanrisya_mei;
    },
    pageSV() {
      return this.$store.state.fc100.page;
    }
  },
  mounted() {
    if (this.yearSV) {
      (this.engYear = this.yearSV),
        (this.nji_satei = this.nji_sateiSV),
        (this.kanrisya_cd = this.kanrisya_cdSV),
        this.kanrisya_mei = this.kanrisya_meiSV,
        (this.page = this.pageSV),
      this.getKanrisya();
      this.getGengoSV(this.engYear);
    } else {
      this.engYear = new Date().getFullYear();
      this.getGengo(this.engYear);
      this.getKanrisya();
    }
  },
  filters: {
    formatNumber: function(number) {
      return numeral(number).format("0,0");
    },
    formatNumeric: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
  },
  methods: {
    getGengoSV(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;
        this.getSaigaiSateiData(this.pageSV);
      });	
    },
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    changedYear(){
      if(this.jpYear != '' && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
        var request_data = {	
          gengo_cd: matches ? matches.toString() : "",	
        }
        apiService.getGengoStartDate(request_data)	
        .then(result=>{
          if(result.length > 0) {
            this.jpYearFlg = true;
            var startDate = result[0].start_date;	
            this.engYear = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);
          } else {
            this.jpYearFlg = false;
            this.engYear = this.jpYear;
          }
        });
      } else {
        if(!this.jpYear) {
          this.engYear = null;
        } else {
          this.engYear = this.jpYear;
          this.jpYearFlg = false;
        }
      }
    },
    getKanrisya() {
      if (localStorage.getItem("Kanrisya_Cd") == 1) {
        this.kanrisya1Check = true;
      }
      var request_data = {
        kengen_cd: localStorage.getItem('kengen_cd'),
        kanrisya_cd: localStorage.getItem('Kanrisya_Cd'),
      };
      apiService
        .getKanrisyaByFlg(request_data)
        .then(result => {
          this.kanrisyaList = result;
        })
        .catch(error => {
          this.err = error;
        });
    },
    kanrisyaChange(kanrisya) {
      var kanrisya_index =
        kanrisya.target.options[kanrisya.target.options.selectedIndex].text;
      for (var i = 0; i < this.kanrisyaList.length; i++) {
        if (this.kanrisyaList[i].kanrisya_cd == kanrisya_index) {
          this.kanrisya_cd = kanrisya_index;
          this.kanrisya_mei = this.kanrisyaList[i].kanrisya_mei;
        }
      }
    },
    getSaigaiSateiData(page) {
      var errFlg = false;
        if (!this.jpYear) {
          confirm(this.$messages.msg['0013E']);
          this.engYearErr = this.$messages.msg["0004W"];
          this.showTable = false;
          this.isHasInfo = false;
          this.isHasError = false;
          errFlg = true;
        } else if (!this.jpYearFlg)
        {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = '災害年' + this.$messages.msg["0002W"];
          this.showTable = false;
          this.isHasInfo = false;
          this.isHasError = true;
          errFlg = true;
        } else {
          this.engYearErr = null;
        }

      if (!this.nji_satei) {
        confirm(this.$messages.msg['0013E']);
        this.nji_sateiErr = "査定" + this.$messages.msg["00013W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        if (!this.isInteger(this.nji_satei)) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = "査定" + this.$messages.msg["0002W"];
          this.isHasError = true;
          this.showTable = false;
          this.isHasInfo = false;
          this.nji_sateiErr = null;
          errFlg = true;
        }
        else {
          this.nji_sateiErr = null;
        }
      }
      if(this.kanrisya_cd == null || this.kanrisya_cd === ""){
        confirm(this.$messages.msg['0013E']);
        this.kanrisyaErrorMsg = "管理者" + this.$messages.msg['00013W'];
        this.showTable = false;
        this.isHasError = false;
        this.isLoading = false;
        errFlg = true;
      }
      if (!errFlg) {
        if (!page) {
          page = 1;
        }
        this.totalAmount = 0;
        this.page = page;
        var request_data = {
          year: this.engYear,
          nji_satei: this.nji_satei,
          kanrisya_cd: this.kanrisya_cd,
          page: page
        };
        if (request_data.kanrisya_cd == 0) {
          this.showTable = false;
        }
        apiService
          .fc100getSaigaiSateiData(request_data)
          .then(result => {
            var paginatedData = result['paginatedData'];
            this.saigaisateiDataList = paginatedData;
            if (this.saigaisateiDataList.data.length > 0) {
              this.clearMsg();
              this.showTable = true;
              
            } else {
              confirm(this.$messages.msg['0013E']);
              this.showTable = false;
              this.isHasError = true;
              this.isHasInfo = false;
              this.dataNotFoundMsg = this.$messages.msg["0010E"];
            }
            this.checkedSaigaisateiData = this.saigaisateiDataList.data[0];
            this.totalAmount = result['totalAmount'];

            this.saigai_satei_cnt = 0;
            if (result['saigai_satei_cnt']) {
                this.saigai_satei_cnt = result['saigai_satei_cnt'];
            }
            this.kotuko_sinsei_cnt = 0;
            if (result['kotuko_sinsei_cnt']) {
                this.kotuko_sinsei_cnt = result['kotuko_sinsei_cnt'];
            }
            this.saigaisateiDataList.current_page = page;
          })
          .catch(error => {
            console.log(error);
            confirm(this.$messages.msg['0013E']);
            this.dataNotFoundMsg = error;
            this.isHasError = true;
          });
      }
    },
    formatInteger: function(number){
      if(number == '' || number == null){
        return '';
      }
      var result = numeral(number).format("0");
      
      return result == 0 ? '': result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    nji_sateiChange(){
      if (this.isInteger(this.nji_satei)) {
        this.nji_satei = this.nji_satei
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    goFC101() {
      var errFlg = false;
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.engYearErr = this.$messages.msg["0004W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        this.engYearErr = null;
      }
      if (!this.nji_satei) {
        confirm(this.$messages.msg['0013E']);
        this.nji_sateiErr = "査定" + this.$messages.msg["00013W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        if (!this.isInteger(this.nji_satei)) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg =  "査定" + this.$messages.msg["0002W"];
          this.isHasError = true;
          this.showTable = false;
          this.isHasInfo = false;
          this.nji_sateiErr = null;
          errFlg = true;
        }
        else {
          this.nji_sateiErr = null;
        }
      }
      if (!errFlg) {
        this.setState();
        this.$router.push({
            name: "FC101",
            query: {
                saigai_yyyy: this.engYear,
                nji_satei: this.nji_satei,
                kanrisya_cd: this.kanrisya_cd == 0 ? 1 : this.kanrisya_cd,
                kbn: 1
            }
        });
      }
    },
    editSaigaiSateiData(saigaisateiData) {
      this.checkedSaigaisateiData = saigaisateiData;
      var errFlg = false;
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.engYearErr = this.$messages.msg["0004W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        this.engYearErr = null;
      }
      if (!this.nji_satei) {
        confirm(this.$messages.msg['0013E']);
        this.nji_sateiErr = "査定" + this.$messages.msg["00013W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        if (!this.isInteger(this.nji_satei)) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg =  "査定" + this.$messages.msg["0002W"];
          this.isHasError = true;
          this.showTable = false;
          this.isHasInfo = false;
          this.nji_sateiErr = null;
          errFlg = true;
        }
        else {
          this.nji_sateiErr = null;
        }
      }
      if (!errFlg) {
        this.setState();
        this.$router.push({
            name: "FC101",
            query: {
              saigai_yyyy: this.engYear,
              nji_satei: this.nji_satei,
              koji_no: this.checkedSaigaisateiData.koji_no,
              bunsatu_no: this.checkedSaigaisateiData.bunsatu_no,
              kanrisya_cd: this.checkedSaigaisateiData.kanrisya_cd,
              kbn: 2
            }
        });
      }
    },
    goFC102(index) {
      var errFlg = false;
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.engYearErr = this.$messages.msg["0004W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        this.engYearErr = null;
      }
      if (!errFlg) {
        this.setState();
        this.$router.push({
          name: "FC102",
          query: {
            saigai_yyyy: this.engYear,
            koji_no: this.saigaisateiDataList.data[index].koji_no,
            bunsatu_no: this.saigaisateiDataList.data[index].bunsatu_no,
            kanrisya_cd: this.saigaisateiDataList.data[index].kanrisya_cd
          }
        });
      }
    },
    goFC103(index) {
      var errFlg = false;
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.engYearErr = this.$messages.msg["0004W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        this.engYearErr = null;
      }
      if (!errFlg) {
        this.setState();
        this.$router.push({
          name: "FC103",
          query: {
            saigai_yyyy: this.engYear,
            koji_no: this.saigaisateiDataList.data[index].koji_no,
            bunsatu_no: this.saigaisateiDataList.data[index].bunsatu_no,
            kanrisya_cd: this.saigaisateiDataList.data[index].kanrisya_cd
          }
        });
      }
    },
    goFC104(index) {
      var errFlg = false;
      if (!this.jpYear) {
        confirm(this.$messages.msg['0013E']);
        this.engYearErr = this.$messages.msg["0004W"];
        this.showTable = false;
        this.isHasInfo = false;
        this.isHasError = false;
        errFlg = true;
      } else {
        this.engYearErr = null;
      }
      if (!errFlg) {
        this.setState();
        this.$router.push({
          name: "FC104",
          query: {
            saigai_yyyy: this.engYear,
            koji_no: this.saigaisateiDataList.data[index].koji_no,
            bunsatu_no: this.saigaisateiDataList.data[index].bunsatu_no,
            kanrisya_cd: this.saigaisateiDataList.data[index].kanrisya_cd
          }
        });
      }
    },
    setState() {
      this.$store.commit("fc100/setYear", this.engYear);
      this.$store.commit("fc100/setNjiSatei", this.nji_satei);
      this.$store.commit("fc100/setKanrisyaCd", this.kanrisya_cd);
      this.$store.commit("fc100/setKanrisyaMei", this.kanrisya_mei);
      this.$store.commit("fc100/setPage", this.page);
    },
    authorityAdmin(){
      var kengen_cd = localStorage.getItem('kengen_cd');
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    },
    clearMsg() {
      (this.isHasError = false),
      (this.dataNotFoundMsg = null),
      (this.isHasInfo = false),
      (this.infoMsg = null),
      (this.engYearErr = null),
      (this.nji_sateiErr = null);
      (this.kanrisyaErrorMsg = null);
    }
  }
};
</script>