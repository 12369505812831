<script>
import Layout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { APIService } from "@/apiService.js";
import Loading from 'vue-loading-overlay';

var numeral = require("numeral");
const apiService = new APIService();

export default {
  name: "fb106",
  components: {
    Layout,
    Loading
  },
  data() {
    return {
      jpYear:"",
      saigai_yyyy: "",
      saigai_yyyy_flg: true,
      gengo_name: "",
      todayDate: "",
      saigai_ken: "",
      meisyouData: {},
      meisyouList: {},
      options: [],
      isOptionSelected: false,
      kouji_furiwake_m: {},
      saigai_ken_start: "",
      saigai_mei: "",
      showTable: false,
      furiwake: false,
      koji_no: 0,
      shi_koji_no: 0,
      saigai_houkoku: {},
      data: {},
      insert_count: 0,
      isLoading: false,
      fullPage: true,
      YearErr: "",
      kenErr: "",
      info: "",
      error: "",
      chk_date: "",
      exclusive_flg: true
    };
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.saigai_yyyy = (new Date().getFullYear()).toString();
    this.getGengo(new Date().getFullYear());
    this.getGengoName(new Date().getFullYear());
    this.getSaigaiMeisyou(new Date().getFullYear());
  },
  filters: {
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    }
  },

  methods: {
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    },
    formatDateTime: function(date) {
      if (date == "" || date == null) {
        return date;
      }
      return moment(String(date)).format("MM/DD HH:MM");
    },
    formatDate: function(date) {
      if (date == "" || date == null) {
        return date;
      }
      return moment(String(date)).format("YYYY/MM/DD");
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    changedYear() {
      this.error = null;
      this.info = null;
      this.YearErr = null;
      this.kenErr = null;
      this.showTable = false;
      if (this.jpYear != "" && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace(/[^\d.]/g, "");	
        var request_data = {	
          gengo_cd: matches ? matches.toString() : ""	
        };
        apiService.getGengoStartDate(request_data).then(result => {
          if(result.length > 0)
          {
            var startDate = result[0].start_date;	
            this.saigai_yyyy = parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);	
            this.saigai_yyyy_flg = true;
            this.isOptionSelected = false;
            this.getSaigaiMeisyou(this.saigai_yyyy);
          } else {
            this.saigai_yyyy = this.jpYear;
            this.saigai_yyyy_flg = false;
            this.meisyouData = {};
            this.meisyouList = [];
            this.options = null;
            this.saigai_ken = null;
          }
        });
      } else {
        if(!this.jpYear)
        {
          this.saigai_yyyy = "";
        } else {
          this.saigai_yyyy_flg = false;
        }
        this.meisyouList = [];
        this.meisyouData = {};
        this.options = null;
        this.saigai_ken = null;
      }
      this.error = null;
      this.info = null;
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.year = result;
        this.jpYear = result;
      });
    },
    getGengoName(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengoName(request_data).then(result => {
        this.gengo_name = result;
      });
    },
    getSaigaiMeisyou(year) {
      var request_data = {
        year: year
      };
      apiService.fb106GetSaigaiMeisyou(request_data).then(result => {       
        if (result['meisyouList'].length > 0) {
          this.meisyouList = result['meisyouList'];
          // 排他チェック用
          this.chk_date = result['chk_date'];

        } else {
          this.options = null;
          this.saigai_ken = null;
        }
        this.isLoading = false;
      });
    },
    onChange(saigai_ken) {
      this.error = null;
      this.info = null;
      this.showTable = false;
      this.saigai_ken = saigai_ken
    },
    searchData() {
      this.error = null;
      this.info = null;
      var kenFlg = false;
      var yrFlg  = false;
      if (this.saigai_yyyy == "" || this.saigai_yyyy == null) {
        confirm(this.$messages.msg['0013E']);
        this.YearErr = this.$messages.msg["0004W"];
        yrFlg = true;
      } else if (!this.saigai_yyyy_flg)
      {
        confirm(this.$messages.msg['0013E']);
        this.error = '災害年' + this.$messages.msg["0002W"];
      } else {
        this.YearErr = null;
      }
      if (this.saigai_ken == "" || this.saigai_ken == null) {
        confirm(this.$messages.msg['0013E']);
        this.kenErr = "災害番号" + this.$messages.msg["00013W"];
        kenFlg = true;
      } else {
        this.kenErr = null;
      }
      if (!yrFlg && !kenFlg) {
        this.showFuriwakeM();
      }
    },
    showFuriwakeM(){
        var request_data = {
          saigai_yyyy: this.saigai_yyyy,
          saigai_ken_start: this.saigai_ken
        };
        apiService
          .fb106GetKoujifuriwake(request_data)
          .then(result => {
            if (result['kouji_furiwake_m'].length > 0) {
              this.showTable = true;
              this.todayDate = this.saigai_yyyy + "/" + this.formatDateTime(new Date());
              this.kouji_furiwake_m = result['kouji_furiwake_m'];
              this.saigai_ken_start = String(this.kouji_furiwake_m[0].saigai_ken_start).padStart(5, "0");
              this.saigai_mei = this.kouji_furiwake_m[0].saigai_mei;

              this.chk_date = result['chk_date']
            } else {
              confirm(this.$messages.msg['0013E']);
              this.showTable = false;
            }
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.error = error;
            this.showTable = false;
          });
    },
    furiwake_shorijiko() {
        this.error = null;
        this.info = null;
        if (confirm("工事番号振分処理を行います。よろしいでしょうか？")) {
        
            // 排他チェック
            if (this.chk_date !== "") {
                var chk_data = {
                    saigai_yyyy    : this.saigai_yyyy
                    , saigai_ken   : this.saigai_ken
                    , furiwake_flg : this.furiwake == "ON" ? true : false
                    , chk_date     : this.chk_date
                };
                apiService.fb106ChkFuriwakeData(chk_data)
                .then(result => {
                    if (!result) {
                        return false;
                    }

                    //再振分チェックボックス＝ONの場合
                    if (this.furiwake == "ON") {
                        var register_data = {
                            saigai_yyyy: this.saigai_yyyy
                            , status: this.$constants.FB106["HONTOROKU"]
                            , ken: this.saigai_ken
                        };
                        apiService.fb106GetKotukoSinsei(register_data).then(result => {
                            if (result.length > 0) {
                                confirm(this.$messages.msg['0013E']);
                                this.error = this.$messages.msg["0011E"];
                            } else {
                                var temporary_data = {
                                    saigai_yyyy: this.saigai_yyyy
                                    , status: this.$constants.FB106["KARITOROKU"]
                                    , ken: this.saigai_ken
                                };
                                apiService.fb106GetKotukoSinsei(temporary_data).then(result => {
                                    if (result.length > 0) {
                                        if (confirm("一度データを削除し再登録する為、事務所または市町村に確認してから実行してください。問題なければ”OK”，中止する場合は”キャンセル")) {
                                            //delete furiwake_m and update saigai_houkoku
                                            this.deleteKojibangoFuriwake();
                                        }
                                    } else {
                                        //delete furiwake_m and update saigai_houkoku
                                        this.deleteKojibangoFuriwake();
                                    }
                                });
                            }
                        });
                    } else {
                        //再振分チェックボックス＝OFFの場合
                        //工事番号の確認及び抽出
                        this.confirmKojibango();
                    }
                })
                .catch(error => {
                    confirm(this.$messages.msg['0013E']);
                    this.error = error;
                });
            }
        }
    },
    confirmKojibango() {
      this.error = null;
      this.info = null;
      var request_data = {
        year: this.saigai_yyyy
      };
      apiService.fb106Koujino(request_data).then(result => {
        if (result.koji_no[0].koji_no) {
          this.koji_no = result.koji_no[0].koji_no;
        } else {
          this.koji_no = 0;
        }
        if (result.shi_koji_no[0].shi_koji_no) {
          this.shi_koji_no = result.shi_koji_no[0].shi_koji_no;
        } else {
          this.shi_koji_no = 1000;
        }

        if (
          result.koji_no[0].koji_no == null &&
          result.shi_koji_no[0].shi_koji_no == null
        ) {
          this.koji_no = 0;
          this.shi_koji_no = 1000;
        }
        var request_data = {
          year: this.saigai_yyyy,
          ken: this.saigai_ken,
          koji_no: this.koji_no,
          shi_koji_no: this.shi_koji_no
        };
        apiService
          .fb106SaigaiHoukoku(request_data)
          .then(result => {
            if (result) {
              var required_data = {
                loginId: localStorage.getItem("LOGIN_ID"),
                data: result,
                year: this.saigai_yyyy,
                ken: this.saigai_ken,
                furiwake_flg: this.furiwake == "ON" ? true : false,
              };
              this.$notify({
                group: "foo",
                title: "工事番号振分",
                text: "振分中です。しばらくお待ち下さい。"
              });
              apiService
                .fb106deleteAndInsertData(required_data)
                .then(result => {
                  this.$notify({
                    group: "foo",
                    clear: true
                  });
                  this.info = result;
                  this.showFuriwakeM();
                })
                .catch(error => {
                  confirm(this.$messages.msg['0013E']);
                  console.log(error);
                  this.error = error;
                  this.$notify({
                    group: "foo",
                    clear: true
                  });
                });
            }
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.error = error;
          });
      });
    },
    deleteKojibangoFuriwake() {
      var request_data = {
        year: this.saigai_yyyy,
        saigai_ken: this.saigai_ken,
        loginId: localStorage.getItem("LOGIN_ID")
      };
      apiService
        .fb106DeleteKojifuriwake(request_data)
        .then(result => {
          if (result) {
            //工事番号の確認及び抽出
            this.confirmKojibango();
          }
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.error = error;
        });
    },
    convSaigaiKen(saigai_ken){
      if(!saigai_ken){
        return "";
      }else{
        return ( '00000' + saigai_ken ).slice(-5);
      }  
    },
  }
};
</script>
<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3" v-if="!isLoading">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="error" class="err">{{ error }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
      </div>

      <div class="container mb-3">
        <form action method>
          <div class="form-group row" style="width:100%;" :class="!YearErr || kenErr ? 'mb-0':''">
            <notifications group="foo" position="top center" :width="400" />
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control bg-required"
                v-model="jpYear"
                @blur="changedYear()"
              />
            </div>
            <label
              for
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:50px"
            >年</label>
            <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:350px">
              <select
                @change="onChange(saigai_ken)"
                class="form-control bg-required"
                v-model="saigai_ken"
              >
              <option value=""></option>
              <option v-for="item in meisyouList" :key="item.saigai_ken" :value="item.saigai_ken">{{convSaigaiKen(item.saigai_ken)}} : {{item.saigai_mei}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <div>
              <span style class="col-sm-3 err" v-show="YearErr">{{ YearErr }}</span>
            </div>
            <div :class="!YearErr? 'offset-sm-3':''">
              <span
                class="col-sm-3 err"
                :class="!YearErr? 'pl-3 ml-4':'pl-5 ml-5'"
                v-show="kenErr"
              >{{ kenErr }}</span>
            </div>
          </div>
          <div class="form-group row pt-3 d-block mx-auto" style="text-align:center;">
            <div class="form-check form-check-inline" style="padding-top:6px;">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="furiwake"
              true-value="ON"
              false-value="OFF"
            />
            <label class="form-check-label" for>再振分</label>
            </div>
            <button
              class="btn btn-light"
              href
              type="button"
              style="width:150px;"
              @click="furiwake_shorijiko()"
            >振分処理実行</button>
          </div>
        </form>
      </div>

      <div class="container pt-3 border-top border-secondary">
        <form class="clearfix">
          <div class="form-group float-right">
            <button
              type="button"
              class="btn btn-light d-block mx-auto"
              style="width:120px;"
              @click="searchData()"
            >検索</button>
          </div>
        </form>
      </div>

      <div v-show="showTable">
        <div class="container mt-0">
          <div class="row">
            <div class="col-sm-4">
              <table class="table-sm">
                <tr>
                  <td class="align-middle">{{this.gengo_name}}年</td>
                  <td class="align-middle text-center" width="50px"></td>
                  <td
                    class="text-center align-middle text-overflow" style="max-width: 200px;"
                    v-if="kouji_furiwake_m"
                  >{{this.saigai_ken_start}}: {{this.saigai_mei}}</td>
                </tr>
              </table>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 text-right align-middle">
              <p class="mb-0">{{this.todayDate}}</p>
            </div>
          </div>
        </div>
        <div class="container mt-2 table-responsive">
          <table class="table text-center table-bordered table-sm">
            <thead class="bg-primary text-light">
              <tr>
                <th class="align-middle" width="6%" height="35px">No</th>
                <th class="align-middle" width="18%">事 業 主 体</th>
                <th class="align-middle" width="27%">事 業 者 名</th>
                <th class="align-middle" width="13%">箇 所 数</th>
                <th class="align-middle" width="18%">開 始 工 事 番 号</th>
                <th class="align-middle" width="18%">終 了 工 事 番 号</th>
              </tr>
            </thead>
            <tbody v-for="(furiwake,index) in kouji_furiwake_m" :key="index" :value="index">
              <tr>
                <td class="align-middle" height="35px">{{index+1}}</td>
                <td class="align-middle" height="35px">{{furiwake.cd_value}}</td>
                <td class="align-middle" align="left">{{furiwake.kanrisya_mei}}</td>
                <td class="align-middle" align="right">{{furiwake.kasyosu|formatNumber}}</td>
                <td class="align-middle" align="right">{{furiwake.koji_no_start}}</td>
                <td class="align-middle" align="right">{{furiwake.koji_no_end}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </Layout>
</template>
