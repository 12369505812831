<script>
import AppLayout from "@/components/layouts/Layout.vue";
import moment from "moment";
import { APIService } from "@/apiService.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();
var numeral = require("numeral");

export default {
  name: "FB102",
  components: {
    AppLayout,Loading
  },
  data() {
    return {
      engYear: this.$route.query.saigai_yyyy,
      ikkan_no: this.$route.query.ikkan_no,
      saigai_yyyy: "",
      satei: "",
      koji_no: "",
      kanrisya_cd: "",
      kanrisya_mei: "",
      jigyou_cd: "",
      seiri_no: "",
      ijo_kisho: "",
      hisaibi: "",
      kojigaiyou: [],
      sateikijyous: [],
      sateikijyou: "",
      tumiage_sotans: [],
      tumiage_sotan: "",
      satei_kbn: "",
      sougou_nendo: "",
      sougou_year: "",
      suuryou1: "",
      suuryou2: "",
      suuryou3: "",
      suuryou4: "",
      suuryou5: "",
      suuryou6: "",
      suuryou7: "",
      suuryou8: "",
      naikari_koujihi: "",
      oukyukarikouji_zapi: "",
      youkou1s: [],
      youkou1: "",
      youkou2s: [],
      youkou2: "",
      meisyou1: "",
      meisyou2: "",
      meisyou3: "",
      meisyou4: "",
      meisyou5: "",
      meisyou6: "",
      meisyou7: "",
      meisyou8: "",
      tani1: "",
      tani2: "",
      tani3: "",
      tani4: "",
      tani5: "",
      tani6: "",
      tani7: "",
      tani8: "",
      sinsei_gaku: "",
      eizenhi: "",
      total_E: "",
      total_F: "",
      max_suryo1: 10,
      max_suryo2: 10,
      max_suryo3: 10,
      max_suryo4: 10,
      max_suryo5: 10,
      max_suryo6: 10,
      max_suryo7: 10,
      max_suryo8: 10,
      info: "",
      showInfo: false,
      textError: "",
      showError: false,
      isNumFormat: false,
      numFormatErr: "",
      isUpdate: false,
      isInsert: false,
      errors: {
        satei_kbn: null
      },
      post: null,
      isLoading: false,
      fullPage: true,
      total_koujihi:"",
      total_koujizapi:"",
      chk_date: "",
      sougou_year_flg: false
    };
  },
  watch: {
    suuryou1: function(suuryou1) {
      if (suuryou1 != null) {
        // console.log(heikin_fusoku.length);
        var suryo1 = suuryou1
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo1 !== suuryou1) {
          // Only if something had to be fixed, update
          suuryou1 = suryo1;
        }
        this.max_suryo1 = suuryou1.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou2: function(suuryou2) {
      if (suuryou2 != null) {
        // console.log(heikin_fusoku.length);
        var suryo2 = suuryou2
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo2 !== suuryou2) {
          // Only if something had to be fixed, update
          suuryou2 = suryo2;
        }
        this.max_suryo2 = suuryou2.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou3: function(suuryou3) {
      if (suuryou3 != null) {
        // console.log(heikin_fusoku.length);
        var suryo3 = suuryou3
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo3 !== suuryou3) {
          // Only if something had to be fixed, update
          suuryou3 = suryo3;
        }
        this.max_suryo3 = suuryou3.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou4: function(suuryou4) {
      if (suuryou4 != null) {
        // console.log(heikin_fusoku.length);
        var suryo4 = suuryou4
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo4 !== suuryou4) {
          // Only if something had to be fixed, update
          suuryou4 = suryo4;
        }
        this.max_suryo4 = suuryou4.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou5: function(suuryou5) {
      if (suuryou5 != null) {
        // console.log(heikin_fusoku.length);
        var suryo5 = suuryou5
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo5 !== suuryou5) {
          // Only if something had to be fixed, update
          suuryou5 = suryo5;
        }
        this.max_suryo5 = suuryou5.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou6: function(suuryou6) {
      if (suuryou6 != null) {
        // console.log(heikin_fusoku.length);
        var suryo6 = suuryou6
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo6 !== suuryou6) {
          // Only if something had to be fixed, update
          suuryou6 = suryo6;
        }
        this.max_suryo6 = suuryou6.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou7: function(suuryou7) {
      if (suuryou7 != null) {
        // console.log(heikin_fusoku.length);
        var suryo7 = suuryou7
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo7 !== suuryou7) {
          // Only if something had to be fixed, update
          suuryou7 = suryo7;
        }
        this.max_suryo7 = suuryou7.indexOf(".") > 0 ? 11 : 10;
      }
    },
    suuryou8: function(suuryou8) {
      if (suuryou8 != null) {
        // console.log(heikin_fusoku.length);
        var suryo8 = suuryou8
          // remove bad characters and multiple points
          .replace(/[^\d.]|\.(?=.*\.)/, "")
          // remove any excess of 8 integer digits
          .replace(/^(\d{8})\d+/, "$1")
          // remove any excess of 2 decimal digits
          .replace(/(\.\d).+/, "$1");
        if (suryo8 !== suuryou8) {
          // Only if something had to be fixed, update
          suuryou8 = suryo8;
        }
        this.max_suryo8 = suuryou8.indexOf(".") > 0 ? 11 : 10;
      }
    }
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getSaigaiGengo(this.engYear);
    this.getKotukoKojigaiyou();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    formatDate: function(date) {
      if (date != "" && date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
      return "";
    },
    formatNumber: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0") == 0
        ? ""
        : numeral(number).format("0,0");
    },
    formatNumeric: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
    removeComma: function(number) {
      return number == "" || number == null
        ? number
        : number.toString().replace(/,/g, "");
    },
    getSaigaiGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.saigai_yyyy = result;
      });
    },
    getSougouGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.sougou_nendo = result;
      });
    },
    changedSaigaiYear(jpYear) {
      var matches = jpYear.match(/[a-zA-Z]+/g);
      var yvalue = jpYear.replace(/[^\d.]/g, "");
      var request_data = {
        gengo_cd: matches.toString()
      };
      apiService.getGengoStartDate(request_data).then(result => {
        var startDate = result[0].start_date;
        this.engYear = parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);
      });
    },
    changedSougouYear() {
      if(this.sougou_nendo != ""){
        var matches = this.sougou_nendo.match(/[a-zA-Z]+/g);
        var yvalue = this.sougou_nendo.replace(/[^\d.]/g, "");
        var request_data = {
          gengo_cd: matches ? matches.toString() : ''
        };
        apiService.getGengoStartDate(request_data).then(result => {
          if(result.length > 0) {
            var startDate = result[0].start_date;
            this.sougou_year = parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);
            this.sougou_year_flg = false;
          } else {
            this.sougou_year_flg = true;
            this.textError = '総合単価年度' + this.$messages.msg['0002W'];
          }
        });
      }else{
        this.sougou_year = "";
        this.sougou_year_flg = false;
      }
    },
    getKotukoKojigaiyou() {
      var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: this.ikkan_no
      };
      apiService.fb102GetKotukoKojigaiyo(request_data).then(result => {
        (this.koji_no = result.kotuko_sinsei[0].koji_no),
        (this.satei = result.kotuko_sinsei[0].nji_satei),
        (this.kanrisya_cd = result.kotuko_sinsei[0].kanrisya_cd),
        (this.kanrisya_mei = result.kotuko_sinsei[0].kanrisya_mei),
        (this.jigyou_cd = result.kotuko_sinsei[0].jigyou_cd),
        (this.seiri_no = result.kotuko_sinsei[0].saigai_seirinao),
        (this.ijo_kisho = result.kotuko_sinsei[0].saigai_kuni),
        (this.hisaibi = this.formatDate(result.kotuko_sinsei[0].hisaibi));
        for (var s in result.sateikijyou) {
          this.sateikijyous.push({
            value: result.sateikijyou[s].cd_value,
            key: result.sateikijyou[s].meisai
          });
        }
        for (var t in result.tumiage_sotan) {
          this.tumiage_sotans.push({
            value: result.tumiage_sotan[t].cd_value,
            key: result.tumiage_sotan[t].meisai
          });
        }
        for (var i in result.saitaku_joko) {
          this.youkou1s.push({
            value: result.saitaku_joko[i].cd_value,
            key: result.saitaku_joko[i].meisai
          });
          this.youkou2s.push({
            value: result.saitaku_joko[i].cd_value,
            key: result.saitaku_joko[i].meisai
          });
        }

        if (result.kojigaiyou.length > 0) {
          this.kojigaiyou = result.kojigaiyou;
          this.satei_kbn = result.kojigaiyou[0].satei_kbn;
          (this.isUpdate = true), (this.isInsert = false);
          this.sateikijyou = result.kojigaiyou[0].jitusa_kbn;
          this.tumiage_sotan = result.kojigaiyou[0].tumiage_soutan;
          this.getSougouGengo(parseInt(result.kojigaiyou[0].sougou_nendo));
          this.suuryou1 = this.formatNumeric(result.kojigaiyou[0].suuryou1);
          this.suuryou2 = this.formatNumeric(result.kojigaiyou[0].suuryou2);
          this.suuryou3 = this.formatNumeric(result.kojigaiyou[0].suuryou3);
          this.suuryou4 = this.formatNumeric(result.kojigaiyou[0].suuryou4);
          this.suuryou5 = this.formatNumeric(result.kojigaiyou[0].suuryou5);
          this.suuryou6 = this.formatNumeric(result.kojigaiyou[0].suuryou6);
          this.suuryou7 = this.formatNumeric(result.kojigaiyou[0].suuryou7);
          this.suuryou8 = this.formatNumeric(result.kojigaiyou[0].suuryou8);
          this.meisyou1 = result.kojigaiyou[0].meisyou1;
          this.meisyou2 = result.kojigaiyou[0].meisyou2;
          this.meisyou3 = result.kojigaiyou[0].meisyou3;
          this.meisyou4 = result.kojigaiyou[0].meisyou4;
          this.meisyou5 = result.kojigaiyou[0].meisyou5;
          this.meisyou6 = result.kojigaiyou[0].meisyou6;
          this.meisyou7 = result.kojigaiyou[0].meisyou7;
          this.meisyou8 = result.kojigaiyou[0].meisyou8;
          this.tani1 = result.kojigaiyou[0].tani1;
          this.tani2 = result.kojigaiyou[0].tani2;
          this.tani3 = result.kojigaiyou[0].tani3;
          this.tani4 = result.kojigaiyou[0].tani4;
          this.tani5 = result.kojigaiyou[0].tani5;
          this.tani6 = result.kojigaiyou[0].tani6;
          this.tani7 = result.kojigaiyou[0].tani7;
          this.tani8 = result.kojigaiyou[0].tani8;
          this.total_koujihi = this.formatNumber(
            result.kojigaiyou[0].sinsei_gaku +
              result.kojigaiyou[0].naikari_koujihi
          );
          this.total_koujizapi = this.formatNumber(
            result.kojigaiyou[0].eizenhi +
              result.kojigaiyou[0].oukyukarikouji_zapi
          );
          this.total_E = this.formatNumber(
            result.kojigaiyou[0].naikari_koujihi +
              result.kojigaiyou[0].oukyukarikouji_zapi
          );
          this.total_F = this.formatNumber(
            result.kojigaiyou[0].sinsei_gaku +
            result.kojigaiyou[0].eizenhi +
            parseInt(this.removeComma(this.total_E))
          );
          this.naikari_koujihi = this.formatNumber(
            result.kojigaiyou[0].naikari_koujihi
          );
          this.oukyukarikouji_zapi = this.formatNumber(
            result.kojigaiyou[0].oukyukarikouji_zapi
          );
          this.sinsei_gaku = this.formatNumber(
            result.kojigaiyou[0].sinsei_gaku
          );
          this.eizenhi = this.formatNumber(result.kojigaiyou[0].eizenhi);
          this.youkou1 = result.kojigaiyou[0].youkou;
          this.youkou2 = result.kojigaiyou[0].youkou2;
          this.sinseiChange(this.sinsei_gaku);
        } else {
          (this.isUpdate = false), (this.isInsert = true);
        }
        this.chk_date = result.chk_date;
      });
      setTimeout(() => {
        this.isLoading = false;
      },1000)
    },
    sateikijyouChange(sateikijyou) {
      this.sateikijyou = sateikijyou;
    },
    tumiageChange(tumiage_sotan) {
      this.tumiage_sotan = tumiage_sotan;
    },
    youkou1Change(youkou1) {
      this.youkou1 = youkou1;
    },
    youkou2Change(youkou2) {
      this.youkou2 = youkou2;
    },
    sinseiChange(sinsei_gaku) {
      this.sinsei_gaku = this.removeComma(sinsei_gaku);
      if (this.isFloat(this.sinsei_gaku)) {
        this.sinsei_gaku = this.sinsei_gaku
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      var totalF = 0;
      var totalKoujihi = 0;
      if(this.isInteger(this.removeComma(this.sinsei_gaku)))
      {
        totalF += parseInt(this.removeComma(this.sinsei_gaku));
      }
      if(this.isInteger(this.removeComma(this.eizenhi)))
      {
        totalF += parseInt(this.removeComma(this.eizenhi));
      }
      if(this.isInteger(this.removeComma(this.total_E)))
      {
        totalF += parseInt(this.removeComma(this.total_E));
      }
      this.total_F = this.formatNumber(totalF);
      if(this.isInteger(this.removeComma(this.sinsei_gaku)))
      {
        totalKoujihi += parseInt(this.removeComma(this.sinsei_gaku));
      }
      if(this.isInteger(this.removeComma(this.naikari_koujihi)))
      {
        totalKoujihi += parseInt(this.removeComma(this.naikari_koujihi));
      }
      this.total_koujihi = this.formatNumber(totalKoujihi);
    },
    sinseiRemoveComma() {
      this.sinsei_gaku = this.removeComma(this.sinsei_gaku);
    },
    eizenhiChange(eizenhi) {
      this.eizenhi = this.removeComma(eizenhi);
      if (this.isFloat(this.eizenhi)) {
        this.eizenhi = this.eizenhi
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      var totalF = 0;
      var totalKoujizapi = 0;
      if(this.isInteger(this.removeComma(this.sinsei_gaku)))
      {
        totalF += parseInt(this.removeComma(this.sinsei_gaku));
      }
      if(this.isInteger(this.removeComma(this.eizenhi)))
      {
        totalF += parseInt( this.removeComma(this.eizenhi));
      }
      if(this.isInteger(this.removeComma(this.total_E)))
      {
        totalF += parseInt( this.removeComma(this.total_E));
      }
      this.total_F = this.formatNumber(totalF);
      if(this.isInteger(this.removeComma(this.eizenhi)))
      {
        totalKoujizapi += parseInt( this.removeComma(this.eizenhi));
      }
      if(this.isInteger(this.removeComma(this.oukyukarikouji_zapi)))
      {
        totalKoujizapi += parseInt( this.removeComma(this.oukyukarikouji_zapi));
      }
      this.total_koujizapi = this.formatNumber(totalKoujizapi);
    },
    eizenhiRemoveComma() {
      this.eizenhi = this.removeComma(this.eizenhi);
    },
    naikariChange(naikari_koujihi) {
      this.naikari_koujihi = this.removeComma(naikari_koujihi);
      if (this.isFloat(this.naikari_koujihi)) {
        this.naikari_koujihi = this.naikari_koujihi
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      var totalE = 0;
      var totalF = 0;
      var totalKoujihi = 0;
      if(this.isInteger(this.removeComma(this.naikari_koujihi)))
      {
        totalE += parseInt(this.removeComma(this.naikari_koujihi));
      }
      if(this.isInteger(this.removeComma(this.oukyukarikouji_zapi)))
      {
        totalE += parseInt(this.removeComma(this.oukyukarikouji_zapi));
      }
      this.total_E = this.formatNumber(totalE);
      if(this.isInteger(this.removeComma(this.sinsei_gaku)))
      {
        totalF += parseInt(this.removeComma(this.sinsei_gaku));
      }
      if(this.isInteger(this.removeComma(this.eizenhi)))
      {
        totalF += parseInt(this.removeComma(this.eizenhi));
      }
      if(this.isInteger(this.removeComma(this.total_E)))
      {
        totalF += parseInt(this.removeComma(this.total_E));
      }
      this.total_F = this.formatNumber(totalF);
      if(this.isInteger(this.removeComma(this.sinsei_gaku)))
      {
        totalKoujihi += parseInt(this.removeComma(this.sinsei_gaku));
      }
      if(this.isInteger(this.removeComma(this.naikari_koujihi)))
      {
        totalKoujihi += parseInt(this.removeComma(this.naikari_koujihi));
      }
      this.total_koujihi = this.formatNumber(totalKoujihi);
    },
    naikariRemoveComma() {
      this.naikari_koujihi = this.removeComma(this.naikari_koujihi);
    },
    oukyukarikoujiChange(oukyukarikouji_zapi) {
      this.oukyukarikouji_zapi = this.removeComma(oukyukarikouji_zapi);
      if (this.isFloat(this.oukyukarikouji_zapi)) {
        this.oukyukarikouji_zapi = this.oukyukarikouji_zapi
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      var totalE = 0;
      var totalF = 0;
      var totalKoujizapi = 0;
      if(this.isInteger(this.removeComma(this.naikari_koujihi)))
      {
        totalE += parseInt(this.removeComma(this.naikari_koujihi));
      }
      if(this.isInteger(this.removeComma(this.oukyukarikouji_zapi)))
      {
        totalE += parseInt(this.removeComma(this.oukyukarikouji_zapi));
      }
      this.total_E = this.formatNumber(totalE);
      if(this.isInteger(this.removeComma(this.sinsei_gaku)))
      {
        totalF += parseInt(this.removeComma(this.sinsei_gaku));
      }
      if(this.isInteger(this.removeComma(this.eizenhi)))
      {
        totalF += parseInt(this.removeComma(this.eizenhi));
      }
      if(this.isInteger(this.removeComma(this.total_E)))
      {
        totalF += parseInt(this.removeComma(this.total_E));
      }
      this.total_F = this.formatNumber(totalF);
      if(this.isInteger(this.removeComma(this.eizenhi)))
      {
        totalKoujizapi += parseInt(this.removeComma(this.eizenhi));
      }
      if(this.isInteger(this.removeComma(this.oukyukarikouji_zapi)))
      {
        totalKoujizapi += parseInt(this.removeComma(this.oukyukarikouji_zapi));
      }
      this.total_koujizapi = this.formatNumber(totalKoujizapi);
    },
    oukyukarikoujiRemoveComma() {
      this.oukyukarikouji_zapi = this.removeComma(this.oukyukarikouji_zapi);
    },
    suuryou1Change(suuryou1) {
      this.suuryou1 = this.removeComma(suuryou1);
      if (this.isFloat(this.suuryou1)) {
        this.suuryou1 = this.suuryou1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo1RemoveComma() {
      this.suuryou1 = this.removeComma(this.suuryou1);
    },
    suuryou2Change(suuryou2) {
      this.suuryou2 = this.removeComma(suuryou2);
      if (this.isFloat(this.suuryou2)) {
        this.suuryou2 = this.suuryou2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo2RemoveComma() {
      this.suuryou2 = this.removeComma(this.suuryou2);
    },
    suuryou3Change(suuryou3) {
      this.suuryou3 = this.removeComma(suuryou3);
      if (this.isFloat(this.suuryou3)) {
        this.suuryou3 = this.suuryou3
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo3RemoveComma() {
      this.suuryou3 = this.removeComma(this.suuryou3);
    },
    suuryou4Change(suuryou4) {
      this.suuryou4 = this.removeComma(suuryou4);
      if (this.isFloat(this.suuryou4)) {
        this.suuryou4 = this.suuryou4
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo4RemoveComma() {
      this.suuryou4= this.removeComma(this.suuryou4);
    },
    suuryou5Change(suuryou5) {
      this.suuryou5 = this.removeComma(suuryou5);
      if (this.isFloat(this.suuryou5)) {
        this.suuryou5 = this.suuryou5
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo5RemoveComma() {
      this.suuryou5 = this.removeComma(this.suuryou5);
    },
    suuryou6Change(suuryou6) {
      this.suuryou6 = this.removeComma(suuryou6);
      if (this.isFloat(this.suuryou6)) {
        this.suuryou6 = this.suuryou6
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo6RemoveComma() {
      this.suuryou6 = this.removeComma(this.suuryou6);
    },
    suuryou7Change(suuryou7) {
      this.suuryou7 = this.removeComma(suuryou7);
      if (this.isFloat(this.suuryou7)) {
        this.suuryou7 = this.suuryou7
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo7RemoveComma() {
      this.suuryou7 = this.removeComma(this.suuryou7);
    },
    suuryou8Change(suuryou8) {
      this.suuryou8 = this.removeComma(suuryou8);
      if (this.isFloat(this.suuryou8)) {
        this.suuryou8 = this.suuryou8
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    suuryo8RemoveComma() {
      this.suuryou8 = this.removeComma(this.suuryou8);
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    insert_btn() {
      this.changedSougouYear();
      this.showInfo = false;
      this.numFormatErr = false;
      this.isLoading = true;
      this.numericFormatCheck();
      setTimeout(() => { 
        if(this.sougou_year_flg)
        {
          confirm(this.$messages.msg['0013E']);
          this.showError = true;
        } else {
          this.showError = false;
        }
        if(!this.isNumFormat && !this.sougou_year_flg) {
          var request_data = {
            saigai_yyyy: this.engYear,
            ikkan_no: parseInt(this.ikkan_no),
            koji_no: parseInt(this.koji_no),
            kanrisya_cd: parseInt(this.kanrisya_cd),
            satei_kbn: parseInt(this.satei_kbn),
            jitusa_kbn: this.sateikijyou,
            tumiage_soutan: this.tumiage_sotan,
            sougou_nendo: this.sougou_year,
            suuryou1: this.removeComma(this.suuryou1),
            suuryou2: this.removeComma(this.suuryou2),
            suuryou3: this.removeComma(this.suuryou3),
            suuryou4: this.removeComma(this.suuryou4),
            suuryou5: this.removeComma(this.suuryou5),
            suuryou6: this.removeComma(this.suuryou6),
            suuryou7: this.removeComma(this.suuryou7),
            suuryou8: this.removeComma(this.suuryou8),
            meisyou1: this.meisyou1,
            meisyou2: this.meisyou2,
            meisyou3: this.meisyou3,
            meisyou4: this.meisyou4,
            meisyou5: this.meisyou5,
            meisyou6: this.meisyou6,
            meisyou7: this.meisyou7,
            meisyou8: this.meisyou8,
            tani1: this.tani1,
            tani2: this.tani2,
            tani3: this.tani3,
            tani4: this.tani4,
            tani5: this.tani5,
            tani6: this.tani6,
            tani7: this.tani7,
            tani8: this.tani8,
            youkou: this.youkou1,
            youkou2: this.youkou2,
            oukyukarikouji_zapi: this.removeComma(this.oukyukarikouji_zapi),
            naikari_koujihi: this.removeComma(this.naikari_koujihi),
            sinsei_gaku: this.removeComma(this.sinsei_gaku),
            eizenhi: this.removeComma(this.eizenhi),
            loginId: localStorage.getItem("LOGIN_ID")
          };
          console.log(request_data)
          apiService
            .fb102Insert(request_data)
            .then(result => {
              this.showInfo = true;
              this.showError = false;
              this.isInsert = false;
              this.isUpdate = true;
              this.errors.satei_kbn = null;
              this.info = result.msg;
              this.chk_date = result.chk_date;
            })
            .catch(error => {
              if (typeof error == "string") {
                confirm(this.$messages.msg['0013E']);
                this.showError = true;
                this.showInfo = false;
                this.isInsert = true;
                this.isUpdate = false;
                this.textError = error;
                this.errors.satei_kbn = null;
              } else {
                this.showError = false;
                this.errors.satei_kbn = error.errors.satei_kbn
                  ? error.errors.satei_kbn[0]
                  : "";
                confirm(this.$messages.msg['0013E']);
              }
            });
        }
      }, 2000)
      setTimeout(() => {
        this.isLoading = false;
      },1000)
    },
    update_btn() {
      this.changedSougouYear();
      this.showInfo = false;
      this.numFormatErr = false;
      this.isLoading = true;
      this.numericFormatCheck();
      setTimeout(() => { 
        if(this.sougou_year_flg)
        {
          confirm(this.$messages.msg['0013E']);
          this.showError = true;
        } else {
          this.showError = false;
        }
        if (!this.isNumFormat && !this.sougou_year_flg) {
          var request_data = {
            saigai_yyyy: this.engYear,
            ikkan_no: parseInt(this.ikkan_no),
            koji_no: parseInt(this.koji_no),
            kanrisya_cd: parseInt(this.kanrisya_cd),
            satei_kbn: parseInt(this.satei_kbn),
            jitusa_kbn: this.sateikijyou,
            tumiage_soutan: this.tumiage_sotan,
            sougou_nendo: this.sougou_year,
            suuryou1: this.removeComma(this.suuryou1),
            suuryou2: this.removeComma(this.suuryou2),
            suuryou3: this.removeComma(this.suuryou3),
            suuryou4: this.removeComma(this.suuryou4),
            suuryou5: this.removeComma(this.suuryou5),
            suuryou6: this.removeComma(this.suuryou6),
            suuryou7: this.removeComma(this.suuryou7),
            suuryou8: this.removeComma(this.suuryou8),
            meisyou1: this.meisyou1,
            meisyou2: this.meisyou2,
            meisyou3: this.meisyou3,
            meisyou4: this.meisyou4,
            meisyou5: this.meisyou5,
            meisyou6: this.meisyou6,
            meisyou7: this.meisyou7,
            meisyou8: this.meisyou8,
            tani1: this.tani1,
            tani2: this.tani2,
            tani3: this.tani3,
            tani4: this.tani4,
            tani5: this.tani5,
            tani6: this.tani6,
            tani7: this.tani7,
            tani8: this.tani8,
            youkou: this.youkou1,
            youkou2: this.youkou2,
            oukyukarikouji_zapi: this.removeComma(this.oukyukarikouji_zapi),
            naikari_koujihi: this.removeComma(this.naikari_koujihi),
            sinsei_gaku: this.removeComma(this.sinsei_gaku),
            eizenhi: this.removeComma(this.eizenhi),
            loginId: localStorage.getItem("LOGIN_ID"),
            chk_date: this.chk_date
          };
          console.log(request_data)
          apiService
            .fb102Update(request_data)
            .then(result => {
              this.showInfo = true;
              this.showError = false;
              this.errors.satei_kbn = null;
              this.info = result.msg;
              this.chk_date = result.chk_date;
            })
            .catch(error => {
              if (typeof error == "string") {
                confirm(this.$messages.msg['0013E']);
                this.showError = true;
                this.textError = error;
                this.errors.satei_kbn = null;
              } else {
                this.showError = false;
                this.errors.satei_kbn = error.errors.satei_kbn
                  ? error.errors.satei_kbn[0]
                  : "";
                confirm(this.$messages.msg['0013E']);
              }
            });
        }
      }, 2000)
      setTimeout(() => {
        this.isLoading = false;
      },1000)
    },
    delete_btn() {
      this.showInfo     = false;
      this.showError    = false;
      this.numFormatErr = false;
      this.isLoading    = true;
      var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: parseInt(this.ikkan_no),
        loginId: localStorage.getItem("LOGIN_ID"),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fb102Delete(request_data)
          .then(result => {
            this.showInfo = true;
            this.info = result;
            this.clearData();
            this.isInsert = true;
            this.isUpdate = false;
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error);
            confirm(this.$messages.msg['0013E']);
            this.showError = true;
            this.textError = error;
            this.errors.satei_kbn = null;
            this.isInsert = false;
            this.isUpdate = true;
            this.isLoading = false;
          });
      }
      this.isLoading = false;
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    },
    numericFormatCheck() {
      var suuryou1 = this.suuryou1.replace(/,/g, "");
      suuryou1 = suuryou1.trim().split(".")[0];
      var suuryou2 = this.suuryou2.replace(/,/g, "");
      suuryou2 = suuryou2.trim().split(".")[0];
      var suuryou3 = this.suuryou3.replace(/,/g, "");
      suuryou3 = suuryou3.trim().split(".")[0];
      var suuryou4 = this.suuryou4.replace(/,/g, "");
      suuryou4 = suuryou4.trim().split(".")[0];
      var suuryou5 = this.suuryou5.replace(/,/g, "");
      suuryou5 = suuryou5.trim().split(".")[0];
      var suuryou6 = this.suuryou6.replace(/,/g, "");
      suuryou6 = suuryou6.trim().split(".")[0];
      var suuryou7 = this.suuryou7.replace(/,/g, "");
      suuryou7 = suuryou7.trim().split(".")[0];
      var suuryou8 = this.suuryou8.replace(/,/g, "");
      suuryou8 = suuryou8.trim().split(".")[0];
      if (suuryou1.length > 9) {
        this.isNumFormat = true;
        this.numFormatErr = this.$messages.msg["0014W"];
      } else {
        if (suuryou2.length > 9) {
          this.isNumFormat = true;
          this.numFormatErr = this.$messages.msg["0015W"];
        } else {
          if (suuryou3.length > 9) {
            this.isNumFormat = true;
            this.numFormatErr = this.$messages.msg["0016W"];
          } else {
            if (suuryou4.length > 9) {
              this.isNumFormat = true;
              this.numFormatErr = this.$messages.msg["0017W"];
            } else {
              if (suuryou5.length > 9) {
                this.isNumFormat = true;
                this.numFormatErr = this.$messages.msg["0018W"];
              } else {
                if (suuryou6.length > 9) {
                  this.isNumFormat = true;
                  this.numFormatErr = this.$messages.msg["0019W"];
                } else {
                  if (suuryou7.length > 9) {
                    this.isNumFormat = true;
                    this.numFormatErr = this.$messages.msg["0020W"];
                  } else {
                    if (suuryou8.length > 9) {
                      this.isNumFormat = true;
                      this.numFormatErr = this.$messages.msg["0021W"];
                    } else {
                      this.isNumFormat = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    clearData() {
      (this.satei_kbn = ""),
      (this.sateikijyou = ""),
      (this.tumiage_sotan = ""),
      (this.sougou_nendo = ""),
      (this.suuryou1 = ""),
      (this.suuryou2 = ""),
      (this.suuryou3 = ""),
      (this.suuryou4 = ""),
      (this.suuryou5 = ""),
      (this.suuryou6 = ""),
      (this.suuryou7 = ""),
      (this.suuryou8 = ""),
      (this.naikari_koujihi = ""),
      (this.oukyukarikouji_zapi = ""),
      (this.youkou1 = ""),
      (this.youkou2 = ""),
      (this.meisyou1 = ""),
      (this.meisyou2 = ""),
      (this.meisyou3 = ""),
      (this.meisyou4 = ""),
      (this.meisyou5 = ""),
      (this.meisyou6 = ""),
      (this.meisyou7 = ""),
      (this.meisyou8 = ""),
      (this.tani1 = ""),
      (this.tani2 = ""),
      (this.tani3 = ""),
      (this.tani4 = ""),
      (this.tani5 = ""),
      (this.tani6 = ""),
      (this.tani7 = ""),
      (this.tani8 = ""),
      (this.sinsei_gaku = ""),
      (this.eizenhi = ""),
      (this.total_E = ""),
      (this.total_F = "");
    },
    openDialog:function(){
        var message = {
            title: '単位記入例'
            ,body: '平米は<b>㎡</b>を使用<br>立米は<b>㎥</b>を使用'
        };

        var options = {
            html: true
            , okText: '閉じる'
            , backdropClose: true
            , customClass: 'fb102'
        };
      this.$dialog.confirm(message, options);
    },
    Download() {
      this.isLoading = true;
      apiService.koujigaiyouDownload().then(result => {
        var date = moment(String(new Date())).format('YYYYMMDD');
        this.downloadReport(result, '工事概要' + date +'.xlsx');
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },
    downloadReport(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
      this.isLoading = false;
    },
  }
};
</script>

<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span class="col-md-12 text-center" v-show="showInfo">{{ this.info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isNumFormat" class="err">{{ this.numFormatErr }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="showError" class="err">{{ this.textError }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form>
            <div class="form-group row" style="width:100%; max-width:500px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="saigai_yyyy" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
              <label
                for
                class="col-sm col-form-label ml-3 text-right"
                style="width:100%; max-width:65px"
              >査定</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
                <input type="text" class="form-control bg-light" v-model="satei" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >次</label>
            </div>
            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">工事番号</label>
              <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="koji_no" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:100px"
              >管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_mei" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-left"
                style="width:100%; max-width:100px"
              >事業主体</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="jigyou_cd" readonly/>
              </div>
              <label
                forkanrisya_cd
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:100px"
              >整理番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
                <input type="text" class="form-control bg-light" v-model="seiri_no" readonly/>
              </div>
            </div>
            <div class="form-group row mb-0" style="width:100%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">気象コード</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:410px">
                <input type="text" class="form-control bg-light" v-model="ijo_kisho" readonly/>
              </div>
              <label
                for
                class="col-sm col-form-label text-left"
                style="width:100%; max-width:100px"
              >被災日</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="hisaibi" readonly/>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              style="width:120px;"
              @click="insert_btn()"
              :disabled="isUpdate"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="update_btn()"
              :disabled="isInsert"
            >更新</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              @click="back_btn()"
              style="width:120px;"
            >戻る</button>
          </div>
        </div>
        <div class="container">
          <div class="form-group" style>
            <div class="form-check form-check-inline" style="width:100%; max-width:100px">
              <label class="form-check-label" for>査定区分</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="1" />
              <label class="form-check-label" for>本省</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="2" />
              <label class="form-check-label" for>整備局</label>
            </div>
          </div>
          <p
            style="width:30%; max-width:130px; white-space: nowrap; color: red;"
          >{{ this.errors.satei_kbn }}</p>
          <div class="form-group row mb-0" style="width:100%; ">
            <label for class="col-sm col-form-label" style="width:100%; max-width:120px">実査・机上</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select
                id
                class="form-control"
                v-model="sateikijyou"
                @change="sateikijyouChange(sateikijyou)"
              >
                <option value=""></option>
                <option v-for="item in sateikijyous" :key="item.key" :value="item.key">{{item.key}} : {{item.value}}</option>
              </select>
            </div>
            <label for class="col-sm col-form-label ml-2" style="width:100%; max-width:120px">積算方法</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:300px">
              <select
                id
                class="form-control"
                v-model="tumiage_sotan"
                @change="tumiageChange(tumiage_sotan)"
              >
                <option value=""></option>
                <option
                  v-for="item in tumiage_sotans"
                  :key="item.key"
                  :value="item.key"
                >{{item.key}} : {{item.value}}</option>
              </select>
            </div>
            <label for class="col-sm col-form-label ml-2" style="width:100%; max-width:130px">総合単価年度</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="tel" class="form-control" v-model="sougou_nendo" @change="changedSougouYear()"/>
            </div>
            <label
              for
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >年</label>
          </div>
        </div>
        <div class="container table-responsive mt-3">
          <table class="table table-sm table-bordered" style>
            <thead class="bg-primary text-light">
              <tr class>
                <th scope="col" class="align-middle text-center border-white" width="10%">No.</th>
                <th scope="col" class="align-middle text-center border-white" width="50%">
                  <div style="display:inline-block;width:25%;"></div>
                  <div style="display:inline-block;width:50%;" class="align-middle text-center">内容「※最大15文字まで」</div>
                  <div style="display:inline-block;width:25%;" class="align-middle text-right">
                    <span >記入例</span>
                    <span class="btn-question" @click="Download()">？</span>
                  </div>
                </th>
                <th scope="col" class="align-middle text-center border-white" width="20%">数量</th>
                <th scope="col" class="align-middle text-center border-white" width="20%">
                    <div style="display:inline-block;width:40%;"></div>
                    <div style="display:inline-block;width:20%;" class="align-middle text-center">単位</div>
                    <div style="display:inline-block;width:40%;" class="align-middle text-right">
                      <span>記入例</span>
                      <span class="btn-question" @click="openDialog()">？</span>
                    </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-middle text-center">1</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou1"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou1"
                    :maxlength="max_suryo1"
                    @blur="suuryou1Change(suuryou1)"
                    @focus="suuryo1RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani1"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">2</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou2"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou2"
                    :maxlength="max_suryo2"
                    @blur="suuryou2Change(suuryou2)"
                    @focus="suuryo2RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani2"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">3</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou3"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou3"
                    :maxlength="max_suryo3"
                    @blur="suuryou3Change(suuryou3)"
                    @focus="suuryo3RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani3"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">4</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou4"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou4"
                    :maxlength="max_suryo4"
                    @blur="suuryou4Change(suuryou4)"
                    @focus="suuryo4RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani4"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">5</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou5"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou5"
                    :maxlength="max_suryo5"
                    @blur="suuryou5Change(suuryou5)"
                    @focus="suuryo5RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani5"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">6</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou6"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou6"
                    :maxlength="max_suryo6"
                    @blur="suuryou6Change(suuryou6)"
                    @focus="suuryo6RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani6"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">7</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou7"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou7"
                    :maxlength="max_suryo7"
                    @blur="suuryou7Change(suuryou7)"
                    @focus="suuryo7RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani7"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle text-center">8</td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="meisyou8"
                    style="width:100%"
                    maxlength="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="suuryou8"
                    :maxlength="max_suryo8"
                    @blur="suuryou8Change(suuryou8)"
                    @focus="suuryo8RemoveComma()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="tani8"
                    style="width:100%"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container pt-3 border-top border-secondary">
          <p class="text-info text-right mb-1">単位:千円</p>
          <table class="table table-sm table-bordered" style>
            <thead class="bg-primary text-light">
              <tr class>
                <th class="align-middle text-center border-white border-right-0" width="25%">本工事費(A)</th>
                <th class="align-middle text-center border-white" width="25%">工事雑費・営繕費(B)</th>
                <th class="align-middle text-center border-white" width="25%">応急仮本工事費(C)</th>
                <th class="align-middle text-center border-white" width="25%">応急仮工事雑費(D)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-middle border" colspan width="25%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="sinsei_gaku"
                    @blur="sinseiChange(sinsei_gaku)"
                    @focus="sinseiRemoveComma()"
                    style="width:85%;display:inline-block"
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
                <td class="align-middle border" width="25%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="eizenhi"
                    @blur="eizenhiChange(eizenhi)"
                    @focus="eizenhiRemoveComma()"
                    style="width:85%;display:inline-block"
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
                <td class="align-middle border" width="25%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="naikari_koujihi"
                    @blur="naikariChange(naikari_koujihi)"
                    @focus="naikariRemoveComma()"
                    style="width:85%;display:inline-block"
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
                <td class="align-middle border" width="25%">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="oukyukarikouji_zapi"
                    @blur="oukyukarikoujiChange(oukyukarikouji_zapi)"
                    @focus="oukyukarikoujiRemoveComma()"
                    style="width:85%;display:inline-block"
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container pt-0">
          <table
            class="table table-sm table-bordered border-left-0 border-bottom-0 border-top-0"
            style
          >
            <thead class="text-light">
              <tr class>
                <th class="align-middle bg-primary text-center border-white" width="25%">工事費[A+C]</th>
                <th class="align-middle bg-primary text-center border-white" width="25%">工事雑費[B+D]</th>
                <th class="align-middle bg-primary text-center border-white" width="25%">応急仮工事費(E)[C+D]</th>
                <th class="align-middle bg-primary text-center border-white" width="25%">総額(F)[A+B+E]</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-middle border" width="25%">
                  <input
                    type="text"
                    class="form-control p-1 bg-light flex-fill text-right"
                    v-model="total_koujihi"
                    style="width:85%;display:inline-block"
                    readonly
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
                <td class="align-middle border" width="25%">
                  <input
                    type="text"
                    class="form-control p-1 bg-light flex-fill text-right"
                    v-model="total_koujizapi"
                    style="width:85%;display:inline-block"
                    readonly
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
                <td class="align-middle border" width="25%">
                  <input
                    type="text"
                    class="form-control p-1 bg-light flex-fill text-right"
                    v-model="total_E"
                    style="width:85%;display:inline-block"
                    readonly
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
                <td class="align-middle border" width="25%">
                  <input
                    type="text"
                    class="form-control p-1 bg-light flex-fill text-right"
                    v-model="total_F"
                    style="width:85%;display:inline-block"
                    readonly
                  />
                  <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container pt-3 border-top border-secondary">
          <div class="form-row" style="width:60%">
            <div class="form-group col-sm" style="width:100%">
              <label for class="mb-0" style>採択条項(応急工事)</label>
              <div class="form-inline">
                <select
                  id
                  class="form-control"
                  v-model="youkou1"
                  @change="youkou1Change(youkou1)"
                  style="width:100%;"
                >
                <option value=""></option>
                <option v-for="item in youkou1s" :key="item.key" :value="item.value">{{item.value}}</option>
                </select>
              </div>
            </div>
            <div class="form-group col-sm" style="width:100%">
              <label for class="mb-0" style></label>
              <div class="form-inline">
                <select
                  id
                  class="form-control"
                  v-model="youkou2"
                  @change="youkou2Change(youkou2)"
                  style="width:100%;"
                >
                  <option value=""></option>
                  <option v-for="item in youkou2s" :key="item.key" :value="item.value">{{item.value}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="delete_btn()"
              :disabled="isInsert"
            >削除</button>
          </div>
        </div>
      </form>
    </main>
  </AppLayout>
</template>
