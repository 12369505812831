<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-err text-center">
        <span class="err" v-show="isCheckBoxErr">{{ this.checkboxMsg }}</span>
        <span class="err" v-show="isNjisateiErr && !isLoading">
          <ul style="list-style-type: none;">
            <li v-for="(njiMsg, index) in njisateiMsg" :key="index">{{ njiMsg }}</li>
          </ul>
        </span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
      </div>
      <div class="container mb-3">
        <form action method>
          <div class="form-group row" style="width:100%;">
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="text"
                class="form-control bg-required"
                maxlength="4"
                v-model="jpYear"
                @blur="changedYear()"
              />
            </div>
            <label
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:30px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:70px">査定</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                v-model="nji_satei"
                @blur="nji_sateiChange()"
              />
            </div>
            <label
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >次</label>
            <label class="col-sm col-form-label text-right" style="width:100%; max-width:120px">工事番号</label>
            <div class="col-sm pr-0" style="width:100%; max-width:130px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                disabled
                v-model="koji_no_from"
                @blur="koji_no_fromChange()"
              />
            </div>
            <label class="col-sm col-form-label text-center" style="width:100%; max-width:50px">〜</label>
            <div class="col-sm pl-0" style="width:100%; max-width:130px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                disabled
                v-model="koji_no_to"
                @blur="koji_no_toChange()"
              />
            </div>
            <label
              class="col-sm col-form-label text-right"
              style="width:100%; max-width:120px"
            >査定年月日</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:130px">
              <datepicker
                :language="ja"
                v-model="nji_satei_date"
                :value="nji_satei_date"
                :typeable="true"
                disabled
                format="yyyy/MM/dd"
                width="100px"
              ></datepicker>
            </div>
          </div>
          <div class="offset-sm-1 pl-2 mb-2">
            <span class="err" v-show="isYearNotFound">{{ this.yearErrorMsg }}</span>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">事業主体</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select id class="form-control" v-model="meisai1" v-on:change="codeChange1($event)">
                <option value=""></option>
                <option
                  v-for="mcode in mcodeList1"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:30px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">管理者</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select
                id
                class="form-control"
                v-model="kanrisya_cd"
                v-on:change="kanrisyaChange($event)"
              >
                <option
                  v-for="kanrisya in kanrisyaList"
                  :key="kanrisya.kanrisya_cd"
                  :value="kanrisya.kanrisya_cd"
                >{{kanrisya.kanrisya_cd}} : {{kanrisya.kanrisya_mei}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">班</label>
            <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:250px">
              <select id class="form-control" v-model="meisai2" v-on:change="codeChange2($event)">
                <option value=""></option>
                <option
                  v-for="mcode in mcodeList2"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">査定区分</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="1" />
                本省査定
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="2" />
                整備局査定
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="satei_kbn" v-bind:value="3" />
                混合査定
              </label>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px"></div>
            <!-- <label class="col-sm col-form-label" style="width:100%; max-width:100px">金額区分</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="kingaku_kbn" v-bind:value="3" />
                工事費総額
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="kingaku_kbn" v-bind:value="1" />
                国庫負担対象額
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="kingaku_kbn" v-bind:value="2" />
                工事雑費・営繕費
              </label>
            </div> -->
            <label for="" class="col-sm col-form-label" style="width:100%; max-width:100px">集計条件</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="condition_tokki" v-bind:value="1" />
              <label class="form-check-label" for="">特記事項を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="condition_kyogi" v-bind:value="1" />
              <label class="form-check-label" for="">協議事項を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="condition_horyu" v-bind:value="1" />
              <label class="form-check-label" for="">保留を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="condition_kairyo" v-bind:value="1" />
              <label class="form-check-label" for="">改良を含む</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="condition_sikkaku_kekkaku" v-bind:value="1" />
              <label class="form-check-label" for="">失格・欠格を含む</label>
            </div>
          </div>
          <div class="form-group row" style>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">出力順</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" v-model="output_order_han" v-bind:value="1" />
                班コード順
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" v-model="output_order_koji" v-bind:value="1" />
                工事番号順
              </label>
            </div>

            <div class="col-sm pr-0 pl-0" style="width: 100%; max-width: 80px"></div>
            <label class="col-sm col-form-label" style="width: 100%; max-width: 100px"> 出力帳票 </label>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="1" />河川等 </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="2" />水道 </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="3" />下水道 </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label"> <input class="form-check-input" type="radio" v-model="tyouhyou_kbn" v-bind:value="0" />すべて </label>
            </div>
          </div>
        </form>
      </div>
      <div class="container pt-3 border-top border-secondary">
        <p class="text-info">出力対象の帳票にチェックを入れて下さい</p>
      </div>
      <div class="container mt-2 table-responsive">
        <div class="form-group float-left">
          <button
            v-on:click="check_all()"
            class="btn btn-light"
            type="submit"
            style="width:90px;"
          >全選択</button>
          <button
            v-on:click="uncheck_all()"
            class="btn btn-light ml-3"
            type="submit"
            style="width:90px;"
          >解除</button>
        </div>
      </div>
      <div class="container mt-2 table-responsive">
        <table class="table text-center table-bordered table-sm">
          <template v-if="authorityAdmin()">
            <thead class="bg-primary text-light">
              <tr>
                <th class="align-middle" width="20%" height="35px">選択</th>
                <th class="align-middle" width="30%">帳票名</th>
                <th class="align-middle" width="20%">選択</th>
                <th class="align-middle" width="30%">帳票名</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk0" />
                </td>
                <td class="align-middle" align="left">復命書</td>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk1" />
                </td>
                <td class="align-middle" align="left">災害総計表</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk2" />
                </td>
                <td class="align-middle" align="left">市町村工事内訳調書</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk3" />
                </td>
                <td class="align-middle" align="left">被災原因別調書</td>
              </tr>
              <tr>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk15" />
                </td>
                <td class="align-middle" align="left">市町村被災原因別調書</td>
                
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk4" />
                </td>
                <td class="align-middle" align="left">全体とりまとめ表</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk5" />
                </td>
                <td class="align-middle" align="left">検査申請済被害報告額調書</td>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk6" />
                </td>
                <td class="align-middle" align="left">内未成工事調書</td>
              </tr>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk7" />
                </td>
                <td class="align-middle" align="left">内転属工事調書</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk8" />
                </td>
                <td class="align-middle" align="left">失格箇所調書</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk9" />
                </td>
                <td class="align-middle" align="left">欠格箇所調書</td>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk10" />
                </td>
                <td class="align-middle" align="left">緊急順位別調書</td>
              </tr>
              <tr>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk11" />
                </td>
                <td class="align-middle" align="left">協議設計箇所調書</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk12" />
                </td>
                <td class="align-middle" align="left">保留箇所調書</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk13" />
                </td>
                <td class="align-middle" align="left">特記事項調書</td>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk14" />
                </td>
                <td class="align-middle" align="left">目論見書</td>
              </tr>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk16" />
                </td>
                <td class="align-middle" align="left">班別とりまとめ表</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk17" />
                </td>
                <td class="align-middle" align="left">班別工事内訳書</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk20" />
                </td>
                <td class="align-middle" align="left">査定速報</td>
                <td class="align-middle"></td>
                <td class="align-middle" align="left"></td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <thead class="bg-primary text-light">
              <tr>
                <th class="align-middle" width="40%" height="35px">選択</th>
                <th class="align-middle" width="60%">帳票名</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk14" />
                </td>
                <td class="align-middle" align="left">目論見書</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk4" />
                </td>
                <td class="align-middle" align="left">全体とりまとめ表</td>
              </tr>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk16" />
                </td>
                <td class="align-middle" align="left">班別とりまとめ表</td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>
      <div class="container mb-3">
        <form action method>
          <button
            type="button"
            class="btn btn-light d-block mx-auto"
            @click="multipleExport()"
            style="width:120px;"
          >出力</button>
        </form>
      </div>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { APIService } from "@/apiService.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();
var numeral = require("numeral");
export default {
  components: {
    Layout,
    datepicker,
    Loading
  },
  data() {
    var kanCd = "";
    var kanMei = "";
    if (localStorage.getItem("Kanrisya_Cd") == 1) {
      kanCd = "0";
      kanMei = "すべて";
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      jpYear: "",
      engYear: "",
      year: "",
      ja: ja,
      kanrisya1Check: false,
      kanrisyaList: [],
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      nji_satei: "",
      koji_no_from: "",
      koji_no_to: "",
      nji_satei_date: "",
      satei3: "",
      mcodeList1: [],
      mcodeList2: [],
      meisai1: "",
      cd_value1: "",
      meisai2: "",
      cd_value2: "",
      satei_kbn: 1,
      kingaku_kbn: 3,
      tyouhyou_kbn: 0,
      output_order_han: false,
      output_order_koji: false,
      condition_tokki: false,
      condition_kyogi: false,
      condition_horyu: false,
      condition_kairyo: false,
      condition_sikkaku_kekkaku: false,
      chkArr: [],
      checkError: false,
      yearErrorMsg: "",
      isYearNotFound: false,
      isCheckBoxErr: false,
      checkboxMsg: "",
      isLoading: false,
      fullPage: true,
      yearFlg: true,
      order_kbn: 2,
      isNjisateiErr: false,
      njisateiMsg: [],
      gengo_name: ""
    };
  },
  mounted() {
    this.engYear = new Date().getFullYear();
    this.getGengo(new Date().getFullYear());
    this.getKanrisya();
    this.getMcodeList1();
    this.getMcodeList2();
  },
  methods: {
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
        this.gengo_name = result[0].gengo_name;
      });
    },
    changedYear() {
        if (this.jpYear != "") {
            var jpYear_1st_val  = this.jpYear.substring(0, 1);
            if(isNaN(jpYear_1st_val)){
              var matches = this.jpYear.match(/[a-zA-Z]+/g);
              var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
              console.log(yvalue)	
              var request_data = {	
                gengo_cd: matches.toString(),	
              }
            }
            apiService.getGengoStartDate(request_data)	
            .then(result=>{	
              console.log(result)	
              if(result.length >0){
                var startDate = result[0].start_date;	
                this.engYear = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);
                this.yearFlg = true;
                console.log(this.engYear)	
              }else{
                this.yearFlg = false;
              }
              
            });
        } else {
            this.jpYear = null;
            this.gengo_name = null;
        }
    },
    getKanrisya() {
      if (localStorage.getItem("Kanrisya_Cd") == 1) {
        this.kanrisya1Check = true;
      }
      var request_data = {
        kengen_cd: localStorage.getItem('kengen_cd'),
        kanrisya_cd: localStorage.getItem('Kanrisya_Cd'),
      };
      apiService
        .getKanrisyaByFlg(request_data)
        .then(result => {
          this.kanrisyaList = result;
        })
        .catch(error => {
          this.err = error;
        });
    },
    kanrisyaChange(kanrisya) {
      var kanrisya_index =
        kanrisya.target.options[kanrisya.target.options.selectedIndex].text;
      for (var i = 0; i < this.kanrisyaList.length; i++) {
        if (this.kanrisyaList[i].kanrisya_cd == kanrisya_index) {
          this.kanrisya_cd = kanrisya_index;
          this.kanrisya_mei = this.kanrisyaList[i].kanrisya_mei;
        }
      }
    },
    getMcodeList1() {
      apiService
        .fc105getMcodeList1()
        .then(result => {
          this.mcodeList1 = result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    codeChange1(mcode) {
      var mcode_index =
        mcode.target.options[mcode.target.options.selectedIndex].text;
      for (var i = 0; i < this.mcodeList1.length; i++) {
        if (this.mcodeList1[i].meisai == mcode_index) {
          this.meisai1 = mcode_index;
          this.cd_value1 = this.mcodeList1[i].cd_value;
        }
      }
    },
    getMcodeList2() {
      apiService
        .fc105getMcodeList2()
        .then(result => {
          this.mcodeList2 = result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    codeChange2(mcode) {
      var mcode_index =
        mcode.target.options[mcode.target.options.selectedIndex].text;
      for (var i = 0; i < this.mcodeList2.length; i++) {
        if (this.mcodeList2[i].meisai == mcode_index) {
          this.meisai2 = mcode_index;
          this.cd_value2 = this.mcodeList2[i].cd_value;
        }
      }
    },
    check_all() {
      var authorityAdmin = this.authorityAdmin();
      if (authorityAdmin) {
        this.chkArr = [
          "chk0",
          "chk8",
          "chk14",
          "chk11",
          "chk4",
          "chk12",
          "chk16",
          "chk13",
          "chk1",
          "chk17",
          "chk10",
          "chk2",
          "chk6",
          "chk3",
          "chk7",
          "chk15",
          "chk9",
          "chk5",
          "chk20"
        ];
      } else {
        this.chkArr = [
          "chk14",
          "chk4",
          "chk16"
        ];
      }
    },
    uncheck_all() {
      this.chkArr = [];
    },
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? "" : result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    nji_sateiChange() {
      if (this.isInteger(this.nji_satei)) {
        this.nji_satei = this.nji_satei
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    koji_no_fromChange() {
      if (this.isInteger(this.koji_no_from)) {
        this.koji_no_from = this.koji_no_from
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    koji_no_toChange() {
      if (this.isInteger(this.koji_no_to)) {
        this.koji_no_to = this.koji_no_to
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    multipleExport() {
      this.njisateiMsg = [];
      this.isLoading = true;
      this.isYearNotFound = false;
      this.isCheckBoxErr = false;
      this.isNjisateiErr = false;
      this.checkError = false;
      if(isNaN(this.engYear)){
        this.yearFlg = false;
      }
      if(!this.yearFlg){
        this.checkError = true;
        this.isLoading = false;
        this.isCheckBoxErr = true;
        this.checkboxMsg = '災害年' + this.$messages.msg["0002W"];
      }else{
        if (this.jpYear == null || this.jpYear == "") {
          this.checkError = true;
          this.isLoading = false;
          this.isYearNotFound = true;
          this.yearErrorMsg = this.$messages.msg["0004W"];
        }
        if (this.chkArr.length === 0) {
          this.checkError = true;
          this.isLoading = false;
          this.isCheckBoxErr = true;
          this.checkboxMsg = this.$messages.msg["0023W"];
        }
      this.requireCheck();
      }
      if (!this.checkError) {
        this.isYearNotFound = false;
        this.isCheckBoxErr = false;
        var request_data = {
          saigai_yyyy: this.engYear,
          jpYear : this.jpYear,
          nji_satei: this.nji_satei,
          satei_kbn: this.satei_kbn,
          kanrisya_cd: this.kanrisya_cd,
          kingaku_kbn: this.kingaku_kbn,
          tyouhyou_kbn: this.tyouhyou_kbn,
          output_order_han: this.output_order_han,
          output_order_koji: this.output_order_koji,
          condition_tokki: this.condition_tokki,
          condition_kyogi: this.condition_kyogi,
          condition_horyu: this.condition_horyu,
          condition_kairyo: this.condition_kairyo,
          condition_sikkaku_kekkaku: this.condition_sikkaku_kekkaku,
          excelObj: this.chkArr,
          meisai1: this.meisai1,
          meisai2: this.meisai2,
          jimusyo_mei: window.localStorage.getItem("Jimusyo_Mei"),
          order_kbn: this.order_kbn,
          gengo_name: this.gengo_name
        };
        apiService
          .fc105Export(request_data)
          .then(result => {
            var date = moment(String(new Date())).format("YYYYMMDD");
            var fileName = this.$constants.FAC05EXCELFN.LC400 + "_" + date + ".xlsx";
            if(this.nji_satei){
              fileName = this.$constants.FAC05EXCELFN.LC400 + "(第"+ this.nji_satei +"次)_"+ date + ".xlsx";
            }
            this.fc105Download(
              result,
              fileName
            );
          })
          .catch(() => {
            this.isCheckBoxErr = true;
            this.isLoading = false;
            this.checkboxMsg = this.$messages.msg['0002E'];
            confirm(this.$messages.msg['0013E']);
          });
      } else {
        this.isLoading = false;
        confirm(this.$messages.msg['0013E']);
      }
    },
    fc105Download(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    requireCheck() {
      this.isLoading = true;
      if (this.chkArr.indexOf("chk0") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0048W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk14") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0050W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk11") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0055W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk4") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0043W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk12") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0056W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk16") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0052W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk13") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0057W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk6") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0053W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk7") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0054W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk9") !== -1 || this.chkArr.indexOf("chk8") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0059W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk5") !== -1) {
        if (!this.nji_satei) {
          this.isNjisateiErr = true;
          this.njisateiMsg.push(this.$messages.msg["0058W"]);
          this.checkError = true;
          this.isLoading = false;
        }
      }
      if (this.chkArr.indexOf("chk20") !== -1) {
        if (!this.nji_satei || (this.nji_satei && !this.isInteger(this.nji_satei))) {
          this.isNjisateiErr = true;
          if(!this.nji_satei) {
            this.njisateiMsg.push(this.$messages.msg["0049W"]);
          } else {
            this.njisateiMsg.push('査定' + this.$messages.msg["0002W"]);
          }
          this.checkError = true;
          this.isLoading = false;
        }
      }
    },
    authorityAdmin(){
      var kengen_cd = localStorage.getItem('kengen_cd');
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>