<template>
	<AppLayout>
		<div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
		<main class="mb-3">
			<div class="col-sm-12 bg-err text-center">
        <span class="err" v-show="isCheckBoxErr">{{ this.checkboxMsg }}</span>
      </div>
			<div class="container mt-3 mb-3">
					<h4 class="text-primary">【抽出条件】</h4>
			</div>
			<div class="container border-bottom border-secondary mb-3">
				<form action method>
					<div class="form-group row" style="width:100%;">					
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control bg-required"
                maxlength="4"
                v-model="jpYear"
                @blur="changedYear()"
              />
            </div>
            <label
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >年</label>
					</div>
					<div class="form-group row" style="width:100%;">
            <div style="width:100%; max-width:220px">
              <span class="err" v-show="isYearNotFound">{{ this.yearErrorMsg }}</span>
            </div>
          </div>
					<div class="form-group row" style>
						<label class="col-sm col-form-label" style="width:100%; max-width:100px">査定</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input
                type="tel"
                class="form-control"
                maxlength="9"
                v-model="nji_satei"
                @blur="nji_sateiChange()"
              />
            </div>
						<label
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >次</label>
						<div class="col-sm pr-0 pl-0" style="width:100%; max-width:30px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:120px">総計算出額</label>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="gaku_kbn" v-bind:value="1" />
                報告額
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="gaku_kbn" v-bind:value="2" />
                申請額
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="gaku_kbn" v-bind:value="3" />
                査定額
              </label>
            </div>
					</div>
				</form>
			</div>
			<div class="container pt-3 border-secondary">
        <p class="text-info">出力対象の帳票にチェックを入れて下さい</p>
      </div>
			<div class="container mt-2 table-responsive">
        <div class="form-group float-left">
          <button
            v-on:click="check_all()"
            class="btn btn-light"
            type="submit"
            style="width:90px;"
          >全選択</button>
          <button
            v-on:click="uncheck_all()"
            class="btn btn-light ml-3"
            type="submit"
            style="width:90px;"
          >解除</button>
        </div>
      </div>
			<div class="container mt-2 table-responsive">
        <table class="table text-center table-bordered table-sm">
          <thead class="bg-primary text-light">
            <tr>
              <th class="align-middle" width="20%" height="35px">選択</th>
              <th class="align-middle" width="30%">帳票名</th>
              <th class="align-middle" width="20%">選択</th>
              <th class="align-middle" width="30%">帳票名</th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr>
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk0" />
                </td>
                <td class="align-middle" align="left">【データ集計処理】事務所別総計表</td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk1" />
                </td>
                <td class="align-middle" align="left">【データ集計処理】公共土木施設被害</td>
              </tr>
              <tr class="table-secondary">
                <td class="align-middle" height="35px">
                  <input type="checkbox" v-model="chkArr" value="chk2" />
                </td>
                <td class="align-middle" align="left">国庫負担データ出力 </td>
                <td class="align-middle">
                  <input type="checkbox" v-model="chkArr" value="chk3" />
                </td>
                <td class="align-middle" align="left">災害査定データ出力</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="container mb-3">
        <form action method>
          <button
            type="button"
            class="btn btn-light d-block mx-auto"
            @click="multipleExport()"
            style="width:120px;"
          >出力</button>
        </form>
      </div>
		</main>
	</AppLayout>
</template>

<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();
var numeral = require("numeral");

export default {
  name: "fg202",
  components: {
    AppLayout,Loading
	},
	data(){
		return {
			engYear: "",
      jpYear: "",
      year: "",
      gengo_name: "",
      todayDate: "",
			mcodeList:[],
			meisai: "",
			nji_satei: "",
			gaku_kbn: 1,
			chkArr: [],
			yearErrorMsg:  "",
			isYearNotFound: false,
			isCheckBoxErr: false,
			checkboxMsg: "",
			isLoading: false,
      fullPage: true
		}
	},
	mounted() {
    this.engYear = new Date().getFullYear();
    this.getGengo(new Date().getFullYear());
    this.getGengoName(new Date().getFullYear());
    this.getMcodeList();
	},
	methods: {
		formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? "" : result;
    },
		getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
        this.year = result;	
      });	
    },
    getGengoName(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengoName(request_data).then(result => {
        this.gengo_name = result;
      });
    },
    changedYear() {
      if (this.jpYear != "") {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace(/[^\d.]/g, "");	
        var request_data = {	
          gengo_cd: matches.toString()	
        };	
        apiService.getGengoStartDate(request_data).then(result => {	
          var startDate = result[0].start_date;	
          this.engYear = parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);	
          this.getGengoName(this.engYear);
        });	
      } else {
        this.engYear = null;
      }
		},
		getMcodeList() {
      apiService
        .fg202getMcodeList()
        .then(result => {
          this.mcodeList = result;
        })
        .catch(error => {
          console.log(error);
        });
		},
		nji_sateiChange() {
      this.nji_satei = this.formatNumber(this.nji_satei);
		},
		multipleExport() {
      this.isCheckBoxErr = false;
      this.isLoading = true;
      this.todayDate = this.year + "/" +moment(String(new Date())).format("MM/DD");
      if (this.jpYear  == null || this.jpYear  == "") {
        confirm(this.$messages.msg['0013E']);
        this.checkError = true;
        this.isLoading = false;
        this.isYearNotFound = true;
        this.yearErrorMsg = this.$messages.msg["0004W"];
      } else {
        this.checkError = false;
        this.yearErrorMsg = "";
			}
			if (!this.checkError) {
        if (this.chkArr.length === 0) {
          this.checkError = true;
          this.isLoading = false;
          this.isCheckBoxErr = true;
          this.checkboxMsg = this.$messages.msg["0023W"];
        } else {
          this.checkError = false;
        }
      }
      if (!this.checkError) {
        this.isYearNotFound = false;
        this.isCheckBoxErr = false;
        const request_data = {
          saigai_yyyy: this.engYear,
          nji_satei: this.nji_satei,
          gaku_kbn: this.gaku_kbn,
          excelObj: this.chkArr,
          todayDate: this.todayDate,
          gengo_name: this.gengo_name,
          jimusyo_mei: localStorage.getItem("Jimusyo_Mei"),
				};
        apiService
          .fg202Export(request_data)
          .then(result => {
            var date = moment(String(new Date())).format("YYYYMMDD");
            this.fg202Download(result,"本庁処理"+ date + ".xlsx");
          })
          .catch(error => {
            this.isCheckBoxErr = true;
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
            this.checkboxMsg = this.$messages.msg["0002E"];
            console.log(error);
          });
      }
    },
    fg202Download(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
		check_all() {
      this.chkArr = [
        "chk0",
        "chk1",
        "chk2",
        "chk3"
      ];
    },
    uncheck_all() {
      this.chkArr = [];
    },
	}
}
</script>