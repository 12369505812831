<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from "moment";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const apiService = new APIService();
var numeral = require("numeral");

export default {
  name: "fa112",
  components: {
    AppLayout,
    datepicker,
    Loading
  },
  data() {
    return {
      maxlength: "10",
      info: "",
      textError: "",
      ja: ja,
      jpYear: "",
      saigai_yyyy: this.$route.query.saigai_yyyy,
      saigai_ken: ("00000" + this.$route.query.saigai_ken).slice(-5),
      houkokusya_cd: this.$route.query.hou,
      houkoku_status: "1",
      meisyou: {},
      shicyousons: [],
      shicyou1: "",
      shicyou2: "",
      shicyou3: "",
      shicyou4: "",
      shicyou5: "",
      shicyou1_mei: "",
      shicyou2_mei: "",
      shicyou3_mei: "",
      shicyou4_mei: "",
      shicyou5_mei: "",
      saigai_meishou: "",
      saigai_end: "",
      saigai_start: "",
      higaiList: {},
      houkokusya_mei: "",
      hou: !this.$route.query.hou ? "" : this.$route.query.hou,
      cyousa_ritu: "",
      kinyubi: "",
      kinyuji: "",
      kyujyo_shicyoucd1: "",
      kyujyo_hatureibi1: "",
      kyujyo_shicyoucd2: "",
      kyujyo_hatureibi2: "",
      kyujyo_shicyoucd3: "",
      kyujyo_hatureibi3: "",
      uchiwake_shicyoucd1: "",
      kansoku1: "",
      uryou1: "",
      uryoubi_start1: "",
      uryoubi_end1: "",
      uryouji_start1: "",
      uryouji_end1: "",
      hiuryou1: "",
      hiuryoubi_start1: "",
      hiuryoubi_end1: "",
      hiuryouji_start1: "",
      hiuryoujii_end1: "",
      jikanuryou1: "",
      jikanuryoubi_start1: "",
      jikanuryoubi_end1: "",
      jikanuryouji_start1: "",
      jikanuryouji_end1: "",
      fusoku1: "",
      fusokubi1: "",
      fusokuji_start1: "",
      fusokuji_end1: "",
      fusokujifun_start1: "",
      fusokujifun_end1: "",
      sonota1: "",
      uchiwake_shicyoucd2: "",
      kansoku2: "",
      uryou2: "",
      uryoubi_start2: "",
      uryoubi_end2: "",
      uryouji_start2: "",
      uryouji_end2: "",
      hiuryou2: "",
      hiuryoubi_start2: "",
      hiuryoubi_end2: "",
      hiuryouji_start2: "",
      hiuryoujii_end2: "",
      jikanuryou2: "",
      jikanuryoubi_start2: "",
      jikanuryoubi_end2: "",
      jikanuryoujii_start2: "",
      jikanuryouji_end2: "",
      fusoku2: "",
      fusokubi2: "",
      fusokuji_start2: "",
      fusokuji_end2: "",
      fusokujifun_start2: "",
      fusokujifun_end2: "",
      sonota2: "",
      showInfo: false,
      showError: false,
      updateFlg: false,
      showInsert: false,
      showUpdate: false,
      showDelete: false,
      isLoading: false,
      fullPage: true,
      chk_date: ""
    };
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getGengo(this.saigai_yyyy);
    this.getMeisyou(this.saigai_yyyy, this.$route.query.saigai_ken);
    this.getShicyouson();

    if (this.$route.query.kubun == 1) {
      // 新規登録時
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken
      };
      apiService.fa112GetHoukokusyacd(request_data).then(result => {
        // 前報のデータ取得
        this.hou = result[0].hou;
        this.showHigaiHoukokuData();
        this.isLoading = false;
        //this.hou = !this.$route.query.hou ? this.$route.query.hou : "";
        this.hou = this.hou ? this.hou+1 : 1;
        this.$route.query.hou = this.hou;
        this.showInsert = true;
      }).catch(error => {
          this.isLoading = false;
          this.unique_error = error;
          console.log(error);
        });
    } else {
      // 更新時
      this.showHigaiHoukokuData();
    }
  },
  watch: {
    fusoku1: function(fusoku1) {
      if (fusoku1.length == 10 && fusoku1.keyCode === 46) {
        this.maxlength = 11;
      } else {
        this.maxlength = 9;
      }
    }
  },
  filters: {
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number) {
      return numeral(number).format("0,0");
    }
  },
  methods: {
    removeComma: function(number) {
      return number.toString().replace(/,/g, "");
    },
    formatNumber: function(number) {
      if (isNaN(number)) {
        return number;
      } else {
        return numeral(number).format("0,0");
      }
    },
    formatDecimal: function(number) {
      if (isNaN(number)) {
        return number;
      } else {
        return numeral(number).format("0,0.0");
      }
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    isFloat: function(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
      });
    },
    showHigaiHoukokuData() {
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken,
        hou: parseInt(this.hou)
      };
      apiService.fa112Detail(request_data).then(result => {
        if (result.higaiList.length > 0) {
          this.higaiList = result.higaiList;
          if(this.$route.query.kubun == null){
            this.updateFlg = true;
            this.showInsert = false;
            this.showUpdate = true;
            this.showDelete = true;
            this.hou = this.higaiList[0].hou;
            this.houkokusya_cd = this.higaiList[0].houkokusya_cd;
            this.houkokusya_mei = this.higaiList[0].houkokusya_mei;
            if (this.higaiList[0].kinyubi) {
              this.kinyubi = moment(String(this.higaiList[0].kinyubi)).format(
                "YYYY/MM/DD"
              );
            }
            this.kinyuji = this.higaiList[0].kinyuji;
            this.cyousa_ritu = this.higaiList[0].cyousa_ritu;
            this.houkoku_status = this.higaiList[0].houkoku_status;
          }
          this.uchiwake_shicyoucd1 = this.higaiList[0].uchiwake_shicyoucd1;
          this.kansoku1 = this.higaiList[0].kansoku1;
          this.kyujyo_shicyoucd1 = this.higaiList[0].kyujyo_shicyoucd1;
          if (this.higaiList[0].kyujyo_hatureibi1) {
            this.kyujyo_hatureibi1 = moment(
              String(this.higaiList[0].kyujyo_hatureibi1)
            ).format("YYYY/MM/DD");
          }
          if (this.higaiList[0].kyujyo_hatureibi2) {
            this.kyujyo_hatureibi2 = moment(
              String(this.higaiList[0].kyujyo_hatureibi2)
            ).format("YYYY/MM/DD");
          }
          if (this.higaiList[0].kyujyo_hatureibi3) {
            this.kyujyo_hatureibi3 = moment(
              String(this.higaiList[0].kyujyo_hatureibi3)
            ).format("YYYY/MM/DD");
          }
          this.kyujyo_shicyoucd2 = this.higaiList[0].kyujyo_shicyoucd2;
          this.kyujyo_shicyoucd3 = this.higaiList[0].kyujyo_shicyoucd3;
          if (this.higaiList[0].uryou1) {
            this.uryou1 = this.formatNumber(this.higaiList[0].uryou1);
          }
          this.uryoubi_start1 = this.higaiList[0].uryoubi_start1
                                ? moment(String(this.higaiList[0].uryoubi_start1)).format("YYYY/MM/DD")
                                : "";
          this.uryoubi_end1 = this.higaiList[0].uryoubi_end1
                              ? moment(String(this.higaiList[0].uryoubi_end1)).format("YYYY/MM/DD")
                              : "";
          this.uryouji_start1 = this.higaiList[0].uryouji_start1;
          this.uryouji_end1 = this.higaiList[0].uryouji_end1;
          if (this.higaiList[0].hiuryou1) {
            this.hiuryou1 = this.formatNumber(this.higaiList[0].hiuryou1);
          }
          this.hiuryoubi_start1 = this.higaiList[0].hiuryoubi_start1
                                  ? moment(String(this.higaiList[0].hiuryoubi_start1)).format("YYYY/MM/DD")
                                  : "";
          this.hiuryoubi_end1 = this.higaiList[0].hiuryoubi_end1
                                ? moment(String(this.higaiList[0].hiuryoubi_end1)).format("YYYY/MM/DD")
                                : "";
          this.hiuryouji_start1 = this.higaiList[0].hiuryouji_start1;
          this.hiuryoujii_end1 = this.higaiList[0].hiuryoujii_end1;
          if (this.higaiList[0].jikanuryou1) {
            this.jikanuryou1 = this.formatNumber(this.higaiList[0].jikanuryou1);
          }
          this.jikanuryoubi_start1 = this.higaiList[0].jikanuryoubi_start1
                                      ? moment(String(this.higaiList[0].jikanuryoubi_start1)).format("YYYY/MM/DD")
                                      : "";
          this.jikanuryoubi_end1 = this.higaiList[0].jikanuryoubi_end1
                                    ? moment(String(this.higaiList[0].jikanuryoubi_end1)).format("YYYY/MM/DD")
                                    : "";
          this.jikanuryouji_start1 = this.higaiList[0].jikanuryouji_start1;
          this.jikanuryouji_end1 = this.higaiList[0].jikanuryouji_end1;
          if (this.higaiList[0].fusoku1) {
            this.fusoku1 = this.formatDecimal(this.higaiList[0].fusoku1);
          }
          this.fusokubi1 = this.higaiList[0].fusokubi1
                            ? moment(String(this.higaiList[0].fusokubi1)).format("YYYY/MM/DD")
                            : "";
          this.fusokuji_start1 = this.higaiList[0].fusokuji_start1;
          this.fusokuji_end1 = this.higaiList[0].fusokuji_end1;
          this.fusokujifun_start1 = this.higaiList[0].fusokujifun_start1;
          this.fusokujifun_end1 = this.higaiList[0].fusokujifun_end1;
          this.sonota1 = this.higaiList[0].sonota1;
          this.uchiwake_shicyoucd2 = this.higaiList[0].uchiwake_shicyoucd2;
          this.kansoku2 = this.higaiList[0].kansoku2;
          if (this.higaiList[0].uryou2) {
            this.uryou2 = this.formatNumber(this.higaiList[0].uryou2);
          }
          this.uryoubi_start2 = this.higaiList[0].uryoubi_start2
                                ? moment(String(this.higaiList[0].uryoubi_start2)).format("YYYY/MM/DD")
                                : "";
          this.uryoubi_end2 = this.higaiList[0].uryoubi_end2
                                ? moment(String(this.higaiList[0].uryoubi_end2)).format("YYYY/MM/DD")
                                : "";
          this.uryouji_start2 = this.higaiList[0].uryouji_start2;
          this.uryouji_end2 = this.higaiList[0].uryouji_end2;
          if (this.higaiList[0].hiuryou2) {
            this.hiuryou2 = this.formatNumber(this.higaiList[0].hiuryou2);
          }
          this.hiuryoubi_start2 = this.higaiList[0].hiuryoubi_start2
                                  ? moment(String(this.higaiList[0].hiuryoubi_start2)).format("YYYY/MM/DD")
                                  : "";
          this.hiuryoubi_end2 = this.higaiList[0].hiuryoubi_end2
                                  ? moment(String(this.higaiList[0].hiuryoubi_end2)).format("YYYY/MM/DD")
                                  : "";
          this.hiuryouji_start2 = this.higaiList[0].hiuryouji_start2;
          this.hiuryoujii_end2 = this.higaiList[0].hiuryoujii_end2;
          if (this.higaiList[0].jikanuryou2) {
            this.jikanuryou2 = this.formatNumber(this.higaiList[0].jikanuryou2);
          }
          this.jikanuryoubi_start2 = this.higaiList[0].jikanuryoubi_start2
                                      ? moment(String(this.higaiList[0].jikanuryoubi_start2)).format("YYYY/MM/DD")
                                      : "";
          this.jikanuryoubi_end2 = this.higaiList[0].jikanuryoubi_end2
                                    ? moment(String(this.higaiList[0].jikanuryoubi_end2)).format("YYYY/MM/DD")
                                    : "";
          this.jikanuryoujii_start2 = this.higaiList[0].jikanuryoujii_start2;
          this.jikanuryouji_end2 = this.higaiList[0].jikanuryouji_end2;
          if (this.higaiList[0].fusoku2) {
            this.fusoku2 = this.formatDecimal(this.higaiList[0].fusoku2);
          }
          this.fusokubi2 = this.higaiList[0].fusokubi2
                            ? moment(String(this.higaiList[0].fusokubi2)).format("YYYY/MM/DD")
                            : "";
          this.fusokuji_start2 = this.higaiList[0].fusokuji_start2;
          this.fusokuji_end2 = this.higaiList[0].fusokuji_end2;
          this.fusokujifun_start2 = this.higaiList[0].fusokujifun_start2;
          this.fusokujifun_end2 = this.higaiList[0].fusokujifun_end2;
          this.sonota2 = this.higaiList[0].sonota2;

            if (this.uchiwake_shicyoucd1) {
              var shicyoucd1 = this.shicyousons.filter(
                i => i.key == this.uchiwake_shicyoucd1
              );
              this.shicyou1_mei = shicyoucd1[0].value;
            }
            if (this.uchiwake_shicyoucd2) {
              var shicyoucd2 = this.shicyousons.filter(
                i => i.key == this.uchiwake_shicyoucd2
              );
              this.shicyou2_mei = shicyoucd2[0].value;
            }
            if (this.kyujyo_shicyoucd1) {
              var shicyoucd3 = this.shicyousons.filter(
                i => i.key == this.kyujyo_shicyoucd1
              );
              this.shicyou3_mei = shicyoucd3[0].value;
            }
            if (this.kyujyo_shicyoucd2) {
              var shicyoucd4 = this.shicyousons.filter(
                i => i.key == this.kyujyo_shicyoucd2
              );
              this.shicyou4_mei = shicyoucd4[0].value;
            }
            if (this.kyujyo_shicyoucd3) {
              var shicyoucd5 = this.shicyousons.filter(
                i => i.key == this.kyujyo_shicyoucd3
              );
              this.shicyou5_mei = shicyoucd5[0].value;
            }
          }
          // 排他チェック用
          this.chk_date = result.chk_date;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      })
      .catch(error => {
        this.isLoading = false;
        this.unique_error = error;
        console.log(error);
      });
    },
    getMeisyou(year, ken) {
      var request_data = {
        year: year,
        ken: ken
      };
      apiService.fa112GetMeisyou(request_data).then(result => {
        this.meisyou = result;
        (this.saigai_meishou = this.meisyou[0].saigai_mei),
          (this.saigai_start = this.formatDate(this.meisyou[0].saigai_start)),
          (this.saigai_end = this.formatDate(this.meisyou[0].saigai_end));
      });
    },
    getShicyouson() {
      apiService.fa112GetShicyouson().then(result => {
        for (var i in result) {
          this.shicyousons.push({
            value: result[i].shicyou_mei,
            key: result[i].shicyou_cd
          });
        }
      });
    },
    shicyouson1Change(shicyou1) {
      this.uchiwake_shicyoucd1 = shicyou1;
      var shicyoucd1 = this.shicyousons.filter(i => i.key == shicyou1);
      this.shicyou1_mei = shicyoucd1[0].value;
    },
    shicyouson2Change(shicyou2) {
      this.uchiwake_shicyoucd2 = shicyou2;
      var shicyoucd2 = this.shicyousons.filter(i => i.key == shicyou2);
      this.shicyou2_mei = shicyoucd2[0].value;
    },
    shicyouson3Change(shicyou3) {
      this.kyujyo_shicyoucd1 = shicyou3;
      var shicyoucd3 = this.shicyousons.filter(i => i.key == shicyou3);
      this.shicyou3_mei = shicyoucd3[0].value;
    },
    shicyouson4Change(shicyou4) {
      this.kyujyo_shicyoucd2 = shicyou4;
      var shicyoucd4 = this.shicyousons.filter(i => i.key == shicyou4);
      this.shicyou4_mei = shicyoucd4[0].value;
    },
    shicyouson5Change(shicyou5) {
      this.kyujyo_shicyoucd3 = shicyou5;
      var shicyoucd5 = this.shicyousons.filter(i => i.key == shicyou5);
      this.shicyou5_mei = shicyoucd5[0].value;
    },
    uryou1Change(uryou1) {
      this.uryou1 = this.replaceComma(uryou1);
      if (this.isInteger(this.uryou1)) {
        this.uryou1 = this.uryou1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    uryou2Change(uryou2) {
      this.uryou2 = this.replaceComma(uryou2);
      if (this.isInteger(this.uryou2)) {
        this.uryou2 = this.uryou2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    hiuryou1Change(hiuryou1) {
      this.hiuryou1 = this.replaceComma(hiuryou1);
      if (this.isInteger(this.hiuryou1)) {
        this.hiuryou1 = this.hiuryou1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    hiuryou2Change(hiuryou2) {
      this.hiuryou2 = this.replaceComma(hiuryou2);
      if (this.isInteger(this.hiuryou2)) {
        this.hiuryou2 = this.hiuryou2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    jikanuryou1Change(jikanuryou1) {
      this.jikanuryou1 = this.replaceComma(jikanuryou1);
      if (this.isInteger(this.jikanuryou1)) {
        this.jikanuryou1 = this.jikanuryou1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    jikanuryou2Change(jikanuryou2) {
      this.jikanuryou2 = this.replaceComma(jikanuryou2);
      if (this.isInteger(this.jikanuryou2)) {
        this.jikanuryou2 = this.jikanuryou2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    fusoku1Change(fusoku1) {
      this.fusoku1 = this.replaceComma(fusoku1);
      if (this.isFloat(this.fusoku1)) {
        this.fusoku1 = this.fusoku1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    fusoku2Change(fusoku2) {
      this.fusoku2 = this.replaceComma(fusoku2);
      if (this.isFloat(this.fusoku2)) {
        this.fusoku2 = this.fusoku2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    insertUpdate() {
      this.isLoading = true;

      if (this.hou == null || this.hou == "") {
        this.showInfo = false;
        this.showError = true;
        confirm(this.$messages.msg['0013E']);
        this.textError = '報を入力してください。';
        this.isLoading = false;
        return;
      }

      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken,
        houkokusya_cd: this.houkokusya_cd,
        houkokusya_mei: this.houkokusya_mei,
        houkoku_status: this.houkoku_status,
        hou: this.hou,
        kinyubi: this.kinyubi,
        kinyuji: this.kinyuji,
        cyousa_ritu: this.cyousa_ritu,
        uchiwake_shicyoucd1: this.uchiwake_shicyoucd1,
        kansoku1: this.kansoku1,
        kyujyo_shicyoucd1: this.kyujyo_shicyoucd1,
        kyujyo_hatureibi1: this.kyujyo_hatureibi1,
        kyujyo_shicyoucd2: this.kyujyo_shicyoucd2,
        kyujyo_hatureibi2: this.kyujyo_hatureibi2,
        kyujyo_shicyoucd3: this.kyujyo_shicyoucd3,
        kyujyo_hatureibi3: this.kyujyo_hatureibi3,
        uryou1: this.removeComma(this.uryou1),
        uryoubi_start1: !this.uryoubi_start1
          ? null
          : moment(String(this.uryoubi_start1)).format("YYYY/MM/DD"),
        uryoubi_end1: !this.uryoubi_end1
          ? null
          : moment(String(this.uryoubi_end1)).format("YYYY/MM/DD"),
        uryouji_start1: this.uryouji_start1,
        uryouji_end1: this.uryouji_end1,
        hiuryou1: this.removeComma(this.hiuryou1),
        hiuryoubi_start1: !this.hiuryoubi_start1
          ? null
          : moment(String(this.hiuryoubi_start1)).format("YYYY/MM/DD"),
        hiuryoubi_end1: !this.hiuryoubi_end1
          ? null
          : moment(String(this.hiuryoubi_end1)).format("YYYY/MM/DD"),
        hiuryouji_start1: this.hiuryouji_start1,
        hiuryoujii_end1: this.hiuryoujii_end1,
        jikanuryou1: this.removeComma(this.jikanuryou1),
        jikanuryoubi_start1: !this.jikanuryoubi_start1
          ? null
          : moment(String(this.jikanuryoubi_start1)).format("YYYY/MM/DD"),
        jikanuryoubi_end1: !this.jikanuryoubi_end1
          ? null
          : moment(String(this.jikanuryoubi_end1)).format("YYYY/MM/DD"),
        jikanuryouji_start1: this.jikanuryouji_start1,
        jikanuryouji_end1: this.jikanuryouji_end1,
        fusoku1: this.removeComma(this.fusoku1),
        fusokubi1: !this.fusokubi1
          ? null
          : moment(String(this.fusokubi1)).format("YYYY/MM/DD"),
        fusokuji_start1: this.fusokuji_start1,
        fusokuji_end1: this.fusokuji_end1,
        fusokujifun_start1: this.fusokujifun_start1,
        fusokujifun_end1: this.fusokujifun_end1,
        sonota1: this.sonota1,
        uchiwake_shicyoucd2: this.uchiwake_shicyoucd2,
        kansoku2: this.kansoku2,
        uryou2: this.removeComma(this.uryou2),
        uryoubi_start2: !this.uryoubi_start2
          ? null
          : moment(String(this.uryoubi_start2)).format("YYYY/MM/DD"),
        uryoubi_end2: !this.uryoubi_end2
          ? null
          : moment(String(this.uryoubi_end2)).format("YYYY/MM/DD"),
        uryouji_start2: this.uryouji_start2,
        uryouji_end2: this.uryouji_end2,
        hiuryou2: this.removeComma(this.hiuryou2),
        hiuryoubi_start2: !this.hiuryoubi_start2
          ? null
          : moment(String(this.hiuryoubi_start2)).format("YYYY/MM/DD"),
        hiuryoubi_end2: !this.hiuryoubi_end2
          ? null
          : moment(String(this.hiuryoubi_end2)).format("YYYY/MM/DD"),
        hiuryouji_start2: this.hiuryouji_start2,
        hiuryoujii_end2: this.hiuryoujii_end2,
        jikanuryou2: this.removeComma(this.jikanuryou2),
        jikanuryoubi_start2: !this.jikanuryoubi_start2
          ? null
          : moment(String(this.jikanuryoubi_start2)).format("YYYY/MM/DD"),
        jikanuryoubi_end2: !this.jikanuryoubi_end2
          ? null
          : moment(String(this.jikanuryoubi_end2)).format("YYYY/MM/DD"),
        jikanuryoujii_start2: this.jikanuryoujii_start2,
        jikanuryouji_end2: this.jikanuryouji_end2,
        fusoku2: this.removeComma(this.fusoku2),
        fusokubi2: !this.fusokubi2
          ? null
          : moment(String(this.fusokubi2)).format("YYYY/MM/DD"),
        fusokuji_start2: this.fusokuji_start2,
        fusokuji_end2: this.fusokuji_end2,
        fusokujifun_start2: this.fusokujifun_start2,
        fusokujifun_end2: this.fusokujifun_end2,
        sonota2: this.sonota2,
        loginId: localStorage.getItem("LOGIN_ID"),
        chk_date: this.chk_date
      };
      if (this.updateFlg) {
        this.showInfo = false;
        this.showError = false;
        apiService
          .fa112Update(request_data)
          .then(result => {
            this.showInfo = true;
            this.showError = false;
            this.info = result.msg;
            this.chk_date = result.chk_date;
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.showInfo = false;
            this.showError = true;
            this.textError = error;
          });
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        apiService
          .fa112Insert(request_data)
          .then(result => {
            this.showInfo   = true;
            this.showError  = false;
            this.showDelete = true;
            this.updateFlg  = true;
            this.showUpdate = true;
            this.showInsert = false;
            
            this.info = result.msg;
            this.chk_date = result.chk_date;

            this.$route.query.kubun = null;
            this.showHigaiHoukokuData();
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.showInfo = false;
            this.showError = true;
            this.showDelete = false;
            this.updateFlg = false;
            this.textError = error;
          });
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    },
    delete_btn() {
      this.isLoading = true;
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        saigai_ken: this.$route.query.saigai_ken,
        hou: parseInt(this.hou),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fa112Delete(request_data)
          .then(result => {
            this.showInfo = true;
            this.info = result;
            this.clearData();
            this.showDelete = false;
            this.showInsert = false;
            this.showUpdate = false;
            this.isLoading = false;
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            console.log(error);
            this.showInfo  = false;
            this.showError = true;
            this.textError = error;
            this.isLoading = false;
          });
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } else {
        this.isLoading = false;
      }
    },
    clearData() {
      (this.houkokusya_cd = ""),
        (this.houkokusya_mei = ""),
        (this.hou = ""),
        (this.cyousa_ritu = ""),
        (this.kinyubi = ""),
        (this.kinyuji = ""),
        (this.kyujyo_shicyoucd1 = ""),
        (this.kyujyo_hatureibi1 = ""),
        (this.kyujyo_shicyoucd2 = ""),
        (this.kyujyo_hatureibi2 = ""),
        (this.kyujyo_shicyoucd3 = ""),
        (this.kyujyo_hatureibi3 = ""),
        (this.uchiwake_shicyoucd1 = ""),
        (this.uryou1 = ""),
        (this.uryoubi_start1 = ""),
        (this.uryoubi_end1 = ""),
        (this.uryouji_start1 = ""),
        (this.uryouji_end1 = ""),
        (this.hiuryou1 = ""),
        (this.hiuryoubi_start1 = ""),
        (this.hiuryoubi_end1 = ""),
        (this.hiuryouji_start1 = ""),
        (this.hiuryoujii_end1 = ""),
        (this.jikanuryou1 = ""),
        (this.jikanuryoubi_start1 = ""),
        (this.jikanuryoubi_end1 = ""),
        (this.jikanuryouji_start1 = ""),
        (this.jikanuryouji_end1 = ""),
        (this.fusoku1 = ""),
        (this.fusokubi1 = ""),
        (this.fusokuji_start1 = ""),
        (this.fusokuji_end1 = ""),
        (this.fusokujifun_start1 = ""),
        (this.fusokujifun_end1 = ""),
        (this.sonota1 = ""),
        (this.uchiwake_shicyoucd2 = ""),
        (this.uryou2 = ""),
        (this.uryoubi_start2 = ""),
        (this.uryoubi_end2 = ""),
        (this.uryouji_start2 = ""),
        (this.uryouji_end2 = ""),
        (this.hiuryou2 = ""),
        (this.hiuryoubi_start2 = ""),
        (this.hiuryoubi_end2 = ""),
        (this.hiuryouji_start2 = ""),
        (this.hiuryoujii_end2 = ""),
        (this.jikanuryou2 = ""),
        (this.jikanuryoubi_start2 = ""),
        (this.jikanuryoubi_end2 = ""),
        (this.jikanuryoujii_start2 = ""),
        (this.jikanuryouji_end2 = ""),
        (this.fusoku2 = ""),
        (this.fusokubi2 = ""),
        (this.fusokuji_start2 = ""),
        (this.fusokuji_end2 = ""),
        (this.fusokujifun_start2 = ""),
        (this.fusokujifun_end2 = ""),
        (this.sonota2 = ""),
        (this.shicyou1 = ""),
        (this.shicyou2 = ""),
        (this.shicyou3 = ""),
        (this.shicyou4 = ""),
        (this.shicyou5 = "");
      this.shicyou1_mei = "";
      this.shicyou2_mei = "";
      this.shicyou3_mei = "";
      this.shicyou4_mei = "";
      this.shicyou5_mei = "";
      this.kansoku1 = "";
      this.kansoku2 = "";
    },
    tabChange1() {
      this.$refs["uchiwake_shicyoucd2"].focus();
    },
    tabChange2() {
      this.$refs["kansoku2"].focus();
    },
    tabChange3() {
      this.$refs["uryouji_end2"].focus();
    },
    tabChange4() {
      this.$refs["hiuryoujii_end2"].focus();
    },
    tabChange5() {
      this.$refs["jikanuryouji_end2"].focus();
    },
    tabChange6() {
      this.$refs["fusokujifun_end2"].focus();
    },
    tabChange7() {
      this.$refs["uchiwake_shicyoucd1"].focus();
    },
    tabChange8() {
      this.$refs["kansoku1"].focus();
    },
    tabChange9() {
      this.$refs["uryouji_end1"].focus();
    },
    tabChange10() {
      this.$refs["hiuryoujii_end1"].focus();
    },
    tabChange11() {
      this.$refs["jikanuryouji_end1"].focus();
    },
    tabChange12() {
      this.$refs["fusokujifun_end1"].focus();
    },
    tabChange13() {
      this.$refs["sonota1"].focus();
    },
    tabChange14() {
      this.$refs["jpYear"].focus();
    }
  }
};
</script>
<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3" id="fa112">
      <p class="col-md-12 bg-success-info text-center" v-show="showInfo">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err" v-show="showError">{{ textError }}</p>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:700px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input
                  ref="jpYear"
                  type="text"
                  class="form-control bg-light"
                  v-model="jpYear"
                  readonly
                />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>
            </div>
            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
              <div class="col-sm pr-0 pl-0" value style="width:100%; max-width:105px">
                <input type="text" class="form-control bg-light" v-model="saigai_ken" readonly />
              </div>
              <label for class="col-sm col-form-label" style="width:100%; max-width:125px">異常気象名</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
                <input type="text" class="form-control bg-light" v-model="saigai_meishou" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:120px"
              >災害発生日</label>
              <div class="col-sm pr-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_start" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-center"
                style="width:100%; max-width:50px"
              >〜</label>
              <div class="col-sm pl-0" style="width:100%; max-width:130px">
                <input type="text" class="form-control bg-light" v-model="saigai_end" readonly />
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <div class="container pt-3 clearfix border-secondary">
        <div class="form-group row float-left" style="width:100%; max-width:700px">
          <div class="form-check form-check-inline" style="width:100%; max-width:150px">
            <label class="col-sm col-form-label" for>災害報告入力</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              v-model="houkoku_status"
              v-bind:value="1"
              checked
            />
            <label class="form-check-label" for>解除</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="houkoku_status" v-bind:value="2" />
            <label class="form-check-label" for>〆切</label>
          </div>
        </div>
        <div class="form-inline float-right">
          <button
            class="btn btn-light"
            type="submit"
            style="width:120px;"
            v-show="showInsert"
            @click="insertUpdate()"
          >登録</button>
          <button
            class="btn btn-light"
            type="submit"
            style="width:120px;"
            v-show="showUpdate"
            @click="insertUpdate()"
          >更新</button>
          <router-link
            :to="{ name: 'FA104' }"
            class="btn btn-light ml-3"
            style="width:120px;"
            role="button"
          >戻る</router-link>
        </div>
      </div>
      <div class="container mb-3 pt-3 border-top border-secondary">
        <div class="form-group row" style="width:100%;">
          <label for class="col-sm col-form-label text-center" style="width:100%; max-width:40px">第</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:50px">
            <input type="tel" class="form-control bg-required" v-model="hou" maxlength="9" />
          </div>
          <label for class="col-sm col-form-label text-center" style="width:100%; max-width:40px">報</label>
          <label
            for
            class="col-sm col-form-label text-center"
            style="width:100%; max-width:80px"
          >報告者</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:200px">
            <input type="text" class="form-control" maxlength="100" v-model="houkokusya_mei" />
          </div>
          <label
            for
            class="col-sm col-form-label text-center"
            style="width:100%; max-width:100px"
          >記入日時</label>
          <div class="col-sm pr-0" style="width:100%;max-width:200px;padding:0px;">
            <datepicker
              :language="ja"
              v-model="kinyubi"
              :value="kinyubi"
              format="yyyy/MM/dd"
              :typeable="true"
              width="100px"
            ></datepicker>
          </div>
          <div class="col-sm pr-0" style="width:100%; max-width:80px">
            <input type="tel" class="form-control" v-model="kinyuji" maxlength="2" />
          </div>
          <label
            for
            class="col-sm col-form-label text-center pl-1"
            style="width:100%; max-width:80px"
          >時現在</label>
          <label
            for
            class="col-sm col-form-label text-center"
            style="width:100%; max-width:80px"
          >調査率</label>
          <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
            <input type="tel" class="form-control" v-model="cyousa_ritu" maxlength="3" />
          </div>
          <label for class="col-sm col-form-label text-center" style="width:100%; max-width:40px">％</label>
        </div>
      </div>
      <div class="container mt-2 pt-3 border-top border-secondary">
        <table class="table table-sm table-bordered">
          <thead class="bg-primary text-light">
            <tr>
              <th scope="col" class="align-middle text-center border-right" width="12%"></th>
              <th
                scope="col"
                colspan
                class="align-middle text-center border-right"
                width="44%"
              >連続雨量最大</th>
              <th scope="col" colspan="10" class="align-middle text-center" width="44%">被災中心地</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">市町村</th>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <select
                    ref="uchiwake_shicyoucd1"
                    class="form-control"
                    name
                    style="width:100%;"
                    v-model="uchiwake_shicyoucd1"
                    @change="shicyouson1Change(uchiwake_shicyoucd1)"
                    v-on:keydown.tab="tabChange1()"
                  >
                    <option
                      v-for="item in shicyousons"
                      :key="item.key"
                      :value="item.key"
                    >{{item.key}} : {{item.value}}</option>
                  </select>
                </div>
              </td>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <select
                    ref="uchiwake_shicyoucd2"
                    class="form-control"
                    name
                    style="width:100%;"
                    v-model="uchiwake_shicyoucd2"
                    @change="shicyouson2Change(uchiwake_shicyoucd2)"
                    v-on:keydown.tab="tabChange8()"
                  >
                    <option
                      v-for="item in shicyousons"
                      :key="item.key"
                      :value="item.key"
                    >{{item.key}} : {{item.value}}</option>
                  </select>
                </div>
              </td>
            </tr>
            <!--市町村 END-->
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">観測所</th>
              <td class="align-middle border-right" width="44%">
                <input
                  ref="kansoku1"
                  type="text"
                  class="form-control p-1"
                  maxlength="100"
                  v-model="kansoku1"
                  style="width:100%"
                  v-on:keydown.tab="tabChange2()"
                />
              </td>
              <td class="align-middle border-right" width="44%">
                <input
                  ref="kansoku2"
                  type="text"
                  class="form-control p-1"
                  maxlength="100"
                  v-model="kansoku2"
                  style="width:100%"
                  v-on:keydown.tab="tabChange9()"
                />
              </td>
            </tr>
            <!--観測所 END-->
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">連続雨量</th>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="uryou1"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="uryou1Change(uryou1)"
                  />
                  <label class="my-1 mr-2" for>㎜</label>
                  <datepicker
                    :language="ja"
                    v-model="uryoubi_start1"
                    :value="uryoubi_start1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="uryouji_start1"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時〜</label>
                  <datepicker
                    :language="ja"
                    v-model="uryoubi_end1"
                    :value="uryoubi_end1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    ref="uryouji_end1"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="uryouji_end1"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange3()"
                  />
                  <label class="my-1 mr-2" for>時</label>
                </div>
              </td>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="uryou2"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="uryou2Change(uryou2)"
                  />
                  <label class="my-1 mr-2" for>㎜</label>
                  <datepicker
                    :language="ja"
                    v-model="uryoubi_start2"
                    :value="uryoubi_start2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="uryouji_start2"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時〜</label>
                  <datepicker
                    :language="ja"
                    v-model="uryoubi_end2"
                    :value="uryoubi_end2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    ref="uryouji_end2"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="uryouji_end2"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange10()"
                  />
                  <label class="my-1 mr-2" for>時</label>
                </div>
              </td>
            </tr>
            <!--連続雨量 END-->
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">最大日雨量</th>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="hiuryou1"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="hiuryou1Change(hiuryou1)"
                  />
                  <label class="my-1 mr-2" for>㎜</label>
                  <datepicker
                    :language="ja"
                    v-model="hiuryoubi_start1"
                    :value="hiuryoubi_start1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="hiuryouji_start1"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時〜</label>
                  <datepicker
                    :language="ja"
                    v-model="hiuryoubi_end1"
                    :value="hiuryoubi_end1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    ref="hiuryoujii_end1"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="hiuryoujii_end1"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange4()"
                  />
                  <label class="my-1 mr-2" for>時</label>
                </div>
              </td>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="hiuryou2"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="hiuryou2Change(hiuryou2)"
                  />
                  <label class="my-1 mr-2" for>㎜</label>
                  <datepicker
                    :language="ja"
                    v-model="hiuryoubi_start2"
                    :value="hiuryoubi_start2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="hiuryouji_start2"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時〜</label>
                  <datepicker
                    :language="ja"
                    v-model="hiuryoubi_end2"
                    :value="hiuryoubi_end2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    ref="hiuryoujii_end2"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="hiuryoujii_end2"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange11()"
                  />
                  <label class="my-1 mr-2" for>時</label>
                </div>
              </td>
            </tr>
            <!--最大日雨量 END-->
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">最大時間雨量</th>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="jikanuryou1"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="jikanuryou1Change(jikanuryou1)"
                  />
                  <label class="my-1 mr-2" for>㎜</label>
                  <datepicker
                    :language="ja"
                    v-model="jikanuryoubi_start1"
                    :value="jikanuryoubi_start1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="jikanuryouji_start1"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時〜</label>
                  <datepicker
                    :language="ja"
                    v-model="jikanuryoubi_end1"
                    :value="jikanuryoubi_end1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    ref="jikanuryouji_end1"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="jikanuryouji_end1"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange5()"
                  />
                  <label class="my-1 mr-2" for>時</label>
                </div>
              </td>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="jikanuryou2"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="jikanuryou2Change(jikanuryou2)"
                  />
                  <label class="my-1 mr-2" for>㎜</label>
                  <datepicker
                    :language="ja"
                    v-model="jikanuryoubi_start2"
                    :value="jikanuryoubi_start2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="jikanuryoujii_start2"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時〜</label>
                  <datepicker
                    :language="ja"
                    v-model="jikanuryoubi_end2"
                    :value="jikanuryoubi_end2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    ref="jikanuryouji_end2"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="jikanuryouji_end2"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange12()"
                  />
                  <label class="my-1 mr-2" for>時</label>
                </div>
              </td>
            </tr>
            <!--最大時間雨量 END-->
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">最大平均風速</th>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="fusoku1"
                    :maxlength="maxlength"
                    style="width:60px;"
                    v-on:change="fusoku1Change(fusoku1)"
                  />
                  <label class="my-1 mr-2" for>m/s</label>
                  <datepicker
                    :language="ja"
                    v-model="fusokubi1"
                    :value="fusokubi1"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokuji_start1"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokujifun_start1"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>分〜</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokuji_end1"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時</label>
                  <input
                    ref="fusokujifun_end1"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokujifun_end1"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange6()"
                  />
                  <label class="my-1 mr-2" for>分</label>
                </div>
              </td>
              <td class="align-middle border-right" width="44%">
                <div class="form-inline align-items-center">
                  <input
                    type="tel"
                    class="form-control p-1"
                    v-model="fusoku2"
                    maxlength="9"
                    style="width:60px;"
                    v-on:change="fusoku2Change(fusoku2)"
                  />
                  <label class="my-1 mr-2" for>m/s</label>
                  <datepicker
                    :language="ja"
                    v-model="fusokubi2"
                    :value="fusokubi2"
                    format="yyyy/MM/dd"
                    :typeable="true"
                    width="100px"
                  ></datepicker>
                  <label class="my-1 mr-2" for>日</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokuji_start2"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokujifun_start2"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>分〜</label>
                  <input
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokuji_end2"
                    maxlength="2"
                    style="width:30px"
                  />
                  <label class="my-1 mr-2" for>時</label>
                  <input
                    ref="fusokujifun_end2"
                    type="tel"
                    class="form-control p-1 text-right"
                    v-model="fusokujifun_end2"
                    maxlength="2"
                    style="width:30px"
                    v-on:keydown.tab="tabChange13()"
                  />
                  <label class="my-1 mr-2" for>分</label>
                </div>
              </td>
            </tr>
            <!--最大平均風速 END-->
            <tr>
              <th scope="row" class="align-middle table-primary border-right" width="12%">その他</th>
              <td class="align-middle border-right" width="44%">
                <input
                  ref="sonota1"
                  type="text"
                  class="form-control p-1"
                  maxlength="100"
                  v-model="sonota1"
                  style="width:100%"
                  v-on:keydown.tab="tabChange7()"
                />
              </td>
              <td class="align-middle border-right" width="44%">
                <input
                  type="text"
                  class="form-control p-1"
                  maxlength="100"
                  v-model="sonota2"
                  style="width:100%"
                  v-on:keydown.tab="tabChange14()"
                />
              </td>
            </tr>
            <!--その他 END-->
          </tbody>
        </table>
        <div class="form-inline float-right">
          <button
            class="btn btn-light ml-3"
            type="submit"
            style="width:120px;"
            v-show="showDelete"
            @click="delete_btn()"
          >削除</button>
        </div>
      </div>
    </main>
  </AppLayout>
</template>