<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle">
          <img src="@/assets/loader.gif" style="dipslay: inline-block; float: left" />
          <h4 style="dipslay: inline-block; float: left; color: #3399f3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="container" style="max-width: 1200px; width: 100%">
        <div class="mb-4" style="max-width: 1200px; width: 100%; display: flex">
          <div style="width: 50%">
            <div v-for="daikoumoku in limitCountFirstHalf(fileList)" :key="'daikoumoku:' + daikoumoku.meisai" class="flex-row flex-wrap">
              <h4 class="mt-4 text-primary">{{ daikoumoku.name }}</h4>
              <div v-for="file in daikoumoku.items" :key="file.file_no" class="flex-row flex-wrap">
                <h5 v-if="file.chukoumoku == 1 && daikoumoku.meisai == 1 && file.check_change_chukoumoku === true" v-once class="mt-4 text-primary">全体版<font size="2">※容量が大きいため、ご注意ください。（約18MB）</font></h5>
                <h5 v-if="file.chukoumoku == 1 && daikoumoku.meisai !== 1 && file.check_change_chukoumoku === true" v-once class="mt-4 text-primary">全体版</h5>
                <h5 v-if="file.chukoumoku == 2 && file.check_change_chukoumoku === true" v-once class="mt-4 text-primary">分割版</h5>
                <img v-if="file.file_type == 1" src="@/assets/pdf_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <img v-if="file.file_type == 2" src="@/assets/excel_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <img v-if="file.file_type == 3" src="@/assets/word_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <img v-if="file.file_type == 4" src="@/assets/ppt_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <a class="btn btn-lg mr-3" type="button" @click="Download(file.file_no, file.file_name)">{{ file.file_name }}</a>
              </div>
            </div>
          </div>
          <div style="width: 50%">
            <div v-for="daikoumoku in limitCountSecondHalf(fileList)" :key="daikoumoku.meisai" class="flex-row flex-wrap">
              <h4 class="mt-4 text-primary">{{ daikoumoku.name }}</h4>
              <div v-for="file in daikoumoku.items" :key="file.file_no" class="flex-row flex-wrap">
                <img v-if="file.file_type == 1" src="@/assets/pdf_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <img v-if="file.file_type == 2" src="@/assets/excel_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <img v-if="file.file_type == 3" src="@/assets/word_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <img v-if="file.file_type == 4" src="@/assets/ppt_icn.png" alt="" class="img-fluid mx-auto" style="height: 37px; width: 37px" />
                <a class="btn btn-lg mr-3" type="button" @click="Download(file.file_no, file.file_name)">{{ file.file_name }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from "moment";
import Loading from "vue-loading-overlay";

const apiService = new APIService();

export default {
  name: "fg203",
  components: {
    AppLayout,
    Loading,
  },
  data() {
    return {
      youshikiList: "",
      youshiki: [],
      fileList: [],
      daikoumokuList: "",
      daikoumokus: [],
      daikoumoku_meisai: [],
      chukoumoku: "",
      isLoading: false,
      fullPage: true,
    };
  },
  created() {
    this.getFailinfo();
  },
  methods: {
    getFailinfo() {
      this.isLoading = true;
      apiService
        .getYoushikiList()
        .then((result) => {
          this.youshikiList = result.youshikiList;
          this.daikoumokuList = result.daikoumokuList;
          this.chukoumokuList = result.chukoumokuList;
          this.fileList = [];

          // 配列にオブジェクトを格納
          for (var i = 0; i < this.daikoumokuList.length; i++) {
            this.fileList.push({
              name: this.daikoumokuList[i].cd_value,
              meisai: this.daikoumokuList[i].meisai,
              items: [],
              chukoumoku: null,
            });
            // items配列にファイル情報を格納
            for (var j = 0; j < this.youshikiList.length; j++) {
              if (this.fileList[i].meisai === this.youshikiList[j].daikoumoku) {
                this.fileList[i].items.push({
                  file_name: this.youshikiList[j].file_name,
                  file_no: this.youshikiList[j].file_no,
                  file_type: this.youshikiList[j].file_type,
                  chukoumoku: this.youshikiList[j].chukoumoku,
                });
              }
            }
          }
          for (var k = 0; k < this.fileList.length; k++) {
            // 中項目変更判定を初期化
            var checkedChukoumoku = 0;
            for (var l = 0; l < this.fileList[k].items.length; l++) {
              // 中項目のnull判定
              if (this.fileList[k].items[l].chukoumoku !== null) {
                // 一つ前の中項目と比べて変更されているか確認
                if (this.fileList[k].items[l].chukoumoku !== checkedChukoumoku) {
                  // 変更されていた場合、変更判定：trueを追加する
                  this.fileList[k].items[l]["check_change_chukoumoku"] = true;
                } else {
                  // 変更されていなかった場合、変更判定：falseを追加する
                  this.fileList[k].items[l]["check_change_chukoumoku"] = false;
                }
              }
              checkedChukoumoku = this.fileList[k].items[l].chukoumoku;
            }
          }
          this.kanrisya_all = false;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          this.isLoading = false;
        })
        .catch((error) => {
          this.err = error;
          this.isLoading = false;
        });
    },
    limitCountFirstHalf(lists) {
      return lists.slice(0, 9);
    },
    limitCountSecondHalf(lists) {
      return lists.slice(9, 16);
    },
    Download(file_no, file_name) {
      var request_data = {
        file_no: file_no,
        file_name: file_name,
      };
      let file_type = file_name.split(".").pop();
      let file = file_name.split(".").slice(0, -1).join(".");
      apiService
        .Download(request_data)
        .then((result) => {
          var date = moment(String(new Date())).format("YYYYMMDD");
          this.downloadReport(result, file + "_" + date + "." + file_type);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadReport(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
  },
};
</script>
