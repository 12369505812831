<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";

const apiService = new APIService();

export default {
  name: "M1102",
  components: {
    AppLayout
  },
  data() {
    return {
      id: "",
      old_password: "",
      password: "",
      password_confirmation: "",
      info: "",
      textError: "",
      errors : {
        id: null,
        old_password: null,
        password: null,
        password_confirmation: null,
      }
    };
  },
  methods: {
    change_pwd() {
    this.errors.id = null;
    this.errors.old_password = null;
    this.errors.password = null;
    this.errors.password_confirmation = null;
    this.textError = "";
    this.info = "";
    var insert_data = {
      id: this.id,
      old_password: this.old_password,
      password: this.password,
      password_confirmation: this.password_confirmation,
    };
    apiService
      .m1102ChangePassword(insert_data)
      .then(result => {
        this.info = result;
      })
      .catch(error => {
        confirm(this.$messages.msg['0013E']);
        if(error.errors) {
          this.errors.id = (error.errors.id) ? error.errors.id[0] : '';
          this.errors.old_password = (error.errors.old_password) ? error.errors.old_password[0] : '';
          this.errors.password = (error.errors.password) ? error.errors.password[0] : '';
          this.errors.password_confirmation = (error.errors.password_confirmation) ? error.errors.password_confirmation[0] : '';
        } else {
          this.textError = error;
        }
      });
    }
  }
};
</script>
<template>
  <AppLayout>
    <main class="mb-3" style="margin-top:20px;">
      <p class="col-md-12 bg-success-info text-center" v-if="info">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err" v-if="textError">{{ textError }}</p>
      <div class="container mb-3 pwd-container">
        <div style="width: 65%;">
          <div class="form-group row">
            <label class="offset-sm-3 col-sm-4 col-sm col-form-label">ID</label>
            <div class="col-sm-4 col-sm pr-0 pl-0">
              <input type="text" class="form-control bg-required" v-model="id">
            </div>
            <p class="offset-sm-7 pwd-err" v-if="this.errors.id" >{{ this.errors.id }}</p>
          </div>
          <div class="form-group row">
            <label class="offset-sm-3 col-sm-4 col-sm col-form-label">現在のパスワード</label>
            <div class="col-sm-4 col-sm pr-0 pl-0">
              <input type="password" class="form-control bg-required" v-model="old_password">
            </div>
            <p class="offset-sm-7 pwd-err" v-if="this.errors.old_password" >{{ this.errors.old_password }}</p>
          </div>
          <div class="form-group row">
            <label class="offset-sm-3 col-sm-4 col-sm col-form-label">新しいパスワード</label>
            <div class="col-sm-4 col-sm pr-0 pl-0">
              <input type="password" class="form-control bg-required" v-model="password">
            </div>
            <p class="offset-sm-7 pwd-err" v-if="this.errors.password" >{{ this.errors.password }}</p>
          </div>
          <div class="form-group row">
            <label class="offset-sm-3 col-sm-4 col-sm col-form-label">新しいパスワード確認</label>
            <div class="col-sm-4 col-sm pr-0 pl-0">
              <input type="password" class="form-control bg-required" v-model="password_confirmation">
            </div>
            <p class="offset-sm-7 pwd-err" v-if="this.errors.password_confirmation" >{{ this.errors.password_confirmation }}</p>
          </div>
        </div>
      </div>
      <div class="form-inline offset-sm-5">
        <button class="btn btn-light" type="submit" @click="change_pwd()">パスワード変更</button>
      </div>
    </main>
  </AppLayout>
</template>
