<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info" >{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="isHasError"  class="err">{{ dataNotFoundMsg }}</span>
      </div>
      <div class="container mt-3 mb-3 clearfix">
        <h4 class="text-primary float-left">【抽出条件】</h4>
      </div>
      <div class="container mb-3">
        <form action="" method="">
          <div class="form-group row" style="width:100%;" :class="!jpYearErr || nji_sateiErr ? 'mb-0':''">
            <label class="col-sm col-form-label" style="width:100%; max-width:80px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="tel" class="form-control bg-required" @blur="changedYear()" v-model="jpYear" maxlength="4">
            </div>
            <label class="col-sm col-form-label text-left pr-0 pl-1" style="width:100%; max-width:20px">年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:70px">査定</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="tel" class="form-control bg-required" v-model="nji_satei" maxlength="9" >
            </div>
            <label class="col-sm col-form-label text-left pr-0 pl-1" style="width:100%; max-width:20px">次</label>
          </div>
          <div class="form-group row" style="width:100%;">
          <div class="pl-10">
            <span style="" class="col-sm-2 err" v-show="jpYearErr">{{ jpYearErr }}</span>
          </div>
          <div class="pl-10" :class="!jpYearErr? 'offset-sm-3':''" >
            <span class="col-sm-3  err" :class="!jpYearErr? 'pl-3':'pl-4'" v-show="nji_sateiErr">{{ nji_sateiErr }}</span>
            </div>    
          </div>   
          <div class="form-group row" style="width:100%;">
            <label class="col-sm col-form-label" style="width:100%; max-width:80px">管理者</label>
            <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:350px">
              <select class="form-control bg-required" v-model="kanrisya_cd" >
                <option
                  v-for="kanrisya in kanrisyaList"
                  :key="kanrisya.kanrisya_cd"
                  :value="kanrisya.kanrisya_cd"
                >{{kanrisya.kanrisya_cd}}<span> : </span>{{kanrisya.kanrisya_mei}}</option>
              </select>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:70px">状態</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input radio_btn" type="radio" v-model="status" v-bind:value="0">
              <label class="form-check-label">すべて</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input radio_btn" type="radio" v-model="status" v-bind:value="1">
              <label class="form-check-label">未登録</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input radio_btn" type="radio" v-model="status" v-bind:value="2" checked>
              <label class="form-check-label">本登録</label>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px"></div>
            <label class="col-sm col-form-label" style="width:100%; max-width:100px">表示件数</label>
            <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
              <select class="form-control bg-white" v-model="page_limit">
                <option value="5" selected>5</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div class="offset-sm-1" >
            <span class="col-sm-3 err"  v-show="kanrisyaErrorMsg">{{ kanrisyaErrorMsg }}</span>
          </div>    
          <button type="button" @click="getKotukoSinseiData()" class="btn btn-light d-block mx-auto" style="width:120px;">検索</button>
        </form>
      </div>
      <div class="container pt-3 border-top border-secondary" v-show="showTable">
        <form class="clearfix">  
          <div class="float-left">
            <p class="text-info">修正・削除行を選択して下さい</p>
          </div>
          <div class="form-group float-right">
            <button class="btn btn-light" type="button" style="width:200px;" v-show="authorityAdmin()" @click="updateData()">工事番号・査定次更新</button>
          </div>
        </form>
      </div>
      <div class="container" v-show="showTable">
        <div class="row">
          <div class="col-sm-4">
            <table class="table-sm">
              <tr>
                <td class="align-middle">検索結果</td>
                <td width="130px" class="bg-light text-center table-bordered align-middle">{{kotukoSinseiDataList.total}}</td>
                <td class="text-center align-middle">件</td>
              </tr>
            </table>
          </div>
          <div class="col-sm-4">
            <b-pagination
              v-model="kotukoSinseiDataList.current_page"
              :total-rows="kotukoSinseiDataList.total"
              :data="kotukoSinseiDataList.data"
              :per-page="kotukoSinseiDataList.per_page"
              @change="getKotukoSinseiData"
            ></b-pagination>
          </div>
          <div class="col-sm-4 text-right align-middle">
            <p class="mb-0 mt-2">{{kotukoSinseiDataList.current_page}}/{{kotukoSinseiDataList.last_page}}項</p>
          </div>  
        </div>
      </div>
      <div class="container pt-3" v-show="showTable">
        <form class="clearfix">  
          <div class="float-left">
            <button type="button" class="btn btn-light" style="width:120px;" @click="exportCSV()">CSV出力</button>
          </div>
          <div class="form-group float-right">
            <p class="mb-0">(金額単位：千円)</p>
          </div>
        </form>
      </div>
      <div class="container mt-0 table-responsive" v-show="showTable">
        <form action="" method="">
          <table class="table text-center table-bordered table-sm">
            <thead class="bg-primary text-light">
              <tr>
                <th rowspan="2" class="align-middle" width="4%"></th>
                <th class="align-middle" width="7%">工事番号</th>
                <th class="align-middle" width="7%">査定次</th>
                <th rowspan="2" class="align-middle" width="4.5%">状態</th>
                <th class="align-middle" width="13%">管理者</th>
                <th class="align-middle" width="11%">工種</th>
                <th class="align-middle" width="9%">市町村</th>
                <th colspan="3" class="align-middle">町名・字名／地先</th>
                <th rowspan="2" class="align-middle" width="21%">情報入力</th>
              </tr>
              <tr>
                <th colspan="2" class="align-middle">査定班</th>
                <th colspan="3" class="align-middle">河川・路線／橋梁</th>
                <th class="align-middle" width="8%">被災延長</th>
                <th class="align-middle" width="9%">被災報告額</th>
                <th class="align-middle" width="8%">申請額</th>
              </tr>
            </thead>
            <tbody v-for="(kotukoSinseiData, index) in kotukoSinseiDataList.data" :key="index" :value="index">
              <tr :class="index%2 != 0 ? 'table-secondary':''">
                <td rowspan="2" class="align-middle">
                    <button class="btn btn-light" type="button" @click="goFB101(kotukoSinseiData, index)" style="width:40px;height:100%;font-size:14px;" >選<br>択</button>
                </td>
                <td class="align-middle" align="right"><input type="tel" class="form-control bg-required" v-model="kotukoSinseiData.new_koji_no" @blur="kojinoChange(index)" :readonly="kojiFlg"></td>
                <td class="align-middle" align="right"><input type="tel" class="form-control bg-required" v-model="kotukoSinseiData.new_nji_satei" @blur="njisateiChange(index)" :readonly="kojiFlg"></td>
                <td class="align-middle">
                  <img v-if="kotukoSinseiData.status==2" src="@/assets/hon.jpg" alt="" class="img-fluid mx-auto d-block" style="height:38px;">
                  <img v-if="kotukoSinseiData.status==1" src="@/assets/mi.jpg" alt="" class="img-fluid mx-auto d-block" style="height:38px;">
                </td>
                <td class="align-middle" align="left">{{ kotukoSinseiData.kanrisya_cd }} {{ kotukoSinseiData.kanrisya_mei }}</td>
                <td class="align-middle" align="left">{{ kotukoSinseiData.kousyu_cd }} {{ kotukoSinseiData.kousyu_name }}</td>
                <td class="align-middle" align="left">{{ kotukoSinseiData.shicyou_cd }} {{ kotukoSinseiData.shicyou_name }}</td>
                <td colspan="3" class="align-middle" align="left">{{ kotukoSinseiData.cyoaza_cd }} {{ kotukoSinseiData.cyoaza_name }}／{{ kotukoSinseiData.chisaki }}</td>
                <td rowspan="2" class="align-middle" align="center">
                  <button class="btn btn-success mr-1" @click="goFB102(index)" role="button" style="width:90px" >工事概要</button>
                  <button class="btn btn-success" @click="goFB103(index)" role="button" style="width:90px" >経済効果</button>
                </td>
              </tr>
              <tr :class="index%2 != 0 ? 'table-secondary':''">
                <td colspan="2" class="align-middle" align="right">
                  <div class="form-inline">
                    <select class="form-control bg-required" v-model="kotukoSinseiData.han_cd" style="width:100%;" v-on:change="codeChange($event,index)" :disabled="kojiFlg">
                      <option value=""></option>
                      <option
                        v-for="mcode in mcodeList"
                        :key="mcode.meisai"
                        :value="mcode.meisai"
                      >{{mcode.meisai}}<span> : </span>{{mcode.cd_value}}</option>
                    </select>
                  </div>
                </td>
                <td class="align-middle">
                  <img v-if="kotukoSinseiData.saigai_exist == 1" src="@/assets/satei_on.jpg" alt="" class="img-fluid mx-auto d-block" style="height:38px;">
                  <img v-if="kotukoSinseiData.saigai_exist == 0" src="@/assets/satei_off.jpg" alt="" class="img-fluid mx-auto d-block" style="height:38px;">
                </td>
                <td scope="row" colspan="3" class="text-overflow align-middle" align="left">{{ kotukoSinseiData.rokasen_cd }} {{ kotukoSinseiData.rokasen_mei }}／{{ kotukoSinseiData.rokyouryou_mei }}</td>
                <td class="align-middle" align="right">{{ kotukoSinseiData.hisai_encyou|formatNumeric }}</td>
                <td class="align-middle" align="right">{{ kotukoSinseiData.higai_gaku|formatNumber }}</td>
                <td class="align-middle" align="right">{{ kotukoSinseiData.sinsei_gaku|formatNumber }}</td>
                <td v-if="false" class="align-middle col-sm-12" align="center">
                  <button class="btn btn-success mr-1" @click="goFB104(index)" role="button" style="width:90px" >改良復旧</button>
                  <button class="btn btn-success" @click="goFB105(index)" role="button" style="letter-spacing: -2px;width:90px;">内未･転</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="container clearfix" v-show="showTable">
        <table class="table table-sm table-borderless float-right">
          <tr>
            <td width="49%"></td>
            <th width="10%" class="text-center border align-middle">箇所数</th>
            <td width="13%" class="bg-light text-center border align-middle">{{ kotukoSinseiDataList.total|formatNumber }}</td>
            <th width="13%" class="text-center border align-middle">申請額計</th>
            <td width="15%" class="bg-light text-center border align-middle">{{ totalAmount|formatNumber }}</td>
          </tr>
        </table>
      </div>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import moment from 'moment';
import { APIService } from '@/apiService.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const apiService = new APIService();

var numeral = require("numeral");
export default {
  name: 'FB100',
  components: {
    Layout,Loading
  },
  data(){
    var kanCd = '';
    var kanMei = '';
    var kojiFlg = '';
    if(this.authorityAdmin()) {
      kanCd = '0';
      kanMei = 'すべて';
      kojiFlg = false;
    } else {
      kanCd = localStorage.getItem("Kanrisya_Cd");
      kanMei = localStorage.getItem("Kanrisya_Mei");
      kojiFlg = true;
    }
    return{
      engYear:'',
      yearFlg: true,
      jpYear:'',
      jpYearErr: '',
      nji_sateiErr: '',
      kanrisyaList: [],
      err: '',
      current_page: 1,
      kanrisya1Check: false,
      kanrisya_cd: kanCd,
      kanrisya_mei: kanMei,
      kojiFlg: kojiFlg,
      nji_satei:'',
      status:'',
      choose_data: [],
      changes: [],
      index: 0,
      kotukoSinseiDataList:{
        total: 10000
      },
      checkedKotukoSinseiData:[],
      kotukosinseiselectedIndex:0,
      totalAmount: 0,
      mcodeList: [],
      showTable:false,
      isHasError: false,
      dataNotFoundMsg: null,
      page_note: [],
      info: '',
      page_limit: 5,
      updateFlg: false,
      searchFlg: false,
      isLoading: false,
      fullPage: true,
      chk_date: "",
      kanrisyaErrorMsg: '',
    }
  },
  computed: {
    changesSV() {
      return this.$store.state.fb100.changes;
    },
    previousItemsSV() {
      return this.$store.state.fb100.previousItems;
    },
    yearSV() {
      return this.$store.state.fb100.year;
    },
    nji_sateiSV() {
      return this.$store.state.fb100.nji_satei;
    },
    kanrisya_cdSV() {
      return this.$store.state.fb100.kanrisya_cd;
    },
    statusSV() {
      return this.$store.state.fb100.status;
    },
    page_limitSV() {
      return this.$store.state.fb100.page_limit;
    },
    pageSV() {
      return this.$store.state.fb100.page;
    },
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    if(this.yearSV) {
        this.changes = this.changesSV,
        this.kotukoSinseiDataList.data = this.previousItemsSV,
        this.engYear = this.yearSV,
        this.nji_satei = this.nji_sateiSV,
        this.kanrisya_cd = this.kanrisya_cdSV,
        this.status = this.statusSV,
        this.page_limit = this.page_limitSV,
        this.page = this.pageSV,
        this.getGengo(this.engYear);
        this.getKanrisya();
        //setTimeout(() => {
          this.getKotukoSinseiData(this.pageSV);
        //},1800)
    } else {
        this.engYear = new Date().getFullYear();
        this.getGengo(new Date().getFullYear());
        this.getKanrisya(new Date().getFullYear());
        this.status = 0;
    }
  },
  filters: {
    formatNumber: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0") == 0
        ? ""
        : numeral(number).format("0,0");
    },
    formatNumeric: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
  },
  methods: {
    getGengo(year){	
      this.info = '';	
      this.isHasError = false;	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format('YYYYMMDD');	
      var request_data = {	
        date: param,	
      };	
      apiService.getGengo(request_data)	
      .then(result=>{	
        this.jpYear = result;	
      });	
    },
    changedYear(){
      if(this.jpYear != '' && /^[a-zA-Z]+$/.test(this.jpYear.substr(0,1))) {
        var matches = this.jpYear.match(/[a-zA-Z]+/g);
        var yvalue = this.jpYear.replace ( /[^\d.]/g, '' );
        var request_data = {	
          gengo_cd: matches ? matches.toString() : "",	
        }
        apiService.getGengoStartDate(request_data)	
        .then(result=>{
          if(result.length > 0) {
            this.yearFlg = true;
            var startDate = result[0].start_date;	
            this.engYear = parseInt(startDate.substr(0,4)) + (parseInt(yvalue) - 1);	
          } else {
            this.yearFlg = false;
            this.engYear = this.jpYear;
          }
        });
      } else {
        if(!this.jpYear) {
          this.engYear = null;
        } else {
          this.engYear = this.jpYear;
          this.year = false;
        }
      }
    },
    getKanrisya(){
      if(localStorage.getItem('Kanrisya_Cd') == 1) {
        this.kanrisya1Check = true;
      }
      var request_data = {
        kengen_cd: localStorage.getItem('kengen_cd'),
        kanrisya_cd: localStorage.getItem('Kanrisya_Cd'),
      }
      apiService.getKanrisyaByFlg(request_data)
      .then(result => {
        this.kanrisyaList = result;
        this.isLoading = false;
      })
      .catch(error => {
        this.err = error;
      })
      
    },
    kanrisyaChange(kanrisya){
      var kanrisya_index = kanrisya.target.options[kanrisya.target.options.selectedIndex].text;
      for(var i = 0; i < this.kanrisyaList.length; i++){
            if(this.kanrisyaList[i].kanrisya_cd == kanrisya_index){
              this.kanrisya_cd = kanrisya_index;
              this.kanrisya_mei = this.kanrisyaList[i].kanrisya_mei;
            }
        }
    },
    getKotukoSinseiData(page){
      this.isHasError = false;
      this.isLoading = true;
      this.getMcodeList();
      this.info = '';
      var errFlg = false;
      if(!this.yearSV){
        if(!this.jpYear) {
          confirm(this.$messages.msg['0013E']);
          this.jpYearErr = this.$messages.msg['0004W'];
          this.showTable = false;
          this.isHasError = false;
          this.isLoading = false;
          errFlg = true;
        } else if (!this.yearFlg)
        {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = '災害年' + this.$messages.msg["0002W"];
          this.showTable = false;
          this.isHasError = true;
          this.isLoading = false;
          errFlg = true;
        } else {
          this.jpYearErr = null;
        }
      }
      if(!this.nji_satei) {
        confirm(this.$messages.msg['0013E']);
        this.nji_sateiErr = "査定" + this.$messages.msg['00013W'];
        this.showTable = false;
        this.isHasError = false;
        this.isLoading = false;
        errFlg = true;
      } else {
        if (!this.isInteger(this.nji_satei)) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = "査定" + this.$messages.msg["0002W"];
          this.isHasError = true;
          this.showTable = false;
          this.isLoading = false;
          this.nji_sateiErr = null;
          errFlg = true;
        } else {
          this.nji_sateiErr = null;
        }
      }
      if(this.kanrisya_cd == null || this.kanrisya_cd === ""){
        confirm(this.$messages.msg['0013E']);
        this.kanrisyaErrorMsg = "管理者" + this.$messages.msg['00013W'];
        this.showTable = false;
        this.isHasError = false;
        this.isLoading = false;
        errFlg = true;
      }
      this.searchFlg = false;
      if(!errFlg) {
        if (!page) {
          this.searchFlg = true;
          page = 1;
        }
      this.totalAmount = 0;
      this.page = page;
      var request_data = {
          changes: this.changes,
          previousItems: this.kotukoSinseiDataList.data,
          year: this.engYear,
          nji_satei: this.nji_satei,
          kanrisya_cd: this.kanrisya_cd,
          status: this.status,
          page_limit: this.page_limit,
          page:page,
          searchFlg: this.searchFlg
        };
      apiService.fb100getKotukoSinseiData(request_data)
        .then(result => {
          if(this.updateFlg) {
            this.changes = [];
            this.updateFlg = false;
          } else {
            this.changes = result.result[0];
          }
          this.kotukoSinseiDataList = result.result[1];
          if(this.kotukoSinseiDataList.data.length > 0){
            this.clearMsg();
            this.showTable = true;
          }else{
            this.showTable = false;
            this.isHasError = true;
            confirm(this.$messages.msg['0013E']);
            this.dataNotFoundMsg = this.$messages.msg['0012E'];
          }
          this.checkedKotukoSinseiData = this.kotukoSinseiDataList.data[0];
          if(this.checkedKotukoSinseiData)
          {
            this.totalAmount = this.checkedKotukoSinseiData.total_sinsei_gaku;
          }
          this.kotukoSinseiDataList.current_page = page;
          this.chk_date = result.chk_date; // 排他チェック用

          setTimeout(() => {
            this.isLoading = false;
          },500)
          })
          .catch(error => {
            this.isLoading = false;
            this.error = error;
            this.isHasError = true;
        })
      }
    },
    getMcodeList() {
      apiService.fb100getMcodeList()
      .then(result => {
        this.mcodeList = result;
      })
      .catch(error => {
        confirm(this.$messages.msg['0013E']);
        this.dataNotFoundMsg = error;
        this.isHasError = true;
      })
    },
    codeChange(mcode,index){
      var mcode_index = mcode.target.options[mcode.target.options.selectedIndex].text;
      for(var i = 0; i < this.mcodeList.length; i++){
        if(this.mcodeList[i].meisai == mcode_index){
          this.kotukoSinseiDataList.data[index].han_name = this.mcodeList[i].cd_value;
          this.kotukoSinseiDataList.data[index].han_cd = mcode_index;
        }
      }
    },
    kojinoChange(index) {
      if (this.isInteger(this.kotukoSinseiDataList.data[index].new_koji_no)) {
        this.kotukoSinseiDataList.data[index].new_koji_no = this.kotukoSinseiDataList.data[index].new_koji_no
      }
    },
    njisateiChange(index) {
      this.kotukoSinseiDataList.data[index].nji_satei = this.removeComma(this.kotukoSinseiDataList.data[index].nji_satei);
      if (this.isFloat(this.kotukoSinseiDataList.data[index].nji_satei)) {
        this.kotukoSinseiDataList.data[index].nji_satei = this.kotukoSinseiDataList.data[index].nji_satei
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    setCheckedKotukoSinseiData(kotukoSinseiData, index){
      this.checkedKotukoSinseiData = kotukoSinseiData;
      this.index = index;
    },
    goFB101(kotukoSinseiData, index) {
      this.setCheckedKotukoSinseiData(kotukoSinseiData, index);
      this.setState();
      this.$router.push({
        name: 'FB101',
        query: {
          saigai_yyyy: this.engYear,
          ikkan_no: this.checkedKotukoSinseiData.ikkan_no
        }
      });
    },
    updateData() {

      var kojiNoList = [];

      this.info = '';
      this.isHasError = false;
      this.dataNotFoundMsg = '';
      for(var i = 0; i < this.kotukoSinseiDataList.data.length; i++) {
        if(!this.kotukoSinseiDataList.data[i].new_koji_no) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = "行" + (i + 1) + "の工事番号" + this.$messages.msg["00013W"];
          this.isHasError = true;
          break;
        }
        if(!this.kotukoSinseiDataList.data[i].nji_satei) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = "行" + (i + 1) + "の査定次" + this.$messages.msg["00013W"];
          this.isHasError = true;
          break;
        }

        // 入力工事Noの重複チェック
        if (kojiNoList.indexOf(this.kotukoSinseiDataList.data[i].new_koji_no) !== -1) {
          confirm(this.$messages.msg['0013E']);
          this.dataNotFoundMsg = this.$messages.msg["0044W"];
          this.isHasError = true;
          break;
        }

        kojiNoList.push(this.kotukoSinseiDataList.data[i].new_koji_no);
      }
    if(!this.isHasError) {
        var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: this.checkedKotukoSinseiData.ikkan_no,
        data: this.changes,
        currentItems: this.kotukoSinseiDataList.data,
        chk_date: this.chk_date
      }
      apiService.fb100update(request_data)
      .then(result => {
        this.changes = [];
        this.updateFlg = true;
        this.getKotukoSinseiData(this.kotukoSinseiDataList.current_page);
        this.info = result;
      }).catch(error => {
        this.changes = [];
        confirm(this.$messages.msg['0013E']);
        this.dataNotFoundMsg = error;
        this.isHasError = true;
      });
    }
  },
    goFB102(index) {
      this.setState();
      this.$router.push({
        name: 'FB102',
        query: {
          saigai_yyyy: this.engYear,
          ikkan_no: this.kotukoSinseiDataList.data[index].ikkan_no
        }
      });
    },
    goFB103(index) {
      this.setState();
      this.$router.push({
        name: 'FB103',
        query: {
          saigai_yyyy: this.engYear,
          ikkan_no: this.kotukoSinseiDataList.data[index].ikkan_no
        }
      });
    },
    goFB104(index) {
      this.setState();
      this.$router.push({
        name: 'FB104',
        query: {
          saigai_yyyy: this.engYear,
          ikkan_no: this.kotukoSinseiDataList.data[index].ikkan_no
        }
      });
    },
    goFB105(index) {
      this.setState();
      this.$router.push({
        name: 'FB105',
        query: {
          saigai_yyyy: this.engYear,
          ikkan_no: this.kotukoSinseiDataList.data[index].ikkan_no
        }
      });
    },
    exportCSV() {
      var request_data = {
        saigai_yyyy: this.engYear,
        nji_satei: this.nji_satei,
        kanrisya_cd: this.kanrisya_cd,
        status: this.status,
      }
      apiService.fb100exportCSV(request_data)
      .then(result => {
        var date = moment(String(new Date())).format('YYYYMMDD');
        this.downloadCSV(result, this.$constants.FB100CSVFN + date + '.csv');
      }).catch(error => {
        console.log(error);
      })
    },
    downloadCSV(result, fileName) {
      if (window.navigator.msSaveBlob) {
        // IEとEdge
        window.navigator.msSaveBlob(new Blob([result]), fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    formatNumber: function(number){
     if(number == 0) {
          return '';
      }
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? '': result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    clearMsg(){
      this.isHasError = false;
      this.dataNotFoundMsg = null,
      this.jpYearErr = null,
      this.nji_sateiErr = null,
      this.kanrisyaErrorMsg = null
    },
    setState() {
      this.$store.commit("fb100/setChanges", this.changes);
      this.$store.commit("fb100/setPreviousItems", this.kotukoSinseiDataList.data);
      this.$store.commit("fb100/setYear", this.engYear);
      this.$store.commit("fb100/setNjiSatei", this.nji_satei);
      this.$store.commit("fb100/setKanrisyaCd", this.kanrisya_cd);
      this.$store.commit("fb100/setStatus", this.status);
      this.$store.commit("fb100/setPageLimit", this.page_limit);
      this.$store.commit("fb100/setPage", this.page);
    },
    authorityAdmin(){
      var kengen_cd = localStorage.getItem('kengen_cd');
      if (kengen_cd == this.$constants.KENGEN_CD.ADMIN) {
        return true;
      } else {
        return false;
      }
    }
   }
};
</script>