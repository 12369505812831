<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span class="col-md-12 text-center" v-show="isHasInfo">{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasErr" class="err">{{ this.errMsg }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <div class="form-group row" style="width:100%; max-width:500px">
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="jpYear" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >年</label>

              <label
                for
                class="col-sm col-form-label ml-3 text-right"
                style="width:100%; max-width:65px"
              >査定</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
                <input type="text" class="form-control bg-light" v-model="nji_satei" readonly />
              </div>
              <label
                for
                class="col-sm col-form-label text-left pr-0 pl-1"
                style="width:100%; max-width:20px"
              >次</label>
            </div>

            <div class="form-group row" style>
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">工事番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="koji_no" readonly />
              </div>

              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:100px"
              >管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_mei" readonly />
              </div>

              <label
                for
                class="col-sm col-form-label text-left"
                style="width:100%; max-width:100px"
              >事業主体</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="cd_value" readonly />
              </div>

              <label
                for
                class="col-sm col-form-label text-right"
                style="width:100%; max-width:100px"
              >整理番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
                <input type="text" class="form-control bg-light" v-model="saigai_seirinao" readonly />
              </div>
            </div>

            <div class="form-group row mb-0" style="width:100%; ">
              <label for class="col-sm col-form-label" style="width:100%; max-width:120px">気象コード</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:410px">
                <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly />
              </div>

              <label
                for
                class="col-sm col-form-label text-left"
                style="width:100%; max-width:100px"
              >被災日</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="hisaibi" readonly />
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--container-fluid-->

      <form>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              @click="insertData()"
              :disabled="insertMode ? false: true"
              style="width:120px;"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              @click="updateData()"
              :disabled="updateMode ? false: true"
              style="width:120px;"
            >更新</button>
            <button
              class="btn btn-light ml-3"
              style="width:120px;"
              role="button"
              @click="back_btn()"
            >戻る</button>
          </div>
        </div>

        <div class="container">
          <div class="form-group row mt-3 mb-2" style="width:100%; ">
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">上部</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select
                class="form-control"
                v-model="saigaiKasenData.jyoubu_cd"
                v-on:change="jyoubuCodeChange($event)"
              >
                <option value=""></option>
                <option
                  v-for="mcode in mCode36"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">下部</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select
                class="form-control"
                v-model="saigaiKasenData.kabu_cd"
                v-on:change="kabuCodeChange($event)"
              >
                <option value=""></option>
                <option
                  v-for="mcode in mCode37"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="container pt-3 clearfix mt-3 border-top border-secondary">
          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">河川</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:180px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">海岸</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:180px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">砂防</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:180px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">急傾斜</p>
          </div>
        </div>

        <div class="container mt-3 pt-1">
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">河川改修</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control"
                v-model="saigaiKasenData.kasen_kaisyu"
                :disabled="kousyu_cd != 1  && kousyu_cd != null"
                v-on:change="kasenKaisyuChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 1 || kousyu_cd == null"
                  v-for="mcode in mCode7"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">海岸保全</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.kaigan_hozen"
                maxlenght="9"
                :disabled="kousyu_cd != 2  && kousyu_cd != null"
                v-on:change="kaiganHozenChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 2 || kousyu_cd == null"
                  v-for="mcode in mCode6"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">砂 防</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.sabou"
                maxlenght="9"
                :disabled="kousyu_cd != 3  && kousyu_cd != null"
                v-on:change="sabouChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 3 || kousyu_cd == null"
                  v-for="mcode in mCode22"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">区域番号</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.jisuberi_no"
                maxlenght="9"
                :disabled="kousyu_cd != 4 && kousyu_cd != 5 && kousyu_cd != null"
                @blur="jisuberi_noChange()"
                @focus="focusjisuberi_no()"
              />
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">河なり</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.kawanari"
                maxlenght="9"
                :disabled="kousyu_cd != 1 && kousyu_cd != null"
                v-on:change="kawanariChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 1 || kousyu_cd == null"
                  v-for="mcode in mCode22"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">現天端高</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.tenzui_daka"
                :maxlength="max"
                :disabled="kousyu_cd != 2 && kousyu_cd != null"
                @blur="tenzui_dakaChange()"
                @focus="focustenzui_daka()"
                @input="inputtenzui_daka()"
              />
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">高 さ</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.damu_takasa"
                :maxlength="max"
                :disabled="kousyu_cd != 3 && kousyu_cd != null"
                @blur="damu_takasaChange()"
                @focus="focusdamu_takasa()"
                @input="inputdamu_takasa()"
              />
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px"></div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復旧天端</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.fukyu_tenzui"
                maxlength="9"
                :disabled="kousyu_cd != 2 && kousyu_cd != null"
                @blur="fukyu_tenzuiChange()"
                @focus="focusfukyu_tenzui()"
              />
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">延 長</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.damu_encyou"
                :maxlength="max"
                :disabled="kousyu_cd != 3 && kousyu_cd != null"
                @blur="damu_encyouChange()"
                @focus="focusdamu_encyou()"
                @input="inputdamu_encyou()"
              />
            </div>
          </div>
        </div>

        <div class="container pt-3 clearfix mt-3 border-top border-secondary">
          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">道路</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:180px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">橋梁</p>
          </div>

          <div
            class="col-sm pr-0 pl-0 p-2 text-center float-left"
            style="width:100%; max-width:180px"
          ></div>

          <div
            class="rounded p-2 text-center float-left"
            style="width:130px; background-color:yellowgreen"
          >
            <p class="m-0 p-0 text-white font-weight-bold">下水道</p>
          </div>
        </div>

        <div class="container mt-3 pt-1">
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">路 面</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.douro_romen"
                maxlength="9"
                :disabled="kousyu_cd != 6 && kousyu_cd != null"
                v-on:change="douroRomenChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 6 || kousyu_cd == null"
                  v-for="mcode in mCode8"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">原 橋</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.rokyouryou_harahasi"
                maxlength="9"
                :disabled="kousyu_cd != 7 && kousyu_cd != null"
                v-on:change="rokyouryouHarahasiChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 7 || kousyu_cd == null"
                  v-for="mcode in mCode9"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">種 類</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.gesuidou_syurui"
                maxlength="9"
                :disabled="kousyu_cd != 8 && kousyu_cd != null"
                v-on:change="gesuidouSyuruiChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 8 || kousyu_cd == null"
                  v-for="mcode in mCode10"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">厚 さ</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.atusa"
                :maxlength="max"
                :disabled="kousyu_cd != 6 && kousyu_cd != null"
                @blur="atusaChange()"
                @focus="focusatusa()"
                @input="inputatusa()"
              />
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">原橋延長</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.harahasi_encyou"
                :maxlength="max"
                :disabled="kousyu_cd != 7 && kousyu_cd != null"
                @blur="harahasi_encyouChange()"
                @focus="focusharahasi_encyou()"
                @input="inputharahasi_encyou()"
              />
            </div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">排 除</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.haijyo_housiki"
                maxlength="9"
                :disabled="kousyu_cd != 8 && kousyu_cd != null"
                v-on:change="haijyoHousikiChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 8 || kousyu_cd == null"
                  v-for="mcode in mCode11"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">現 員</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.gendou_habain"
                :maxlength="max"
                :disabled="kousyu_cd != 6 && kousyu_cd != null"
                @blur="gendou_habainChange()"
                @focus="focusgendou_habain()"
                @input="inputhgendou_habain()"
              />
            </div>
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">被災延長</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.harahasi_higaiencyou"
                :maxlength="max"
                :disabled="kousyu_cd != 7 && kousyu_cd != null"
                @blur="harahasi_higaiencyouChange()"
                @focus="focusharahasi_higaiencyou()"
                @input="inputharahasi_higaiencyou()"
              />
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復 員</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.douro_habain"
                :maxlength="max"
                :disabled="kousyu_cd != 6 && kousyu_cd != null"
                @blur="douro_habainChange()"
                @focus="focusdouro_habain()"
                @input="inputdouro_habain()"
              />
            </div>
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">原橋幅員</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.harahasi_habain"
                :maxlength="max"
                :disabled="kousyu_cd != 7 && kousyu_cd != null"
                @blur="harahasi_habainChange()"
                @focus="focusharahasi_habain()"
                @input="inputharahasi_habain()"
              />
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px"></label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px"></div>

            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復 旧 端</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <select
                class="form-control text-right"
                v-model="saigaiKasenData.fukyu_hasi"
                maxlength="9"
                :disabled="kousyu_cd != 7 && kousyu_cd != null"
                v-on:change="fukyuHasiChange($event)"
              >
                <option value=""></option>
                <option
                  v-show="kousyu_cd == 7 || kousyu_cd == null"
                  v-for="mcode in mCode9"
                  :key="mcode.meisai"
                  :value="mcode.meisai"
                >{{mcode.meisai}} : {{mcode.cd_value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" style="width:100%;">
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:100px"></div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px"></div>
            <label for class="col-sm col-form-label" style="width:100%; max-width:100px">復旧幅員</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:210px">
              <input
                type="tel"
                class="form-control text-right"
                v-model="saigaiKasenData.rokyouryou_habain"
                :maxlength="max"
                :disabled="kousyu_cd != 7 && kousyu_cd != null"
                @blur="rokyouryou_habainChange()"
                @focus="focusrokyouryou_habain()"
                @input="inputrokyouryou_habain()"
              />
            </div>
          </div>
        </div>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              @click="deleteData()"
              :disabled="updateMode ? false: true"
              style="width:120px;"
            >削除</button>
          </div>
        </div>
      </form>
    </main>
  </Layout>
</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from "moment";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
var numeral = require("numeral");
const apiService = new APIService();
export default {
  components: {
    Layout,Loading
  },
  data() {
    if (this.$route.query) {
      return {
        max: "10",
        jpYear: "",
        engYear: this.$route.query.saigai_yyyy,
        ikkan_no: this.$route.query.ikkan_no,
        nji_satei: "",
        koji_no: "",
        hisaibi: "",
        saigai_seirinao: "",
        kanrisya_mei: "",
        cd_value: "",
        saigai_mei: "",
        mCode36: "",
        mCode37: "",
        mCode7: "",
        mCode22: "",
        mCode6: "",
        mCode8: "",
        mCode9: "",
        mCode10: "",
        mCode11: "",

        isHasInfo: false,
        info: "",
        isHasErr: false,
        errMsg: "",
        updateMode: false,
        insertMode: false,
        kousyu_cd: "",
        saigaiKasenData: {},
        loginUser: {
          id: window.localStorage.getItem("LOGIN_ID"),
          kanrisya_cd: window.localStorage.getItem("Kanrisya_Cd"),
          kanrisya_name: window.localStorage.getItem("Kanrisya_Mei")
        },
        post: null,
        isLoading: false,
        fullPage: true,
        chk_date: ""
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getSaigaiKasen();
    this.getKotukoSinsei();
    this.getGengo(this.engYear);
    this.getMasterCheckLists();
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    getKotukoSinsei() {
      var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: this.ikkan_no
      };
      apiService
        .getKotukoSinsei(request_data)
        .then(result => {
          this.nji_satei = result.nji_satei;
          this.koji_no = result.koji_no;
          this.kousyu_cd = result.kousyu_cd;
          this.hisaibi = this.formatDate(result.hisaibi);
          this.saigai_seirinao = result.saigai_seirinao;
          this.kanrisya_mei = result.kanrisya_mei;
          this.cd_value = result.cd_value;
          this.saigai_mei = result.saigai_mei;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getSaigaiKasen() {
      var saigai_kasen = {
        jyoubu_cd: "",
        jyoubu_name: "",
        kabu_cd: "",
        kabu_name: "",
        kasen_kaisyu: "",
        kasen_kaisyu_name: "",
        kawanari: "",
        kawanari_name: "",
        kaigan_hozen: "",
        kaigan_hozen_name: "",
        tenzui_daka: "",
        fukyu_tenzui: "",
        sabou: "",
        sabou_name: "",
        damu_takasa: "",
        damu_encyou: "",
        jisuberi_no: "",
        douro_romen: "",
        douro_romen_name: "",
        atusa: "",
        gendou_habain: "",
        douro_habain: "",
        rokyouryou_harahasi: "",
        rokyouryou_harahasi_name: "",
        harahasi_encyou: "",
        harahasi_higaiencyou: "",
        harahasi_habain: "",
        fukyu_hasi: "",
        fukyu_hasi_name: "",
        rokyouryou_habain: "",
        gesuidou_syurui: "",
        gesuidou_syurui_name: "",
        haijyo_housiki: "",
        haijyo_housiki_name: ""
      };
      var request_data = {
        ikkan_no: this.ikkan_no,
        saigai_yyyy: this.engYear
      };
      apiService.fb104getSaigaiKasenData(request_data).then(result => {
        this.saigaiKasenData = result.saigaiKasenData[0];
        if (result.saigaiKasenData.length > 0) {
          this.updateMode = true;
          this.insertMode = false;
          this.saigaiKasenData.tenzui_daka = this.formatDecimal(
            this.saigaiKasenData.tenzui_daka
          );
          this.saigaiKasenData.fukyu_tenzui = this.formatDecimal(
            this.saigaiKasenData.fukyu_tenzui
          );
          this.saigaiKasenData.damu_takasa = this.formatDecimal(
            this.saigaiKasenData.damu_takasa
          );
          this.saigaiKasenData.damu_encyou = this.formatDecimal(
            this.saigaiKasenData.damu_encyou
          );
          this.saigaiKasenData.atusa = this.formatDecimal(
            this.saigaiKasenData.atusa
          );
          this.saigaiKasenData.gendou_habain = this.formatDecimal(
            this.saigaiKasenData.gendou_habain
          );
          this.saigaiKasenData.douro_habain = this.formatDecimal(
            this.saigaiKasenData.douro_habain
          );
          this.saigaiKasenData.harahasi_encyou = this.formatDecimal(
            this.saigaiKasenData.harahasi_encyou
          );
          this.saigaiKasenData.harahasi_higaiencyou = this.formatDecimal(
            this.saigaiKasenData.harahasi_higaiencyou
          );
          this.saigaiKasenData.harahasi_habain = this.formatDecimal(
            this.saigaiKasenData.harahasi_habain
          );
          this.saigaiKasenData.rokyouryou_habain = this.formatDecimal(
            this.saigaiKasenData.rokyouryou_habain
          );
        } else {
          this.saigaiKasenData = saigai_kasen;
          this.insertMode = true;
          this.updateMode = false;
        }

        this.chk_date = result.chk_date; // 排他チェック用

        setTimeout(() => {
          this.isLoading = false;
        },500)
      });
    },
    insertData() {
      this.removeComma();
      this.isLoading = true;
      var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: this.ikkan_no,
        koji_no: this.koji_no,
        kanrisya_cd: this.kanrisya_cd,
        saigaiKasenData: this.saigaiKasenData,
        loginId: this.loginUser.id
      };
      apiService
        .fb104insertData(request_data)
        .then(result => {
          this.isHasInfo = true;
          this.isHasErr = false;
          this.info = result;
          this.getSaigaiKasen();
          setTimeout(() => {
            this.isLoading = false;
          },500)
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.isHasInfo = false;
          this.isHasErr = true;
          this.errMsg = error;
          this.isLoading = false;
        });
    },
    updateData() {
      this.removeComma();
      this.isLoading = true;
      var request_data = {
        saigai_yyyy: this.engYear,
        ikkan_no: this.ikkan_no,
        koji_no: this.koji_no,
        saigaiKasenData: this.saigaiKasenData,
        loginId: this.loginUser.id,
        chk_date: this.chk_date
      };
      apiService
        .fb104updateData(request_data)
        .then(result => {
          this.isHasInfo = true;
          this.isHasErr = false;
          this.info = result;
          this.getSaigaiKasen();
          setTimeout(() => {
            this.isLoading = false;
          },500)
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.isHasInfo = false;
          this.isHasErr = true;
          this.errMsg = error;
          this.isLoading = false;
        });
    },
    deleteData() {
      this.isLoading = true;
      var request_data = {
        saigai_yyyy: this.engYear,
        koji_no: this.koji_no,
        ikkan_no: this.ikkan_no,
        loginId: this.loginUser.id,
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fb104deleteData(request_data)
          .then(result => {
            this.isHasInfo = true;
            this.isHasErr = false;
            this.info = result;
            this.getSaigaiKasen();
          })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.isHasInfo = false;
            this.isHasErr = true;
            this.errMsg = error;
            this.isLoading = false;
          });
      }
      setTimeout(() => {
        this.isLoading = false;
      },500)
    },
    getMasterCheckLists() {
      apiService.fc103getMasterCheckLists().then(result => {
        this.mCode36 = result.mCode36;
        this.mCode37 = result.mCode37;
        this.mCode27 = result.mCode27;
        this.mCode7 = result.mCode7;
        this.mCode22 = result.mCode22;
        this.mCode6 = result.mCode6;
        this.mCode8 = result.mCode8;
        this.mCode9 = result.mCode9;
        this.mCode10 = result.mCode10;
        this.mCode11 = result.mCode11;
      });
    },
    removeComma() {
      this.saigaiKasenData.tenzui_daka =
        this.saigaiKasenData.tenzui_daka != null
          ? this.saigaiKasenData.tenzui_daka
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.fukyu_tenzui =
        this.saigaiKasenData.fukyu_tenzui != null
          ? this.saigaiKasenData.fukyu_tenzui
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.damu_takasa =
        this.saigaiKasenData.damu_takasa != null
          ? this.saigaiKasenData.damu_takasa
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.damu_encyou =
        this.saigaiKasenData.damu_encyou != null
          ? this.saigaiKasenData.damu_encyou
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.atusa =
        this.saigaiKasenData.atusa != null
          ? this.saigaiKasenData.atusa
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.gendou_habain =
        this.saigaiKasenData.gendou_habain != null
          ? this.saigaiKasenData.gendou_habain
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.douro_habain =
        this.saigaiKasenData.douro_habain != null
          ? this.saigaiKasenData.douro_habain
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.harahasi_encyou =
        this.saigaiKasenData.harahasi_encyou != null
          ? this.saigaiKasenData.harahasi_encyou
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.harahasi_higaiencyou =
        this.saigaiKasenData.harahasi_higaiencyou != null
          ? this.saigaiKasenData.harahasi_higaiencyou
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.harahasi_habain =
        this.saigaiKasenData.harahasi_habain != null
          ? this.saigaiKasenData.harahasi_habain
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
      this.saigaiKasenData.rokyouryou_habain =
        this.saigaiKasenData.rokyouryou_habain != null
          ? this.saigaiKasenData.rokyouryou_habain
              .toString()
              .trim()
              .replace(/,/g, "")
          : "";
    },
    jyoubuCodeChange(index) {
      var jyoubu_cd =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode36.length; i++) {
        if (jyoubu_cd == this.mCode36[i].display_no) {
          this.saigaiKasenData.jyoubu_cd = jyoubu_cd;
          this.saigaiKasenData.jyoubu_name = this.mCode36[i].cd_value;
        }
      }
    },
    kabuCodeChange(index) {
      var kabu_cd =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode37.length; i++) {
        if (kabu_cd == this.mCode37[i].meisai) {
          this.saigaiKasenData.kabu_cd = kabu_cd;
          this.saigaiKasenData.kabu_name = this.mCode37[i].cd_value;
        }
      }
    },
    kasenKaisyuChange(index) {
      var kasen_kaisyu =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode7.length; i++) {
        if (kasen_kaisyu == this.mCode7[i].display_no) {
          this.saigaiKasenData.kasen_kaisyu = kasen_kaisyu;
          this.saigaiKasenData.kasen_kaisyu_name = this.mCode7[i].cd_value;
        }
      }
    },
    kaiganHozenChange(index) {
      var kaigan_hozen =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode6.length; i++) {
        if (kaigan_hozen == this.mCode6[i].display_no) {
          this.saigaiKasenData.kaigan_hozen = kaigan_hozen;
          this.saigaiKasenData.kaigan_hozen_name = this.mCode6[i].cd_value;
        }
      }
    },
    sabouChange(index) {
      var sabou = index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode22.length; i++) {
        if (sabou == this.mCode22[i].display_no) {
          this.saigaiKasenData.sabou = sabou;
          this.saigaiKasenData.sabou_name = this.mCode22[i].cd_value;
        }
      }
    },
    kawanariChange(index) {
      var kawanari =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode22.length; i++) {
        if (kawanari == this.mCode22[i].display_no) {
          this.saigaiKasenData.kawanari = kawanari;
          this.saigaiKasenData.kawanari_name = this.mCode22[i].cd_value;
        }
      }
    },
    douroRomenChange(index) {
      var douro_romen =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode8.length; i++) {
        if (douro_romen == this.mCode8[i].display_no) {
          this.saigaiKasenData.douro_romen = douro_romen;
          this.saigaiKasenData.douro_romen_name = this.mCode8[i].cd_value;
        }
      }
    },
    rokyouryouHarahasiChange(index) {
      var rokyouryou_harahasi =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode9.length; i++) {
        if (rokyouryou_harahasi == this.mCode9[i].display_no) {
          this.saigaiKasenData.rokyouryou_harahasi = rokyouryou_harahasi;
          this.saigaiKasenData.rokyouryou_harahasi_name = this.mCode9[
            i
          ].cd_value;
        }
      }
    },
    gesuidouSyuruiChange(index) {
      var gesuidou_syurui =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode10.length; i++) {
        if (gesuidou_syurui == this.mCode10[i].display_no) {
          this.saigaiKasenData.gesuidou_syurui = gesuidou_syurui;
          this.saigaiKasenData.gesuidou_syurui_name = this.mCode10[i].cd_value;
        }
      }
    },
    haijyoHousikiChange(index) {
      var haijyo_housiki =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode11.length; i++) {
        if (haijyo_housiki == this.mCode11[i].display_no) {
          this.saigaiKasenData.haijyo_housiki = haijyo_housiki;
          this.saigaiKasenData.haijyo_housiki_name = this.mCode11[i].cd_value;
        }
      }
    },
    fukyuHasiChange(index) {
      var fukyu_hasi =
        index.target.options[index.target.options.selectedIndex].text;
      for (var i = 0; i < this.mCode9.length; i++) {
        if (fukyu_hasi == this.mCode9[i].display_no) {
          this.saigaiKasenData.fukyu_hasi = fukyu_hasi;
          this.saigaiKasenData.fukyu_hasi_name = this.mCode9[i].cd_value;
        }
      }
    },
    focustenzui_daka() {
      if (
        (this.saigaiKasenData.tenzui_daka != null && this.kousyu_cd == 2) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.tenzui_daka = this.saigaiKasenData.tenzui_daka
          .trim()
          .replace(/,/g, "");
      }
    },
    inputtenzui_daka() {
      this.max = this.saigaiKasenData.tenzui_daka.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.tenzui_daka.length > 9 &&
        this.saigaiKasenData.tenzui_daka.charAt(9) != "."
      ) {
        this.saigaiKasenData.tenzui_daka = this.saigaiKasenData.tenzui_daka.substring(
          0,
          9
        );
      }
    },
    tenzui_dakaChange() {
      this.saigaiKasenData.tenzui_daka = this.removeCommaVal(this.saigaiKasenData.tenzui_daka);
      if (this.isFloat(this.saigaiKasenData.tenzui_daka)) {
        this.saigaiKasenData.tenzui_daka = this.saigaiKasenData.tenzui_daka
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusjisuberi_no() {
      if (
        (this.saigaiKasenData.jisuberi_no != null && this.kousyu_cd == 4) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.jisuberi_no = this.saigaiKasenData.jisuberi_no
          .trim()
          .replace(/,/g, "");
      }
    },
    focusdamu_takasa() {
      if (
        (this.saigaiKasenData.damu_takasa != null && this.kousyu_cd == 3) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.damu_takasa = this.saigaiKasenData.damu_takasa
          .trim()
          .replace(/,/g, "");
      }
    },
    inputdamu_takasa() {
      this.max = this.saigaiKasenData.damu_takasa.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.damu_takasa.length > 9 &&
        this.saigaiKasenData.damu_takasa.charAt(9) != "."
      ) {
        this.saigaiKasenData.damu_takasa = this.saigaiKasenData.damu_takasa.substring(
          0,
          9
        );
      }
    },
    damu_takasaChange() {
      this.saigaiKasenData.damu_takasa = this.removeCommaVal(this.saigaiKasenData.damu_takasa);
      if (this.isFloat(this.saigaiKasenData.damu_takasa)) {
        this.saigaiKasenData.damu_takasa = this.saigaiKasenData.damu_takasa
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    jisuberi_noChange() {
      if (this.isInteger(this.saigaiKasenData.jisuberi_no)) {
        this.saigaiKasenData.jisuberi_no = this.saigaiKasenData.jisuberi_no
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusfukyu_tenzui() {
      if (
        (this.saigaiKasenData.fukyu_tenzui != null && this.kousyu_cd == 2) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.fukyu_tenzui = this.saigaiKasenData.fukyu_tenzui
          .trim()
          .replace(/,/g, "");
      }
    },
    fukyu_tenzuiChange() {
      this.saigaiKasenData.fukyu_tenzui = this.removeCommaVal(this.saigaiKasenData.fukyu_tenzui);
      if (this.isFloat(this.saigaiKasenData.fukyu_tenzui)) {
        this.saigaiKasenData.fukyu_tenzui = this.saigaiKasenData.fukyu_tenzui
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusdamu_encyou() {
      if (
        (this.saigaiKasenData.damu_encyou != null && this.kousyu_cd == 3) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.damu_encyou = this.saigaiKasenData.damu_encyou
          .trim()
          .replace(/,/g, "");
      }
    },
    inputdamu_encyou() {
      this.max = this.saigaiKasenData.damu_encyou.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.damu_encyou.length > 9 &&
        this.saigaiKasenData.damu_encyou.charAt(9) != "."
      ) {
        this.saigaiKasenData.damu_encyou = this.saigaiKasenData.damu_encyou.substring(
          0,
          9
        );
      }
    },
    damu_encyouChange() {
      this.saigaiKasenData.damu_encyou = this.removeCommaVal(this.saigaiKasenData.damu_encyou);
      if (this.isFloat(this.saigaiKasenData.damu_encyou)) {
        this.saigaiKasenData.damu_encyou = this.saigaiKasenData.damu_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusatusa() {
      if (
        (this.saigaiKasenData.atusa != null && this.kousyu_cd == 5) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.atusa = this.saigaiKasenData.atusa
          .trim()
          .replace(/,/g, "");
      }
    },
    inputatusa() {
      this.max = this.saigaiKasenData.atusa.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.atusa.length > 9 &&
        this.saigaiKasenData.atusa.charAt(9) != "."
      ) {
        this.saigaiKasenData.atusa = this.saigaiKasenData.atusa.substring(0, 9);
      }
    },
    atusaChange() {
      this.saigaiKasenData.atusa = this.removeCommaVal(this.saigaiKasenData.atusa);
      if (this.isFloat(this.saigaiKasenData.atusa)) {
        this.saigaiKasenData.atusa = this.saigaiKasenData.atusa
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusharahasi_encyou() {
      if (
        (this.saigaiKasenData.harahasi_encyou != null && this.kousyu_cd == 6) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.harahasi_encyou = this.saigaiKasenData.harahasi_encyou
          .trim()
          .replace(/,/g, "");
      }
    },
    harahasi_encyouChange() {
      this.saigaiKasenData.harahasi_encyou = this.removeCommaVal(this.saigaiKasenData.harahasi_encyou);
      if (this.isFloat(this.saigaiKasenData.harahasi_encyou)) {
        this.saigaiKasenData.harahasi_encyou = this.saigaiKasenData.harahasi_encyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusgendou_habain() {
      if (
        (this.saigaiKasenData.gendou_habain != null && this.kousyu_cd == 5) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.gendou_habain = this.saigaiKasenData.gendou_habain
          .trim()
          .replace(/,/g, "");
      }
    },
    inputhgendou_habain() {
      this.max = this.saigaiKasenData.gendou_habain.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.gendou_habain.length > 9 &&
        this.saigaiKasenData.gendou_habain.charAt(9) != "."
      ) {
        this.saigaiKasenData.gendou_habain = this.saigaiKasenData.gendou_habain.substring(
          0,
          9
        );
      }
    },
    inputharahasi_encyou() {
      this.max =
        this.saigaiKasenData.harahasi_encyou.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.harahasi_encyou.length > 9 &&
        this.saigaiKasenData.harahasi_encyou.charAt(9) != "."
      ) {
        this.saigaiKasenData.harahasi_encyou = this.saigaiKasenData.harahasi_encyou.substring(
          0,
          9
        );
      }
    },
    gendou_habainChange() {
      this.saigaiKasenData.gendou_habain = this.removeCommaVal(this.saigaiKasenData.gendou_habain);
      if (this.isFloat(this.saigaiKasenData.gendou_habain)) {
        this.saigaiKasenData.gendou_habain = this.saigaiKasenData.gendou_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusharahasi_higaiencyou() {
      if (
        (this.saigaiKasenData.harahasi_higaiencyou != null &&
          this.kousyu_cd == 6) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.harahasi_higaiencyou = this.saigaiKasenData.harahasi_higaiencyou
          .trim()
          .replace(/,/g, "");
      }
    },
    inputharahasi_higaiencyou() {
      this.max =
        this.saigaiKasenData.harahasi_higaiencyou.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.harahasi_higaiencyou.length > 9 &&
        this.saigaiKasenData.harahasi_higaiencyou.charAt(9) != "."
      ) {
        this.saigaiKasenData.harahasi_higaiencyou = this.saigaiKasenData.harahasi_higaiencyou.substring(
          0,
          9
        );
      }
    },
    harahasi_higaiencyouChange() {
      this.saigaiKasenData.harahasi_higaiencyou = this.removeCommaVal(this.saigaiKasenData.harahasi_higaiencyou);
      if (this.isFloat(this.saigaiKasenData.harahasi_higaiencyou)) {
        this.saigaiKasenData.harahasi_higaiencyou = this.saigaiKasenData.harahasi_higaiencyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusdouro_habain() {
      if (
        (this.saigaiKasenData.douro_habain != null && this.kousyu_cd == 5) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.douro_habain = this.saigaiKasenData.douro_habain
          .trim()
          .replace(/,/g, "");
      }
    },
    inputdouro_habain() {
      this.max = this.saigaiKasenData.douro_habain.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.douro_habain.length > 9 &&
        this.saigaiKasenData.douro_habain.charAt(9) != "."
      ) {
        this.saigaiKasenData.douro_habain = this.saigaiKasenData.douro_habain.substring(
          0,
          9
        );
      }
    },
    douro_habainChange() {
      this.saigaiKasenData.douro_habain = this.removeCommaVal(this.saigaiKasenData.douro_habain);
      if (this.isFloat(this.saigaiKasenData.douro_habain)) {
        this.saigaiKasenData.douro_habain = this.saigaiKasenData.douro_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusharahasi_habain() {
      if (
        (this.saigaiKasenData.harahasi_habain != null && this.kousyu_cd == 6) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.harahasi_habain = this.saigaiKasenData.harahasi_habain
          .trim()
          .replace(/,/g, "");
      }
    },
    inputharahasi_habain() {
      this.max =
        this.saigaiKasenData.harahasi_habain.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.harahasi_habain.length > 9 &&
        this.saigaiKasenData.harahasi_habain.charAt(9) != "."
      ) {
        this.saigaiKasenData.harahasi_habain = this.saigaiKasenData.harahasi_habain.substring(
          0,
          9
        );
      }
    },
    harahasi_habainChange() {
      this.saigaiKasenData.harahasi_habain = this.removeCommaVal(this.saigaiKasenData.harahasi_habain);
      if (this.isFloat(this.saigaiKasenData.harahasi_habain)) {
        this.saigaiKasenData.harahasi_habain = this.saigaiKasenData.harahasi_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    focusrokyouryou_habain() {
      if (
        (this.saigaiKasenData.rokyouryou_habain != null &&
          this.kousyu_cd == 6) ||
        this.kousyu_cd == null
      ) {
        this.saigaiKasenData.rokyouryou_habain = this.saigaiKasenData.rokyouryou_habain
          .trim()
          .replace(/,/g, "");
      }
    },
    inputrokyouryou_habain() {
      this.max =
        this.saigaiKasenData.rokyouryou_habain.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigaiKasenData.rokyouryou_habain.length > 9 &&
        this.saigaiKasenData.rokyouryou_habain.charAt(9) != "."
      ) {
        this.saigaiKasenData.rokyouryou_habain = this.saigaiKasenData.rokyouryou_habain.substring(
          0,
          9
        );
      }
    },
    rokyouryou_habainChange() {
      this.saigaiKasenData.rokyouryou_habain = this.removeCommaVal(this.saigaiKasenData.rokyouryou_habain);
      if (this.isFloat(this.saigaiKasenData.rokyouryou_habain)) {
        this.saigaiKasenData.rokyouryou_habain = this.saigaiKasenData.rokyouryou_habain
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatDate: function(date) {
      if (date != "" && date != null) {
        return moment(String(date)).format("YYYY/MM/DD");
      }
      return "";
    },
    formatDecimal: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
    formatNumber: function(number) {
      if (number == "" || number == null) {
        return "";
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    },
    formatInteger: function(number) {
      if (number == "" || number == null) {
        return "";
      }
      var result = numeral(number).format("0");

      return result == 0 ? "" : result;
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    removeCommaVal: function(number) {
      return number == "" || number == null
        ? number
        : number.toString().replace(/,/g, "");
    },
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService.getGengo(request_data).then(result => {	
        this.jpYear = result;	
      });	
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    }
  }
};
</script>