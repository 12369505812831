<script>
import AppLayout from '@/components/layouts/Layout.vue';
import moment from "moment";
import { APIService } from "@/apiService.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();
var numeral = require("numeral");
export default {
  name: 'fb103',
  components: {
    AppLayout,Loading
  },
  data() {
    return {
      max: '10',
      jpYear: "",
      year: parseInt(this.$route.query.saigai_yyyy),
      ikkan_no: this.$route.query.ikkan_no,
      kotuko_sinsei:[],
      nji_satei: '',
      koji_no: '',
      hisaibi: '',
      saigai_seirinao: '',
      kanrisya_mei: '',
      cd_value: '',
      saigai_mei: '',
      mcodeList: [],
      kotuko_keizaikouka:[],
      hanran_menseki:'',
      jyutaku_tatemono:'',
      gatukou_koukyou:'',
      kouji_souko:'',
      tahata:'',
      sanrin:'',
      tetudou_douro:'',
      koutu_toho:'',
      koutu_car:'',
      disableUpDelBtn: true,
      disableInsertBtn: true,
      info: '',
      error: '',
      loginUser: {
        login_id: window.localStorage.getItem("LOGIN_ID")
      },
      post: null,
      isLoading: false,
      fullPage: true,
      chk_date: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getKotukoSinsei();
    this.getMcodeList();
    this.getGengo(this.year);
    // this.getKeizaikoukaData(this.year, this.ikkan_no);
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    getGengo(year) {	
      var date = new Date(year, 6, 1, 0, 0, 0, 0);	
      var param = moment(String(date)).format("YYYYMMDD");	
      var request_data = {	
        date: param	
      };	
      apiService	
        .getGengo(request_data)	
        .then(result => {	
          this.saigai_yyyy = result;	
          this.jpYear = result;	
        })	
        .catch(error => {	
          console.log(error);	
        });	
    },
    getKotukoSinsei() {
      var request_data = {
        saigai_yyyy: this.year,
        ikkan_no: this.ikkan_no
      }
      apiService.getKotukoSinsei(request_data)
      .then(result => {
        this.kotuko_sinsei = result;
        this.nji_satei = result.nji_satei;
        this.koji_no = result.koji_no;
        this.hisaibi = this.formatDate(result.hisaibi);
        this.saigai_seirinao = result.saigai_seirinao;
        this.kanrisya_mei = result.kanrisya_mei;
        this.cd_value = result.cd_value;
        this.saigai_mei = result.saigai_mei;
        this.getKeizaikoukaData(this.year, this.ikkan_no);
        if(this.kotuko_sinsei.length == 0){
          this.hisaibi = '';
        }
        setTimeout(() => {
          this.isLoading = false;
        },500)
      }).catch (error => {
        console.log(error);
        this.isLoading = false;
      });
    },
    getMcodeList() {
      apiService.getMcodeList()
      .then(result => {
        this.mcodeList = result;
      })
      .catch(error => {
        console.log(error);
      })
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatNumber: function(number){
      if(number == '0') {
          return '';
      }
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? '': result;
    },
    formatDecimal: function(number) {
      return number=='' || number==null || numeral(number).format("0,0.0")==0 ? '' : numeral(number).format("0,0.0");
    },
    replaceComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    isFloat(value) {
      var float = /^\d+\.\d+$/.test(value);
      var int = /^\d+$/.test(value);
      if (float || int) {
        return true;
      }
    },
    getKeizaikoukaData(year, ikkan_no){
      var request_data = {
        year: year,
        ikkan_no: ikkan_no
      };
      apiService
        .fb103getKeizaikoukaData(request_data)
        .then(result => {
          this.kotuko_keizaikouka = result.kotuko_keizaikouka;
          if(this.kotuko_keizaikouka.length > 0){
            this.hanran_menseki = this.kotuko_keizaikouka[0].hanran_menseki;
            this.hanran_menseki = this.formatDecimal(this.hanran_menseki);
            this.jyutaku_tatemono = this.formatNumber(this.kotuko_keizaikouka[0].jyutaku_tatemono);
            this.gatukou_koukyou = this.formatNumber(this.kotuko_keizaikouka[0].gatukou_koukyou);
            this.kouji_souko = this.formatNumber(this.kotuko_keizaikouka[0].kouji_souko);
            this.tahata = this.formatDecimal(this.kotuko_keizaikouka[0].tahata);
            this.sanrin = this.formatDecimal(this.kotuko_keizaikouka[0].sanrin);
            this.tetudou_douro = this.formatNumber(this.kotuko_keizaikouka[0].tetudou_douro);
            this.koutu_toho = this.formatNumber(this.kotuko_keizaikouka[0].koutu_toho);
            this.koutu_car = this.formatNumber(this.kotuko_keizaikouka[0].koutu_car);
            this.disableInsertBtn = true;
            this.disableUpDelBtn = false;
          } else {
            this.disableInsertBtn = false;
            this.disableUpDelBtn = true;
          }

          // 排他チェック用
          this.chk_date = result.chk_date

          setTimeout(() => {
            this.isLoading = false;
          },500)
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    commaHanran_menseki(){
      this.hanran_menseki = this.replaceComma(this.hanran_menseki);
      if (this.isFloat(this.hanran_menseki)) {
        this.hanran_menseki = this.hanran_menseki
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }  
    },
    commaSanrin(){
      this.sanrin = this.replaceComma(this.sanrin);
      if (this.isFloat(this.sanrin)) {
        this.sanrin = this.sanrin
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    commaTahata(){
      this.tahata = this.replaceComma(this.tahata);
      if (this.isFloat(this.tahata)) {
        this.tahata = this.tahata
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    commaJyutaku_tatemono(){
      this.jyutaku_tatemono = this.replaceComma(this.jyutaku_tatemono);
      if (this.isFloat(this.jyutaku_tatemono)) {
        this.jyutaku_tatemono = this.jyutaku_tatemono
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }      
    },
    commaGatukou_koukyou(){
      this.gatukou_koukyou = this.replaceComma(this.gatukou_koukyou);
      if (this.isFloat(this.gatukou_koukyou)) {
        this.gatukou_koukyou = this.gatukou_koukyou
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    commaKouji_souko(){
      this.kouji_souko = this.replaceComma(this.kouji_souko);
      if (this.isFloat(this.kouji_souko)) {
        this.kouji_souko = this.kouji_souko
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    commaTetudou_douro(){
      this.tetudou_douro = this.replaceComma(this.tetudou_douro);
      if (this.isFloat(this.tetudou_douro)) {
        this.tetudou_douro = this.tetudou_douro
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    commaKoutu_toho(){
      this.koutu_toho = this.replaceComma(this.koutu_toho);
      if (this.isFloat(this.koutu_toho)) {
        this.koutu_toho = this.koutu_toho
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    commaKoutu_car(){
      this.koutu_car = this.replaceComma(this.koutu_car);
      if (this.isFloat(this.koutu_car)) {
        this.koutu_car = this.koutu_car
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    removecommaHanran_menseki(){
      this.hanran_menseki = this.replaceComma(this.hanran_menseki);
    },
    removecommaTahata(){
      this.tahata = this.replaceComma(this.tahata);
    },
    removecommaSanrin(){
      this.sanrin = this.replaceComma(this.sanrin);
    },
    removecommaJyutaku_tatemono(){
      this.jyutaku_tatemono = this.replaceComma(this.jyutaku_tatemono);
    },
    removecommaGatukou_koukyou(){
      this.gatukou_koukyou = this.replaceComma(this.gatukou_koukyou);
    },
    removecommaKouji_souko(){
      this.kouji_souko = this.replaceComma(this.kouji_souko);
    },
    removecommaTetudou_douro(){
      this.tetudou_douro = this.replaceComma(this.tetudou_douro);
    },
    removecommaKoutu_toho(){
      this.koutu_toho = this.replaceComma(this.koutu_toho);
    },
    removecommaKoutu_car(){
      this.koutu_car = this.replaceComma(this.koutu_car);
    },
    inputHanran() {
      this.max = this.hanran_menseki.indexOf(".") > 0 ? 11 : 10;
      if(this.hanran_menseki.length > 9 &&  this.hanran_menseki.charAt(9) != '.' ) {
        this.hanran_menseki = this.hanran_menseki.substring(0,9);
      }
    },
    inputTahata() {
      this.max = this.tahata.indexOf(".") > 0 ? 11 : 10;
      if(this.tahata.length > 9 &&  this.tahata.charAt(9) != '.' ) {
        this.tahata = this.tahata.substring(0,9);
      }
    },
    inputSanrin() {
      this.max = this.sanrin.indexOf(".") > 0 ? 11 : 10;
      if(this.sanrin.length > 9 &&  this.sanrin.charAt(9) != '.' ) {
        this.sanrin = this.sanrin.substring(0,9);
      }
    },
    insert_btn(){
      this.info = '';
      this.error = '';
      this.isLoading = true;
      var request_data = {
        year:this.year,
        ikkan_no:this.ikkan_no,
        koji_no:this.koji_no,
        hanran_menseki:this.replaceComma(this.hanran_menseki),
        jyutaku_tatemono:this.replaceComma(this.jyutaku_tatemono),
        gatukou_koukyou:this.replaceComma(this.gatukou_koukyou),
        kouji_souko:this.replaceComma(this.kouji_souko),
        tahata:this.replaceComma(this.tahata),
        sanrin:this.replaceComma(this.sanrin),
        tetudou_douro:this.replaceComma(this.tetudou_douro),
        koutu_toho:this.replaceComma(this.koutu_toho),
        koutu_car:this.replaceComma(this.koutu_car),
        login_id: localStorage.getItem("LOGIN_ID"),
        chk_date: this.chk_date
        }
      apiService.fb103Insert(request_data)
        .then(result => {
          this.info = result;
          this.getKeizaikoukaData(this.year, this.ikkan_no);
          this.disableInsertBtn = true;
          this.disableUpDelBtn = false;
          this.isLoading = false;
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.info = '';
          this.error = error;
          this.disableInsertBtn = false;
          this.disableUpDelBtn = true;
          this.isLoading = false;
        });
    },
    update_btn(){
      this.info = '';
      this.error = '';
      this.isLoading = true;
      var request_data = {
        year:this.year,
        ikkan_no:this.ikkan_no,
        koji_no:this.koji_no,
        hanran_menseki:this.replaceComma(this.hanran_menseki),
        jyutaku_tatemono:this.replaceComma(this.jyutaku_tatemono),
        gatukou_koukyou:this.replaceComma(this.gatukou_koukyou),
        kouji_souko:this.replaceComma(this.kouji_souko),
        tahata:this.replaceComma(this.tahata),
        sanrin:this.replaceComma(this.sanrin),
        tetudou_douro:this.replaceComma(this.tetudou_douro),
        koutu_toho:this.replaceComma(this.koutu_toho),
        koutu_car:this.replaceComma(this.koutu_car),
        login_id: localStorage.getItem("LOGIN_ID"),
        chk_date: this.chk_date
      }
      apiService.fb103Update(request_data)
        .then(result => {
          this.info = result;
          this.getKeizaikoukaData(this.year, this.ikkan_no);
          this.isLoading = false; 
        })
        .catch(error => {
          confirm(this.$messages.msg['0013E']);
          this.info = '';
          this.error = error;
          this.isLoading = false;
        });
    },
    delete_btn(){
      this.info = '';
      this.error = '';
      this.isLoading = true;
      var request_data = {
        year:this.year,
        ikkan_no:this.ikkan_no,
        koji_no:this.koji_no,
        login_id: localStorage.getItem('LOGIN_ID'),
        chk_date: this.chk_date
      }  
      if(confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")){
        apiService.fb103Delete(request_data)
        .then(result => {
          this.info = result;
          this.clearKeizaikoukaData();
          this.getKeizaikoukaData(this.year, this.ikkan_no);
          this.disableInsertBtn = false;
          this.disableUpDelBtn  = true;
          this.isLoading = false; 
        })
          .catch(error => {
            confirm(this.$messages.msg['0013E']);
            this.info = '';
            this.error = error;
            this.disableInsertBtn = true;
            this.disableUpDelBtn = false;
            this.isLoading = false;
          });
      } else {
          this.isLoading = false;
      }

    },
    clearKeizaikoukaData(){
      this.hanran_menseki = '',
      this.jyutaku_tatemono = '',
      this.gatukou_koukyou = '',
      this.kouji_souko = '',
      this.tahata = '',
      this.sanrin = '',
      this.tetudou_douro = '',
      this.koutu_toho = '',
      this.koutu_car = ''
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    }
  },
}
</script>

<template>
  <AppLayout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <div class="col-sm-12 bg-success-info text-center">
        <span v-if="info" >{{ info }}</span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-if="error"  class="err">{{ error }}</span>
      </div>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">        
          <form action="" method="">
            <div class="form-group row" style="width:100%; max-width:500px">
              <label for="" class="col-sm col-form-label" style="width:100%; max-width:120px">災害年</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="jpYear" readonly/>
              </div>
              <label for="" class="col-sm col-form-label text-left pr-0 pl-1" style="width:100%; max-width:20px">年</label>
              <label for="" class="col-sm col-form-label ml-3 text-right" style="width:100%; max-width:65px">査定</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
                <input type="text" class="form-control bg-light" v-model="nji_satei" readonly/>
              </div>
              <label for="" class="col-sm col-form-label text-left pr-0 pl-1" style="width:100%; max-width:20px">次</label>
            </div>                
            <div class="form-group row" style="">
              <label for="" class="col-sm col-form-label" style="width:100%; max-width:120px">工事番号</label>
              <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:60px">
                <input type="text" class="form-control bg-light" v-model="koji_no" >
              </div>              
              <label for="" class="col-sm col-form-label text-right" style="width:100%; max-width:100px">管理者</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                <input type="text" class="form-control bg-light" v-model="kanrisya_mei" readonly/>
              </div>              
              <label for="" class="col-sm col-form-label text-right" style="width:100%; max-width:120px">事業主体</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="cd_value" readonly/>
              </div>              
              <label for="" class="col-sm col-form-label text-right" style="width:100%; max-width:120px">整理番号</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:80px">
                <input type="text" class="form-control bg-light" v-model="saigai_seirinao" readonly/>
              </div>
            </div>                
            <div class="form-group row mb-0" style="width:100%; ">
              <label for="" class="col-sm col-form-label" style="width:100%; max-width:120px">気象コード</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:410px">
                <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly/>
              </div>              
              <label for="" class="col-sm col-form-label ml-4" style="width:100%; max-width:80px">被災日</label>
              <div class="col-sm pr-0 pl-0" style="width:100%; max-width:230px">
                <input type="text" class="form-control bg-light" v-model="hisaibi" readonly/>
              </div>
            </div>
          </form>
        </div>
      </div><!--container-fluid-->     
      <form>          
        <div class="container pt-3 clearfix">           
          <div class="form-inline float-right">
              <button v-on:click="insert_btn()" class="btn btn-light" :disabled = this.disableInsertBtn type="button" style="width:120px;">登録</button>
              <button v-on:click="update_btn()" class="btn btn-light ml-3" :disabled = this.disableUpDelBtn type="button" style="width:120px;">更新</button>
              <button class="btn btn-light ml-3" type="button" style="width:120px;" @click="back_btn()">戻る</button>
          </div>
        </div>                
        <div class="container mt-3">
          <div class="row">
            <div class="col-sm-4">
              <table>
                <tbody>
                  <tr>
                    <td scope="row" class="align-middle pt-2 pb-2">氾濫防止面積</td>
                    <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                      <div class="form-inline">
                        <input type="tel" class="form-control p-1 text-right" style="width:150px" :maxlength="max" @blur="commaHanran_menseki()" @input="inputHanran()" @focus="removecommaHanran_menseki()" v-model="hanran_menseki">
                        <label for="" class="form-label ml-2" style="">ha</label>
                      </div>
                    </td>
                  </tr>                    
                  <tr>
                    <td scope="row" class="align-middle pt-2 pb-2">住宅建物</td>
                    <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                      <div class="form-inline">
                        <input type="tel" class="form-control p-1 text-right" style="width:150px" maxlength="9" @blur="commaJyutaku_tatemono()" @focus="removecommaJyutaku_tatemono()" v-model="jyutaku_tatemono">
                        <label for="" class="form-label ml-2" style="">戸</label>
                      </div>
                    </td>
                  </tr>                    
                  <tr>
                    <td scope="row" class="align-middle pt-2 pb-2">学校・公共</td>
                    <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                      <div class="form-inline">
                        <input type="tel" class="form-control p-1 text-right" style="width:150px" maxlength="9" @blur="commaGatukou_koukyou()" @focus="removecommaGatukou_koukyou()" v-model="gatukou_koukyou">
                        <label for="" class="form-label ml-2" style="">棟</label>
                      </div>
                    </td>
                  </tr>                    
                  <tr>
                    <td scope="row" class="align-middle pt-2 pb-2">工場・倉庫</td>
                    <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                      <div class="form-inline">
                        <input type="tel" class="form-control p-1 text-right" style="width:150px" maxlength="9" @blur="commaKouji_souko()" @focus="removecommaKouji_souko()" v-model="kouji_souko">
                        <label for="" class="form-label ml-2" style="">棟</label>
                      </div>
                    </td>
                  </tr>                    
                  <tr>
                    <td scope="row" class="align-middle pt-2 pb-2">田畑</td>
                    <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                      <div class="form-inline">
                        <input type="tel" class="form-control p-1 text-right" style="width:150px" :maxlength="max" @blur="commaTahata()" @input="inputTahata()" @focus="removecommaTahata()" v-model="tahata">
                        <label for="" class="form-label ml-2" style="">ha</label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>                
            <div class="col-sm-4">
                <table>
                  <tbody>
                    <tr>
                      <td scope="row" class="align-middle pt-2 pb-2">山林</td>
                      <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                        <div class="form-inline">
                          <input type="tel" class="form-control p-1 text-right" style="width:150px" :maxlength="max" @blur="commaSanrin()" @input="inputSanrin()" @focus="removecommaSanrin()" v-model="sanrin">
                          <label for="" class="form-label ml-2" style="">ha</label>
                        </div>
                      </td>
                    </tr>                      
                    <tr>
                      <td scope="row" class="align-middle pt-2 pb-2">鉄道・道路</td>
                      <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                        <div class="form-inline">
                          <input type="tel" class="form-control p-1 text-right" style="width:150px" maxlength="9" @blur="commaTetudou_douro()" @focus="removecommaTetudou_douro()" v-model="tetudou_douro">
                          <label for="" class="form-label ml-2" style="">m</label>
                        </div>
                      </td>
                    </tr>                      
                    <tr>
                      <td scope="row" class="align-middle pt-2 pb-2">交通量徒歩</td>
                      <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                        <div class="form-inline">
                          <input type="tel" class="form-control p-1 text-right" style="width:150px" maxlength="9" @blur="commaKoutu_toho()" @focus="removecommaKoutu_toho()" v-model="koutu_toho">
                          <label for="" class="form-label ml-2" style="">人/日</label>
                        </div>
                      </td>
                    </tr>                      
                    <tr>
                      <td scope="row" class="align-middle pt-2 pb-2">交通量自動車</td>
                      <td class="align-middle pl-3 pt-2 pb-2" colspan="">
                        <div class="form-inline">
                          <input type="tel" class="form-control p-1 text-right" style="width:150px" maxlength="9" @blur="commaKoutu_car()" @focus="removecommaKoutu_car()" v-model="koutu_car">
                          <label for="" class="form-label ml-2" style="">台/日</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div> 
        </div>
        <div class="container pt-3 clearfix">           
          <div class="form-inline float-right">
              <button v-on:click="delete_btn()" class="btn btn-light ml-3" :disabled = this.disableUpDelBtn type="button" style="width:120px;">削除</button>
          </div>
        </div>  
      </form>        
    </main>
  </AppLayout>
</template>
