<script>
import AppLayout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";

const apiService = new APIService();

export default {
  name: "INF002",
  components: {
    AppLayout
  },
  data() {
    return {
      naiyou: "",
      oshirase_id: "",
      info: "",
      textError: "",
      updateFlg: false,
      insertFlg: false,
      showInfo: false,
      showError: false
    };
  },
  mounted() {
    this.oshirase();
  },
  methods: {
    oshirase() {
      apiService.inf002getOshirase().then(result => {
        if (result.length > 0) {
          this.insertFlg = false;
          this.updateFlg = true;
          this.naiyou = result[0].naiyou;
          this.oshirase_id = result[0].id;
        } else {
          this.insertFlg = true;
          this.updateFlg = false;
        }
      });
    },
    insert_btn() {
      if (this.insertFlg) {
        var insert_data = {
          naiyou: this.naiyou,
          loginId: localStorage.getItem("LOGIN_ID")
        };
        apiService
          .inf002Insert(insert_data)
          .then(result => {
            this.showInfo = true;
            this.showError = false;
            this.insertFlg = false;
            this.updateFlg = true;
            this.info = result;
          })
          .catch(error => {
            this.showInfo = false;
            this.showError = true;
            this.insertFlg = true;
            this.updateFlg = false;
            this.textError = error;
          });
      } else {
        var update_data = {
          naiyou: this.naiyou,
          id: this.oshirase_id,
          loginId: localStorage.getItem("LOGIN_ID")
        };
        apiService
          .inf002Update(update_data)
          .then(result => {
            this.showInfo = true;
            this.showError = false;
            this.insertFlg = false;
            this.updateFlg = true;
            this.info = result;
          })
          .catch(error => {
            this.showInfo = false;
            this.showError = true;
            this.insertFlg = false;
            this.updateFlg = true;
            this.textError = error;
          });
      }
    }
  }
};
</script>
<template>
  <AppLayout>
    <main class="mb-3">
      <p class="col-md-12 bg-success-info text-center" v-show="showInfo">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err" v-show="showError">{{ textError }}</p>
      <form>
        <div class="container mt-3 mb-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              style="width:120px;"
              @click="insert_btn()"
            >登録</button>
          </div>
        </div>
        <div class="container mb-3">
          <div class="form-group row" style="width:100%;">
            <label for class="col-sm col-form-label" style="width:100%; max-width:80px">内容</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:1000px">
              <textarea class="form-control bg-required" rows="20" v-model="naiyou"></textarea>
            </div>
          </div>
        </div>
      </form>
    </main>
  </AppLayout>
</template>