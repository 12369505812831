<script>
import Layout from "@/components/layouts/Layout.vue";
import { APIService } from "@/apiService.js";
import moment from "moment";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiService = new APIService();
var numeral = require("numeral");

export default {
  components: {
    Layout,Loading
  },
  data() {
    if (this.$route.query.kanrisya_cd == localStorage.getItem("Kanrisya_Cd")) {
      var kanrisha_mei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      max_suryo1: "10",
      max_suryo2: "10",
      max_suryo3: "10",
      max_suryo4: "10",
      max_suryo5: "10",
      max_suryo6: "10",
      max_suryo7: "10",
      max_suryo8: "10",
      isNumFormat: false,
      numFormatErr: "",
      engYear: "",
      jpYear: "",
      tanka_year: "",
      saigai_yyyy: this.$route.query.saigai_yyyy,
      koji_no: this.$route.query.koji_no,
      bunsatu_no: this.$route.query.bunsatu_no,
      kanrisya_cd: this.$route.query.kanrisya_cd,
      kanrisya_mei: kanrisha_mei,
      satei: "",
      kotuko_kouji: {},
      saigai_kouji: {},
      sateikijyous: [],
      sateikijyou: "",
      sateikijyou_name: "",
      tumiage_sotans: [],
      tumiage_sotan: "",
      tumiage_sotan_name: "",
      okyukou_bunruis: [],
      okyukou_bunrui: "",
      okyukou_bunrui_name: "",
      meisyou1: "",
      meisyou2: "",
      meisyou3: "",
      meisyou4: "",
      meisyou5: "",
      meisyou6: "",
      meisyou7: "",
      meisyou8: "",
      suryo1: "",
      suryo2: "",
      suryo3: "",
      suryo4: "",
      suryo5: "",
      suryo6: "",
      suryo7: "",
      suryo8: "",
      tani1: "",
      tani2: "",
      tani3: "",
      tani4: "",
      tani5: "",
      tani6: "",
      tani7: "",
      tani8: "",
      saigai_meisyou1: "",
      saigai_meisyou2: "",
      saigai_meisyou3: "",
      saigai_meisyou4: "",
      saigai_meisyou5: "",
      saigai_meisyou6: "",
      saigai_meisyou7: "",
      saigai_meisyou8: "",
      saigai_suryo1: "",
      saigai_suryo2: "",
      saigai_suryo3: "",
      saigai_suryo4: "",
      saigai_suryo5: "",
      saigai_suryo6: "",
      saigai_suryo7: "",
      saigai_suryo8: "",
      saigai_tani1: "",
      saigai_tani2: "",
      saigai_tani3: "",
      saigai_tani4: "",
      saigai_tani5: "",
      saigai_tani6: "",
      saigai_tani7: "",
      saigai_tani8: "",
      kettei_gaku: "",
      eizenhi: "",
      satei_total: "",
      kari_koujihi: "",
      tanka_nendo: "",
      sinsei_gaku: "",
      kotuko_eizenhi: "",
      //kotuko_total: "",
      naikari_koujihi: "",
      kutoko_okyukou: "",
      info: "",
      textError: "",
      showInfo: false,
      showError: false,
      updateFlg: false,
      insertFlg: false,
      errors: {
        sateikijyou: null,
        tumiage_sotan: null
      },
      post: null,
      total_sougaku:"",	
      total_kari_koujihi:"",	
      kari_kouji_zapi:"",	
      oukyukarikouji_zapi:"",
      total_koujihi:"",	
      total_kouji_zapi:"",	
      //total_koujihi_show:"",	
      //total_sougaku_show:""	,
      isLoading: false,
      fullPage: true,
      chk_date: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.getKoujigaiyou();
    this.getGengo1(this.saigai_yyyy);
  },
  filter: {
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0");
      return result == 0 ? "" : result;
    }
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    inputSuryo1() {
      this.max_suryo1 = this.saigai_suryo1.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo1.length > 9 &&
        this.saigai_suryo1.charAt(9) != "."
      ) {
        this.saigai_suryo1 = this.saigai_suryo1.substring(0, 9);
      }
    },
    inputSuryo2() {
      this.max_suryo2 = this.saigai_suryo2.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo2.length > 9 &&
        this.saigai_suryo2.charAt(9) != "."
      ) {
        this.saigai_suryo2 = this.saigai_suryo2.substring(0, 9);
      }
    },
    inputSuryo3() {
      this.max_suryo3 = this.saigai_suryo3.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo3.length > 9 &&
        this.saigai_suryo3.charAt(9) != "."
      ) {
        this.saigai_suryo3 = this.saigai_suryo3.substring(0, 9);
      }
    },
    inputSuryo4() {
      this.max_suryo4 = this.saigai_suryo4.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo4.length > 9 &&
        this.saigai_suryo4.charAt(9) != "."
      ) {
        this.saigai_suryo4 = this.saigai_suryo4.substring(0, 9);
      }
    },
    inputSuryo5() {
      this.max_suryo5 = this.saigai_suryo5.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo5.length > 9 &&
        this.saigai_suryo5.charAt(9) != "."
      ) {
        this.saigai_suryo5 = this.saigai_suryo5.substring(0, 9);
      }
    },
    inputSuryo6() {
      this.max_suryo6 = this.saigai_suryo6.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo6.length > 9 &&
        this.saigai_suryo6.charAt(9) != "."
      ) {
        this.saigai_suryo6 = this.saigai_suryo6.substring(0, 9);
      }
    },
    inputSuryo7() {
      this.max_suryo7 = this.saigai_suryo7.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo7.length > 9 &&
        this.saigai_suryo7.charAt(9) != "."
      ) {
        this.saigai_suryo7 = this.saigai_suryo7.substring(0, 9);
      }
    },
    inputSuryo8() {
      this.max_suryo8 = this.saigai_suryo8.indexOf(".") > 0 ? 11 : 10;
      if (
        this.saigai_suryo8.length > 9 &&
        this.saigai_suryo8.charAt(9) != "."
      ) {
        this.saigai_suryo8 = this.saigai_suryo8.substring(0, 9);
      }
    },
    formatNumber: function(number) {
      if (number == 0) {
        return "";
      }
      if (number == "" || number == null) {
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? "" : result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    formatNumeric: function(number) {
      return number == "" ||
        number == null ||
        numeral(number).format("0,0.0") == 0
        ? ""
        : numeral(number).format("0,0.0");
    },
    removeComma: function(string) {
      if (string == "" || string == null) {
        return string;
      }
      return string.trim().replace(/,/g, "");
    },
    getKoujigaiyou() {
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        koji_no: this.koji_no,
        bunsatu_no: this.bunsatu_no,
        kanrisya_cd: this.kanrisya_cd
      };
      console.log(request_data)
      apiService
        .fc102getKoujigaiyou(request_data)
        .then(result => {
                      console.log(result)
          for (var s in result.sateikijyou) {
            this.sateikijyous.push({
              value: result.sateikijyou[s].cd_value,
              key: result.sateikijyou[s].meisai
            });
          }
          for (var t in result.tumiage_sotan) {
            this.tumiage_sotans.push({
              value: result.tumiage_sotan[t].cd_value,
              key: result.tumiage_sotan[t].meisai
            });
          }
          for (var o in result.okyukou_bunrui) {
            this.okyukou_bunruis.push({
              value: result.okyukou_bunrui[o].cd_value,
              key: result.okyukou_bunrui[o].meisai
            });
          }

          var gaku_a = 0;
          var gaku_b = 0;
          var gaku_c = 0;
          var gaku_d = 0;

          if (result.saigai_kouji && result.saigai_kouji.length > 0) {
            this.updateFlg = true;
            this.insertFlg = false;
            this.saigai_kouji = result.saigai_kouji;

            this.tanka_nendo = this.saigai_kouji[0].tanka_nendo;
            this.kari_koujihi = this.saigai_kouji[0].kari_koujihi;
            if (this.saigai_kouji[0].oukyukou_bunrui) {
              this.okyukou_bunrui = this.saigai_kouji[0].oukyukou_bunrui;
              this.okyukou_bunrui_name = this.okyukou_bunruis.filter(
                i => i.key == this.okyukou_bunrui
              )[0].value;
            }
            this.saigai_meisyou1 = this.saigai_kouji[0].meisyou1;
            this.saigai_meisyou2 = this.saigai_kouji[0].meisyou2;
            this.saigai_meisyou3 = this.saigai_kouji[0].meisyou3;
            this.saigai_meisyou4 = this.saigai_kouji[0].meisyou4;
            this.saigai_meisyou5 = this.saigai_kouji[0].meisyou5;
            this.saigai_meisyou6 = this.saigai_kouji[0].meisyou6;
            this.saigai_meisyou7 = this.saigai_kouji[0].meisyou7;
            this.saigai_meisyou8 = this.saigai_kouji[0].meisyou8;
            this.saigai_suryo1 = this.formatNumeric(
              this.saigai_kouji[0].suryo1
            );
            this.saigai_suryo2 = this.formatNumeric(
              this.saigai_kouji[0].suryo2
            );
            this.saigai_suryo3 = this.formatNumeric(
              this.saigai_kouji[0].suryo3
            );
            this.saigai_suryo4 = this.formatNumeric(
              this.saigai_kouji[0].suryo4
            );
            this.saigai_suryo5 = this.formatNumeric(
              this.saigai_kouji[0].suryo5
            );
            this.saigai_suryo6 = this.formatNumeric(
              this.saigai_kouji[0].suryo6
            );
            this.saigai_suryo7 = this.formatNumeric(
              this.saigai_kouji[0].suryo7
            );
            this.saigai_suryo8 = this.formatNumeric(
              this.saigai_kouji[0].suryo8
            );
            this.saigai_tani1 = this.saigai_kouji[0].tani1;
            this.saigai_tani2 = this.saigai_kouji[0].tani2;
            this.saigai_tani3 = this.saigai_kouji[0].tani3;
            this.saigai_tani4 = this.saigai_kouji[0].tani4;
            this.saigai_tani5 = this.saigai_kouji[0].tani5;
            this.saigai_tani6 = this.saigai_kouji[0].tani6;
            this.saigai_tani7 = this.saigai_kouji[0].tani7;
            this.saigai_tani8 = this.saigai_kouji[0].tani8;

            this.tanka_nendo = this.saigai_kouji[0].sougou_nendo;

            this.kettei_gaku     = this.formatNumber(result.saigai_kouji[0].kettei_gaku);
            this.eizenhi         = this.formatNumber(result.saigai_kouji[0].eizenhi);
            this.kari_koujihi    = this.formatNumber(result.saigai_kouji[0].kari_koujihi);
            this.kari_kouji_zapi = this.formatNumber(result.saigai_kouji[0].oukyukarikouji_zapi);

            gaku_a = parseInt(this.removeComma(this.kettei_gaku))||0;
            gaku_b = parseInt(this.removeComma(this.eizenhi))||0;
            gaku_c = parseInt(this.removeComma(this.kari_koujihi))||0;
            gaku_d = parseInt(this.removeComma(this.kari_kouji_zapi))||0;

            // 工事費
            this.total_koujihi = this.formatNumber(
                gaku_a + gaku_c
            );

            // 工事雑費
            this.total_kouji_zapi = this.formatNumber(
                gaku_b + gaku_d
            );

            // 応急仮工事費
            this.total_kari_koujihi = this.formatNumber(
                gaku_c + gaku_d
            );

            // 総額
            this.total_sougaku= this.formatNumber(
                gaku_a + gaku_b + gaku_c + gaku_d
            );

            if(result.saigai_kouji[0].tanka_nendo) {
              this.getGengo(parseInt(result.saigai_kouji[0].tanka_nendo));
            }
            //this.tanka_nendo = parseInt(this.engYear);
            //this.tanka_nendo = result.saigai_kouji[0].tanka_nendo;

            this.sateikijyou = result.saigai_kouji[0].sateikijyou_kbn;
            this.sateikijyou_name = this.sateikijyous.filter(
              i => i.key == this.sateikijyou
            )[0].value;
            this.tumiage_sotan = result.saigai_kouji[0].tumeage_soutan;
            this.tumiage_sotan_name = this.tumiage_sotans.filter(
              i => i.key == this.tumiage_sotan
            )[0].value;
            if(this.tumiage_sotan) {
                  this.tumiage_sotan_name = this.tumiage_sotans.filter(
                  i => i.key == this.tumiage_sotan
                )[0].value;
              } 
          } else {
            this.updateFlg = false;
            this.insertFlg = true;
            if (result.kotuko_kouji.length > 0) {
              this.saigai_meisyou1 = result.kotuko_kouji[0].meisyou1;
              this.saigai_meisyou2 = result.kotuko_kouji[0].meisyou2;
              this.saigai_meisyou3 = result.kotuko_kouji[0].meisyou3;
              this.saigai_meisyou4 = result.kotuko_kouji[0].meisyou4;
              this.saigai_meisyou5 = result.kotuko_kouji[0].meisyou5;
              this.saigai_meisyou6 = result.kotuko_kouji[0].meisyou6;
              this.saigai_meisyou7 = result.kotuko_kouji[0].meisyou7;
              this.saigai_meisyou8 = result.kotuko_kouji[0].meisyou8;
              this.saigai_suryo1 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou1
              );
              this.saigai_suryo2 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou2
              );
              this.saigai_suryo3 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou3
              );
              this.saigai_suryo4 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou4
              );
              this.saigai_suryo5 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou5
              );
              this.saigai_suryo6 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou6
              );
              this.saigai_suryo7 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou7
              );
              this.saigai_suryo8 = this.formatNumeric(
                result.kotuko_kouji[0].suuryou8
              );
              this.saigai_tani1 = result.kotuko_kouji[0].tani1;
              this.saigai_tani2 = result.kotuko_kouji[0].tani2;
              this.saigai_tani3 = result.kotuko_kouji[0].tani3;
              this.saigai_tani4 = result.kotuko_kouji[0].tani4;
              this.saigai_tani5 = result.kotuko_kouji[0].tani5;
              this.saigai_tani6 = result.kotuko_kouji[0].tani6;
              this.saigai_tani7 = result.kotuko_kouji[0].tani7;
              this.saigai_tani8 = result.kotuko_kouji[0].tani8;
              
              this.kettei_gaku     = this.formatNumber(result.kotuko_kouji[0].sinsei_gaku);
              this.eizenhi         = this.formatNumber(result.kotuko_kouji[0].eizenhi);
              this.kari_koujihi    = this.formatNumber(result.kotuko_kouji[0].naikari_koujihi);
              this.kari_kouji_zapi = this.formatNumber(result.kotuko_kouji[0].oukyukarikouji_zapi);

              gaku_a = parseInt(this.removeComma(this.kettei_gaku))||0;
              gaku_b = parseInt(this.removeComma(this.eizenhi))||0;
              gaku_c = parseInt(this.removeComma(this.kari_koujihi))||0;
              gaku_d = parseInt(this.removeComma(this.kari_kouji_zapi))||0;

              // 工事費
              this.total_koujihi = this.formatNumber(
                gaku_a + gaku_c
              );

              // 工事雑費
              this.total_kouji_zapi = this.formatNumber(
                gaku_b + gaku_d
              );

              // 応急仮工事費
              this.total_kari_koujihi = this.formatNumber(
                gaku_c + gaku_d
              );

              // 総額
              this.total_sougaku= this.formatNumber(
                gaku_a + gaku_b + gaku_c + gaku_d
              );
              //this.engYear = result.saigai_k

              this.sateikijyou = result.kotuko_kouji[0].jitusa_kbn;
              this.sateikijyou_name = this.sateikijyous.filter(
                i => i.key == this.sateikijyou
              )[0].value;
              this.tumiage_sotan = result.kotuko_kouji[0].tumiage_soutan;
              if(this.tumiage_sotan) {
                  this.tumiage_sotan_name = this.tumiage_sotans.filter(
                  i => i.key == this.tumiage_sotan
                )[0].value;
              } 

              //総合単価年度
              if(result.kotuko_kouji[0].sougou_nendo) {
                this.getGengo(parseInt(result.kotuko_kouji[0].sougou_nendo));
              }
            } else {
              this.sateikijyou = 1;
              this.sateikijyou_name = this.sateikijyous.filter(
                i => i.key == this.sateikijyou
              )[0].value;
              this.tumiage_sotan = 1;
              this.tumiage_sotan_name = this.tumiage_sotans.filter(
                i => i.key == this.tumiage_sotan
              )[0].value;
            }
          }
          if (result.saigai_satei.length > 0) {
            this.satei = result.saigai_satei[0].nji_satei;
          } else {
            this.satei = "";
          }

          if (result.kotuko_kouji.length > 0) {
            this.meisyou1 = result.kotuko_kouji[0].meisyou1;
            this.meisyou2 = result.kotuko_kouji[0].meisyou2;
            this.meisyou3 = result.kotuko_kouji[0].meisyou3;
            this.meisyou4 = result.kotuko_kouji[0].meisyou4;
            this.meisyou5 = result.kotuko_kouji[0].meisyou5;
            this.meisyou6 = result.kotuko_kouji[0].meisyou6;
            this.meisyou7 = result.kotuko_kouji[0].meisyou7;
            this.meisyou8 = result.kotuko_kouji[0].meisyou8;
            this.suryo1 = this.formatNumeric(result.kotuko_kouji[0].suuryou1);
            this.suryo2 = this.formatNumeric(result.kotuko_kouji[0].suuryou2);
            this.suryo3 = this.formatNumeric(result.kotuko_kouji[0].suuryou3);
            this.suryo4 = this.formatNumeric(result.kotuko_kouji[0].suuryou4);
            this.suryo5 = this.formatNumeric(result.kotuko_kouji[0].suuryou5);
            this.suryo6 = this.formatNumeric(result.kotuko_kouji[0].suuryou6);
            this.suryo7 = this.formatNumeric(result.kotuko_kouji[0].suuryou7);
            this.suryo8 = this.formatNumeric(result.kotuko_kouji[0].suuryou8);
            this.tani1 = result.kotuko_kouji[0].tani1;
            this.tani2 = result.kotuko_kouji[0].tani2;
            this.tani3 = result.kotuko_kouji[0].tani3;
            this.tani4 = result.kotuko_kouji[0].tani4;
            this.tani5 = result.kotuko_kouji[0].tani5;
            this.tani6 = result.kotuko_kouji[0].tani6;
            this.tani7 = result.kotuko_kouji[0].tani7;
            this.tani8 = result.kotuko_kouji[0].tani8;
            this.sinsei_gaku = this.formatNumber(result.kotuko_kouji[0].sinsei_gaku);
            this.kotuko_eizenhi = this.formatNumber(result.kotuko_kouji[0].eizenhi);
            // this.kotuko_total = this.formatNumber(
            //   parseInt(this.removeComma(this.sinsei_gaku)) +
            //   parseInt(this.removeComma(this.kotuko_eizenhi))
            // );
            this.naikari_koujihi = this.formatNumber(result.kotuko_kouji[0].naikari_koujihi);
            this.oukyukarikouji_zapi = this.formatNumber(result.kotuko_kouji[0].oukyukarikouji_zapi);
            // this.total_koujihi_show = this.formatNumber(
            //   parseInt(this.removeComma(this.naikari_koujihi)) +
            //   parseInt(this.removeComma(this.oukyukarikouji_zapi))	
            // );	
            // this.total_sougaku_show= this.formatNumber(	
            //   parseInt(this.removeComma(this.kettei_gaku)) +	
            //   parseInt(this.removeComma(this.eizenhi))+	
            //   parseInt(this.removeComma(this.total_koujihi_show))	
            // );	
            this.kutoko_okyukou = result.kotuko_kouji[0].oukyukouji_bunrui;
            this.kanrisya_mei = result.kotuko_kouji[0].kanrisya_mei;

            //総合単価年度
            if(result.kotuko_kouji[0].sougou_nendo) {
              this.getGengo(parseInt(result.kotuko_kouji[0].sougou_nendo));
            }
          }

            // 排他チェック用の日付
            this.chk_date = result.chk_date;
        })
        .catch(error => {
          console.log(error);
        });
        setTimeout(() => {
          this.isLoading = false;
        },500)
    },
    getGengo1(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.jpYear = result;
      });
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.tanka_nendo = result;
      });
    },
    changedTankanendo(jpYear) {
      var matches = jpYear.match(/[a-zA-Z]+/g);
      var yvalue = jpYear.replace(/[^\d.]/g, "");
      var request_data = {
        gengo_cd: matches.toString()
      };
      apiService.getGengoStartDate(request_data).then(result => {
        var startDate = result[0].start_date;
        this.tanka_year =
          parseInt(startDate.substr(0, 4)) + (parseInt(yvalue) - 1);
      });
    },
    // sateikijyouChange(sateikijyou) {
    //   this.sateikijyou = sateikijyou;
    //   var kijyou_id = this.sateikijyous.filter(i => i.key == sateikijyou);
    //   this.sateikijyou_name = kijyou_id[0].value;
    // },
    // tumiageChange(tumiage_sotan) {
    //   this.tumiage_sotan = tumiage_sotan;
    //   var sotan_id = this.tumiage_sotans.filter(i => i.key == tumiage_sotan);
    //   this.tumiage_sotan_name = sotan_id[0].value;
    // },
    // okyukouChange(okyukou_bunrui) {
    //   this.okyukou_bunrui = okyukou_bunrui;
    //   var bunrui_id = this.okyukou_bunruis.filter(i => i.key == okyukou_bunrui);
    //   this.okyukou_bunrui_name = bunrui_id[0].value;
    // },
    ketteigakuChange(kettei_gaku) {
      this.kettei_gaku = this.removeComma(kettei_gaku);
      if (this.isInteger(this.kettei_gaku)) {
        this.kettei_gaku = this.kettei_gaku
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      var gaku_a = parseInt(this.removeComma(this.kettei_gaku))||0;
      var gaku_b = parseInt(this.removeComma(this.eizenhi))||0;
      var gaku_c = parseInt(this.removeComma(this.kari_koujihi))||0;
      var gaku_d = parseInt(this.removeComma(this.kari_kouji_zapi))||0;

      // 工事費
      this.total_koujihi = this.formatNumber(
        gaku_a + gaku_c
      );

      // 総額
      this.total_sougaku= this.formatNumber(
        gaku_a + gaku_b + gaku_c + gaku_d
      );
    },
    eizenhiChange(eizenhi) {
      this.eizenhi = this.removeComma(eizenhi);
      if (this.isInteger(this.eizenhi)) {
        this.eizenhi = this.eizenhi
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      var gaku_a = parseInt(this.removeComma(this.kettei_gaku))||0;
      var gaku_b = parseInt(this.removeComma(this.eizenhi))||0;
      var gaku_c = parseInt(this.removeComma(this.kari_koujihi))||0;
      var gaku_d = parseInt(this.removeComma(this.kari_kouji_zapi))||0;

      // 工事雑費
      this.total_kouji_zapi = this.formatNumber(
        gaku_b + gaku_d
      );

      // 総額
      this.total_sougaku= this.formatNumber(
        gaku_a + gaku_b + gaku_c + gaku_d
      );
    },
    koujihiChange(kari_koujihi) {
      this.kari_koujihi = this.removeComma(kari_koujihi);
      if (this.isInteger(this.kari_koujihi)) {
        this.kari_koujihi = this.kari_koujihi
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      var gaku_a = parseInt(this.removeComma(this.kettei_gaku))||0;
      var gaku_b = parseInt(this.removeComma(this.eizenhi))||0;
      var gaku_c = parseInt(this.removeComma(this.kari_koujihi))||0;
      var gaku_d = parseInt(this.removeComma(this.kari_kouji_zapi))||0;

      // 工事費
      this.total_koujihi = this.formatNumber(
        gaku_a + gaku_c
      );

      // 応急仮工事費
      this.total_kari_koujihi = this.formatNumber(
        gaku_c + gaku_d
      );

      // 総額
      this.total_sougaku= this.formatNumber(
        gaku_a + gaku_b + gaku_c + gaku_d
      );
    },
    koujizapiChange(kari_kouji_zapi) {	
      this.kari_kouji_zapi = this.removeComma(kari_kouji_zapi);
      if (this.isInteger(this.kari_kouji_zapi)) {
        this.kari_kouji_zapi = this.kari_kouji_zapi
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      var gaku_a = parseInt(this.removeComma(this.kettei_gaku))||0;
      var gaku_b = parseInt(this.removeComma(this.eizenhi))||0;
      var gaku_c = parseInt(this.removeComma(this.kari_koujihi))||0;
      var gaku_d = parseInt(this.removeComma(this.kari_kouji_zapi))||0;

      // 工事雑費
      this.total_kouji_zapi = this.formatNumber(
        gaku_b + gaku_d
      );

      // 応急仮工事費
      this.total_kari_koujihi = this.formatNumber(
        gaku_c + gaku_d
      );

      // 総額
      this.total_sougaku= this.formatNumber(
        gaku_a + gaku_b + gaku_c + gaku_d
      );
    },	
    saigaisuryo1Change(saigai_suryo1) {
      this.saigai_suryo1 = this.removeComma(saigai_suryo1);
      if (this.isInteger(this.saigai_suryo1)) {
        this.saigai_suryo1 = this.saigai_suryo1
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo1RemoveComma() {
      this.saigai_suryo1 = this.removeComma(this.saigai_suryo1);
    },
    saigaisuryo2Change(saigai_suryo2) {
      this.saigai_suryo2 = this.removeComma(saigai_suryo2);
      if (this.isInteger(this.saigai_suryo2)) {
        this.saigai_suryo2 = this.saigai_suryo2
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo2RemoveComma() {
      this.saigai_suryo2 = this.removeComma(this.saigai_suryo2);
    },
    saigaisuryo3Change(saigai_suryo3) {
      this.saigai_suryo3 = this.removeComma(saigai_suryo3);
      if (this.isInteger(this.saigai_suryo3)) {
        this.saigai_suryo3 = this.saigai_suryo3
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo3RemoveComma() {
      this.saigai_suryo3 = this.removeComma(this.saigai_suryo3);
    },
    saigaisuryo4Change(saigai_suryo4) {
      this.saigai_suryo4 = this.removeComma(saigai_suryo4);
      if (this.isInteger(this.saigai_suryo4)) {
        this.saigai_suryo4 = this.saigai_suryo4
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo4RemoveComma() {
      this.saigai_suryo4 = this.removeComma(this.saigai_suryo4);
    },
    saigaisuryo5Change(saigai_suryo5) {
      this.saigai_suryo5 = this.removeComma(saigai_suryo5);
      if (this.isInteger(this.saigai_suryo5)) {
        this.saigai_suryo5 = this.saigai_suryo5
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo5RemoveComma() {
      this.saigai_suryo5 = this.removeComma(this.saigai_suryo5);
    },
    saigaisuryo6Change(saigai_suryo6) {
      this.saigai_suryo6 = this.removeComma(saigai_suryo6);
      if (this.isInteger(this.saigai_suryo6)) {
        this.saigai_suryo6 = this.saigai_suryo6
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo6RemoveComma() {
      this.saigai_suryo6 = this.removeComma(this.saigai_suryo6);
    },
    saigaisuryo7Change(saigai_suryo7) {
      this.saigai_suryo7 = this.removeComma(saigai_suryo7);
      if (this.isInteger(this.saigai_suryo7)) {
        this.saigai_suryo7 = this.saigai_suryo7
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo7RemoveComma() {
      this.saigai_suryo7 = this.removeComma(this.saigai_suryo7);
    },
    saigaisuryo8Change(saigai_suryo8) {
      this.saigai_suryo8 = this.removeComma(saigai_suryo8);
      if (this.isInteger(this.saigai_suryo8)) {
        this.saigai_suryo8 = this.saigai_suryo8
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    saigaisuryo8RemoveComma() {
      this.saigai_suryo8 = this.removeComma(this.saigai_suryo8);
    },
    insert_btn() {
      this.showInfo = false;
      this.showError = false;
      this.errors.sateikijyou = null;
      this.errors.tumiage_sotan = null;
      this.isLoading = true;
      if (this.tanka_nendo) {
        this.changedTankanendo(this.tanka_nendo);
      }
      setTimeout(() => {
        this.numericFormatCheck();
        if (!this.isNumFormat) {
          var request_data = {
            saigai_yyyy: this.saigai_yyyy,
            koji_no: parseInt(this.koji_no),
            kanrisya_cd: parseInt(this.kanrisya_cd),
            bunsatu_no: parseInt(this.bunsatu_no),
            sateikijyou_kbn: this.sateikijyou,
            tumeage_soutan: this.tumiage_sotan,
            tanka_nendo: this.tanka_year,
            //tanka_nendo: this.tanka_nendo,
            suryo1: this.removeComma(this.saigai_suryo1),
            suryo2: this.removeComma(this.saigai_suryo2),
            suryo3: this.removeComma(this.saigai_suryo3),
            suryo4: this.removeComma(this.saigai_suryo4),
            suryo5: this.removeComma(this.saigai_suryo5),
            suryo6: this.removeComma(this.saigai_suryo6),
            suryo7: this.removeComma(this.saigai_suryo7),
            suryo8: this.removeComma(this.saigai_suryo8),
            oukyukou_bunrui: this.okyukou_bunrui,
            kari_koujihi: this.removeComma(this.kari_koujihi),
            kari_kouji_zapi: this.removeComma(this.kari_kouji_zapi),
            meisyou1: this.saigai_meisyou1,
            meisyou2: this.saigai_meisyou2,
            meisyou3: this.saigai_meisyou3,
            meisyou4: this.saigai_meisyou4,
            meisyou5: this.saigai_meisyou5,
            meisyou6: this.saigai_meisyou6,
            meisyou7: this.saigai_meisyou7,
            meisyou8: this.saigai_meisyou8,
            tani1: this.saigai_tani1,
            tani2: this.saigai_tani2,
            tani3: this.saigai_tani3,
            tani4: this.saigai_tani4,
            tani5: this.saigai_tani5,
            tani6: this.saigai_tani6,
            tani7: this.saigai_tani7,
            tani8: this.saigai_tani8,
            kettei_gaku: this.removeComma(this.kettei_gaku),
            eizenhi: this.removeComma(this.eizenhi),
            loginId: localStorage.getItem("LOGIN_ID")
          };
          apiService
            .fc102Insert(request_data)
            .then(result => {
              this.showInfo  = true;
              this.showError = false;
              this.insertFlg = false;
              this.updateFlg = true;
              this.errors.sateikijyou = null;
              this.errors.tumiage_sotan = null;
              this.info     = result.msg;
              this.chk_date = result.chk_date;
            })
            .catch(error => {
              if (typeof error == "string") {
                confirm(this.$messages.msg['0013E']);
                this.showError = true;
                this.showInfo  = false;
                this.insertFlg = true;
                this.updateFlg = false;
                this.textError = error;
                this.errors.sateikijyou = null;
                this.errors.tumiage_sotan = null;
              } else {
                this.showError = false;
                this.errors.sateikijyou = error.errors.sateikijyou_kbn
                  ? error.errors.sateikijyou_kbn[0]
                  : "";
                this.errors.tumiage_sotan = error.errors.tumeage_soutan
                  ? error.errors.tumeage_soutan[0]
                  : "";
                confirm(this.$messages.msg['0013E']);
              }
            });
        }
        this.isLoading = false;
      }, 1800);
    },
    update_btn() {
      this.showInfo =  false;
      this.showError = false;
      this.errors.sateikijyou = null;
      this.errors.tumiage_sotan = null;
      this.isLoading = true;
      if (this.tanka_nendo) {
        this.changedTankanendo(this.tanka_nendo);
      }
      this.numericFormatCheck();
      setTimeout(() => {
        if (!this.isNumFormat) {
          var request_data = {
            saigai_yyyy: this.saigai_yyyy,
            koji_no: parseInt(this.koji_no),
            kanrisya_cd: parseInt(this.kanrisya_cd),
            bunsatu_no: parseInt(this.bunsatu_no),
            sateikijyou_kbn: this.sateikijyou,
            tumeage_soutan: this.tumiage_sotan,
            tanka_nendo: this.tanka_year,
            //tanka_nendo: this.tanka_nendo,
            suryo1: this.removeComma(this.saigai_suryo1),
            suryo2: this.removeComma(this.saigai_suryo2),
            suryo3: this.removeComma(this.saigai_suryo3),
            suryo4: this.removeComma(this.saigai_suryo4),
            suryo5: this.removeComma(this.saigai_suryo5),
            suryo6: this.removeComma(this.saigai_suryo6),
            suryo7: this.removeComma(this.saigai_suryo7),
            suryo8: this.removeComma(this.saigai_suryo8),
            oukyukou_bunrui: this.okyukou_bunrui,
            kari_koujihi: this.removeComma(this.kari_koujihi),
            kari_kouji_zapi: this.removeComma(this.kari_kouji_zapi),
            meisyou1: this.saigai_meisyou1,
            meisyou2: this.saigai_meisyou2,
            meisyou3: this.saigai_meisyou3,
            meisyou4: this.saigai_meisyou4,
            meisyou5: this.saigai_meisyou5,
            meisyou6: this.saigai_meisyou6,
            meisyou7: this.saigai_meisyou7,
            meisyou8: this.saigai_meisyou8,
            tani1: this.saigai_tani1,
            tani2: this.saigai_tani2,
            tani3: this.saigai_tani3,
            tani4: this.saigai_tani4,
            tani5: this.saigai_tani5,
            tani6: this.saigai_tani6,
            tani7: this.saigai_tani7,
            tani8: this.saigai_tani8,
            kettei_gaku: this.removeComma(this.kettei_gaku),
            eizenhi: this.removeComma(this.eizenhi),
            loginId: localStorage.getItem("LOGIN_ID"),
            chk_date: this.chk_date
          };
          console.log(request_data)
          apiService
            .fc102Update(request_data)
            .then(result => {
              this.showInfo  = true;
              this.showError = false;
              this.errors.sateikijyou = null;
              this.errors.tumiage_sotan = null;
              this.info     = result.msg;
              this.chk_date = result.chk_date;
            })
            .catch(error => {
              if (typeof error == "string") {
                confirm(this.$messages.msg['0013E']);
                this.showInfo  = false;
                this.showError = true;
                this.textError = error;
                this.errors.sateikijyou = null;
                this.errors.tumiage_sotan = null;
              } else {
                this.showError = false;
                this.errors.sateikijyou = error.errors.sateikijyou_kbn
                  ? error.errors.sateikijyou_kbn[0]
                  : "";
                this.errors.tumiage_sotan = error.errors.tumeage_soutan
                  ? error.errors.tumeage_soutan[0]
                  : "";
                confirm(this.$messages.msg['0013E']);
              }
            });
        }
        this.isLoading = false;
      }, 1800);
    },
    delete_btn() {
      var request_data = {
        saigai_yyyy: this.saigai_yyyy,
        koji_no: parseInt(this.koji_no),
        bunsatu_no: parseInt(this.bunsatu_no),
        chk_date: this.chk_date
      };
      if (confirm("データを削除すると復元することが出来ませんが、削除してもよろしいですか？")) {
        apiService
          .fc102Delete(request_data)
          .then(result => {
            this.showInfo  = true;
            this.showError = false;
            this.info      = result;
            this.insertFlg = true;
            this.updateFlg = false;
            this.clearData();
          })
          .catch(error => {
            console.log(error);
            confirm(this.$messages.msg['0013E']);
            this.showInfo  = false;
            this.showError = true;
            this.textError = error;
            this.errors.sateikijyou = null;
            this.errors.tumiage_sotan = null;
          });
          setTimeout(() => {
            this.isLoading = false;
          },500)
      }
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    },
    numericFormatCheck() {
      var suuryou1 = this.saigai_suryo1.replace(/,/g, "");
      suuryou1 = suuryou1.trim().split(".")[0];
      var suuryou2 = this.saigai_suryo2.replace(/,/g, "");
      suuryou2 = suuryou2.trim().split(".")[0];
      var suuryou3 = this.saigai_suryo3.replace(/,/g, "");
      suuryou3 = suuryou3.trim().split(".")[0];
      var suuryou4 = this.saigai_suryo4.replace(/,/g, "");
      suuryou4 = suuryou4.trim().split(".")[0];
      var suuryou5 = this.saigai_suryo5.replace(/,/g, "");
      suuryou5 = suuryou5.trim().split(".")[0];
      var suuryou6 = this.saigai_suryo6.replace(/,/g, "");
      suuryou6 = suuryou6.trim().split(".")[0];
      var suuryou7 = this.saigai_suryo7.replace(/,/g, "");
      suuryou7 = suuryou7.trim().split(".")[0];
      var suuryou8 = this.saigai_suryo8.replace(/,/g, "");
      suuryou8 = suuryou8.trim().split(".")[0];
      if (suuryou1.length > 9) {
        this.isNumFormat = true;
        this.numFormatErr = this.$messages.msg["0014W"];
        confirm(this.$messages.msg['0013E']);
      } else {
        if (suuryou2.length > 9) {
          this.isNumFormat = true;
          this.numFormatErr = this.$messages.msg["0015W"];
          confirm(this.$messages.msg['0013E']);
        } else {
          if (suuryou3.length > 9) {
            this.isNumFormat = true;
            this.numFormatErr = this.$messages.msg["0016W"];
            confirm(this.$messages.msg['0013E']);
          } else {
            if (suuryou4.length > 9) {
              this.isNumFormat = true;
              this.numFormatErr = this.$messages.msg["0017W"];
              confirm(this.$messages.msg['0013E']);
            } else {
              if (suuryou5.length > 9) {
                this.isNumFormat = true;
                this.numFormatErr = this.$messages.msg["0018W"];
                confirm(this.$messages.msg['0013E']);
              } else {
                if (suuryou6.length > 9) {
                  this.isNumFormat = true;
                  this.numFormatErr = this.$messages.msg["0019W"];
                  confirm(this.$messages.msg['0013E']);
                } else {
                  if (suuryou7.length > 9) {
                    this.isNumFormat = true;
                    this.numFormatErr = this.$messages.msg["0020W"];
                    confirm(this.$messages.msg['0013E']);
                  } else {
                    if (suuryou8.length > 9) {
                      this.isNumFormat = true;
                      this.numFormatErr = this.$messages.msg["0021W"];
                      confirm(this.$messages.msg['0013E']);
                    } else {
                      this.isNumFormat = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    clearData() {
      //(this.koji_no = ""),
      //(this.bunsatu_no = ""),
      (this.sateikijyou = ""),
      (this.sateikijyou_name = ""),
      (this.tumiage_sotan = ""),
      (this.tumiage_sotan_name = ""),
      (this.okyukou_bunrui = ""),
      (this.okyukou_bunrui_name = ""),
      (this.tanka_nendo = ""),
    //   (this.meisyou1 = ""),
    //   (this.meisyou2 = ""),
    //   (this.meisyou3 = ""),
    //   (this.meisyou4 = ""),
    //   (this.meisyou5 = ""),
    //   (this.meisyou6 = ""),
    //   (this.meisyou7 = ""),
    //   (this.meisyou8 = ""),
    //   (this.suryo1 = ""),
    //   (this.suryo2 = ""),
    //   (this.suryo3 = ""),
    //   (this.suryo4 = ""),
    //   (this.suryo5 = ""),
    //   (this.suryo6 = ""),
    //   (this.suryo7 = ""),
    //   (this.suryo8 = ""),
    //   (this.tani1 = ""),
    //   (this.tani2 = ""),
    //   (this.tani3 = ""),
    //   (this.tani4 = ""),
    //   (this.tani5 = ""),
    //   (this.tani6 = ""),
    //   (this.tani7 = ""),
    //   (this.tani8 = ""),
      (this.saigai_meisyou1 = ""),
      (this.saigai_meisyou2 = ""),
      (this.saigai_meisyou3 = ""),
      (this.saigai_meisyou4 = ""),
      (this.saigai_meisyou5 = ""),
      (this.saigai_meisyou6 = ""),
      (this.saigai_meisyou7 = ""),
      (this.saigai_meisyou8 = ""),
      (this.saigai_suryo1 = ""),
      (this.saigai_suryo2 = ""),
      (this.saigai_suryo3 = ""),
      (this.saigai_suryo4 = ""),
      (this.saigai_suryo5 = ""),
      (this.saigai_suryo6 = ""),
      (this.saigai_suryo7 = ""),
      (this.saigai_suryo8 = ""),
      (this.saigai_tani1 = ""),
      (this.saigai_tani2 = ""),
      (this.saigai_tani3 = ""),
      (this.saigai_tani4 = ""),
      (this.saigai_tani5 = ""),
      (this.saigai_tani6 = ""),
      (this.saigai_tani7 = ""),
      (this.saigai_tani8 = ""),
      (this.kettei_gaku = ""),
      (this.eizenhi = ""),
      (this.kari_koujihi = ""),
      (this.kari_kouji_zapi = ""),
      (this.total_koujihi = ""),
      (this.total_kouji_zapi = ""),
      (this.total_kari_koujihi = ""),
      (this.total_sougaku = "");
    },
    openDialog:function(){
        var message = {
            title: '単位記入例'
            ,body: '平米は<b>㎡</b>を使用<br>立米は<b>㎥</b>を使用'
        };

        var options = {
            html: true
            , okText: '閉じる'
            , backdropClose: true
            , customClass: 'fc102'
        };
      this.$dialog.confirm(message, options);
    },
    Download() {
      this.isLoading = true;
      apiService.koujigaiyouDownload().then(result => {
        var date = moment(String(new Date())).format('YYYYMMDD');
        this.downloadReport(result, '工事概要' + date +'.xlsx');
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },
    downloadReport(result, fileName) {
      const blob = new Blob([result], { type: "text/xlsx" });
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
      this.isLoading = false;
    },
  }
};
</script>
<template>
  <Layout>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage">
        <div style="vertical-align: middle;">
          <img src="@/assets/loader.gif" style="dipslay:inline-block; float:left">
          <h4 style="dipslay:inline-block; float:left; color:#3399F3">読み込み中...</h4>
        </div>
      </loading>
    </div>
    <main class="mb-3">
      <p class="col-md-12 bg-success-info text-center" v-show="showInfo">{{ info }}</p>
      <p class="col-md-12 bg-err text-center err" v-show="isNumFormat">{{ numFormatErr }}</p>
      <p class="col-md-12 bg-err text-center err" v-show="showError">{{ textError }}</p>
      <div class="container-fluid bg-lightgreen pt-3 pb-3">
        <div class="container">
          <form action method>
            <table>
              <tbody>
                <tr>
                  <td class="pr-2 pb-3 align-middle">災害年</td>
                  <td class="pb-3 align-middle">
<!--
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="jpYear"
                      style="width:100%; max-width:60px"
                      readonly
                    />
-->
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="jpYear"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pr-5 pb-3 align-middle">年</td>
                  <td class="pr-2 pb-3 align-middle">査定</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="satei"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pb-3 align-middle">次</td>
                  <td class="pr-5 align-middle"></td>
                  <td class="pr-2 pb-3 align-middle">管理者</td>
                  <td class="pb-3 align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="kanrisya_cd"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pb-3 align-middle" width="400px">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="kanrisya_mei"
                      style="max-width:500px"
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td class="pr-2 align-middle">工事番号</td>
                  <td class="align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="koji_no"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                  <td class="pr-5 align-middle"></td>
                  <td class="pr-2 align-middle">分冊</td>
                  <td class="align-middle">
                    <input
                      type="text"
                      class="form-control bg-light"
                      v-model="bunsatu_no"
                      style="width:100%; max-width:60px"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <!--container-fluid-->
      <form>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light"
              type="button"
              style="width:120px;"
              :disabled="insertFlg ? false : true"
              @click="insert_btn()"
            >登録</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="update_btn()"
              :disabled="updateFlg ? false : true"
            >更新</button>
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="back_btn()"
            >戻る</button>
          </div>
        </div>
        <div class="container">
          <div class="form-group row mb-0 mt-3" style="width:100%; ">
            <label for class="col-sm col-form-label" style="width:100%; max-width:120px">実査・机上</label>
<!--
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:70px">
              <select
                id
                class="form-control bg-required"
                v-model="sateikijyou"
                @change="sateikijyouChange(sateikijyou)"
              >
                <option v-for="item in sateikijyous" :key="item.key" :value="item.key">{{item.key}}</option>
              </select>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
              <input type="text" class="form-control bg-light" v-model="sateikijyou_name" readonly />
            </div>
-->
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select
                id
                class="form-control bg-required"
                v-model="sateikijyou"
              >
                <option value=""></option>
                <option v-for="item in sateikijyous" :key="item.key" :value="item.key">{{item.key}}<span> : </span>{{item.value}}</option>
              </select>
            </div>
            <label for class="col-sm col-form-label ml-2" style="width:100%; max-width:120px">積算方法</label>
<!--
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:70px">
              <select
                id
                class="form-control bg-required"
                v-model="tumiage_sotan"
                @change="tumiageChange(tumiage_sotan)"
              >
                <option
                  v-for="item in tumiage_sotans"
                  :key="item.key"
                  :value="item.key"
                >{{item.key}}</option>
              </select>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:150px">
              <input
                type="text"
                class="form-control bg-light"
                v-model="tumiage_sotan_name"
                readonly
              />
            </div>
-->
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:220px">
              <select
                id
                class="form-control bg-required"
                v-model="tumiage_sotan"
              >
                <option value=""></option>
                <option
                  v-for="item in tumiage_sotans"
                  :key="item.key"
                  :value="item.key"
                >{{item.key}}<span> : </span>{{item.value}}</option>
              </select>
            </div>
            <label for class="col-sm col-form-label ml-2" style="width:100%; max-width:130px">総合単価年度</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="tel" class="form-control" v-model="tanka_nendo" />
            </div>
            <label
              for
              class="col-sm col-form-label text-left pr-0 pl-1"
              style="width:100%; max-width:20px"
            >年</label>
          </div>
          <div class="form-group row mb-0 mt-3" style="width:100%; ">
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:120px;margin-left:10px;">
              <p
                style="width:30%; max-width:130px; white-space: nowrap; color: red;"
              >{{ this.errors.sateikijyou }}</p>
            </div>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:120px;margin-left:230px;">
              <p
                style="width:30%; max-width:130px; white-space: nowrap; color: red;"
              >{{ this.errors.tumiage_sotan }}</p>
            </div>
          </div>
        </div>
        <div class="container table-responsive mt-3">
          <p class="text-info mb-1">各行の上段には、国庫負担申請情報を表示します</p>
          <table class="table table-sm table-bordered" style>
            <thead class="table-dark">
              <tr class>
                <th scope="col" class="align-middle text-center border-white" width="10%">No.</th>
                <th scope="col" class="align-middle text-center border-white" width="50%">
                  <div style="display:inline-block;width:25%;"></div>
                  <div style="display:inline-block;width:50%;" class="align-middle text-center">内容「※最大15文字まで」</div>
                  <div style="display:inline-block;width:25%;" class="align-middle text-right">
                    <span >記入例</span>
                    <span class="btn-question" @click="Download()">？</span>
                  </div>
                </th>
                <th scope="col" class="align-middle text-center border-white" width="20%">数量</th>
                <th scope="col" class="align-middle text-center border-white" width="20%">
                    <div style="display:inline-block;width:40%;"></div>
                    <div style="display:inline-block;width:20%;" class="align-middle text-center">単位</div>
                    <div style="display:inline-block;width:40%;" class="align-middle text-right">
                      <span>記入例</span>
                      <span class="btn-question" @click="openDialog()">？</span>
                    </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2" class="align-middle text-center">1</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou1}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo1}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani1}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou1"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo1"
                    :maxlength="max_suryo1"
                    @blur="saigaisuryo1Change(saigai_suryo1)"
                    @focus="saigaisuryo1RemoveComma()"
                    @input="inputSuryo1()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani1"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">2</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou2}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo2}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani2}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou2"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo2"
                    :maxlength="max_suryo2"
                    @blur="saigaisuryo2Change(saigai_suryo2)"
                    @focus="saigaisuryo2RemoveComma()"
                    @input="inputSuryo2()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani2"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">3</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou3}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo3}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani3}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou3"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo3"
                    :maxlength="max_suryo3"
                    @blur="saigaisuryo3Change(saigai_suryo3)"
                    @focus="saigaisuryo3RemoveComma()"
                    @input="inputSuryo3()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani3"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">4</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou4}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo4}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani4}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou4"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo4"
                    :maxlength="max_suryo4"
                    @blur="saigaisuryo4Change(saigai_suryo4)"
                    @focus="saigaisuryo4RemoveComma()"
                    @input="inputSuryo4()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani4"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">5</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou5}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo5}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani5}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou5"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo5"
                    :maxlength="max_suryo5"
                    @blur="saigaisuryo5Change(saigai_suryo5)"
                    @focus="saigaisuryo5RemoveComma()"
                    @input="inputSuryo5()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani5"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">6</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou6}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo6}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani6}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou6"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo6"
                    :maxlength="max_suryo6"
                    @blur="saigaisuryo6Change(saigai_suryo6)"
                    @focus="saigaisuryo6RemoveComma()"
                    @input="inputSuryo6()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani6"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">7</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou7}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo7}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani7}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou7"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo7"
                    :maxlength="max_suryo7"
                    @blur="saigaisuryo7Change(saigai_suryo7)"
                    @focus="saigaisuryo7RemoveComma()"
                    @input="inputSuryo7()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani7"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="2" class="align-middle text-center">8</td>
                <td class="align-middle bg-lightyellow" height="35px;">{{this.meisyou8}}</td>
                <td class="align-middle bg-lightyellow" style="text-align:right;">{{this.suryo8}}</td>
                <td class="align-middle bg-lightyellow">{{this.tani8}}</td>
              </tr>
              <tr>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_meisyou8"
                    style="width:100%"
                    maxlength ="15"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="tel"
                    class="form-control p-1 flex-fill text-right"
                    v-model="saigai_suryo8"
                    :maxlength="max_suryo8"
                    @blur="saigaisuryo8Change(saigai_suryo8)"
                    @focus="saigaisuryo8RemoveComma()"
                    @input="inputSuryo8()"
                    style="width:100%"
                  />
                </td>
                <td class="align-middle border">
                  <input
                    type="text"
                    class="form-control p-1 flex-fill"
                    v-model="saigai_tani8"
                    maxlength="10"
                    style="width:100%"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="container pt-3 d-flex flex-wrap justify-content-between border-top border-secondary"
        >
          <div style="width:100%;">
            <p class="text-info text-right mb-1">単位:千円</p>
            <table class="table table-sm table-bordered" style>
              <thead class="table-dark">
                <tr class>
                  <th
                    class="align-middle text-center border-white border-right-0"
                    width="24%"
                    style="text-align:right;"
                  >本工事費(A)</th>
                  <th
                    class="align-middle text-center border-white"
                    width="24%"
                    style="text-align:right;"
                  >工事雑費・営繕費(B)</th>
                  <th
                    class="align-middle text-center border-white"
                    width="24%"
                    style="text-align:right;"
                  >応急仮本工事費(C)</th>
                  <th
                    class="align-middle text-center border-white"
                    width="24%"
                    style="text-align:right;"
                  >応急仮工事雑費(D)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="align-middle border bg-lightyellow text-right"
                    colspan
                    width="16%"
                    height="35px;"
                    maxlength="9"
                  >
                    <label class="align-middle text-right" style="width:85%;margin-bottom:0px;">{{this.sinsei_gaku}}</label>
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td
                    class="align-middle border bg-lightyellow text-right"
                    colspan
                    width="16%"
                    height="35px;"
                    maxlength="9"
                  >
                    <label class="align-middle text-right" style="width:85%;margin-bottom:0px;">{{this.kotuko_eizenhi}}</label>
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td
                    class="align-middle border bg-lightyellow text-right"
                    colspan
                    width="16%"
                    height="35px;"
                    maxlength="9"
                  >
                    <label class="align-middle text-right" style="width:85%;margin-bottom:0px;">{{this.naikari_koujihi}}</label>
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td
                    class="align-middle border bg-lightyellow text-right"
                    colspan
                    width="16%"
                    height="35px;"
                    maxlength="9"
                  >
                    <label class="align-middle text-right" style="width:85%;margin-bottom:0px;">{{this.oukyukarikouji_zapi}}</label>
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                </tr>
                <tr>
                  <td class="align-middle border" colspan width="16%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right text-right"
                      v-model="kettei_gaku"
                      v-on:change="ketteigakuChange(kettei_gaku)"
                      maxlength="9"
                      style="width:85%;display:inline-block"
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td class="align-middle border" colspan width="16%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right text-right"
                      v-model="eizenhi"
                      v-on:change="eizenhiChange(eizenhi)"
                      maxlength="9"
                      style="width:85%;display:inline-block"
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td class="align-middle border" colspan width="16%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right"
                      style="width:85%;display:inline-block"
                      v-model="kari_koujihi"
                      v-on:change="koujihiChange(kari_koujihi)"
                      maxlength="9"
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td class="align-middle border" colspan width="16%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right text-right"
                      style="width:85%;display:inline-block"
                      v-model="kari_kouji_zapi"
                      maxlength="9"
                      v-on:change="koujizapiChange(kari_kouji_zapi)"
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-sm table-bordered" style>
              <thead class="table-dark">
                <tr class>
                  <th
                    class="align-middle text-center border-white border-right-0"
                    width="25%"
                    style="text-align:right;"
                  >工事費[A+C]</th>
                  <th
                    class="align-middle text-center border-white"
                    width="25%"
                    style="text-align:right;"
                  >工事雑費[B+D]</th>
                  <th
                    class="align-middle text-center border-white"
                    width="25%"
                    style="text-align:right;"
                  >応急仮工事費(E)[C+D]</th>
                  <th
                    class="align-middle text-center border-white"
                    width="25%"
                    style="text-align:right;"
                  >総額(F)[A+B+E]</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="align-middle border" colspan width="25%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right text-right"
                      v-model="total_koujihi"
                      maxlength="9"
                      style="width:85%;display:inline-block"
                      readonly
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td class="align-middle border" colspan width="25%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right text-right"
                      v-model="total_kouji_zapi"
                      maxlength="9"
                      style="width:85%;display:inline-block"
                      readonly
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td class="align-middle border" colspan width="25%">
                    <input
                      type="tel"
                      class="form-control p-1 bg-light flex-fill text-right"
                      style="width:85%;display:inline-block"
                      v-model="total_kari_koujihi"
                      maxlength="9"
                      readonly
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                  <td class="align-middle border" colspan width="25%">
                    <input
                      type="tel"
                      class="form-control p-1 flex-fill text-right text-right"
                      style="width:85%;display:inline-block"
                      v-model="total_sougaku"
                      maxlength="9"
                      readonly
                    />
                    <label class="align-middle text-center" style="width:15%;margin-bottom:0px;">千円</label>
                  </td>
                </tr>
              </tbody>
            </table>
<!--
            <p class="text-info mb-0">x203B工事費・営繕費は、応急仮工事費分を含みます</p>
            <p class="text-info mt-0 ml-2">応急仮工事雑費は、工事雑費・営繕費を含みます</p>
-->
          </div>
        </div>
        <div class="container pt-3 clearfix">
          <div class="form-inline float-right">
            <button
              class="btn btn-light ml-3"
              type="button"
              style="width:120px;"
              @click="delete_btn()"
              :disabled="updateFlg ? false : true"
            >削除</button>
          </div>
        </div>
      </form>
    </main>
  </Layout>
</template>