<script>
import { APIService } from "@/apiService.js";
const apiService = new APIService();
export default {
  data() {
    return {
      userToken: window.localStorage.getItem("AUTH_TOKEN") || false,
      loginName: window.localStorage.getItem("LOGIN_NAME"),
      shimei: window.localStorage.getItem("SHIMEI"),
      screenName: this.$constants.SCREEN_NAME[this.$route.name],
      isTest: window.location.host == this.$constants.HOST_NAME_TEST ? true : false,
      title: window.location.host == this.$constants.HOST_NAME_TEST ? "災害管理集計システム - 演習モード" : "災害管理集計システム",
    };
  },
  computed: {
    reportname() {
      return this.$store.state.fa103.reportname;
    },
  },
  methods: {
    logout() {
      apiService
        .logoutUser(this.userToken)
        .then((result) => {
          localStorage.setItem("message", result.message);
          this.$router.push({ name: "M1100" });
        })
        .catch(() => {
          this.$router.push({ name: "M1100" });
        });
    },
  },
};
</script>
<template>
  <div>
    <nav class="navbar navbar-dark" :class="this.isTest ? 'test-bg-primary' : 'bg-primary'">
      <span class="navbar-brand">{{ this.title }}</span>
      <div class="ml-auto">
        <form
          class="form-inline"
          v-if="
            userToken &&
            this.$route.name !== 'M1100' &&
            (this.$route.name === 'M1101' ||
              this.$route.name === 'M1200' ||
              this.$route.name === 'M1201' ||
              this.$route.name === 'M1202' ||
              this.$route.name === 'M1203' ||
              this.$route.name === 'INF002' ||
              this.$route.name === 'FG203' ||
              this.$route.name === 'FG204' ||
              this.$route.name === 'FD101' ||
              this.$route.name === 'FD102')
          "
        >
          <router-link :to="{ name: 'M1101' }" class="btn btn-light" role="button">TOPへ戻る</router-link>
          <button class="btn btn-light ml-3" @click.prevent="logout">ログアウト</button>
        </form>
        <form class="form-inline" v-if="this.$route.name === 'M1102'">
          <router-link :to="{ name: 'M1100' }" class="btn-header btn-light" role="button">ログインへ戻る</router-link>
        </form>
        <form
          class="form-inline"
          v-else-if="
            userToken &&
            (this.$route.name === 'FA100' ||
              this.$route.name === 'FA101' ||
              this.$route.name === 'FA102' ||
              this.$route.name === 'FA103' ||
              this.$route.name === 'FA104' ||
              this.$route.name === 'FA105' ||
              this.$route.name === 'fa106' ||
              this.$route.name === 'FA107' ||
              this.$route.name === 'fa108' ||
              this.$route.name === 'FA109' ||
              this.$route.name === 'FA110' ||
              this.$route.name === 'FA111' ||
              this.$route.name === 'FA112' ||
              this.$route.name === 'FA113' ||
              this.$route.name === 'FA114')
          "
        >
          <router-link :to="{ name: 'M1101' }" class="btn-header btn-light" role="button">TOPへ戻る</router-link>
          <router-link :to="{ name: 'M1200' }" class="btn-header btn-light ml-3" role="button">MENUへ戻る</router-link>
          <button class="btn-header btn-light ml-3" @click.prevent="logout">ログアウト</button>
        </form>
        <form
          class="form-inline"
          v-else-if="
            userToken &&
            (this.$route.name === 'FB100' ||
              this.$route.name === 'FB101' ||
              this.$route.name === 'FB102' ||
              this.$route.name === 'FB103' ||
              this.$route.name == 'FB104' ||
              this.$route.name === 'FB105' ||
              this.$route.name === 'FB106' ||
              this.$route.name === 'FB108')
          "
        >
          <router-link :to="{ name: 'M1101' }" class="btn-header btn-light" role="button">TOPへ戻る</router-link>
          <router-link :to="{ name: 'M1201' }" class="btn-header btn-light ml-3" role="button">MENUへ戻る</router-link>
          <button class="btn-header btn-light ml-3" @click.prevent="logout">ログアウト</button>
        </form>
        <form
          class="form-inline"
          v-else-if="
            userToken && (this.$route.name === 'FC133' || this.$route.name === 'FC100' || this.$route.name === 'FC101' || this.$route.name === 'FC102' || this.$route.name === 'FC103' || this.$route.name === 'FC104' || this.$route.name === 'FC105')
          "
        >
          <router-link :to="{ name: 'M1101' }" class="btn-header btn-light" role="button">TOPへ戻る</router-link>
          <router-link :to="{ name: 'M1202' }" class="btn-header btn-light ml-3" role="button">MENUへ戻る</router-link>
          <button class="btn-header btn-light ml-3" @click.prevent="logout">ログアウト</button>
        </form>
        <form class="form-inline" v-else-if="userToken && this.$route.name === 'FG202'">
          <router-link :to="{ name: 'M1101' }" class="btn-header btn-light" role="button">TOPへ戻る</router-link>
          <router-link :to="{ name: 'M1203' }" class="btn-header btn-light ml-3" role="button">MENUへ戻る</router-link>
          <button class="btn-header btn-light ml-3" @click.prevent="logout">ログアウト</button>
        </form>
        <form class="form-inline" v-else-if="userToken && this.$route.name === 'AUTHERROR'">
          <router-link :to="{ name: 'M1101' }" class="btn-header btn-light" role="button">TOPへ戻る</router-link>
          <router-link :to="{ name: 'M1203' }" class="btn-header btn-light ml-3" role="button">MENUへ戻る</router-link>
          <button class="btn-header btn-light ml-3" @click.prevent="logout">ログアウト</button>
        </form>
      </div>
    </nav>
    <header class="bg-light">
      <nav class="navbar navbar-dark">
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            {{ screenName }}
            <span v-if="this.$route.name === 'FA103' && reportname">({{ reportname }})</span>
          </li>
        </ul>
        <ul class="list-inline mb-0" v-if="userToken && this.$route.name !== 'M1100'">
          <li class="list-inline-item">{{ loginName }}{{ shimei }}</li>
        </ul>
      </nav>
    </header>
  </div>
</template>
