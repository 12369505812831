<script>
import AppLayout from '@/components/layouts/Layout.vue'
import { APIService } from "@/apiService.js";
import datepicker from 'vuejs-datepicker';
import moment from "moment";
import {ja} from 'vuejs-datepicker/dist/locale';
var numeral = require("numeral");
const apiService = new APIService();

export default {
  name: 'FA109',
  components: {
    AppLayout, datepicker
  },
  data() {
    if (this.$route.query.kanrisya_cd == localStorage.getItem("Kanrisya_Cd")) {
      var kanrisha_mei = localStorage.getItem("Kanrisya_Mei");
    }
    return {
      max: '10',
      ja: ja,
      engYear: this.$route.query.saigai_yyyy,
      saigai_ken: this.$route.query.saigai_ken,
      kanrisya_cd: this.$route.query.kanrisya_cd,
      kanrisha_mei: kanrisha_mei,
      houkokusya_cd: this.$route.query.houkokusya_cd,
      saigai_mei: '',
      saigai_start: '',
      saigai_end: '',
      saigai_yyyy: '',
      isHasInfo: false,
      info: '',
      isHasErr: false,
      errMsg: '',
      higai_sisetu2_detail: [],
      selectedNo: 1,
      blockNo: [],
      hisai_encyou: '',
      cd_value17:'',
      cd_value18:'',
      post: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(from));
  },
  beforeRouteUpdate(to, from, next) {
    this.post = null;
    this.setData(from);
    next();
  },
  mounted() {
    this.getSaigaiMeisyou();
    this.itemCount = this.selectedNo;
    this.getGengo(this.engYear);
    this.getHigaiSisetu2();
  },
  methods: {
    setData(from) {
      this.post = from;
    },
    showBlock() {
      this.isHasInfo = false;
      this.isHasErr = false;
      this.michikawameisList = [];
      this.cyouazas = [];
      this.itemCount = this.selectedNo;
      this.getHigaiSisetu2();
    },
    getSaigaiMeisyou() {
      var request_data = {
        year: this.engYear,
        ken: this.saigai_ken
      };
      apiService.getSaigaiMeisyou(request_data)
      .then(result => {
        this.saigai_mei = result[0].saigai_mei;
        this.saigai_start = this.formatDate(result[0].saigai_start);
        this.saigai_end = this.formatDate(result[0].saigai_end);
      })
      .catch(error => {
        console.log(error);
      });
    },
    getHigaiSisetu2() {
      var request_data = {
        saigai_yyyy: this.engYear,
        saigai_ken: this.saigai_ken,
        kanrisya_cd: this.kanrisya_cd,
        houkokusya_cd: this.houkokusya_cd,
        kubun_cd: 1,
      };
      apiService.fa109getHigaiSisetu2(request_data)
        .then(result => {
          this.higai_sisetu2_detail = result;
        for(var i = 0; i< this.higai_sisetu2_detail.length; i++) {
          this.higai_sisetu2_detail[i].oukyukouhou_start = this.formatDate(this.higai_sisetu2_detail[i].oukyukouhou_start);
          this.higai_sisetu2_detail[i].oukyukouhou_end = this.formatDate(this.higai_sisetu2_detail[i].oukyukouhou_end);
          this.higai_sisetu2_detail[i].koutu_kiseibi = this.formatDate(this.higai_sisetu2_detail[i].koutu_kiseibi);
          this.higai_sisetu2_detail[i].hisai_encyou = this.formatDecimal(this.higai_sisetu2_detail[i].hisai_encyou);
          this.higai_sisetu2_detail[i].hisai_gaku = this.formatNumber(this.higai_sisetu2_detail[i].hisai_gaku);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    getGengo(year) {
      var date = new Date(year, 6, 1, 0, 0, 0, 0);
      var param = moment(String(date)).format("YYYYMMDD");
      var request_data = {
        date: param
      };
      apiService.getGengo(request_data).then(result => {
        this.saigai_yyyy = result;
      });
    },
    michikawashubetsuChange(kasensu_mei, index) {
      this.shubetu_index = kasensu_mei.target.options[kasensu_mei.target.options.selectedIndex].text
        for(var i = 0; i < this.higai_sisetu2_detail[index].kasensumeiList.length; i++){
          if(this.higai_sisetu2_detail[index].kasensumeiList[i].kasen_syubetu == this.shubetu_index){
            this.higai_sisetu2_detail[index].kasen_syubetumei = this.higai_sisetu2_detail[index].kasensumeiList[i].kasen_syubetumei;
            this.higai_sisetu2_detail[index].rokasen_syubetu = this.shubetu_index;
          }
        }  
        this.higai_sisetu2_detail[index].rokasen_mei = '';
        this.higai_sisetu2_detail[index].rokasen_cd = '';
        var request_data = {
          rokasen_syubetu: this.shubetu_index
        };
        apiService
          .fa109checkKasenshubetu(request_data)
          .then(result => {
            this.higai_sisetu2_detail[index].shubetuCheckList = result;
          })
          .catch(error => {
            this.error = error;
          });
    },
    shichousonsChange(shichouson, index) {
      this.shichou_index = shichouson.target.options[shichouson.target.options.selectedIndex].text;
        for(var i = 0; i < this.higai_sisetu2_detail[index].shcyousonList.length; i++){
          if(this.higai_sisetu2_detail[index].shcyousonList[i].shicyou_cd == this.shichou_index){
            this.higai_sisetu2_detail[index].shicyou_mei = this.higai_sisetu2_detail[index].shcyousonList[i].shicyou_mei;
            this.higai_sisetu2_detail[index].hisai_shiguncd = this.shichou_index;
          }
        }
        this.higai_sisetu2_detail[index].cyouaza_mei = '';
        this.higai_sisetu2_detail[index].hisai_machicd = '';
        var request_data = {
          shcyou_cd: this.shichou_index
        };
        apiService
          .fa109shcyousonCheckKbn2(request_data)
          .then(result => {
            this.higai_sisetu2_detail[index].shcyouson2List = result;
          })
          .catch(error => {
            this.error = error;
          });
    },
    rosenKyouryoumeiChange(rosenKyouryoumei, index) {
      var rosen_kyouryou = rosenKyouryoumei.target.options[rosenKyouryoumei.target.options.selectedIndex].text;
        for(var i = 0; i < this.higai_sisetu2_detail[index].shubetuCheckList.length; i++){
          if(this.higai_sisetu2_detail[index].shubetuCheckList[i].kasen_cd == rosen_kyouryou){
            this.higai_sisetu2_detail[index].rokasen_mei = this.higai_sisetu2_detail[index].shubetuCheckList[i].rokasen_mei;
            this.higai_sisetu2_detail[index].rokasen_cd = rosen_kyouryou;
          }
        }
    },
    ukairoKbnChange(ukairo_kbn, index) {
      this.ukairo_kbn_index = ukairo_kbn.target.options[ukairo_kbn.target.options.selectedIndex].text;
        for(var i = 0; i < this.higai_sisetu2_detail[index].mCode17.length; i++){
          if(this.higai_sisetu2_detail[index].mCode17[i].display_no == this.ukairo_kbn_index){
            this.higai_sisetu2_detail[index].cd_value17 = this.higai_sisetu2_detail[index].mCode17[i].cd_value;
            this.higai_sisetu2_detail[index].ukairo_kbn = this.ukairo_kbn_index;
          }
        }
    },
    cdValChange(cd_value, index) {
      this.cd_value_index = cd_value.target.options[cd_value.target.options.selectedIndex].text;
        for(var i = 0; i < this.higai_sisetu2_detail[index].mCode18.length; i++){
          if(this.higai_sisetu2_detail[index].mCode18[i].display_no == this.cd_value_index){
            this.higai_sisetu2_detail[index].cd_value18 = this.higai_sisetu2_detail[index].mCode18[i].cd_value;
            this.higai_sisetu2_detail[index].koutu_kiseikbn = this.cd_value_index;
          }
        }
    },
    cyouazaMeiChange(cyouaza_mei, index) {
      var cyouaza_mei_index = cyouaza_mei.target.options[cyouaza_mei.target.options.selectedIndex].text;
        for(var i = 0; i < this.higai_sisetu2_detail[index].shcyouson2List.length; i++){
          if(this.higai_sisetu2_detail[index].shcyouson2List[i].cyouaza_cd == cyouaza_mei_index){
            this.higai_sisetu2_detail[index].cyouaza_mei = this.higai_sisetu2_detail[index].shcyouson2List[i].cyouaza_mei;
            this.higai_sisetu2_detail[index].hisai_machicd = cyouaza_mei_index;
          }
        }
    },
    focusEncyou(index){
      if(this.higai_sisetu2_detail[index].hisai_encyou != null){
        this.higai_sisetu2_detail[index].hisai_encyou = this.higai_sisetu2_detail[index].hisai_encyou.toString().trim().replace(/,/g,'');
      }
    },
    hisaiEncyouChange(index) {
      this.higai_sisetu2_detail[index].hisai_encyou = this.formatDecimal(this.higai_sisetu2_detail[index].hisai_encyou);
    },
    focusGaku(index){
      if(this.higai_sisetu2_detail[index].hisai_gaku != null){
        this.higai_sisetu2_detail[index].hisai_gaku = this.higai_sisetu2_detail[index].hisai_gaku.toString().trim().replace(/,/g,'');
      }
    },
    hisaiGakuChange(index) {
      this.higai_sisetu2_detail[index].hisai_gaku = this.formatNumber(this.higai_sisetu2_detail[index].hisai_gaku);
    },
    input(index) {
      this.max = this.higai_sisetu2_detail[index].hisai_encyou.indexOf(".") > 0 ? 11 : 10;
      if(this.higai_sisetu2_detail[index].hisai_encyou.length > 9 &&  this.higai_sisetu2_detail[index].hisai_encyou.charAt(9) != '.' ) {
        this.higai_sisetu2_detail[index].hisai_encyou = this.higai_sisetu2_detail[index].hisai_encyou.substring(0,9);
      }
    },
    formatDate: function(date) {
      if(date == '' || date == null ){
        return date;
      }
      return moment(String(date)).format("YYYY/MM/DD");
    },
    formatDecimal: function(number) {
      return number=='' || number==null || numeral(number).format("0,0.0")==0 ? '' : numeral(number).format("0,0.0");
    },
    formatNumber: function(number){
      if(number == '' || number == null){
        return number;
      }
      var result = numeral(number).format("0,0");
      return result == 0 ? '': result;
    },
    isInteger(value) {
      return /^\d+$/.test(value);
    },
    insertHaigai2() {
      this.info = '';
      this.isHasInfo = false;
      this.errMsg = '';
      this.isHasErr = false;
      for(var i = 0; i < this.higai_sisetu2_detail.length; i++) {
        var temp =  this.higai_sisetu2_detail[i].hisai_encyou == null || this.higai_sisetu2_detail[i].hisai_encyou == '' ? '': this.higai_sisetu2_detail[i].hisai_encyou.toString().trim().replace(/,/g,'');
        this.higai_sisetu2_detail[i].hisai_encyou = temp;
        var temp1 = this.higai_sisetu2_detail[i].hisai_gaku == null || this.higai_sisetu2_detail[i].hisai_gaku == '' ? '': this.higai_sisetu2_detail[i].hisai_gaku.toString().replace(/,/g,'');
        this.higai_sisetu2_detail[i].hisai_gaku = temp1 ;
        this.higai_sisetu2_detail[i].oukyukouhou_start = this.formatDate(this.higai_sisetu2_detail[i].oukyukouhou_start);
        this.higai_sisetu2_detail[i].oukyukouhou_end = this.formatDate(this.higai_sisetu2_detail[i].oukyukouhou_end);
        this.higai_sisetu2_detail[i].koutu_kiseibi = this.formatDate(this.higai_sisetu2_detail[i].koutu_kiseibi);
      }
      var request_data =  {
        saigai_yyyy: this.engYear,
        saigai_ken: this.saigai_ken,
        kanrisya_cd: this.kanrisya_cd,
        houkokusya_cd: this.houkokusya_cd,
        login_id: localStorage.getItem('LOGIN_ID'),
        list: this.higai_sisetu2_detail,
        updateCount: this.itemCount
      }
      apiService.fa109insertHigaiSisetu2(request_data)
      .then(result => {
        this.info = result;
        this.isHasInfo = true;
        this.getHigaiSisetu2();
      })
      .catch(error => {
        this.errMsg = error;
        this.isHasErr = true;
      });
    },
    back_btn() {
      this.$router.push({
        name: this.post.name,
        query: this.post.query
      });
    }
  }
}
</script>

<template>
  <AppLayout>
    <main class="mb-3" id="fa109">
      <div class="col-sm-12 bg-success-info text-center">
        <span class="col-md-12 text-center" v-show="isHasInfo"> {{ info }} </span>
      </div>
      <div class="col-sm-12 bg-err text-center">
        <span v-show="isHasErr"  class="err">{{ this.errMsg }}</span>
      </div>
     <div class="container-fluid bg-lightgreen pt-3 pb-3">
      <div class="container">
        <form action="" method="">
          <div class="form-group row" style="width:100%; max-width:700px">
            <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">災害年</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="text" class="form-control bg-light" v-model="saigai_yyyy" readonly>
            </div>
            <label for="" class="col-sm col-form-label text-left pr-0 pl-1" style="width:100%; max-width:20px">年</label>
            <label for="" class="col-sm col-form-label text-right" style="width:100%; max-width:150px">被害報告番号</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:105px">
              <input type="text" class="form-control bg-light"  v-model="houkokusya_cd" readonly>
            </div>
          </div>
          <div class="form-group row" style="">
            <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">災害番号</label>
            <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:105px">
              <input type="text" class="form-control bg-light" v-model="saigai_ken" readonly>
            </div>
            <label for="" class="col-sm col-form-label" style="width:100%; max-width:125px">異常気象名</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:280px">
              <input type="text" class="form-control bg-light" v-model="saigai_mei" readonly>
            </div>
            <label for="" class="col-sm col-form-label text-right" style="width:100%; max-width:120px">災害発生日</label>
            <div class="col-sm pr-0" style="width:100%; max-width:130px">
              <input type="text" class="form-control bg-light" v-model="saigai_start" readonly>
            </div>
            <label for="" class="col-sm col-form-label text-center" style="width:100%; max-width:50px">〜</label>
            <div class="col-sm pl-0" style="width:100%; max-width:130px">
              <input type="text" class="form-control bg-light" v-model="saigai_end" readonly>
            </div>
          </div>
          <div class="form-group row mb-0" style="width:55%; ">
            <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">管理者</label>
            <div class="col-sm pr-0 pl-0" style="width:100%; max-width:60px">
              <input type="text" class="form-control bg-light" v-model="kanrisya_cd" readonly>
            </div>
            <div class="col-sm pr-0 pl-1" style="width:100%; max-width:500px">
              <input type="text" class="form-control bg-light" v-model="kanrisha_mei" readonly>
            </div>
          </div>
        </form>
      </div>
     </div><!--container-fluid-->
      <div class="container pt-3 clearfix align-middle">
        <div class="form-inline float-right">
          <button class="btn btn-light" type="button" @click="insertHaigai2()" style="width:120px;">登録</button>
          <button class="btn btn-light ml-3" style="width:120px;" type="button" @click="back_btn()">戻る</button>
        </div>
      </div>
        <div class="container pt-3 clearfix align-middle">
          <div class="form-inline float-left">
            <button class="btn btn-light"  type="button" style="width:120px;" @click="showBlock()">行変更</button>
            <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
              <select id="" class="form-control" v-model="selectedNo">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container mt-2 pt-3">
          <table class="table table-sm table-bordered">
            <tbody v-for="(higai_sisetu2,index) in higai_sisetu2_detail" :key="index" :value="index">
              <tr v-if="index < itemCount ">
                <th scope="row" class="align-middle table-primary border-right" width="5%">{{higai_sisetu2.renban}}</th>
                <td class="align-middle border-right" :class="index%2 !=0 ? 'table-secondary' : ''">
                  <div class="form-group row" style="width:100%;">
                    <div style="width:100%; max-width:15px"></div>
                    <div class="rounded p-0 text-center mb-0" style="width:130px; background-color:yellowgreen">
                      <input type = "hidden" v-model="higai_sisetu2.renban">
                      <p class="m-0 p-0 text-white font-weight-bold">区分 道路</p>
                    </div>
                    <div style="width:100%; max-width:340px"></div>
                    <label for="" class="m-0 p-0 text-primary font-weight-bold" style="width:100%; max-width:130px">被災位置</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%;">
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">道路種別</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
                      <select id="" class="form-control"  v-model="higai_sisetu2.rokasen_syubetu" v-on:change="michikawashubetsuChange($event, index)">
                        <option
                          v-for="kasensu_mei in higai_sisetu2.kasensumeiList"
                          :key="kasensu_mei.kasen_syubetu"
                          :value="kasensu_mei.kasen_syubetu"
                        >{{kasensu_mei.kasen_syubetu}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="higai_sisetu2.kasen_syubetumei" readonly>
                    </div>
                    <div style="width:100%; max-width:10px"></div>
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">市町村</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
                      <select id="" class="form-control"  v-model="higai_sisetu2.hisai_shiguncd" @change="shichousonsChange($event, index)">
                        <option
                          v-for="shcyousons in higai_sisetu2.shcyousonList"
                          :key="shcyousons.shicyou_cd"
                          :value="shcyousons.shicyou_cd"
                        >{{shcyousons.shicyou_cd}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="higai_sisetu2.shicyou_mei" readonly>
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">路河川橋梁</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
                      <select id="" class="form-control" v-model="higai_sisetu2.rokasen_cd" v-on:change="rosenKyouryoumeiChange($event, index)">
                        <option
                          v-for="michikawamei in higai_sisetu2.shubetuCheckList"
                          :key="michikawamei.kasen_cd"
                          :value="michikawamei.kasen_cd"
                        >{{michikawamei.kasen_cd}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="higai_sisetu2.rokasen_mei" readonly>
                    </div>                    
                    <div style="width:100%; max-width:10px"></div>
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">町字</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
                      <select id="" class="form-control" v-model="higai_sisetu2.hisai_machicd" v-on:change="cyouazaMeiChange($event, index)">
                        <option
                          v-for="cyouaza in higai_sisetu2.shcyouson2List"
                          :key="cyouaza.cyouaza_cd"
                          :value="cyouaza.cyouaza_cd"
                        >{{cyouaza.cyouaza_cd}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:250px">
                      <input type="text" class="form-control bg-light" v-model="higai_sisetu2.cyouaza_mei" readonly>
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">被災延長</label>
                    <div class="col-sm pr-0 pl-0" style="max-width:200px;">
                      <input type="text" class="form-control text-right" v-model="higai_sisetu2.hisai_encyou" :maxlength="max" style="width:100%;" @blur="hisaiEncyouChange(index)" @focus="focusEncyou(index)" @input="input(index)">
                    </div>
                    <label for="" class="col-sm col-form-label text-left" style="width:100%; max-width:140px">m</label>
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:150px">期間</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:150px">
                      <datepicker :language="ja" v-model="higai_sisetu2.oukyukouhou_start" :value="higai_sisetu2.oukyukouhou_start" format="yyyy/MM/dd" width="100px"></datepicker>
                    </div>
                    <label for="" class="col-sm col-form-label text-center" style="width:100%; max-width:50px">〜</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:150px">
                      <datepicker :language="ja" v-model="higai_sisetu2.oukyukouhou_end" :value="higai_sisetu2.oukyukouhou_end" format="yyyy/MM/dd" width="100px"></datepicker>
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">被災額</label>
                    <div class="col-sm pr-0 pl-0" style="max-width:200px;">
                      <input type="text" class="form-control text-right" v-model="higai_sisetu2.hisai_gaku" maxlength="9" style="width:100%;" @blur="hisaiGakuChange(index)" @focus="focusGaku(index)">
                    </div>
                    <label for="" class="col-sm col-form-label text-left" style="width:100%; max-width:130px">千円</label>
                    <div style="width:100%; max-width:10px"></div>
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:150px">迂回路の有無</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:100px">
                      <select id="" class="form-control" v-model='higai_sisetu2.ukairo_kbn' v-on:change="ukairoKbnChange($event, index)">
                        <option
                          v-for="code17 in higai_sisetu2.mCode17"
                          :key="code17.meisai"
                          :value="code17.meisai"
                        >{{code17.meisai}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:100px">
                      <input type="text" class="form-control bg-light" v-model='higai_sisetu2.cd_value17' readonly>
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:130px">応急工法</label>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:340px">
                      <input type="text" class="form-control" v-model="higai_sisetu2.oukyukouhou" maxlength="100">
                    </div>
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:150px">交通規制日</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:150px">
                      <datepicker :language="ja" v-model="higai_sisetu2.koutu_kiseibi" :value="higai_sisetu2.koutu_kiseibi" format="yyyy/MM/dd"></datepicker>
                    </div>
                    <label for="" class="col-sm col-form-label pl-3" style="width:100%; max-width:100px">規制区分</label>
                    <div class="col-sm pr-0 pl-0" value="" style="width:100%; max-width:70px">
                      <select id="" class="form-control" v-model="higai_sisetu2.koutu_kiseikbn" v-on:change="cdValChange($event, index)">
                        <option
                          v-for="code18 in higai_sisetu2.mCode18"
                          :key="code18.meisai"
                          :value="code18.meisai"
                        >{{code18.meisai}}</option>
                      </select>
                    </div>
                    <div class="col-sm pr-0 pl-0" style="max-width:70px;">
                      <input type="text" class="form-control bg-light" v-model='higai_sisetu2.cd_value18' readonly style="width:100%;">
                    </div>
                  </div>
                  <div class="form-group row" style="width:100%;">
                    <div class="col-sm pr-0 pl-0" style="max-width:470px;"></div>
                    <label for="" class="col-sm col-form-label" style="width:100%; max-width:150px">被災状況等</label>
                    <div class="col-sm pr-0 pl-0" style="width:100%; max-width:350px">
                      <input type="text" class="form-control" v-model="higai_sisetu2.hisai_jyoukyou" maxlength="100">
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </main>
  </AppLayout>
</template>
