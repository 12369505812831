const defaultState = {
  search: '',
};
export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    setYear(state, result) {
      state.year = result;
    },
    setKen(state, result) {
      state.ken = result;
    },
    setKanrisya(state, result) {
      state.kanrisya_cd = result;
    },
    setKanrisyaMei(state, result) {
      state.kanrisya_mei = result;
    },
    setLoginId(state, result) {
      state.login_id = result;
    },
    setLoginName(state, result) {
      state.login_name = result;
    },
    setValidFlg(state, result) {
      state.valid_flg = result;
    },
    setPage(state, result) {
      state.page = result;
    },
    setStatus(state, result) {
      state.status = result;
    },
    init(state) {
      Object.assign(state, defaultState);
    },
  }
};
